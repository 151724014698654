// Récupérer le fait d'aller sur l'expertise
let isVFgoToExpertise = false;
if ($("#vehicule_fournisseur_results").data("gotoexpertise") == true || $("#vehicule_fournisseur_achete_results").data("gotoexpertise") == true) {
    isVFgoToExpertise = true;
}

/**
 * Setup une page d'édition unitaire de VF
 * @param {string} var_clicked
 * @param {(string|number)} id_clicked
 * @returns {any}
 */
function vfSetup(var_clicked, id_clicked) {
    if (var_clicked == 'offre_fournisseur') {
        $("[id^='btnGroupNormeCO2_']").each(function () {
            let idVF = $(this)[0].id.replace('btnGroupNormeCO2_', '');
            $("#btnGroupNormeCO2_" + idVF).html((new Date($('#vehicule_fournisseur_input_date_mec_' + idVF).val()).getTime() - new Date("2020-02-29").getTime()) <= 0 ? "NEDC" : "WLTP");
        })
    } else {
        $("#btnGroupNormeCO2_" + id_clicked).html((new Date($('#vehicule_fournisseur_input_date_mec_' + id_clicked).val()).getTime() - new Date("2020-02-29").getTime()) <= 0 ? "NEDC" : "WLTP");
    }
    $('select[id^="vehicule_fournisseur_input_fournisseur_"]').selectpicker();
    $('select[id^="vehicule_fournisseur_input_tva_"]').selectpicker();
    $('select[id^="vehicule_fournisseur_input_marque_"]').selectpicker();
    if (var_clicked == 'offre_fournisseur') {
        // Si on est dans une offre fournisseur, on ne doit pas bloquer les modèles pour les offres déjà existantes, mais on doit bloquer les modèles de la ligne vierge
        $('select[id^="vehicule_fournisseur_input_modele_"]').each(function () {
            if ($(this).attr('id') != 'vehicule_fournisseur_input_modele___id__') {
                $(this).prop("disabled", false);
            }
        });
    }
    $('select[id^="vehicule_fournisseur_input_modele_"]').selectpicker();
    $('select[id^="vehicule_fournisseur_input_marque_"]').each(function () {
        let idMarque = $(this).val();
        let listOptionsModele = document.querySelectorAll("select#" + $(this)[0].id.replace("marque", "modele") + " option");
        for (let index = 0; index < listOptionsModele.length; index++) {
            if ($(listOptionsModele[index]).attr("data-marque") == idMarque) {
                $(listOptionsModele[index]).removeClass("d-none");
            } else {
                $(listOptionsModele[index]).addClass("d-none");
            }
        }
    });
    $('select[id^="vehicule_fournisseur_input_boite_"]').selectpicker();
    $('select[id^="vehicule_fournisseur_input_carburant_"]').selectpicker();
    $('select[id^="vehicule_fournisseur_input_couleur_"]').selectpicker();
    $('select[id^="vehicule_fournisseur_input_is_verif_by_"]').selectpicker();
    $('select[id^="vehicule_fournisseur_input_nbPlace_"]').selectpicker();
    $('select[id^="vehicule_fournisseur_input_delaiProjet_"]').selectpicker();
    $('select[id^="offre_fournisseur_input_fournisseur_"]').selectpicker();
    calculQuickStudyStatus(id_clicked);
    if (var_clicked == 'vehicule_fournisseur') {
        vfDataCompilationGenerator(id_clicked);
        // On va checker que les fournisseurs dans le select soit du même type que le fournisseur sélectionné, sinon on les enlève
        if (id_clicked != null) {
            let typeFRE = $('#vehicule_fournisseur_input_fournisseur_' + id_clicked).find('option[value="' + $('#vehicule_fournisseur_input_fournisseur_' + id_clicked).val() + '"]').attr('data-expertise');
            let typeVente = $('#vehicule_fournisseur_input_fournisseur_' + id_clicked).find('option[value="' + $('#vehicule_fournisseur_input_fournisseur_' + id_clicked).val() + '"]').attr('data-typevente');
            $('#vehicule_fournisseur_input_fournisseur_' + id_clicked).children().each(function () {
                if ($(this).attr('data-expertise') != typeFRE && $(this).attr('data-typevente') != typeVente) {
                    $(this).remove();
                }
            });
        }
    }
    if (var_clicked == 'offre_fournisseur') {
        $("[id^='btnGroupNormeCO2_']").each(function () {
            let idVF = $(this)[0].id.replace('btnGroupNormeCO2_', '');
            vfLinkGenerator(idVF);
        })
    } else {
        vfLinkGenerator(id_clicked);
    }
    $("#" + var_clicked + "_input_fournisseur_" + id_clicked).trigger("change");
    if (var_clicked == 'offre_fournisseur') {
        $('#offre_fournisseur_loader').addClass('d-none');
        $('.card-body').removeClass('d-none');
    }
    if (var_clicked == 'vehicule_fournisseur') {
        updateBodyworkDamage(id_clicked);
    } else {
        $("#liste_vehicule_fournisseur_offre_fournisseur_" + id_clicked).find("[id^='ligne_vehicule_fournisseur_'][id!='ligne_vehicule_fournisseur___id__']").toArray().forEach(element => {
            updateBodyworkDamage(element.getAttribute("data-id"));
        });
    }
    if (isVFgoToExpertise) { // condition pour aller ouvrir l'expertise
        $('#btnGroupFRE_' + idVF).trigger("click");
    }
}

function checkVin(e) {
    // Vérification de la présence VIN parmis les VFs déjà existant
    const idVF = e.target.id.replace(/^.*\D+(\d*)$/g, '$1');
    let optionsRecherche = new FormData();
    optionsRecherche.append('id', idVF);
    optionsRecherche.append('vin', e.target.value);
    $.ajax({
        type: 'POST',
        url: siteURL + 'admin/vehicule_fournisseur/update/checkVin',
        contentType: false,
        processData: false,
        data: optionsRecherche,
        success: function (data) {
            // Retour structuré de la façon suivante
            // nbSameVIN : nombre de fois que le VIN apparait en base de donnée
            // idVF      : ID du premier VF qui possède le même VIN
            // [nbSameVIN,idVF]
            data = data.split(",");
            const nbSameVIN = data[0];
            const idVF = data[1];
            // Si il y a au moins un VF avec le même VIN
            if (parseInt(nbSameVIN)) {
                // Affichage d'un avertisement
                if ($("#offre_fournisseur_results").length) {
                    toastr.warning("VIN déjà existant sous la vf-" + idVF + ".<br>Les informations ont été préchargé.", 'Warning');
                    const vinInputByOF = $("[id^='vehicule_fournisseur_input_vin_'][data-idof='" + e.target.getAttribute("data-idof") + "']");
                    vinInputByOF.toArray().forEach(input => {
                        if (e.target.value == input.value) {
                            const idOF = input.closest("tr").getAttribute("data-idof");
                            const numeroLigne = input.closest("tr").getAttribute("data-numeroligne");
                            let optionsRecherche = new FormData();
                            optionsRecherche.append('id', idVF);
                            optionsRecherche.append('idof', idOF);
                            optionsRecherche.append('numeroLigne', numeroLigne);
                            $.ajax({
                                type: 'POST',
                                url: siteURL + 'admin/achat/offre_fournisseur/show_line',
                                contentType: false,
                                processData: false,
                                data: optionsRecherche,
                                success: function (data) {
                                    const oldIdVF = e.target.id.replace(/^.*\D+(\d*)$/g, '$1');
                                    $("#ligne_vehicule_fournisseur_" + oldIdVF + "[data-numeroligne='" + numeroLigne + "'][data-idof='" + idOF + "']").replaceWith(data);
                                    $("#ligne_vehicule_fournisseur_" + idVF + " input:not([id^='vehicule_fournisseur_input_vin_'])").trigger("change");
                                    const modeleId = $("#ligne_vehicule_fournisseur_" + idVF + " select[id^='vehicule_fournisseur_input_modele_']").val();
                                    $("#ligne_vehicule_fournisseur_" + idVF + " select").trigger("change");
                                    $("#ligne_vehicule_fournisseur_" + idVF + " select[id^='vehicule_fournisseur_input_modele_']").val(modeleId);
                                    $("#ligne_vehicule_fournisseur_" + idVF + " .selectpicker").selectpicker().selectpicker("refresh");
                                }
                            });
                        }
                    });
                } else {
                    $('#vehicule_fournisseur-modal-vin-existant').modal("show");
                    $.each($('.vehicule_fournisseur-lien-vf-already-create'), function () {
                        $(this).attr("href", siteURL + "admin/vehicule_fournisseur/" + idVF);
                    });
                    $.each($('.vehicule_fournisseur-id-vf-already-create'), function () {
                        $(this).html(idVF);
                    });
                }
            }
        }
    });
}

// Génération des lien
function vfLinkGenerator(idVF) {
    let vehicule_fournisseur_marque = $('#vehicule_fournisseur_input_marque_' + idVF).val();
    let vehicule_fournisseur_modele = $('#vehicule_fournisseur_input_modele_' + idVF).val();
    let vehicule_fournisseur_boite = $('#vehicule_fournisseur_input_boite_' + idVF).val();
    let vehicule_fournisseur_carburant = $('#vehicule_fournisseur_input_carburant_' + idVF).val();
    let vehicule_fournisseur_date_mec = $('#vehicule_fournisseur_input_date_mec_' + idVF).val().replace(/-\w+/g, "");
    if ($('#offre_fournisseur_information_container').length == 1 && idVF != "__id__") {
        vehicule_fournisseur_date_mec = $('#vehicule_fournisseur_input_year_' + idVF).val();
    }
    let vehicule_fournisseur_kilometrage = $('#vehicule_fournisseur_input_kilometrage_' + idVF).val();

    if (vehicule_fournisseur_marque.length != 0) {
        vehicule_fournisseur_marque = $('#vehicule_fournisseur_option_marque_' + vehicule_fournisseur_marque).text();
    }
    if (vehicule_fournisseur_modele.length != 0) {
        vehicule_fournisseur_modele = $('#vehicule_fournisseur_option_modele_' + vehicule_fournisseur_modele).text();
    }

    let url_lacentrale = lacentraleLinkGenerator(vehicule_fournisseur_marque, vehicule_fournisseur_modele, vehicule_fournisseur_boite, vehicule_fournisseur_carburant, vehicule_fournisseur_date_mec, vehicule_fournisseur_kilometrage);
    let url_leboncoin = leboncoinLinkGenerator(vehicule_fournisseur_marque, vehicule_fournisseur_modele, vehicule_fournisseur_boite, vehicule_fournisseur_carburant, vehicule_fournisseur_date_mec, vehicule_fournisseur_kilometrage);
    let url_starterre = starterreLinkGenerator(vehicule_fournisseur_marque, vehicule_fournisseur_modele, vehicule_fournisseur_boite, vehicule_fournisseur_carburant, vehicule_fournisseur_date_mec, vehicule_fournisseur_kilometrage);
    let url_aramisauto = aramisautoLinkGenerator(vehicule_fournisseur_marque, vehicule_fournisseur_modele, vehicule_fournisseur_boite, vehicule_fournisseur_carburant, vehicule_fournisseur_date_mec, vehicule_fournisseur_kilometrage);

    $("#vehicule_fournisseur_lacentrale_" + idVF).val(url_lacentrale);
    $("#btnGroupLacentrale_" + idVF + " > a").attr("href", url_lacentrale);
    $("#vehicule_fournisseur_leboncoin_" + idVF).val(url_leboncoin);
    $("#btnGroupLeboncoin_" + idVF + " > a").attr("href", url_leboncoin);
    $("#vehicule_fournisseur_starterre_" + idVF).val(url_starterre);
    $("#btnGroupStarterre_" + idVF + " > a").attr("href", url_starterre);
    $("#vehicule_fournisseur_aramisauto_" + idVF).val(url_aramisauto);
    $("#btnGroupAramisauto_" + idVF + " > a").attr("href", url_aramisauto);
}

/**
 * Génération des tableaux comparatifs
 * @param {(string|number)} idVF
 * @returns {any}
 */
function vfDataCompilationGenerator(idVF) {
    let vehicule_fournisseur_marque = $('#vehicule_fournisseur_input_marque_' + idVF).val();
    let vehicule_fournisseur_modele = $('#vehicule_fournisseur_input_modele_' + idVF).val();
    let vehicule_fournisseur_boite = $('#vehicule_fournisseur_input_boite_' + idVF).val();
    let vehicule_fournisseur_carburant = $('#vehicule_fournisseur_input_carburant_' + idVF).val();
    let vehicule_fournisseur_date_mec = $('#vehicule_fournisseur_input_date_mec_' + idVF).val().replace(/-\w+/g, "");
    let vehicule_fournisseur_kilometrage = $('#vehicule_fournisseur_input_kilometrage_' + idVF).val();
    let vehicule_fournisseur_puissance_din = $('#vehicule_fournisseur_input_puissance_din_' + idVF).val();

    // console.log("------------------------------------------------------------");
    // console.log("vfDataCompilationGenerator -> Données récupérées dans les champs :");
    // console.log(vehicule_fournisseur_marque);
    // console.log(vehicule_fournisseur_modele);
    // console.log(vehicule_fournisseur_boite);
    // console.log(vehicule_fournisseur_carburant);
    // console.log(vehicule_fournisseur_date_mec); // Plus ou moins 1 an
    // console.log(vehicule_fournisseur_kilometrage); // Plus ou moins 20000
    // console.log(vehicule_fournisseur_puissance_din); // Plus ou moins 20
    // console.log("------------------------------------------------------------");

    let optionsRecherche = new FormData();
    optionsRecherche.append('id', idVF);
    optionsRecherche.append('marque', vehicule_fournisseur_marque);
    optionsRecherche.append('modele', vehicule_fournisseur_modele);
    optionsRecherche.append('boite', vehicule_fournisseur_boite);
    optionsRecherche.append('carburant', vehicule_fournisseur_carburant);
    optionsRecherche.append('date_mec', vehicule_fournisseur_date_mec);
    optionsRecherche.append('kilometrage', vehicule_fournisseur_kilometrage);
    optionsRecherche.append('puissance_din', vehicule_fournisseur_puissance_din);

    $.ajax({
        type: 'POST',
        url: siteURL + 'admin/vehicule_fournisseur/update/loadStudyList',
        contentType: false,
        processData: false,
        data: optionsRecherche,
        success: function (data) {
            // On met à jour le nombre de véhicule similaire en stock dans la card
            let nbVehiculeSimilaireEnStock = (data.nbVehiculeSimilaireEnStock ? data.nbVehiculeSimilaireEnStock.length : 0)
            $('#vehicule_fournisseur-nb-vehicule-similaire-en-stock').html(nbVehiculeSimilaireEnStock);
            $('#vehicule_fournisseur-evolution-vente3Mois-content').data('donneegraph', JSON.parse(data.donneegraph));
            initLineEvolutionVente3Mois();

            $("#vehicule_fournisseur_study_list_" + idVF).html(data.html);

            let columnDefsVehicule_fournisseur_VS_table_ = [
                { "targets": [9], "orderable": false }
            ];
            columnDefsVehicule_fournisseur_VS_table_.push(...getColumnDefsType("vehicule_fournisseur_VS_table_" + idVF))
            $('#vehicule_fournisseur_VS_table_' + idVF).DataTable({
                "autoWidth": false,
                "searching": false,
                "paging": false,
                "info": false,
                "responsive": true,
                "conditionalPaging": true,
                "order": [[1, "desc"]],
                "columnDefs": columnDefsVehicule_fournisseur_VS_table_,
            });

            let columnDefsVehicule_fournisseur_VV_table_ = [
                { "targets": [9], "orderable": false }
            ];
            columnDefsVehicule_fournisseur_VV_table_.push(...getColumnDefsType("vehicule_fournisseur_VV_table_" + idVF))
            $('#vehicule_fournisseur_VV_table_' + idVF).DataTable({
                "autoWidth": false,
                "searching": false,
                "paging": false,
                "info": false,
                "responsive": true,
                "conditionalPaging": true,
                "order": [[1, "desc"]],
                "columnDefs": columnDefsVehicule_fournisseur_VV_table_,
            });

            let columnDefsVehicule_fournisseur_VF_table_ = [
                { "targets": [9, 10], "orderable": false }
            ];
            columnDefsVehicule_fournisseur_VF_table_.push(...getColumnDefsType("vehicule_fournisseur_VF_table_" + idVF))
            $('#vehicule_fournisseur_VF_table_' + idVF).DataTable({
                "autoWidth": false,
                "searching": false,
                "paging": false,
                "info": false,
                "responsive": true,
                "conditionalPaging": true,
                "order": [[0, "desc"]],
                "columnDefs": columnDefsVehicule_fournisseur_VF_table_,
            });
        }
    });
}

function updateMarque(e) {
    // Pour les offres fournisseurs
    let idOF = "";
    if ($('#offre_fournisseur_information_container').length == 1) {
        idOF = $(e.target).attr('data-idof');
    }

    let idMarque = $(e.target).val();
    let listOptionsModele = "";
    // Pour les offres fournisseurs
    if ($('#offre_fournisseur_information_container').length == 1) {
        listOptionsModele = document.querySelectorAll("#liste_vehicule_fournisseur_offre_fournisseur_" + idOF + " select#" + e.target.id.replace("marque", "modele") + " option");
    } else {
        listOptionsModele = document.querySelectorAll("select#" + e.target.id.replace("marque", "modele") + " option");
    }

    for (let index = 0; index < listOptionsModele.length; index++) {
        if ($(listOptionsModele[index]).attr("data-marque") == idMarque) {
            $(listOptionsModele[index]).removeClass("d-none");
        } else {
            $(listOptionsModele[index]).addClass("d-none");
        }
    }

    // Pour les offres fournisseurs
    if ($('#offre_fournisseur_information_container').length == 1) {
        if (e.target.id.replace("marque", "modele") != 'vehicule_fournisseur_input_modele___id__') {
            $("#liste_vehicule_fournisseur_offre_fournisseur_" + idOF + " #" + e.target.id.replace("marque", "modele")).prop('disabled', false);
            $("#liste_vehicule_fournisseur_offre_fournisseur_" + idOF + " #" + e.target.id.replace("marque", "modele")).val('').selectpicker('refresh');
        }
    } else {
        $("#" + e.target.id.replace("marque", "modele")).prop('disabled', false);
        $("#" + e.target.id.replace("marque", "modele")).val('').selectpicker('refresh');
    }
}

function updateCO2(e) {
    let idVF = e.target.id.replace("vehicule_fournisseur_input_date_mec_", "");
    // Pour les offres fournisseurs
    let idOF = "";
    if ($('#offre_fournisseur_information_container').length == 1) {
        if (idVF != "__id__") {
            idOF = $(e.target).attr('data-idof');
            // On check si on a bien noté la date en jj/mm/aaaa en regardant si l'année et au début
            if ($(e.target).val().match(/^(\d{4})(-|\/)(\d{2})(-|\/)(\d{2})/g)) {
                $(e.target).val($(e.target).val().replace(/^(\d{4})(-|\/)(\d{2})(-|\/)(\d{2})/g, '$5/$3/$1'))
            }
            // On remplace les tirets dans la date par des slash
            $(e.target).val($(e.target).val().replaceAll('-', '/'));
            $("#liste_vehicule_fournisseur_offre_fournisseur_" + idOF + " #btnGroupNormeCO2_" + idVF).html((new Date($(e.target).val()).getTime() - new Date("2020-02-29").getTime()) <= 0 ? "NEDC" : "WLTP");
            // Pour mettre à jour le champ année
            let annee = $(e.target).val().match(/\d{4}/g);
            $("#liste_vehicule_fournisseur_offre_fournisseur_" + idOF + ' #vehicule_fournisseur_input_year_' + idVF).val(annee)
            // Pour refresh la date dans le result
            $("#liste_vehicule_fournisseur_offre_fournisseur_" + idOF + " #result_vehicule_fournisseur_input_date_mec_" + idVF).html(new Date($(e.target).val()).toLocaleDateString());
        }
    } else {
        $("#btnGroupNormeCO2_" + idVF).html((new Date($(e.target).val()).getTime() - new Date("2020-02-29").getTime()) <= 0 ? "NEDC" : "WLTP");
        // Pour mettre à jour le champ année
        $('#vehicule_fournisseur_input_year_' + idVF).val(new Date($(e.target).val()).getFullYear())
        // Pour refresh la date dans le result
        $("#result_vehicule_fournisseur_input_date_mec_" + idVF).html(new Date($(e.target).val()).toLocaleDateString());
    }
}

function updateDevise(e) {
    let idVF = e.target.id.replace("vehicule_fournisseur_input_fournisseur_", "");
    if ($(e.target).val() != "") {
        $("#btnGroupDevise_" + idVF).html("<div css-tooltip>" + $($(e.target)[0].selectedOptions[0]).attr("data-devisename") + "<div css-tooltip class='text-nowrap'> 1 EUR = " + $($(e.target)[0].selectedOptions[0]).attr("data-devise") + " " + $($(e.target)[0].selectedOptions[0]).attr("data-devisename") + "</div></div>");
        // Update du symbole de la monnaire
        $('#vehicule_fournisseur-symbole-devise-buying-price-local-money').html($($(e.target)[0].selectedOptions[0]).attr("data-devisesymbole"));
        // Update du nom du pays dans la carte
        $("#vehicule_fournisseur_pays_" + idVF).html($($(e.target)[0].selectedOptions[0]).attr("data-nompays"));
        // Update du drapeau du pays dans la carte
        let srcInitial = $("#vehicule_fournisseur_drapeau_" + idVF).attr("src");
        let srcChange = srcInitial.replace(/(\w{2}.png)/g, $($(e.target)[0].selectedOptions[0]).attr("data-codepays") + ".png");
        $("#vehicule_fournisseur_drapeau_" + idVF).attr("src", srcChange);
        // Update du mail
        $("#vehicule_fournisseur_mail_" + idVF).html($($(e.target)[0].selectedOptions[0]).attr("data-mail"));
        // Update du téléphone
        $("#vehicule_fournisseur_telephone_" + idVF).html($($(e.target)[0].selectedOptions[0]).attr("data-telephone"));
    }
}

function updateAvailable(e) {
    let idVF = e.target.id.replace("vehicule_fournisseur_input_is_visible_pro_", "");
    if ($(e.target).val() == "on") {
        $("#vehicule_fournisseur_input_is_envoi_" + idVF).val("on");
        $("#vehicule_fournisseur_input_is_envoi_" + idVF).prop('checked', true).change();
    } else {
        $("#vehicule_fournisseur_input_is_envoi_" + idVF).val("off");
        $("#vehicule_fournisseur_input_is_envoi_" + idVF).prop('checked', false).change();
    }
}

function vfEtatModify(form, etat, menu = false) {
    form.append('etat', etat);
    if (form.get("etat") == "achete") {
        let id = null;
        if (!menu) {
            if (form.get("id_vehicules")) {
                id = form.get("id_vehicules");
            } else {
                id = form.get("id_offres");
            }
        }
        const typeAchat = menu ? $('#menu-type_achat') : $('#type_achat_' + id);
        const nomClient = menu ? $('#menu-nom_client_marchand') : $('#nom_client_marchand_' + id);
        form.append('type_achat', typeAchat.val());
        if (typeAchat.val() == "achat_client_marchand") {
            if (nomClient.val() == "") {
                typeAchat.val("achat_stock");
                nomClient.val("");
                nomClient.addClass("d-none");
                toastr.error('Le nom du client marchand est obligatoire.', 'Erreur');
                return false;
            }
            form.append('nom_client_marchand', nomClient.val());
        }
    }
    vfEtatModifySend(form, menu);
}

function vfEtatModifySend(form, menu) {
    $.ajax({
        type: 'POST',
        url: siteURL + 'admin/vehicule_fournisseur/update/etatModify',
        contentType: false,
        processData: false,
        data: form,
        success: function (data) {
            // Reset
            if (menu) { $('#menu-type_achat').val("achat_stock"); $('#menu-nom_client_marchand').val(""); $('#menu-nom_client_marchand').addClass("d-none"); }
            // Error
            if (data.incomplete.annuaire.length) { toastr.error('The following VFs don\'t have a provider :<br>vf-' + data.incomplete.annuaire.join("<br>vf-"), 'Error'); }
            if (data.incomplete.documents.length) { toastr.error('The provider\'s documents was not complete, then the action was unsuccessful for the following VFs :<br>vf-' + data.incomplete.documents.join("<br>vf-"), 'Error'); }
            if (data.incomplete.expertise.length) { toastr.error('The following VFs don\'t have a complete expertise :<br>vf-' + data.incomplete.expertise.join("<br>vf-"), 'Error'); }
            if (data.incomplete.statut.length) { toastr.error('The following VFs don\'t have a status :<br>vf-' + data.incomplete.statut.join("<br>vf-"), 'Error'); }
            if (data.incomplete.desachete.length) { toastr.error('The following VFs can\'t be unbuy :<br>vf-' + data.incomplete.desachete.join("<br>vf-"), 'Error'); }
            // Success
            if (data.complete.length) {
                if (form.get("etat") == "achete") {
                    toastr.success('The following VFs has been successfully purchased and you can find it on "Véhicules fournisseur achetés" :<br>vf-' + data.complete.join("<br>vf-"), 'Success');
                } else {
                    toastr.success('VF status modified', 'Success');
                }
                $("[id$='-tabs-list-tab']").trigger("click");
            }
        },
        error: function () {
            toastr.error('Error when modifying VF status', 'Error');
        }
    });
}

// Pour afficher la modal de changement de fournisseur
function vfOpenModalChangementFournisseur(e) {
    let idVF = e.target.id.replace("vehicule_fournisseur_input_fournisseur_", "");
    // Pour éviter d'ouvrir la modal au lancement de la page, on va checker si l'input a bien changé en regardant sa valeur et la valeur de l'id fournisseur du véhicule
    let idFournisseurActuel = $('#vehicule_fournisseur-actual-fournisseur-' + idVF).attr('data-idfournisseuractual');
    let idFournisseurChange = $('#vehicule_fournisseur_input_fournisseur_' + idVF).val();
    if (idFournisseurActuel != idFournisseurChange) {
        // Affiche la modal
        $('#vehicule_fournisseur-modal-changement-fournisseur-' + idVF).modal('show');
        // Change le nom dans la modal du fournisseur choisi
        let nomFournisseurChange = $('#vehicule_fournisseur_input_fournisseur_' + idVF).find('option[value="' + idFournisseurChange + '"]').html().trim();
        $('#vehicule_fournisseur-name-change-fournisseur-' + idVF).html(nomFournisseurChange);
    }
    // Pour mettre à jour la devise et le drapeau
    updateDevise(e);
}

// Pour remettre la valeur du select du fournisseur au fournisseur initiale si on ferme la modal sans sauvegarder
function vfCloseModalChangementFournisseur(e) {
    let idVF = e.target.id.replace("vehicule_fournisseur-modal-changement-fournisseur-", "");
    let idFournisseurActuel = $('#vehicule_fournisseur-actual-fournisseur-' + idVF).attr('data-idfournisseuractual');
    if ($('#vehicule_fournisseur-change-fournisseur-accepte-' + idVF).attr('data-changefournisseuraccepte') == 0) {
        // Si on annule le changement, repasse le select sur le fournisseur actuel du VF
        $('#vehicule_fournisseur_input_fournisseur_' + idVF).val(idFournisseurActuel).selectpicker('refresh').trigger('change');
    } else if ($('#vehicule_fournisseur-change-fournisseur-accepte-' + idVF).attr('data-changefournisseuraccepte') == 1) {
        // Si on a accepté le changement, on repasse l'attribut à 0 pour si on rechange de fournisseur après
        $('#vehicule_fournisseur-change-fournisseur-accepte-' + idVF).attr('data-changefournisseuraccepte', 0);
    }
}

function vfChangeFournisseur(e) {
    let idVF = e.target.id.replace("vehicule_fournisseur-change-fournisseur-accepte-", "");

    // On change l'attribut qui permet d'accepter ou non le changement de fournisseur
    $('#vehicule_fournisseur-change-fournisseur-accepte-' + idVF).attr('data-changefournisseuraccepte', 1);
    // On change les attributs de fournisseur actuel (nom et id) pour le fournisseur changé
    $('#vehicule_fournisseur-actual-fournisseur-' + idVF).attr('data-idfournisseuractual', $('#vehicule_fournisseur_input_fournisseur_' + idVF).val());
    let nomFournisseurChange = $('#vehicule_fournisseur_input_fournisseur_' + idVF).find('option[value="' + $('#vehicule_fournisseur_input_fournisseur_' + idVF).val() + '"]').html().trim();
    $('#vehicule_fournisseur-actual-fournisseur-' + idVF).html(nomFournisseurChange);

    // On check si on a si le VF est relié a des OF pour le supprimer des OF
    if ($('#vehicule_fournisseur-list-of-deleting-vf-' + idVF).length) {
        // On va supprimer le VF sur ces OF
        $('#vehicule_fournisseur-list-of-deleting-vf-' + idVF).children().each(function () {
            let idOF = $(this).attr('data-idof');
            let formVFDeleteOnOF = new FormData();
            formVFDeleteOnOF.append('idOF', idOF);
            formVFDeleteOnOF.append('idVF', idVF);
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/achat/offre_fournisseur/delete_vf_on_of',
                contentType: false,
                processData: false,
                data: formVFDeleteOnOF,
                success: function () {
                    toastr.success('Supplier vehicle delete on the OF-' + idOF + '.', 'Success');
                },
            });
        });
    }
    $('#vehicule_fournisseur-modal-changement-fournisseur-' + idVF).modal('hide');
    $('#vehicule_fournisseur-send-modify-' + idVF).trigger('click');
}

// Fonction pour initialiser le graphique de vente des 3 derniers mois
function initLineEvolutionVente3Mois() {
    let dateFinGraph = moment().startOf('day');

    // On va chercher les données pour construire les datas du graphique si on en a
    let dataGraphique = "";
    if ($('#vehicule_fournisseur-evolution-vente3Mois-content').data('donneegraph') != []) {
        dataGraphique = fillMissingMonths(
            $('#vehicule_fournisseur-evolution-vente3Mois-content').data('donneegraph'),
            moment($('#vehicule_fournisseur-evolution-vente3Mois-content').data('datedebut'), "DD/MM/YYYY"),
            dateFinGraph);
    }

    // Si on a 0 leads/offres, on rajoute à aujourd'hui à 0
    if (dataGraphique.length == 0) {
        dataGraphique.push({ t: dateFinGraph, y: 0 });
    }

    // On détermine le max pour ajouter un au graphique
    let maxY = Math.max(...dataGraphique.map(d => d.y));

    let configEvolutionVente3Mois = {
        type: 'line',
        data: {
            datasets: [{
                data: dataGraphique,
                backgroundColor: "#CFE2FF",
                borderColor: "#4285F4",
                lineTension: 0,
            }]
        },
        options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
                display: false,
            },
            scales: {
                xAxes: [{
                    type: 'time',
                    time: {
                        unit: 'month',
                        tooltipFormat: 'MMM YYYY',
                        displayFormats: {
                            month: 'MMM YYYY',
                        },
                    },
                    ticks: { // Permet de faire le graphique de la première valeur à aujourd'hui
                        min: moment(dataGraphique[0].t),
                        max: dateFinGraph,
                        display: false,
                    },
                    gridLines: {
                        display: false,
                    },
                }],
                yAxes: [{
                    ticks: {
                        beginAtZero: true,
                        display: false,
                        max: (maxY + 1),
                    },
                    gridLines: {
                        display: false,
                    },
                }],
            },
        }
    };

    new Chart(document.getElementById('vehicule_fournisseur-evolution-vente3Mois-chartjs').getContext('2d'), configEvolutionVente3Mois);
}