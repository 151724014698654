$(document).ready(function() {

    // Génération de la datatable des generateur reseaux sociaux
    reseauxsociaux_table = $('#reseauxsociaux_table').DataTable({
        "autoWidth": false,
        "language": {
            "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/fr-FR.json"
        },
        "responsive": true,
        "ajax": {
            "url": siteURL + 'json/vehicules',
            "type": "POST",
        },
        "order": [
            [0, "desc"]
        ],
        "columnDefs": [
            // TEXT CENTER
            {
                "className": "text-center align-middle",
                "targets": [4,5,6,7,8,9,10,11],
            },
            // ALIGN MIDDLE
            {
                "className": "align-middle",
                "targets": '_all',
            },
            // ID
            {
                "name": "id",
                "targets": 0,
                "render": function(data, type, row) {
                    return '<a href="'+siteURL+row[0]+'" id="display_vehicule_'+row[0]+'"><i class="fad fa-eye pr-1"></i></a> '+row[0];
                },
            },
            // MARQUE
            {
                "name": "marque",
                "targets": 1,
                "render": function(data, type, row) {
                    return row[3];
                },
            },
            // MODELE
            {
                "name": "modele",
                "targets": 2,
                "render": function(data, type, row) {
                    return row[4];
                },
            },
            // FINITION
            {
                "targets": 3,
                "render": function(data, type, row) {
                    return row[5];
                },
            },
            // ETAT
            {
                "targets": 4,
                "render": function(data, type, row) {
                    var kilometrage = row[7];
                    if (kilometrage <= 100) {
                        return 'Neuf';
                    } else {
                        return 'Occasion';
                    }
                    // return row[7];
                },
            },
            // KILOMETRAGE
            {
                "targets": 5,
                "render": function(data, type, row) {
                    return row[7] + " km";
                },
            },
            // EMPLACEMENT
            {
                "targets": 6,
                "render": function(data, type, row) {
                    if (row[11] == null) {
                        return "En arrivage";
                    } else {
                        return row[11];
                    }

                },
            },
            // DISPONIBLE
            {
                "targets": 7,
                "render": function(data, type, row) {
                    if (row[12] == null) {
                        return '<i class="fas fa-check" style="color:green" title="Non Validé"></i><span class="d-inline d-lg-none pl-2">OUI</span>';
                    } else {
                        return '<i class="fas fa-times" style="color:red" title="Non Validé"></i><span class="d-inline d-lg-none pl-2">NON</span>';
                    }
                },
            },
            // PHOTO
            {
                "width": "25px",
                "targets": 8,
                "render": function(data, type, row) {
                    if (row[13] == "") {
                        return '<i class="fas fa-circle" style="color:red" title="Non Validé"></i><span class="d-inline d-lg-none pl-2">NON</span>';
                    } else {
                        return '<i class="fas fa-circle" style="color:green" title="Validé"></i><span class="d-inline d-lg-none pl-2">OUI</span>';
                    }
                },
                //"orderable": false,
            },
            // FACEBOOK
            {
                "width": "25px",
                "targets": 9,
                "render": function(data, type, row) {
                    if (row[14] == 1) {
                        return '<i class="fas fa-circle" style="color:green" title="Validé"></i><span class="d-inline d-lg-none pl-2">OUI</span>';
                    } else {
                        return '<i class="fas fa-circle" style="color:red" title="Non Validé"></i><span class="d-inline d-lg-none pl-2">NON</span>';
                    }
                    return '<i class="fas fa-circle" style="color:red" title="Non Validé"></i><span class="d-inline d-lg-none pl-2">NON</span>';
                },
                //"orderable": false,
            },
            // INSTAGRAM
            {
                "width": "25px",
                "targets": 10,
                "render": function(data, type, row) {
                    if (row[15] == 1) {
                        return '<i class="fas fa-circle" style="color:green" title="Validé"></i><span class="d-inline d-lg-none pl-2">OUI</span>';
                    } else {
                        return '<i class="fas fa-circle" style="color:red" title="Non Validé"></i><span class="d-inline d-lg-none pl-2">NON</span>';
                    }
                    return '<i class="fas fa-circle" style="color:red" title="Non Validé"></i><span class="d-inline d-lg-none pl-2">NON</span>';
                },
                // "orderable": false,
            },
            // MODIFY
            {
                "width": "25px",
                "targets": 11,
                "render": function(data, type, row) {
                    return '<button class="btn w-100 p-0" id="reseauxsociaux-open-modify-' + row[0] + '" data-category="outils"><i class="fad fa-edit"></i>';
                },
                "orderable": false,
            },
        ],
        "conditionalPaging": true,
    });


    //Envoi de modification reseauxsociaux
    $('body').on('click', "button[id^='reseauxsociaux-send-modify-']", function() {
        var clickedId = this.id.split(/[- ]+/).pop();
        if (makeSureFields(this.id) == true) {
            var form = new FormData();
            // On extrait le contenu du formulaire dans la variable 'form'
            form.append('id', clickedId);
            form.append('facebook', $('#reseauxsociaux_facebook_' + clickedId).val());
            form.append('instagram', $('#reseauxsociaux_instagram_' + clickedId).val());

            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/outils/reseauxsociaux/modify',
                contentType: false,
                processData: false,
                data: form,
                success: function(data) {
                    if (data == "exists") {
                        toastr.error('Erreur', 'Erreur');
                    }
                    else {
                        if (clickedId == 0) {
                            toastr.success('Succès', 'Succès');
                            // $('#formprospect_0')[0].reset();
                        }
                        else {
                            toastr.success('Les réseaux sociaux ont bien été enregistrés', 'Succès');
                            $('#reseauxsociaux-tabs-modify-' + clickedId).remove();
                            $('#reseauxsociaux-close-modify-' + clickedId).parent().remove();
                        }
                        reseauxsociaux_table.ajax.reload();
                        $('#reseauxsociaux-tabs-list-tab').trigger('click');
                    }
                }
            });
        }
        return false;
    });


    // Recharge la table
    $('[id^="reseauxsociauxtable-"').on( 'change keyup', function () {
        column = this.id.replace('reseauxsociauxtable-', '');
        // Au changement de la marque afficher les modeles correspondants
        if (column == 1) {
            var marque = this.value;
            if (marque != "") {
                var form = new FormData();
                form.append('marque', marque);

                $.ajax({
                    type: 'POST',
                    url: siteURL + "json/modeles_marque",
                    data: form,
                    processData: false,
                    contentType: false,
                    success: function(data, status) {
                        $("#reseauxsociauxtable-2").empty();
                        $("#reseauxsociauxtable-2").append('<option value=>Tous les modèles</option>');
                        for (id in data) {
                            if (data[id]['nb'] > 0) {
                                $("#reseauxsociauxtable-2").append("<option value='" + id + "'>" + id + "</option>");
                            }
                        };
                        $("#reseauxsociauxtable-2").val('default').selectpicker("refresh");
                    }
                });
                reseauxsociaux_table.column(2).search( '' ).draw()
                reseauxsociaux_table.column( column ).search(
                    '^'+$(this).val()+'$',
                    true,
                    false,
                ).draw();
            }
            else {
                reseauxsociaux_table.column(1).search( '' ).draw();
                $("#reseauxsociauxtable-2").empty();
                $("#reseauxsociauxtable-2").append('<option value=>Tous les modèles</option>');
                $("#reseauxsociauxtable-2").val('default').selectpicker("refresh");
                reseauxsociaux_table.column(2).search( '' ).draw();
            }

        }
        else if (column == 0) {
            reseauxsociaux_table.column(0).search( $(this).val() ).draw();
        }
        else {
            if (this.value != "") {
                reseauxsociaux_table.column( column ).search(
                    '^'+$(this).val()+'$',
                    true,
                    false,
                ).draw();
            } else {
                reseauxsociaux_table.column(column).search( '' ).draw();
            }

        }
    } );

    // RESET
    $('#reseauxsociauxtable-reset').on('click', function() {
        $('#reseauxsociaux-tablesearch').trigger("reset");
        $("#reseauxsociauxtable-1").val('default').selectpicker("refresh");
        $("#reseauxsociauxtable-2").val('default').selectpicker("refresh");
        $("#reseauxsociauxtable-4").val('default').selectpicker("refresh");
        $("#reseauxsociauxtable-6").val('default').selectpicker("refresh");
        reseauxsociaux_table.columns().search( '' ).draw();
        return false;
    })

    // Randomiser le text dans le textarea
    $('body').on ('click', 'button[id^="grs-random-"]', function ()
    {
        id_clicked  = this.id.split('-')[2];
        // data[0] : remise neuf
        // data[1] : lien voiture
        // data[2] : différents hastags
        // data[3] : emplacement
        // data[4] : telephone
        // data[5] : id vehicule
        var data = $('#grs-random-'+id_clicked).data('value');
        $("body #area_"+data[5]).val(randomText(data)); // Place le texte dans le texetarea en fonction de l'id (data[5])
    })


    // Copier le text du textarea
    $('body').on ('click', 'button[id^="grs-paste-"]', function ()
    {
        $('[id^="area_"]').select(); //this puts the textarea for the id labeled 'area'
        document.execCommand( 'copy' );
        toastr.success('Copié dans le presse papier', 'Succès');
    })

    // Fonction aussi présente mais ne marche pas si pas présente ici
    var finaly;
    var accroche = [
        "🚘🚘🚘 Nouvel arrivage ORA7 🚘🚘🚘",
        "🔥🔥🔥 Offre à ne pas manquer 🔥🔥🔥",
        "⭐⭐⭐ Exclusivité ORA7 ⭐⭐⭐",
        "⭐⭐⭐ Nouveauté ORA7 ⭐⭐⭐",
        "💙💙💙 Le choix ORA7 💙💙💙",
        "🔥🔥🔥 À ne pas manquer 🔥🔥🔥",
        "💙💙💙 La sélection ORA7 💙💙💙",
        "🔷🔷🔷 ORA7 et son offre 🔷🔷🔷",
        "✔✔✔ Validé par ORA7 ✔✔✔",
        "💎💎💎 Le bijou ORA7 💎💎💎",
        "✨✨✨ L'étincelle ORA7 ✨✨✨",
        "❄❄❄ Offre givrée ❄❄❄",
    ];
    var emoticon = ["👉", "🌐"];
    var urlORA = ["Rendez vous sur www.ora7.fr", "Découvrez notre stock sur www.ora7.fr", "Toutes nos voitures sur www.ora7.fr"];
    function randomText(info){
        console.log(info);
        var randomAccroche = accroche[Math.floor(Math.random()*accroche.length)];
        finaly = randomAccroche + "\n";
        finaly += info[8]+"\n";
        if (info[0] != 0) {
            var randomRemise = randomRemiseNeuf(info[0]);
            finaly += randomRemise + "\n";
        }

        // finaly += "\n"+urlORA[Math.floor(Math.random()*urlORA.length)];
        finaly += emoticon[Math.floor(Math.random()*emoticon.length)]+" "+info[1]+"\n";
        finaly += "\n"+info[2]+"\n";
        if (info[3] != null && info[3] != "" && info[3] != " ") {
            finaly += "\n🏠 ORA7 "+info[3];
        }
        if (info[7] != null && info[7] != "" && info[7] != " ") {
            finaly += "\n👥 Contactez "+info[7];
        }
        if (info[4] != null && info[4] != "" && info[4] != " ") {
            finaly += "\n📞 "+info[4];
        }
        if (info[3] != null && info[3] != "" && info[3] != " " && info[6] != "") {
            finaly += "\n🕔 " + info[6];
        }
        return finaly;
    }

    function randomRemiseNeuf(remise) {
        var number = Math.floor((Math.random() * 4) + 1); // Random de 0 a 1
        switch(number) {
            case 1:
                var textRemise = "🚗🚗🚗 -"+remise+"% de remise sur le catalogue ! 🚗🚗🚗";
                break;
            case 2:
                var textRemise = "⚡⚡⚡ -"+remise+"% de remise sur le catalogue ! ⚡⚡⚡";
                break;
            case 3:
                var textRemise = "🚙🚙🚙 -"+remise+"% de remise sur le catalogue ! 🚙🚙🚙";
                break;
            case 4:
                var textRemise = "🚘🚘🚘 -"+remise+"% de remise sur le catalogue ! 🚘🚘🚘";
                break;
            default:
                var textRemise = "🚗🚗🚗 -"+remise+"% de remise sur le catalogue ! ⚡⚡⚡";
                break;
        }
        return textRemise;
    }

});