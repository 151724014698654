const dialogToastrDelay = 3000;

/**
 * @function dialogToastrTimeout
 * @param {jQuery} dialog
 * @param {string} id
 * @summary This function is used to display toastr error message
 */
function dialogToastrTimeout(dialog, id) {
    setTimeout((dialog) => { dialog.find("#" + id).removeClass("show"); }, dialogToastrDelay, dialog);
}

/**
 * @function dialogToastrError
 * @param {jQuery} dialog
 * @param {string} name
 * @param {string} msg
 * @summary This function is used to display toastr error message
 */
function dialogToastrError(dialog, name, msg) {
    dialog.find("#" + name + "-error-msg").html("Erreur :<br/>" + msg);
    dialog.find("#" + name + "-error").addClass("show");
    dialog.find("#" + name + "-error").trigger("click");
    dialogToastrTimeout(dialog, name + "-error");
}

/**
 * @function dialogToastrSuccess
 * @param {jQuery} dialog
 * @param {string} name
 * @param {string} msg
 * @summary This function is used to display toastr success message
 */
function dialogToastrSuccess(dialog, name, msg) {
    dialog.find("#" + name + "-success-msg").html(msg);
    dialog.find("#" + name + "-success").addClass("show");
    dialog.find("#" + name + "-success").trigger("click");
    dialogToastrTimeout(dialog, name + "-success");
}
