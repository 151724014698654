if ($("#widget-maj-prix").length) {
    /**
     * Fonction de loading
     * @param {Object} reload
     */
    function widgetMajPrixAjax() {
        // Requête ajax
        $.ajax({
            type: 'POST',
            url: siteURL + 'admin/widget/maj-prix',
            contentType: false,
            processData: false,
            success: function (data) {
                if (data != null) {
                    $("#maj-prix-results-div").html(data);
                }

                // On appelle la requête du prochain widget
                nextWidgetRequeteAjax();
            }
        });
    }
};