$('body').on('change', "#background_pro_upload", function(e){

    var form = new FormData();
    form.append('data', $(this)[0].files[0]);

    $.ajax({
        url: siteURL + 'admin/outils/backgroundpro/upload_background',
        type: 'POST',
        cache: false,
        contentType: false,
        processData: false,
        data: form,
        success: function(data) {;
            // data deviens un tableau
            var data = data.split(" ; ");
            if (data[0] != "error") {
                toastr.success('La photo a bien été envoyée', "Succès");

                // Afficher l'image en dessous
            	$('#background_pro_result').slideUp( 'slow', function() {;
                        $('#background_pro_result').html('<div class="w-100 text-center"><img src="'+siteURL+data[0]+'" class="mt-2 img-fluid border border-secondary rounded-sm"></div>')
            			$('#background_pro_result').slideDown();
            		});
                $('#background_pro_file').html(data[0]);
                // Afficher l'image avec les autres
                $('#all-background').append('<div class="col-12 col-md-4"><img src="'+siteURL+data[0]+'" data-bandeau="'+data[0]+'" class="cursor-pointer col-12 pb-1 background_exists"><p class="text-center">'+data[1]+'<i class="fas fa-trash float-right mr-5" id="delete_bg_'+data[1].toLowerCase()+'" data-id="'+data[1].toLowerCase()+'"></i></p></div>');
                // Cacher le texte de fond
                $('#no-background').hide();
            }
            else {
                toastr.error('Une erreur est survenue', data[1]);
            }
        },
    });
});

$('body').on('click', '[id^=delete_bg_]', function(e){

    var url_delete = $(this).data("id");
    var form = new FormData();
    form.append('url_delete', this.id.replace('delete_bg_', ''));

    $.ajax({
        url: siteURL + 'admin/outils/backgroundpro/delete_background',
        type: 'POST',
        cache: false,
        contentType: false,
        processData: false,
        data: form,
        success: function(data) {;
            // data deviens un tableau

            var data = data.split(" ; ");
            if (data[0] != "error") {
                toastr.success('La photo a bien été supprimée', "Succès");

                // Supprime l'image supprimer
                $('#delete_'+data[1].replace(".jpg", "")).parent().parent().remove();
                // Cacher le dernier resultat
                $('#background_pro_result').hide();
                $("#background_pro_upload").val("");
            }
            else {
                toastr.error('Une erreur est survenue', data[1]);
            }
        },
    });
});