$('body').on('change', "#reception_input_vin_photo", function (e) {
    const [file] = this.files;
    let resizedImageBlob;
    var fr = new FileReader();
    fr.onload = function (e) {
        var img = new Image();
        img.onload = function () {
            // Créer un canvas pour redimensionner l'image
            var canvas = document.createElement('canvas');
            var ctx = canvas.getContext('2d');
            // Définir les nouvelles dimensions
            if (img.width > img.height) {
                canvas.width = 854;
                canvas.height = img.height * 854 / img.width;
            } else {
                canvas.width = 480;
                canvas.height = img.height * 480 / img.width;
            }
            // Dessiner l'image redimensionnée dans le canvas
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
            // Convertir le canvas en un blob
            canvas.toBlob(function (blob) {
                resizedImageBlob = blob;
                // Créer un nouveau fichier à partir du blob redimensionné
                let newFile = new File([blob], file.name, { type: file.type });
                newFile = new File([newFile], newFile.name.replace(new RegExp("(.+)[.](.+[^.])", "g"), "$1").slugify() + "." + newFile.name.replace(new RegExp("(.+)[.](.+[^.])", "g"), "$2").slugify(), { type: newFile.type });

                // Utilisation de l'API OCR pour lire l'imaage
                // Code repris de https://ocr.space/OCRAPI#js
                //Prepare form data
                let formData = new FormData();
                formData.append("file", newFile);
                formData.append("language", "eng");
                formData.append("apikey", "K85533975988957"); // Clé API gratuite
                formData.append("OCREngine", 2) // Laisser le moteur 2, car plus performant dans notre cas
                // On envoie l'image à l'API OCR
                $.ajax({
                    url: "https://api.ocr.space/parse/image",
                    data: formData,
                    dataType: 'json',
                    cache: false,
                    contentType: false,
                    processData: false,
                    type: 'POST',
                    success: function (ocrParsedResult) {
                        let resultatOCR = ocrParsedResult["ParsedResults"];
                        let text = "";
                        if (resultatOCR != null) {
                            $.each(resultatOCR, function (index, value) {
                                if (value["ParsedText"].length > text.length) {
                                    text = value["ParsedText"];
                                }
                            });
                            // On met le VIN trouvé par l'OCR dans l'input
                            $('#reception_input_vin').val(text);
                        } else {
                            console.log("errorMessage :", ocrParsedResult["ErrorMessage"]);
                            console.log("errorDetails :", ocrParsedResult["ErrorDetails"]);
                            toastr.error("Erreur avec l'OCR, essayez de recommencer.", 'Error');
                        }
                    }
                });

            }, "image/jpg");
        }
        img.src = e.target.result;
    }
    fr.readAsDataURL(file);
});
