function DOMSubtreeModified(node, callback) {
    const mutationObserver = new MutationObserver((mutations) => {
        let hasMutation = false;
        for (let mutation of mutations) { hasMutation = hasMutation || mutation.type === 'childList' || mutation.type === 'attributes'; }
        if (hasMutation) {
            callback(node, mutationObserver);
        }
    });
    mutationObserver.observe(node, { attributes: true, childList: true, subtree: true });
    return mutationObserver;
}

function onClassChange(node, callback) {
    let lastClassString = node.classList.toString();
    const mutationObserver = new MutationObserver((mutationList) => {
        for (const item of mutationList) {
            if (item.attributeName === "class") {
                const classString = node.classList.toString();
                if (classString !== lastClassString) {
                    callback(mutationObserver);
                    lastClassString = classString;
                    break;
                }
            }
        }
    });
    mutationObserver.observe(node, { attributes: true });
    return mutationObserver;
}

function onValueChange(node, callback) {
    let lastValue = node.value.toString();
    const mutationObserver = new MutationObserver((mutationList) => {
        for (const item of mutationList) {
            if (item.attributeName === "value") {
                const value = node.value.toString();
                if (value !== lastValue) {
                    callback(mutationObserver);
                    lastValue = value;
                    break;
                }
            }
        }
    });
    mutationObserver.observe(node, { attributes: true });
    return mutationObserver;
}

function onOpenChange(node, callback) {
    let lastOpen = node.open;
    const mutationObserver = new MutationObserver((mutationList) => {
        for (const item of mutationList) {
            if (item.attributeName === "open") {
                const open = node.open;
                if (open !== lastOpen) {
                    callback(mutationObserver);
                    lastOpen = open;
                    break;
                }
            }
        }
    });
    mutationObserver.observe(node, { attributes: true });
    return mutationObserver;
}

moment.updateLocale('fr', {
    months : [
        "Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet",
        "Août", "Septembre", "Octobre", "Novembre", "Décembre"
    ],
    monthsShort : [
        "Janv", "Févr", "Mars", "Avr", "Mai", "Juin",
        "Juill", "Août", "Sept", "Oct", "Nov", "Déc"
    ]
});

$(document).ready( function () {
    $(window).on("click", function (event) {
        if (!event.target.id.startsWith("colvisConfig") && !event.target.className.startsWith("toast-")) {
            if ($(".control-sidebar-slide-open").length == 1) {
                if ($(event.target).parents("aside").length == 0) {
                    $("#close-sidebar-right").trigger('click');
                }
            }
        }
        // Défocus de la barre de recherche
        if ($(event.target).parents("#search-box").length == 0) {
            document.getElementById("search-input").parentElement.classList.remove("show");
            document.getElementById("search-results").classList.remove("show");
            document.getElementById("search-have-result").classList.add("d-none");
            document.getElementById("search-no-result").classList.add("d-none");
        }
        // Vérification de la connexion de l'utilisateur
        if (window.location.href.includes("/admin")) {
            $.ajax({
                type: 'POST',
                url: siteURL + 'lost-session',
                success: function (data) {
                    if (parseInt(data)) {
                        window.open(siteURL + 'login', '_self');
                    }
                }
            });
        }
        // Fix de passage à un onglet déjà sélectionné
        if (event.target.getAttribute("data-toggle") == "pill" && event.target.getAttribute("role") == "tab") {
            $(event.target).removeClass('active');
            $(event.target).tab('show');
        }
    });

	// Jquery UI
    $( ".sortable" ).sortable({ revert: true });
    $( "ul, li" ).disableSelection();

    // Affiche les champs adapté du formulaire
    $('body').on('click', ".custom-switch > input", function() {
        if ($(this).val() == "on") {
            $(this).val("off");
        } else {
            $(this).val("on");
        }
    });

    $('body').on('click', "input[type='checkbox'][data-card-sticky]", function() {
        const el = $(this);
        const card = $($(this).parents(".card")[0]);
        if (el.prop("checked")) { card.attr("data-card-sticky", el.attr("data-card-sticky")); }
        else { card.removeAttr("data-card-sticky"); }
    });

    $('body').on('click', 'a[id^="display_vehicule_"]', function() {
        var lobibox_display_vehicule = Lobibox.window({
            width: 1000,
            title: 'Véhicule #'+this.id.replace('display_vehicule_',''),
            baseClass: "modal-content modal-lobistrap",
            url: siteURL+'admin/vehicules/display/'+this.id.replace('display_vehicule_',''),
            showAfterLoad: false,
        });
        return false;
    });

    $('body').on('click', 'a[id^="display_v_fournisseur"]', function() {
        var lobibox_display_vehicule = Lobibox.window({
            width: 1000,
            title: 'Véhicule #vf-'+this.id.replace('display_v_fournisseur',''),
            baseClass: "modal-content modal-lobistrap",
            url: siteURL+'admin/vehicules_fournisseur/display/'+this.id.replace('display_v_fournisseur',''),
            showAfterLoad: false,
        });
        return false;
    });

    $('body').on('click', 'a[id^="display_en_v_fournisseur"]', function() {
        var lobibox_display_vehicule = Lobibox.window({
            width: 1000,
            title: 'Véhicule #vf-'+this.id.replace('display_en_v_fournisseur',''),
            baseClass: "modal-content modal-lobistrap",
            url: siteURL+'admin/vehicules_fournisseur/display_en/'+this.id.replace('display_en_v_fournisseur',''),
            showAfterLoad: false,
        });
        return false;
    });

    accueil_table = $('#lastvehicules_table').DataTable({
        "autoWidth": false,
        "language": {
            "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/fr-FR.json"
        },
        "responsive": true,
        "order": [
            [10, "desc"], [1, "desc"]
        ],
        "columnDefs": [
            // TEXT CENTER
            {
                "className": "text-center align-middle",
                "targets": [0,1,5,6,7,8,9,10],
            },
            // ALIGN MIDDLE
            {
                "className": "align-middle",
                "targets": '_all',
            },
            // PHOTO
            {
                "width": "25px",
                "targets": 0,
                "orderable": false,
            },
        ],
        "conditionalPaging": true,
        "initComplete": function() {
            $(this).show();
            $('#lastvehicules_table_loading').remove();
            $('#lastvehicules_collapse').click();
        }
    });

	// Konami Code
    // Permets en cas d'appui sur  ▲▲▼▼◄►◄►BA de renverser l'écran à 180°
	var _0x1afa=['keyCode','rotate(180deg)','css','.wrapper','transform','split','keydown','round','length','none'];(function(_0x35a04f,_0x1afafa){var _0x575b3b=function(_0x30b32a){while(--_0x30b32a){_0x35a04f['push'](_0x35a04f['shift']());}};_0x575b3b(++_0x1afafa);}(_0x1afa,0x145));var _0x575b=function(_0x35a04f,_0x1afafa){_0x35a04f=_0x35a04f-0x0;var _0x575b3b=_0x1afa[_0x35a04f];return _0x575b3b;};var k=[0x26,0x26,0x28,0x28,0x25,0x27,0x25,0x27,0x42,0x41],n=0x0;$(document)[_0x575b('0x1')](function(_0x46c0ed){if(_0x46c0ed[_0x575b('0x5')]===k[n++]){matrix=$(_0x575b('0x8'))[_0x575b('0x7')](_0x575b('0x9'));if(matrix!==_0x575b('0x4')){var _0x578c1b=matrix[_0x575b('0x0')]('(')[0x1]['split'](')')[0x0][_0x575b('0x0')](',');var _0xcd3544=_0x578c1b[0x0];var _0x1c4dec=_0x578c1b[0x1];var _0x5eb3f4=Math[_0x575b('0x2')](Math['atan2'](_0x1c4dec,_0xcd3544)*(0xb4/Math['PI']));}else{var _0x5eb3f4=0x0;}if(n===k[_0x575b('0x3')]){if(_0x5eb3f4!=0xb4){$(_0x575b('0x8'))[_0x575b('0x7')](_0x575b('0x9'),_0x575b('0x6'));}else{$(_0x575b('0x8'))[_0x575b('0x7')](_0x575b('0x9'),'rotate(0deg)');}n=0x0;return![];}}else{n=0x0;}});

	// Permets de rajouter un champ éditable dans l'administration
	// Exemple, ne pas oublier de respecter le type des ID :
	// <div class="input-group">
	// 		<input type="input" class="form-control" id="user_login_689" disabled>
	// 		<div class="input-group-append">
	// 			<span class="input-group-text btn" id="user_login_fieldmodify_689">
	//				<i class="fas fa-unlock-alt"></i>
	// 			</span>
	// 		</div>
	// </div>
    $('body').on('click', "[id*='_fieldmodify_']", function() {
        $(this).children().toggleClass("fa-unlock-alt fa-lock")
        $('#'+this.id.split('_')[0]+'_'+this.id.split('_')[1]+'_'+this.id.split('_')[3]).attr('disabled', function(_, attr){ return !attr});
    });

    // Permets un rafraichissement du datatable en cliquant sur l'onglet général
    $('body').on('click', "[id*='-tabs-list-tab']", function() {
        var_clicked = this.id.split('-')[0];
        // Pas de refresh pour le générateur de réseaux sociaux
        if (var_clicked != 'reseauxsociaux' && var_clicked != 'prospect' && var_clicked != 'devise' && var_clicked != 'pays_opt' && var_clicked != 'raccourcis_opt' && var_clicked != 'vehicule_fournisseur' && var_clicked != 'offre_fournisseur' && var_clicked != 'annuaire' && var_clicked != 'vehicule_fournisseur_listing' && var_clicked != 'freCategorie' && var_clicked != 'frePrestation' && var_clicked != 'freElement' && var_clicked != 'freForfait' && var_clicked != 'facture') {
            eval(var_clicked+'_table').ajax.reload();
        }
    });

    // Permets d'ouvrir un nouvel onglet
    // Nécessite que l'id soit de la forme "user-open-modify-689"
    $('body').on('click', "[id*='-open-']", function() {
        id_clicked = this.id.split('-')[3];
		function_clicked = this.id.split('-')[2];
        var_clicked = this.id.split('-')[0];
        var_category = $(this).data('category')+"/";

        // temp
        if (var_category == "undefined/") { var_category = ""; }

        var modify = "";
        if (var_clicked == 'prospect') { var modify = "un prospect";}
        if (var_clicked == 'avisclients') { var modify = "un avis client";}
        if (var_clicked == 'carrosserie') { var modify = "une carrosserie";}
        if (var_clicked == 'slider') { var modify = "un slider";}
        if (var_clicked == 'changelog') { var modify = "un changelog";}
        if (var_clicked == 'actualite') { var modify = "une actualité";}
        if (var_clicked == 'evenement') { var modify = "un évènement";}
        if (var_clicked == 'centre') { var modify = "un centre";}
        if (var_clicked == 'user') { var modify = "un utilisateur";}
        if (var_clicked == 'offreemploi') { var modify = "une offre d'emploi";}
        if (var_clicked == 'pager') { var modify = "un pager";}
        if (var_clicked == 'categorie') { var modify = "une catégorie";}
        if (var_clicked == 'marque') { var modify = "une marque";}
        if (var_clicked == 'reseauxsociaux') { var modify = "#"+id_clicked;}
        if (var_clicked == 'ora7tv') { var modify = "une chaîne";}
        if (var_clicked == 'motorisation') { var modify = "une motorisation";}
        if (var_clicked == 'etat') { var modify = "un état";}

        // On vérifie que le tab en question n'existe pas déjà
        if ($("#"+var_clicked+"-tabs-modify-"+id_clicked+"-tab").length == 0) {
            if (var_clicked == 'optionspro_admin_categorie') {
                $('#optionspro_admin-tabs').append('<li class="nav-item"><a class="nav-link" id="optionspro_admin-tabs-modify-'+id_clicked+'-tab" data-toggle="pill" href="#optionspro_admin-tabs-modify-'+id_clicked+'" role="tab" aria-controls="optionspro_admin-tabs-modify-'+id_clicked+'" aria-selected="false"><i class="pr-2 fal fa-pen"></i>Modifier <span class="d-none d-md-inline">' + modify + '</span><i class="p-2 fal fa-times" id="optionspro_admin-close-modify-'+id_clicked+'"></i></a></li>');
            }
            else if (var_clicked == 'ora7tv') {
                $('#tv-tabs').append('<li class="nav-item"><a class="nav-link" id="tv-tabs-modify-'+id_clicked+'-tab" data-toggle="pill" href="#tv-tabs-modify-'+id_clicked+'" role="tab" aria-controls="tv-tabs-modify-'+id_clicked+'" aria-selected="false"><i class="pr-2 fal fa-pen"></i>Modifier <span class="d-none d-md-inline">' + modify + '</span><i class="p-2 fal fa-times" id="tv-close-modify-'+id_clicked+'"></i></a></li>');
            }
            else if (var_clicked == 'devise') {
                $('#devise-tabs').append('<li class="nav-item"><a class="nav-link" id="devise-tabs-modify-'+id_clicked+'-tab" data-toggle="pill" href="#devise-tabs-modify-'+id_clicked+'" role="tab" aria-controls="devise-tabs-modify-'+id_clicked+'" aria-selected="false"><i class="pr-2 fal fa-pen"></i>Modifier <span class="d-none d-md-inline">' + modify + '</span><i class="p-2 fal fa-times" id="devise-close-modify-'+id_clicked+'"></i></a></li>');
            }
            else if (var_clicked == 'pays_opt') {
                $('#pays_opt-tabs').append('<li class="nav-item"><a class="nav-link" id="pays_opt-tabs-modify-'+id_clicked+'-tab" data-toggle="pill" href="#pays_opt-tabs-modify-'+id_clicked+'" role="tab" aria-controls="pays_opt-tabs-modify-'+id_clicked+'" aria-selected="false"><i class="pr-2 fal fa-pen"></i>Modifier <span class="d-none d-md-inline">' + modify + '</span><i class="p-2 fal fa-times" id="pays_opt-close-modify-'+id_clicked+'"></i></a></li>');
            }
            else if (var_clicked == 'raccourcis_opt') {
                $('#raccourcis_opt-tabs').append('<li class="nav-item"><a class="nav-link" id="raccourcis_opt-tabs-modify-'+id_clicked+'-tab" data-toggle="pill" href="#raccourcis_opt-tabs-modify-'+id_clicked+'" role="tab" aria-controls="raccourcis_opt-tabs-modify-'+id_clicked+'" aria-selected="false"><i class="pr-2 fal fa-pen"></i>Modifier <span class="d-none d-md-inline">' + modify + '</span><i class="p-2 fal fa-times" id="raccourcis_opt-close-modify-'+id_clicked+'"></i></a></li>');
            }
            else if (var_clicked == 'vehicule_fournisseur') {
                $('#vehicule_fournisseur-tabs').append('<li class="nav-item"><a class="nav-link" id="vehicule_fournisseur-tabs-modify-'+id_clicked+'-tab" data-toggle="pill" href="#vehicule_fournisseur-tabs-modify-'+id_clicked+'" role="tab" aria-controls="vehicule_fournisseur-tabs-modify-'+id_clicked+'" aria-selected="false"><i class="pr-2 fal fa-pen"></i>vf-'+id_clicked+' <span class="d-none d-md-inline">' + modify + '</span><i class="p-2 fal fa-times" id="vehicule_fournisseur-close-modify-'+id_clicked+'"></i></a></li>');
            }
            else if (var_clicked == 'offre_fournisseur') {
                if (this.getAttribute("disabled") == null) {
                    $('#offre_fournisseur-tabs').append('<li class="nav-item"><a class="nav-link" id="offre_fournisseur-tabs-modify-'+id_clicked+'-tab" data-toggle="pill" href="#offre_fournisseur-tabs-modify-'+id_clicked+'" role="tab" aria-controls="offre_fournisseur-tabs-modify-'+id_clicked+'" aria-selected="false"><i class="pr-2 fal fa-pen"></i>of-'+id_clicked+' <span class="d-none d-md-inline">' + modify + '</span><i class="p-2 fal fa-times" id="offre_fournisseur-close-modify-'+id_clicked+'"></i></a></li>');
                    $('#offre_fournisseur_loader').removeClass('d-none');
                    $('.card-body').addClass('d-none');
                } else {
                    toastr.error('The OF cannot be modified because the following VFs are sent to the trade :<br>vf-' + this.getAttribute("disabled").split(",").join("<br>vf-"), 'Error');
                    return;
                }
            }
            else if (var_clicked == 'annuaire') {
                $('#annuaire-tabs').append('<li class="nav-item"><a class="nav-link" id="annuaire-tabs-modify-'+id_clicked+'-tab" data-toggle="pill" href="#annuaire-tabs-modify-'+id_clicked+'" role="tab" aria-controls="annuaire-tabs-modify-'+id_clicked+'" aria-selected="false"><i class="pr-2 fal fa-pen"></i>'+$($("[id='annuaire-open-modify-"+id_clicked+"']")[1]).text().trim()+' <span class="d-none d-md-inline">' + modify + '</span><i class="p-2 fal fa-times" id="annuaire-close-modify-'+id_clicked+'"></i></a></li>');
            }
            else if (var_clicked == 'facture') {
                $('#facture-tabs').append('<li class="nav-item"><a class="nav-link" id="facture-tabs-modify-'+id_clicked+'-tab" data-toggle="pill" href="#facture-tabs-modify-'+id_clicked+'" role="tab" aria-controls="facture-tabs-modify-'+id_clicked+'" aria-selected="false"><i class="pr-2 fal fa-pen"></i>'+id_clicked+' <span class="d-none d-md-inline">' + modify + '</span><i class="p-2 fal fa-times" id="facture-close-modify-'+id_clicked+'"></i></a></li>');
            }
            else {
                $('#'+var_clicked+'-tabs').append('<li class="nav-item"><a class="nav-link" id="'+var_clicked+'-tabs-modify-'+id_clicked+'-tab" data-toggle="pill" href="#'+var_clicked+'-tabs-modify-'+id_clicked+'" role="tab" aria-controls="'+var_clicked+'-tabs-modify-'+id_clicked+'" aria-selected="false"><i class="pr-2 fal fa-pen"></i>Modifier <span class="d-none d-md-inline">' + modify + '</span><i class="p-2 fal fa-times" id="'+var_clicked+'-close-modify-'+id_clicked+'"></i></a></li>');
            }
            $.ajax({
                type: 'POST',
                url: siteURL+'admin/'+var_category+var_clicked+'/show_modify',
                data: { id: id_clicked},
                success: function(data) {
                    if (var_clicked == 'optionspro_admin_categorie') {
                        $('#optionspro_admin-tabs-list').after('<div class="tab-pane fade" id="optionspro_admin-tabs-modify-'+id_clicked+'" role="tabpanel" aria-labelledby="optionspro_admin-tabs-modify-'+id_clicked+'-tab">'+data+'</div>');
                        $('#optionspro_admin-tabs-modify-'+id_clicked+'-tab').trigger('click');
                    }
                    else if (var_clicked == 'ora7tv') {
                        $('#tv-tabs-list').after('<div class="tab-pane fade" id="tv-tabs-modify-'+id_clicked+'" role="tabpanel" aria-labelledby="tv-tabs-modify-'+id_clicked+'-tab">'+data+'</div>');
                        $('#tv-tabs-modify-'+id_clicked+'-tab').trigger('click');

                        // Recreer le tableau pour les fenetres modal de chaque chaine
                        $.ajax({
                            type: 'POST',
                            url: siteURL+'json/ora7tv_search',
                            data: { id: id_clicked},
                            success: function(data) {
                                idVehicules = data.dataJson;
                                vehiculesAddInChaine[id_clicked] = [];
                                idVehicules.forEach(function(idVehicule) {
                                    vehiculesAddInChaine[id_clicked].push(idVehicule);
                                });

                                if (typeof vehiculetv_table === "undefined") {
                                    vehiculetv_table = new Array();
                                }
                                vehiculetv_table[id_clicked] = $('#vehiculetv_table_'+id_clicked).DataTable({
                                    "autoWidth": false,
                                    "language": {
                                        "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/fr-FR.json"
                                    },
                                    "responsive": true,
                                    "ajax": {
                                        "url": siteURL+'json/vehicules',
                                        "type": "POST",
                                    },
                                    "order": [
                                        [0, "desc"]
                                    ],
                                    "columns": [
                                        null,
                                        null,
                                        null,
                                        null,
                                        null,
                                        null,
                                        null,
                                        null,
                                        {
                                            "orderDataType": "dom-text",
                                            type: 'string',
                                        },
                                    ],
                                    "columnDefs": [
                                        // TEXT CENTER
                                        {
                                            "className": "text-center align-middle",
                                            "targets": [4,5,6,7,8],
                                        },
                                        // ALIGN MIDDLE
                                        {
                                            "className": "align-middle",
                                            "targets": '_all',
                                        },
                                        {
                                            "orderDataType": "dom-text",
                                            targets: 8,
                                        },
                                        // ID
                                        {
                                            "name": "id",
                                            "targets": 0,
                                            "render": function(data, type, row) {
                                                return row[0];
                                            },
                                        },
                                        // MARQUE
                                        {
                                            "name": "marque",
                                            "targets": 1,
                                            "render": function(data, type, row) {
                                                return row[3];
                                            },
                                        },
                                        // MODELE
                                        {
                                            "name": "modele",
                                            "targets": 2,
                                            "render": function(data, type, row) {
                                                return row[4];
                                            },
                                        },
                                        // FINITION
                                        {
                                            "targets": 3,
                                            "render": function(data, type, row) {
                                                return row[5];
                                            },
                                        },
                                        // ETAT
                                        {
                                            "targets": 4,
                                            "render": function(data, type, row) {
                                                var kilometrage = row[7];
                                                if (kilometrage <= 100) {
                                                    return 'Neuf';
                                                } else {
                                                    return 'Occasion';
                                                }
                                                // return row[7];
                                            },
                                        },
                                        // EMPLACEMENT
                                        {
                                            "targets": 5,
                                            "render": function(data, type, row) {
                                                if (row[11] == null) {
                                                    return "En arrivage";
                                                } else {
                                                    return row[11];
                                                }

                                            },
                                        },
                                        // DISPONIBLE
                                        {
                                            "targets": 6,
                                            "render": function(data, type, row) {
                                                if (row[12] == null) {
                                                    return '<i class="fas fa-check" style="color:green" title="Validé"></i><span class="d-inline d-lg-none pl-2">OUI</span>';
                                                } else {
                                                    return '<i class="fas fa-times" style="color:red" title="Non Validé"></i><span class="d-inline d-lg-none pl-2">NON</span>';
                                                }
                                            },
                                        },
                                        // PHOTO
                                        {
                                            "width": "25px",
                                            "targets": 7,
                                            "render": function(data, type, row) {
                                                if (row[13] == "") {
                                                    return '<i class="fas fa-circle" style="color:red" title="Non Validé"></i><span class="d-inline d-lg-none pl-2">NON</span>';
                                                } else {
                                                    return '<i class="fas fa-circle" style="color:green" title="Validé"></i><span class="d-inline d-lg-none pl-2">OUI</span>';
                                                }
                                            },
                                            //"orderable": false,
                                        },
                                        // ADD
                                        {
                                            "width": "25px",
                                            "targets": 8,
                                            "render": function(data, type, row) {
                                                vehiculePresent = false;
                                                idVehicules.forEach(function(idVehicule) {
                                                    if (row[0] == idVehicule) {
                                                        vehiculePresent = true;
                                                    }
                                                });

                                                if (vehiculePresent == false) {
                                                    return '<button class="btn w-100 p-0" id="vehiculetv-add-' + id_clicked + "-" + row[0] + '" title="Ajouter"><i class="fas fa-plus-circle"></i></button>';
                                                } else {
                                                    return "<button class='btn w-100 p-0' id='vehiculetv-del-" + id_clicked + "-" + row[0] + "' title='Supprimer'><i class='fas fa-check-circle' id='vehiculeadd-" + id_clicked + "-" + row[0] + "' style='color: #22E03B;'></i></button>";
                                                    vehiculePresent = false;
                                                }

                                            },
                                        },
                                    ],
                                    "conditionalPaging": true,

                                });

                                // Rafraichi les filtres pour les affiches
                                $("#vehiculetvtable-1-"+id_clicked).selectpicker({
                                    "title": "Toutes les marques"
                                }).selectpicker("refresh");
                                $("#vehiculetvtable-2-"+id_clicked).selectpicker({
                                    "title": "Tous les modèles"
                                }).selectpicker("refresh");
                                $("#vehiculetvtable-4-"+id_clicked).selectpicker({
                                    "title": "Tous les états"
                                }).selectpicker("refresh");
                                $("#vehiculetvtable-5-"+id_clicked).selectpicker({
                                    "title": "Emplacement"
                                }).selectpicker("refresh");

                                // TYPE SELECTION
                                dataTypeSelection = data.dataTypeSelection;
                                $("#vehiculetvtype-1-"+id_clicked).selectpicker({
                                    "title": "Toutes les marques"
                                }).selectpicker("refresh");
                                $("#vehiculetvtype-1-"+id_clicked).val(dataTypeSelection[0]).selectpicker("refresh");

                                // Charge les modeles si une marque a été sélectionnée
                                if (data.dataTypeSelection[0] != '' & data.dataTypeSelection[0] != null) {
                                    var form = new FormData();
                                    form.append('marque', data.dataTypeSelection[0]);

                                    $.ajax({
                                        type: 'POST',
                                        url: siteURL + "json/modeles",
                                        data: form,
                                        processData: false,
                                        contentType: false,
                                        success: function(data, status) {
                                            $('#vehiculetvtype-2-'+id_clicked).empty();
                                            $('#vehiculetvtype-2-'+id_clicked).append('<option value=>Tous les modèles</option>');
                                            for (id in data) {
                                                if (data[id]['nb'] > 0) {
                                                    $('#vehiculetvtype-2-'+id_clicked).append("<option value='" + data[id].id + "'"+(data[id].id == dataTypeSelection[1] ? "selected" : "")+">" + id + "</option>");
                                                }
                                            };
                                            $('#vehiculetvtype-2-'+id_clicked).selectpicker("refresh");
                                        }
                                    });
                                } else {
                                    $("#vehiculetvtype-2-"+id_clicked).selectpicker({
                                        "title": "Tous les modèles"
                                    }).selectpicker("refresh");
                                }

                                $("#vehiculetvtype-3-"+id_clicked).selectpicker({
                                    "title": "Tous les états"
                                }).selectpicker("refresh");
                                $("#vehiculetvtype-3-"+id_clicked).val(dataTypeSelection[2]).selectpicker("refresh");

                                $("#vehiculetvtype-4-"+id_clicked).selectpicker({
                                    "title": "Tous les emplacements"
                                }).selectpicker("refresh");
                                $("#vehiculetvtype-4-"+id_clicked).val(dataTypeSelection[3]).selectpicker("refresh");

                                var form = new FormData();
                                form.append('marque', dataTypeSelection[0]);
                                form.append('modele', dataTypeSelection[1]);
                                form.append('etat', dataTypeSelection[2]);
                                form.append('emplacement', dataTypeSelection[3]);
                                // Nombre de vehicule disponible
                                $.ajax({
                                    type: 'POST',
                                    url: siteURL + "annonce_nombre_vehicule",
                                    data: form,
                                    processData: false,
                                    contentType: false,
                                    success: function(data, status) {
                                        $('#resultcountvehicule-'+id_clicked).empty();
                                        $('#resultcountvehicule-'+id_clicked).text('Nombre de véhicule(s) disponible(s) : ' + data);

                                    }
                                });

                            }
                        });
                    }
                    else {
                        $('#'+var_clicked+'-tabs-list').after('<div class="tab-pane fade" id="'+var_clicked+'-tabs-modify-'+id_clicked+'" role="tabpanel" aria-labelledby="'+var_clicked+'-tabs-modify-'+id_clicked+'-tab">'+data+'</div>');
                        $('#'+var_clicked+'-tabs-modify-'+id_clicked+'-tab').trigger('click');
                    }

                    if (var_clicked == 'actualite') {
                        makeSummerNote(var_clicked + '_intro_content_'+id_clicked, 100,  ['text']);
                        makeSummerNote(var_clicked + '_content_'+id_clicked, 300, ['full']);
                    }
                    if (var_clicked == 'categorie') {
                        var form = new FormData();
                        form.append('id', id_clicked);

                        $.ajax({
                            type: 'POST',
                            url: siteURL + "admin/web/categorie/information",
                            data: form,
                            processData: false,
                            contentType: false,
                            success: function(data, status) {
                                information = JSON.parse(data)
                                countCaractereTitle('categorie', information["title"], id_clicked);
                                countCaractereMetaDescription('categorie', information["meta_description"], id_clicked);
                                countCaractereBaliseH1('categorie', information["balise_h1"], id_clicked);
                                for (i = 1; i <= 2; i++) {
                                    countCaractereTagline('categorie_text_tagline_'+i, information["tagline_"+i], id_clicked);
                                }
                                countCaractereDescriptionCourte('categorie', information["description_courte"], id_clicked);
                            }
                        });
                    }
                    if (var_clicked == 'article') {
                        makeSummerNote('article_texteIntro_'+id_clicked, 200, ['full']);
                        makeSummerNote('article_contenu1_'+id_clicked, 200, ['full']);
                        makeSummerNote('article_contenu2_'+id_clicked, 200, ['full']);
                        makeSummerNote('article_contenu3_'+id_clicked, 200, ['full']);
                        makeSummerNote('article_contenu4_'+id_clicked, 200, ['full']);
                    }
                    if (var_clicked == 'article_categorie') {
                        makeSummerNote('article_categorie_texteIntro_'+id_clicked, 200, ['full']);
                    }
                    if (var_clicked == 'slider') {
                        makeSummerNote(var_clicked + '_content_'+id_clicked, 200, ['text']);
                    }
                    if (var_clicked == 'changelog') {
                        makeSummerNote(var_clicked + '_content_'+id_clicked, 200, ['text']);
                    }
                    if (var_clicked == 'offreemploi') {
                        makeSummerNote(var_clicked + '_content_responsabilites_'+id_clicked, 200, "");
                        makeSummerNote(var_clicked + '_content_qualifications_'+id_clicked, 200, "");
                        makeSummerNote(var_clicked + '_content_avantages_'+id_clicked, 200, "");
                    }
                    if (var_clicked == 'pager') {
                        makeSummerNote(var_clicked + '_content_texte_'+id_clicked, 100, ['simple']);
                    }
                    if (var_clicked == 'prospect') {
                        $('select[name="prospect_marques_'+id_clicked+'"]').selectpicker();
                    }
                    if (var_clicked == 'annuaire') {
                        annuaireSetup(id_clicked);
                    }
                    if (var_clicked == 'facture') {
                        factureSetup(id_clicked);
                    }
                    if (var_clicked == 'vehicule_fournisseur' || var_clicked == 'offre_fournisseur') {
                        vfSetup(var_clicked, id_clicked);
                    }
                    if (var_clicked == 'frePrestation') {
                        selectpickerRefresh([
                            "frePrestation-categorie-",
                            "frePrestation-dynamique-"
                        ], id_clicked);
                    }
                    if (var_clicked == 'freElement') {
                        selectpickerRefresh([
                            "freElement-categorie-",
                            "freElement-exterieur-"
                        ], id_clicked);
                    }
                    if (var_clicked == 'user') {
                        $('select[name="user_centre_secondaire_'+id_clicked+'"]').selectpicker();
                    }
                    if (var_clicked == 'reseauxsociaux') {
                        var data = $('body button[id^="grs-random-"]').data('value');
                        $("#area_"+id_clicked).val(randomText(data));
                    }
                    if (var_clicked == 'clickandlease') {
                        // Check si loyer 1 existe
                        if ($("#loyer1_prixmois_"+id_clicked).val().length > 0 && $("#loyer1_mois_"+id_clicked).val().length > 0 && $("#loyer1_apport_"+id_clicked).val().length > 0 && $("#loyer1_kilometretotal_"+id_clicked).val().length > 0 && $("#loyer1_valeurrachat_"+id_clicked).val().length > 0) {
                            $("#clickandlease_clickandlease_"+id_clicked).prop('disabled', false);
                            $("#clickandlease_leboncoin_"+id_clicked).prop('disabled', false);
                            $('#validate-loyer-2-'+id_clicked).prop('disabled', false);

                            $("#clickandlease-copy-modify-"+id_clicked).prop('disabled', false);

                            $("#delete-loyer-1-"+id_clicked).removeClass("buttonDeleteFalse");
                            $("#delete-loyer-1-"+id_clicked).addClass("buttonDeleteTrue");

                            if (typeof allLoyer[id_clicked] === "undefined") {
                                allLoyer[id_clicked] = [];
                            }
                            allLoyer[id_clicked][1] = {prixMois: $("#loyer1_prixmois_"+id_clicked).val(), mois: $("#loyer1_mois_"+id_clicked).val(), apport: $("#loyer1_apport_"+id_clicked).val(), kilometreTotal: $("#loyer1_kilometretotal_"+id_clicked).val(), valeurRachat: $("#loyer1_valeurrachat_"+id_clicked).val()};
                            // Check si loyer 2 existe
                            if ($("#loyer2_prixmois_"+id_clicked).val().length > 0 && $("#loyer2_mois_"+id_clicked).val().length > 0 && $("#loyer2_apport_"+id_clicked).val().length > 0 && $("#loyer2_kilometretotal_"+id_clicked).val().length > 0 && $("#loyer2_valeurrachat_"+id_clicked).val().length > 0) {
                                $('#validate-loyer-3-'+id_clicked).prop('disabled', false);

                                $("#delete-loyer-1-"+id_clicked).removeClass("buttonDeleteTrue");
                                $("#delete-loyer-1-"+id_clicked).addClass("buttonDeleteFalse");

                                $("#delete-loyer-2-"+id_clicked).removeClass("buttonDeleteFalse");
                                $("#delete-loyer-2-"+id_clicked).addClass("buttonDeleteTrue");
                                allLoyer[id_clicked][2] = {prixMois: $("#loyer2_prixmois_"+id_clicked).val(), mois: $("#loyer2_mois_"+id_clicked).val(), apport: $("#loyer2_apport_"+id_clicked).val(), kilometreTotal: $("#loyer2_kilometretotal_"+id_clicked).val(), valeurRachat: $("#loyer2_valeurrachat_"+id_clicked).val()};
                                // Check si loyer 3 existe
                                if ($("#loyer3_prixmois_"+id_clicked).val().length > 0 && $("#loyer3_mois_"+id_clicked).val().length > 0 && $("#loyer3_apport_"+id_clicked).val().length > 0 && $("#loyer3_kilometretotal_"+id_clicked).val().length > 0 && $("#loyer3_valeurrachat_"+id_clicked).val().length > 0) {
                                    $('#validate-loyer-4-'+id_clicked).prop('disabled', false);

                                    $("#delete-loyer-2-"+id_clicked).removeClass("buttonDeleteTrue");
                                    $("#delete-loyer-2-"+id_clicked).addClass("buttonDeleteFalse");

                                    $("#delete-loyer-3-"+id_clicked).removeClass("buttonDeleteFalse");
                                    $("#delete-loyer-3-"+id_clicked).addClass("buttonDeleteTrue");
                                    allLoyer[id_clicked][3] = {prixMois: $("#loyer3_prixmois_"+id_clicked).val(), mois: $("#loyer3_mois_"+id_clicked).val(), apport: $("#loyer3_apport_"+id_clicked).val(), kilometreTotal: $("#loyer3_kilometretotal_"+id_clicked).val(), valeurRachat: $("#loyer3_valeurrachat_"+id_clicked).val()};
                                    // Check si loyer 4 existe
                                    if ($("#loyer4_prixmois_"+id_clicked).val().length > 0 && $("#loyer4_mois_"+id_clicked).val().length > 0 && $("#loyer4_apport_"+id_clicked).val().length > 0 && $("#loyer4_kilometretotal_"+id_clicked).val().length > 0 && $("#loyer4_valeurrachat_"+id_clicked).val().length > 0) {
                                        $("#delete-loyer-3-"+id_clicked).removeClass("buttonDeleteTrue");
                                        $("#delete-loyer-3-"+id_clicked).addClass("buttonDeleteFalse");

                                        $("#delete-loyer-4-"+id_clicked).removeClass("buttonDeleteFalse");
                                        $("#delete-loyer-4-"+id_clicked).addClass("buttonDeleteTrue");
                                        allLoyer[id_clicked][4] = {prixMois: $("#loyer4_prixmois_"+id_clicked).val(), mois: $("#loyer4_mois_"+id_clicked).val(), apport: $("#loyer4_apport_"+id_clicked).val(), kilometreTotal: $("#loyer4_kilometretotal_"+id_clicked).val(), valeurRachat: $("#loyer4_valeurrachat_"+id_clicked).val()};

                                    }
                                }
                            }
                        } else {
                            $("#clickandlease_clickandlease_"+id_clicked).prop('disabled', 'disabled');
                            $("#clickandlease_leboncoin_"+id_clicked).prop('disabled', 'disabled');
                        }

                        if (typeof clickandleasecopy_table === "undefined") {
                            clickandleasecopy_table = new Array();
                        }

                        // Génération de la datatable des generateur clickandlease copy
                        clickandleasecopy_table[id_clicked] = $('#clickandleasecopy_table-'+id_clicked).DataTable({
                            "autoWidth": false,
                            "language": {
                                "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/fr-FR.json"
                            },
                            "responsive": true,
                            "ajax": {
                                "url": siteURL+'json/vehicules',
                                "type": "POST",
                            },
                            "order": [
                                [0, "desc"]
                            ],
                            "columns": [
                                null,
                                null,
                                null,
                                null,
                                null,
                                null,
                                null,
                                null,
                                null,
                                {
                                    "orderDataType": "dom-checkbox",
                                },
                                {
                                    "orderDataType": "dom-checkbox",
                                },
                                null,
                            ],
                            "columnDefs": [
                                // TEXT CENTER
                                {
                                    "className": "text-center align-middle",
                                    "targets": [4,5,6,7,8,9,10,11],
                                },
                                // ALIGN MIDDLE
                                {
                                    "className": "align-middle",
                                    "targets": '_all',
                                },
                                // ID
                                {
                                    "name": "id",
                                    "targets": 0,
                                    "render": function(data, type, row) {
                                        return row[0];
                                    },
                                },
                                // MARQUE
                                {
                                    "name": "marque",
                                    "targets": 1,
                                    "render": function(data, type, row) {
                                        return row[3];
                                    },
                                },
                                // MODELE
                                {
                                    "name": "modele",
                                    "targets": 2,
                                    "render": function(data, type, row) {
                                        return row[4];
                                    },
                                },
                                // FINITION
                                {
                                    "targets": 3,
                                    "render": function(data, type, row) {
                                        return row[5];
                                    },
                                },
                                // ETAT
                                {
                                    "targets": 4,
                                    "render": function(data, type, row) {
                                        var kilometrage = row[7];
                                        if (kilometrage <= 100) {
                                            return 'Neuf';
                                        } else {
                                            return 'Occasion';
                                        }
                                        // return row[7];
                                    },
                                },
                                // KILOMETRAGE
                                {
                                    "targets": 5,
                                    "render": function(data, type, row) {
                                        return row[7] + " km";
                                    },
                                },
                                // EMPLACEMENT
                                {
                                    "targets": 6,
                                    "render": function(data, type, row) {
                                        if (row[11] == null) {
                                            return "En arrivage";
                                        } else {
                                            return row[11];
                                        }

                                    },
                                },
                                // DISPONIBLE
                                {
                                    "targets": 7,
                                    "render": function(data, type, row) {
                                        if (row[12] == null) {
                                            return '<i class="fas fa-check" style="color:green" title="Non Validé"></i><span class="d-inline d-lg-none pl-2">OUI</span>';
                                        } else {
                                            return '<i class="fas fa-times" style="color:red" title="Non Validé"></i><span class="d-inline d-lg-none pl-2">NON</span>';
                                        }
                                    },
                                },
                                // PHOTO
                                {
                                    "width": "25px",
                                    "targets": 8,
                                    "render": function(data, type, row) {
                                        if (row[13] == "") {
                                            return '<i class="fas fa-circle" style="color:red" title="Non Validé"></i><span class="d-inline d-lg-none pl-2">NON</span>';
                                        } else {
                                            return '<i class="fas fa-circle" style="color:green" title="Validé"></i><span class="d-inline d-lg-none pl-2">OUI</span>';
                                        }
                                    },
                                    //"orderable": false,
                                },
                                // LEBONCOIN
                                {
                                    "width": "25px",
                                    "targets": 9,
                                    "render": function(data, type, row) {
                                        if (row[17] == 1) {
                                            return "<input type='checkbox' id='clickandleasecopy-leboncoin-"+ row[0] +"-"+ id_clicked +"' name='leboncoin"+ row[0] +"' checked>";
                                        } else {
                                            return "<input type='checkbox' id='clickandleasecopy-leboncoin-"+ row[0] +"-"+ id_clicked +"' name='leboncoin"+ row[0] +"'>";
                                        }
                                        return "<input type='checkbox' id='clickandleasecopy-leboncoin-"+ row[0] +"-"+ id_clicked +"' name='leboncoin"+ row[0] +"'>";
                                    },
                                    //"orderable": false,
                                },
                                // CLICK AND LEASE
                                {
                                    "width": "25px",
                                    "targets": 10,
                                    "render": function(data, type, row) {
                                        if (row[16] == 1) {
                                            return "<input type='checkbox' id='clickandleasecopy-clickandlease-"+ row[0] +"-"+ id_clicked +"' name='clickandlease"+ row[0] +"' checked>";
                                        } else {
                                            return "<input type='checkbox' id='clickandleasecopy-clickandlease-"+ row[0] +"-"+ id_clicked +"' name='clickandlease"+ row[0] +"'>";
                                        }
                                        return "<input type='checkbox' id='clickandleasecopy-clickandlease-"+ row[0] +"-"+ id_clicked +"' name='clickandlease"+ row[0] +"'>";
                                    },
                                    // "orderable": false,
                                },
                                // MODIFY
                                {
                                    "width": "25px",
                                    "targets": 11,
                                    "render": function(data, type, row) {
                                        return '<button class="btn w-100 p-0" id="clickandleasecopy-paste-'+ row[0] +'-'+ id_clicked +'"><i class="far fa-copy"></i></button>';
                                    },
                                    "orderable": false,
                                },
                            ],
                            "conditionalPaging": true,
                        });

                    /* Create an array with the values of all the checkboxes in a column */
                    $.fn.dataTable.ext.order['dom-checkbox'] = function  ( settings, col )
                    {
                        return this.api().column( col, {order:'index'} ).nodes().map( function ( td, i ) {
                            return $('input', td).prop('checked') ? '1' : '0';
                        } );
                    }

                    }
                }
            });
        } else {
            //? Double click sur un open peut causer des erreurs
            //? En attendant, on met juste un toaster d'alerte d'onglet déjà ouvert
            //? Explication : le double clic causait l'ouverture de l'onglet + le clic sur l'onglet
            // if (var_clicked == 'annuaire') {
            //     console.log("kfhgdqsvj");
            //     $("#annuaire-tabs-modify-"+id_clicked+"-tab").trigger('click');
            // } else if (var_clicked == 'offre_fournisseur') {
            //         $("#offre_fournisseur-tabs-modify-"+id_clicked+"-tab").trigger('click');
            // } else if (var_clicked == 'vehicule_fournisseur') {
            //     $("#vehicule_fournisseur-tabs-modify-"+id_clicked+"-tab").trigger('click');
            // }
            toastr.error('Onglet déjà ouvert', 'Erreur');
        }
    });

    $('body').on('click', "[data-information='true'], [data-information='false']", function() {
        var type = $(this).data('information');
        var name = $(this).data('information-name');

        var h5 = $($(this).siblings()[0]);
        var p = $($(this).siblings()[1]);
        var div = $(this).parent();
        var button = $(this).parent().find('button');

        $.ajax({
            type: 'POST',
            url: siteURL+'admin/information/'+(type=="false"||type == false?'show':'hide'),
            data: { element: name},
            success: function(data) {
                switch (data) {
                    case 'shown':
                        break;
                    case 'hidden':
                        break;
                    case 'nok':
                        break;
                }
            }
        });

        if (type == "false" ||type == false) {
            div.animate({
                width: "100%",
            }, 1000, function() {
                p.slideDown( 'slow', function() {
                    h5.find('.info-title').fadeIn('fast', function () {
                        button.html("×");
                        button.data('information', 'true');
                    })
                });
            });
        } else {
            p.slideUp( 'slow', function() {
                h5.find('.info-title').fadeOut('fast', function () {
                    div.animate({
                        width: "100px",
                    }, 1000, function() {
                        button.html("&laquo;");
                        button.data('information', 'false');
                    })
                });
            });
        }
    });

    // Permets de supprimer une entité
    // Nécessite que l'id du bouton cliqué soit de la forme "user-delete-689"
    $('body').on('click', "[id*='-delete-']", function() {
        id_clicked = this.id.split('-')[2];
        function_clicked = this.id.split('-')[1];
        var_clicked = this.id.split('-')[0];
        var_category = $(this).data('category')+"/";

        // temp
        if (var_category == "undefined/") { var_category = "";}

        Lobibox.confirm({
            title: 'Suppression',
            baseClass: "modal-content modal-lobistrap",
            msg: "Êtes-vous sûr de vouloir supprimer cet élément <strong>définitivement</strong> de la base de données ? Attention, il sera <strong>impossible</strong> de le restaurer ensuite.",
            buttons : {
                ok : {
                    type: 'ok',
                    text: 'Supprimer'
                },
                cancel : {
                    type: 'cancel',
                    text: 'Conserver'
                }
            },
            callback: function(lobibox, type){
                if (type === 'ok'){
                    $.ajax({
                        type: 'POST',
                        url: siteURL+'admin/'+var_category+var_clicked+'/delete',
                        data: { id: id_clicked},
                        success: function(data) {
                            if (var_clicked == 'ora7tv') {
                                var_clicked = 'tv';
                            }
                            $('#'+var_clicked+'-tabs-modify-'+id_clicked+'-tab').parent().remove();
                            $('#'+var_clicked+'-tabs-modify-'+id_clicked).remove();
                            $('#'+var_clicked+'-tabs-list-tab').trigger('click');
                            if (var_clicked == 'devise') {
                                devise_table.ajax.reload();
                            }
                            if (var_clicked == 'pays_opt') {
                                pays_table.ajax.reload();
                            }
                            if (var_clicked == 'freFrais') {
                                let optionsRecherche = new FormData();
                                optionsRecherche.append('id', $("#stock_vehicule_page").data("id"));
                                $("#freFrais_results").addClass("d-none");
                                $.ajax({
                                    type: 'POST',
                                    url: siteURL + 'admin/fre/freFrais/ajax',
                                    contentType: false,
                                    processData: false,
                                    data: optionsRecherche,
                                    success: function (data) {
                                        $("#freFrais_results").html(data);
                                        $("#freFrais-total-update").trigger("click");
                                        $(".selectpicker").selectpicker("refresh");
                                        $("#freFrais_results").removeClass("d-none");
                                    }
                                });
                            }
                            if (var_clicked == 'raccourcis_opt') {
                                $.ajax({
                                    type: 'POST',
                                    url: siteURL + 'admin/configuration/raccourcis_opt/loadAdd',
                                    data: {},
                                    success: function(data) {
                                        $("#raccourcis_opt-tabs-add").html(data);
                                    }
                                });
                                $.ajax({
                                    type: 'POST',
                                    url: siteURL + 'admin/configuration/raccourcis_opt/majRaccourcis',
                                    data: {},
                                    success: function(data) {
                                        $("#nav-raccourcis-list").html(data);
                                    }
                                });
                            }
                        },
                        error: function(data) {
                            if (var_clicked == "annuaire") {
                                toastr.error('Le contact n\'a pas pu être supprimé.<br>Il est probable qu\'un élément comme un véhicule fournisseur empêche la suppression.', 'Erreur');
                            }
                        }
                    });
                }
            },
        });

        return false;
    });
    // Permets de fermer un onglet existant
    // Nécessite que l'id soit de la forme "user-close-modify-689"
    $('body').on('click', "[id*='-close-']", function() {
		var_clicked = this.id.split('-')[0];
		function_clicked = this.id.split('-')[2];
        id_clicked = this.id.split('-')[3];

        $(this).parent().remove();
        $('#'+var_clicked+'-tabs-'+function_clicked+'-'+id_clicked).remove();
        $('#'+var_clicked+'-tabs-list-tab').trigger('click');

        // Pas de refresh pour le générateur de réseaux sociaux
        if (var_clicked != 'reseauxsociaux' && var_clicked != 'prospect' && var_clicked != 'vehicule_fournisseur' && var_clicked != 'offre_fournisseur' && var_clicked != 'raccourcis_opt' && var_clicked != 'annuaire' && var_clicked != 'facture' && var_clicked != 'freCategorie' && var_clicked != 'frePrestation' && var_clicked != 'freElement' && var_clicked != 'freForfait' && var_clicked != 'generateurannonceclickandleasecopy') {
            eval(var_clicked+'_table').ajax.reload()
        }
    });

	// Permets de réinitialiser un formulaire
    // Nécessite que l'id du formulaire soit de la forme "formuser_689"//
	$('body').on('click', "[id*='-reinitialize-']", function() {
		var_clicked = this.id.split('-')[0];
		id_clicked = this.id.split('-')[2];

		// Specification des tailles et classes d'image
		if (var_clicked == 'user') { imagedimension = '450x450'; imageadditionalclass= 'rounded-circle';}
		else { imagedimension = '640x340'; imageadditionalclass= '';}

		$('#form'+var_clicked+'_'+id_clicked)[0].reset();
        if (var_clicked == 'tv') {
            vehiculesAddInChaine[0].forEach(function(idVehicule) {
                $("button[id^='vehiculetv-del-0-"+idVehicule+"']").replaceWith("<button class='btn w-100 p-0' id='vehiculetv-add-0-" + idVehicule + "' title='Ajouter'><i class='fas fa-plus-circle'></i></button>");
            });
            vehiculesAddInChaine[0].length = 0;
        }
		$.ajax({
			url: var_clicked+'/delete_photo',
			type: 'POST',
			data: {'id':id_clicked},
			success: function(data) {
                $("#"+var_clicked+"_photodisplay_"+id_clicked).html("<img class='img-fluid "+imageadditionalclass+"' src='https://via.placeholder.com/"+imagedimension+"'>");
                $("#"+var_clicked+"_photo_"+id_clicked).val('');
            }
        });
        toastr.success('Le formulaire a bien été réinitialisé', "Succès");
        return false;
	});

    $('body').on('keyup', "input[data-count='true'], textarea[data-count='true'] ~ .ck-editor > div > .ck-editor__editable", function() {
        // Dans le cas d'un input transformé par un CKEditor
        if ($(this).hasClass('ck-editor__editable')) {
            var ckEditor = $(this).parent().parent();
            var countObject = ckEditor.prev();
            var countCurrent = $(this).text().length;
            var counterSpan = ckEditor.next('.text-counter');
        }
        // Dans le cas d'un input classique
        else {
            var countObject = $(this);
            var countCurrent = $(this).val().length;
            var counterSpan = $(this).next('.text-counter');
        }

        var countOptimal = countObject.data("optimal");
        var countLow = countObject.data("low");
        var countHigh = countObject.data("high");
        var countMiddle = countObject.data("middle");
        var countMiddleLow = countMiddle.split(',')[0];
        var countMiddleHigh = countMiddle.split(',')[1];

        // On gère le pluriel du mot 'caractère' le cas échéant
        if (countCurrent > 1) { countPluriel = "s";} else { countPluriel = "";}
        var countText = countCurrent+'/'+countOptimal+' <span class="d-none d-md-inline">caractère'+countPluriel+'</span>';

        // On affiche le compteur si le nombre de caractères est supérieur à 0
        if (countCurrent != 0) {counterSpan.html(countText);}
        // Si l'input est vide alors pas besoin d'afficher le compteur
        else {counterSpan.html("");}

        // almost ok
        if (countCurrent >= countLow && countCurrent < countMiddleLow || countCurrent > countMiddleHigh && countCurrent <= countHigh) {
            counterSpan.removeClass('bg-danger');
            $(this).removeClass('border-danger');
            counterSpan.removeClass('bg-success');
            $(this).removeClass('border-success');
            counterSpan.addClass('bg-orange');
            $(this).addClass('border-orange');
        }
        // it's ok
        else if (countCurrent >= countMiddleLow && countCurrent <= countMiddleHigh) {
            counterSpan.removeClass('bg-danger');
            $(this).removeClass('border-danger');
            counterSpan.removeClass('bg-orange');
            $(this).removeClass('border-orange');
            counterSpan.addClass('bg-success');
            $(this).addClass('border-success');
        }
        // too high or too low
        else if (countCurrent < countLow || countCurrent > countHigh) {
            counterSpan.removeClass('bg-orange');
            $(this).removeClass('border-orange');
            counterSpan.removeClass('bg-green');
            $(this).removeClass('border-green');
            counterSpan.addClass('bg-danger');
            $(this).addClass('border-danger');
        }
        else {
            counterSpan.removeClass('bg-orange');
            $(this).removeClass('border-orange');
            counterSpan.removeClass('bg-green');
            $(this).removeClass('border-green');
            counterSpan.removeClass('bg-danger');
            $(this).removeClass('border-danger');
        }
    });

    $('body').on('change', "textarea", checkEgalPremierCaractere)

    $('body').on('dragover', ".drag-and-drop", function (e) { e.preventDefault(); });
    $('body').on('drop', ".drag-and-drop", function (e) {
        e.preventDefault();
        if (e.dataTransfer === undefined) { e.dataTransfer = e.originalEvent.dataTransfer; }
        if (e.dataTransfer !== undefined) {
            let idInputCible = "";
            if (e.target.tagName.toLowerCase() !== "label") {
                idInputCible = $(e.target).parents("label").attr("for");
            } else {
                idInputCible = e.target.htmlFor;
            }

            if (e.dataTransfer.items) {
                [...e.dataTransfer.items].forEach((item, i) => { if (item.kind === "file") { importFileInInput(item.getAsFile(), idInputCible); } });
            } else {
                [...e.dataTransfer.files].forEach((file, i) => { importFileInInput(file, idInputCible); });
            }
            $("#" + idInputCible).trigger("change");
        }
    });

    // Pour enlever les espaces au début et à la fin des inputs de texte
    $('body').on('focusout', "input[type='text'], input[type='url']", function() {
        $(this).val($(this).val().trim());
    });

    var finaly;
    var accroche = [
        "🚘🚘🚘 Nouvel arrivage ORA7 🚘🚘🚘",
        "🔥🔥🔥 Offre à ne pas manquer 🔥🔥🔥",
        "⭐⭐⭐ Exclusivité ORA7 ⭐⭐⭐",
        "⭐⭐⭐ Nouveauté ORA7 ⭐⭐⭐",
        "💙💙💙 Le choix ORA7 💙💙💙",
        "🔥🔥🔥 À ne pas manquer 🔥🔥🔥",
        "💙💙💙 La sélection ORA7 💙💙💙",
        "🔷🔷🔷 ORA7 et son offre 🔷🔷🔷",
        "✔✔✔ Validé par ORA7 ✔✔✔",
        "💎💎💎 Le bijou ORA7 💎💎💎",
        "✨✨✨ L'étincelle ORA7 ✨✨✨",
        "❄❄❄ Offre givrée ❄❄❄",
    ];
    var emoticon = ["👉", "🌐"];
    var urlORA = ["Rendez vous sur www.ora7.fr", "Découvrez notre stock sur www.ora7.fr", "Toutes nos voitures sur www.ora7.fr"];
    function randomText(info){
        var randomAccroche = accroche[Math.floor(Math.random()*accroche.length)];
        finaly = randomAccroche + "\n";
        finaly += info[8]+"\n";
        if (info[0] != 0) {
            var randomRemise = randomRemiseNeuf(info[0]);
            finaly += randomRemise + "\n";
        }

        // finaly += "\n"+urlORA[Math.floor(Math.random()*urlORA.length)];
        finaly += emoticon[Math.floor(Math.random()*emoticon.length)]+" "+info[1]+"\n";
        finaly += "\n"+info[2]+"\n";
        if (info[3] != null && info[3] != "" && info[3] != " ") {
            finaly += "\n🏠 ORA7 "+info[3];
        }
        if (info[7] != null && info[7] != "" && info[7] != " ") {
            finaly += "\n👥 Contactez "+info[7];
        }
        if (info[4] != null && info[4] != "" && info[4] != " ") {
            finaly += "\n📞 "+info[4];
        }
        if (info[3] != null && info[3] != "" && info[3] != " " && info[6] != "") {
            finaly += "\n🕔 " + info[6];
        }
        return finaly;
    }

    function randomRemiseNeuf(remise) {
        var number = Math.floor((Math.random() * 4) + 1); // Random de 0 a 1
        switch(number) {
            case 1:
                var textRemise = "🚗🚗🚗 -"+remise+"% de remise sur le catalogue ! 🚗🚗🚗";
                break;
            case 2:
                var textRemise = "⚡⚡⚡ -"+remise+"% de remise sur le catalogue ! ⚡⚡⚡";
                break;
            case 3:
                var textRemise = "🚙🚙🚙 -"+remise+"% de remise sur le catalogue ! 🚙🚙🚙";
                break;
            case 4:
                var textRemise = "🚘🚘🚘 -"+remise+"% de remise sur le catalogue ! 🚘🚘🚘";
                break;
            default:
                var textRemise = "🚗🚗🚗 -"+remise+"% de remise sur le catalogue ! ⚡⚡⚡";
                break;
        }
        return textRemise;
    }

    function countCaractereTitle(var_categorie, title, id_clicked) {
        if (title != "" && title != null) {
            number = title.length;
            $('#'+var_categorie+'_text_title_'+id_clicked).text(number+"/63 caractère"+(number >= 2 ? "s" : ""));
            if (number >= 40 && number < 55 || number > 72 && number <= 90) {
                $('#'+var_categorie+'_text_title_'+id_clicked).css('color', 'orange');
            } else if (number >= 55 && number <= 72) {
                $('#'+var_categorie+'_text_title_'+id_clicked).css('color', 'green');
            } else {
                $('#'+var_categorie+'_text_title_'+id_clicked).css('color', 'red');
            }
        }
    }

    function countCaractereMetaDescription(var_categorie, meta_description, id_clicked) {
        if (meta_description != "" && meta_description != null) {
            number = meta_description.length;
            $('#'+var_categorie+'_text_meta_description_'+id_clicked).text(number+"/154 caractère"+(number >= 2 ? "s" : ""));
            if (number >= 100 && number < 130 || number > 170 && number <= 200) {
                $('#'+var_categorie+'_text_meta_description_'+id_clicked).css('color', 'orange');
            } else if (number >= 130 && number <= 170) {
                $('#'+var_categorie+'_text_meta_description_'+id_clicked).css('color', 'green');
            } else {
                $('#'+var_categorie+'_text_meta_description_'+id_clicked).css('color', 'red');
            }
        }
    }

    function countCaractereBaliseH1(var_categorie, balise_h1, id_clicked) {
        if (balise_h1 != "" && balise_h1 != null) {
            number = balise_h1.length;
            $('#'+var_categorie+'_text_balise_h1_'+id_clicked).text(number+"/60 caractère"+(number >= 2 ? "s" : ""));
            if (number >= 40 && number < 50 || number > 70 && number <= 80) {
                $('#'+var_categorie+'_text_balise_h1_'+id_clicked).css('color', 'orange');
            } else if (number >= 50 && number <= 70) {
                $('#'+var_categorie+'_text_balise_h1_'+id_clicked).css('color', 'green');
            } else {
                $('#'+var_categorie+'_text_balise_h1_'+id_clicked).css('color', 'red');
            }
        }
    }

    function countCaractereTagline(id_tagline, tagline, id_clicked) {
        if (tagline != "" && tagline != null) {
            number = tagline.length;
            $('#'+id_tagline+'_'+id_clicked).text(number+"/100 caractère"+(number >= 2 ? "s" : ""));
            if (number >= 60 && number < 80 || number > 120 && number <= 140) {
                $('#'+id_tagline+'_'+id_clicked).css('color', 'orange');
            } else if (number >= 80 && number <= 120) {
                $('#'+id_tagline+'_'+id_clicked).css('color', 'green');
            } else {
                $('#'+id_tagline+'_'+id_clicked).css('color', 'red');
            }
        }
    }

    function countCaractereDescriptionCourte(var_categorie, description_courte, id_clicked) {
        if (description_courte != "" && description_courte != null) {
            number = description_courte.length;
            $('#'+var_categorie+'_text_description_courte_'+id_clicked).text(number+"/400 caractère"+(number >= 2 ? "s" : ""));
            if (number >= 260 && number < 320 || number > 480 && number <= 560) {
                $('#'+var_categorie+'_text_description_courte_'+id_clicked).css('color', 'orange');
            } else if (number >= 320 && number <= 480) {
                $('#'+var_categorie+'_text_description_courte_'+id_clicked).css('color', 'green');
            } else {
                $('#'+var_categorie+'_text_description_courte_'+id_clicked).css('color', 'red');
            }
        }
    }

    function countCaractereTitre(id_titre, titre, id_clicked) {
        if (titre != "" && titre != null) {
            number = titre.length;
            $('#'+id_titre+'_'+id_clicked).text(number+"/70 caractère"+(number >= 2 ? "s" : ""));
            if (number >= 45 && number < 60 || number > 80 && number <= 85) {
                $('#'+id_titre+'_'+id_clicked).css('color', 'orange');
            } else if (number >= 60 && number <= 80) {
                $('#'+id_titre+'_'+id_clicked).css('color', 'green');
            } else {
                $('#'+id_titre+'_'+id_clicked).css('color', 'red');
            }
        }
    }

    function countCaractereContenu(id_contenu, contenu, id_clicked) {
        if (contenu != "" && contenu != null) {
            number = contenu.length;
            $('#'+id_contenu+'_'+id_clicked).text(number+"/600 caractère"+(number >= 2 ? "s" : ""));
            if (number >= 400 && number < 500 || number > 700 && number <= 800) {
                $('#'+id_contenu+'_'+id_clicked).css('color', 'orange');
            } else if (number >= 500 && number <= 700) {
                $('#'+id_contenu+'_'+id_clicked).css('color', 'green');
            } else {
                $('#'+id_contenu+'_'+id_clicked).css('color', 'red');
            }
        }
    }

    // Fonction pour trim le texte et vérifier que le premier caractère n'est pas un égal, pour éviter les erreurs d'export CSV/Excel qui pensent que c'est une formule
    function checkEgalPremierCaractere() {
        let valueTexte = $(this).val();
        // On supprime les espaces avant et après
        valueTexte = valueTexte.trim();
        // On check le premier caractères
        while (valueTexte[0] == "=") {
            // On supprime le premier caractère
            valueTexte = valueTexte.substring(1);
        }
        // On remplace la valeur du texte
        $(this).val(valueTexte);
    }

    // Pour restreindre la pagination de DataTable sur mobile
    if (window.innerWidth <= 750) {
        $.fn.DataTable.ext.pager.numbers_length = 3;
    }

    // Switch de vue de graphique au click sur le bouton de switch au sein du conteneur
    $('body').on('click', "[chart-view-container] [chart-view-switch]", function () {
        const target = this.getAttribute("chart-view-switch");
        const container = $(this).parents("[chart-view-container]");
        container.find("[chart-view-switch][chart-view-switch!='" + target + "']").addClass("btn-primary").removeClass("btn-outline-primary");
        container.find("[chart-view-switch][chart-view-switch='" + target + "']").addClass("btn-outline-primary").removeClass("btn-primary");
        container.find("[chart-view-content][chart-view-content!='" + target + "']").addClass("d-none");
        container.find("[chart-view-content][chart-view-content='" + target + "']").removeClass("d-none");
    });
});