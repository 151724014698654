/*
  /admin/vehicules/carrosseries
 */

// Génération de la datatable des prospects
carrosserie_table = $('#carrosserie_table').DataTable({
    "autoWidth": false,
    "language": {
        "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/fr-FR.json"
    },
    "responsive": true,
    "ajax": {
        "url": siteURL + 'json/carrosseries',
        "type": "POST",
    },
    "order": [
        [0, "desc"]
    ],
    "columnDefs": [
        // TEXT CENTER
        {
            "className": "text-center align-middle",
            "targets": [3,4],
        },
        // ALIGN MIDDLE
        {
            "className": "align-middle",
            "targets": '_all',
        },
        // ID
        {
            "targets": 0,
            "render": function(data, type, row) {
                return row[0];
            },
        },
        // NAME
        {
            "targets": 1,
            "render": function(data, type, row) {
                return row[1];
            },
        },
        // NAME REWRITE
        {
            "targets": 2,
            "render": function(data, type, row) {
                return row[2];
            },
        },
        // VISIBLE
        {
            "targets": 3,
            "render": function(data, type, row) {
                if (row[3] == 1) {
                    return '<i class="fas fa-check" style="color:green" title="Validé"></i><span class="d-inline d-lg-none pl-2">OUI</span>';
                } else {
                    return '<i class="fas fa-times" style="color:red" title="Non Validé"></i><span class="d-inline d-lg-none pl-2">NON</span>';
                }
            },
        },
        // MODIFY
        {
            "width": "50px",
            "targets": 4,
            "render": function ( data, type, row ) {
                return '<button class="btn w-100 p-0" id="carrosserie-open-modify-'+row[0]+'" data-category="vehicules" ><i class="fad fa-edit"></i>';
            },
            "orderable": false,
        },
    ],
    "conditionalPaging": true,
});

// Envoi de modification d'une carrosserie
$('body').on('click', "button[id^='carrosserie-send-modify-']", function() {
    var id_clicked = this.id.replace('carrosserie-send-modify-', '');

    var form = new FormData();
    // On extrait le contenu du formulaire dans la variable 'form'
    form.append('id', id_clicked);
    form.append('carrosserie_name', $('#carrosserie_name_'+id_clicked).val());
    form.append('carrosserie_rewrite', $('#carrosserie_rewrite_'+id_clicked).val());

    // Verifie la longeur du texte
    if ($("#carrosserie_name_"+id_clicked).val().length == 0) {
        toastr.error('Merci de remplir le nom', 'Erreur');
        return false;
    }
    if ($("#carrosserie_rewrite_"+id_clicked).val().length == 0) {
        toastr.error('Merci de remplir le nom rewrite', 'Erreur');
        return false;
    }

    // SEO
    form.append('carrosserie_title', $('#carrosserie_title_'+id_clicked).val());
    form.append('carrosserie_balise_h1', $('#carrosserie_balise_h1_'+id_clicked).val());
    if (!IE11) {
        form.append('carrosserie_meta_description', $('#carrosserie_meta_description_'+id_clicked).next().find('.ck-editor__editable').text());
        form.append('carrosserie_tagline_1', $('#carrosserie_tagline_1_'+id_clicked).next().find('.ck-editor__editable').html().replace(/(<p[^>]+?>|<p>|<\/p>)/img, ""));
        form.append('carrosserie_tagline_2', $('#carrosserie_tagline_2_'+id_clicked).next().find('.ck-editor__editable').html().replace(/(<p[^>]+?>|<p>|<\/p>)/img, ""));
        form.append('carrosserie_description_courte', $('#carrosserie_description_courte_'+id_clicked).next().find('.ck-editor__editable').html());

        form.append('carrosserie_contenu_1', $('#carrosserie_contenu_1_'+id_clicked).next().find('.ck-editor__editable').html());
    }
    else {
        form.append('carrosserie_meta_description', $('#carrosserie_meta_description_'+id_clicked).val());
        form.append('carrosserie_tagline_1', $('#carrosserie_tagline_1_'+id_clicked).val().replace(/(<p[^>]+?>|<p>|<\/p>)/img, ""));
        form.append('carrosserie_tagline_2', $('#carrosserie_tagline_2_'+id_clicked).val().replace(/(<p[^>]+?>|<p>|<\/p>)/img, ""));
        form.append('carrosserie_description_courte', $('#carrosserie_description_courte_'+id_clicked).val());

        form.append('carrosserie_contenu_1', $('#carrosserie_contenu_1_'+id_clicked).val());
    }


    form.append('carrosserie_is_visible', $('input[name="carrosserie_visible_' + id_clicked + '"]:checked').val());

    // Verifie la longeur du texte
    if ($("#carrosserie_title_"+id_clicked).val().length > 200) {
        toastr.error('Merci de reduire le title', 'Erreur');
        return false;
    }
    // Verifie la longeur du texte
    if ($("#carrosserie_meta_description_"+id_clicked).val().length > 249) {
        toastr.error('Merci de reduire la meta description', 'Erreur');
        return false;
    }
    // Verifie la longeur du texte
    if ($("#carrosserie_balise_h1_"+id_clicked).val().length > 200) {
        toastr.error('Merci de reduire la balise h1', 'Erreur');
        return false;
    }


    $.ajax({
        type: 'POST',
        url: siteURL + 'admin/vehicules/carrosserie/modify',
        cache: false,
        contentType: false,
        processData: false,
        data: form,
        success: function(data) {
            // TOAST
            toastr.success('La carrosserie a bien été enregistrée', 'Succès');
            // On nettoye le html en supprimant l'onglet 'Modifier/Nouveau'
            $('#carrosserie-tabs-modify-'+id_clicked).remove();
            $('#carrosserie-close-modify-'+id_clicked).parent().remove();
            if (id_clicked == 0) {
                // On réinitialise le formulaire
                $('#formcarrosserie_'+id_clicked)[0].reset();
                $('#carrosserie_contenu_3_0').summernote('reset');

                $('#carrosserie_text_title_0').text("0/63 caractère");
                $('#carrosserie_text_title_0').css('color', 'black');
                $('#carrosserie_text_meta_description_0').text("0/153 caractère");
                $('#carrosserie_text_meta_description_0').css('color', 'black');
                $('#carrosserie_text_balise_h1_0').text("0/60 caractère");
                $('#carrosserie_text_balise_h1_0').css('color', 'black');
                for (i = 0; i <= 2; i++) {
                    $('#carrosserie_text_tagline_'+i+'_0').text("0/100 caractère");
                    $('#carrosserie_text_tagline_'+i+'_0').css('color', 'black');
                }
                $('#carrosserie_text_description_courte_0').text("0/400 caractère");
                $('#carrosserie_text_description_courte_0').css('color', 'black');
            }
            // Retour à la liste d'actualités en rechargeant la datatable
            $('#carrosserie-tabs-list-tab').trigger('click');
            modele_table.ajax.reload();
        }
    });
     // Empèche le 'button' de fonctionner
    return false;
});