$(document).ready(function() {

    // Génération de la datatable des prospects
    prospectconnexion_table = $('#prospectconnexion_table').DataTable({
        "autoWidth": false,
        "language": {
            "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/fr-FR.json"
        },
        "responsive": true,
        "ajax": siteURL + 'json/prospects-connexion',
        "order": [
            [13, "desc"]
        ],
        "columnDefs": [
            // TEXT CENTER
            {
                "className": "text-center align-middle",
                "targets": '_all',
            },
            // ALIGN MIDDLE
            {
                "className": "align-middle",
                "targets": '_all',
            },
            //ID
            {
                "name": "societe",
                "targets": 0,
                "render": function(data, type, row) {
                    return row[0];
                },
            },
            //SOCIETE
            {
                "name": "societe",
                "targets": 1,
                "render": function(data, type, row) {
                    return row[1];
                },
            },
            // MAIL
            {
                "name": "mail",
                "targets": 2,
                "render": function(data, type, row) {
                    return row[2];
                },
            },
            // COMMERCIAL
            {
                "name": "commercial",
                "targets": 3,
                "render": function(data, type, row) {
                    return row[3];
                },
            },
            // DATE OUVERTURE COMPTE
            {
                "name": "date ouverture compte",
                "targets": 4,
                "render": function(data, type, row) {
                    return row[4].date.substring(0, 10);
                },
            },
            // SOURCE
            {
                "name": "source",
                "targets": 5,
                "render": function(data, type, row) {
                    return row[5];
                },
            },
            // M0
            {
                "name": "m0",
                "targets": 6,
                "render": function(data, type, row) {
                    return row[6];
                },
            },
            // M1
            {
                "name": "m1",
                "targets": 7,
                "render": function(data, type, row) {
                    return row[7];
                },
            },
            // M2
            {
                "name": "m2",
                "targets": 8,
                "render": function(data, type, row) {
                    return row[8];
                },
            },
            // M3
            {
                "name": "m3",
                "targets": 9,
                "render": function(data, type, row) {
                    return row[9];
                },
            },
            // M4
            {
                "name": "m4",
                "targets": 10,
                "render": function(data, type, row) {
                    return row[10];
                },
            },
            // M5
            {
                "name": "m5",
                "targets": 11,
                "render": function(data, type, row) {
                    return row[11];
                },
            },
            // M6
            {
                "name": "m6",
                "targets": 12,
                "render": function(data, type, row) {
                    return row[12];
                },
            },
            // Nombre de connexion
            {
                "name": "nombre de connexion",
                "targets": 13,
                "render": function(data, type, row) {
                    return row[13];
                },
            },
            // Derniere connexion
            {
                "name": "derniere connexion",
                "targets": 14,
                "render": function(data, type, row) {
                    return row[14];
                },
            },
        ],
        "conditionalPaging": true,
    });

});

$('body').on('click', "[id^='prospect_connexion_']", function() {
    exporttype = this.id.replace("prospect_connexion_", "");

    // // console.log(exporttype);

    // all_data = vehicules_listingpro_table.rows().data();

    // id_vehicules = [];

    // $.each(all_data, function(key, value) {

    //     if (value[0].includes("vehicule_listingpro_selection_") && value[0].includes("fa-check-square")) {

    //         regex = new RegExp(/id="(.*?)"/g);
    //         id = value[0].match(regex);
    //         if (id == null) {
    //             regex = new RegExp(/id='(.*?)'/g);
    //             id = value[0].match(regex);
    //         }

    //         temp = id[0].split("_");

    //         id_vehicules.push(temp[temp.length - 1].slice(0, -1));
    //     }
    // });

    // var form = new FormData();
    // form.append('ids', id_vehicules);

    today = new Date();

    switch (exporttype) {
        case 'excel':
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/outils/prospects-connexion/excel',
                contentType: false,
                processData: false,
                // data: form,
                success: function(data) {
                    toastr.success('Le fichier excel est fini', 'Succès');

                    var element = document.createElement('a');
                    element.setAttribute('href',siteURL + data);
                    element.setAttribute('download', "Liste Connexion Marchand "+today.toLocaleDateString("fr")+".xlsx");
                    document.body.appendChild(element);
                    element.click();
                    document.body.removeChild(element);

                }
            });
            break;
        default:

            break;
    }
});