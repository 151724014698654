$('body').on('click', "[id*='-dialog-'][id$='-responsive'][menu-open-close]", (e) => {
    // Is open ?
    const open = e.target.getAttribute("menu-open-close") == "open";
    // If open, then close, else open
    e.target.setAttribute("menu-open-close", open ? "close" : "open");

    // Sélectionner le parent <dialog> de l'élément cliqué
    const dialog = $(e.target).closest('dialog');
    // Si l'état est ouvert (open == true)
    if (!open && dialog.length) {
        // Créer dynamiquement le backdrop
        const backdrop = $('<div>', { class: 'modal-backdrop fade show' });
        // Ajouter le backdrop au parent <dialog>
        dialog.append(backdrop);
    } else {
        // Si on ferme le dialogue, on retire le backdrop
        dialog.find('.modal-backdrop').remove();
    }
});
