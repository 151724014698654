//filetype : image / document / ?
//

// Gestion des photos de temps
$('body').on('change', ".custom-file-input", function (e) {
    if ($(this).data("upload") == undefined) {
        var clicked = $(this)
        var clickedId = this.id.replace('file_upload_', '');
        var displayArea = $('#file_display_' + clickedId);
        var formInputArea = $('#file_result_' + clickedId);

        dataRewrite = $(this).data("rewrite");

        let file = clicked[0].files[0];
        if (!($(this).data("rename") === undefined)) {
            file = new File([file], $(this).data("rename") + "." + file.type.split("/")[1], { type: file.type });
        }

        let actuelThisJquery = $(this);

        var fr = new FileReader();
        fr.onload = function (e) {
            var img = new Image();
            img.onload = function () {
                // Créer un canvas pour redimensionner l'image
                var canvas = document.createElement('canvas');
                var ctx = canvas.getContext('2d');
                // Définir les nouvelles dimensions
                canvas.width = (img.width >= 3000 ? img.width / 4 : (img.width >= 1500 ? img.width / 2 : img.width));
                canvas.height = (img.width >= 3000 ? img.height / 4 : (img.width >= 1500 ? img.height / 2 : img.height));
                // Dessiner l'image redimensionnée dans le canvas
                ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
                // Convertir le canvas en un blob
                canvas.toBlob(function (blob) {
                    // Créer un nouveau fichier à partir du blob redimensionné
                    let newFile = new File([blob], file.name, { type: file.type });
                    newFile = new File([newFile], newFile.name.replace(new RegExp("(.+)[.](.+[^.])", "g"), "$1").slugify() + "." + newFile.name.replace(new RegExp("(.+)[.](.+[^.])", "g"), "$2").slugify(), { type: newFile.type });

                    var form = new FormData();
                    form.append('data', newFile);
                    form.append('filemime', actuelThisJquery.data("mime"));
                    form.append('filetypes', actuelThisJquery.data("filetypes"));
                    form.append('imageratio', actuelThisJquery.data("imageRatio"));
                    form.append('fileexist', actuelThisJquery.data("fileexist"));

                    $.ajax({
                        url: 'file/upload',
                        type: 'POST',
                        cache: false,
                        contentType: false,
                        processData: false,
                        data: form,
                        success: function (data) {
                            // success == true
                            if (data.success == true) {
                                toastr.success('Le fichier a bien été envoyé', "Succès");
                                switch (data.type) {
                                    case "image":
                                        displayArea.slideUp('slow', function () {
                                            if (dataRewrite != "nop") {
                                                rewrite = '<label for="file_rewrite_' + data.id + '" class="float-left"><i class="fad fa-align-justify fa-flip-vertical pl-1 pr-2"></i>Rewrite</label><div class="input-group"><div class="input-group-prepend"><span class="input-group-text">https://ora7.fr/image/</span></div><input type="input" class="form-control form-control" id="file_rewrite_' + data.id + '" value=""><div class="input-group-append"><span class="input-group-text" id="file_rewrite_validate_' + data.id + '"><i class="fal fa-arrow-alt-from-left"></i></span></div>';
                                            } else { rewrite = ''; }
                                            image = '<div class="w-100 text-center"><img'+(this.getAttribute("custom-fancy") ? ' custom-fancy="'+this.getAttribute("custom-fancy")+'"' : '')+' src="' + data.path + data.file + '" class="mt-2 img-fluid border border-secondary rounded-sm"><div class="file-info">' + data.file + ' - ' + data.image.width + " <i class=\"fal fa-times\"></i> " + data.image.height + " - " + data.size + ' bytes</div></div>';
                                            displayArea.html(rewrite + image)
                                            displayArea.slideDown();
                                        });
                                        formInputArea.val(data.id);
                                        break;
                                    case "application":
                                        displayArea.html('<div class="file-info mt-2">Fichier : <a href="' + data.path + data.file + '">' + data.file + '</a> - ' + data.size + ' bytes</div>');
                                        formInputArea.val(data.id);
                                        break;
                                }
                            }
                            // success == false
                            else {
                                switch (data.error) {
                                    case "type":
                                        var expectedType = '';
                                        data.expected.forEach(function (e) { expectedType = expectedType + "<br /> - " + e; });
                                        toastr.error('Le fichier que vous avez envoyé n\'est pas d\'un format attendu :' + expectedType, "Erreur");
                                        break;
                                    case "ratio":
                                        toastr.error('Le fichier que vous avez envoyé n\'est pas au bon ratio attendu: ' + data.expected, "Erreur");
                                        break;
                                    case "exists":
                                        toastr.info('Le fichier que vous avez envoyé existe déjà en base de données');
                                        switch (data.type) {
                                            case "image":
                                                displayArea.slideUp('slow', function () {
                                                    if (data.rewrite != null) { icon = '<i class="fal fa-check"></i>'; }
                                                    else { icon = '<i class="fal fa-arrow-alt-from-left"></i>'; }
                                                    if (dataRewrite != "nop") {
                                                        rewrite = '<label for="file_rewrite_' + data.id + '" class="float-left"><i class="fad fa-align-justify fa-flip-vertical pl-1 pr-2"></i>Rewrite</label><div class="input-group"><div class="input-group-prepend"><span class="input-group-text">https://ora7.fr/image/</span></div><input type="input" class="form-control form-control" id="file_rewrite_' + data.id + '" value="' + data.image.rewrite + '"><div class="input-group-append"><span class="input-group-text" id="file_rewrite_validate_' + data.id + '">' + icon + '</span></div>';
                                                    }
                                                    else { rewrite = ''; }
                                                    image = '<div class="w-100 text-center"><img src="' + data.path + data.file + '" class="mt-2 img-fluid border border-secondary rounded-sm"><div class="file-info">' + data.file + ' - ' + data.image.width + " <i class=\"fal fa-times\"></i> " + data.image.height + " - " + data.size + ' bytes</div></div>'
                                                    displayArea.html(rewrite + image)
                                                    displayArea.slideDown();
                                                });
                                                formInputArea.val(data.id);
                                                break;
                                            case "application":
                                                displayArea.html('<div class="file-info mt-2">Fichier : <a href="' + data.path + data.file + '">' + data.file + '</a> - ' + data.size + ' bytes</div>');
                                                formInputArea.val(data.id);
                                                break;
                                        }
                                        break;
                                    default:
                                        toastr.error('Une erreur interne est survenue, merci de contacter les développeurs.', "Erreur");
                                        break;
                                }
                            }
                        },
                    });
                }, file.type);
            }
            img.src = e.target.result;
        }
        fr.readAsDataURL(file);
    }
});

$('body').on('click', "[id^='file_remove_']", function (e) {
    clickedId = this.id.replace('file_remove_', '');
    $('#file_upload_' + clickedId).val('');
    $('#file_result_' + clickedId).val('');
    $('#file_display_' + clickedId).html('');
});

$('body').on('click', "[id^='file_choice_']", function (e) {
    clickedId = this.id.replace('file_choice_', '');
    file_upload = $('#file_upload_' + clickedId);

    chosingFile = Lobibox.window({
        title: 'Choix d\'une image',
        baseClass: "modal-content modal-lobistrap",
        width: 1200,
        url: 'file/chose',
        autoload: true,
        loadMethod: 'POST',
        //Load parameters
        params: {
            filetypes: file_upload.data("filetypes"),
            fileratio: file_upload.data("imageRatio"),
            filechoice: clickedId,
        },
    });
});

$('body').on('keypress', "[id^='file_rewrite_']", function (e) {
    clickedId = this.id.replace('file_rewrite_', '');
    console.log($('#file_rewrite_validate_' + clickedId));
    $('#file_rewrite_validate_' + clickedId).html('<i class="fal fa-arrow-alt-from-left"></i>');
});


$('body').on('click', "[id^='file_chose_']", function (e) {
    clickedId = this.id.replace('file_chose_', '');
    file_chosen = clickedId.split('-')[0];
    file_choice = clickedId.split('-')[1];

    picture_path = $(this).data('picture');
    picture_name = $(this).data('name');
    picture_width = $(this).data('width');
    picture_height = $(this).data('height');
    picture_rewrite = $(this).data('rewrite');
    picture_bytes = $(this).data('bytes');

    var displayArea = $('#file_display_' + file_choice);
    var formInputArea = $('#file_result_' + file_choice);


    displayArea.slideUp('slow', function () {
        ;
        if (picture_rewrite != null && picture_rewrite != '') { icon = '<i class="fal fa-check"></i>'; }
        else { icon = '<i class="fal fa-arrow-alt-from-left"></i>'; }

        //displayArea.html('<img src="'+ picture_path +'" class="mt-2 img-fluid border border-secondary rounded-sm"><div class="file-info">' + picture_name + ' - ' + picture_width + " <i class=\"fal fa-times\"></i> " + picture_height + " - " + picture_bytes + ' bytes</div>')
        displayArea.html('<label for="file_rewrite_' + clickedId + '" class="float-left"><i class="fad fa-align-justify fa-flip-vertical pl-1 pr-2"></i>Rewrite</label><div class="input-group"><div class="input-group-prepend"><span class="input-group-text">https://ora7.fr/image/</span></div><input type="input" class="form-control form-control" id="file_rewrite_' + clickedId + '" value="' + picture_rewrite + '"><div class="input-group-append"><span class="input-group-text" id="file_rewrite_validate_' + clickedId + '">' + icon + '</span></div><div class="w-100 text-center"><img src="' + picture_path + '" class="mt-2 img-fluid border border-secondary rounded-sm"><div class="file-info">' + picture_name + ' - ' + picture_width + " <i class=\"fal fa-times\"></i> " + picture_height + " - " + picture_bytes + ' bytes</div></div>');
        displayArea.slideDown();
    });
    formInputArea.val(file_chosen);

    lobibox = $('.lobibox').data('lobibox').destroy();
});

$('body').on('click', "[id^='file_rewrite_validate_']", function (e) {
    clickedFile = this.id.replace('file_rewrite_validate_', '');
    rewriteValue = $("#file_rewrite_" + clickedFile).val();

    var form = new FormData();
    form.append('id', clickedFile);
    form.append('rewrite', rewriteValue);
    $("#file_rewrite_validate_" + clickedFile).html('<i class="fas fa-spinner fa-spin"></i>')

    $.ajax({
        url: 'file/rewrite',
        type: 'POST',
        cache: false,
        contentType: false,
        processData: false,
        data: form,
        success: function (data) {

            if (data == 2) {
                $("#file_rewrite_validate_" + clickedFile).html('<i class="far fa-exclamation-triangle" style="color:Tomato"></i>');
                $("#file_rewrite_" + clickedFile).val("");
                $("#file_rewrite_" + clickedFile).attr("placeholder", "Ce rewrite existe déjà");
            }
            if (data == 1 || data == 0) {
                $("#file_rewrite_validate_" + clickedFile).html('<i class="fal fa-check" style="color:Green"></i>');
            }
        }
    });
});