
$(document).ready( function () {

	// Génération de la table des statistiques
    contactstats_table = $('#statistiquescontacts_table').DataTable( {
        "autoWidth": false,
    	"language": {
            "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/fr-FR.json"
        },
        "responsive": true,
    	"ajax": siteURL+'json/contactstats',
    	"order": [[ 0, "desc" ]],
    	"columnDefs": [
            // TEXT CENTER
            {
                "className": "text-center align-middle",
                "targets": [0,2,3],
            },
            // ALIGN MIDDLE
            {
                "className": "align-middle",
                "targets": '_all',
            },
            // DATE
            {
                "targets": 0,
                "render": function ( data, type, row ) {
                    var contactDate = row[1].split('/');
                    if ( contactDate != '') {
                        return contactDate[0] + '/' + contactDate[1] + '/' + contactDate[2];
                    }
                    else {
                        return '<i class="fas fa-ellipsis-h"></i>';
                    }
                },
                "width": 180,
            },
            // TYPE
            {
                "targets": 1,
                "render": function ( data, type, row ) {
                    return row[2];
                },

            },
            // CENTRE
            {
                "targets": 2,
                "render": function ( data, type, row ) {
                    if (row[3] == "") {
                        return '<i class="fas fa-ellipsis-h"></i>';
                    }
                    return (row[3] == "En arrivage" ? 'Neuville-sur-Saône' : row[3]);
                },
                "width": 150,
            },
            // VEHICULE
            {
                "targets": 3,
                "render": function ( data, type, row ) {
                    return row[4];
                },
                "width": 110,
            },
            // SITE
            {
                "targets": 4,
                "render": function ( data, type, row ) {
                    return row[5];
                },
            },
    	],
        "conditionalPaging": true,
    });
    // Génération de la table des statistiques mensuelles
    contactstatsmensuel_table = $('#statistiquescontactsmensuels_table').DataTable( {
        "autoWidth": false,
        "language": {
            "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/fr-FR.json",
        },
        "responsive": true,
        "ajax": siteURL+'json/contactstatsmensuel',
        "order": [[ 0, "desc" ], [1, "desc"]],
        "columnDefs": [
            // TEXT CENTER
            {
                "className": "text-center align-middle",
                "targets": [0, 3,4],
            },
            // ALIGN MIDDLE
            {
                "className": "align-middle",
                "targets": '_all',
            },
            // DATE
            {
                "targets": 0,
                "render": function ( data, type, row ) {
                    return row[0];
                },
                "width" : 80,
            },
            // SOURCE
            {
                "targets": 1,
                "render": function ( data, type, row ) {
                    return row[2];
                },
            },
            // TYPE
            {
                "targets": 2,
                "render": function ( data, type, row ) {
                    return row[1];
                },
            },
            // CENTRE
            {
                "targets": 3,
                "render": function ( data, type, row ) {
                    if (row[3] == "-") {
                        return '<i class="fas fa-ellipsis-h"></i>';
                    }
                    return (row[3] == "En arrivage" ? 'Neuville-sur-Saône' : row[3]);
                },
                "width": 150,
            },

            // NOMBRE
            {
                "targets": 4,
                "render": function ( data, type, row ) {
                    return row[4];
                },
                "width": 100,
            },

        ],
        "conditionalPaging": true,
    });
});