// Supprime la ligne du VF sur une offre fournisseur
function deleteVFsurOF(e) {
    let idOF = $(e.target).attr('data-idof');
    let idVF = e.target.id.replace('vehicule_fournisseur_delete_', '')
    $('#liste_vehicule_fournisseur_offre_fournisseur_' + idOF + ' #ligne_vehicule_fournisseur_' + idVF).remove();

    // On va changer tout les numéros de ligne pour que ça reste cohérent (si on supprime la ligne numéro 4, il faut que la 5 passe au numéro 4, la 6 au numéro 5, etc)
    // Pour débloquer le changement de fournisseur, il ne faut plus qu'il y est de VF acheté, on en profite pour vérifier cela
    let encoreVFAchete = false;
    $('#liste_vehicule_fournisseur_offre_fournisseur_' + idOF + ' [id^="ligne_vehicule_fournisseur_"]').each(function (index) {
        let idVFLigne = $(this).attr('id').replace('ligne_vehicule_fournisseur_', '');
        if ($(this).attr('data-numeroLigne') != "ligneVierge") {
            let numeroLigneAncien = $(this).attr('data-numeroLigne');
            $(this).attr('data-numeroLigne', index + 1);
            $(this).removeClass('numeroLigne-' + numeroLigneAncien);
            $(this).addClass('numeroLigne-' + (index + 1));
            $('#numero_ligne_' + idVFLigne).html(index + 1);
        }
        // Pour dire s'il reste des VF achetés
        if ($(this).attr('data-vfachete') == 1) {
            encoreVFAchete = true;
        }
    });

    // On va aussi supprimer le véhicule de l'offre en base
    let formVFDeleteOnOF = new FormData();
    formVFDeleteOnOF.append('idOF', idOF);
    formVFDeleteOnOF.append('idVF', idVF);
    $.ajax({
        type: 'POST',
        url: siteURL + 'admin/achat/offre_fournisseur/delete_vf_on_of',
        contentType: false,
        processData: false,
        data: formVFDeleteOnOF,
        success: function () {
            toastr.success('Supplier vehicle delete on the offer.', 'Success');
            // On débloque le changement de fournisseur s'il n'y a plus de VF acheté
            if (!encoreVFAchete) {
                $('#offre_fournisseur_select_fournisseur_' + idOF).removeClass('cursor-not-allowed').addClass('cursor-pointer');
                $('#offre_fournisseur_input_fournisseur_' + idOF).prop('disabled', false);
                $('#offre_fournisseur_select_fournisseur_' + idOF + " .adminTableTooltip").addClass('d-none');
            } else {
                $('#offre_fournisseur_select_fournisseur_' + idOF).removeClass('cursor-pointer').addClass('cursor-not-allowed');
                $('#offre_fournisseur_input_fournisseur_' + idOF).prop('disabled', true);
                $('#offre_fournisseur_select_fournisseur_' + idOF + " .adminTableTooltip").removeClass('d-none');
            }
        },
    });
}

// Met à jour l'offre fournisseur avec les véhicules fournisseurs
function updateOF(e) {
    e.preventDefault();
    let idOF = e.target.id.replace('offre_fournisseur-send-modify-', '');
    let isPreStudyComplete = true;
    $('#liste_vehicule_fournisseur_offre_fournisseur_' + idOF + " tbody").find("[id^='ligne_vehicule_fournisseur_'][id!='ligne_vehicule_fournisseur___id__']").each((i, e) => {
        isPreStudyComplete = isPreStudyComplete && vfCheckPreStudy(e.id.replace("ligne_vehicule_fournisseur_", ""));
    });
    // On sauvegardera les ID des VF (même des nouveaux) qui n'ont pas tout les champs
    let ligneIncorrect = [];
    // On teste tout les champs obligatoires pour voir si ils sont bien rempli + On rajoute une sécurité sur le VIN pour qu'il soit correct
    ligneIncorrect = ofCheck(idOF, "vehicule_fournisseur_input_date_mec_", ligneIncorrect);
    ligneIncorrect = ofCheck(idOF, "vehicule_fournisseur_input_year_", ligneIncorrect);
    ligneIncorrect = ofCheck(idOF, "vehicule_fournisseur_input_vin_", ligneIncorrect);
    ligneIncorrect = ofCheck(idOF, "vehicule_fournisseur_input_marque_", ligneIncorrect);
    ligneIncorrect = ofCheck(idOF, "vehicule_fournisseur_input_modele_", ligneIncorrect);
    ligneIncorrect = ofCheck(idOF, "vehicule_fournisseur_input_version_", ligneIncorrect);
    ligneIncorrect = ofCheck(idOF, "vehicule_fournisseur_input_boite_", ligneIncorrect);
    ligneIncorrect = ofCheck(idOF, "vehicule_fournisseur_input_carburant_", ligneIncorrect);
    ligneIncorrect = ofCheck(idOF, "vehicule_fournisseur_input_kilometrage_", ligneIncorrect);
    ligneIncorrect = ofCheck(idOF, "vehicule_fournisseur_input_puissance_din_", ligneIncorrect);
    ligneIncorrect = ofCheck(idOF, "vehicule_fournisseur_input_couleur_", ligneIncorrect);
    if (idOF != 0) {
        ligneIncorrect = ofCheck(idOF, "vehicule_fournisseur_input_tva_", ligneIncorrect);
        ligneIncorrect = ofCheck(idOF, "vehicule_fournisseur_input_prix_transport_ht_", ligneIncorrect);
        ligneIncorrect = ofCheck(idOF, "vehicule_fournisseur_input_prix_achat_ht_monnaie_locale_", ligneIncorrect);
        ligneIncorrect = ofCheck(idOF, "vehicule_fournisseur_input_prix_marche_ttc_", ligneIncorrect);
        ligneIncorrect = ofCheck(idOF, "vehicule_fournisseur_input_fre_ht_", ligneIncorrect);
        ligneIncorrect = ofCheck(idOF, "vehicule_fournisseur_input_malus_", ligneIncorrect);
    }

    // On enlève les doublons des id incorrects
    ligneIncorrect = ligneIncorrect.distinct();
    ligneIncorrect.sort((a, b) => a - b);

    if (ligneIncorrect.length > 0) {
        if (ligneIncorrect.length == 1) {
            toastr.error("Line " + ligneIncorrect.join(', ') + " is incorrect. Please check the red fields that must be completed.");
        } else {
            toastr.error("Lines " + ligneIncorrect.join(', ') + " are incorrect. Please check the red fields that must be completed.");
        }
    } else if ($("#liste_vehicule_fournisseur_offre_fournisseur_" + idOF + " tbody").children().length <= 1) { // On vérifie qu'il y est des VF dans l'offre
        toastr.error("The offer need vehicles.");
    } else {
        // On enregistre l'offre, qu'elle soit nouvelle ou non
        let title = $('#offre_fournisseur_input_title_' + idOF).val();
        let contenu = $('#offre_fournisseur_input_contenu_' + idOF).val();
        let idFournisseur = $('#offre_fournisseur_input_fournisseur_' + idOF).val();
        let dateCreationOF = $('#offre_fournisseur_input_dateCreation_' + idOF).val();
        // On vérifie quand même qu'on a le titre et le fournisseur
        let offreOK = true;
        if (title == "") {
            offreOK = false;
            toastr.error("The supplier offer need a title");
        }
        if (idFournisseur == "") {
            offreOK = false;
            toastr.error("The supplier offer need a provider");
        }

        if (offreOK) {
            $('#offre_fournisseur_loader').removeClass('d-none');
            $('.card-body').addClass('d-none');
            let formOF = new FormData();
            formOF.append('idOF', idOF);
            formOF.append('title', title);
            formOF.append('contenu', contenu);
            formOF.append('idFournisseur', idFournisseur);
            formOF.append('dateCreationOF', dateCreationOF);
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/achat/offre_fournisseur/modify_of',
                contentType: false,
                processData: false,
                data: formOF,
                success: function (data) {
                    toastr.success('Supplier offer save.', 'Success');
                    // On sélectionne toutes les lignes des VF, sans prendre la ligne vierge
                    let ligneVF = $('#liste_vehicule_fournisseur_offre_fournisseur_' + idOF + " tbody").find("[id^='ligne_vehicule_fournisseur_'][id!='ligne_vehicule_fournisseur___id__']");
                    // On boucle sur chaque véhicule
                    let tabIdVf = [];
                    ligneVF.each(function () {
                        // On garde l'id du véhicule
                        tabIdVf.push($(this).attr('data-id'));
                    });
                    console.log('tabIdVf :', tabIdVf);
                    updateVF(tabIdVf[0], idOF, data, tabIdVf, 0);
                },
            });
        }
    }
}

function ofResetAddForm() {
    $.ajax({
        type: 'POST',
        url: siteURL + 'admin/achat/offre_fournisseur/show_modify',
        data: { id: 0 },
        success: function (data) {
            $("#offre_fournisseur-tabs-add").html(data);
        }
    });
}

function ofShowModify(idOF) {
    $('#offre_fournisseur-tabs').append('<li class="nav-item"><a class="nav-link" id="offre_fournisseur-tabs-modify-' + idOF + '-tab" data-toggle="pill" href="#offre_fournisseur-tabs-modify-' + idOF + '" role="tab" aria-controls="offre_fournisseur-tabs-modify-' + idOF + '" aria-selected="false"><i class="pr-2 fal fa-pen"></i>of-' + idOF + ' <span class="d-none d-md-inline"></span><i class="p-2 fal fa-times" id="offre_fournisseur-close-modify-' + idOF + '"></i></a></li>');
    $.ajax({
        type: 'POST',
        url: siteURL + 'admin/achat/offre_fournisseur/show_modify',
        data: { id: idOF },
        success: function (data) {
            $('#offre_fournisseur-tabs-list').after('<div class="tab-pane fade" id="offre_fournisseur-tabs-modify-' + idOF + '" role="tabpanel" aria-labelledby="offre_fournisseur-tabs-modify-' + idOF + '-tab">' + data + '</div>');
            $('#offre_fournisseur-tabs-modify-' + idOF + '-tab').trigger('click');
            vfSetup("offre_fournisseur", idOF);
        }
    });
}

// Pour changer les informations des fournisseurs ainsi que relancer les calculs sur les VF
function ofUpdateDevise(e) {
    // Update du nom du pays dans la carte
    let idOF = e.target.id.replace("offre_fournisseur_input_fournisseur_", "");
    $("#offre_fournisseur_pays_" + idOF).html($($(e.target)[0].selectedOptions[0]).attr("data-nompays"));
    // Update du drapeau du pays dans la carte
    let srcInitial = $("#offre_fournisseur_drapeau_" + idOF).attr("src");
    let srcChange = srcInitial.replace(/(\w{2}.png)/g, $($(e.target)[0].selectedOptions[0]).attr("data-codepays") + ".png");
    $("#offre_fournisseur_drapeau_" + idOF).attr("src", srcChange);
    // Update des frais de plateforme
    $("#offre_fournisseur_input_fraisPlateforme_ht_" + idOF).val((parseFloat($($("#offre_fournisseur_input_fournisseur_" + idOF)[0].selectedOptions[0]).attr("data-fraisplateforme")) / parseFloat($($("#offre_fournisseur_input_fournisseur_" + idOF)[0].selectedOptions[0]).attr("data-devise"))).toFixed(2));
    $("#offre_fournisseur_input_fraisPlateforme_ttc_" + idOF).val(((parseFloat($($("#offre_fournisseur_input_fournisseur_" + idOF)[0].selectedOptions[0]).attr("data-fraisplateforme")) / parseFloat($($("#offre_fournisseur_input_fournisseur_" + idOF)[0].selectedOptions[0]).attr("data-devise"))) * 1.2).toFixed(2));
    // Update des marges des véhicules
    // On vérifie juste de pas triger l'évènement sur la ligne vierge pour éviter de créer une ligne vide
    if ($("#liste_vehicule_fournisseur_offre_fournisseur_" + idOF + " tbody").children().length > 1) {
        $("#liste_vehicule_fournisseur_offre_fournisseur_" + idOF + " tbody").children().each(function() {
            let idVF = $(this).attr('id').replace('ligne_vehicule_fournisseur_', '')
            if (idVF != "__id__") {
                $("#vehicule_fournisseur_input_prix_achat_ht_monnaie_locale_" + idVF).trigger('change');
            }
        })
    }
    // Définie l'affichage des boutons en bout d'input suivant le mode de frais (expertise ou montant)
    const modeExpertise = $($(e.target)[0].selectedOptions[0]).data("expertise") == "expertise";
    $("[id^='td_vehicule_fournisseur_input_bodywork_damage_ht_']").find(".input-group-append").each((i, e) => {
        if (e.id == "btn-quick-expertise") {
            if (modeExpertise) { $(e).removeClass("d-none"); } else { $(e).addClass("d-none"); }
        } else {
            if (modeExpertise) { $(e).addClass("d-none"); } else { $(e).removeClass("d-none"); }
        }
    });
    $("[id^='vehicule_fournisseur_input_bodywork_damage_ht_']").each((i, e) => {
        if (modeExpertise) { $(e).attr("disabled", ""); } else { $(e).removeAttr("disabled"); }
    });
}

// Pour afficher la modal de changement de fournisseur
function ofOpenModalChangementFournisseur(e) {
    let idOF = e.target.id.replace("offre_fournisseur_input_fournisseur_", "");
    // Pour éviter d'ouvrir la modal au lancement de la page, on va checker si l'input a bien changé en regardant sa valeur et la valeur de l'id fournisseur du véhicule
    let idFournisseurActuel = $('#offre_fournisseur-actual-fournisseur-' + idOF).attr('data-idfournisseuractual');
    let idFournisseurChange = $('#offre_fournisseur_input_fournisseur_' + idOF).val();
    if (idFournisseurActuel != idFournisseurChange) {
        // Affiche la modal
        $('#offre_fournisseur-modal-changement-fournisseur-' + idOF).modal('show');
        // Change le nom dans la modal du fournisseur choisi
        let nomFournisseurChange = $('#offre_fournisseur_input_fournisseur_' + idOF).find('option[value="' + idFournisseurChange + '"]').html().trim();
        $('#offre_fournisseur-name-change-fournisseur-' + idOF).html(nomFournisseurChange);
    }
    // Pour mettre à jour la devise et le drapeau
    ofUpdateDevise(e);
}

// Pour remettre la valeur du select du fournisseur au fournisseur initiale si on ferme la modal sans sauvegarder
function ofCloseModalChangementFournisseur(e) {
    let idOF = e.target.id.replace("offre_fournisseur-modal-changement-fournisseur-", "");
    let idFournisseurActuel = $('#offre_fournisseur-actual-fournisseur-' + idOF).attr('data-idfournisseuractual');
    if ($('#offre_fournisseur-change-fournisseur-accepte-' + idOF).attr('data-changefournisseuraccepte') == 0) {
        // Si on annule le changement, repasse le select sur le fournisseur actuel du VF
        $('#offre_fournisseur_input_fournisseur_' + idOF).val(idFournisseurActuel).selectpicker('refresh').trigger('change');
    } else if ($('#offre_fournisseur-change-fournisseur-accepte-' + idOF).attr('data-changefournisseuraccepte') == 1) {
        // Si on a accepté le changement, on repasse l'attribut à 0 pour si on rechange de fournisseur après
        $('#offre_fournisseur-change-fournisseur-accepte-' + idOF).attr('data-changefournisseuraccepte', 0);
    }
}

function ofChangeFournisseur(e) {
    let idOF = e.target.id.replace("offre_fournisseur-change-fournisseur-accepte-", "");

    // On change l'attribut qui permet d'accepter ou non le changement de fournisseur
    $('#offre_fournisseur-change-fournisseur-accepte-' + idOF).attr('data-changefournisseuraccepte', 1);
    // On change les attributs de fournisseur actuel (nom et id) pour le fournisseur changé
    $('#offre_fournisseur-actual-fournisseur-' + idOF).attr('data-idfournisseuractual', $('#offre_fournisseur_input_fournisseur_' + idOF).val());
    let nomFournisseurChange = $('#offre_fournisseur_input_fournisseur_' + idOF).find('option[value="' + $('#offre_fournisseur_input_fournisseur_' + idOF).val() + '"]').html().trim();
    $('#offre_fournisseur-actual-fournisseur-' + idOF).html(nomFournisseurChange);

    // On check si on a si le VF est relié a des OF pour le supprimer des OF
    if ($('#offre_fournisseur-list-of-deleting-vf-' + idOF).length) {
        // On va supprimer le VF sur ces OF
        $('#offre_fournisseur-list-of-deleting-vf-' + idOF).children().each(function () {
            let idOF = $(this).attr('data-idof');
            let idVF = $(this).attr('data-idvf');
            let formVFDeleteOnOF = new FormData();
            formVFDeleteOnOF.append('idOF', idOF);
            formVFDeleteOnOF.append('idVF', idVF);
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/achat/offre_fournisseur/delete_vf_on_of',
                contentType: false,
                processData: false,
                data: formVFDeleteOnOF,
                success: function () {
                    toastr.success('Supplier vehicle delete on the OF-' + idOF + '.', 'Success');
                },
            });
        });
    }
    $('#offre_fournisseur-modal-changement-fournisseur-' + idOF).modal('hide');
    $('#offre_fournisseur-send-modify-' + idOF).trigger('click');
}
