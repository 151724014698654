if ($("#widget-action-prepa").length) {
    let compteurDepotSerezin = 0;
    let compteurDepotMontelimar = 0;
    let compteurDepotNeuville = 0;

    function widgetActionPrepaAjax() {
        $("#loader-widget-action-prepa").removeClass('d-none');

        $.ajax({
            type:'POST',
            url: siteURL + 'admin/widget/action-prepa',
            contentType: false,
            processData: false,
            success: function(data){
                $.each(data.vehicules, function (key, value) {
                    if (value.depotSerezin != null && value.depotSerezin == "depotSerezin") {
                        compteurDepotSerezin = compteurDepotSerezin + 1;
                    }
                    if (value.depotMontelimar != null && value.depotMontelimar == "depotMontelimar") {
                        compteurDepotMontelimar = compteurDepotMontelimar + 1;
                    }
                    if (value.depotNeuville != null && value.depotNeuville == "depotNeuville") {
                        compteurDepotNeuville = compteurDepotNeuville + 1;
                    }
                });

                let config = {
                    type: 'doughnut',
                    data: {
                        labels: [
                            "Dépôt Sérézin (" + compteurDepotSerezin + ")",
                            "Dépôt Montélimar (" + compteurDepotMontelimar + ")",
                            "Dépôt Neuville (" + compteurDepotNeuville + ")",
                        ],
                        datasets: [{
                            data: [
                                compteurDepotSerezin,
                                compteurDepotMontelimar,
                                compteurDepotNeuville,
                            ],
                            backgroundColor: [
                                '#f56954',
                                '#00a65a',
                                '#f39c12',
                            ]
                        }]
                    }
                };

                new Chart(document.getElementById('action-prepa-DonutChart'), config);
                $("#widget-action-prepa-title a").html(data.compteurVehicule + ' véhicules en alerte <i class="fas fa-arrow-circle-right"></i>');
                $("#loader-widget-action-prepa").addClass('d-none');

                // On appelle la requête du prochain widget
                nextWidgetRequeteAjax();
            }
        })
    }
}