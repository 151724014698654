function updateMechanicalDamage(e) {
    // On isole l'id du VF
    let idVF = e.target.id
        .replace("vehicule_fournisseur_input_marque_", "")
        .replace("vehicule_fournisseur_input_modele_", "")
        .replace("vehicule_fournisseur_input_carburant_", "")
        .replace("vehicule_fournisseur_input_year_", "")
        .replace("vehicule_fournisseur_input_kilometrage_", "")
        .replace("vehicule_fournisseur_input_fre_ht_", "")
        .replace("vehicule_fournisseur_input_date_mec_", "");
    // On se sert de l'id pour récupérer les valeurs de marque/modele/energie/année/kilométrage
    let vehicule_fournisseur_marque = $('#vehicule_fournisseur_input_marque_' + idVF).val();
    let vehicule_fournisseur_modele = $('#vehicule_fournisseur_input_modele_' + idVF).val();
    let vehicule_fournisseur_carburant = $('#vehicule_fournisseur_input_carburant_' + idVF).val();
    let vehicule_fournisseur_year = $('#vehicule_fournisseur_input_year_' + idVF).val();
    let vehicule_fournisseur_kilometrage = $('#vehicule_fournisseur_input_kilometrage_' + idVF).val();

    // Pour refresh la marque, le modele, le carburant et le km dans la card result
    let modele = "Select a model";
    if ($("#vehicule_fournisseur_option_modele_" + vehicule_fournisseur_modele).html() != undefined) {
        modele = $("#vehicule_fournisseur_option_modele_" + vehicule_fournisseur_modele).html();
    }
    let carburant = '';
    // Convertie l'energie vers une valeur trouvable en BDD
    switch (vehicule_fournisseur_carburant) {
        case "ES":
            carburant = "Essence"
            break
        case "GO":
            carburant = "Diesel"
            break
        case "EH":
            carburant = "Hybride"
            break
        case "EL":
            carburant = "Electrique"
            break
        case "GP":
            carburant = "GPL"
            break
        case "ET":
            carburant = "Ethanol"
            break
        default:
            break;
    }
    // Écrit les valeurs dans la card résult comme rappel de données du véhicule
    $("#result_vehicule_fournisseur_input_marque_" + idVF).html($("#vehicule_fournisseur_option_marque_" + vehicule_fournisseur_marque).html());
    $("#result_vehicule_fournisseur_input_modele_" + idVF).html(modele);
    $("#result_vehicule_fournisseur_input_carburant_" + idVF).html(carburant);
    $("#result_vehicule_fournisseur_input_kilometrage_" + idVF).html(vehicule_fournisseur_kilometrage + " km");

    // Si toute les valeurs ont bien été renseigné, on update les frais mécanique
    if (vehicule_fournisseur_marque.length && vehicule_fournisseur_modele.length && vehicule_fournisseur_carburant.length && vehicule_fournisseur_year.length && vehicule_fournisseur_kilometrage.length) {
        // Construit les paramètres
        let optionsRecherche = new FormData();
        optionsRecherche.append('id', idVF);
        optionsRecherche.append('marque', vehicule_fournisseur_marque);
        optionsRecherche.append('modele', vehicule_fournisseur_modele);
        optionsRecherche.append('carburant', vehicule_fournisseur_carburant);
        optionsRecherche.append('year', vehicule_fournisseur_year);
        optionsRecherche.append('kilometrage', vehicule_fournisseur_kilometrage);

        // Lance la requête
        $.ajax({
            type: 'POST',
            url: siteURL + 'admin/vehicule_fournisseur/update/fraisMecanique',
            contentType: false,
            processData: false,
            data: optionsRecherche,
            success: function (data) {
                // Écrit les frais mécaniques retournés
                $("#vehicule_fournisseur_input_mechanical_damage_ht_" + idVF).val((parseFloat(data)).toFixed(2));
                $("#vehicule_fournisseur_input_mechanical_damage_ttc_" + idVF).val((parseFloat(data) * 1.20).toFixed(2));
                // Lance l'update des frais estimés
                updateFraisTotal(idVF);
            }
        });

        // Si il y a une expertise de faisable
        if ($("#vehicule_fournisseur-expertise-" + idVF).length) {
            // Reset la popup expertise
            $("#vehicule_fournisseur-expertise-" + idVF).html("");
            // Update la popup expertise (pour les prix dynamiques)
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/vehicule_fournisseur/update/expertiseRender',
                contentType: false,
                processData: false,
                data: optionsRecherche,
                success: function (data) {
                    $("#vehicule_fournisseur-expertise-" + idVF).html(data);
                }
            });
        }
    } else {
        // Si pas assez de valeur, 450 frais mécanique
        $("#vehicule_fournisseur_input_mechanical_damage_ht_" + idVF).val(450);
        $("#vehicule_fournisseur_input_mechanical_damage_ttc_" + idVF).val(450 * 1.2);
        // Si il y a une expertise de faisable
        if ($("#vehicule_fournisseur-expertise-" + idVF).length) {
            // Reset la popup expertise
            $("#vehicule_fournisseur-expertise-" + idVF).html("");
        }
        // Lance l'update des frais estimés
        updateFraisTotal(idVF);
    }
}

function updateBodyworkDamage(idVF) {
    let idOF = $("#vehicule_fournisseur_input_bodywork_damage_ht_" + idVF).data("idof");
    const idFournisseur = "#" + (idOF == undefined ? "vehicule_fournisseur_input_fournisseur_" + idVF : "offre_fournisseur_input_fournisseur_" + idOF);
    const modeExpertise = $($(idFournisseur)[0].selectedOptions[0]).data("expertise") == "expertise";
    if (modeExpertise) {
        let bodyworkDamage = $("#vehicule_fournisseur_input_bodywork_damage_ht_" + idVF).val();
        if ($("#vehicule_fournisseur-expertise-" + idVF).length && $("#vehicule_fournisseur_input_stop_bodywork_damage_ht_" + idVF).val().length) {
            // FULL EXPERTISE
            bodyworkDamage = $("#vehicule_fournisseur-expertise-" + idVF).data("expertise").reduce((a, c) => (c.toDo ? a + parseFloat(c.prixHtTotal) : a), 0);
        } else {
            // QUICK EXPERTISE
            bodyworkDamage = quickExpertiseTotal("vehicule_fournisseur_quick_expertise_" + idVF);
            // Bloque full expertise si quick expertise est utilisé
            if ($("#vehicule_fournisseur-expertise-" + idVF).length) {
                const expertise = $("#vehicule_fournisseur-expertise-" + idVF).data("expertise");
                const mappedExpertise = Object.entries(expertise.reduce((acc, frais) => { acc[frais["prestation"]] = (acc[frais["prestation"]] || 0) + frais["quantite"]; return acc; }, {})).map(([id, quantite]) => ({ id: Number(id), quantite: quantite }));
                const quickExpertise = quickExpertiseValue("vehicule_fournisseur_quick_expertise_" + idVF);
                const filterQuickExpertise = quickExpertise.filter((v) => v.quantite).map((frais) => { return { "id": frais.id, "quantite": frais.quantite }; });
                // Diff, cache full expertise si il y a une différence avec la quick expertise
                const diffExpertise = mappedExpertise.filter(item1 => !filterQuickExpertise.some(item2 => JSON.stringify(item2) === JSON.stringify(item1))).concat(filterQuickExpertise.filter(item1 => !mappedExpertise.some(item2 => JSON.stringify(item2) === JSON.stringify(item1))));
                $("[id^='btnGroupFRE_" + idVF + "']").each((i, e) => { if (diffExpertise.length) { $(e).addClass("d-none"); } else { $(e).removeClass("d-none"); } });
            }
        }
        // Écrit les frais de carrosseries calculés
        $("[id^='vehicule_fournisseur_input_bodywork_damage_ht_" + idVF + "']").val((bodyworkDamage).toFixed(2));
    }
    // Lance l'update des frais estimés
    updateFraisTotal(idVF);
}

function updateFraisTotal(idVF) {
    $("[id^='vehicule_fournisseur_input_bodywork_damage_ttc_" + idVF + "']").val(($("[id^='vehicule_fournisseur_input_bodywork_damage_ht_" + idVF + "']").val() * 1.20).toFixed(2))
    // Update l'affichage des frais estimés (total et différence)
    $("#vehicule_fournisseur_input_fre_ht_" + idVF).val((parseFloat($("#vehicule_fournisseur_input_mechanical_damage_ht_" + idVF).val()) + parseFloat($("#vehicule_fournisseur_input_bodywork_damage_ht_" + idVF).val())).toFixed(2));
    $("#vehicule_fournisseur_input_fre_ttc_" + idVF).val((parseFloat($("#vehicule_fournisseur_input_mechanical_damage_ttc_" + idVF).val()) + parseFloat($("#vehicule_fournisseur_input_bodywork_damage_ttc_" + idVF).val())).toFixed(2));
    $("#vehicule_fournisseur_input_fre_ht_" + idVF).trigger("change");
    // Update progressbar des frais
    $('#vehicule_fournisseur-progressbar-fre-bodywork').css("width", ($('#vehicule_fournisseur_input_bodywork_damage_ht_' + idVF).val() / $('#vehicule_fournisseur_input_fre_ht_' + idVF).val() * 100) + "%")
    $('#vehicule_fournisseur-progressbar-fre-mechanical').css("width", ($('#vehicule_fournisseur_input_mechanical_damage_ht_' + idVF).val() / $('#vehicule_fournisseur_input_fre_ht_' + idVF).val() * 100) + "%")
}
