
$(document).ready( function () {

	// Génération de la table des statistiques
    prospectstats_table = $('#statistiquesprospects_table').DataTable( {
        "autoWidth": false,
    	"language": {
            "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/fr-FR.json"
        },
        "responsive": true,
    	"ajax": siteURL+'json/contacts',
    	"order": [[ 1, "desc" ]],
    	"columnDefs": [
            // TEXT CENTER
            {
                "className": "text-center align-middle",
                "targets": [0,1,2,3,7,8],
            },
            // ALIGN MIDDLE
            {
                "className": "align-middle",
                "targets": '_all',
            },
            {
                "targets": 0,
                "render": function ( data, type, row ) {
                            return row[0];
                },
                "width": 50,
            },
            // DATE
            {
                "targets": 1,
                "render": function ( data, type, row ) {
                    var contactDate = row[1].split('/');
                    if ( contactDate != '') {
                        return contactDate[0] + '/' + contactDate[1] + '/' + contactDate[2];
                    }
                    else {
                        return '<i class="fas fa-ellipsis-h"></i>';
                    }
                },
                "width": 90,
            },
            // CENTRE
            {
                "targets": 2,
                "render": function ( data, type, row ) {
                    if (row[2] == "") { return '<i class="fas fa-ellipsis-h"></i>'; }
                    return row[2];
                },
                "width": 90,
            },
            // SOURCE
            {
                "targets": 3,
                "render": function ( data, type, row ) {
                    if (row[3] == "") { return '<i class="fas fa-ellipsis-h"></i>'; }
                    return row[3];
                },
                "width": 150,
            },
            // NOM
            {
                "targets": 4,
                "render": function ( data, type, row ) {
                    if (row[4] == "") { return '<i class="fas fa-ellipsis-h"></i>'; }
                    return row[4];
                },
                "width": 120,
            },
            // PRENOM
            {
                "targets": 5,
                "render": function ( data, type, row ) {
                    if (row[5] == "") { return '<i class="fas fa-ellipsis-h"></i>'; }
                    return row[5];
                },
                "width": 120,
            },
            // MAIL
            {
                "targets": 6,
                "render": function ( data, type, row ) {
                    if (row[6] == "") { return '<i class="fas fa-ellipsis-h"></i>'; }
                    return row[6];
                },
            },
            // ACCEPTE MAIL
            {
                "targets": 7,
                "render": function ( data, type, row ) {
                    if (row[7] == "true") { return '<i class="fa fa-check-square"></i>';}
                    if (row[7] == "false") { return '';}
                    return '<i class="fas fa-ellipsis-h"></i>';
                },
                "width": "90px",
            },
            // PHONE
            {
                "targets": 8,
                "render": function ( data, type, row ) {
                    if (row[8] == "") { return '<i class="fas fa-ellipsis-h"></i>'; }
                    return '<a href="tel:'+row[8]+'">'+row[8]+"</a>";
                },
                "width": 100,
            },
            // MESSAGE
            {
                "targets": 9,
                "render": function ( data, type, row ) {
                    if (row[9] == "" && row[9] != null) { return '<i class="fas fa-ellipsis-h"></i>'; }
                    return '<i class="fad fa-comments-alt pr-2 cursor-pointer" data-toggle-message="'+row[0]+'"></i><span data-toggle-messagedisplay="'+row[0]+'">'+row[9].slice(0,20)+"&ctdot;</span><span data-toggle-messagedisplay='"+row[0]+"' style='display:none'>"+row[9]+"</span>";
                },
                "width" : 140,
            },
            // FICHES
            {
                "targets": 10,
                "render": function ( data, type, row ) {

                    if (row[10].length == 0) { return '<i class="fas fa-ellipsis-h"></i>'; }
                    vehicules = "";
                    for(fiche in row[10]) {
                        vehicules = vehicules + row[10][fiche]['vehicule']+"<br>";
                    }
                    return vehicules;
                },
                "width": 100,
            },
    	],
        "conditionalPaging": true,
    });
});

$('body').on('click', "[data-toggle-message]", function() {
    $(this).data('toggle-message');
    $("[data-toggle-messagedisplay='"+$(this).data('toggle-message')+"']").toggle()
});