let proposition_vehicule_email_table = null;

$(document).ready(function () {
    if ($("#proposition_vehicule_email_page").length) {
        if ($("#proposition_vehicule_email-changes-modal").length) {
            $("#proposition_vehicule_email-changes-btn").trigger("click");
        }

        const regexPrixMarchand = /vf-\d+|\d+/g;
        getStatusMailAsynchrone($('[id^="statut-mail-"]'));
        $('body').on('change', "#proposition_vehicule_email-user", function (e) {
            let selectedOption = $("#proposition_vehicule_email-user").find(":selected");
            $("#proposition_vehicule_email-user-image").attr("src", selectedOption.data("url"));
            $("#proposition_vehicule_email-user-label").html(selectedOption.data("fonction"));
            $("#proposition_vehicule_email-user-tel").html(selectedOption.data("tel").toString().replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, "$1.$2.$3.$4.$5"));
            $("#proposition_vehicule_email-user-email").html(selectedOption.data("email"));
        });

        $('body').on('change', "#proposition_vehicule_email-centre", function (e) {
            let selectedOption = $("#proposition_vehicule_email-centre").find(":selected");
            $("[centre-image]").toArray().forEach(image => {
                if ($(image).data("name") == selectedOption.data("name")) {
                    $(image).removeClass("d-none");
                } else {
                    $(image).addClass("d-none");
                }
            });
            $("#proposition_vehicule_email-centre-tel").html(selectedOption.data("tel").toString().replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, "$1.$2.$3.$4.$5"));
            $("#proposition_vehicule_email-centre-adresse").html(selectedOption.data("adresse"));
            $("#proposition_vehicule_email-centre-codePostal").html(selectedOption.data("codePostal"));
            $("#proposition_vehicule_email-centre-ville").html(selectedOption.data("ville"));
        });

        $('body').on('change', "#proposition_vehicule_email-annuaire", function (e) {
            let selectedOption = $("#proposition_vehicule_email-annuaire").find(":selected");
            $("#proposition_vehicule_email-annuaire-image").attr("src", (selectedOption.data("logo") != siteURL ? selectedOption.data("logo") : "https://cdn-icons-png.flaticon.com/512/219/219969.png"));
            $("#proposition_vehicule_email-annuaire-label").html(selectedOption.data("nomentreprise"));
            $("#proposition_vehicule_email-envoi").removeClass("d-none");
            $("#proposition_vehicule_email-annuaire-tel").html("<a href='tel:" + selectedOption.data("tel").toString() + "'>" + selectedOption.data("tel").toString().replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, "$1.$2.$3.$4.$5") + "</a>");
            $("#proposition_vehicule_email-annuaire-telFixe").html("<a href='tel:" + selectedOption.data("telfixe").toString() + "'>" + selectedOption.data("telfixe").toString().replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, "$1.$2.$3.$4.$5") + "</a>");
            $("#proposition_vehicule_email-annuaire-email").html("<a href='mailto:" + selectedOption.data("email") + "'>" + selectedOption.data("email") + "</a>");
            $("#proposition_vehicule_email-annuaire-siteweb").html("<a href='" + selectedOption.data("siteweb") + "'>" + selectedOption.data("siteweb") + "</a>");
            $("#proposition_vehicule_email-annuaire-adresse").html(selectedOption.data("adresse"));
            $("#proposition_vehicule_email-annuaire-codePostal").html(selectedOption.data("codepostal"));
            $("#proposition_vehicule_email-annuaire-ville").html(selectedOption.data("ville"));
            $("#proposition_vehicule_email-employe-div").html("");
            if (selectedOption.data("ispro") == "1" && selectedOption.data("hasemploye") == "1") {
                let form = new FormData();
                form.append('component', "proposition_vehicule_email_entreprise");
                form.append('timestamp', $("#proposition_vehicule_email_page").data("idsession"));
                form.append('idAnnuaire', $(this).val());
                $.ajax({
                    type: 'POST',
                    url: siteURL + 'admin/stock/proposition/component',
                    contentType: false,
                    processData: false,
                    data: form,
                    success: function (html) {
                        $("#proposition_vehicule_email-employe-div").html(html);
                        $("#proposition_vehicule_email-employe").selectpicker();
                    }
                });
            }
            if ($("#proposition_vehicule_email-table").length == 0) {
                $('.proposition_vehicule_email-modal-load').removeClass('d-none');
                $('.proposition_vehicule_email-modal-body').addClass('d-none');
                let selectedOption = $("#proposition_vehicule_email-annuaire").find(":selected");
                let form = new FormData();
                form.append('timestamps', window.location.pathname.match(/(\d+)$/g));
                form.append('component', "proposition_vehicule_email_list");
                form.append('listId', $("#proposition_vehicule_email-list").data("list"));
                form.append('listIdVF', $("#proposition_vehicule_email-list").data("listvf"));
                form.append('isPro', selectedOption.data("ispro"));
                $.ajax({
                    type: 'POST',
                    url: siteURL + 'admin/stock/proposition/component',
                    contentType: false,
                    processData: false,
                    data: form,
                    success: function (html) {
                        $("#proposition_vehicule_email-list").html(html);
                        updatePropositionTotal();
                        $("#proposition_vehicule_email-modaladd-affichage-stock").removeClass('btn-primary');
                        $("#proposition_vehicule_email-modaladd-affichage-stock").addClass('btn-outline-primary');
                        $("#proposition_vehicule_email-modaladd-affichage-stockFournisseur").removeClass('btn-outline-primary');
                        $("#proposition_vehicule_email-modaladd-affichage-stockFournisseur").addClass('btn-primary');
                        $("#proposition_vehicule_email-total").removeClass("d-none");
                        $('.proposition_vehicule_email-modal-load').addClass('d-none');
                        $('.proposition_vehicule_email-modal-body').removeClass('d-none');

                        // Toaster dans le cas d'une duplication
                        if ($('#duplication-vehicule-non-mis').length > 0) {
                            // On retire les véhicules des data qui ne sont plus disponible
                            let idSupprime = $('#duplication-vehicule-non-mis').html().replaceAll(' ', '').split(',');
                            let listId = $("#proposition_vehicule_email-list").data("list").split(',').filter(id => !idSupprime.includes(id)).join(',');
                            let listIdVF = $("#proposition_vehicule_email-list").data("listvf").split(',').filter(id => !idSupprime.includes(id)).join(',');
                            $("#proposition_vehicule_email-list").data("list", listId);
                            $("#proposition_vehicule_email-list").data("listvf", listIdVF);
                            toastr.error('Les véhicules suivant n\'ont pas pu être ajoutés (vendu ou plus disponible) : ' + $('#duplication-vehicule-non-mis').html(), 'Erreur')
                        }
                    }
                });
            }
        });

        $('body').on('change', "#proposition_vehicule_email-employe", function (e) {
            let selectedOption = $("#proposition_vehicule_email-employe").find(":selected");
            let form = new FormData();
            form.append('component', "proposition_vehicule_email_employe");
            form.append('timestamp', $("#proposition_vehicule_email_page").data("idsession"));
            form.append('idEmploye', selectedOption.val());
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/stock/proposition/component',
                contentType: false,
                processData: false,
                data: form,
                success: function () {
                    $("#proposition_vehicule_email-employe-tel").html(selectedOption.data("tel").toString().replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, "$1.$2.$3.$4.$5"));
                    $("#proposition_vehicule_email-employe-email").html(selectedOption.data("email"));
                }
            });
        });

        // Édition prix marchand remisé ou minimum
        $('body').on('click dblclick', "[id^='edit_prix_remise_marchand_']", function (e) {
            let idVehicule = this.id.match(regexPrixMarchand)[0];
            let originalContent = $(this).text().match(regexPrixMarchand);
            $(this).parent().html("<input type='number' size='10' min='0' step='100' id='input_prix_remise_marchand_" + idVehicule + "' value='" + (originalContent != null ? originalContent.join("") : "") + "' />");
            $("#input_prix_remise_marchand_" + idVehicule).trigger("focus");
        });
        $('body').on('click dblclick', "[id^='input_prix_remise_marchand_']", function (e) { e.stopPropagation(); });
        $('body').on('keyup blur', "[id^='input_prix_remise_marchand_']", function (e) {
            let idVehicule = this.id.match(regexPrixMarchand)[0];
            let newContent = $(this).val().match(regexPrixMarchand);
            if (newContent == null) {
                newContent = [0];
                $(this).val("");
            } else {
                $(this).val(newContent.join(""));
            }
            let prixMarchand = newContent.join("");
            if (e.key === undefined || e.key == "Enter") {
                $("#prix_remise_marchand_" + idVehicule).html("<button type='button' id='edit_prix_remise_marchand_" + idVehicule + "' class='btn btn-outline-dark btn-sm text-11'>" + (parseFloat(prixMarchand) ? prixMarchand.replace(/(?=(\d\d\d)+(?!\d))/g, " ").trim() + " €" : "Éditer <i class='fal fa-pen text-10'></i>") + "</button>");
                updatePropositionMargeHT(idVehicule);
                disableSendBtn();
            }
        });

        function updatePropositionMargeHT(idVehicule) {
            const prix = parseFloat($("#proposition_vehicule_email-table-prix-" + idVehicule).html().replaceAll(" ", "").replaceAll("€", "").replaceAll(",", ".").trim());
            const prixRemise = parseInt(($("#edit_prix_remise_marchand_" + idVehicule).text().match(/\d+/g) ?? ["0"]).join(""));
            const prixRevient = parseFloat($("#prix_remise_marchand_" + idVehicule).parent().find(".marge-ht").attr("data-prix-revient"));
            const tva = parseFloat($("#prix_remise_marchand_" + idVehicule).parent().find(".marge-ht").attr("data-tva"));
            let margeHT = ((prixRemise > 0 ? prixRemise : prix) / tva) - prixRevient; margeHT = tva == 1 && margeHT > 0 ? margeHT / 1.2 : margeHT; margeHT = Math.round(margeHT * 100, 2) / 100;
            $("#prix_remise_marchand_" + idVehicule).parent().find(".marge-ht").html(
                margeHT.toString().replace(/(?=(\d\d\d)+(?!\d))/g, " ").replace(".", ",").trim() + " €"
            );
            updatePropositionTotal();
        }

        function updatePropositionTotal() {
            writeTotal = function (type, total) { $("#proposition_vehicule_email-total-" + type).html((Math.round(total * 100) / 100).toString().replace(/(?=(\d\d\d)+(?!\d))/g, " ").replace(".", ",").trim() + " €"); }
            // prix, remise
            let prixTotal = 0; let remiseTotal = 0; let prix = null; let prixRemise = null; const prixEl = $("#proposition_vehicule_email-table").find("[id^='proposition_vehicule_email-table-prix-']");
            for (let index = 0; index < prixEl.length; index++) {
                prix = parseFloat($(prixEl[index]).html().replaceAll("-", "0").replaceAll(" ", "").replaceAll("€", "").replaceAll(",", ".").trim());
                prixRemise = parseInt(($("#edit_prix_remise_marchand_" + prixEl[index].id.replace("proposition_vehicule_email-table-prix-", "")).text().match(/\d+/g) ?? ["0"]).join(""));
                prixTotal += (prixRemise > 0 ? prixRemise : prix);
                remiseTotal += (prixRemise > 0 ? prix - prixRemise : 0);
            }
            writeTotal("prix", prixTotal);
            writeTotal("remise", remiseTotal);
            // marge
            let margeTotal = 0; const margeEl = $("#proposition_vehicule_email-table").find(".marge-ht");
            for (let index = 0; index < margeEl.length; index++) {
                margeTotal += parseFloat($(margeEl[index]).html().replaceAll("-", "0").replaceAll(" ", "").replaceAll("€", "").replaceAll(",", ".").trim());
            }
            writeTotal("marge", margeTotal);
            // fre
            let freTotal = 0; const freEl = $("#proposition_vehicule_email-table").find("[id^='proposition_vehicule_email-table-fre-']");
            for (let index = 0; index < freEl.length; index++) {
                freTotal += parseFloat($(freEl[index]).attr("data-order").replaceAll("-", "0").replaceAll(" ", "").replaceAll("€", "").replaceAll(",", ".").trim());
            }
            writeTotal("fre", freTotal);
        }

        $('body').on('click', "[id^='proposition_vehicule_email-table-trash-']", function (e) {
            let idVehicule = this.id.replace("proposition_vehicule_email-table-trash-", "");
            let listId = $("#proposition_vehicule_email-list").data("list").toString() == "" ? [] : $("#proposition_vehicule_email-list").data("list").toString().split(",");
            let listIdVF = $("#proposition_vehicule_email-list").data("listvf").toString() == "" ? [] : $("#proposition_vehicule_email-list").data("listvf").toString().split(",");
            if (listId.length + listIdVF.length > 1 && (listId[0] != "" ||  listIdVF[0] != "")) {
                let listIdEl = $("#proposition_vehicule_email-table").find(".proposition_vehicule_email-table-id");
                listId = [];
                for (let index = 0; index < listIdEl.length; index++) {
                    if (parseInt(listIdEl[index].innerHTML) != idVehicule && !listIdEl[index].innerHTML.startsWith('vf')) {
                        listId.push(parseInt(listIdEl[index].innerHTML));
                    }
                }
                listIdVF = [];
                for (let index = 0; index < listIdEl.length; index++) {
                    if (listIdEl[index].innerHTML != idVehicule && listIdEl[index].innerHTML.startsWith('vf')) {
                        listIdVF.push(listIdEl[index].innerHTML);
                    }
                }
                let form = new FormData();
                form.append('component', "proposition_vehicule_email_trash");
                form.append('timestamp', $("#proposition_vehicule_email_page").data("idsession"));
                form.append('listId', listId);
                form.append('listIdVF', listIdVF);
                $.ajax({
                    type: 'POST',
                    url: siteURL + 'admin/stock/proposition/component',
                    contentType: false,
                    processData: false,
                    data: form,
                    success: function () {
                        $("#proposition_vehicule_email-table-trash-" + idVehicule).parents("tr").remove();
                        $("#proposition_vehicule_email-list").data("list", listId.join(","));
                        $("#proposition_vehicule_email-list").data("listvf", listIdVF.join(","));
                        toastr.success('Le véhicule a été retiré.', 'Succes');
                        updatePropositionTotal();
                        disableSendBtn();
                        cacheOuVisibleBoutonSendEmail();
                    }
                });
            } else {
                toastr.error('Vous ne pouvez pas avoir moins d\'un véhicule.', 'Erreur');
            }
        });

        $('body').on('click', "#proposition_vehicule_email-table-add, #proposition_vehicule_email-modaladd-affichage-stock", function (e) {
            $("#proposition_vehicule_email-modaladd").find(".proposition_vehicule_email-modal-body").addClass("d-none");
            $("#proposition_vehicule_email-modaladd").find(".proposition_vehicule_email-modal-load").removeClass("d-none");
            $('#proposition_vehicule_email-modaladd-affichage-stock').addClass('btn-outline-primary');
            $('#proposition_vehicule_email-modaladd-affichage-stock').removeClass('btn-primary');
            $('#proposition_vehicule_email-modaladd-affichage-stockFournisseur').removeClass('btn-outline-primary');
            $('#proposition_vehicule_email-modaladd-affichage-stockFournisseur').addClass('btn-primary');
            let form = new FormData();
            form.append('component', "proposition_vehicule_email_add");
            form.append('listId', $("#proposition_vehicule_email-list").data("list"));
            form.append('listIdVF', $("#proposition_vehicule_email-list").data("listvf"));
            form.append('isPro', $("#proposition_vehicule_email-annuaire").find(":selected").data("ispro"));
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/stock/proposition/component',
                contentType: false,
                processData: false,
                data: form,
                success: function (html) {
                    $("#proposition_vehicule_email-modaladd").find(".proposition_vehicule_email-modal-body").html(html);
                    proposition_vehicule_email_table = $("#proposition_vehicule_email-tableadd").DataTable({
                        "autoWidth": false,
                        "language": { "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/fr-FR.json" },
                        "paging": false,
                        "responsive": true,
                        "order": [[1, "asc"], [2, "asc"]],
                        "conditionalPaging": true,
                        "columnDefs": [
                            { type: 'num', targets: [0, 4, 5] },
                            { "className": "text-center align-middle", "targets": '_all' }, // ALIGN MIDDLE
                            { "targets": [8], "orderable": false }
                        ]
                    });
                    $('#proposition_vehicule_email-tableadd').on('draw.dt', function () {
                        $("#proposition_vehicule_email-modaladd").find(".proposition_vehicule_email-modal-load").addClass("d-none");
                        $("#proposition_vehicule_email-modaladd").find(".proposition_vehicule_email-modal-body").removeClass("d-none");
                    });
                }
            });
        });

        $('body').on('click', "#proposition_vehicule_email-modaladd-affichage-stockFournisseur", function (e) {
            $("#proposition_vehicule_email-modaladd").find(".proposition_vehicule_email-modal-body").addClass("d-none");
            $("#proposition_vehicule_email-modaladd").find(".proposition_vehicule_email-modal-load").removeClass("d-none");
            $('#proposition_vehicule_email-modaladd-affichage-stockFournisseur').addClass('btn-outline-primary');
            $('#proposition_vehicule_email-modaladd-affichage-stockFournisseur').removeClass('btn-primary');
            $('#proposition_vehicule_email-modaladd-affichage-stock').removeClass('btn-outline-primary');
            $('#proposition_vehicule_email-modaladd-affichage-stock').addClass('btn-primary');
            let form = new FormData();
            form.append('component', "proposition_vehicule_email_addVF");
            form.append('listId', $("#proposition_vehicule_email-list").data("list"));
            form.append('listIdVF', $("#proposition_vehicule_email-list").data("listvf"));
            form.append('isPro', $("#proposition_vehicule_email-annuaire").find(":selected").data("ispro"));
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/stock/proposition/component',
                contentType: false,
                processData: false,
                data: form,
                success: function (html) {
                    $("#proposition_vehicule_email-modaladd").find(".proposition_vehicule_email-modal-body").html(html);
                    proposition_vehicule_email_table = $("#proposition_vehicule_email-tableadd").DataTable({
                        "autoWidth": false,
                        "language": { "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/fr-FR.json" },
                        "paging": false,
                        "responsive": true,
                        "order": [[1, "asc"], [2, "asc"]],
                        "conditionalPaging": true,
                        "columnDefs": [
                            { type: 'num', targets: [0, 4, 5] },
                            { "className": "text-center align-middle", "targets": '_all' }, // ALIGN MIDDLE
                            { "targets": [8], "orderable": false }
                        ]
                    });
                    $('#proposition_vehicule_email-tableadd').on('draw.dt', function () {
                        $("#proposition_vehicule_email-modaladd").find(".proposition_vehicule_email-modal-load").addClass("d-none");
                        $("#proposition_vehicule_email-modaladd").find(".proposition_vehicule_email-modal-body").removeClass("d-none");
                    });
                }
            });
        });

        $('body').on('click', "[id^='proposition_vehicule_email-tableadd-add-']", function (e) {
            let idVehicule = this.id.replace("proposition_vehicule_email-tableadd-add-", "");
            let isVF = idVehicule.startsWith('vf');
            let form = new FormData();
            form.append('component', "proposition_vehicule_email_list_row");
            form.append('timestamp', $("#proposition_vehicule_email_page").data("idsession"));
            form.append('id', idVehicule);
            form.append('isPro', $("#proposition_vehicule_email-annuaire").find(":selected").data("ispro"));
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/stock/proposition/component',
                contentType: false,
                processData: false,
                data: form,
                success: function (data) {
                    if (data != "") {
                        if (isVF) {
                            let listIdVF = $("#proposition_vehicule_email-list").data("listvf").toString().split(",");
                            listIdVF[listIdVF.length] = idVehicule;
                            listIdVF.sort(function (a, b) { return parseInt(a) - parseInt(b) });
                            $("#proposition_vehicule_email-list").data("listvf", listIdVF.join(","));
                        } else {
                            let listId = $("#proposition_vehicule_email-list").data("list").toString().split(",");
                            listId[listId.length] = idVehicule;
                            listId.sort(function (a, b) { return parseInt(a) - parseInt(b) });
                            $("#proposition_vehicule_email-list").data("list", listId.join(","));
                        }
                        proposition_vehicule_email_table.row($("#proposition_vehicule_email-tableadd-add-" + idVehicule).parents("tr")).node().remove();
                        let listIdEl = $("#proposition_vehicule_email-table").find("[id^='proposition_vehicule_email-table-id-']");
                        let added = false;
                        let ligneAInserer = $(data);
                        listIdEl.each(function(index, element) {
                            let marqueExistante = $(element).attr('data-marqueNameRewrite');
                            let modeleExistante = $(element).attr('data-modeleNameRewrite');

                            if (marqueExistante > ligneAInserer.attr('data-marqueNameRewrite') || (marqueExistante === ligneAInserer.attr('data-marqueNameRewrite') && modeleExistante > ligneAInserer.attr('data-modeleNameRewrite'))) {
                                $(ligneAInserer).insertBefore($(element))
                                added = true;
                                return false;
                            }
                        });
                        if (!added) { $("#proposition_vehicule_email-table").find("tbody").append(data); }
                        toastr.success('Le véhicule a été ajouté.', 'Succes');
                        updatePropositionTotal();
                        disableSendBtn();
                        cacheOuVisibleBoutonSendEmail();
                    } else {
                        toastr.error('Le véhicule n\'a pas pu être ajouté.', 'Erreur');
                    }
                }
            });
        });

        if ($("#proposition_vehicule_email-relance").length) {
            $('body').on('click', "[id^='annuaire-suiviRelation-edit-isRelance-']", function (e) {
                $("[id^='annuaire-suiviRelation-edit-isRelance-on-']").removeClass("btn-success").addClass("btn-outline-success");
                $("[id^='annuaire-suiviRelation-edit-isRelance-off-']").removeClass("btn-danger").addClass("btn-outline-danger");
                if ($(this).data("value") == "on") {
                    $("[id^='annuaire-suiviRelation-edit-isRelance-on-']").removeClass("btn-outline-success").addClass("btn-success");
                    $("[id^='annuaire-suiviRelation-edit-relanceCouleur-']").parent().addClass("d-flex").removeClass("d-none");
                    $("[id^='annuaire-suiviRelation-edit-relanceDateDebut-']").parent().removeClass("d-none");
                    $("[id^='annuaire-suiviRelation-edit-relanceDateFin-']").parent().removeClass("d-none");
                } else {
                    $("[id^='annuaire-suiviRelation-edit-isRelance-off-']").removeClass("btn-outline-danger").addClass("btn-danger");
                    $("[id^='annuaire-suiviRelation-edit-relanceCouleur-']").parent().addClass("d-none").removeClass("d-flex");
                    $("[id^='annuaire-suiviRelation-edit-relanceDateDebut-']").parent().addClass("d-none");
                    $("[id^='annuaire-suiviRelation-edit-relanceDateFin-']").parent().addClass("d-none");
                }
            });

            $('body').on('click', "[id^='annuaire-suiviRelation-edit-relanceCouleur-']", function (e) {
                $("input[id^='annuaire-suiviRelation-edit-relanceCouleur-']").val($(this).parent().data("hexa-color"));
            });

            $("[id^='annuaire-suiviRelation-edit-relanceDateDebut-']").datetimepicker(annuaireSuiviRelationEditDatetimepickerOptions($("[id^='annuaire-suiviRelation-edit-relanceDateDebut-']")[0].id));

            $('body').on('click', "[id^='annuaire-suiviRelation-edit-relanceDateDebut-']", function (e) {
                $(this).datetimepicker(annuaireSuiviRelationEditDatetimepickerOptions($(this)[0].id, $(this).val()));
            });
        }

        function disableSendBtn(disable = true) {
            if ($("#proposition_vehicule_email_page").data("idtype") != "tmp") {
                if (disable) {
                    $("#proposition_vehicule_email-document-btn-check").addClass("d-none");
                    $("#proposition_vehicule_email-document-btn-send").addClass("d-none");
                } else {
                    $("#proposition_vehicule_email-document-btn-check").removeClass("d-none");
                    $("#proposition_vehicule_email-document-btn-send").removeClass("d-none");
                }
            }
        }

        $('body').on('click', "#proposition_vehicule_email-document-btn-save", function (e) {
            let params = propositionVehiculeEmailDocumentGetData();
            if (params != null) {
                if (params.listId != '' || params.listIdVF != '') {
                    let form = new FormData();
                    form.append("id", ($("#proposition_vehicule_email_page").data("idtype") == "tmp" ? 0 : $("#proposition_vehicule_email_page").data("idsession")));
                    Object.keys(params).forEach(param => {
                        if (params.hasOwnProperty(param)) {
                            form.append(param, params[param]);
                        }
                    });
                    $.ajax({
                        type: 'POST',
                        url: siteURL + "admin/stock/proposition-vehicule-email/post/save",
                        contentType: false,
                        processData: false,
                        data: form,
                        success: function (id) {
                            toastr.success("Enregistrement réussi", "Succès");
                            if ($("#proposition_vehicule_email_page").data("idtype") == "tmp") {
                                window.location.href = window.location.href.replace(/token\/\d+$/, id);
                            } else {
                                disableSendBtn(false);
                            }
                            cacheOuVisibleBoutonSendEmail();
                        }
                    });
                } else {
                    toastr.error("Veuillez renseigner au moins un véhicule pour enregistrer la proposition", "Erreur");
                }
            }
        });

        $('body').on('click', "#proposition_vehicule_email-document-btn-check", function (e) {
            Lobibox.window({
                title: "Prévisualisation de l'e-mail",
                baseClass: "modal-content modal-lobistrap",
                width: 823, // Pour une prévisualisation de 600px + largeur des paddings de la modal + largeur de la modal
                url: siteURL + "admin/stock/proposition-vehicule-email/post/check",
                autoload: true,
                loadMethod: "POST",
                params: { "id": $("#proposition_vehicule_email_page").data("idsession") }
            });
        });

        let lobiEnvoi = null;
        $('body').on('click', "#proposition_vehicule_email-document-btn-send", function (e) {
            if (checkVehiculeVendu() != false) {
                cacheOuVisibleBoutonSendEmail();
            } else {
                lobiEnvoi = Lobibox.window({
                    title: "Envoi de l'offre",
                    baseClass: "modal-content modal-lobistrap",
                    width: 1000,
                    url: siteURL + "admin/stock/proposition-vehicule-email/post/send",
                    autoload: true,
                    loadMethod: "POST",
                    params: { "id": $("#proposition_vehicule_email_page").data("idsession"), "idEmployeSelectionne" : $("#proposition_vehicule_email-employe").val() }
                });
            }
        });

        $('body').on('click', "#proposition_vehicule_email-envoi-btn", function (e) {
            $("#proposition_vehicule_email-envoi-btn").prop("disabled", true);
            let toEmailEl = $("[id^='proposition_vehicule_email-envoi-email-']:checked");
            let toEmail = [];
            for (let index = 0; index < toEmailEl.length; index++) { toEmail[index] = toEmailEl[index].getAttribute("data-email"); }
            if (toEmail.length) {
                let form = new FormData();
                form.append("id", $("#proposition_vehicule_email_page").data("idsession"));
                form.append("toEmail", toEmail);
                form.append("copy", $("#proposition_vehicule_email-envoi-copy").val());
                form.append("objet", $("#proposition_vehicule_email-envoi-objet").val());
                form.append("message", $("#proposition_vehicule_email-envoi-message").val());
                $.ajax({
                    type: 'POST',
                    url: siteURL + "admin/stock/proposition-vehicule-email/post/send/mail",
                    contentType: false,
                    processData: false,
                    data: form,
                    success: function (dateEnvoi) {
                        if (dateEnvoi) {
                            $("#proposition_vehicule_email-document-dateEnvoi").html(dateEnvoi);
                            lobiEnvoi.destroy();
                            toastr.success("Envoi de l'offre véhicule réussi", "Succès");
                        } else {
                            toastr.error("L'envoi de l'offre véhicule a échoué", "Erreur");
                        }
                        $("#proposition_vehicule_email-envoi-btn").prop("disabled", false);
                    }
                });
            } else {
                toastr.error("Aucun destinataire n'est désigné.", "Erreur");
                $("#proposition_vehicule_email-envoi-btn").prop("disabled", false);
            }
        });

        function propositionVehiculeEmailDocumentGetData() {
            let listId = $("#proposition_vehicule_email-list").data("list").toString();
            let listIdVF = $("#proposition_vehicule_email-list").data("listvf").toString();
            let vendeur = $("#proposition_vehicule_email-user").find(":selected").val();
            let centre = $("#proposition_vehicule_email-centre").find(":selected").val();
            let annuaire = $("#proposition_vehicule_email-annuaire").find(":selected").val();
            let employe = $("#proposition_vehicule_email-employe").find(":selected").val();
            let prixRemiseArray = {};
            let prixRemise = null;
            listId.split(",").forEach(id => {
                prixRemise = $("#edit_prix_remise_marchand_" + id).text().match(/\d+/g);
                prixRemiseArray[id] = parseFloat((prixRemise ? prixRemise.join("") : 0));
            });
            listIdVF.split(",").forEach(id => {
                prixRemise = $("#edit_prix_remise_marchand_" + id).text().match(/\d+/g);
                prixRemiseArray[id] = parseFloat((prixRemise ? prixRemise.join("") : 0));
            });
            let data = {
                listId: listId,
                listIdVF: listIdVF,
                vendeur: vendeur,
                centre: centre,
                annuaire: annuaire,
                employe: employe,
                prixRemise: JSON.stringify(prixRemiseArray),
                relance: JSON.stringify(($("#proposition_vehicule_email-relance").length ? {
                    isRelance: $("[id^='annuaire-suiviRelation-edit-isRelance-on-']").hasClass("btn-success"),
                    relanceCouleur: $("input[id^='annuaire-suiviRelation-edit-relanceCouleur-']").val(),
                    relanceDateDebut: new Date($("[id^='annuaire-suiviRelation-edit-relanceDateDebut-']").val().replace(/(\d+)[/](\d+)[/](\d+)/, '$3-$2-$1')).toISOString(),
                    relanceDateFin: (new Date((new Date($("[id^='annuaire-suiviRelation-edit-relanceDateDebut-']").val().replace(/(\d+)[/](\d+)[/](\d+)/, '$3-$2-$1'))).getTime() + (parseInt($("[id^='annuaire-suiviRelation-edit-relanceDateFin-']").val()) * 1000))).toISOString()
                } : {})),
                appel: ($("#proposition_vehicule_email-appel").length ? $("#proposition_vehicule_email-appel").data("appel") : 0)
            };
            if (propositionVehiculeEmailDocumentCheckData(data)) { return data; }
            return null;
        }

        function propositionVehiculeEmailDocumentCheckData({ vendeur = null, centre = null, annuaire = null, employe = null } = {}) {
            let error_message = "";
            if (vendeur == undefined || vendeur.length == 0) { error_message += "Le vendeur n\'a pas été renseigné.<br/>"; }
            if (centre == undefined || centre.length == 0) { error_message += "Le centre n\'a pas été renseigné.<br/>"; }
            if (annuaire == undefined || annuaire.length == 0) { error_message += "Le client n\'a pas été renseigné.<br/>"; }
            else { if (employe == undefined || employe.length == 0) { error_message += "L'employé du client n\'a pas été renseigné.<br/>"; } }
            if (error_message == "") { return true; }
            toastr.error(error_message, "Erreur");
            return null;
        }

        function checkVehiculeVendu() {
            let etatVehicules = $("[id^='etat_vehicule_']");
            let vendu = false;
            let idVehiculesVendus = [];
            etatVehicules.each(function (index) {
                if ($(this).children().hasClass('badge-danger')) {
                    vendu = true;
                    idVehiculesVendus.push($(this).data('id'));
                }
            });
            if (vendu == true) {
                return idVehiculesVendus;
            } else {
                return false;
            }
        }

        function cacheOuVisibleBoutonSendEmail() {
            if (checkVehiculeVendu() != false) {
                toastr.error("Envoi impossible. Les véhicules " + checkVehiculeVendu().join(', ') + " sont déjà vendus. Retirer ces véhicules de la proposition pour l'envoyer.", "Erreur");
                $("#proposition_vehicule_email-document-btn-send").css('cursor', 'not-allowed');
                $("#proposition_vehicule_email-document-btn-send").attr('title', 'Véhicule(s) ' + checkVehiculeVendu().join(', ') + " à supprimer pour envoyer le mail.");
            } else {
                $("#proposition_vehicule_email-document-btn-send").css('cursor', 'pointer');
                $("#proposition_vehicule_email-document-btn-send").attr('title', 'Envoyer le mail');
            }
        }

        updatePropositionTotal();
    }
});