$(document).ready( function () {

	// Génération de la datatable des évènements
    evenement_table = $('#evenements_table').DataTable( {
        "autoWidth": false,
    	"language": {
            "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/fr-FR.json"
        },
        "responsive": true,
    	"ajax": '../json/evenements',
    	"order": [[ 1, "desc" ]],
    	"columnDefs": [
            // ALIGN MIDDLE
            {
                "className": "align-middle",
                "targets": '_all',
            },
            // DATE
    		{
    			"targets": 1,
    			"render": function ( data, type, row ) {
    				var monthNames = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];
    				var from = data.split('/');
    				var to = row[2].split('/');
                    return "du "+from[2]+" "+monthNames[eval(from[1]) - 1]+" "+from[0] + ' au '+ to[2]+" "+monthNames[eval(to[1]) - 1]+" "+to[0];
                },
    		},
    		// EMPLACEMENT
    		{
                "targets": 2,
    			"render": function ( data, type, row ) {
                    let display = [];
                    row[3].forEach( function(e) {
                    	switch (e) {
                    		case 'ORA1':
                    			display.push('Lyon-Sérézin');
                    			break;
                    		case 'ORA2':
                    			display.push('Neuville-sur-Saône');
                    			break;
                    		case 'ORA6':
                    			display.push('Montélimar');
                    			break;
                    		case 'ARR':
                    			display.push('En arrivage');
                    			break;
                    		default:
                    			break;
                    	}
                    });
                    return display;
                },
                "orderable": false,
    		},
    		// OFFRE COMMERCIALE
    		{
                "targets": 3,
    			"render": function ( data, type, row ) {
                    switch (row[4]) {
                    	case 'RISC':
                    		return "Remises Immédiates sans conditions";
                    	case 'CGOMI':
                    		return "Carte Grise offerte (Malus Inclus)";
                    	case 'CGOHM':
                    		return "Carte Grise offerte (Hors Malus)";
                    	case '24MOIS':
                    		return "Garantie 24 Mois Offerte";
                    	default :
                    		return row[4];
                    }
                },
    		},
    		// MODIFY
    		{
    			"width": "50px",
                "targets": 4,
    			"render": function ( data, type, row ) {
                    return '<button class="btn w-100 p-0" id="evenement-open-modify-'+row[5]+'"><i class="fad fa-edit"></i>';
                },
                "orderable": false,
    		},
    	],
    });

  	// Ouvre la fenêtre de recherche des véhicules après un click
	$('body').on('click', "button[id^='evenement-open-vehicules-']", function() {
		var id_clicked = this.id.replace('evenement-open-vehicules-', '');
		// On vérifie que le tab en question n'existe pas déjà
		if ($("#evenement-open-vehicules-"+id_clicked+"-tab").length == 0) {
			if (id_clicked != 0) {
				$('#evenement-tabs-modify-'+id_clicked+"-tab").parent().after('<li class="nav-item"><a class="nav-link" id="evenement-tabs-vehicules-'+id_clicked+'-tab" data-toggle="pill" href="#evenement-tabs-vehicules-'+id_clicked+'" role="tab" aria-controls="evenement-tabs-vehicules-'+id_clicked+'" aria-selected="false"><i class="pr-2 fas fa-search"></i>'+id_clicked+'<span class="d-none d-md-inline"> | Recherche de Véhicules</span><i class="pl-2 far fa-times" id="evenement-close-vehicules-'+id_clicked+'"></i></a></li>');
			}
			else {
				$('#evenement-ajout').after('<li class="nav-item"><a class="nav-link" id="evenement-tabs-vehicules-'+id_clicked+'-tab" data-toggle="pill" href="#evenement-tabs-vehicules-'+id_clicked+'" role="tab" aria-controls="evenement-tabs-vehicules-'+id_clicked+'" aria-selected="false"><i class="pr-2 fas fa-search"></i> Nouveau <span class="d-none d-md-inline">| Recherche de Véhicules</span><i class="pl-2 far fa-times" id="evenement-close-vehicules-'+id_clicked+'"></i></a></li>');
			}
			 $.ajax({
	            type: 'POST',
	            url: '../admin/evenement/search_vehicules',
	            data: { 'evenement_id': id_clicked },
	            success: function(data) {
	                $('#evenement-tabs-list').after('<div class="tab-pane fade" id="evenement-tabs-vehicules-'+id_clicked+'" role="tabpanel" aria-labelledby="evenement-tabs-vehicules-'+id_clicked+'-tab">'+data+'</div>');
	                $.ajax({
			            type: 'POST',
			            url: '../json/marques',
			            success: function(data) {
			                for (id in data['marques']) {
								$("[name='evenement_vehicules_marque_"+id_clicked+"']").append('<option value='+data['marques'][id]['id']+">"+data['marques'][id]['marque_name']+"</option>");
			                };
			            }
			        });
	                $('#evenement-tabs-vehicules-'+id_clicked+'-tab').trigger('click');
	            }
	        });
		}
		return false;
	});

	// Recupération des modèles disponibles dans la page de recherche après un choix de marque
	$('body').on('change', "[name^='evenement_vehicules_marque_']", function () {
		var id_clicked = this.name.replace('evenement_vehicules_marque_', '');
		$.ajax({
            type: 'POST',
            url: '../json/modeles',
            data: 'marque=' + $(this).val()+',',
            success: function(data) {
            	$("[name='evenement_vehicules_modele_"+id_clicked+"']").empty();
            	$("[name='evenement_vehicules_modele_"+id_clicked+"']").append('<option value=""> -- </option>');
                for (id in data[0]) {
                    if (data[0][id]['nb'] > 0){
                         $("[name='evenement_vehicules_modele_"+id_clicked+"']").append('<option value='+data[0][id]['id']+">"+id+ "</option>");
                     }
                };

            }
        });
        $('#evenement-tabs-vehicules-'+id_clicked+'-tab').trigger('click');
	});

	// Permets de fermer un ou plusieurs onglets
	// Il s'agit de la même fonction pour les 3 fenêtres possibles
	$('body').on('click', "[id^='evenement-close-']", function() {
		var this_id = this.id.replace('evenement-close-', '');
		id_clicked = this_id.split('-')[1];
		function_clicked = this_id.split('-')[0];

		$(this).parent().remove();
		$('#evenement-tabs-'+function_clicked+'-'+id_clicked).remove();
		switch (function_clicked) {
			case 'modify':
				$('#evenement-close-vehicules-'+id_clicked).trigger('click');
				$('#evenement-close-vehiculeslist-'+id_clicked).trigger('click');
				$('#evenement-tabs-list-tab').trigger('click');
				break;

			case 'vehicules':
			$('#evenement-close-vehiculeslist-'+id_clicked).trigger('click');
			if (id_clicked != 0) {
				$('#evenement-tabs-modify-'+id_clicked+'-tab').trigger('click');
			}
			else {
				$('#evenement-ajout').trigger('click');
			}
			break;

			case 'vehiculeslist':
				$('#evenement-close-vehiculeslist-'+id_clicked).trigger('click');
				$('#evenement-tabs-vehicules-'+id_clicked+'-tab').trigger('click');
				break;
			default:
				return;
		}
	});

	// Envoie la requête permettant de rajouter un véhicule à la liste de l'evenement
	$('body').on('click', "[id^='vehicule_'][id$='_add']", function() {
		var clicked = this.id.replace('vehicule_','').replace('_add', '').split('_');
		var id_clicked = clicked[1];
		var vehicule_clicked = clicked[0];
		if ($(this).is(':checked')) { var event = 'add';} else { var event = 'delete';}
		$.ajax({
            type: 'POST',
            url: '../admin/evenement/check_vehicule',
            data: { 'evenement_id': id_clicked, 'vehicule_id': vehicule_clicked, 'event': event },
            success: function(data) {
            }
        });
	});

	// Ouvre la fenêtre de résultat des véhicules recherches
	// Mets à jour les résulstats si la fenêtre existe déjà
	$('body').on('click', "[name^='evenement-vehicule-recherche-']", function() {
		var id_clicked = this.name.replace('evenement-vehicule-recherche-', '');
		var formresults = [];
		formresults['evenement'] = id_clicked;
		$('[id^="evenement_vehicules_"]:enabled, [name^="evenement_vehicules_"]:enabled').each( function () {
			var index = this.name.replace('evenement_vehicules_','').replace('_'+id_clicked,'');
			if ($(this).val() != '') {
				formresults[index] = $(this).val();
			}
		});
		if (id_clicked != 0 && $("#evenement-tabs-vehiculeslist-"+id_clicked+"-tab").length == 0) {
			$('#evenement-tabs-vehicules-'+id_clicked+"-tab").parent().after('<li class="nav-item"><a class="nav-link" id="evenement-tabs-vehiculeslist-'+id_clicked+'-tab" data-toggle="pill" href="#evenement-tabs-vehiculeslist-'+id_clicked+'" role="tab" aria-controls="evenement-tabs-vehiculeslist-'+id_clicked+'" aria-selected="false"><i class="pr-2 fas fa-list"></i>'+id_clicked+'<span class="d-none d-md-inline"> | Liste de Véhicules</span><i class="pl-2 far fa-times" id="evenement-close-vehiculeslist-'+id_clicked+'"></i></a></li>');
		}
		else if (id_clicked == 0 && $("#evenement-tabs-vehiculeslist-"+id_clicked+"-tab").length == 0){
			$('#evenement-tabs-vehicules-'+id_clicked+"-tab").parent().after('<li class="nav-item"><a class="nav-link" id="evenement-tabs-vehiculeslist-'+id_clicked+'-tab" data-toggle="pill" href="#evenement-tabs-vehiculeslist-'+id_clicked+'" role="tab" aria-controls="evenement-tabs-vehiculeslist-'+id_clicked+'" aria-selected="false"><i class="pr-2 fas fa-list"></i> Nouveau <span class="d-none d-md-inline">| Liste de Véhicules</span><i class="pl-2 far fa-times" id="evenement-close-vehiculeslist-'+id_clicked+'"></i></a></li>');
		}
		$.ajax({
            type: 'POST',
            url: '../admin/evenement/list_vehicules',
            data: { 'evenement_id': id_clicked },
            success: function(data) {

            		if ($("#evenement-tabs-vehiculeslist-"+id_clicked).length == 0) {
                		$('#evenement-tabs-list').after('<div class="tab-pane fade" id="evenement-tabs-vehiculeslist-'+id_clicked+'" role="tabpanel" aria-labelledby="evenement-tabs-vehiculeslist-'+id_clicked+'-tab">'+data+'</div>');
            		}
            		else {
            			$('#evenement-tabs-vehiculeslist-'+id_clicked).remove();
            			$('#evenement-tabs-list').after('<div class="tab-pane fade" id="evenement-tabs-vehiculeslist-'+id_clicked+'" role="tabpanel" aria-labelledby="evenement-tabs-vehiculeslist-'+id_clicked+'-tab">'+data+'</div>');
            		}

                    evenement_vehicules_table = $('#evenements_vehicules_table').DataTable( {
                    	"autoWidth": false,
				    	"language": {
				            "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/fr-FR.json"
				        },
				        "responsive": true,
				    	"ajax": {
				    		'url': '../json/vehicules',
				    		'data': formresults,
				    		'type': 'POST',
				    	},
				    	"order": [[ 1, "desc" ]],
				    	"lengthMenu": [[15, 30, 50, 100, -1], [15, 30, 50, 100, "Toutes"]],
				    	"columnDefs": [
				    		{
				    			"targets": 8,
				    			"render": function ( data, type, row ) {
				                    switch (row[8]) {
				                    	case 'GO':
				                    		return 'Diesel';
				                    	case 'ES':
				                    		return 'Essence';
				                    	case 'EH':
				                    		return 'Hybride';
				                    	default:
				                    		return row[8];
				                    }
				                },
				    		},
				    		{
				    			"targets": 9,
				    			"render": function ( data, type, row ) {
				                    return row[9]+' &euro; TTC ';
				                },
				    		},
				    		{
				    			"width": "50px",
				    			"targets": 10,
				    			"render": function ( data, type, row ) {
				                    return '<div class="form-check text-center"><input class="form-check-input" type="checkbox" value="" id="vehicule_'+row[0]+'_'+id_clicked+'_add"'+data+'></div>';
				                },
				                "orderable": false,
				    		},

				    	],
				    });
				$('#evenement-tabs-vehiculeslist-'+id_clicked+'-tab').trigger('click');
            }
        });
	});

	// Ouvre la fenêtre de résultat des véhicules recherches en interceptant l'appui sur la touche Entrée
	// Mets à jour les résulstats si la fenêtre existe déjà
	$('body').on('keypress', "[id^='evenement_vehicules_']", function(e) {
		var id_clicked = this.id.replace('evenement_vehicules_','').split('_').pop();

		// Intercepte l'appui sur la touche entrée et simule un click
		var keycode = (e.keyCode ? e.keyCode : e.which);
		if(keycode == '13'){
			$('#evenement-vehicule-recherche-'+id_clicked).trigger('click');
		}
		event.stopPropagation();
	});

	// Mets à jour le contenu des modifications d'un évènement
	// Si l'évènement porte l'id 0 alors il s'agit d'un nouvel évènement à ajouter
	$('body').on('click', "button[id^='evenement-send-modify-']", function() {
		var id_clicked = this.id.replace('evenement-send-modify-', '');

		var form = new FormData();
        form.append('id', id_clicked);
        form.append('evenement_date_debut', $('#evenement_date_debut_'+id_clicked).val());
        form.append('evenement_date_fin', $('#evenement_date_fin_'+id_clicked).val());
        form.append('evenement_nom', $('#evenement_nom_'+id_clicked).val());
        form.append('evenement_offrecommerciale', $('select[name="evenement_offrecommerciale_'+id_clicked+'"]').children("option:selected").val());
        form.append('evenement_type', $('input[name=evenement_type_'+id_clicked+']:checked').val());
        form.append('evenement_balise_alt', $('#evenement_photo_alt_'+id_clicked).val());
        form.append('evenement_balise_h3', $('#evenement_photo_h3_'+id_clicked).val());
        form.append('evenement_slider', $('#file_result_0').val());
        form.append('evenement_publicite', $('#file_result_1').val());
        form.append('evenement_intitule_long', $('#evenement_intitule_long_'+id_clicked).val());
        form.append('evenement_intitule_court', $('#evenement_intitule_court_'+id_clicked).val());
        form.append('evenement_couleur', $('#evenement_couleur_'+id_clicked).val());
        form.append('evenement_couleur_active', $('input[name=evenement_couleur_active_'+id_clicked+']:checked').val());

        // Liste les différents emplacements séléctionnés en supprimant le 'Tous'
        var emplacements = [];
        $('input[name^="evenement_emplacements_'+id_clicked+'"]').each( function() {
        	if ($(this).is(':checked') && this.id != 'evenement_emplacements_'+id_clicked+'_ALL') {
        		emplacements.push(this.id.replace('evenement_emplacements_'+id_clicked+'_', ''));
        	}
        });
        form.append('evenement_emplacements', emplacements);

		$.ajax({
            type: 'POST',
            url: '../admin/evenement/modify',
			cache: false,
			contentType: false,
			processData: false,
            data: form,
            success: function(data) {
            	toastr.success('L\'évènement a bien été enregistré', 'Succès');
            	$('#evenement-tabs-modify-'+id_clicked).remove();
            	$('#evenement-close-modify-'+id_clicked).parent().remove();
				// Si nous sommes sur un nouvel évènement à ajouter uniquement, pas une modification
				if (id_clicked == 0) {
            		var new_click = data;
            		// TODO a reprendre plus tard
      //       		$('#evenement-ajout').after('<li class="nav-item"><a class="nav-link" id="evenement-tabs-modify-'+data+'-tab" data-toggle="pill" href="#evenement-tabs-modify-'+data+'" role="tab" aria-controls="evenement-tabs-modify-'+data+'" aria-selected="false"><i class="pr-2 fas fa-calendar"></i>'+data+' <span class="d-none d-md-inline">| Modifier l\'évènement</span><i class="pl-2 far fa-times" id="evenement-close-modify-'+data+'"></i></a></li>');
					 // $.ajax({
			   //          type: 'POST',
			   //          url: '../admin/evenement/show_modify',
			   //          data: { 'evenement_id': data },
			   //          success: function(data) {
			   //              $('#evenement-tabs-list').after('<div class="tab-pane fade" id="evenement-tabs-modify-'+new_click+'" role="tabpanel" aria-labelledby="evenement-tabs-modify-'+new_click+'-tab">'+data+'</div>');
			   //              $('#evenement-tabs-modify-'+new_click+'-tab').trigger('click');
			   //          }
			   //      });

					$.ajax({
						url: '../admin/evenement/delete_photo',
						type: 'POST',
						data: {'id':id_clicked},
						success: function(data) {
							$("#evenement_photodisplay_"+id_clicked).html("<img style='width:300px' src='https://via.placeholder.com/640x340'>");
							$("#evenement_photo_"+id_clicked).val('');
						}
			    	});
			    	$('#formevenement_'+id_clicked)[0].reset();
				}
				$('#evenement-tabs-list-tab').trigger('click');
				evenement_table.ajax.reload()
            }
        });
        return false;
	});

	// Evenement permettant la selection de tous les stocks
	$('body').on('click', "input[id^='evenement_emplacements_'][id$='_ALL']", function() {
		if ($(this).is(':checked')) {
			$("input[name^='evenement_emplacements_']").prop("checked", true);
		}
		else {
			$("input[name^='evenement_emplacements_']").prop("checked", false);
		}
	});

	// Permets d'éviter que la date de fin d'un évènement soit inférieure à celle du début
	$('body').on('change', "[id^='evenement_date_debut_']", function() {
		var id_clicked = this.id.replace('evenement_date_debut_','');
		if ($('#evenement_date_debut_'+id_clicked).val() > $('#evenement_date_fin_'+id_clicked).val()) {
			$('#evenement_date_fin_'+id_clicked).val('');
		}
		$('#evenement_date_fin_'+id_clicked).attr('min', $('#evenement_date_debut_'+id_clicked).val());
	});
});