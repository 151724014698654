// Génération du lien aramisauto
function aramisautoLinkGenerator(vehicule_fournisseur_marque, vehicule_fournisseur_modele, vehicule_fournisseur_boite, vehicule_fournisseur_carburant, vehicule_fournisseur_date_mec, vehicule_fournisseur_kilometrage) {
    // URL de base
    let url_aramisauto = "https://www.aramisauto.com/achat/";

    if (vehicule_fournisseur_marque.length == 0 && vehicule_fournisseur_modele.length == 0) {
        // Les boites sans marque/modèle
        if (vehicule_fournisseur_boite.length != 0) {
            url_aramisauto = url_aramisauto.replace("/achat/", "/achat/" + (vehicule_fournisseur_boite == "A" ? "automatique" : "manuelle"));
        }
    } else {
        // Les marques et modèles passent en slug
        url_aramisauto = url_aramisauto.replace(
            "/achat/",
            "/voitures/"
            + (vehicule_fournisseur_marque.length != 0 ? vehicule_fournisseur_marque.slugify() : "")
            + (vehicule_fournisseur_modele.length != 0 ? "/" + vehicule_fournisseur_modele.slugify() : "")
        );
        url_aramisauto = url_aramisauto + "/offres/";
        // Les boites
        if (vehicule_fournisseur_boite.length != 0) {
            url_aramisauto = url_aramisauto + (vehicule_fournisseur_boite == "A" ? "automatique" : "manuelle") + "/";
        }
    }
    url_aramisauto = url_aramisauto + "?orderBy=lowestPrice";
    // Les carburants
    if (vehicule_fournisseur_carburant.length != 0) {
        url_aramisauto = url_aramisauto + (
            vehicule_fournisseur_carburant == "ES" ? "&fuelTypes%5Bgasoline%5D%5B0%5D=gasoline"
            : (vehicule_fournisseur_carburant == "GO" ? "&fuelTypes%5Bdiesel%5D%5B0%5D=diesel"
                : (vehicule_fournisseur_carburant == "EH" ? "&fuelTypes%5Bhybrid%5D%5B0%5D=full-hybrid&fuelTypes%5Bhybrid%5D%5B1%5D=plugin-hybrid&fuelTypes%5Bhybrid%5D%5B2%5D=mild-hybrid&fuelTypes%5Bgasoline%5D%5B0%5D=mild-hybrid-gasoline&fuelTypes%5Bdiesel%5D%5B0%5D=mild-hybrid-diesel"
                    : (vehicule_fournisseur_carburant == "EL" ? "&fuelTypes%5Belectric%5D=true"
                        : (vehicule_fournisseur_carburant == "GP" ? "&fuelTypes%5Bgasoline%5D%5B1%5D=lpg" : "")
                    )
                )
            )
        );
    }
    // Kilométrage :
    // Arrondi à la dizaine de millier si ni essence et ni diesel
    //
    // kmPerYearMin = {essence -> 10000, diesel -> 17500}
    // kmPerYearMax = {essence -> 15000, diesel -> 25000}
    // distMin = (année actuel - année MEC) * kmPerYearMin
    // distMax = (année actuel - année MEC) * kmPerYearMax
    // Arrondi à la dizaine de millier si essence ou diesel et si le kilometrage est compris entre distMin et distMax
    // Si < distMin alors distMin
    // Si > distMax alors viellir le véhicule de (année actuel - (kilometrage du véhicule / kmPerYearMax))
    if (vehicule_fournisseur_kilometrage.length != 0) {
        if (vehicule_fournisseur_carburant != "ES" && vehicule_fournisseur_carburant != "GO" && vehicule_fournisseur_carburant != "EH") {
            url_aramisauto = url_aramisauto + "&mileage%5Bkm%5D%5Bmin%5D=" + Math.ceil(parseFloat(vehicule_fournisseur_kilometrage) / 10000) * 10000;
        } else if (vehicule_fournisseur_date_mec.length != 0) {
            yearDiff = (new Date().getFullYear()) - parseInt(vehicule_fournisseur_date_mec) + 1;
            if (vehicule_fournisseur_carburant == "ES" || vehicule_fournisseur_carburant == "EH") {
                let kmPerYearMin = 10000;
                let kmPerYearMax = 15000;
                distMin = yearDiff * kmPerYearMin;
                distMax = yearDiff * kmPerYearMax;
                if (parseFloat(vehicule_fournisseur_kilometrage) < distMin) {
                    vf_kilometrage_url = distMin;
                } else if (parseFloat(vehicule_fournisseur_kilometrage) <= distMax) {
                    vf_kilometrage_url = Math.ceil(parseFloat(vehicule_fournisseur_kilometrage) / 10000) * 10000;
                } else {
                    vf_kilometrage_url = Math.ceil(parseFloat(vehicule_fournisseur_kilometrage) / 10000) * 10000;
                    vehicule_fournisseur_date_mec = parseInt(vehicule_fournisseur_date_mec) - Math.floor(parseFloat(vehicule_fournisseur_kilometrage) / kmPerYearMax);
                }
            } else if (vehicule_fournisseur_carburant == "GO") {
                let kmPerYearMin = 17500;
                let kmPerYearMax = 25000;
                distMin = yearDiff * kmPerYearMin;
                distMax = yearDiff * kmPerYearMax;
                if (parseFloat(vehicule_fournisseur_kilometrage) < distMin) {
                    vf_kilometrage_url = distMin;
                } else if (parseFloat(vehicule_fournisseur_kilometrage) <= distMax) {
                    vf_kilometrage_url = Math.ceil(parseFloat(vehicule_fournisseur_kilometrage) / 10000) * 10000;
                } else {
                    vf_kilometrage_url = Math.ceil(parseFloat(vehicule_fournisseur_kilometrage) / 10000) * 10000;
                    vehicule_fournisseur_date_mec = parseInt(vehicule_fournisseur_date_mec) - Math.floor(parseFloat(vehicule_fournisseur_kilometrage) / kmPerYearMax);
                }
            }
            url_aramisauto = url_aramisauto + "&mileage%5Bkm%5D%5Bmin%5D=" + vf_kilometrage_url;
        }
    }
    // Date de MEC
    if (vehicule_fournisseur_date_mec.length != 0) {
        url_aramisauto = url_aramisauto + "&annee=" + vehicule_fournisseur_date_mec;
    }

    return url_aramisauto;
}
