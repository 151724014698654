let timerBeforeRefreshDataAgenda = null;

$(document).ready(function() {
    if ($("#agenda_page").length) {
        // Pour effacer l'url du goToAgenda
        window.history.pushState("", "", siteURL + "admin/agenda");

        /* initialize the calendar
            -----------------------------------------------------------------*/

        let goToAgenda = $("#agenda_page").data("gotoagenda");

        let Calendar = FullCalendar.Calendar;
        let calendarEl = document.getElementById('agenda-calendar');

        let calendar = new Calendar(calendarEl, {
            locale: "fr",
            initialView: 'timeGridWeek',
            customButtons: {
                filtreButton: {
                    text: 'Filtrer',
                }
            },
            headerToolbar: {
                left: 'prev,next today',
                center: 'title',
                right: 'filtreButton,dayGridMonth,timeGridWeek,timeGridDay,listWeek'
            },
            themeSystem: 'bootstrap5',
            events: [],
            allDaySlot: false,
            firstDay: 1,
            editable: true,
            droppable: false,
            eventMaxStack: 1,
            slotMinTime: "07:30:00",
            slotMaxTime: "20:00:00",
            slotDuration: "00:15:00",
            eventDidMount: function(info) {
                const eventDisplay = info.event._def.extendedProps.eventDisplay;
                const tooltip = new Tooltip(info.el, {
                    title: info.event.extendedProps.description,
                    placement: 'top',
                    trigger: 'hover',
                    container: 'body',
                    template: '<div class="tooltip-agenda" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>',
                    html: true,
                });
                if (info.el.querySelectorAll('.fc-event-title').length) { info.el.querySelectorAll('.fc-event-title')[0].innerHTML = eventDisplay; }
                else { info.el.querySelectorAll('.fc-list-event-title')[0].innerHTML = eventDisplay; }
                // On rajoute un attribut pour l'id de l'event
                $(info.el).attr("id", "event-" + info.event._def.extendedProps.object.id);
            },
            eventChange: function(changeInfo) {
                sendCalendar({
                    "id": changeInfo.event._def.publicId.replace("agenda-event-", ""),
                    "start": changeInfo.event._instance.range.start.toISOString(),
                    "end": changeInfo.event._instance.range.end.toISOString(),
                    "collaborateur": changeInfo.event._def.extendedProps.object.user.id,
                });
            },
            eventClick: function(eventClickInfo) {
                eventClickExternalise(eventClickInfo.event._def);
            },
        });

        calendar.render();

        /* initialize CRUD function
            -----------------------------------------------------------------*/

        function loadCalendar() {
            let options = new FormData();
            options.append('start', calendar.currentData.dateProfile.activeRange.start.toISOString());
            options.append('end', calendar.currentData.dateProfile.activeRange.end.toISOString());
            options.append('user', $("#filtersagenda-collaborateur").val());
            options.append('typeAgenda', $("#filtersagenda-typeAgenda").val());
            options.append('sousTypeAgenda', $("#filtersagenda-sousTypeAgenda").val());

            $.ajax({
                type:'POST',
                url: siteURL + 'admin/agenda/get',
                contentType: false,
                processData: false,
                data: options,
                success: function(data) {
                    calendar.removeAllEvents();
                    data.forEach(element => { refreshCalendarEvent(element) });

                    // Pour rajouter les class et attribut du bouton des filtres
                    $('.fc-filtreButton-button').addClass("btn btn-default text-12 px-4 py-2");
                    $('.fc-filtreButton-button').removeClass("btn-primary");
                    $('.fc-filtreButton-button').attr("data-widget", "control-sidebar");
                    $('.fc-filtreButton-button').attr("data-slide", "true");
                    $('.fc-filtreButton-button').attr("type", "button");
                    $('.fc-more-link').toArray().forEach(element => { $(element).css('background-color', '#EA4335').css('color', 'white'); });
                }
            });
        }

        function sendCalendar(agenda, crud = "post") {
            let error = "";
            if (crud == "post") {
                agenda.start = agenda.start.replace(/(\d+)[/](\d+)[/](\d+)[\s](\d+)[:](\d+)/, '$3-$2-$1T$4:$5:00.000Z');
                agenda.end = agenda.end.replace(/(\d+)[/](\d+)[/](\d+)[\s](\d+)[:](\d+)/, '$3-$2-$1T$4:$5:00.000Z');
                const regexDateCheck = /\d+-\d+-\d+T\d+:\d+:\d+\.\d+Z/;
                if (agenda.start.match(regexDateCheck) == null || agenda.start.match(regexDateCheck)[0] != agenda.start.match(regexDateCheck)["input"]) { error += "La date de début est manquante ou invalide<br>"; }
                if (agenda.end.match(regexDateCheck) == null || agenda.end.match(regexDateCheck)[0] != agenda.end.match(regexDateCheck)["input"]) { error += "La date de fin est manquante ou invalide<br>"; }
                if (!(agenda.label === undefined)) { if (agenda.label == "" && agenda.typeAgenda != "relance") { error += "Le label est manquant<br>"; } }
                if (!(agenda.contenu === undefined)) { if (agenda.contenu == "") { error += "Le commentaire est manquant<br>"; } }
                if (!(agenda.collaborateur === undefined)) { if (agenda.collaborateur == "") { error += "Le collaborateur est manquant<br>"; } }
            }
            if (error != "" && crud == "post") { toastr.error(error, 'Erreur'); }
            else {
                let options = new FormData();
                if (!(agenda.start === undefined)) { options.append('start', (new Date(agenda.start)).toISOString()); }
                if (!(agenda.end === undefined)) { options.append('end', (new Date(agenda.end)).toISOString()); }
                if (!(agenda.typeAgenda === undefined)) { options.append('typeAgenda', agenda.typeAgenda); }
                if (!(agenda.collaborateur === undefined)) { options.append('user', agenda.collaborateur); }
                if (!(agenda.label === undefined)) { options.append('label', agenda.label); }
                if (!(agenda.contenu === undefined)) { options.append('contenu', agenda.contenu); }
                if (!(agenda.couleur === undefined)) { options.append('couleur', agenda.couleur); }
                let realise;
                if ($("#agenda-input-tacheRealisee-oui").hasClass('btn-success')) {
                    realise = true;
                } else if ($("#agenda-input-tacheRealisee-non").hasClass('btn-danger')) {
                    realise = false;
                }
                options.append('crud', crud);
                options.append('realise', realise);
                $.ajax({
                    type: 'POST',
                    url: siteURL + 'admin/agenda/post/'+agenda.id,
                    contentType: false,
                    processData: false,
                    data: options,
                    success: function(data) {
                        if (crud == "post") { toastr.success('La modification de l\'évènement à bien été enregistré', 'Succès'); }
                        else {
                            if (data.status == "ok") { toastr.success('La suppression de l\'évènement à bien été effectué', 'Succès'); }
                            else { toastr.error('La suppression de l\'évènement à échoué', 'Erreur'); }
                        }
                        resetCalendarForm();
                        refreshCalendarEvent(data, crud);
                        if (agenda.typeAgenda == "rendez-vous" && agenda.annuaire != null) {
                            if (data.object.annuaire != null) {
                                let options = "";
                                if (data.object.annuaire.email != null && data.object.annuaire.email != "") {
                                    options += "<option value=\""+data.object.annuaire.email+"\">"+data.object.annuaire.email+"</option>";
                                }
                                if (data.object.annuaire.employeEntreprises != null) {
                                    data.object.annuaire.employeEntreprises.forEach(employeMail => {
                                        options += "<option value=\""+employeMail+"\">"+employeMail+"</option>";
                                    });
                                }
                                $("#agenda-input-ics-select-client").html(options);
                            }
                            else { $("#agenda-input-ics-select-client").html(""); }
                            $("#agenda-input-ics-checkbox-client").prop("disabled", data.object.annuaire == null);
                            $("#agenda-input-ics-select-client").prop("disabled", data.object.annuaire == null);
                            $("#agenda-input-ics-checkbox-client").prop("checked", false);
                            $("#agenda-input-ics-checkbox-commercial").prop("checked", true);
                            $("#agenda-input-ics-select-commercial").html("<option value=\""+data.object.user.email+"\">"+data.object.user.email+"</option>");
                            $("#agenda-input-ics").data("id", data.object.id);
                            $("#agenda-modal-ics").modal('show');
                        }
                    },
                    error: function() {
                        if (crud == "post") { toastr.error('La modification de l\'évènement à échoué', 'Erreur'); }
                        else { toastr.error('La suppression de l\'évènement à échoué', 'Erreur'); }
                    }
                });
            }
        }

        /* initialize event -----------------------------------------------------------------*/

        setupLoadAjax(loadCalendar);
        setupResetFilter(resetFilter);

        $("body").on("click", ".fc-prev-button", loadCalendar);
        $("body").on("click", ".fc-next-button", loadCalendar);
        $("body").on("click", ".fc-today-button", loadCalendar);
        $("body").on("click", ".fc-dayGridMonth-button", loadCalendar);
        $("body").on("click", ".fc-timeGridWeek-button", loadCalendar);
        $("body").on("click", ".fc-timeGridDay-button", loadCalendar);
        $("body").on("click", ".fc-listWeek-button", loadCalendar);
        $("body").on("click", ".fc-listDay-button", loadCalendar);
        $('body').on('click', "#agenda-input-color-chooser > li", function(e) {
            e.preventDefault();
            let type = ($("#agenda-input-add").hasClass("d-none") ? "modify" : "add");
            $("#agenda-input-couleur").val($(this).find("a").data("hexa-color"));
            $("#agenda-input-"+type).removeClass("btn-primary");
            $("#agenda-input-"+type).removeClass("btn-warning");
            $("#agenda-input-"+type).removeClass("btn-success");
            $("#agenda-input-"+type).removeClass("btn-danger");
            $("#agenda-input-"+type).removeClass("btn-secondary");
            $("#agenda-input-"+type).addClass("btn-"+$(this).find("a")[0].classList[0].replace("text-", ""));
        });
        $("body").on("click", "#agenda-input-tacheRealisee-oui", function() {
            $("#agenda-input-tacheRealisee-oui").removeClass('btn-outline-success');
            $("#agenda-input-tacheRealisee-oui").addClass('btn-success');
            $("#agenda-input-tacheRealisee-non").removeClass('btn-danger');
            $("#agenda-input-tacheRealisee-non").addClass('btn-outline-danger');
        });
        $("body").on("click", "#agenda-input-tacheRealisee-non", function() {
            $("#agenda-input-tacheRealisee-oui").removeClass('btn-success');
            $("#agenda-input-tacheRealisee-oui").addClass('btn-outline-success');
            $("#agenda-input-tacheRealisee-non").removeClass('btn-outline-danger');
            $("#agenda-input-tacheRealisee-non").addClass('btn-danger');
        });
        $("body").on("click", "#agenda-input-modify", modifyCalendarEvent);
        $("body").on("click", "#agenda-input-cancel", resetCalendarForm);
        $("body").on("click", "#agenda-input-add", modifyCalendarEvent);
        $("body").on("click", "#agenda-input-remove", removeCalendarEvent);
        $("body").on("click", "#agenda-input-ics", sendCalendarICS);

        // Bouton reset
        $('body').on('click', '#filtersagenda-reset', function() {
            resetFilter();
            loadCalendar();
        });

        // Chargement lors du changement dans les filtres
        $('body').on('change', "[id^='filtersagenda-']", function () {
            // Rafraichi les données de l'agenda s'il n'y a pas eu d'autre modification de filtre dans les 500 ms.
            clearTimeout(timerBeforeRefreshDataAgenda);
            timerBeforeRefreshDataAgenda = setTimeout(loadCalendar(), 500);
        });

        filtreConfigFunOnEvent();

        /* initialize other function
            -----------------------------------------------------------------*/

        function getRelanceObjectName(agenda) {
            let className = {
                "Commentaire": "Commentaire",
                "AppelTelephonique": "Appel téléphonique",
                "Agenda": "Programmation agenda"
            };
            let strToReturn = className[agenda.class];
            if (agenda.typeAgenda != null) {
                if (["tache"].includes(agenda.typeAgenda)) { strToReturn = strToReturn + " d\'une " + agenda.typeAgenda; }
                else { strToReturn = strToReturn + " d\'un " + agenda.typeAgenda; }
            }
            if (agenda.typeAppelTelephonique != null) {
                if (["entrant"].includes(agenda.typeAppelTelephonique)) { strToReturn = strToReturn + " " + agenda.typeAppelTelephonique; }
                else { strToReturn = strToReturn + " de " + agenda.typeAppelTelephonique; }
            }
            return strToReturn;
        };

        refreshDateTimePicker();
        function refreshDateTimePicker(picker = "all", date = null) {
            const datetimepickerOptions = (id) => {
                return {
                    value: date,
                    lang: 'fr',
                    format: (date == null ? "d/m/Y" : "d/m/Y H:i"),
                    dayOfWeekStart: 1,
                    step: 15,
                    validateOnBlur: false,
                    timepicker: (!(date == null)),
                    onSelectDate: function() {
                        $("#agenda-input-"+id).datetimepicker('setOptions', { format: "d/m/Y H:i", timepicker: true });
                        datetimepickerSetMinMax();
                    },
                    onSelectTime: function() { datetimepickerSetMinMax(); }
                }
            };

            const datetimepickerSetMinMax = () => {
                const regexDate = /(\d+)[/](\d+)[/](\d+)[\s](\d+)[:](\d+)|(\d+)[/](\d+)[/](\d+)|(\d+)[:](\d+)/;
                if ($('#agenda-input-dateFin').val() != "") {
                    $("#agenda-input-dateDebut").datetimepicker('setOptions', {
                        maxDate: ($('#agenda-input-dateFin').val().replace(regexDate, '$3$8/$2$7/$1$6') != "//" ? $('#agenda-input-dateFin').val().replace(regexDate, '$3$8/$2$7/$1$6') : false),
                        maxTime: ($('#agenda-input-dateFin').val().replace(regexDate, '$4$9:$5$10') != ":" && $('#agenda-input-dateFin').val().replace(regexDate, '$3$8/$2$7/$1$6') == $('#agenda-input-dateDebut').val().replace(regexDate, '$3$8/$2$7/$1$6') ? $('#agenda-input-dateFin').val().replace(regexDate, '$4$9:$5$10') : false)
                    });
                }
                if ($('#agenda-input-dateDebut').val() != "") {
                    $("#agenda-input-dateFin").datetimepicker('setOptions', {
                        minDate: ($('#agenda-input-dateDebut').val().replace(regexDate, '$3$8/$2$7/$1$6') != "//" ? $('#agenda-input-dateDebut').val().replace(regexDate, '$3$8/$2$7/$1$6') : false),
                        minTime: ($('#agenda-input-dateDebut').val().replace(regexDate, '$4$9:$5$10') != ":" && $('#agenda-input-dateDebut').val().replace(regexDate, '$3$8/$2$7/$1$6') == $('#agenda-input-dateFin').val().replace(regexDate, '$3$8/$2$7/$1$6') ? $('#agenda-input-dateDebut').val().replace(regexDate, '$4$9:$5$10').replace(/0$/, "1") : false)
                    });
                }
            };

            if (picker == "all" || picker == "dateDebut") {
                $('#agenda-input-dateDebut').datetimepicker(datetimepickerOptions("dateDebut"));
                if (date == null) { $('#agenda-input-dateDebut').val(null); $("#agenda-input-dateDebut").datetimepicker('setOptions', { maxDate: false, maxTime: false }); }
            }
            if (picker == "all" || picker == "dateFin") {
                $('#agenda-input-dateFin').datetimepicker(datetimepickerOptions("dateFin"));
                if (date == null) { $('#agenda-input-dateFin').val(null); $("#agenda-input-dateFin").datetimepicker('setOptions', { minDate: false, minTime: false }); }
            }

            if (picker == "all" || picker == "dateDebut") { datetimepickerSetMinMax(); }
            if (picker == "all" || picker == "dateFin") { datetimepickerSetMinMax(); }
        }

        function refreshCalendarEvent(agenda, crud = "post") {
            if (agenda.ids !== undefined) {
                agenda.ids.forEach(id => {
                    let event = calendar.getEventById(id);
                    if (event) { event.remove(); }
                });
            } else {
                let event = calendar.getEventById(agenda.id);
                if (event) { event.remove(); }
            }
            if (crud == "post") {
                agenda.start = new Date(agenda.start.date);
                agenda.end = new Date(agenda.end.date);
                if ($("#filtersagenda-collaborateur").val().length == 0) { // Pas de filtre
                    calendar.addEvent(agenda);
                } else if ($("#filtersagenda-collaborateur").val().includes(agenda.object.user.id.toString())) { // Avec filtre
                    calendar.addEvent(agenda);
                }
                if (goToAgenda) {
                    if (goToAgenda == agenda.id.replace('agenda-event-', '')) {
                        eventClickExternalise(calendar.getEventById(agenda.id)._def);
                    }
                }
            }
        }

        function removeCalendarEvent() {
            sendCalendar({ "id": $("#agenda-input-"+($("#agenda-input-add").hasClass("d-none") ? "modify" : "add")).data("id") }, "remove");
        }

        function modifyCalendarEvent() {
            sendCalendar({
                "id": $("#agenda-input-"+($("#agenda-input-add").hasClass("d-none") ? "modify" : "add")).data("id"),
                "start": $("#agenda-input-dateDebut").val(),
                "end": $("#agenda-input-dateFin").val(),
                "typeAgenda": $("#agenda-input-typeAgenda").val(),
                "collaborateur": $("#agenda-input-collaborateur").val(),
                "label": $("#agenda-input-label").val(),
                "contenu": $("#agenda-input-contenu").val(),
                "couleur": $("#agenda-input-couleur").val(),
            });
        }

        function resetCalendarForm() {
            $("#agenda-input-modify").addClass("d-none");
            $("#agenda-input-cancel").addClass("d-none");
            $("#agenda-input-goToAnnuaire").addClass("d-none");
            $("#agenda-input-goToVehicule").addClass("d-none");
            $("#agenda-lien-goToVehicule").attr("href", siteURL + "admin/stock/idVehicule");
            $("#agenda-modal-remove-btn").addClass("d-none");
            $("#agenda-modal-remove-btn").prop('disabled', false);
            $("#agenda-input-add").removeClass("d-none");
            $("#agenda-input-collaborateur").val('').selectpicker('refresh');
            $("#agenda-input-typeAgenda").prop('disabled', false).val('').selectpicker('refresh');
            $("#agenda-input-couleur").val('#007bff');
            refreshDateTimePicker("all", null);
            $("#agenda-input-label").prop('disabled', false);
            $("#agenda-input-label").parent().removeClass("d-none");
            $("#agenda-input-label").val('');
            $("#agenda-input-contenu").prop('disabled', false);
            $("#agenda-input-contenu").val('');
            $("#agenda-input-relanceObject").addClass("d-none");
            $("#agenda-input-relanceObject").html("");
            $("#agenda-input-add").addClass("btn-primary");
            $("#agenda-input-add").removeClass("btn-warning");
            $("#agenda-input-add").removeClass("btn-success");
            $("#agenda-input-add").removeClass("btn-danger");
            $("#agenda-input-add").removeClass("btn-secondary");
            $("#agenda-input-tacheRealisee").addClass('d-none');
            $("#agenda-input-tacheRealisee").removeClass('d-flex');
            $("#agenda-input-tacheRealisee-oui").removeClass('btn-success');
            $("#agenda-input-tacheRealisee-oui").addClass('btn-outline-success');
            $("#agenda-input-tacheRealisee-oui").attr('disabled', false);
            $("#agenda-input-tacheRealisee-non").removeClass('btn-danger');
            $("#agenda-input-tacheRealisee-non").addClass('btn-outline-danger');
            $("#agenda-input-tacheRealisee-non").attr('disabled', false);
        }

        function resetFilter() {
            $("#filtersagenda-collaborateur").val('default').selectpicker("refresh");
            $("#filtersagenda-typeAgenda").val('default').selectpicker("refresh");
            $("#filtersagenda-sousTypeAgenda").val('default').selectpicker("refresh");
        }

        function sendCalendarICS() {
            let options = new FormData();
            options.append('clientTo', ($("#agenda-input-ics-checkbox-client").is(":checked") ? true : null));
            options.append('client', $("#agenda-input-ics-select-client").val());
            options.append('commercialTo', ($("#agenda-input-ics-checkbox-commercial").is(":checked") ? true : null));
            options.append('commercial', $("#agenda-input-ics-select-commercial").val());
            $.ajax({
                type:'POST',
                url: siteURL + 'admin/agenda/ics/'+$("#agenda-input-ics").data("id"),
                contentType: false,
                processData: false,
                data: options,
                success: function(data) { (data.startsWith("success;") ? toastr.success(data.replace("success;", ""), "Succès") : toastr.error(data.replace("error;", ""), "Erreur")) },
                error: function() { toastr.error(data.replace("error;", ""), "Erreur") }
            });
        }

        function eventClickExternalise(_def) {
            let agenda = _def.extendedProps.object;
            let hasAnnuaire = (_def.extendedProps.object.annuaire != null ? true : false);
            let idAnnuaire = (_def.extendedProps.object.annuaire != null ? _def.extendedProps.object.annuaire.idAnnuaire : "");
            let idFirstVehicule = (_def.extendedProps.object.vehicule != null ? _def.extendedProps.object.vehicule[0] : "");
            let hasCompteRendu = _def.extendedProps.hasCompteRendu;
            let hasVehicule = _def.extendedProps.hasVehicule;
            $("#agenda-input-add").addClass("d-none");
            $("#agenda-input-goToAnnuaire").addClass("d-none");
            $("#agenda-input-goToVehicule").addClass("d-none");
            $("#agenda-input-modify").removeClass("d-none");
            $("#agenda-input-cancel").removeClass("d-none");
            $("#agenda-modal-remove-btn").removeClass("d-none");
            // Pour verrouiller la suppression que seul l'utilisateur de l'event peut supprimer l'event
            // if ($("#agenda-modal-remove-btn").attr("data-iduserconnecte") == _def.extendedProps.object.user.id) {
            //     $("#agenda-modal-remove-btn").prop('disabled', false);
            // } else {
            //     $("#agenda-modal-remove-btn").prop('disabled', true);
            // }
            if (hasAnnuaire) {
                $("#agenda-input-goToAnnuaire").removeClass("d-none");
                let gototab = "&gototab=suivi_relation";
                if (agenda.annuaire.typeEntreprise == "fournisseur") {
                    gototab += "_fournisseur";
                }
                $("#agenda-lien-goToAnnuaire").attr("href", siteURL + "admin/annuaire/annuaires?gotoannuaire=" + idAnnuaire + gototab);
                $("#agenda-input-tacheRealisee").addClass('d-none');
                $("#agenda-input-tacheRealisee").removeClass('d-flex');
                $("#agenda-modal-remove-btn").addClass("d-none");
            } else {
                $("#agenda-input-tacheRealisee").removeClass('d-none');
                $("#agenda-input-tacheRealisee").addClass('d-flex');
                if (hasCompteRendu) {
                    $("#agenda-input-tacheRealisee-oui").removeClass('btn-outline-success');
                    $("#agenda-input-tacheRealisee-oui").addClass('btn-success');
                    $("#agenda-input-tacheRealisee-oui").attr('disabled', true);
                    $("#agenda-input-tacheRealisee-non").removeClass('btn-danger');
                    $("#agenda-input-tacheRealisee-non").addClass('btn-outline-danger');
                    $("#agenda-input-tacheRealisee-non").attr('disabled', true);

                } else {
                    $("#agenda-input-tacheRealisee-oui").removeClass('btn-success');
                    $("#agenda-input-tacheRealisee-oui").addClass('btn-outline-success');
                    $("#agenda-input-tacheRealisee-oui").attr('disabled', false);
                    $("#agenda-input-tacheRealisee-non").removeClass('btn-outline-danger');
                    $("#agenda-input-tacheRealisee-non").addClass('btn-danger');
                    $("#agenda-input-tacheRealisee-non").attr('disabled', false);

                }
            }
            if (hasVehicule) {
                $("#agenda-input-goToVehicule").removeClass("d-none");
                $("#agenda-lien-goToVehicule").attr("href", siteURL + "admin/stock/" + idFirstVehicule);
            } else {
                $("#agenda-input-goToVehicule").addClass("d-none");
                $("#agenda-lien-goToVehicule").attr("href", siteURL + "admin/stock/idVehicule");
            }
            $("#agenda-input-modify").data("id", agenda.id);
            $("#agenda-input-typeAgenda").prop('disabled', true).selectpicker('val', agenda.typeAgenda.toLowerCase()).selectpicker('refresh');
            refreshDateTimePicker("dateDebut", new Date(agenda.dateDebut.date));
            refreshDateTimePicker("dateFin", new Date(agenda.dateFin.date));
            $("#agenda-input-couleur").val(agenda.couleur);
            for (const iterator of $("#agenda-input-color-chooser > li > a")) { if (iterator.getAttribute("data-hexa-color") == agenda.couleur) { iterator.click(); } }
            if (agenda.relanceObj != null) {
                agenda = agenda.relanceObj.object; // Transformation de la relance par l'objet
                $("#agenda-input-relanceObject").html("Évènement associé : " + getRelanceObjectName(agenda));
                $("#agenda-input-relanceObject").removeClass("d-none");
                $("#agenda-input-label").prop('disabled', true);
                $("#agenda-input-contenu").prop('disabled', true);
                if (agenda.label == null) { $("#agenda-input-label").parent().addClass("d-none"); }
            } else {
                $("#agenda-input-relanceObject").addClass("d-none");
                $("#agenda-input-relanceObject").html("");
                $("#agenda-input-label").prop('disabled', false);
                $("#agenda-input-contenu").prop('disabled', false);
                $("#agenda-input-label").parent().removeClass("d-none");
            }
            $("#agenda-input-collaborateur").val(agenda.user.id).selectpicker('refresh');
            $("#agenda-input-label").val(agenda.label);
            $("#agenda-input-contenu").val(agenda.contenu);
        }
    }
});
