let factureTable = null;
let timerBeforeRefreshDataFacture = null;
let selectedFactureCache = [];

$(document).ready(function () {
    if ($("#facture-results").length) {
        colvisFunOnEvent();
        function loadAjax() {
            $("#facture-results").addClass("d-none");
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/fre/facture/loadAjax',
                contentType: false,
                processData: false,
                data: getParams(),
                success: function (data) {
                    $("#facture-results").html(data);
                    for (let index = 0; index < selectedFactureCache.length; index++) {
                        $("#facture-selection-" + selectedFactureCache[index]).prop("checked", true);
                    }
                    factureTable = $('#facture-table').DataTable({
                        "dom": dataTablesCustomDom(),
                        "buttons": [dataTablesColvisBtn('th:nth-child(n+2)')],
                        "autoWidth": false,
                        "language": { "url": "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/French.json" },
                        "paging": true,
                        "pageLength": 25,
                        "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, "All"]],
                        "responsive": true,
                        "order": [],
                        "conditionalPaging": true,
                        "columnDefs": [
                            { "className": "text-center align-middle", "targets": '_all' }, // ALIGN MIDDLE
                            { "targets": [0, 11], "orderable": false }
                        ],
                        "columns": getColvisColumn("facture-table")
                    });
                    $('#facture-table').on('init.dt', function () { initMirrorBtn(); setColvisTable(factureTable); colvisFunSelectRefresh(); });
                    $('#facture-table').on('draw.dt', function () { $("#facture-results").removeClass("d-none"); });
                }
            })
        };

        setupLoadAjax(loadAjax);
        setupResetFilter(resetFilter);

        $('body').on('click', "#facture-tabs-list-tab", function() {
            loadAjax();
        });

        $('body').on('change', "[id^='filtersfacture-']", function () { clearTimeout(timerBeforeRefreshDataFacture); timerBeforeRefreshDataFacture = setTimeout(loadAjax, 500); });
        // Bouton reset
        $('body').on('click', '#filtersfacture-reset', function () {
            resetFilter();
            loadAjax();
        });

        // Récupération des paramètres des requêtes suivant les filtres
        function getParams() {
            var optionsRecherche = new FormData();
            optionsRecherche.append('prestataire', $("#filtersfacture-prestataire").val());
            optionsRecherche.append('dateDocumentDebut', $("#filtersfacture-dateDocumentDebut").val());
            optionsRecherche.append('dateDocumentFin', $("#filtersfacture-dateDocumentFin").val());
            optionsRecherche.append('dateImportDebut', $("#filtersfacture-dateImportDebut").val());
            optionsRecherche.append('dateImportFin', $("#filtersfacture-dateImportFin").val());
            optionsRecherche.append('categorie', $("#filtersfacture-categorie").val());
            return optionsRecherche;
        }

        $('body').on('click', "#facture-export", function (e) {
            var options = new FormData();
            options.append('ids', selectedFactureCache);
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/fre/facture/export',
                contentType: false,
                processData: false,
                data: options,
                success: function (data) {
                    toastr.success('Le fichier excel est fini', 'Succès');
                    var element = document.createElement('a');
                    element.setAttribute('href', siteURL + data);
                    element.setAttribute('download', "Export facture du " + (new Date()).toLocaleDateString("fr") + ".xlsx");
                    document.body.appendChild(element);
                    element.click();
                    document.body.removeChild(element);
                },
                error: function () {
                    toastr.error("Erreur lors de l'export", "Erreur");
                }
            })
        });

        // Selection des véhicules
        $('body').on('change', "[id^='facture-selection-']", function (evt) {
            if (evt.stopPropagation !== undefined) { evt.stopPropagation(); } else { evt.cancelBubble = true; }
            if (this.id.replace("facture-selection-", "") == "all") {
                $("[id^='facture-selection-']").each(function () {
                    if ($("#facture-selection-all").is(':checked')) {
                        $(this).prop("checked", true);
                    } else {
                        $(this).prop("checked", false);
                    }
                });
            } else {
                if ($(this).is(":checked")) {
                    $(this).prop("checked", true);
                } else {
                    $(this).prop("checked", false);
                }
            }
            factureTable.rows().invalidate();
            let all_data = factureTable.rows().data();
            let index = null;
            id_factures = [];
            $.each(all_data, function (key, value) {
                let tr = $('#facture-tr-' + $(value[0]["display"])[0].id.replace("facture-selection-", ""));
                if (tr.length != 0) {
                    if ($(value[0]["display"])[0].id.includes("facture-selection-") && $("#" + $(value[0]["display"])[0].id).is(":checked")) {
                        id_factures.push($(value[0]["display"])[0].id.replace("facture-selection-", ""));
                    } else {
                        index = selectedFactureCache.findIndex((el) => el == $(value[0]["display"])[0].id.replace("facture-selection-", ""));
                        if (index != -1) { selectedFactureCache.splice(index, 1); }
                    }
                }
            });
            for (let index = 0; index < id_factures.length; index++) {
                selectedFactureCache.push(id_factures[index]);
            }
            selectedFactureCache = selectedFactureCache.filter((value, index, array) => array.indexOf(value) === index);
        });

        function resetFilter() {
            $("#filtersfacture-prestataire").val('default').selectpicker('refresh');
            $("#filtersfacture-dateDocumentDebut").val($("#firstDateDocumentDebut").val());
            $("#filtersfacture-dateDocumentFin").val($("#lastDateDocumentFin").val());
            $("#filtersfacture-dateImportDebut").val($("#firstDateImportDebut").val());
            $("#filtersfacture-dateImportFin").val($("#lastDateImportFin").val());
            $("#filtersfacture-categorie").val('all');
        }

        $('body').on('change', "[id^='facture-prixHt-']", function (e) {
            const idFacture = this.id.replace("facture-prixHt-", "");
            $("#facture-tva-" + idFacture).val(Math.round(parseFloat($("#facture-prixHt-" + idFacture).val()) * 20) / 100);
            $("#facture-prixTtc-" + idFacture).val(parseFloat($("#facture-prixHt-" + idFacture).val()) + parseFloat($("#facture-tva-" + idFacture).val()));
        });

        $('body').on('click', "[id^='facture-send-modify-']", function (e) {
            const idFacture = this.id.replace("facture-send-modify-", "");
            var options = new FormData();
            options.append('id', idFacture);
            options.append('vehicule', $("#facture-vehicule-" + idFacture).val());
            options.append('annuaire', $("#facture-annuaire-" + idFacture).val());
            options.append('dateDocument', $("#facture-dateDocument-" + idFacture).val());
            options.append('prixHt', $("#facture-prixHt-" + idFacture).val());
            options.append('tva', $("#facture-tva-" + idFacture).val());
            options.append('prixTtc', $("#facture-prixTtc-" + idFacture).val());
            options.append('freCategorie', $("#facture-categorie-" + idFacture).val());
            options.append('numeroFacture', $("#facture-numeroFacture-" + idFacture).val());
            let error = "";
            if (!options.get("numeroFacture").length) {
                error += "Le numéro de facture est manquant<br>";
            }
            if (isNaN(options.get("vehicule"))) {
                error += "Le VIN est manquant<br>";
            }
            if (isNaN(options.get("freCategorie"))) {
                error += "Le type de prestation est manquant<br>";
            }
            if (isNaN(options.get("annuaire"))) {
                error += "Le prestataire est manquant<br>";
            }
            if (!options.get("dateDocument").length) {
                error += "La date du document est manquante<br>";
            }
            if (!options.get("prixHt").length) {
                error += "Le prix HT est manquant<br>";
            }
            if (error.length) {
                toastr.error(error, "Erreur");
            } else {
                $.ajax({
                    type: 'POST',
                    url: siteURL + 'admin/fre/facture/save',
                    contentType: false,
                    processData: false,
                    data: options,
                    success: function (data) {
                        toastr.success("L'enregistrement est fini", "Succès");
                        $('#facture-tabs-list-tab').trigger('click');
                        $("#facture-tabs-add").html(data);
                        factureSetup(0);
                    },
                    error: function () {
                        toastr.error("Erreur lors de l'enregistrement", "Erreur");
                    }
                });
            }
        });

        filtreConfigFunOnEvent();
    }
});

function factureSetup(idFacture) {
    $("#facture-vehicule-" + idFacture).selectpicker();
    $("#facture-categorie-" + idFacture).selectpicker();
    $("#facture-annuaire-" + idFacture).selectpicker();
}