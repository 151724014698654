$(document).ready(function () {
    if ($("#annuaire_results").length) {
        function loadAjax() {
            let idAnnuaire = this.id.replace("annuaire-sub-tab-commerce-", "");
            let form = new FormData();
            form.append('idSuiviRelationObject', 0);
            form.append('idAnnuaire', idAnnuaire);
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/annuaire/annuaires/commerce',
                contentType: false,
                processData: false,
                data: form,
                success: function (data) {
                    if (data == null) {
                        toastr.error('Erreur lors du chargement', 'Erreur');
                    } else {
                        $('#annuaire-tab-commerce-' + idAnnuaire + '-commerce').html(data);
                        initDatatable(idAnnuaire);
                    }
                }
            });
        }

        function initDatatable(idAnnuaire) {
            $("#annuaire-commerce-table-propo-" + idAnnuaire).DataTable({
                "autoWidth": false,
                "language": { "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/fr-FR.json" },
                "paging": true,
                "responsive": true,
                "order": [[0, "desc"]],
                "conditionalPaging": true,
                "columnDefs": [
                    { type: 'num', targets: [0, 2, 4, 5] },
                    { "className": "text-center align-middle", "targets": '_all' },
                    { "targets": [3, 6], "orderable": false }
                ],
            });

            $("#annuaire-commerce-table-propo-" + idAnnuaire).on('draw.dt', function () {
                getStatusMailAsynchrone($("#annuaire-tabs-modify-" + idAnnuaire).find('[id^="statut-mail-"]'));
            });
            $("#annuaire-commerce-table-propo-" + idAnnuaire).on('page.dt', function () {
                getStatusMailAsynchrone($("#annuaire-tabs-modify-" + idAnnuaire).find('[id^="statut-mail-"]'));
            });
            $("#annuaire-commerce-table-propo-" + idAnnuaire).on('length.dt', function () {
                getStatusMailAsynchrone($("#annuaire-tabs-modify-" + idAnnuaire).find('[id^="statut-mail-"]'));
            });
        }

        $('body').on('click', "[id^='annuaire-sub-tab-commerce-']", loadAjax);

        // Pour créer une nouvelle proposition
        $('body').on('click', "[id^='annuaire-commerce-tab-nouvelle-proposition-']", function (e) {
            let idAnnuaire = this.id.replace("annuaire-commerce-tab-nouvelle-proposition-", "");
            window.open($(this).data("src") + "?idAnnuaire=" + idAnnuaire, "_blank");
        });

        // Pour dupliquer une proposition
        $('body').on('click', "[id^='duplication_proposition_mail-']", function (e) {
            window.open($(this).data("src"), "_blank");
        });
    }
});
