$(document).ready(function() {

    // Génération de la datatable des generateur reseaux sociaux
    generateurcession_table = $('#generateurcession_table').DataTable({
        "autoWidth": false,
        "language": {
            "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/en-GB.json"
        },
        "responsive": true,
        "order": [],
        "columnDefs": [
            // TEXT CENTER
            {
                "className": "text-center align-middle",
                "targets": [2],
            },
            // ALIGN MIDDLE
            {
                "className": "align-middle",
                "targets": '_all',
            },
            // VIN
            {
                "targets": 0,
            },
            // RETURN
            {
                "targets": 1,
            },
            // STATUS
            {
                "targets": 2,
                "orderable": false,
            },
        ],
        "conditionalPaging": true,
    });

    $('#mailWasSendModal').modal('toggle');

    $('body').on('change', "#cession_input_file", function() {
        var files = $("#cession_input_file")[0].files;
        if (files.length != 0) {
            $('#cession_form_message').removeClass('d-none');
        } else {
            $('#cession_form_message').addClass('d-none');
        }
    });

    $('body').on('click', "#cession_submit", function(event) {
        var files = $("#cession_input_file")[0].files;
        if (files.length == 0) {
            event.preventDefault();
        }
    });

    $('body').on('click', "#cession_reset", function() {
        $('#cession_form_message').addClass('d-none');
    });
})
