$(document).ready(function() {
    makeSummerNote('article_categorie_texteIntro_0', 200, ['full']);

    // Génération de la datatable des article_categorie
    article_categorie_table = $('#article_categorie_table').DataTable( {
        "autoWidth": false,
        "language": {
            "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/fr-FR.json"
        },
        "responsive": true,
        "ajax": siteURL + 'json/article_categorie',
        "order": [[ 0, "desc" ]],
        "columnDefs": [
            // TEXT CENTER
            {
                "className": "text-center align-middle",
                "targets": [0,3,4],
            },
            //TEXT START
            {
                "className": "text-start",
                "targets": [2],
            },
            {
                "width": "40px",
                "targets": 0,
                "render": function ( data, type, row ) {
                    return row[0];
                },
                "orderable": true,
            },
            {
                "width": "150px",
                "targets": 1,
                "render": function ( data, type, row ) {
                    return row[1];
                },
            },
            {
                "targets": 2,
                "render": function ( data, type, row ) {
                    return "<span class='text-start'>"+row[2]+"</span>";
                },
                "orderable": true,
            },
            {
                "width": "100px",
                "targets": 3,
                "render": function ( data, type, row ) {
                    return row[3];
                },
                "orderable": true,
            },
            {
                "width": "100px",
                "targets": 4,
                "render": function ( data, type, row ) {
                    return (
                        row[4] == 1
                        ? '<i class="fas fa-check" style="color:green" title="Validé"></i><span class="d-inline d-lg-none pl-2">OUI</span>'
                        : '<i class="fas fa-times" style="color:red" title="Non Validé"></i><span class="d-inline d-lg-none pl-2">NON</span>'
                    );
                },
                "orderable": true,
            },
            {
                "width": "50px",
                "targets": 5,
                "render": function ( data, type, row ) {
                    return '<button class="btn w-100 p-0" id="article_categorie-open-modify-'+row[0]+'" data-category="web"><i class="fad fa-edit"></i>';
                },
                "orderable": false,
            },
        ],
        "conditionalPaging": true,
    });

    // Envoi de modification d'un article_categorie
    $('body').on('click', "button[id^='article_categorie-send-modify-']", function(e) {
        e.preventDefault();
        var clickedId = this.id.split(/[- ]+/).pop();

        var form = new FormData();
        // On extrait le contenu du formulaire dans la variable 'form'
        form.append('id', clickedId);
        form.append('article_categorie_name', $('#article_categorie_name_'+clickedId).val());
        form.append('article_categorie_title', $('#article_categorie_title_'+clickedId).val());
        form.append('article_categorie_metaDescription', $('#article_categorie_metaDescription_'+clickedId).val());
        form.append('article_categorie_site', $('#article_categorie_site_'+clickedId).val());
        form.append('article_categorie_isVisible', ($('#article_categorie_isVisible_'+clickedId).val() == "on" ? 1 : 0));
        form.append('article_categorie_baliseH1', $('#article_categorie_baliseH1_'+clickedId).val());
        form.append('article_categorie_texteIntro', $('#article_categorie_texteIntro_'+clickedId).val());

        $.ajax({
            type: 'POST',
            url: siteURL + 'admin/web/article_categorie/modify',
            cache: false,
            contentType: false,
            processData: false,
            data: form,
            success: function(data) {
                // Si il s'agit de l'ajout d'un nouveau article_categorie
                if (clickedId == 0) {
                    toastr.success('La nouvelle categorie d\'article a bien été enregistrée', 'Succès');
                    $('#formarticle_categorie_0')[0].reset();
                    $("#article_categorie_texteIntro_0").each(function(e) { $(this).summernote('reset'); });
                }
                // Si il s'agit d'une modification de article_categorie
                else {
                    toastr.success('La categorie d\'article a bien été modifié', 'Succès');
                    // On nettoye le html en supprimant l'onglet 'Modifier/Nouveau'
                    $('#article_categorie-tabs-modify-'+clickedId).remove();
                    $('#article_categorie-close-modify-'+clickedId).parent().remove();
                }
                // Retour à la liste des article_categorie en rechargeant la datatable
                article_categorie_table.ajax.reload();
                $('#article_categorie-tabs-list-tab').trigger('click');
            }
        });
        // Empèche le 'button' de fonctionner
        return false;
    });
});