// Setup toute les stats de la page
function annuaireStatsConnexionSetupAll(id, setupAfter = null) {
    const listIdAnnuaire = [];
    $("#annuaire-tabs-modify-" + id).find("[annuaire-stats-connexion][annuaire-stats-load]").toArray().forEach(
        e => { listIdAnnuaire.push(e.getAttribute("annuaire-id")); }
    );
    listIdAnnuaire.distinct().forEach(idAnnuaire => {
        annuaireStatsConnexionSetup(idAnnuaire, setupAfter);
    })
}

// Setup toute les stats de la page
function annuaireStatsConnexionSetupAllExcept(id, exceptedId, setupAfter = null) {
    const listIdAnnuaire = [];
    $("#annuaire-tabs-modify-" + id).find("[annuaire-stats-connexion][annuaire-stats-load][annuaire-id!='" + exceptedId + "']").toArray().forEach(
        e => { listIdAnnuaire.push(e.getAttribute("annuaire-id")); }
    );
    listIdAnnuaire.distinct().forEach(idAnnuaire => {
        annuaireStatsConnexionSetup(idAnnuaire, setupAfter);
    })
}

// Setup les stats de l'annuaire indiqué en paramètre
function annuaireStatsConnexionSetup(idAnnuaire, setupAfter = null) {
    $("[annuaire-stats-connexion][annuaire-stats-load][annuaire-id='" + idAnnuaire + "']").toArray().forEach(
        e => {
            $.ajax({
                type: 'GET',
                url: siteURL + 'admin/annuaire/load-stats/' + idAnnuaire,
                success: function (stats) {
                    e.setAttribute("annuaire-stats-connexion", JSON.stringify(stats));
                    annuaireStatsConnexionSetupSelect(e);
                    annuaireStatsConnexionSet(e);
                    if (setupAfter != null) { setupAfter(e, stats); }
                }
            });
        }
    );
}

function annuaireStatsConnexionGetData(canvas, raw = false) {
    let stats = JSON.parse(canvas.getAttribute("annuaire-stats-connexion"));
    stats = (stats ? stats : {});
    if (raw) { return stats; }
    const select = annuaireStatsConnexionGetSelect(canvas);
    let isMonthLimited = false;
    let limit = null;
    if (select != null) {
        const selected = select.val();
        const exeptions = ["all", "6Month", "12Month"];
        if (!exeptions.includes(selected)) {
            const selectedValue = stats[selected];
            stats = {};
            stats[selected] = selectedValue;
        } else if (selected.endsWith("Month")) {
            isMonthLimited = true;
            limit = selected.replace("Month", "");
        }
    }
    const moisArray = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];
    labelStats = [];
    dataStats = [];
    Object.entries(stats).forEach(e1 => {
        const [annee, listMois] = e1;
        Object.entries(listMois).forEach(e2 => {
            const [mois, stat] = e2;
            labelStats.push(annee + '-' + moisArray[parseInt(mois) - 1]);
            dataStats.push(stat);
        });
    });
    if (isMonthLimited) {
        if (labelStats.length > limit && dataStats.length > limit) {
            labelStats = labelStats.slice(limit * -1)
            dataStats = dataStats.slice(limit * -1)
        }
    }
    return {
        "label": labelStats,
        "data": dataStats
    };
}

$('body').on('click', "[annuaire-stats-connexion]", function (e) {
    const el = e.target;
    if (el.getAttribute("annuaire-stats-onmodal") == null) {
        $("[annuaire-stats-modal][annuaire-id='" + el.getAttribute("annuaire-id") + "']").modal('show');
    }
});

function annuaireStatsConnexionSetupSelect(canvas) {
    const select = annuaireStatsConnexionGetSelect(canvas);
    if (select != null) {
        let options = {};
        Object.keys(annuaireStatsConnexionGetData(canvas, true)).forEach(annee => {
            options[annee] = document.createElement("option");
            options[annee].innerText = annee;
            options[annee].value = annee;
            select[0].appendChild(options[annee]);
        });
    }
}

function annuaireStatsConnexionGetSelect(canvas) {
    const modal = $(canvas).parents("div[annuaire-stats-modal]");
    const onModal = (modal.length ? true : false);
    if (onModal) {
        const select = modal.find("select[annuaire-stats-select]");
        if (select.length) {
            return select;
        }
    }
    return null;
}

$('body').on('change', "[annuaire-stats-select]", annuaireStatsConnexionSelect);
function annuaireStatsConnexionSelect(e) {
    const select = e.target;
    const modal = $(select).parents("div[annuaire-stats-modal]");
    const onModal = (modal.length ? true : false);
    if (onModal) {
        const canvas = modal.find("canvas[annuaire-stats-connexion]");
        const parent = canvas.parent();
        const newCanvas = canvas[0].cloneNode(true);
        canvas.remove();
        parent.append(newCanvas);
        annuaireStatsConnexionSet(newCanvas);
    }
}

// Creer le graphique des statistiques
function annuaireStatsConnexionSet(canvas) {
    const data = annuaireStatsConnexionGetData(canvas);
    const hasLabel = (parseInt(canvas.getAttribute("annuaire-stats-label")) ? true : false);
    let ctx = canvas.getContext('2d');
    let chart = new Chart(ctx, {
        // The type of chart we want to create
        type: 'line',
        // The data for our dataset
        data: {
            labels: data.label,
            datasets: [{
                label: 'Nombre de connexions',
                backgroundColor: 'rgb(0, 174, 239)',
                borderColor: 'rgb(0, 137, 189)',
                pointRadius: (hasLabel ? 3 : 0),
                data: data.data
            }]
        },
        // Configuration options go here
        options: {
            legend: { display: false },
            scales: {
                yAxes: [{ ticks: { display: hasLabel, beginAtZero: true }, gridLines: { display: hasLabel } }],
                xAxes: [{ ticks: { display: hasLabel }, gridLines: { display: hasLabel } }]
            }
        }
    });
}
