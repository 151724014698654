function loadLastDate(idAnnuaire) {
    $("[id^='annuaire-suiviRelation-lastDate-'][id$='-" + idAnnuaire + "']").each((k, el) => {
        const type = el.id.replace("annuaire-suiviRelation-lastDate-", "").replace("-" + idAnnuaire, "");
        const objectif = { "appel": [0, 15, 30], "offre": [0, 30, 60], "connexion": [0, 15, 30], "commande": [0, 30, 90]};
        $.ajax({
            type: 'GET',
            url: siteURL + 'admin/annuaire/suiviRelation/lastDate/' + idAnnuaire + '/' + type,
            success: function (data) {
                if (data.data != null) {
                    if (data.data.date) {

                        const date = new Date(data.data.date.date);
                        objectif[type].forEach((v, k) => {
                            if (dateDiff(date, Date.now()).day >= v) {
                                $("#annuaire-suiviRelation-lastDate-" + type + "-" + idAnnuaire).find("[annuaire-suiviRelation-lastDate-diff]").removeClass("text-success");
                                $("#annuaire-suiviRelation-lastDate-" + type + "-" + idAnnuaire).find("[annuaire-suiviRelation-lastDate-diff]").removeClass("text-warning");
                                $("#annuaire-suiviRelation-lastDate-" + type + "-" + idAnnuaire).find("[annuaire-suiviRelation-lastDate-diff]").removeClass("text-danger");
                                $("#annuaire-suiviRelation-lastDate-" + type + "-" + idAnnuaire).find("[annuaire-suiviRelation-lastDate-diff]").addClass("text-" + (k ? (k - 1 ? "danger" : "warning") : "success"));
                            }
                        });
                        $("#annuaire-suiviRelation-lastDate-" + type + "-" + idAnnuaire).find("[annuaire-suiviRelation-lastDate-diff]").html(drawDateDiff(dateDiff(date, Date.now())));
                        $("#annuaire-suiviRelation-lastDate-" + type + "-" + idAnnuaire).find("[annuaire-suiviRelation-lastDate-icon]").removeClass("d-none");
                        $("#annuaire-suiviRelation-lastDate-" + type + "-" + idAnnuaire).find("[annuaire-suiviRelation-lastDate-date]").html(date.toLocaleDateString('fr-FR', { year: 'numeric', month: 'short', day: 'numeric' }));
                    } else {
                        let value = data.data;
                        switch (type) {
                            //! A modifié car importé depuis le lastDate du suivi de relation fournisseur
                            case "nombrevfetudie30j":
                                value += " véhicule(s)";
                                break;
                            default:
                                break;
                        }
                        $("#annuaire-suiviRelationFournisseur-lastDate-" + type + "-" + idAnnuaire).find("[annuaire-suiviRelationFournisseur-lastDate-icon]").removeClass("d-none");
                        $("#annuaire-suiviRelationFournisseur-lastDate-" + type + "-" + idAnnuaire).find("[annuaire-suiviRelationFournisseur-lastDate-date]").html(value);
                    }
                }
            }
        });
    })
}