let vehiculealivrer_table = null;
let timerBeforeRefreshDataVehiculeALivrer = null;

$(document).ready(function() {
    if ($("#vehicule_a_livrer_results").length) {
        colvisFunOnEvent();

        function loadAjax() {
            let optionsRecherche = optionsRecherchesDataForm();
            $('#vehicule_a_livrer_loader').removeClass('d-none');
            $('.card-body').addClass('d-none');
            $("#vehicule_a_livrer_results").addClass("d-none");
            $.ajax({
                type:'POST',
                url: siteURL + 'admin/vehicule_a_livrer/vehicule_a_livrer_ajax',
                contentType: false,
                processData: false,
                data: optionsRecherche,
                success: function(data){
                    $("#vehicule_a_livrer_results").html(data);
                    // Génération de la datatable des generateur reseaux sociaux
                    vehiculealivrer_table = $('#vehicule_a_livrer_table').DataTable({
                        "dom": dataTablesCustomDom(),
                        "buttons": [dataTablesColvisBtn('th:nth-child(n+2)')],
                        "autoWidth": false,
                        "language": { "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/fr-FR.json" },
                        "paging": true,
                        "pageLength": 25,
                        "responsive": true,
                        "order": [[0, "desc"]],
                        "conditionalPaging": true,
                        "columnDefs": [].push(...getColumnDefsType("vehicule_a_livrer_table")),
                        "columns": getColvisColumn("vehicule_a_livrer_table")
                    });
                    $('#vehicule_a_livrer_table').on('init.dt', function () { initMirrorBtn(); setColvisTable(vehiculealivrer_table); colvisFunSelectRefresh(); });
                    $("#vehicule_a_livrer_results").removeClass("d-none");
                    $('#vehicule_a_livrer_loader').addClass('d-none');
                    $('.card-body').removeClass('d-none');
                }
            })
        };

        setupLoadAjax(loadAjax);
        setupResetFilter(resetFilter);

        $('body').on('change', "[id^='filtersvehiculealivrer-']", function() {
            clearTimeout(timerBeforeRefreshDataVehiculeALivrer);
            timerBeforeRefreshDataVehiculeALivrer = setTimeout(loadAjax, 500);
        });

        // Bouton reset
        $('body').on('click', '#filtersvehiculealivrer-reset', function() {
            resetFilter();
            loadAjax();
        });

        function optionsRecherchesDataForm() {
            let optionsRecherche = new FormData();
            optionsRecherche.append('vendeur', $("#filtersvehiculealivrer-vendeur").val());
            optionsRecherche.append('chargeLivraison', $("#filtersvehiculealivrer-chargeLivraison").val());
            optionsRecherche.append('parc', $("#filtersvehiculealivrer-parc").val());
            optionsRecherche.append('dateDebutLivraison', $("#filtersvehiculealivrer-dateDebutLivraison").val());
            optionsRecherche.append('dateFinLivraison', $("#filtersvehiculealivrer-dateFinLivraison").val());
            optionsRecherche.append('lieuLivraison', $("#filtersvehiculealivrer-lieuLivraison").val());
            optionsRecherche.append('statutPaiement', $("#filtersvehiculealivrer-statutPaiement").val());
            optionsRecherche.append('statutLivraison', $("#filtersvehiculealivrer-statutLivraison").val());
            optionsRecherche.append('typeVente', $("#filtersvehiculealivrer-typeVente").val());
            optionsRecherche.append('dateDebutCommande', $("#filtersvehiculealivrer-dateDebutCommande").val());
            optionsRecherche.append('dateFinCommande', $("#filtersvehiculealivrer-dateFinCommande").val());
            optionsRecherche.append('dateDebutFacture', $("#filtersvehiculealivrer-dateDebutFacture").val());
            optionsRecherche.append('dateFinFacture', $("#filtersvehiculealivrer-dateFinFacture").val());
            return optionsRecherche;
        }

        function resetFilter() {
            $("#filtersvehiculealivrer-vendeur").val('default').selectpicker("refresh");
            $("#filtersvehiculealivrer-chargeLivraison").val('default').selectpicker("refresh");
            $("#filtersvehiculealivrer-parc").val('default').selectpicker("refresh");
            $("#filtersvehiculealivrer-dateDebutLivraison").val('').selectpicker("refresh");
            $("#filtersvehiculealivrer-dateFinLivraison").val('').selectpicker("refresh");
            $("#filtersvehiculealivrer-lieuLivraison").val('default').selectpicker("refresh");
            $("#filtersvehiculealivrer-statutPaiement").val('default').selectpicker("refresh");
            $("#filtersvehiculealivrer-statutLivraison").val('default').selectpicker("refresh");
            $("#filtersvehiculealivrer-typeVente").val('default').selectpicker("refresh");
            $("#filtersvehiculealivrer-dateDebutCommande").val('').selectpicker("refresh");
            $("#filtersvehiculealivrer-dateFinCommande").val('').selectpicker("refresh");
            $("#filtersvehiculealivrer-dateDebutFacture").val('').selectpicker("refresh");
            $("#filtersvehiculealivrer-dateFinFacture").val('').selectpicker("refresh");
        }

        filtreConfigFunOnEvent();
    }
})
