/**
 * Event listener
 * @event quick-expertise-update-prixht
 * @param {Event} e
 * @summary This event is used to update "prixht" from some field of VF.
 */
$('body').on('change', "[quick-expertise-update-prixht]", function (e) {
    const line = $(e.target).parents("[id^='ligne_vehicule_fournisseur_']");
    quickExpertiseUpdatePrixHt(line.data("idof"));
});

/**
 * @function quickExpertiseUpdatePrixHt
 * @param {number} idOF
 * @summary This function is used to update value of "prixht" of each "prestation" on quick expertise
 */
function quickExpertiseUpdatePrixHt(idOF) {
    const vehicules = {};
    const list = $("#liste_vehicule_fournisseur_offre_fournisseur_" + idOF);
    const lines = list.find("[id^='ligne_vehicule_fournisseur_'][id!='ligne_vehicule_fournisseur___id__']").toArray();
    lines.forEach(el => {
        let vfDejaExistant = el.querySelector("[id^='td_id_']").childElementCount;
        if (vfDejaExistant == false) {
            const line = $(el);
            const idVF = line.data("id");
            vehicules[idVF] = {};
            vehicules[idVF]["id"] = idVF;
            vehicules[idVF]["energie"] = line.find("[id='vehicule_fournisseur_input_carburant_" + idVF + "'][data-idof='" + idOF + "']").val();
            vehicules[idVF]["millesime"] = line.find("[id='vehicule_fournisseur_input_year_" + idVF + "'][data-idof='" + idOF + "']").val();
            vehicules[idVF]["carrosserie"] = line.find("[id='vehicule_fournisseur_input_modele_" + idVF + "'][data-idof='" + idOF + "']")[0].selectedOptions[0].getAttribute("data-carrosserie");
            vehicules[idVF]["marqueId"] = line.find("[id='vehicule_fournisseur_input_marque_" + idVF + "'][data-idof='" + idOF + "']").val();
            vehicules[idVF]["modeleId"] = line.find("[id='vehicule_fournisseur_input_modele_" + idVF + "'][data-idof='" + idOF + "']").val();
            vehicules[idVF] = JSON.stringify(vehicules[idVF]);
        }
    });
    const prestations = $("[quick-expertise-quantite]").toArray().map(el => parseInt(el.getAttribute("data-id"))).distinct();
    let formData = new FormData();
    formData.append('vehicules', JSON.stringify(vehicules));
    formData.append('prestations', JSON.stringify(prestations));
    $.ajax({
        type: 'POST',
        url: siteURL + 'admin/fre/freFrais/getPrestationPrix',
        contentType: false,
        processData: false,
        data: formData,
        success: function (data) {
            $("[quick-expertise-quantite]").toArray().forEach(el => {
                const prestationId = parseInt(el.getAttribute("data-id"));
                const line = $(el).parents("[id^='ligne_vehicule_fournisseur_'][id!='ligne_vehicule_fournisseur___id__']");
                if (line.length) {
                    const vehiculeId = parseInt(line[0].id.replace("ligne_vehicule_fournisseur_", ""));
                    $(el).data("prixht", data[vehiculeId][prestationId]);
                }
            });
        }
    });
}

/**
 * @function quickExpertiseQuantiteChange
 * @param {Element} el
 * @param {string} type
 * @param {number} step
 * @summary This function is used to change value of "quantite" input
 */
function quickExpertiseQuantiteChange(el, type, step = 1) {
    const quickExpertiseId = el.id.replace("-quantite-" + type, "");
    const newValue = parseInt($(el.parentElement).find("[quick-expertise-quantite]").html()) + (type == "plus" ? step : step * -1);
    $(el.parentElement).find("[quick-expertise-quantite]").html((newValue < 0 ? 0 : newValue));
    quickExpertiseUpdate(quickExpertiseId);
}

/**
 * @function quickExpertiseValue
 * @param {string} quickExpertiseId
 * @summary This function get internal value of "quickExpertise" modal
 */
function quickExpertiseValue(quickExpertiseId) {
    return $("#" + quickExpertiseId)
        .find("[quick-expertise-quantite]")
        .toArray()
        .map((e) => {
            return {
                "id": parseInt(e.getAttribute("data-id")),
                "quantite": parseInt(e.innerHTML),
                "prixht": parseFloat(e.getAttribute("data-prixht")),
            };
        });
}

/**
 * @function quickExpertiseTotal
 * @param {string} quickExpertiseId
 * @summary This function get internal value of "quickExpertise" modal
 */
function quickExpertiseTotal(quickExpertiseId) {
    return Object.values(quickExpertiseValue(quickExpertiseId)).reduce((a, e) => a + (e.quantite * e.prixht), 0);
}

/**
 * @function quickExpertiseUpdate
 * @param {string} quickExpertiseId
 * @summary This function update internal value of "quickExpertise" modal
 */
function quickExpertiseUpdate(quickExpertiseId) {
    updateBodyworkDamage(quickExpertiseId.replace(/\D/gm, ""));
}
