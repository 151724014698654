$(document).ready(function() {


    $("#designsaison-activeanimation").change(function() {
        if(this.checked) {
            $("#designsaison-animation").prop('disabled', false);
        } else {
            $("#designsaison-animation").prop('disabled', 'disabled');
        }
    });

    $("#designsaison-activelogo").change(function() {
        if(this.checked) {
            $("#designsaison-logo").prop('disabled', false);
        } else {
            $("#designsaison-logo").prop('disabled', 'disabled');
        }
    });


    //Envoi de modification generateurdesignsaison
    $('body').on('click', "#designsaison-send-modify", function() {
        
        var form = new FormData();
        // On extrait le contenu du formulaire dans la variable 'form'

        if($("#designsaison-activeanimation").prop( "checked" ) == true) {
            form.append('active-animation', 1);
        } else {
            form.append('active-animation', 0);
        }

        if($("#designsaison-activelogo").prop( "checked" ) == true) {
            form.append('active-logo', 1);
        } else {
            form.append('active-logo', 0);
        }

        var animation = $('#designsaison-animation option:selected').map(function(i,v) {
            return this.value;
        }).get();
        form.append('animation', animation[0]);

        var logo = $('#designsaison-logo option:selected').map(function(i,v) {
            return this.value;
        }).get();
        form.append('logo', logo[0]);

        
        $.ajax({
            type: 'POST',
            url: siteURL + 'admin/web/designsaison/modify',
            contentType: false,
            processData: false,
            data: form,
            success: function(data) {
                if (data == "exists") {
                    toastr.error('Erreur', 'Erreur');
                }
                else {
                    toastr.success('Les choix ont bien été enregisté', 'Succès');
                }
            }
        });

        return false;
            
    });

});