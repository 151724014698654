$(document).ready( function () {

    /* $(window).on('resize', function(){
        $('#resultvl, #resultvu').width($('#generateur-body').outerWidth() - 17);
    });
   $('#resultvl, #resultvu').width($('#generateur-body').outerWidth() - 17 );
   */

    // Lors du click sur une checkbox ou de la saisie d'un champ
    $("[id^='checkboxvu_'], [id^='checkboxvl_'], input[id^='optionv']").on('change keyup', function(e) {
            var o = '';
            // Si il s'agit d'une saisie dans l'input
            if (e.type == "keyup") { c_type = this.id.split('_')[0].replace('option', ''); }
            // Ou alors du click sur la checkbox
            else { c_type = this.id.split('_')[0].replace('checkbox', '');}
            // On cycle toutes les checkboxes
            $("[id^='checkbox"+c_type+"_']:checked").each( function (i, e) {
                // Récupérons quelques valeurs
                c_id = e.id.split('_')[1];
                c_value = $(e).data('content');
                // Si la checkbox est :checked
                if ($(e).is(':checked')) {
                    // On rajoute le contenu à l'output
                    o = o + $("#checkbox"+c_type+"_"+c_id).data('content');
                    // Si un champ custom est présent
                    if ($('#option'+c_type+'_'+c_id).length > 0) { o = o + " " + $('#option'+c_type+'_'+c_id).val(); }
                    // On rajoute un / si nous sommes dans le tableau autre part qu'à la dernière place et si le tableau fait moins d'un seul choix
                    if (i+1 < $("[id^='checkbox"+c_type+"_']:checked").length && $("[id^='checkbox"+c_type+"_']:checked").length != 1) { o = o + ' / ';}
                }
            });
            // On remplit le textarea
            $('#result'+c_type).html(o);
    });
    $("[id^='resultv'], [id^='gocopyv']").click( function() {
        if (this.id.indexOf("gocopy") >= 0) { type = this.id.replace('gocopy', ''); } else { type = this.id.replace('result', '') }
        var $temp = $("<input>");
        $("body").append($temp);
        if ($('#result'+type).text() != "") {
            $temp.val($('#result'+type).text()).select();
            document.execCommand("copy");
            toastr.success('Contenu du générateur copié dans le presse-papier', 'Succès');
        }
        $temp.remove();
    });
    $("[id^='goresetv']").click( function() {
        type = this.id.replace('goreset', '');
        $("[id^='checkbox"+type+"_']:checked").each( function () { $(this).prop( "checked", false ); });
        $("[id^='option"+type+"_']").each( function() { $(this).val("") });
        $('#result'+type).html("");
    });

    $('.sortable-vl').sortable({
        update: function(event, ui) {
            var start_pos = ui.item.data('start_pos');
            var index = ui.placeholder.index();
            var results = Array();
            $('[id^="categoriecard-vl-"]').each( function() {
                results.push(this.id.replace("categoriecard-vl-", ""));
            });
            var form = new FormData();
            form.append('order', results);
            $.ajax({
                type: 'POST',
                url: '../admin/generateur_options/reorder_vl',
                cache: false,
                contentType: false,
                processData: false,
                data: form,
            });
        },
    });
    $("#sortable-vl").disableSelection();

    $('.sortable-vu').sortable({
        update: function(event, ui) {
            var start_pos = ui.item.data('start_pos');
            var index = ui.placeholder.index();
            var results = Array();
            $('[id^="categoriecard-vu-"]').each( function() {
                results.push(this.id.replace("categoriecard-vu-", ""));
            });
            console.log(results);
            var form = new FormData();
            form.append('order', results);
            $.ajax({
                type: 'POST',
                url: '../admin/generateur_options/reorder_vu',
                cache: false,
                contentType: false,
                processData: false,
                data: form,
            });
        },
    });
    $("#sortable-vu").disableSelection();
});
