// Pour la vérification de la sélection de la carte grise
$('body').on('click', "[id^='papier_cg_']", function (e) {
    let value = this.id.replace('papier_cg_', '');
    let contreValue = (value == "true" ? "false" : "true")
    $('#papier_cg_' + value).addClass('btn-primary');
    $('#papier_cg_' + value).removeClass('btn-outline-secondary');
    $('#papier_cg_' + contreValue).removeClass('btn-primary');
    $('#papier_cg_' + contreValue).addClass('btn-outline-secondary');
});

// Pour la vérification de la sélection du certificat de conformité Européen
$('body').on('click', "[id^='papier_coc_']", function (e) {
    let value = this.id.replace('papier_coc_', '');
    let contreValue = (value == "true" ? "false" : "true")
    $('#papier_coc_' + value).addClass('btn-primary');
    $('#papier_coc_' + value).removeClass('btn-outline-secondary');
    $('#papier_coc_' + contreValue).removeClass('btn-primary');
    $('#papier_coc_' + contreValue).addClass('btn-outline-secondary');

    // Pour afficher l'input de CO2 certifié s'il y a le COC
    if (value == "true") {
        $('#card-papier-presence-coc').addClass('d-none');
        $('#card-papier-coc').removeClass('d-none');
    }
});

// Pour revenir sur la card de COC
$('body').on('click', "#retour-papier-coc", function (e) {
    $('#card-papier-presence-coc').removeClass('d-none');
    $('#card-papier-coc').addClass('d-none');
});

// Pour la vérification de la sélection du double des clé
$('body').on('click', "[id^='papier_doublecle_']", function (e) {
    let value = this.id.replace('papier_doublecle_', '');
    let contreValue = (value == "true" ? "false" : "true")
    $('#papier_doublecle_' + value).addClass('btn-primary');
    $('#papier_doublecle_' + value).removeClass('btn-outline-secondary');
    $('#papier_doublecle_' + contreValue).removeClass('btn-primary');
    $('#papier_doublecle_' + contreValue).addClass('btn-outline-secondary');
});

// Pour update les étapes de la réception
function updateReceptionPapier(step, dialog) {
    channelLoader(step, "papier", "on");

    // On vérifie d'abord s'il y a des champs manquants
    // Vérification des champs manquants
    let champManquants = [];

    // On va vérifier aussi les boutons spécifiques à la carte grise, COC et double des clés à l'étape 3
    let carteGriseReception = ($("#papier_cg_true").length ? $("#papier_cg_true").hasClass("btn-primary") : null);
    let certificatConformiteEuropeenReception = ($("#papier_coc_true").length ? $("#papier_coc_true").hasClass("btn-primary") : null);
    let doubleCleReception = ($("#papier_doublecle_true").length ? $("#papier_doublecle_true").hasClass("btn-primary") : null);
    let co2certifie = $('#papier_co2_certifie').val();
    if (carteGriseReception != null && !$("#papier_cg_true").hasClass('btn-primary') && !$("#papier_cg_false").hasClass('btn-primary')) {
        champManquants.push("Carte Grise");
    }
    if (certificatConformiteEuropeenReception != null && !$("#papier_coc_true").hasClass('btn-primary') && !$("#papier_coc_false").hasClass('btn-primary')) {
        champManquants.push("COC");
    }
    // Vérification du CO2 certifié si COC présent
    if (certificatConformiteEuropeenReception && co2certifie == "") {
        champManquants.push("CO2 certifié");
    }
    if (doubleCleReception != null && !$("#papier_doublecle_true").hasClass('btn-primary') && !$("#papier_doublecle_false").hasClass('btn-primary')) {
        champManquants.push("Doublé clé");
    }

    if (champManquants.length > 0) { // On met un toaster pour les champs manquants
        let debutText = champManquants.length > 1 ? "Les champs " : "Le champ ";
        let finText = champManquants.length > 1 ? " sont manquants." : " est manquant.";
        dialogToastrError(dialog, "papier", debutText + champManquants.join() + finText);
        channelLoader(step, "papier", "off");
    } else {
        let form = new FormData();
        form.append('idVehicule', $("#stock_vehicule-papier").attr("data-idvs"));
        form.append('cg', $("#papier_cg_true").hasClass("btn-primary"));
        form.append('coc', $("#papier_coc_true").hasClass("btn-primary"));
        form.append('db', $("#papier_doublecle_true").hasClass("btn-primary"));
        form.append('co2certifie', co2certifie);
        $.ajax({
            type: 'POST',
            url: siteURL + 'admin/stock/update/papier-update',
            data: form,
            processData: false,
            contentType: false,
            success: function(data) {
                console.log('data.statutOngletPapier :', data.statutOngletPapier);
                // Pour rafraîchir la pastille de l'onglet réception et débloquer l'onglet papier si la réception est finie
                $('#stock_vehicule-tab-papier .stock_vehicule-statut-onglet').removeClass('bg-danger').removeClass('bg-warning').removeClass('bg-success').addClass('bg-' + data.statutOngletPapier);
                $('#stock_vehicule-tab-papier .stock_vehicule-statut-onglet').html(data.statutOngletPapier == "danger" ? '<i class="fas fa-exclamation"></i>' : '<i class="fas fa-check"></i>');

                channelLoader(step, "papier", "off");
                channelClose(step, "papier");
                // On rafraîchit le tab, et on enlève le loader de la modal
                $("#stock_vehicule-papier-container").html(data.container);
                $("#stock_vehicule-tab-papier").trigger("click");
            },
            error: function () {
                dialogToastrError(dialog, "papier", "Une erreur s'est produite.");
            }
        });
    }
}