let actionprepa_table = null;
let timerBeforeRefreshDataActionPrepa = null;

$(document).ready(function() {
    if ($("#action_prepa_results").length) {
        colvisFunOnEvent();

        function loadAjax() {
            let optionsRecherche = optionsRecherchesDataForm();
            $('#action_prepa_loader').removeClass('d-none');
            $('.card-body').addClass('d-none');
            $("#action_prepa_results").addClass("d-none");
            $.ajax({
                type:'POST',
                url: siteURL + 'admin/preparation/action_prepa_ajax',
                contentType: false,
                processData: false,
                data: optionsRecherche,
                success: function(data){
                    $("#action_prepa_results").html(data);
                    // Génération de la datatable des generateur reseaux sociaux
                    actionprepa_table = $('#action_prepa_table').DataTable({
                        "dom": dataTablesCustomDom(),
                        "buttons": [dataTablesColvisBtn('th:nth-child(n+2)')],
                        "autoWidth": false,
                        "language": { "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/fr-FR.json" },
                        "paging": true,
                        "pageLength": 25,
                        "responsive": true,
                        "order": [[0, "desc"]],
                        "conditionalPaging": true,
                        "columnDefs": [
                            { type: 'num', targets: [0, 5] },
                            { "className": "text-center align-middle", "targets": '_all' }, // ALIGN MIDDLE
                            { "targets": 1, "orderable": false }
                        ],
                        "columns": getColvisColumn("action_prepa_table")
                    });
                    $('#action_prepa_table').on('init.dt', function () { initMirrorBtn(); setColvisTable(actionprepa_table); colvisFunSelectRefresh(); });
                    $('#action_prepa_table').on('draw.dt', function () { actionPrepaLinkGenerator(); });
                    $('#action_prepa_table').on('page.dt', function () { actionPrepaLinkGenerator(); });
                    $('#action_prepa_table').on('length.dt', function () { actionPrepaLinkGenerator(); });
                    $("#action_prepa_results").removeClass("d-none");
                    $('#action_prepa_loader').addClass('d-none');
                    $('.card-body').removeClass('d-none');
                }
            })
        };

        setupLoadAjax(loadAjax);
        setupResetFilter(resetFilter);

        $('body').on('change', "[id^='filtersactionprepa-']", function() {
            clearTimeout(timerBeforeRefreshDataActionPrepa);
            timerBeforeRefreshDataActionPrepa = setTimeout(loadAjax, 500);
        });

        $('body').on('click mouseup', "[id^='filtersactionprepa-prepa']", function() {
            clearTimeout(timerBeforeRefreshDataActionPrepa);
            timerBeforeRefreshDataActionPrepa = setTimeout(loadAjax, 500);
        });

        // Bouton reset
        $('body').on('click', '#filtersactionprepa-reset', function() {
            resetFilter();
            loadAjax();
        });


        function optionsRecherchesDataForm() {
            let optionsRecherche = new FormData();
            optionsRecherche.append('jourstock', $("#filtersactionprepa-jourstock").val());
            optionsRecherche.append('badge', $("#filtersactionprepa-badge").val());
            optionsRecherche.append('parc', $("#filtersactionprepa-parc").val());
            return optionsRecherche;
        }

        function resetFilter() {
            $("#filtersactionprepa-jourstock").val('default').selectpicker("refresh");
            $("#filtersactionprepa-badge").val('default').selectpicker("refresh");
            $("#filtersactionprepa-parc").val('default').selectpicker("refresh");
        }

        filtreConfigFunOnEvent();

        function actionPrepaLinkGenerator() {
            // Génération dynamique des liens sur le tableau
            actionprepa_table.rows().invalidate();
            let all_data = actionprepa_table.rows().data();
            $.each(all_data, function (key, value) {
                let tr = $('#' + value["DT_RowId"]);
                if (tr.length != 0) {
                    $("#stock_lacentrale_" + value["DT_RowId"].replace("action_prepa_table_tr_", "")).attr("href", lacentraleLinkGenerator(tr.attr("data-marque"), tr.attr("data-modele"), tr.attr("data-boite"), tr.attr("data-carburant"), tr.attr("data-datemec"), tr.attr("data-kilometrage")));
                    $("#stock_leboncoin_" + value["DT_RowId"].replace("action_prepa_table_tr_", "")).attr("href", leboncoinLinkGenerator(tr.attr("data-marque"), tr.attr("data-modele"), tr.attr("data-boite"), tr.attr("data-carburant"), tr.attr("data-datemec"), tr.attr("data-kilometrage")));
                    $("#stock_starterre_" + value["DT_RowId"].replace("action_prepa_table_tr_", "")).attr("href", starterreLinkGenerator(tr.attr("data-marque"), tr.attr("data-modele"), tr.attr("data-boite"), tr.attr("data-carburant"), tr.attr("data-datemec"), tr.attr("data-kilometrage")));
                    $("#stock_aramisauto_" + value["DT_RowId"].replace("action_prepa_table_tr_", "")).attr("href", aramisautoLinkGenerator(tr.attr("data-marque"), tr.attr("data-modele"), tr.attr("data-boite"), tr.attr("data-carburant"), tr.attr("data-datemec"), tr.attr("data-kilometrage")));
                }
            });
        }
    }
})
