let widget_table = null;

$(document).ready(function() {
    if ($("#widget_results").length) {

        function loadAjax() {
            $("#widget_results").addClass("d-none");
            $.ajax({
                type:'POST',
                url: siteURL + 'admin/configuration/widget_ajax',
                contentType: false,
                processData: false,
                data: {},
                success: function(data){
                    $("#widget_results").html(data);
                    widget_table = $('#widget_table').DataTable({
                        "autoWidth": false,
                        "language": {
                            "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/fr-FR.json"
                        },
                        "paging": false,
                        "responsive": true,
                        "order": [
                            [1, "asc"]
                        ],
                        "columnDefs": [
                            {
                                "className": "text-center align-middle",
                                "width": "50px",
                                "targets": [0],
                            }
                        ],
                        "conditionalPaging": true,
                    });
                    $('#widget_table').on('draw.dt', function () {
                        $("#widget_results").removeClass("d-none");
                    });
                }
            })
        };

        // Envoi de modification d'un widget
        $('body').on('change', "[id^='widget-input-']", function(e) {
            e.preventDefault();
            let widgetId = this.id.replace(/\D*/, '');
            let widgetConfigId = $("#widget-tr-"+widgetId).data("id");
            let ordre = ($('#widget-input-ordre-'+widgetId).length ? $('#widget-input-ordre-'+widgetId).val() : null);
            let form = new FormData();
            form.append('widgetConfigId', (widgetConfigId == "" ? null : widgetConfigId));
            form.append('widgetId', widgetId);
            form.append('ordre', ordre);
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/configuration/widget_opt/'+($('#widget-input-isVisible-'+widgetId).val() == "on" ? "modify" : "delete"),
                contentType: false,
                processData: false,
                data: form,
                success: function() {
                    toastr.success('La modification a bien été enregistrée', 'Succès');
                    loadAjax();
                },
            });
        });

        loadAjax();
    }
});