$(document).ready(function () {
    if ($("#annuaire_results").length) {
        $('body').on('click', "[id^='annuaire-sub-tab-suivi_relation_fournisseur-']", function (e) {
            const idAnnuaire = this.id.replace("annuaire-sub-tab-suivi_relation_fournisseur-", "");
            annuaireSuiviRelationFournisseurLoad(idAnnuaire, 0, true);
        });

        $('body').on('change', "[id^='annuaire-suiviRelationFournisseur-acheteur-']", function (e) {
            const idAnnuaire = this.id.replace("annuaire-suiviRelationFournisseur-acheteur-", "");
            annuaireSuiviRelationFournisseurAcheteurUpdate(idAnnuaire, this);
        });

        $('body').on('click change', "[id^='annuaire-suiviRelationFournisseur-timeline-']", function (e) {
            const base = "annuaire-suiviRelationFournisseur-timeline-";
            const id = this.id.replace(/^.*\D+(\d*)$/g, '$1');
            const extract = this.id.replace(base, "").replace(id, "").replace(/-$/, "");
            if (extract.length) {
                if (["isCompteRendu-on", "isCompteRendu-off"].includes(extract)) {
                    if (extract == "isCompteRendu-on") {
                        $("#" + base + "isCompteRendu-off-" + id).removeClass("btn-danger");
                        $("#" + base + "isCompteRendu-off-" + id).addClass("btn-outline-danger");
                        $("#" + base + "isCompteRendu-on-" + id).removeClass("btn-outline-success");
                        $("#" + base + "isCompteRendu-on-" + id).addClass("btn-success");
                        $("#" + base + "compteRendu-" + id).parent().removeClass("d-none");
                        $("#" + base + "compteRendu-" + id).parent().addClass("d-flex");
                        $("#" + base + "modify-" + id).parent().removeClass("d-none");
                        $("#" + base + "modify-" + id).parent().addClass("d-flex");
                    } else {
                        $("#" + base + "isCompteRendu-on-" + id).removeClass("btn-success");
                        $("#" + base + "isCompteRendu-on-" + id).addClass("btn-outline-success");
                        $("#" + base + "isCompteRendu-off-" + id).removeClass("btn-outline-danger");
                        $("#" + base + "isCompteRendu-off-" + id).addClass("btn-danger");
                        $("#" + base + "compteRendu-" + id).parent().removeClass("d-flex");
                        $("#" + base + "compteRendu-" + id).parent().addClass("d-none");
                        $("#" + base + "modify-" + id).parent().removeClass("d-flex");
                        $("#" + base + "modify-" + id).parent().addClass("d-none");
                    }
                } else if (extract == "modify") {
                    if ($("#" + base + "compteRendu-" + id).val().length) {
                        let form = new FormData();
                        form.append('id', id);
                        form.append('val', $("#" + base + "compteRendu-" + id).val());
                        $.ajax({
                            type: 'POST',
                            url: siteURL + 'admin/annuaire/suiviRelationFournisseur/compteRendu',
                            contentType: false,
                            processData: false,
                            data: form,
                            success: function (idAnnuaire) {
                                toastr.success('Enregistrement réussi', 'Succès');
                                annuaireSuiviRelationFournisseurLoad(idAnnuaire);
                            },
                            error: function () {
                                toastr.error('Erreur lors de l\'enregistrement', 'Erreur');
                            }
                        });
                    } else {
                        toastr.error('Le compte rendu n\'est pas renseigné', 'Erreur');
                    }
                }
            }
        });
    }
});

function annuaireSuiviRelationFournisseurLoad(idAnnuaire, scroll = 0, modify = false) {
    let offset = 0;
    let limit = 20;
    if ($("#annuaire-suiviRelationFournisseur-timeline-chargement-" + idAnnuaire).length && modify == false) {
        offset = $("#annuaire-suiviRelationFournisseur-timeline-chargement-" + idAnnuaire).data("offset");
    }
    let form = new FormData();
    form.append('idSuiviRelationFournisseurObject', 0);
    form.append('idAnnuaire', idAnnuaire);
    form.append('offset', offset);
    form.append('limit', limit);
    $.ajax({
        type: 'POST',
        url: siteURL + 'admin/annuaire/suiviRelationFournisseur/load',
        contentType: false,
        processData: false,
        data: form,
        success: function (data) {
            if (data == null) {
                toastr.error('Erreur lors du chargement', 'Erreur');
            } else {
                // Si on a déjà une timeline, c'est qu'on charge les évènements antérieurs, donc on doit les rajouter à la timeline
                    if (offset > 0) {
                    offset += 20;
                    $("#annuaire-suiviRelationFournisseur-timeline-chargement-" + idAnnuaire).remove();
                    $("#annuaire-suiviRelationFournisseur-timeline-" + idAnnuaire).prepend(data);
                    $('#annuaire-tab-suivi_relation_fournisseur-' + idAnnuaire + '-suiviRelationFournisseur').prepend(
                        '<button class="btn btn-primary w-fit btn-cta mx-auto my-3" id="annuaire-suiviRelationFournisseur-timeline-chargement-' + idAnnuaire + '" data-offset="' + offset + '">Charger plus</button>'
                    );
                } else {
                    $('#annuaire-tab-suivi_relation_fournisseur-' + idAnnuaire + '-suiviRelationFournisseur').html(data);
                    $('#annuaire-tab-suivi_relation_fournisseur-' + idAnnuaire + '-suiviRelationFournisseur').prepend(
                        '<button class="btn btn-primary w-fit btn-cta mx-auto my-3" id="annuaire-suiviRelationFournisseur-timeline-chargement-' + idAnnuaire + '" data-offset="' + limit + '">Charger plus</button>'
                    );
                }

                if($('#annuaire-suiviRelationFournisseur-timeline-fini-' + idAnnuaire).length) {
                    $('#annuaire-suiviRelationFournisseur-timeline-chargement-' + idAnnuaire).remove();
                }

                if (scroll != 0) {
                    $('#annuaire-tab-suivi_relation_fournisseur-' + idAnnuaire + '-suiviRelationFournisseur').scrollTop($('#annuaire-tab-suivi_relation_fournisseur-' + idAnnuaire + '-suiviRelationFournisseur')[0].scrollHeight - scroll);
                } else {
                    annuaireSuiviRelationFournisseurScroll(idAnnuaire);
                }
                getStatusMailAsynchrone($("#annuaire-tabs-modify-" + idAnnuaire).find('[id^="annuaire-suiviRelationFournisseur-status-mail-"]'));
            }
        }
    });
}

function annuaireSuiviRelationFournisseurAcheteurUpdate(idAnnuaire, el) {
    const value = el.value;
    const elDelete = $(el).parent();
    const elParent = $(el).parent().parent();
    $.ajax({
        type: 'POST',
        url: siteURL + 'admin/annuaire/annuaire/acheteur',
        data: { id: idAnnuaire, user: value },
        success: function (data) {
            elDelete.remove();
            elParent.append(data);
            toastr.success('L\'acheteur a bien été modifiée', 'Success');
        },
        error: function () {
            toastr.error('Erreur lors de l\'enregistrement', 'Erreur');
        }
    });
}

function annuaireSuiviRelationFournisseurScroll(idAnnuaire) {
    $('#annuaire-tab-suivi_relation_fournisseur-' + idAnnuaire + '-suiviRelationFournisseur').scrollTop($('#annuaire-tab-suivi_relation_fournisseur-' + idAnnuaire + '-suiviRelationFournisseur')[0].scrollHeight);
}

function annuaireSuiviRelationFournisseurIcs(idAnnuaire, id) {
    let options = new FormData();
    options.append('idAnnuaire', idAnnuaire);
    $.ajax({
        type: 'POST',
        url: siteURL + 'admin/annuaire/suiviRelationFournisseur/ics',
        contentType: false,
        processData: false,
        data: options,
        success: function (data) {
            let options = "";
            if (data.data.annuaire.email != null && data.data.annuaire.email != "") {
                options += "<option value=\"" + data.data.annuaire.email + "\">" + data.data.annuaire.email + "</option>";
            }
            if (data.data.annuaire.employeEntreprises != null) {
                data.data.annuaire.employeEntreprises.forEach(employeMail => {
                    options += "<option value=\"" + employeMail + "\">" + employeMail + "</option>";
                });
            }
            $("#annuaire-suiviRelationFournisseur-input-ics-select-client-" + idAnnuaire).html(options);
            $("#annuaire-suiviRelationFournisseur-input-ics-checkbox-client-" + idAnnuaire).prop("checked", false);
            $("#annuaire-suiviRelationFournisseur-input-ics-checkbox-commercial-" + idAnnuaire).prop("checked", true);
            $("#annuaire-suiviRelationFournisseur-input-ics-select-commercial-" + idAnnuaire).html("<option value=\"" + data.data.user.email + "\">" + data.data.user.email + "</option>");
            $("#annuaire-suiviRelationFournisseur-input-ics-" + idAnnuaire).data("id", id);
            $("#annuaire-suiviRelationFournisseur-modal-ics-" + idAnnuaire).modal('show');
        }
    });
}

$('body').on('click', "[id^='annuaire-suiviRelationFournisseur-input-ics-']", function () {
    let idAnnuaire = this.id.replace("annuaire-suiviRelationFournisseur-input-ics-", "");
    if (idAnnuaire.match(/\d+/)[0] == idAnnuaire.match(/\d+/)["input"]) {
        let options = new FormData();
        options.append('clientTo', ($("#annuaire-suiviRelationFournisseur-input-ics-checkbox-client-" + idAnnuaire).is(":checked") ? true : null));
        options.append('client', $("#annuaire-suiviRelationFournisseur-input-ics-select-client-" + idAnnuaire).val());
        options.append('commercialTo', ($("#annuaire-suiviRelationFournisseur-input-ics-checkbox-commercial-" + idAnnuaire).is(":checked") ? true : null));
        options.append('commercial', $("#annuaire-suiviRelationFournisseur-input-ics-select-commercial-" + idAnnuaire).val());
        $.ajax({
            type: 'POST',
            url: siteURL + 'admin/agenda/ics/' + $("#annuaire-suiviRelationFournisseur-input-ics-" + idAnnuaire).data("id"),
            contentType: false,
            processData: false,
            data: options,
            success: function (data) { (data.startsWith("success;") ? toastr.success(data.replace("success;", ""), "Succès") : toastr.error(data.replace("error;", ""), "Erreur")) },
            error: function (data) { toastr.error(data.replace("error;", ""), "Erreur") }
        });
    }
});

$('body').on('click', "[id^='annuaire-suiviRelationFournisseur-timeline-chargement-']", function () {
    let idAnnuaire = this.id.replace("annuaire-suiviRelationFournisseur-timeline-chargement-", "");
    let scroll = $('#annuaire-tab-suivi_relation_fournisseur-' + idAnnuaire + '-suiviRelationFournisseur')[0].scrollHeight;
    $('#annuaire-suiviRelationFournisseur-timeline-chargement-' + idAnnuaire).html('<i class="fas fa-spinner fa-spin"></i>')
    annuaireSuiviRelationFournisseurLoad(idAnnuaire, scroll);
});