$(document).ready( function () {

    makeSummerNote('offreemploi_content_responsabilites_0', 200, "");
    makeSummerNote('offreemploi_content_qualifications_0', 200, "");
    makeSummerNote('offreemploi_content_avantages_0',200, "");

	// Génération de la table des évènements
    offreemploi_table = $('#offreemploi_table').DataTable( {
        "autoWidth": false,
    	"language": {
            "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/fr-FR.json"
        },
        "responsive": true,
    	"ajax": siteURL + 'json/offresemploi',
    	"order": [[ 2, "desc" ]],
    	"columnDefs": [
            // ALIGN MIDDLE
            {
                "className": "align-middle",
                "targets": '_all',
            },
            // CENTRE
    		{
                "targets": 0,
    			"render": function ( data, type, row ) {
                    return row[1];
                },
    		},
            // NOTE
            {
                "targets": 1,
                "render": function ( data, type, row ) {
                    return row[2];
                },
            },
            // DATE
            {
                "targets": 2,
                "render": function ( data, type, row ) {
                    return row[3];
                },
            },
            //CLIENT
    		{
                "targets": 3,
    			"render": function ( data, type, row ) {
                    return row[4];
                },
                "orderable": false,
    		},
            // CONTENU
            {
                "targets": 4,
                "render": function ( data, type, row ) {
                    return row[5];
                },
                "orderable": false,
            },
                // VALIDE
            {
                "width": "50px",
                "targets": 5,
                "render": function (data, type, row) {
                    if (row[6] == 1) {
                        return '<i class="fal fa-check-square"></i><span class="d-inline d-lg-none pl-2">OUI</span>';
                    }
                    else {
                        return '<i class="fal fa-square"></i><span class="d-inline d-lg-none pl-2">NON</span>';
                    }
                },
                "orderable": false,
            },
            // MODIFY
    		{
                "width": "50px",
                "targets": 6,
    			"render": function ( data, type, row ) {
                    return '<button class="btn w-100 p-0" id="offreemploi-open-modify-'+row[0]+'" data-category="administratif"><i class="fad fa-edit"></i>';
                },
                "orderable": false,
    		},
    	],
        "conditionalPaging": true,
    });

    // Envoi de modification d'une offre d'emploi
    $('body').on('click', "button[id^='offreemploi-send-modify-']", function() {
        var id_clicked = this.id.replace('offreemploi-send-modify-', '');

        var form = new FormData();
        // On extrait le contenu du formulaire dans la variable 'form'
        form.append('id', id_clicked);
        form.append('offre_poste', $('#offreemploi_poste_'+id_clicked).val());
        form.append('offre_contrat', $('select[name="offreemploi_contrat_'+id_clicked+'"]').val());
        form.append('offre_duree', $('#offreemploi_duree_'+id_clicked).val());
        form.append('offre_remuneration', $('#offreemploi_remuneration_'+id_clicked).val());
        form.append('offre_dateembauche', $('#offreemploi_dateembauche_'+id_clicked).val());
        form.append('offre_responsabilites', $('#offreemploi_content_responsabilites_'+id_clicked).val());
        form.append('offre_qualifications', $('#offreemploi_content_qualifications_'+id_clicked).val());
        if ($('#offreemploi_content_avantages_'+id_clicked).val() != "\t\t\t\t\t\t\t\t\t\t\t") {
            form.append('offre_avantages', $('#offreemploi_content_avantages_'+id_clicked).val());
        }
        else {
            form.append('offre_avantages', "");
        }
        form.append('offre_destination', $('#offreemploi_destination_'+id_clicked).val());
        form.append('offre_centre', $('select[name="offreemploi_centre_'+id_clicked+'"]').val());
        form.append('offre_isactive', $('input[name="offreemploi_onsite_'+id_clicked+'"]:checked').val());
        form.append('offre_image', $('#file_result_0').val());

        start_slideshow = 1000;
        $("input[id^='file_result_']").each( function(e) {

        });

        // Requète ajax d'envoi du formulaire : /src/Controller/AdminController.php -> admin_offresemploi()
        $.ajax({
            type: 'POST',
            url: siteURL + 'admin/administratif/offreemploi/modify',
            cache: false,
            contentType: false,
            processData: false,
            data: form,
            success: function(data) {
                // TOAST
                toastr.success('L\'offre d\'emploi a bien été enregistrée', 'Succès');
                // On nettoye le html en supprimant l'onglet 'Modifier/Nouveau'
                $('#offreemploi-tabs-modify-'+id_clicked).remove();
                $('#offreemploi-close-modify-'+id_clicked).parent().remove();
                // Si nous sommes sur une nouvelle offre d'emploi (id == 0)
                if (id_clicked == 0) {
                    // On réinitialise le formulaire
                    $('#formoffreemploi_'+id_clicked)[0].reset();
                    $("[id*='_content_']").each( function(e) { $(this).summernote('reset'); });
                }
                // Retour à la liste d'actualités en rechargeant la datatable
                $('#offreemploi-tabs-list-tab').trigger('click');
                offreemploi_table.ajax.reload();
            }
        });
         // Empèche le 'button' de fonctionner
        return false;
    });
});