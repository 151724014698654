let vehicule_fournisseur_listing_table = null;
let timerBeforeRefreshDataVehiculeFournisseurListing = null;
let selectedListingvfCache = [];

$(document).ready(function() {
    if ($("#vehicule_fournisseur_listing_results").length) {

        colvisFunOnEvent();
        function loadAjax() {
            if (vehicule_fournisseur_listing_table != null) {
                vehicule_fournisseur_listing_table.rows().invalidate();
                let all_data = vehicule_fournisseur_listing_table.rows().data();
                id_vehicules = [];
                $.each(all_data, function(key, value) {
                    let idCheckBox = value[1]["@data-order"];
                    if (idCheckBox != "") {
                        if ($("#selection_vehicule_fournisseur_"+idCheckBox).is(':checked')) {
                            id_vehicules.push(idCheckBox.replace("selection_vehicule_fournisseur_", ""));
                        }
                    }
                });
                for (let index = 0; index < id_vehicules.length; index++) {
                    selectedListingvfCache.push(id_vehicules[index]);
                }
                selectedListingvfCache = selectedListingvfCache.filter((value, index, array) => array.indexOf(value) === index);
            }
            let optionsRecherche = new FormData();
            optionsRecherche.append('identifiant', $("#filtersvehiculefournisseurlisting-identifiant").val());
            optionsRecherche.append('marques', $("#filtersvehiculefournisseurlisting-marque").val());
            optionsRecherche.append('modeles', $("#filtersvehiculefournisseurlisting-modele").val());
            optionsRecherche.append('statut', $("#filtersvehiculefournisseurlisting-statut").val());
            optionsRecherche.append('active', 1);
            optionsRecherche.append('createur', $("#filtersvehiculefournisseurlisting-createur").val());
            optionsRecherche.append('fournisseur', $("#filtersvehiculefournisseurlisting-fournisseur").val());
            $("#vehicule_fournisseur_listing_results").addClass("d-none");
            $.ajax({
                type:'POST',
                url: siteURL + 'admin/outils/vehicule_fournisseur_listing/loadResults',
                contentType: false,
                processData: false,
                data: optionsRecherche,
                success: function(data){
                    $("#vehicule_fournisseur_listing_results").html(data);
                    for (let index = 0; index < selectedListingvfCache.length; index++) {
                        $("#selection_vehicule_fournisseur_"+selectedListingvfCache[index]).prop("checked", true);
                    }
                    vehicule_fournisseur_listing_table = $('#vehicule_fournisseur_listing_table').DataTable({
                        "dom": dataTablesCustomDom(),
                        "buttons": [dataTablesColvisBtn(['th:nth-child(n+3):not(:last-child)'])],
                        "autoWidth": false,
                        "language": {
                            "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/fr-FR.json"
                        },
                        "paging": true,
                        "pageLength": 25,
                        "responsive": true,
                        "order": [[3, "asc"], [4, "asc"]],
                        "conditionalPaging": true,
                        "columnDefs": [
                            { type: 'num', targets: [1, 10, 11, 12, 13, 14, 15, 16, 17, 18] },
                            { "className": "text-center align-middle", "targets": '_all' }, // ALIGN MIDDLE
                            { "targets": [0, 1, 22], "orderable": false }
                        ],
                        "columns": getColvisColumn("vehicule_fournisseur_listing_table")
                    });
                    $('#vehicule_fournisseur_listing_table').on('init.dt', function () { initMirrorBtn(); setColvisTable(vehicule_fournisseur_listing_table); colvisFunSelectRefresh(); });

                    $('#vehicule_fournisseur_listing_table').on('draw.dt', function () {
                        // Génération dynamique des liens sur le tableau
                        vehicule_fournisseur_listing_table.rows().invalidate();
                        let all_data = vehicule_fournisseur_listing_table.rows().data();
                        $.each(all_data, function(key, value) {
                            let idCheckBox = value[1]["@data-order"];
                            let tr = $('#vehicule_fournisseur_listing_table_tr_'+idCheckBox);
                            if (tr.length) {
                                $("#vehicule_fournisseur_listing_lacentrale_"+idCheckBox.replace("selection_vehicule_fournisseur_", "")).attr("href", lacentraleLinkGenerator(tr.attr("data-marque"), tr.attr("data-modele"), tr.attr("data-boite"), tr.attr("data-carburant"), tr.attr("data-datemec"), tr.attr("data-kilometrage")));
                                $("#vehicule_fournisseur_listing_leboncoin_"+idCheckBox.replace("selection_vehicule_fournisseur_", "")).attr("href", leboncoinLinkGenerator(tr.attr("data-marque"), tr.attr("data-modele"), tr.attr("data-boite"), tr.attr("data-carburant"), tr.attr("data-datemec"), tr.attr("data-kilometrage")));
                                $("#vehicule_fournisseur_listing_starterre_"+idCheckBox.replace("selection_vehicule_fournisseur_", "")).attr("href", starterreLinkGenerator(tr.attr("data-marque"), tr.attr("data-modele"), tr.attr("data-boite"), tr.attr("data-carburant"), tr.attr("data-datemec"), tr.attr("data-kilometrage")));
                                $("#vehicule_fournisseur_listing_aramisauto_"+idCheckBox.replace("selection_vehicule_fournisseur_", "")).attr("href", aramisautoLinkGenerator(tr.attr("data-marque"), tr.attr("data-modele"), tr.attr("data-boite"), tr.attr("data-carburant"), tr.attr("data-datemec"), tr.attr("data-kilometrage")));
                            }
                        });
                        $("#vehicule_fournisseur_listing_results").removeClass("d-none");
                    });
                }
            })
        };

        setupLoadAjax(loadAjax);
        setupLoadModele(loadModele);
        setupResetFilter(resetFilter);

        $('body').on('keyup', "[id^='filtersvehiculefournisseurlisting-identifiant']", function() {
            clearTimeout(timerBeforeRefreshDataVehiculeFournisseurListing);
            timerBeforeRefreshDataVehiculeFournisseurListing = setTimeout(loadAjax, 500);
        });

        $('body').on('change', "[id^='filtersvehiculefournisseurlisting-']", function() {
            clearTimeout(timerBeforeRefreshDataVehiculeFournisseurListing);
            timerBeforeRefreshDataVehiculeFournisseurListing = setTimeout(loadAjax, 500);
        });

        // Bouton reset
        $('body').on('click', '#filtersvehiculefournisseurlisting-reset', function() {
            resetFilter();
            loadAjax();
        });

        $('body').on('click', "[id^='vehicule-fournisseur-etat-']", function() {
            let etat = this.id.replace("vehicule-fournisseur-etat-", "").replace(/-\d+/g, "");
            let form = new FormData();
            form.append('id_vehicules', [this.id.replace(/\w+-/g, "")]);
            vfEtatModify(form, etat);
            loadAjax();
        });

        $('body').on('click', "#menu-vehicule-fournisseur-listing-delete", function() {
            if (vehicule_fournisseur_listing_table != null) {
                Lobibox.confirm({
                    title: 'Suppression',
                    baseClass: "modal-content modal-lobistrap",
                    msg: "Êtes-vous sûr de vouloir supprimer cet élément <strong>définitivement</strong> de la base de données ? Attention, il sera <strong>impossible</strong> de le restaurer ensuite.",
                    buttons : {
                        ok : {
                            type: 'ok',
                            text: 'Supprimer'
                        },
                        cancel : {
                            type: 'cancel',
                            text: 'Conserver'
                        }
                    },
                    callback: function(lobibox, type){
                        if (type === 'ok'){
                            vehicule_fournisseur_listing_table.rows().invalidate();
                            let all_data = vehicule_fournisseur_listing_table.rows().data();
                            let id_vehicules = [];
                            $.each(all_data, function(key, value) {
                                let idCheckBox = value[1]["@data-order"];
                                if ($("#selection_vehicule_fournisseur_"+idCheckBox).is(':checked')) {
                                    id_vehicules.push(idCheckBox.replace("selection_vehicule_fournisseur_", ""));
                                }
                            });
                            // Si aucun véhicule est rentré
                            if (id_vehicules.length == 0) {
                                toastr.error('Merci de sélectionner au moins un véhicule', 'Erreur');
                                return false;
                            }
                            let form = new FormData();
                            form.append('id_vehicules', id_vehicules);
                            $.ajax({
                                type:'POST',
                                url: siteURL + 'admin/vehicule_fournisseur/update/deleteSeveral',
                                contentType: false,
                                processData: false,
                                data: form,
                                success: function(data) {
                                    if (data.split(',').length > 1) {
                                        toastr.success('Véhicules fournisseur désactivés', 'Success');
                                    } else {
                                        toastr.success('Véhicule fournisseur désactivé', 'Success');
                                    }
                                    loadAjax();
                                    return false;
                                },
                                error: function() {
                                    toastr.error('Erreur lors de la désactivation des véhicules fournisseur', 'Erreur');
                                }
                            });
                        }
                    },
                });
            }
        });

        $('body').on('change', "#filtersvehiculefournisseurlisting-marque", loadModele);

        // Bouton refresh
        $('body').on('click', "#vehicule_fournisseur-tabs-list-tab", function() {
            loadAjax();
        });

        // Bouton édition prix minimum marchand
        $('body').on('click dblclick', "[id^='edit_prix_proposition_']", function(e) {
            let regexPrixProposition = /\d+/g;
            let idVehicule = $(this)[0].id.match(regexPrixProposition)[0];
            let OriginalContent = $(this).text();
            let OriginalContentMatched = OriginalContent.match(regexPrixProposition);
            if (OriginalContentMatched != null) {
                $(this).parent().html("<input type='text' id='input_prix_proposition_"+idVehicule+"' size='10' value='"+OriginalContentMatched.join("")+"' />");
            } else {
                $(this).parent().html("<input type='text' id='input_prix_proposition_"+idVehicule+"' size='10' value='' />");
            }
            $("#input_prix_proposition_"+idVehicule).trigger("focus");
            let fre_ht = $("#fre_ht_"+idVehicule).text();
            let malus = $("#malus_"+idVehicule).text();
            let prix_marche_ttc = $("#prix_marche_ttc_"+idVehicule).text();
            let prixPropo = $("[id='prix_proposition_"+idVehicule+"']");
            let prixMargeClient = $("[id='prix_marge_client_ttc_"+idVehicule+"']");
            let prixMargeHT = $("[id='prix_marge_ht_"+idVehicule+"']");
            let prixRevientMarchandTtc = $("[id='prix_revient_marchand_ttc_"+idVehicule+"']");
            $("#input_prix_proposition_"+idVehicule).on("keyup", function(e) {
                let prix_proposition = setPrixProposition($(this), regexPrixProposition, idVehicule);
                if (e.key == "Enter") {
                    if (prix_proposition == 0) {
                        for (let i = 0; i < prixPropo.length; i++) {
                            $(prixPropo[i]).html("<button type='button' id='edit_prix_proposition_"+idVehicule+"' class='btn btn-outline-dark btn-sm text-12'>Éditer <i class='fas fa-pen'></i></button>");
                            $(prixPropo[i]).parent()[0].dataset.order = 0;
                            if (vehicule_fournisseur_listing_table != null) {vehicule_fournisseur_listing_table.rows().invalidate();}
                            for (let j = 0; j < prixMargeClient.length; j++) {
                                $(prixMargeClient[j]).html("");
                            }
                        }
                        $("#vehicule_fournisseur_td_id_"+idVehicule).html("");
                    } else {
                        for (let i = 0; i < prixPropo.length; i++) {
                            $(prixPropo[i]).html("<button type='button' id='edit_prix_proposition_"+idVehicule+"' class='btn btn-outline-dark btn-sm text-12'>" + prix_proposition.replace(/(?=(\d\d\d)+(?!\d))/g, " ").trim() + " €</button>");
                            $(prixPropo[i]).parent()[0].dataset.order = prix_proposition;
                            if (vehicule_fournisseur_listing_table != null) {vehicule_fournisseur_listing_table.rows().invalidate();}
                            for (let j = 0; j < prixMargeClient.length; j++) {
                                $(prixMargeClient[j]).html((prix_marche_ttc - prix_proposition - malus - fre_ht).toString().replace(/(?=(\d\d\d)+(?!\d))/g, " ").trim() + " €");
                                let newMargeHt = Math.round((prix_proposition - $(prixRevientMarchandTtc[j]).html().match(regexPrixProposition).join("")) / 1.2);
                                $(prixMargeHT[j]).html(newMargeHt + " €/HT");
                            }
                        }
                        $("#vehicule_fournisseur_td_id_"+idVehicule).html("<input type=\"checkbox\" id=\"selection_vehicule_fournisseur_"+idVehicule+"\" onclick=\"checkVehiculeFournisseur(event)\">");
                    }
                }
            });
            $("#input_prix_proposition_"+idVehicule).on("blur", function() {
                let prix_proposition = setPrixProposition($(this), regexPrixProposition, idVehicule);
                if (prix_proposition == 0) {
                    for (let i = 0; i < prixPropo.length; i++) {
                        $(prixPropo[i]).html("<button type='button' id='edit_prix_proposition_"+idVehicule+"' class='btn btn-outline-dark btn-sm text-12'>Éditer <i class='fas fa-pen'></i></button>");
                        $(prixPropo[i]).parent()[0].dataset.order = 0;
                        if (vehicule_fournisseur_listing_table != null) {vehicule_fournisseur_listing_table.rows().invalidate();}
                        for (let j = 0; j < prixMargeClient.length; j++) {
                            $(prixMargeClient[j]).html("");
                        }
                    }
                    $("#vehicule_fournisseur_td_id_"+idVehicule).html("");
                } else {
                    for (let i = 0; i < prixPropo.length; i++) {
                        $(prixPropo[i]).html("<button type='button' id='edit_prix_proposition_"+idVehicule+"' class='btn btn-outline-dark btn-sm text-12'>" + prix_proposition.replace(/(?=(\d\d\d)+(?!\d))/g, " ").trim() + " €</button>");
                        $(prixPropo[i]).parent()[0].dataset.order = prix_proposition;
                        if (vehicule_fournisseur_listing_table != null) {vehicule_fournisseur_listing_table.rows().invalidate();}
                        for (let j = 0; j < prixMargeClient.length; j++) {
                            $(prixMargeClient[j]).html((prix_marche_ttc - prix_proposition - malus - fre_ht).toString().replace(/(?=(\d\d\d)+(?!\d))/g, " ").trim() + " €");
                            let newMargeHt = Math.round((prix_proposition - $(prixRevientMarchandTtc[j]).html().match(regexPrixProposition).join("")) / 1.2);
                            $(prixMargeHT[j]).html(newMargeHt + " €/HT");
                        }
                    }
                    $("#vehicule_fournisseur_td_id_"+idVehicule).html("<input type=\"checkbox\" id=\"selection_vehicule_fournisseur_"+idVehicule+"\" onclick=\"checkVehiculeFournisseur(event)\">");
                }
            });
            $("#input_prix_proposition_"+idVehicule).click(function(e) {
                e.stopPropagation();
            });
        });

        $('body').on('click', "[id^='vehicule_fournisseur_listing_export_']", function() {
            if (vehicule_fournisseur_listing_table != null) {
                exporttype = this.id.replace("vehicule_fournisseur_listing_export_", "");
                vehicule_fournisseur_listing_table.rows().invalidate();
                let all_data = vehicule_fournisseur_listing_table.rows().data();
                let id_vehicules = [];
                $.each(all_data, function(key, value) {
                    let idCheckBox = value[1]["@data-order"];
                    if (idCheckBox != "") {
                        if ($("#selection_vehicule_fournisseur_"+idCheckBox).is(':checked')) {
                            id_vehicules.push(idCheckBox.replace("selection_vehicule_fournisseur_", ""));
                        }
                    }
                });
                if (id_vehicules.length) {
                    var form = new FormData();
                    form.append('ids', id_vehicules);
                    today = new Date();

                    switch (exporttype) {
                        case 'excel':
                            $.ajax({
                                type: 'POST',
                                url: siteURL + 'admin/outils/vehicule_fournisseur_listing/ficheExcel',
                                contentType: false,
                                processData: false,
                                data: form,
                                success: function(data) {
                                    toastr.success('Le fichier excel est fini', 'Succès');
                                    var element = document.createElement('a');
                                    element.setAttribute('href',siteURL + "assets/docs/vehicule_fournisseur/results_vf.xlsx");
                                    element.setAttribute('download', "Liste Vehicule Fournisseur Ora7 "+today.toLocaleDateString("fr")+".xlsx");
                                    document.body.appendChild(element);
                                    element.click();
                                    document.body.removeChild(element);
                                }
                            });
                            break;
                        case 'pdf':
                            $.ajax({
                                type: 'POST',
                                url: siteURL + 'admin/outils/vehicule_fournisseur_listing/fichePDF',
                                contentType: false,
                                processData: false,
                                data: form,
                                success: function(data) {
                                    toastr.success('Le fichier pdf est fini', 'Succès');
                                    var element = document.createElement('a');
                                    element.setAttribute('href',siteURL + "assets/docs/vehicule_fournisseur/results_vf.pdf");
                                    element.setAttribute('download', "Liste Vehicule Fournisseur Ora7 "+today.toLocaleDateString("fr")+".pdf");
                                    document.body.appendChild(element);
                                    element.click();
                                    document.body.removeChild(element);
                                }
                            });
                            break;
                        default:
                            break;
                    }
                } else {
                    toastr.error('Merci de sélectionner au moins un véhicule', 'Erreur');
                    return false;
                }
                return true;
            }
        });

        $('body').on('click', "#button-listing-proposition-commercial", function() {
            if (vehicule_fournisseur_listing_table != null) {
                vehicule_fournisseur_listing_table.rows().invalidate();
                let all_data = vehicule_fournisseur_listing_table.rows().data();
                id_vehicules = [];
                $.each(all_data, function(key, value) {
                    let idCheckBox = value[1]["@data-order"];
                    if (idCheckBox != "") {
                        if ($("#selection_vehicule_fournisseur_"+idCheckBox).is(':checked')) {
                            id_vehicules.push(idCheckBox.replace("selection_vehicule_fournisseur_", ""));
                        }
                    }
                });
                // Si aucun véhicule est rentré
                if (id_vehicules.length == 0) {
                    toastr.error('Merci de sélectionner au moins un véhicule', 'Erreur');
                    return false;
                }
                if (id_vehicules.length > 30) {
                    toastr.error('Merci de renseigner moins de 30 véhicules', 'Erreur');
                    return false;
                }
                Lobibox.window({
                    width: 1500,
                    title: 'Liste vehicule fournisseur prix remisé',
                    baseClass: "modal-content modal-lobistrap",
                    url: siteURL+'admin/outils/vehicule_fournisseur_listing/propositionCommercial',
                    params: {'ids': id_vehicules},
                    showAfterLoad: false,
                });
                return false;
            }
        });

        /**
         * Proposition par mail
         */
        $('body').on('click', "#vehicule_fournisseur_proposition_mail", function() {
            if (vehicule_fournisseur_listing_table != null) {
                vehicule_fournisseur_listing_table.rows().invalidate();
                let all_data = vehicule_fournisseur_listing_table.rows().data();
                let id_vehicules = [];
                let id_vehiculesSansVF = [];
                $.each(all_data, function(key, value) {
                    let idCheckBox = value[1]["@data-order"];
                    if (idCheckBox != "") {
                        if ($("#selection_vehicule_fournisseur_"+idCheckBox).is(':checked')) {
                            id_vehicules.push("vf-" + idCheckBox.replace("selection_vehicule_fournisseur_", ""));
                            id_vehiculesSansVF.push(idCheckBox.replace("selection_vehicule_fournisseur_", ""));
                        }
                    }
                });

                // Si aucun véhicule est rentré
                if (id_vehiculesSansVF.length == 0) {
                    toastr.error('Merci de sélectionner au moins un véhicule', 'Erreur');
                    return false;
                }
                if (id_vehiculesSansVF.length > 30) {
                    toastr.error('Merci de renseigner moins de 30 véhicules', 'Erreur');
                    return false;
                }
                window.open($(this).data("src") + "?listIdVF=" + id_vehicules.join(), "_blank");
                id_vehiculesSansVF.forEach(element => {
                    $("#selection_vehicule_fournisseur_" + element).prop("checked", false);
                });
                return false;
            }
        });

        // Pour créer une nouvelle proposition
        $('body').on('click', "[id^='vehicule_fournisseur_proposition_individuelle_mail-']", function (e) {
            let idVehicule = this.id.replace("vehicule_fournisseur_proposition_individuelle_mail-", "");
            window.open($(this).data("src") + "?listIdVF=" + idVehicule, "_blank");
        });

        // Update le champ nom_client_marchand dans la pop-up achat
        $('body').on('change', "[id^='type_achat_']", function() {
            let idVF = this.id.replace("type_achat_", "");
            if ($(this).val() == "achat_client_marchand") {
                $("#nom_client_marchand_"+idVF).removeClass("d-none");
            } else {
                $("#nom_client_marchand_"+idVF).addClass("d-none");
            }
        });

        function resetFilter() {
            $("#filtersvehiculefournisseurlisting-identifiant").val('').selectpicker("refresh");
            $("#filtersvehiculefournisseurlisting-marque").val('default').selectpicker("refresh");
            $("#filtersvehiculefournisseurlisting-modele").find("optgroup").prop('disabled', true);
            $('#filtersvehiculefournisseurlisting-modele').prop("disabled", true);
            $("#filtersvehiculefournisseurlisting-modele").val('default').selectpicker('refresh');
            $("#filtersvehiculefournisseurlisting-statut").val('default').selectpicker("refresh");
            $("#filtersvehiculefournisseurlisting-createur").val('default').selectpicker("refresh");
            $("#filtersvehiculefournisseurlisting-fournisseur").val('default').selectpicker("refresh");
            $("#filtreConfigSelect-filtreConfig-aucun").val('aucun').prop("selected", true);
        }

        function loadModele() {
            $("#filtersvehiculefournisseurlisting-modele").find("optgroup").prop('disabled', true);
            if($('#filtersvehiculefournisseurlisting-marque').val().length != 0) {
                $("#filtersvehiculefournisseurlisting-modele").prop('disabled', false);
                $('#filtersvehiculefournisseurlisting-marque').val().forEach(element => { $("[id='opt_"+element+"']").prop('disabled', false); });
                $("[id^='opt_']").each(function() {
                    let marqueEnCours = $(this).attr('id').replace('opt_', '');
                    if (!$('#filtersvehiculefournisseurlisting-marque').val().includes(marqueEnCours)) {
                        $(this).prop('disabled', true);
                    }
                })
                $("#filtersvehiculefournisseurlisting-modele").selectpicker('refresh');
            } else {
                $('#filtersvehiculefournisseurlisting-modele').prop("disabled", true);
                $("#filtersvehiculefournisseurlisting-modele").val('default').selectpicker('refresh');
            }
        }

        filtreConfigFunOnEvent();
    }
});

function setPrixProposition(el, regexPrixProposition, idVehicule) {
    let newContent = el.val().match(regexPrixProposition);
    if (newContent == null) {
        newContent = [0];
        el.val("");
    } else {
        el.val(newContent.join(""));
    }
    let form = new FormData();
    form.append('id', idVehicule);
    form.append('prix_proposition', parseInt(newContent.join("")));
    $.ajax({
        type:'POST',
        url: siteURL + 'admin/outils/vehicule_fournisseur_listing/setPrixProposition',
        contentType: false,
        processData: false,
        data: form,
        success: function(data){
            // OK
        }
    });
    return form.get('prix_proposition');
}

