$(document).ready(function () {
    if ($("#annuaire_results").length) {
        $('body').on('click', "[id^='annuaire-sub-tab-suivi_relation-']", function (e) {
            const idAnnuaire = this.id.replace("annuaire-sub-tab-suivi_relation-", "");
            annuaireSuiviRelationLoad(idAnnuaire, 0, true);
        });

        $('body').on('change', "[id^='annuaire-suiviRelation-vendeur-']", function (e) {
            const idAnnuaire = this.id.replace("annuaire-suiviRelation-vendeur-", "");
            annuaireSuiviRelationVendeurUpdate(idAnnuaire, this);
        });

        $('body').on('click change', "[id^='annuaire-suiviRelation-timeline-']", function (e) {
            const base = "annuaire-suiviRelation-timeline-";
            const id = this.id.replace(/^.*\D+(\d*)$/g, '$1');
            const extract = this.id.replace(base, "").replace(id, "").replace(/-$/, "");
            if (extract.length) {
                if (["isCompteRendu-on", "isCompteRendu-off"].includes(extract)) {
                    if (extract == "isCompteRendu-on") {
                        $("#" + base + "isCompteRendu-off-" + id).removeClass("btn-danger");
                        $("#" + base + "isCompteRendu-off-" + id).addClass("btn-outline-danger");
                        $("#" + base + "isCompteRendu-on-" + id).removeClass("btn-outline-success");
                        $("#" + base + "isCompteRendu-on-" + id).addClass("btn-success");
                        $("#" + base + "compteRendu-" + id).parent().removeClass("d-none");
                        $("#" + base + "compteRendu-" + id).parent().addClass("d-flex");
                        $("#" + base + "modify-" + id).parent().removeClass("d-none");
                        $("#" + base + "modify-" + id).parent().addClass("d-flex");
                    } else {
                        $("#" + base + "isCompteRendu-on-" + id).removeClass("btn-success");
                        $("#" + base + "isCompteRendu-on-" + id).addClass("btn-outline-success");
                        $("#" + base + "isCompteRendu-off-" + id).removeClass("btn-outline-danger");
                        $("#" + base + "isCompteRendu-off-" + id).addClass("btn-danger");
                        $("#" + base + "compteRendu-" + id).parent().removeClass("d-flex");
                        $("#" + base + "compteRendu-" + id).parent().addClass("d-none");
                        $("#" + base + "modify-" + id).parent().removeClass("d-flex");
                        $("#" + base + "modify-" + id).parent().addClass("d-none");
                    }
                } else if (extract == "modify") {
                    if ($("#" + base + "compteRendu-" + id).val().length) {
                        let form = new FormData();
                        form.append('id', id);
                        form.append('val', $("#" + base + "compteRendu-" + id).val());
                        $.ajax({
                            type: 'POST',
                            url: siteURL + 'admin/annuaire/suiviRelation/compteRendu',
                            contentType: false,
                            processData: false,
                            data: form,
                            success: function (idAnnuaire) {
                                toastr.success('Enregistrement réussi', 'Succès');
                                annuaireSuiviRelationLoad(idAnnuaire, 0, true);
                            },
                            error: function () {
                                toastr.error('Erreur lors de l\'enregistrement', 'Erreur');
                            }
                        });
                    } else {
                        toastr.error('Le compte rendu n\'est pas renseigné', 'Erreur');
                    }
                }
            }
        });

        $('body').on('click', "[id^='annuaire-suiviRelation-edit-type-appelTelephonique-']", function (e) {
            $("#annuaire-appelTelephonique-process-modal-" + this.id.replace("annuaire-suiviRelation-edit-type-appelTelephonique-", "")).modal("show");
        });
    }
});

function annuaireSuiviRelationLoad(idAnnuaire, scroll = 0, modify = false) {
    let offset = 0;
    let limit = 20;
    if ($("#annuaire-suiviRelation-timeline-chargement-" + idAnnuaire).length && modify == false) {
        offset = $("#annuaire-suiviRelation-timeline-chargement-" + idAnnuaire).data("offset");
    }
    let form = new FormData();
    form.append('idSuiviRelationObject', 0);
    form.append('idAnnuaire', idAnnuaire);
    form.append('offset', offset);
    form.append('limit', limit);
    // Rajouter un limit/offset qui sera sur un élément HTML
    $.ajax({
        type: 'POST',
        url: siteURL + 'admin/annuaire/suiviRelation/load',
        contentType: false,
        processData: false,
        data: form,
        success: function (data) {
            if (data == null) {
                toastr.error('Erreur lors du chargement', 'Erreur');
            } else {
                // Si on a déjà une timeline, c'est qu'on charge les évènements antérieurs, donc on doit les rajouter à la timeline
                if (offset > 0) {
                    offset += 20;
                    $("#annuaire-suiviRelation-timeline-chargement-" + idAnnuaire).remove();
                    $("#annuaire-suiviRelation-timeline-" + idAnnuaire).prepend(data);
                    $('#annuaire-tab-suivi_relation-' + idAnnuaire + '-suiviRelation').prepend(
                        '<button class="btn btn-primary w-fit btn-cta mx-auto my-3" id="annuaire-suiviRelation-timeline-chargement-' + idAnnuaire + '" data-offset="' + offset + '">Charger plus</button>'
                    );
                } else {
                    $('#annuaire-tab-suivi_relation-' + idAnnuaire + '-suiviRelation').html(data);
                    $('#annuaire-tab-suivi_relation-' + idAnnuaire + '-suiviRelation').prepend(
                        '<button class="btn btn-primary w-fit btn-cta mx-auto my-3" id="annuaire-suiviRelation-timeline-chargement-' + idAnnuaire + '" data-offset="' + limit + '">Charger plus</button>'
                    );
                }

                if($('#annuaire-suiviRelation-timeline-fini-' + idAnnuaire).length) {
                    $('#annuaire-suiviRelation-timeline-chargement-' + idAnnuaire).remove();
                }

                if (scroll != 0) {
                    $('#annuaire-tab-suivi_relation-' + idAnnuaire + '-suiviRelation').scrollTop($('#annuaire-tab-suivi_relation-' + idAnnuaire + '-suiviRelation')[0].scrollHeight - scroll);
                } else {
                    annuaireSuiviRelationScroll(idAnnuaire);
                }
                getStatusMailAsynchrone($("#annuaire-tabs-modify-" + idAnnuaire).find('[id^="annuaire-suiviRelation-status-mail-"]'));
                getStatusCampagneMailAsynchrone($("#annuaire-tabs-modify-" + idAnnuaire).find('[id^="annuaire-suiviRelation-status-campagne-mail-"]'));
            }
        }
    });
}

function annuaireSuiviRelationVendeurUpdate(idAnnuaire, el) {
    const value = el.value;
    const elDelete = $(el).parent();
    const elParent = $(el).parent().parent();
    $.ajax({
        type: 'POST',
        url: siteURL + 'admin/annuaire/annuaire/vendeur',
        data: { id: idAnnuaire, user: value },
        success: function (data) {
            elDelete.remove();
            elParent.append(data);
            toastr.success('Le vendeur a bien été modifiée', 'Success');
        },
        error: function () {
            toastr.error('Erreur lors de l\'enregistrement', 'Erreur');
        }
    });
}

function annuaireSuiviRelationScroll(idAnnuaire) {
    $('#annuaire-tab-suivi_relation-' + idAnnuaire + '-suiviRelation').scrollTop($('#annuaire-tab-suivi_relation-' + idAnnuaire + '-suiviRelation')[0].scrollHeight);
}

function annuaireSuiviRelationIcs(idAnnuaire, id) {
    let options = new FormData();
    options.append('idAnnuaire', idAnnuaire);
    $.ajax({
        type: 'POST',
        url: siteURL + 'admin/annuaire/suiviRelation/ics',
        contentType: false,
        processData: false,
        data: options,
        success: function (data) {
            let options = "";
            if (data.data.annuaire.email != null && data.data.annuaire.email != "") {
                options += "<option value=\"" + data.data.annuaire.email + "\">" + data.data.annuaire.email + "</option>";
            }
            if (data.data.annuaire.employeEntreprises != null) {
                data.data.annuaire.employeEntreprises.forEach(employeMail => {
                    options += "<option value=\"" + employeMail + "\">" + employeMail + "</option>";
                });
            }
            $("#annuaire-suiviRelation-input-ics-select-client-" + idAnnuaire).html(options);
            $("#annuaire-suiviRelation-input-ics-checkbox-client-" + idAnnuaire).prop("checked", false);
            $("#annuaire-suiviRelation-input-ics-checkbox-commercial-" + idAnnuaire).prop("checked", true);
            $("#annuaire-suiviRelation-input-ics-select-commercial-" + idAnnuaire).html("<option value=\"" + data.data.user.email + "\">" + data.data.user.email + "</option>");
            $("#annuaire-suiviRelation-input-ics-" + idAnnuaire).data("id", id);
            $("#annuaire-suiviRelation-modal-ics-" + idAnnuaire).modal('show');
        }
    });
}

$('body').on('click', "[id^='annuaire-suiviRelation-input-ics-']", function () {
    let idAnnuaire = this.id.replace("annuaire-suiviRelation-input-ics-", "");
    if (idAnnuaire.match(/\d+/)[0] == idAnnuaire.match(/\d+/)["input"]) {
        let options = new FormData();
        options.append('clientTo', ($("#annuaire-suiviRelation-input-ics-checkbox-client-" + idAnnuaire).is(":checked") ? true : null));
        options.append('client', $("#annuaire-suiviRelation-input-ics-select-client-" + idAnnuaire).val());
        options.append('commercialTo', ($("#annuaire-suiviRelation-input-ics-checkbox-commercial-" + idAnnuaire).is(":checked") ? true : null));
        options.append('commercial', $("#annuaire-suiviRelation-input-ics-select-commercial-" + idAnnuaire).val());
        $.ajax({
            type: 'POST',
            url: siteURL + 'admin/agenda/ics/' + $("#annuaire-suiviRelation-input-ics-" + idAnnuaire).data("id"),
            contentType: false,
            processData: false,
            data: options,
            success: function (data) { (data.startsWith("success;") ? toastr.success(data.replace("success;", ""), "Succès") : toastr.error(data.replace("error;", ""), "Erreur")) },
            error: function (data) { toastr.error(data.replace("error;", ""), "Erreur") }
        });
    }
});

$('body').on('click', "[id^='annuaire-suiviRelation-timeline-chargement-']", function () {
    let idAnnuaire = this.id.replace("annuaire-suiviRelation-timeline-chargement-", "");
    let scroll = $('#annuaire-tab-suivi_relation-' + idAnnuaire + '-suiviRelation')[0].scrollHeight;
    $('#annuaire-suiviRelation-timeline-chargement-' + idAnnuaire).html('<i class="fas fa-spinner fa-spin"></i>')
    annuaireSuiviRelationLoad(idAnnuaire, scroll);
});