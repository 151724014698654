$(document).ready(function () {
    if ($("#annuaire_results").length) {
        let lobibox_display_bibliothequeLogo = null;

        // Update Espace Marchand date de validation
        $('body').on('click', "[id^='annuaire_boutiquePro_isActiv_']", function (e) {
            let idAnnuaire = this.id.replace("annuaire_boutiquePro_isActiv_", "");
            let input = $(this);
            input.prop('disabled', true);
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/annuaire/espaceMarchand/activation',
                data: { idAnnuaire: idAnnuaire, activation: this.value },
                success: function (data) {
                    input.prop('disabled', false);
                    if (input.val() == "on") {
                        toastr.success('L\'espace marchand a bien été activé', 'Success');
                        $("#annuaire-side-stats-" + idAnnuaire + "-isActiv").html("<i class='far fa-check text-success'></i> Actif");
                    } else {
                        toastr.success('L\'espace marchand a bien été désactivé', 'Success');
                        $("#annuaire-side-stats-" + idAnnuaire + "-isActiv").html("<i class='far fa-times text-danger'></i> Inactif");
                    }
                    if (data) {
                        $("#annuaire-side-stats-" + idAnnuaire + "-datePremiereValidation").html((new Date(Date.now())).toLocaleString('fr-FR', { timeZone: 'Europe/Paris' }).replace(/(.*):\d{2}/, "$1") + " <span class='text-muted text-10 fw-normal'>0 seconde</span>");
                    }
                },
                error: function () {
                    toastr.error('Erreur lors de l\'enregistrement', 'Erreur');
                }
            });
        });

        // Update Espace Marchand vehiculeFournisseurAcces
        $('body').on('click', "[id^='annuaire_boutiquePro_vehiculeFournisseurAcces_']", function (e) {
            let idAnnuaire = this.id.replace("annuaire_boutiquePro_vehiculeFournisseurAcces_", "");
            let input = $(this);
            input.prop('disabled', true);
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/annuaire/espaceMarchand/vehiculeFournisseurAcces',
                data: { idAnnuaire: idAnnuaire, vehiculeFournisseurAcces: this.value },
                success: function (data) {
                    toastr.success('L\'accès aux véhicules fournisseurs de l\'espace marchand a bien été mis à jour', 'Success');
                },
                error: function () {
                    toastr.error('Erreur lors de l\'enregistrement', 'Erreur');
                }
            });
        });

        // Update Espace Marchand offrePrixEligible
        $('body').on('click', "[id^='annuaire_boutiquePro_offrePrixEligible_']", function (e) {
            let idAnnuaire = this.id.replace("annuaire_boutiquePro_offrePrixEligible_", "");
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/annuaire/espaceMarchand/offrePrixEligible',
                data: { idAnnuaire: idAnnuaire, offrePrixEligible: this.value },
                success: function (data) {
                    toastr.success('L\'éligibilité aux offres de prix de l\'espace marchand a bien été mis à jour', 'Success');
                },
                error: function () {
                    toastr.error('Erreur lors de l\'enregistrement', 'Erreur');
                }
            });
        });

        // Update Espace Marchand banniere
        $('body').on('change', "[id^='annuaire_boutiquePro_banniere_']", function (e) {
            let idAnnuaire = this.id.replace("annuaire_boutiquePro_banniere_", "");
            let input = $(this);
            const [file] = this.files;
            if (file) {
                input.prop('disabled', true);
                let form = new FormData();
                form.append('data', input[0].files[0]);
                form.append('filemime', input.data("mime"));
                form.append('filetypes', input.data("filetypes"));
                form.append('imageratio', input.data("imageRatio"));
                form.append('idEspaceMarchand', input.data("idespacemarchand"));
                form.append('espaceMarchandType', "banner");

                $.ajax({
                    url: 'file/upload',
                    type: 'POST',
                    cache: false,
                    contentType: false,
                    processData: false,
                    data: form,
                    success: function (data) {
                        // success == true
                        if (data.success == true) {
                            console.log(data);
                            console.log(input);
                            console.log(input.parent());
                            input.parent().css("background-size", "cover");
                            input.parent().css("background-position", "center");
                            input.parent().css("background-image", "url(" + data.path + data.file + ")");
                            input.prop('disabled', false);
                            toastr.success('La banniere a bien été enregistré.', 'Success');
                        }
                        // success == false
                        else {
                            input.prop('disabled', false);
                            if (data.error == 'exists') {
                                toastr.error('Erreur lors de l\'enregistrement, le fichier existe déjà.', 'Erreur');
                            } else if (data.error == 'type') {
                                toastr.error('Erreur lors de l\'enregistrement, le type de fichier n\'est pas accepté.', 'Erreur');
                            } else if (data.error == 'ratio') {
                                toastr.error('Erreur lors de l\'enregistrement, le ratio de taille du fichier n\'est pas accepté.', 'Erreur');
                            }
                        }
                    },
                });
            }
        });

        // Update Espace Marchand logo
        $('body').on('change', "[id^='annuaire_boutiquePro_logo_']", function (e) {
            let idAnnuaire = this.id.replace("annuaire_boutiquePro_logo_", "");
            let input = $(this);
            const [file] = this.files;
            let image = $("#annuaire_boutiquePro_logoImage_" + idAnnuaire);
            if (file) {
                input.prop('disabled', true);
                let form = new FormData();
                form.append('data', input[0].files[0]);
                form.append('filemime', input.data("mime"));
                form.append('filetypes', input.data("filetypes"));
                form.append('imageratio', input.data("imageRatio"));
                form.append('idEspaceMarchand', input.data("idespacemarchand"));
                form.append('espaceMarchandType', "logo");

                $.ajax({
                    url: 'file/upload',
                    type: 'POST',
                    cache: false,
                    contentType: false,
                    processData: false,
                    data: form,
                    success: function (data) {
                        // success == true
                        if (data.success == true) {
                            console.log(data);
                            image[0].src = data.path + data.file;
                            input.prop('disabled', false);
                            toastr.success('Le logo a bien été enregistré.', 'Success');
                        }
                        // success == false
                        else {
                            input.prop('disabled', false);
                            if (data.error == 'exists') {
                                toastr.error('Erreur lors de l\'enregistrement, le fichier existe déjà.', 'Erreur');
                            } else if (data.error == 'type') {
                                toastr.error('Erreur lors de l\'enregistrement, le type de fichier n\'est pas accepté.', 'Erreur');
                            } else if (data.error == 'ratio') {
                                toastr.error('Erreur lors de l\'enregistrement, le ratio de taille du fichier n\'est pas accepté.', 'Erreur');
                            }
                        }
                    },
                });
            }
        });

        // Ouvre la bibliothèque d'image et envoie la réponse du logo
        $('body').on('click', "[id='annuaire_boutiquePro_bibliothequeLogo']", function () {
            // On sauvegarde l'id annuaire pour l'utiliser après
            let idAnnuaire = $("[id^='annuaire_boutiquePro_logo_']");
            idAnnuaire = idAnnuaire[0].id.replace("annuaire_boutiquePro_logo_", "");

            // On ouvre une modale présentant tout les logos de la bibliothèque
            lobibox_display_bibliothequeLogo = Lobibox.window({
                width: 1200,
                title: 'Bibliothèque de logo',
                baseClass: "modal-content modal-lobistrap",
                url: siteURL + 'admin/annuaire/espaceMarchand/display/bibliotheque_logo',
                showAfterLoad: false,
            });
        });

        // Update le logo selon le logo cliqué
        $('body').on('click', ".logo-bibliotheque-logo", function (e) {
            let idAnnuaire = $("[id^='annuaire_boutiquePro_logo_']");
            idAnnuaire = idAnnuaire[0].id.replace("annuaire_boutiquePro_logo_", "");

            // On sauvegarde l'image
            let imageChoisi = $(this).children();
            let lienImageChoisi = imageChoisi[0].src;
            let nomImageChoisi = imageChoisi[0].id;

            // On ferme la modale Lobibox
            lobibox_display_bibliothequeLogo.destroy();

            let image = $("#annuaire_boutiquePro_logoImage_" + idAnnuaire);

            let form = new FormData();
            form.append('idAnnuaire', idAnnuaire);

            $.ajax({
                url: siteURL + 'admin/annuaire/espaceMarchand/display/bibliotheque_logo/uploadLogo/' + nomImageChoisi,
                type: 'POST',
                contentType: false,
                processData: false,
                data: form,
                success: function (data) {
                    // success == true
                    if (data.success == true) {
                        console.log(data);
                        image[0].src = siteURL + data.route;
                        toastr.success('Le logo a bien été enregistré.', 'Success');
                    }
                    // success == false
                    else if (data.success == false) {
                        toastr.error('Erreur lors de l\'enregistrement, le logo n\'a pas été trouvé.', 'Erreur');
                    } else {
                        toastr.error('Erreur lors de l\'enregistrement, erreur 500.', 'Erreur')
                    }
                },
            });
        });

        // Update Espace Marchand couleur principale
        $('body').on('change', "[id^='annuaire_boutiquePro_couleurPrincipale_']", function (e) {
            let idAnnuaire = this.id.replace("annuaire_boutiquePro_couleurPrincipale_", "");
            let input = $(this);
            input.prop('disabled', true);
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/annuaire/espaceMarchand/couleurPrincipale',
                data: { idAnnuaire: idAnnuaire, couleurPrincipale: this.value },
                success: function (data) {
                    input.prop('disabled', false);
                    input.parent().next().html(input.val());
                    toastr.success('La couleur a bien été modifiée', 'Success');
                },
                error: function () {
                    toastr.error('Erreur lors de l\'enregistrement', 'Erreur');
                }
            });
        });

        // Update Espace Marchand couleur secondaire
        $('body').on('change', "[id^='annuaire_boutiquePro_couleurSecondaire_']", function (e) {
            let idAnnuaire = this.id.replace("annuaire_boutiquePro_couleurSecondaire_", "");
            let input = $(this);
            input.prop('disabled', true);
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/annuaire/espaceMarchand/couleurSecondaire',
                data: { idAnnuaire: idAnnuaire, couleurSecondaire: this.value },
                success: function (data) {
                    input.prop('disabled', false);
                    input.parent().next().html(input.val());
                    toastr.success('La couleur a bien été modifiée', 'Success');
                },
                error: function () {
                    toastr.error('Erreur lors de l\'enregistrement', 'Erreur');
                }
            });
        });

        // Update Espace Marchand date de validation
        $('body').on('change', "[id^='annuaire_boutiquePro_marge_']", function (e) {
            let idAnnuaire = this.id.replace("annuaire_boutiquePro_marge_", "");
            let input = $(this);
            input.prop('disabled', true);
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/annuaire/espaceMarchand/marge',
                data: { idAnnuaire: idAnnuaire, marge: this.value },
                success: function (data) {
                    input.prop('disabled', false);
                    toastr.success('La marge a bien été modifiée', 'Success');
                },
                error: function () {
                    toastr.error('Erreur lors de l\'enregistrement', 'Erreur');
                }
            });
        });

        // Update Utilisateur Espace Marchand roles
        $('body').on('change', "[id^='annuaire_utilisateurEspaceMarchand_roles_']", function (e) {
            let idUtilisateurEspaceMarchand = this.id.replace("annuaire_utilisateurEspaceMarchand_roles_", "");
            let input = $(this);
            input.prop('disabled', true);
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/annuaire/utilisateurEspaceMarchand/roles',
                data: { idUtilisateurEspaceMarchand: idUtilisateurEspaceMarchand, roles: [this.value] },
                success: function (data) {
                    input.prop('disabled', false);
                    toastr.success('Le role a bien été modifiée', 'Success');
                },
                error: function () {
                    toastr.error('Erreur lors de l\'enregistrement', 'Erreur');
                }
            });
        });

        // Update Utilisateur Espace Marchand isActiv
        $('body').on('click', "[id^='annuaire_utilisateurEspaceMarchand_isActiv_']", function (e) {
            let idUtilisateurEspaceMarchand = this.id.replace("annuaire_utilisateurEspaceMarchand_isActiv_", "");
            let input = $(this);
            input.prop('disabled', true);
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/annuaire/utilisateurEspaceMarchand/activation',
                data: { idUtilisateurEspaceMarchand: idUtilisateurEspaceMarchand, activation: this.value },
                success: function (data) {
                    input.prop('disabled', false);
                    if (input.val() == "on") {
                        toastr.success('L\'utilisateur de l\'espace marchand a bien été activé', 'Success');
                    } else {
                        toastr.success('L\'utilisateur de l\'espace marchand a bien été désactivé', 'Success');
                    }
                },
                error: function () {
                    toastr.error('Erreur lors de l\'enregistrement', 'Erreur');
                }
            });
        });

        // Update Utilisateur Espace Marchand MDP
        $('body').on('click', "[id^='annuaire_utilisateurEspaceMarchand_reinitialiserMDP_']", function (e) {
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/annuaire/utilisateurEspaceMarchand/reinitialisation',
                data: { email: $(this).attr("data-mail") },
                success: function (data) {
                    console.log(data)
                    if (data == "ok") {
                        toastr.success('Le mail de reinitialisation à bien été envoyé', 'Success');
                    } else {
                        toastr.error(data, 'Erreur');
                    }
                },
                error: function () {
                    toastr.error('Erreur lors de l\'envoi', 'Erreur');
                }
            });
        });
    }
});
