// Polyfill .repeat
if (!String.prototype.repeat) {
  String.prototype.repeat = function(count) {
    'use strict';
    if (this == null) {
      throw new TypeError('can\'t convert ' + this + ' to object');
    }
    var str = '' + this;
    count = +count;
    if (count != count) {
      count = 0;
    }
    if (count < 0) {
      throw new RangeError('repeat count must be non-negative');
    }
    if (count == Infinity) {
      throw new RangeError('repeat count must be less than infinity');
    }
    count = Math.floor(count);
    if (str.length == 0 || count == 0) {
      return '';
    }
    // Ensuring count is a 31-bit integer allows us to heavily optimize the
    // main part. But anyway, most current (August 2014) browsers can't handle
    // strings 1 << 28 chars or longer, so:
    if (str.length * count >= 1 << 28) {
      throw new RangeError('repeat count must not overflow maximum string size');
    }
    var rpt = '';
    for (;;) {
      if ((count & 1) == 1) {
        rpt += str;
      }
      count >>>= 1;
      if (count == 0) {
        break;
      }
      str += str;
    }
    // Could we try:
    // return Array(count + 1).join(this);
    return rpt;
  }
}


$(document).ready( function () {

	// Génération de la table des évènements
    avisclients_table = $('#avisclients_table').DataTable( {
        "autoWidth": false,
    	"language": {
            "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/fr-FR.json"
        },
        "responsive": true,
    	"ajax": siteURL + 'json/avisclients',
    	"order": [[ 2, "desc" ]],
    	"columnDefs": [
            { responsivePriority: 1, targets: 0 },
            { responsivePriority: 2, targets: 1 },
            { responsivePriority: 3, targets: 7 },
            { responsivePriority: 4, targets: 6 },
            { responsivePriority: 5, targets: 8 },
            { responsivePriority: 6, targets: 5 },
            { responsivePriority: 7, targets: 3 },
            { responsivePriority: 8, targets: 2 },
            { responsivePriority: 9, targets: 4 },
            // TEXT CENTER
            {
                "className": "text-center align-middle",
                "targets": [1, 6, 7, 8],
            },
            // ALIGN MIDDLE
            {
                "className": "align-middle",
                "targets": '_all',
            },
            // CENTRE
    		{
                "targets": 0,
    			"render": function ( data, type, row ) {
                    return row[1];
                },
    		},
            // NOTE
            {
                "targets": 1,
                "render": function ( data, type, row ) {
                    var notes_negatives = 5 - row[5];
                    var notes_positives = 5 - notes_negatives;

                    return '<i class="fas fa-star text-warning text-8 text-nowrap"></i>'.repeat(notes_positives) + '<i class="fal fa-star text-nowrap"></i>'.repeat(notes_negatives);
                },
            },
            // DATE
            {
                "targets": 2,
                "render": function ( data, type, row ) {
                    var monthNames = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];
                    var avisDate = row[4].split('/');
                    if ( avisDate != '') {
                        return avisDate[0] + '/' + avisDate[1] + '/' + avisDate[2];
                        //return avisDate[2] + '<span class="d-none d-lg-inline"> ' + monthNames[eval(avisDate[1]) - 1] + ' </span><span class="d-inline d-lg-none">/'+avisDate[1]+'/</span>' + avisDate[0];
                    }
                    else {
                        return '--';
                    }
                },
            },
            //CLIENT
    		{
                "targets": 3,
    			"render": function ( data, type, row ) {
                    return row[2] + ' ' + row[3];
                },
                "orderable": false,
    		},
            // CONTENU
            {
                "width": "500px",
                "targets": 4,
                "render": function ( data, type, row ) {
                    return row[7].replace(/\n/g,"<br />");
                },
                "orderable": false,
            },
            // SOURCE
            {
                "targets": 5,
                "render": function ( data, type, row ) {
                    return row[6];
                },
            },
            // VISIBLE
    		{
                "width": "40px",
                "targets": 6,
                "render": function (data, type, row) {
                    if (row[8] == 1) {
                        return '<i class="fal fa-check-square"></i><span class="d-inline d-lg-none pl-2">OUI</span>';
                    }
                    else {
                        return '<i class="fal fa-square"></i><span class="d-inline d-lg-none pl-2">NON</span>';
                    }
                },
            },
            // VALIDE
            {
                "width": "50px",
                "targets": 7,
                "render": function (data, type, row) {
                    if (row[9] == 1) {
                        return '<i class="fal fa-check-square"></i><span class="d-inline d-lg-none pl-2">OUI</span>';
                    }
                    else {
                        return '<i class="fal fa-square"></i><span class="d-inline d-lg-none pl-2">NON</span>';
                    }
                },
            },
            // MODIFY
    		{
                "width": "50px",
                "targets": 8,
    			"render": function ( data, type, row ) {
                    return '<button class="btn w-100 p-0" id="avisclients-open-modify-'+row[0]+'" data-category="web"><i class="fad fa-edit"></i>';
                },
                "orderable": false,
    		},
    	],
        "conditionalPaging": true,
    });

        // Envoi de modification d'un avis client
    $('body').on('click', "button[id^='avisclients-send-modify-']", function() {
        var id_clicked = this.id.split(/[- ]+/).pop();

        var form = new FormData();
        // On extrait le contenu du formulaire dans la variable 'form'
        form.append('id', id_clicked);
        form.append('nomclient', $('#avisclients_nom_'+id_clicked).val());
        form.append('prenomclient', $('#avisclients_prenom_'+id_clicked).val());
        form.append('note', $('select[name="avisclients_note_'+id_clicked+'"]').val());
        form.append('date', $('#avisclients_date_'+id_clicked).val());
        form.append('texte', $('#avisclients_texte_'+id_clicked).val());
        form.append('source', $('#avisclients_source_'+id_clicked).val());
        form.append('centre', $('select[name="avisclients_centre_'+id_clicked+'"]').val());
        form.append('isvisible', $('input[name="avisclients_visible_'+id_clicked+'"]:checked').val());
        form.append('isvalid', $('input[name="avisclients_valide_'+id_clicked+'"]:checked').val());

        // Requète ajax d'envoi du formulaire : /src/Controller/AdminController.php -> admin_avisclients()
        $.ajax({
            type: 'POST',
            url: siteURL + 'admin/web/avisclients/modify',
            cache: false,
            contentType: false,
            processData: false,
            data: form,
            success: function(data) {
                // TOAST
                toastr.success('L\' Avis client a bien été enregistré', 'Succès');
                // On nettoye le html en supprimant l'onglet 'Modifier/Nouveau'
                $('#avisclients-tabs-modify-'+id_clicked).remove();
                $('#avisclients-close-modify-'+id_clicked).parent().remove();
                // Si nous sommes sur un nouvel avis client (id == 0)
                if (id_clicked == 0) {
                    $('#formavisclients_'+id_clicked)[0].reset();
                }
                // Retour à la liste des avis client en rechargeant la datatable
                $('#avisclients-tabs-list-tab').trigger('click');
                avisclients_table.ajax.reload();
            }
        });
        // Empèche le 'button' de fonctionner
        return false;
    });
});