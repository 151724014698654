// Génération de la datatable des prospects
pays_table = $('#pays_table').DataTable({
    "autoWidth": false,
    "language": {
        "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/fr-FR.json"
    },
    "paging": false,
    "responsive": true,
    "ajax": {
        "url": siteURL + 'json/pays',
        "type": "POST",
    },
    "order": [
        [0, "asc"]
    ],
    "columnDefs": [
        // TEXT CENTER
        {
            "className": "text-center align-middle",
            "targets": [4],
        },
        // ALIGN MIDDLE
        {
            "className": "align-middle",
            "targets": '_all',
        },
        // NON
        {
            "targets": 0,
            "render": function(data, type, row) {
                return row[1];
            },
        },
        // PREFIX TEL
        {
            "targets": 1,
            "render": function(data, type, row) {
                return row[2];
            },
        },
        // DEVISE
        {
            "targets": 2,
            "render": function(data, type, row) {
                return row[3];
            },
        },
        // CODE PAYS
        {
            "targets": 3,
            "render": function(data, type, row) {
                return row[4];
            },
        },
        // MODIFY
        {
            "width": "50px",
            "targets": 4,
            "render": function ( data, type, row ) {
                return '<button class="btn w-100 p-0" id="pays_opt-open-modify-'+row[0]+'" data-category="administration"><i class="fad fa-edit"></i>';
            },
            "orderable": false,
        },
    ],
    "conditionalPaging": true,
});


// Envoi de modification d'un pays
$('body').on('click', "button[id^='pays_opt-send-modify-']", function(e) {
    e.preventDefault();
    let id_clicked = this.id.replace('pays_opt-send-modify-', '');
    let pays_nom = $('#pays_nom_'+id_clicked).val();
    let pays_code_pays = $('#pays_code_pays_'+id_clicked).val();
    let pays_prefix_tel = $('#pays_prefix_tel_'+id_clicked).val();
    let pays_devise = $('#pays_devise_'+id_clicked).val();
    let error_message = "";
    if (pays_nom.length == 0) {
        error_message += "Le nom du pays n\'a pas été renseigné.<br/>"
    }
    if (pays_code_pays.length == 0) {
        error_message += "Le code du pays n\'a pas été renseigné.<br/>"
    }
    if (pays_prefix_tel.length == 0) {
        error_message += "Le préfixe téléphonique du pays n\'a pas été renseigné.<br/>"
    }
    if (error_message != "") {
        toastr.error(error_message, 'Erreur');
    } else {
        let form = new FormData();
        form.append('id', id_clicked);
        form.append('pays_nom', pays_nom);
        form.append('pays_code_pays', pays_code_pays);
        form.append('pays_prefix_tel', pays_prefix_tel);
        form.append('pays_devise', pays_devise);
        $.ajax({
            type: 'POST',
            url: siteURL + 'admin/administration/pays_opt/modify',
            contentType: false,
            processData: false,
            data: form,
            success: function() {
                toastr.success('La pays a bien été enregistrée', 'Succès');
                let eurId = $('#pays_eur_id');
                $('#pays_nom_'+id_clicked).val('');
                $('#pays_code_pays_'+id_clicked).val('');
                $('#pays_prefix_tel_'+id_clicked).val('');
                if (eurId.length) {
                    $('#pays_devise_'+id_clicked).prop('selectedIndex', eurId.val());
                } else {
                    $('#pays_devise_'+id_clicked).prop('selectedIndex', 0);
                }
                $('#pays_opt-tabs-modify-'+id_clicked).remove();
                $('#pays_opt-close-modify-'+id_clicked).parent().remove();
                $('#pays_opt-tabs-list-tab').trigger('click');
                pays_table.ajax.reload();
            },
        });
    }
});