
$(document).ready(function () {
    if ($("#campagne-page-form").length) {
        loadCampagneStats($("[id^='statut-campagne-']").toArray().map(function (el) { return el.id.replace("statut-campagne-", "") }));
        $('body').on('click', "#campagne-btn-save", function (e) {
            const options = getParams();
            let error_message = "";
            if (options.get("nom").length == 0) { error_message += "Le nom n\'a pas été renseigné.<br/>"; }
            if (options.get("annuaires").length == 0) { error_message += "Aucun destinataire n\'a pas été renseigné.<br/>"; }
            if (options.get("objet").length == 0) { error_message += "L'objet n\'a pas été renseigné.<br/>"; }
            if (options.get("entete").length == 0) { error_message += "L'entete n\'a pas été renseigné.<br/>"; }
            if (options.get("titre").length == 0) { error_message += "Le titre n\'a pas été renseigné.<br/>"; }
            if (options.get("contenu").length == 0) { error_message += "Le contenu n\'a pas été renseigné.<br/>"; }
            console.log(options.get("image"));
            if (options.get("vehicules").length == 0) { error_message += "Aucun véhicule n\'a pas été renseigné.<br/>"; }
            if (error_message.length) {
                toastr.error(error_message, 'Erreur');
            } else {
                $.ajax({
                    type: 'POST',
                    url: siteURL + 'admin/campagne/' + $("#campagne-page-form").data("id"),
                    contentType: false,
                    processData: false,
                    data: options,
                    success: function (data) {
                        $("#comptabilite-chiffreAffaire-results").html(data);
                        if ($("#campagne-page-form").data("id") == 0) {
                            window.location.href = window.location.href.replace("add", data);
                        }
                    }
                });
            }
        });

        function getParams() {
            let optionsRecherche = new FormData();
            optionsRecherche.append('nom', $("#campagne-nom").val());
            optionsRecherche.append('user', $("#campagne-user").val());
            optionsRecherche.append('centre', $("#campagne-centre").val());
            optionsRecherche.append('annuaires', selectedAnnuaireCache);
            optionsRecherche.append('entite', $("input[id^='campagne-entite-'][type='radio'][name='campagne-entite']:checked").val());
            optionsRecherche.append('objet', $("#campagne-objet").val());
            optionsRecherche.append('entete', $("#campagne-entete").val());
            optionsRecherche.append('titre', $("#campagne-titre").val());
            optionsRecherche.append('contenu', $("#campagne-contenu").val());
            optionsRecherche.append('image', $("#file_result_image").val());
            optionsRecherche.append('vehicules', selectedStockCache);
            return optionsRecherche;
        }

        $('body').on('click', "#campagne-btn-check", function (e) {
            Lobibox.window({
                title: "Prévisualisation de l'e-mail",
                baseClass: "modal-content modal-lobistrap",
                width: 823, // Pour une prévisualisation de 600px + largeur des paddings de la modal + largeur de la modal
                url: siteURL + "admin/campagne/post/check",
                autoload: true,
                loadMethod: "POST",
                params: { "id": $("#campagne-page-form").data("id") }
            });
        });

        let lobiEnvoi = null;
        $('body').on('click', "#campagne-btn-send", function (e) {
            lobiEnvoi = Lobibox.window({
                title: "Envoi de la campagne",
                baseClass: "modal-content modal-lobistrap",
                width: 1000,
                url: siteURL + "admin/campagne/post/send",
                autoload: true,
                loadMethod: "POST",
                params: { "id": $("#campagne-page-form").data("id") }
            });
        });

        $('body').on('click', "#campagne-cancel-btn", function (e) { lobiEnvoi.destroy(); });
        $('body').on('click', "#campagne-end-btn", function (e) { lobiEnvoi.destroy(); });

        $('body').on('click', "#campagne-envoi-btn", function (e) {
            $(".btn-close").remove();
            $("#campagne-cancel-btn").prop("disabled", true);
            $("#campagne-envoi-btn").prop("disabled", true);
            let destinataire = "";
            if($("#campagne-choix-mail-entreprise").prop('checked') && $("#campagne-choix-mail-employe").prop('checked')) {
                destinataire ="les-deux";
            } else if ($("#campagne-choix-mail-entreprise").prop('checked')) {
                destinataire ="entreprise";
            } else if ($("#campagne-choix-mail-employe").prop('checked')) {
                destinataire ="employe";
            }
            let form = new FormData();
            form.append("id", $("#campagne-page-form").data("id"));
            form.append("destinataire", destinataire);
            $.ajax({
                type: 'POST',
                url: siteURL + "admin/campagne/post/send/mail",
                contentType: false,
                processData: false,
                data: form,
                success: function () {
                    $("#campagne-cancel-btn").addClass("d-none");
                    $("#campagne-envoi-btn").addClass("d-none");
                    $("#campagne-end-btn").removeClass("d-none");
                    $("#campagne-btn-save").remove();
                    $("#campagne-btn-send").remove();
                },
                error: function () {
                    $("#campagne-cancel-btn").prop("disabled", false);
                    $("#campagne-envoi-btn").prop("disabled", false);
                },
            });
        });

        $('body').on('click', "[id^='campagne-choix-mail-']", function (e) {
            let nombreDestinaire = 0;
            // Check qui est check, et prendre la longueur des trucs
            $("[id^='campagne-choix-mail-']").each(function() {
                if ($(this).prop('checked')) {
                    let typeDestinataire = this.id.replace('campagne-choix-mail-', '');
                    nombreDestinaire = nombreDestinaire + $("[data-typedestinataire='" + typeDestinataire + "']").length;
                }
            });
            $("#campagne-nombre-contact-impacte").html(nombreDestinaire);
        });

        $('body').on('change', "#campagne-user", function (e) {
            let selectedOption = $("#campagne-user").find(":selected");
            $("#campagne-user-image").attr("src", selectedOption.data("url"));
            $("#campagne-user-fonction").html(selectedOption.data("fonction"));
            $("#campagne-user-tel").html(selectedOption.data("tel"));
            $("#campagne-user-email").html(selectedOption.data("email"));
        });

        $('body').on('change', "#campagne-centre", function (e) {
            let selectedOption = $("#campagne-centre").find(":selected");
            $(".campagne-centre-image").toArray().forEach(image => {
                if ($(image).data("name") == selectedOption.data("name")) {
                    $(image).removeClass("d-none");
                } else {
                    $(image).addClass("d-none");
                }
            });
            $("#campagne-centre-tel").html(selectedOption.data("tel"));
            $("#campagne-centre-adresse").html(selectedOption.data("adresse"));
            $("#campagne-centre-commune").html(selectedOption.data("codepostal") + ", " + selectedOption.data("ville"));
        });
    }
});