// let vehicule_fournisseur_table = null;
// let vehicule_fournisseur_table_page = 0;

$(document).ready(function () {
    if ($("[id^='formvehicule_fournisseur_']").length) {
        // Initialisation de la page
        let idVF = $("[id^='formvehicule_fournisseur_']")[0].id.replace('formvehicule_fournisseur_', '') != 0 ? $("[id^='formvehicule_fournisseur_']")[0].id.replace('formvehicule_fournisseur_', '') : null;
        vfSetup('vehicule_fournisseur', idVF);

        // Cas d'un VF acheté, on met tout en disabled, et on
        if ($('#formvehicule_fournisseur_' + idVF).data('vfachete') == 1) {
            $.each($('[id^=vehicule_fournisseur_input_]'), function() {
                $(this).prop("disabled", true);
            });
            $.each($('.selectpicker'), function() {
                $(this).prop("disabled", true);
                $(this).selectpicker('refresh');
            });
            $.each($('.custom-file-input'), function() {
                $(this).prop("disabled", true);
            });
            $("#vehicule_fournisseur-delete-" + idVF).addClass('d-none');
            $("#vehicule_fournisseur-send-modify-" + idVF).addClass('d-none');
        }

        // Si on change la photo 3/4 Avant, on remplace dans l'aperçu
        $('body').on('hidden.bs.modal', "#vehicule_fournisseur-photo", function () {
            let idVF = $("[id^='formvehicule_fournisseur_']")[0].id.replace("formvehicule_fournisseur_", "");
            let newURLPhoto = $("#file_display_imagePhotoAvant_" + idVF + " img").attr('src');
            $('#vehicule_fournisseur-photo-34-avant-apercu').attr('src', newURLPhoto);
        });

        // Changement de z-index pour la modal de data compilation pour pouvoir afficher les lobibox de détails des véhicules
        $('body').on('shown.bs.modal', "#vehicule_fournisseur-data-compilation", function (e) {
            $('#vehicule_fournisseur-data-compilation').css('z-index', 3001);
            $('.modal-backdrop').css('z-index', 3000);
        });
    }
});
