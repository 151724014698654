let propositions_commerciales_table = null;
let timerBeforeRefreshDataPropositionsCommerciales = null;

$(document).ready(function() {
    if ($("#propositions_commerciales_results").length) {
        colvisFunOnEvent();
        function loadAjax() {
            let optionsRecherche = new FormData();
            optionsRecherche.append('date_proposition_commerciale_debut', (new Date($("#filterspropositions_commerciales-datepropositionscommerciales1").val())).toLocaleDateString("fr"));
            optionsRecherche.append('date_proposition_commerciale_fin', (new Date($("#filterspropositions_commerciales-datepropositionscommerciales2").val())).toLocaleDateString("fr"));
            optionsRecherche.append('user', $("#filterspropositions_commerciales-user").val());
            optionsRecherche.append('isPro', $("#filterspropositions_commerciales-isPro").val());
            $("#propositions_commerciales_results").addClass("d-none");
            $.ajax({
                type:'POST',
                url: siteURL + 'admin/propositions-commerciales/propositions_commerciales_ajax',
                contentType: false,
                processData: false,
                data: optionsRecherche,
                success: function(data) {
                    $("#propositions_commerciales_results").html(data);
                    propositions_commerciales_table = $('#propositions_commerciales_table').DataTable({
                        "dom": dataTablesCustomDom(),
                        "buttons": [dataTablesColvisBtn('th:nth-child(n+4)')],
                        "autoWidth": false,
                        "language": { "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/fr-FR.json" },
                        "paging": true,
                        "pageLength": 25,
                        "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, "All"]],
                        "responsive": true,
                        "order": [[0, "desc"]],
                        "conditionalPaging": true,
                        "columnDefs": [
                            { type: 'num', targets: [0, 5, 7, 8, 9] },
                            { "className": "text-center align-middle", "targets": '_all' }, // ALIGN MIDDLE
                        ],
                        "columns": getColvisColumn("propositions_commerciales_table")
                    });
                    $('#propositions_commerciales_table').on('init.dt', function () { initMirrorBtn(); setColvisTable(propositions_commerciales_table); colvisFunSelectRefresh(); });
                    $('#propositions_commerciales_table').on('draw.dt', function () {
                        $("#propositions_commerciales_results").removeClass("d-none");
                        getStatusMailAsynchrone($('[id^="proposition-commerciale-crm-statut-mail-"]'));
                    });
                    $('#propositions_commerciales_table').on('page.dt', function () { getStatusMailAsynchrone($('[id^="statut-mail-"]')); });
                    $('#propositions_commerciales_table').on('length.dt', function () { getStatusMailAsynchrone($('[id^="statut-mail-"]')); });
                }
            })
        };

        setupLoadAjax(loadAjax);
        setupResetFilter(resetFilter);

        $('body').on('change', "[id^='filterspropositions_commerciales-']", function() {
            clearTimeout(timerBeforeRefreshDataPropositionsCommerciales); timerBeforeRefreshDataPropositionsCommerciales = setTimeout(loadAjax, 500);
        });

        // Bouton reset
        $('body').on('click', '#filterspropositions_commerciales-reset', function() {
            resetFilter();
            loadAjax();
        });

        // Pour créer une nouvelle proposition
        $('body').on('click', "#nouvelle-proposition", function (e) {
            window.open($(this).data("src"), "_blank");
        });

        // Pour dupliquer une proposition
        $('body').on('click', "[id^='duplication_proposition_mail-']", function (e) {
            window.open($(this).data("src"), "_blank");
        });

        function initDatePropositionCommerciale() {
            $("#filterspropositions_commerciales-datepropositionscommerciales1").val($("#firstpropositionscommerciales").val());
            $("#filterspropositions_commerciales-datepropositionscommerciales2").val($("#lastpropositionscommerciales").val());
        }

        function resetFilter() {
            initDatePropositionCommerciale();
            $("#filterspropositions_commerciales-user").val('default').selectpicker("refresh");
            $("#filterspropositions_commerciales-isPro").val('').selectpicker("refresh");
        }

        filtreConfigFunOnEvent();
        initDatePropositionCommerciale();
    }
});