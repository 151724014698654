$(document).ready(function() {

    // Génération de la datatable des generateur reseaux sociaux
    generateurqrcode_table = $('#generateurqrcode_table').DataTable({
        "autoWidth": false,
        "language": {
            "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/fr-FR.json"
        },
        "responsive": true,
        "ajax": {
            "url": siteURL + 'json/vehicules_all',
            "type": "POST",
        },
        "order": [
            [1, "desc"]
        ],
        "columnDefs": [
            // TEXT CENTER
            {
                "className": "text-center align-middle",
                "targets": [4,5,6,7,8,9,10],
            },
            // ALIGN MIDDLE
            {
                "className": "align-middle",
                "targets": '_all',
            },
            // PHOTO
            {
                "name": "photo",
                "targets": 0,
                "render": function(data, type, row) {
                    return "<div class='hover'><img src='"+siteURL+row[19]+"' class='img-fluid' style='max-width:95px;'><div class='thumb-hover'><img src='"+siteURL+row[19]+"' style='position: absolute;width: 33%;margin-top:-226px;margin-left: 50px;border: 2px solid #dee2e6; z-index:9999'></div></div>";
                },
                "orderable": false,
            },
            // ID
            {
                "name": "id",
                "targets": 1,
                "render": function(data, type, row) {
                    return '<a href="'+siteURL+"admin/stock/"+row[0]+'" target="_blank">'+row[0]+'</a> ';
                },
            },
            // VIN
            {
                "name": "vin",
                "targets": 2,
                "render": function(data, type, row) {
                    return row[20];
                },
            },
            // MARQUE
            {
                "name": "marque",
                "targets": 3,
                "render": function(data, type, row) {
                    return row[3];
                },
            },
            // MODELE
            {
                "name": "modele",
                "targets": 4,
                "render": function(data, type, row) {
                    return row[4];
                },
            },
            // FINITION
            {
                "targets": 5,
                "render": function(data, type, row) {
                    return row[5];
                },
            },
            // ETAT
            {
                "targets": 6,
                "render": function(data, type, row) {
                    var kilometrage = row[7];
                    if (kilometrage <= 100) {
                        return 'Neuf';
                    } else {
                        return 'Occasion';
                    }
                    // return row[7];
                },
            },
            // KILOMETRAGE
            {
                "targets": 7,
                "render": function(data, type, row) {
                    return row[7] + " km";
                },
            },
            // EMPLACEMENT
            {
                "targets": 8,
                "render": function(data, type, row) {
                    if (row[11] == null) {
                        return "En arrivage";
                    } else {
                        return row[11];
                    }

                },
            },
            // DISPONIBLE
            {
                "targets": 9,
                "render": function(data, type, row) {
                    if (row[12] == null) {
                        return '<i class="fas fa-check" style="color:green" title="Disponible"></i><span class="d-inline d-lg-none pl-2">OUI</span>';
                    } else {
                        return '<i class="fas fa-times" style="color:red" title="Non Disponible"></i><span class="d-inline d-lg-none pl-2">NON</span>';
                    }
                },
            },
            // PDF
            {
                "width": "25px",
                "targets": 10,
                "render": function(data, type, row) {
                    return '<a download href="'+siteURL+'fiche-pdf/'+row[0]+'/"><i class="fas fa-download cursor-pointer" title="PDF"></i></a>';
                },
                "orderable": false,
            },
            // QR CODE
            {
                "width": "25px",
                "targets": 11,
                "render": function(data, type, row) {
                    return '<a download href="'+siteURL+'admin/outils/qrcodes/pdf/'+row[0]+'"><i class="fas fa-download cursor-pointer" title="QR code"></i></a>';

                },
                "orderable": false,
            },
            // FACEBOOK
            {
                "width": "25px",
                "targets": 12,
                "render": function(data, type, row) {
                    return '<input type="checkbox" class="cursor-pointer" value="" id="qrcodevehicule_'+row[0]+'"'+data+'>';
                },
                "orderable": false,
            },
        ],
        "conditionalPaging": true,
    });


    //Envoi de modification generateurqrcode
    $('body').on('click', "input[id^='qrcodevehicule_']", function() {
        var clicked_id = this.id.split(/[_ ]+/).pop();
        if (typeof clickeds_ids == 'undefined') { clickeds_ids = Array(); }
        if ($(this).prop("checked") == true) { clickeds_ids.push(clicked_id); $('#generateurqrcodes').removeAttr('disabled'); $('#generateurqrcodes').addClass('bg-warning');}
        else { clickeds_ids.splice(clickeds_ids.indexOf(clicked_id), 1);  }
        if (clickeds_ids.length == 0) { $('#generateurqrcodes').prop('disabled', true); $('#generateurqrcodes').removeClass('bg-warning');}
    });

    //Envoi de modification generateurqrcode
    $('body').on('click', "#generateurqrcodes", function() {
        if(clickeds_ids.length > 0) {
            let request = clickeds_ids.join();

            $(this).after('<a class="d-none" id="qrcodemultiple" href="'+siteURL+'admin/outils/qrcodes/pdf/'+request+'" download>a</a>');
            document.getElementById('qrcodemultiple').click();
            $('#qrcodemultiple').remove();
        }
    });

    // Recharge la table
    $('[id^="generateurqrcodetable-"').on( 'change keyup', function () {
        column = this.id.replace('generateurqrcodetable-', '');
        // Au changement de la marque afficher les modeles correspondants
        if (column == 2) {
            var marque = this.value;
            var form = new FormData();
            form.append('marque', marque);

            $.ajax({
                type: 'POST',
                url: siteURL + "json/modeles_marque",
                data: form,
                processData: false,
                contentType: false,
                success: function(data, status) {

                    $("#generateurqrcodetable-3").empty();
                    $("#generateurqrcodetable-3").append('<option value=>Tous les modèles</option>');
                    for (id in data) {
                        if (data[id]['nb'] > 0) {
                            $("#generateurqrcodetable-3").append("<option value='" + id + "'>" + id + "</option>");
                        }
                    };
                    $("#generateurqrcodetable-3").val('default').selectpicker("refresh");
                    generateurqrcode_table.column(3).search( '' ).draw()
                }
            });
            if ($(this).val() != "") { generateurqrcode_table.column(2).search( '^'+$(this).val()+'$', true, false).draw(); }
            else { generateurqrcode_table.column(2).search( '' ).draw() }
        }
        else if (column == 1) {
            generateurqrcode_table.column(1).search( $(this).val() ).draw();
        }
        else {
            if ($(this).val() != "") {
                generateurqrcode_table.column( column ).search(
                    '^'+$(this).val()+'$',
                    true,
                    false,
                ).draw();
            }
            else {
                generateurqrcode_table.column(column).search( '' ).draw()
            }
        }
    } );

    // RESET
    $('#generateurqrcodetable-reset').on('click', function() {
        $('#generateurqrcode-tablesearch').trigger("reset");
        $("#generateurqrcodetable-1").val('default').selectpicker("refresh");
        $("#generateurqrcodetable-2").val('default').selectpicker("refresh");
        $("#generateurqrcodetable-3").val('default').selectpicker("refresh");
        $("#generateurqrcodetable-5").val('default').selectpicker("refresh");
        generateurqrcode_table.columns().search( '' ).draw();
        $("input[id^='qrcodevehicule_']").each( function () {
            if ($(this).prop("checked") == true) { $(this).prop("checked", false);}
        });
        clickeds_ids = Array();
        $('#generateurqrcodes').prop('disabled', true); $('#generateurqrcodes').removeClass('bg-warning');
        return false;
    })
})


/*function downloadFile(fileName, type) {
  const a = document.createElement("a");
  a.style.display = "none";
  document.body.appendChild(a);

  a.href = window.URL.createObjectURL(
    new Blob([data], { type })
  );

  a.setAttribute("download", fileName);

  a.click();

  window.URL.revokeObjectURL(a.href);
  document.body.removeChild(a);
}*/