$(document).ready(function () {
    if ($("#facture-import").length) {
        $('body').on('change', "#facture-import-import", function (e) {
            if (this.files.length) {
                const data = this.files[0].arrayBuffer();
                data.then((data) => {
                    const workbook = XLSX.read(data);
                    Object.values(workbook.Sheets).forEach(sheet => {
                        const categorie = $("#facture-import-categorie")[0].selectedOptions[0].innerHTML;
                        const arr = sheet2arr(sheet);
                        const listVehiculeId = $("#facture-import-table").data("listvehiculeid");
                        const listNomEntrepriseZeendoc = $("#facture-import-table").data("listnomentreprisezeendoc");
                        arr.forEach((line, index) => {
                            if (index > 3 && index < (arr.length - 3)) {
                                let hasError = false;
                                let error = "&#9888;&#65039;";
                                let newLine = [];
                                for (let i = 0; i < 10; i++) { newLine[i] = ""; }
                                if (line[0] != undefined && line[0].toString().startsWith("0728-") && line[0].toString().length == 12 && listVehiculeId.includes(parseInt(line[0].replace("0728-", "")))) { newLine[0] = line[0] } else { newLine[0] = error; hasError = true; }
                                if (line[1] != undefined && line[1].toString().length == 17) { newLine[1] = line[1] } else { newLine[1] = error; hasError = true; }
                                if (line[3] != undefined && listNomEntrepriseZeendoc.includes(line[3])) { newLine[2] = line[3] } else { newLine[2] = error; hasError = true; }
                                if (line[4] != undefined) { newLine[3] = line[4] } else { newLine[3] = error; hasError = true; }
                                if (line[5] != undefined) { newLine[4] = line[5].split("/").map((rank, i, row) => { if (i + 1 === row.length) { return '20' + rank; } else { return (rank.length > 1 ? rank : '0' + rank); } }).join("/").replace(/(\d+)\/(\d+)\/(\d+)/, "$2/$1/$3") } else { newLine[4] = error; hasError = true; }
                                if (line[6] != undefined && !isNaN(line[6].replace(",", ""))) { newLine[5] = line[6].replace(",", "") } else { newLine[5] = error; hasError = true; }
                                if (line[7] != undefined && !isNaN(line[6].replace(",", ""))) { newLine[6] = line[7].replace(",", "") } else { newLine[6] = error; hasError = true; }
                                if (line[8] != undefined && !isNaN(line[6].replace(",", ""))) { newLine[7] = line[8].replace(",", "") } else { newLine[7] = error; hasError = true; }
                                newLine[8] = '<span class="badge badge-' + (categorie == "Mécanique" ? 'warning' : (categorie == "Carrosserie" ? 'success' : 'info')) + ' text-uppercase text-10 fw-normal">' + categorie + '</span>';
                                newLine[9] = '<i facture-import-trash class="fas fa-trash-alt ' + (hasError ? 'text-white' : 'text-danger') + '"></i>';
                                const rowNode = factureTable.row.add(newLine).draw().node();
                                if (hasError) {
                                    $(rowNode).addClass("bg-danger");
                                    $(rowNode).find("td").addClass("bg-danger");
                                }
                            }
                        });
                    });
                    errorMsgDisplayUpdate();
                });
            }
        });

        const factureTable = $('#facture-import-table').DataTable({
            "autoWidth": false,
            "language": { "url": "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/French.json" },
            "paging": true,
            "ordering": false,
            "responsive": true,
            "conditionalPaging": true,
            "columnDefs": [
                { "className": "text-center align-middle", "targets": '_all' },
                { "targets": [9], "orderable": false }
            ]
        });

        function errorMsgDisplayUpdate() {
            const nbIni = factureTable.rows().nodes().length;
            const nbErr = factureTable.rows().nodes().filter(tr => tr.classList.contains("bg-danger")).length;
            $("#facture-import-error-msg-nbErr").html(nbErr + " erreur" + (nbErr > 1 ? "s" : ""));
            $("#facture-import-error-msg-nbIni").html(nbIni + " ligne" + (nbIni > 1 ? "s" : ""));
            $("#facture-import-error-msg-0").addClass("d-none");
            $("#facture-import-error-msg-10").addClass("d-none");
            if (nbErr > 10) {
                $("#facture-import-error-msg-10").removeClass("d-none");
            } else {
                $("#facture-import-error-msg-0").removeClass("d-none");
            }
            if (nbErr) {
                $("#facture-import-error-msg").removeClass("d-none");
            } else {
                $("#facture-import-error-msg").addClass("d-none");
            }
            $("#facture-import-save").prop("disabled", (nbErr ? true : false));
        }

        $('body').on('click', "[facture-import-trash]", function (e) {
            const actualPage = factureTable.page();
            factureTable.row($(this).parents('tr')).remove().draw();
            factureTable.page(actualPage).draw(false);
            errorMsgDisplayUpdate();
        });

        $('body').on('click', "#facture-import-save", function (e) {
            const btn = $("#facture-import-save");
            const btnTxt = btn.html();
            const spinner = '<span class="spinner-border spinner-border-sm"></span>';
            btn.html(spinner);
            errorMsgDisplayUpdate();
            if ($("#facture-import-error-msg").hasClass("d-none")) {
                let options = new FormData();
                options.append('data', JSON.stringify(factureTable.rows().data().toArray().map(tr => {
                    tr[0] = parseInt(tr[0].replace("0728-", ""));
                    tr[4] = tr[4].replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$2-$1");
                    tr[8] = tr[8].replace(regexAntiHTML, "");
                    return tr.slice(0, tr.length - 1);
                })));
                $.ajax({
                    type: 'POST',
                    url: siteURL + 'admin/fre/facture-import/save',
                    contentType: false,
                    processData: false,
                    data: options,
                    success: function (data) {
                        if (data.success) { window.location.href = window.location.href.replace("facture-import", "facture"); }
                        else { toastr.error('Un erreur s\'est produite l\'ors de l\'import.', 'Erreur'); btn.html(btnTxt); }
                    },
                    error: function () {
                        toastr.error('Un erreur s\'est produite l\'ors de l\'import.', 'Erreur');
                    }
                });
            }
        });
    }
});
