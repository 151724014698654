/**
 * @function channelGetDialog
 * @param {number} step
 * @param {string} name
 * @summary This function is used to get the channel dialog to step
 */
function channelGetDialog(step, name) { return document.querySelector("[channel-dialog][data-step='" + step + "'][data-name='" + name + "']"); }

/**
 * @function channelLoader
 * @param {number} step
 * @param {string} name
 * @param {string} value
 * @summary This function is used to open the channel dialog to step
 */
function channelLoader(step, name, value) {
    const dialog = channelGetDialog(step, name);
    dialog.setAttribute("channel-dialog-loader", value);
}

/**
 * @function channelOpen
 * @param {number} step
 * @param {string} name
 * @summary This function is used to open the channel dialog to step
 */
function channelOpen(step, name) {
    const dialog = channelGetDialog(step, name);
    if (dialog !== null) { dialogInOut(dialog, "open").click(); }
}

/**
 * @function channelClose
 * @param {number} step
 * @param {string} name
 * @summary This function is used to open the channel dialog to step
 */
function channelClose(step, name) {
    const dialog = channelGetDialog(step, name);
    if (dialog !== null) { dialogInOut(dialog, "close").click(); }
}

/**
 * @function channelPrev
 * @param {number} step
 * @summary This function is used to go to the prev step of the channel
 */
function channelPrev(step, name) {
    channelClose(step, name);
    channelOpen(step - 1, name);
}

/**
 * @function channelNext
 * @param {number} step
 * @summary This function is used to go to the next step of the channel
 */
function channelNext(step, name) {
    channelClose(step, name);
    channelOpen(step + 1, name);
}
