document.addEventListener("DOMContentLoaded", (event) => {
    function initKpiGraph(canvas) {
        try {
            const chart = getKpiGraphData(canvas);
            // Setup DATA / LABEL
            let dataMaxLength = 0;
            const datasets = [];
            const labels = [];
            for (let i = 0; i < chart.datas.length; i++) {
                if (chart.datas[i].length > dataMaxLength) dataMaxLength = chart.datas[i].length;
                const byDefault = "rgba(0, 0, 0, 0.1)";
                const data = [];
                chart.datas[i].forEach((v, k) => {
                    data.push(v["value"] ?? v);
                    labels[k] = v["label"] ?? v;
                });
                datasets.push({
                    data: data,
                    backgroundColor: (i == chart.datas.length - 1 ? (chart.type != "doughnut" ? Array(data.length).fill(...chart.fillColor) : chart.fillColor) ?? byDefault : byDefault),
                    borderColor: (i == chart.datas.length - 1 ? (chart.type != "doughnut" ? Array(data.length).fill(...chart.borderColor) : chart.borderColor) ?? byDefault : byDefault),
                    borderWidth: (chart.type != "doughnut" ? 2 : 0),
                    pointRadius: 0,
                    pointHoverRadius: 3,
                    pointHitRadius: 10,
                    lineTension: 0,
                });
            }
            // Setup OPTIONS
            const options = { responsive: true, maintainAspectRatio: false, legend: { display: chart.type == "doughnut" } };
            if (chart.isTimed) {
                options.scales = {
                    xAxes: [{ type: 'time', gridLines: { display: false }, offset: chart.type == "bar",
                        time: { unit: 'month', tooltipFormat: 'DD/MM/YYYY', displayFormats: { month: 'MMM YYYY' } },
                        ticks: { min: moment().subtract(dataMaxLength, chart.timeScale + 's').startOf(chart.timeScale), max: moment().startOf(chart.timeScale) }
                    }],
                    yAxes: [{ gridLines: { display: false }, ticks: { beginAtZero: true } }]
                };
            } else { options.scales = { xAxes: [{ gridLines: { display: false }, ticks: { display: false } }], yAxes: [{ gridLines: { display: false }, ticks: { display: false } }] }; }
            // Setup CHART
            let updated = false; // Pour régler un bug graphique
            const chartjs = new Chart(chart.ctx, {
                type: chart.type,
                data: { datasets: datasets, labels: labels },
                options: options,
                plugins: [{ afterRender: c => updated || (updated = true, c.update()) }] // Pour régler un bug graphique
            });
            return chartjs;
        } catch (error) { console.log("initKpiGraph -> error :", error); }
    }

    function getKpiGraphData(canvas) {
        // Setup CONFIG
        const ctx = canvas.getContext('2d');
        const type = canvas.getAttribute("data-type");
        const timeScale = canvas.getAttribute("data-timeScale");
        const isTimed = timeScale.length > 0;
        const fillColor = canvas.getAttribute("data-fillColor").split(",");
        const borderColor = canvas.getAttribute("data-borderColor").split(",");
        const datas = JSON.parse(canvas.getAttribute("data-datas"));
        // Setup TIMED DATA
        if (isTimed) {
            datas.forEach((v, k) => {
                v.forEach((w, i) => {
                    datas[k][i] = { t: moment().subtract(v.length - i, timeScale + 's').startOf(timeScale), y: w };
                });
            });
        }
        return { ctx: ctx, type: type, timeScale: timeScale, isTimed: isTimed, fillColor: fillColor, borderColor: borderColor, datas: datas };
    }

    document.querySelectorAll("canvas[id^='kpi-']").forEach((canvas) => {
        //? créer une date avec MomentJS
        //TODO console.log(moment([2012, 0, 31]).format("YYYY-MM-DD"));
        initKpiGraph(canvas);
    });
});