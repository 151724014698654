/**
 * Initialise un champ de signature
 * @param {HTMLElement} sign - Le champ de signature HTML
 */
function initSign(sign) {
  const oCanvas = sign.querySelector("[sign-canvas]"), oClear = sign.querySelector("[sign-clear]"); // Récupère le canvas et le bouton de reset
  oCanvas.bDraw = false; // Initialise le booleen de dessin en cours à faux
  oCanvas.addEventListener("mousedown", signDownDrawligne); // Écoute l'evenement de debut de clic
  oCanvas.addEventListener("mouseup", signUpDrawligne); // Écoute l'evenement de fin de clic
  oCanvas.addEventListener("mousemove", signMoveDrawligne); // Écoute l'evenement de mouvement de souris
  oCanvas.addEventListener("touchstart", signDownDrawligne); // Écoute l'evenement de debut de touch (pour mobile)
  oCanvas.addEventListener("touchend", signUpDrawligne); // Écoute l'evenement de fin de touch (pour mobile)
  oCanvas.addEventListener("touchmove", signMoveDrawligne); // Écoute l'evenement de mouvement de touch (pour mobile)
  oClear.addEventListener("click", signToolsClear); // Écoute l'evenement de click sur le bouton de reset
}

/**
 * Dessine une ligne sur le canvas en fonction de la précedente position de l'utilisateur jusqu'à la position actuelle
 * @param {Event} oEvent - L'evenement envoyé par le navigateur
 * @see https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/mousemove_event
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Touch_events/Using_Touch_Events
 */
function signMoveDrawligne(oEvent) {
  if (oEvent.cancelable) { oEvent.preventDefault(); }
  const oCanvas = oEvent.currentTarget, oCtx = oCanvas.getContext('2d'), oPos = signGetPosition(oEvent, oCanvas);
  if (oCanvas.bDraw) { // Si l'utilisateur est en train de dessiner
    // Écrit la ligne
    oCtx.strokeStyle = '#000000';
    oCtx.lineWidth = 3;
    oCtx.beginPath();
    oCtx.moveTo(oCanvas.posX, oCanvas.posY);
    oCtx.lineTo(oPos.posX, oPos.posY);
    oCtx.stroke();

    // Met à jour la position de l'utilisateur sur le canvas en fonction de la position de l'utilisateur
    oCanvas.posX = oPos.posX;
    oCanvas.posY = oPos.posY;
  }
}

/**
 * Démarre l'écriture sur le canvas
 * @param {Event} oEvent - L'evenement envoyé par le navigateur
 * @see https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/mousedown_event
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Touch_events/Using_Touch_Events
 */
function signDownDrawligne(oEvent) {
  if (oEvent.cancelable) { oEvent.preventDefault(); }
  const oCanvas = oEvent.currentTarget, oPos = signGetPosition(oEvent, oCanvas);
  oCanvas.posX = oPos.posX;
  oCanvas.posY = oPos.posY;
  oCanvas.bDraw = true;
  signToolsCheck(oCanvas);
}

/**
 * Arrête l'écriture sur le canvas
 * @param {Event} oEvent - L'evenement envoyé par le navigateur
 * @see https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/mouseup_event
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Touch_events/Using_Touch_Events
 */
function signUpDrawligne(oEvent) {
  if (oEvent.cancelable) { oEvent.preventDefault(); }
  const oCanvas = oEvent.currentTarget;
  oCanvas.bDraw = false;
  signToolsCheck(oCanvas, true);
}

/**
 * Renvoie la position de l'utilisateur sur le canvas en fonction de l'evenement envoyé
 * @param {Event} oEvent - L'evenement envoyé par le navigateur
 * @param {HTMLElement} oCanvas - Le canvas 
 * @returns {Object} Un objet contenant les coordonnées du point dans le canvas
 * @prop {number} posX - La coordonnée X du point dans le canvas
 * @prop {number} posY - La coordonnée Y du point dans le canvas
 */
function signGetPosition(oEvent, oCanvas) {
  const oRect = oCanvas.getBoundingClientRect(), oEventEle = oEvent.changedTouches ? oEvent.changedTouches[0] : oEvent;
  return {
    posX: (oEventEle.clientX - oRect.left) / (oRect.right - oRect.left) * oCanvas.width,
    posY: (oEventEle.clientY - oRect.top) / (oRect.bottom - oRect.top) * oCanvas.height
  };
}

/**
 * Met à jour la capture du canvas sous forme d'image
 * @param {HTMLElement} oCanvas - Le canvas
 * @param {boolean} [bAction=false] - Si true, enregistre la capture de l'image
 */
function signToolsCheck(oCanvas, bAction) {
  const oCapture = oCanvas.parentElement.querySelector("[sign-capture]"); // Récupère l'élément où sera affiché la capture
  oCapture.innerHTML = ''; // Vide la capture
  if (bAction == true) { // Si on veut enregistrer la capture
    const oImage = document.createElement('img'); // Création de l'image
    oImage.src = oCanvas.toDataURL("image/png"); // Création de la source de l'image
    oCapture.appendChild(oImage); // Ajout de l'image dans la capture
  }
}

/**
 * Efface le contenu du canvas et met à jour la capture du canvas sous forme d'image
 * @param {Event} oEvent - L'evenement envoyé par le navigateur
 * @see https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/click_event
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Touch_events/Using_Touch_Events
 */
function signToolsClear(oEvent) {
  if (oEvent.cancelable) { oEvent.preventDefault(); }
  const oCanvas = oEvent.currentTarget.parentElement.querySelector("[sign-canvas]"), oCtx = oCanvas.getContext('2d');
  oCtx.clearRect(0, 0, oCanvas.width, oCanvas.height);
  signToolsCheck(oCanvas);
}
