$(document).ready( function () {

    // Génération de la datatable des postes
    poste_table = $('#postes_table').DataTable( {
        "autoWidth": false,
    	"language": {
            "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/fr-FR.json"
        },
        "responsive": true,
    	"ajax": siteURL+'json/postes',
    	"order": [[ 1, "asc" ], [0, "asc"]],
    	"columnDefs": [
            { responsivePriority: 1, targets: 0 },
            { responsivePriority: 2, targets: 1 },
            // TEXT CENTER
            {
                "className": "text-center align-middle",
                "targets": [1],
            },
            // TEXT STAR
            {
                "className": "text-start",
                "targets": [0],
            },
            // NAME
            {
                "targets": 0,
                "render": function ( data, type, row ) {
                    return row[1];
                },
            },
            // ORDRE
            {
                "width": "70px",
                "targets": 1,
                "render": function ( data, type, row ) {
                    return row[2];
                },
            },
            // MODIFY
    		{
                "width": "50px",
                "targets": 2,
    			"render": function ( data, type, row ) {
                    return '<button class="btn w-100 p-0" id="poste-open-modify-'+row[0]+'" data-category="administratif"><i class="fad fa-edit"></i>';
                },
                "orderable": false,
    		},
    	],
        "conditionalPaging": true,
    });

    // Envoi de modification d'un poste
    $('body').on('click', "button[id^='poste-send-modify-']", function() {
        var clickedId = this.id.split(/[- ]+/).pop();;

        var form = new FormData();
        // On extrait le contenu du formulaire dans la variable 'form'
        form.append('id', clickedId);
        form.append('poste_name', $('#poste_name_'+clickedId).val());
        form.append('poste_ordre', $('select[name="poste_ordre_'+clickedId+'"]').children("option:selected").val());

        // Requète ajax d'envoi du formulaire : /src/Controller/AdminController.php -> admin_postes()
        $.ajax({
            type: 'POST',
            url: siteURL+'admin/administratif/poste/modify',
            cache: false,
            contentType: false,
            processData: false,
            data: form,
            success: function(data) {
                // Si il s'agit de l'ajout d'un nouveau poste
                if (clickedId == 0) {
                    toastr.success('Le nouveau poste a bien été enregistré', 'Succès');
                    $('#formposte_0')[0].reset();
                }
                // Si il s'agit d'une modification d'postealité
                else {
                    toastr.success('Le poste a bien été modifié', 'Succès');
                    // On nettoye le html en supprimant l'onglet 'Modifier/Nouveau'
                    $('#poste-tabs-modify-'+clickedId).remove();
                    $('#poste-close-modify-'+clickedId).parent().remove();
                }
                // Retour à la liste d'postealités en rechargeant la datatable
                poste_table.ajax.reload();
                $('#poste-tabs-list-tab').trigger('click');
            }
        });
        return false;
    });
});