// Génération de la datatable des prospects
etat_table = $('#etat_table').DataTable({
    "autoWidth": false,
    "language": {
        "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/fr-FR.json"
    },
    "responsive": true,
    "ajax": {
        "url": siteURL + 'json/etats',
        "type": "POST",
    },
    "order": [
        [0, "desc"]
    ],
    "columnDefs": [
        // TEXT CENTER
        {
            "className": "text-center align-middle",
            "targets": [3,4],
        },
        // ALIGN MIDDLE
        {
            "className": "align-middle",
            "targets": '_all',
        },
        // ID
        {
            "targets": 0,
            "render": function(data, type, row) {
                return row[0];
            },
        },
        // NAME
        {
            "targets": 1,
            "render": function(data, type, row) {
                return row[1];
            },
        },
        // NAME REWRITE
        {
            "targets": 2,
            "render": function(data, type, row) {
                return row[2];
            },
        },
        // VISIBLE
        {
            "targets": 3,
            "render": function(data, type, row) {
                if (row[3] == 1) {
                    return '<i class="fas fa-check" style="color:green" title="Validé"></i><span class="d-inline d-lg-none pl-2">OUI</span>';
                } else {
                    return '<i class="fas fa-times" style="color:red" title="Non Validé"></i><span class="d-inline d-lg-none pl-2">NON</span>';
                }
            },
        },
        // MODIFY
        {
            "width": "50px",
            "targets": 4,
            "render": function ( data, type, row ) {
                return '<button class="btn w-100 p-0" id="etat-open-modify-'+row[0]+'" data-category="vehicules"><i class="fad fa-edit"></i>';
            },
            "orderable": false,
        },
    ],
    "conditionalPaging": true,
});


// Envoi de modification d'une etat
$('body').on('click', "button[id^='etat-send-modify-']", function() {
    var id_clicked = this.id.replace('etat-send-modify-', '');

    var form = new FormData();
    // On extrait le contenu du formulaire dans la variable 'form'
    form.append('id', id_clicked);
    form.append('etat_title', $('#etat_title_'+id_clicked).val());

    form.append('etat_balise_h1', $('#etat_balise_h1_'+id_clicked).val());
    form.append('etat_name', $('#etat_name_'+id_clicked).val());
    form.append('etat_rewrite', $('#etat_rewrite_'+id_clicked).val());
    if (!IE11) {
        form.append('etat_meta_description', $('#etat_meta_description_'+id_clicked).next().find('.ck-editor__editable').text());
        form.append('etat_tagline_1', $('#etat_tagline_1_'+id_clicked).next().find('.ck-editor__editable').html().replace(/(<p[^>]+?>|<p>|<\/p>)/img, ""));
        form.append('etat_tagline_2', $('#etat_tagline_2_'+id_clicked).next().find('.ck-editor__editable').html().replace(/(<p[^>]+?>|<p>|<\/p>)/img, ""));
        form.append('etat_description_courte', $('#etat_description_courte_'+id_clicked).next().find('.ck-editor__editable').html());

        form.append('etat_contenu_1', $('#etat_contenu_1_'+id_clicked).next().find('.ck-editor__editable').html());
    }
    else {
        form.append('etat_meta_description', $('#etat_meta_description_'+id_clicked).val());
        form.append('etat_tagline_1', $('#etat_tagline_1_'+id_clicked).val().replace(/(<p[^>]+?>|<p>|<\/p>)/img, ""));
        form.append('etat_tagline_2', $('#etat_tagline_2_'+id_clicked).val().replace(/(<p[^>]+?>|<p>|<\/p>)/img, ""));
        form.append('etat_description_courte', $('#etat_description_courte_'+id_clicked).val());

        form.append('etat_contenu_1', $('#etat_contenu_1_'+id_clicked).val());
    }

    form.append('etat_is_visible', $('input[name="etat_visible_' + id_clicked + '"]:checked').val());

    // Verifie la longeur du texte
    if ($("#etat_title_"+id_clicked).val().length > 200) {
        toastr.error('Merci de reduire le title', 'Erreur');
        return false;
    }
    // Verifie la longeur du texte
    if ($("#etat_meta_description_"+id_clicked).val().length > 249) {
        toastr.error('Merci de reduire la meta description', 'Erreur');
        return false;
    }
    // Verifie la longeur du texte
    if ($("#etat_balise_h1_"+id_clicked).val().length > 200) {
        toastr.error('Merci de reduire la balise h1', 'Erreur');
        return false;
    }

    $.ajax({
        type: 'POST',
        url: siteURL + 'admin/vehicules/etat/modify',
        cache: false,
        contentType: false,
        processData: false,
        data: form,
        success: function(data) {
            // TOAST
            toastr.success("L'etat a bien été enregistrée", 'Succès');
            // On nettoye le html en supprimant l'onglet 'Modifier/Nouveau'
            $('#etat-tabs-modify-'+id_clicked).remove();
            $('#etat-close-modify-'+id_clicked).parent().remove();

            if (id_clicked == 0) {
                // On réinitialise le formulaire
                $('#formetat_'+id_clicked)[0].reset();
                $("[id*='_content_']").each( function(e) { $(this).summernote('reset'); });
            }
            // Retour à la liste d'actualités en rechargeant la datatable
            $('#etat-tabs-list-tab').trigger('click');
            etat_table.ajax.reload();
        }
    });
     // Empèche le 'button' de fonctionner
    return false;
});