// Génération de la datatable des prospects
devise_table = $('#devise_table').DataTable({
    "autoWidth": false,
    "language": {
        "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/fr-FR.json"
    },
    "paging": false,
    "responsive": true,
    "ajax": {
        "url": siteURL + 'json/devises',
        "type": "POST",
    },
    "order": [
        [0, "asc"]
    ],
    "columnDefs": [
        // TEXT CENTER
        {
            "className": "text-center align-middle",
            "targets": [2],
        },
        // ALIGN MIDDLE
        {
            "className": "align-middle",
            "targets": '_all',
        },
        // NON
        {
            "targets": 0,
            "render": function(data, type, row) {
                return row[1];
            },
        },
        // TAUX DE CHANGE
        {
            "targets": 1,
            "render": function(data, type, row) {
                return row[2];
            },
        },
        // MODIFY
        {
            "width": "50px",
            "targets": 2,
            "render": function ( data, type, row ) {
                return '<button class="btn w-100 p-0" id="devise-open-modify-'+row[0]+'" data-category="administration"><i class="fad fa-edit"></i>';
            },
            "orderable": false,
        },
    ],
    "conditionalPaging": true,
});


// Envoi de modification d'une devise
$('body').on('click', "button[id^='devise-send-modify-']", function(e) {
    e.preventDefault();
    let id_clicked = this.id.replace('devise-send-modify-', '');
    let devise_nom = $('#devise_nom_'+id_clicked).val();
    let devise_taux = $('#devise_taux_'+id_clicked).val();
    if (devise_nom.length == 3) {
        let form = new FormData();
        form.append('id', id_clicked);
        form.append('devise_nom', devise_nom.toUpperCase());
        form.append('devise_taux', devise_taux);
        $.ajax({
            type: 'POST',
            url: siteURL + 'admin/administration/devise/modify',
            contentType: false,
            processData: false,
            data: form,
            success: function() {
                toastr.success('La devise a bien été enregistrée', 'Succès');
                $('#devise_nom_'+id_clicked).val('');
                $('#devise_taux_'+id_clicked).val('');
                $('#devise-tabs-modify-'+id_clicked).remove();
                $('#devise-close-modify-'+id_clicked).parent().remove();
                $('#devise-tabs-list-tab').trigger('click');
                devise_table.ajax.reload();
            },
        });
    } else {
        toastr.error('Le nom de la devise doit comporter 3 caractères.<br/>Exemples : EUR, USD, JPY, GBP, ...', 'Erreur');
    }
});