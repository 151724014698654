let comptabiliteFacturationTable = null;
let timerBeforeRefreshDataFacturation = null;

$(document).ready(function () {
    if ($("#comptabilite-facturation-results").length) {
        window.history.pushState("", "", siteURL + "admin/comptabilite/facturation");

        colvisFunOnEvent();
        // Chargement du tableau de facturation
        function loadAjax({ goToRecapFacture = "" } = {}) {
            $("#comptabilite-facturation-results").addClass("d-none");
            const form = getParams();
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/comptabilite/facturation/loadAjax',
                contentType: false,
                processData: false,
                data: form,
                success: function (data) {
                    $("#comptabilite-facturation-results").html(data);
                    comptabiliteFacturationTable = $('#comptabilite-facturation-table').DataTable({
                        "oSearch": { "sSearch": (goToRecapFacture ? "id:[" + goToRecapFacture.toString() + "]" : "") },
                        "dom": dataTablesCustomDom({ scrollable: true, graphs: true }),
                        "buttons": [dataTablesColvisBtn('th:nth-child(n+1)')],
                        "autoWidth": false,
                        "language": { "url": "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/French.json" },
                        "paging": true,
                        "pageLength": 25,
                        "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, "All"]],
                        "responsive": false,
                        "order": [[1, "asc"]],
                        "conditionalPaging": true,
                        "columnDefs": [].push(...getColumnDefsType("comptabilite-facturation-table")),
                        "columns": getColvisColumn("comptabilite-facturation-table")
                    });
                    $('#comptabilite-facturation-table').on('init.dt', function () { initMirrorBtn(); setColvisTable(comptabiliteFacturationTable); colvisFunSelectRefresh(); });
                    $('#comptabilite-facturation-table').on('draw.dt', function () { $("#comptabilite-facturation-results").removeClass("d-none"); });
                    $('#comptabilite-facturation-table').on('page.dt', function () { });
                    $('#comptabilite-facturation-table').on('length.dt', function () { });
                    facturationGraphCompare(false);
                    facturationGraphInit(form);
                }
            })
        };

        setupLoadAjax(loadAjax);
        setupLoadModele(loadModele);
        setupResetFilter(resetFilter);

        // Récupération des paramètres des requêtes suivant les filtres
        function getParams() {
            var optionsRecherche = new FormData();
            // Si on a un goToRecapFacture, on ne doit pas passer les dates mises par défaut
            if (goToRecapFacture == "") {
                optionsRecherche.append('datecommande1', (new Date($("#filtersfacturation-datecommande1").val())).toLocaleDateString("fr"));
                optionsRecherche.append('datecommande2', (new Date($("#filtersfacturation-datecommande2").val())).toLocaleDateString("fr"));
            } else {
                optionsRecherche.append('datecommande1', "Invalid Date");
                optionsRecherche.append('datecommande2', "Invalid Date");
            }
            optionsRecherche.append('datefacture1', (new Date($("#filtersfacturation-datefacture1").val())).toLocaleDateString("fr"));
            optionsRecherche.append('datefacture2', (new Date($("#filtersfacturation-datefacture2").val())).toLocaleDateString("fr"));
            optionsRecherche.append('facture', $("#filtersfacturation-facture").val());
            optionsRecherche.append('reglement', $("#filtersfacturation-reglement").val());
            optionsRecherche.append('livre', $("#filtersfacturation-livre").val());
            optionsRecherche.append('finance', $("#filtersfacturation-finance").val());
            optionsRecherche.append('emplacement', $("#filtersfacturation-emplacement").val());
            optionsRecherche.append('entite', $("#filtersfacturation-entite").val());
            optionsRecherche.append('vendeur', $("#filtersfacturation-vendeur").val());
            optionsRecherche.append('client', $("#filtersfacturation-client").val());
            optionsRecherche.append('tva', $("#filtersfacturation-tva").val());
            optionsRecherche.append('reprise', $("#filtersfacturation-reprise").val());
            optionsRecherche.append('jourstock', $("#filtersfacturation-jourstock").val());
            optionsRecherche.append('erreur', $("#filtersfacturation-erreur").val());
            optionsRecherche.append('marque', $("#filtersfacturation-marque").val());
            optionsRecherche.append('modele', $("#filtersfacturation-modele").val());
            optionsRecherche.append('energie', $("#filtersfacturation-energie").val());
            optionsRecherche.append('boite', $("#filtersfacturation-boite").val());
            return optionsRecherche;
        }

        // Requête pour tout type d'export de donné de facturation
        $('body').on('click', "[id^='comptabilite-facturation-export-']", function (e) {
            const exportType = e.target.id.replace("comptabilite-facturation-export-", "");
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/comptabilite/facturation/export/' + exportType,
                contentType: false,
                processData: false,
                data: getParams(),
                success: function (data) {
                    toastr.success('Le fichier ' + exportType + ' est fini', 'Succès');
                    var element = document.createElement('a');
                    element.setAttribute('href', siteURL + data);
                    element.setAttribute('download', "Export " + (new Date()).toLocaleDateString("fr") + "." + (exportType == "excel" ? "xlsx" : exportType));
                    document.body.appendChild(element);
                    element.click();
                    document.body.removeChild(element);
                },
                error: function () {
                    toastr.error("Erreur lors de l'export", "Erreur");
                }
            })
        });

        $('body').on('change', "[id^='filtersfacturation-']", function () { clearTimeout(timerBeforeRefreshDataFacturation); timerBeforeRefreshDataFacturation = setTimeout(loadAjax, 500); });
        // Bouton reset
        $('body').on('click', '#filtersfacturation-reset', function () {
            resetFilter();
            loadAjax();
        });
        $('body').on('change', "#filtersfacturation-marque", loadModele);

        function loadModele() {
            if ($('#filtersfacturation-marque').val().length != 0) {
                $("#filtersfacturation-modele").prop('disabled', false);
                $('#filtersfacturation-marque').val().forEach(element => { $("#opt_" + element).prop('disabled', false); });
                $("[id^='opt_']").each(function () {
                    let marqueEnCours = $(this).attr('id').replace('opt_', '');
                    if (!$('#filtersfacturation-marque').val().includes(marqueEnCours)) {
                        $(this).prop('disabled', true);
                    }
                })
                $("#filtersfacturation-modele").selectpicker('refresh');
            } else {
                $('#filtersfacturation-modele').prop("disabled", true);
                $("#filtersfacturation-modele").find("optgroup").prop('disabled', true);
                $("#filtersfacturation-modele").selectpicker('refresh');
            }
        }

        /**
         * Import des ventes depuis Spider VO
         * @description DÉBUT
         */

        $('body').on('dragover', "[for='comptabilite-facturation-import']", function (e) { e.preventDefault(); });
        $('body').on('drop', "[for='comptabilite-facturation-import']", function (e) {
            e.preventDefault();
            if (e.dataTransfer === undefined) { e.dataTransfer = e.originalEvent.dataTransfer; }
            if (e.dataTransfer !== undefined) {
                if (e.dataTransfer.items) {
                    [...e.dataTransfer.items].forEach((item, i) => { if (item.kind === "file") { importRecapFacture(item.getAsFile()); } });
                } else {
                    [...e.dataTransfer.files].forEach((file, i) => { importRecapFacture(file); });
                }
            }
        });
        $('body').on('change', "#comptabilite-facturation-import", function () {
            if (this.files.length > 0) {
                importRecapFacture(this.files[0]);
            }
        });
        let importRecapFactureFile = null;
        function importRecapFacture(file) {
            document.getElementById("comptabilite-facturation-import-filename").innerHTML = file.name;
            document.getElementById("comptabilite-facturation-import-file").classList.remove("d-none");
            document.getElementById("comptabilite-facturation-request").classList.remove("d-none");
            importRecapFactureFile = file;
        }

        $('#comptabilite-facturation-history-modal').on('shown.bs.modal', function () {
            $.ajax({
                type: 'GET',
                url: siteURL + 'admin/comptabilite/facturation/history',
                success: function (data) {
                    if (data.success == true) {
                        document.getElementById("comptabilite-facturation-history").replaceWith(createElementFromHTML(data.history).querySelector("#comptabilite-facturation-history"));
                    } else {
                        const row = document.createElement("tr"); // Créer une nouvelle ligne de tableau
                        row.appendChild(Object.assign(document.createElement("td"), { textContent: "Échec du chargement", colSpan: 4 })); // Créer la cellule pour l'erreur
                        document.getElementById("comptabilite-facturation-history").querySelector("tbody").appendChild(row); // Ajouter la ligne au tbody
                    }
                },
                error: function () {
                    const row = document.createElement("tr"); // Créer une nouvelle ligne de tableau
                    row.appendChild(Object.assign(document.createElement("td"), { textContent: "Échec du chargement", colSpan: 4 })); // Créer la cellule pour l'erreur
                    document.getElementById("comptabilite-facturation-history").querySelector("tbody").appendChild(row); // Ajouter la ligne au tbody
                }
            });
        });

        /**
         * Import des ventes depuis Spider VO
         * L'import se comporte en 3 étapes :
         * 1. Lecture du fichier d'import
         * 2. Envoi des requêtes d'enregistrement
         * 3. Suppression des données en trop
         */
        $('body').on('click', "#comptabilite-facturation-request", function () { // Lorsque l'utilisateur clique sur le bouton d'envoi de la requête
            $('#comptabilite-facturation-import-modal').on('hidden.bs.modal', function () { // Lorsque la modal de séléction du fichier est fermée
                $('#comptabilite-facturation-request-modal').on('shown.bs.modal', function () { // Lorsque la modal des envois de requête est ouverte
                    importRecapFactureRead() // Lance la lecture du fichier
                        .then(({ importForms, maxDate, minDate, listNoDossier, importId }) => { // Lorsque la lecture est terminée avec succès
                            importRecapFactureSend(importForms, importId) // Lance l'envoi des requêtes
                                .then(() => { // Lorsque l'envoi est terminé avec succès
                                    importRecapFactureFnpc(importForms, importId) // Lance les neutralisations
                                        .then(() => { // Lorsque l'envoi est terminé avec succès
                                            importRecapFactureLoad(maxDate, minDate, listNoDossier) // Lance la suppression des données en trop
                                                .then(() => { // Lorsque la suppression des données en trop est terminé avec sucees
                                                    const importModalBtn = document.querySelector("[data-target='#comptabilite-facturation-import-modal']");
                                                    importModalBtn.setAttribute("disabled", true);
                                                    $('#comptabilite-facturation-request-modal').on('hidden.bs.modal', function () { // Lorsque la modal des envois de requête est fermée
                                                        $.ajax({
                                                            type: 'GET',
                                                            url: siteURL + 'admin/comptabilite/facturation/importModalUpdate', // Update des modals d'import
                                                            success: function (data) {
                                                                if (data.success == true) {
                                                                    // Remplace le contenu des modals
                                                                    document.getElementById("comptabilite-facturation-import-modal").replaceWith(createElementFromHTML(data.import));
                                                                    document.getElementById("comptabilite-facturation-request-modal").replaceWith(createElementFromHTML(data.request));
                                                                    importModalBtn.removeAttribute("disabled");
                                                                    // Met à jour la date de dernière mise à jour
                                                                    const dateLastImportIdentificator = importModalBtn.parentElement.querySelector(".fas.fa-regular.fa-clock");
                                                                    const dateCreation = new Date(data.dateLastImport.dateCreation.date.replace(" ", "T").split(".")[0]);
                                                                    const dateDebut = new Date(data.dateLastImport.dateDebut.date.replace(" ", "T").split(".")[0]);
                                                                    const dateFin = new Date(data.dateLastImport.dateFin.date.replace(" ", "T").split(".")[0]);
                                                                    dateLastImportIdentificator.parentElement.childNodes[dateLastImportIdentificator.parentElement.childNodes.length - 1].data = " Dernière mise à jour : " + timestampToFormatedDate(dateCreation.getTime(), true);
                                                                    dateLastImportIdentificator.parentElement.nextElementSibling.textContent = "(Commande du " + timestampToFormatedDate(dateDebut.getTime()) + " au " + timestampToFormatedDate(dateFin.getTime()) + ")";
                                                                } else window.location.reload(); // Si l'update des modals d'import a echoué, on rafraichit la page
                                                            }, error: function () { window.location.reload(); } // Si l'update des modals d'import a echoué, on rafraichit la page
                                                        });
                                                    });
                                                    toastr.success("L'importation des ventes est terminé", "Succès"); // Afficher un message de succès pour marquer la fin de l'import
                                                    loadAjax(); // Recharge les données du tableau de facturation
                                                })
                                                .catch(error => { toastr.error("Erreur lors du chargement", "Erreur"); console.error(error); }); // Lorsque le chargement des données est terminée avec erreur
                                        })
                                        .catch(error => { toastr.error("Erreur lors de la neutralisation", "Erreur"); console.error(error); }); // Lorsque la neutralisation est terminée avec erreur
                                })
                                .catch(error => { toastr.error("Erreur lors de l'envoi", "Erreur"); console.error(error); }); // Lorsque l'envoi des requêtes est terminée avec erreur
                        })
                        .catch(error => { toastr.error("Erreur lors de la lecture", "Erreur"); console.error(error); }); // Lorsque la lecture du fichier est terminée avec erreur
                });
                $("#comptabilite-facturation-request-modal").modal('show'); // Ouvrir la modal de l'envoi de la requête
            });
            $('#comptabilite-facturation-import-modal').modal('hide'); // Fermer la modal de séléction du fichier
        });

        const importRef = {
            noDossier: { col: 1, label: null },
            noPolice: { col: 2, label: null },
            dateCommande: { col: 5, label: null },
            financement: { col: 9, label: null },
            noFacture: { col: 10, label: null },
            dateFacture: { col: 11, label: null },
            fournisseurNomEntreprise: { col: 14, label: null },
            fournisseurAdresse: { col: 15, label: null },
            fournisseurCodePostal: { col: 16, label: null },
            fournisseurVille: { col: 17, label: null },
            fournisseurPays: { col: 18, label: null },
            fournisseurTelFixe: { col: 19, label: null },
            fournisseurTelMobile: { col: 20, label: null },
            fournisseurEmail: { col: 21, label: null },
            vendeur: { col: 23, label: null },
            clientNom: { col: 24, label: null },
            clientAdresse: { col: 25, label: null },
            clientVille: { col: 27, label: null },
            clientCodePostal: { col: 26, label: null },
            clientPays: { col: 28, label: null },
            clientTelFixe: { col: 29, label: null },
            clientTelMobile: { col: 30, label: null },
            clientEmail: { col: 31, label: null },
            vehiculeMarque: { col: 33, label: null },
            vehiculeModele: { col: 34, label: null },
            vehiculeImmatriculation: { col: 35, label: null },
            vehiculeVin: { col: 36, label: null },
            vehiculeKm: { col: 37, label: null },
            vehiculeEnergie: { col: 38, label: null },
            vehiculeTva: { col: 39, label: null },
            vehiculePrixAchatPrix: { col: 41, label: null },
            vehiculePrixVente: { col: 42, label: null },
            montantFmrunformatNumber: { col: 43, label: null },
            montantCarteGrise: { col: 49, label: null },
            transportAchat: { col: 50, label: null },
            vehiculeNbJoursStock: { col: 53, label: null },
            repriseMarqueModele: { col: 56, label: null },
            repriseImmatriculation: { col: 57, label: null },
            repriseVin: { col: 58, label: null },
            repriseMontant: { col: 59, label: null },
            montantMalus: { col: 60, label: null },
            entiteCommerciale: { col: 61, label: null },
            etatReglement: { col: 63, label: null },
            dateReglement: { col: 64, label: null },
            dateLivraison: { col: 68, label: null }
        };

        const importRefGetLabel = (col) => Object.values(importRef).find(ref => ref.col === col).label; // Fonction de recherche du label pour une colonne donnée en paramètre

        function importRecapFactureRead() { // Fonction de lecture de l'import
            return new Promise((resolve, reject) => {
                // Préparation de la barre de progression
                const readProgress = document.getElementById("comptabilite-facturation-progress-read"); // Initialisation de la barre de progression
                let readProgressPrevious = 0;
                // Préparation du tableau des erreurs
                const readError = document.getElementById("comptabilite-facturation-progress-read-error"); // Initialisation de l'élement contenant le tableau des erreurs
                const readErrorTbody = readError.querySelector("tbody"); // Initialisation de l'élement contenant le corps du tableau
                readErrorTbody.innerHTML = ""; // Vide le corps du tableau
                try {
                    const data = importRecapFactureFile.arrayBuffer();
                    data.then((data) => {
                        const sheet = Object.values(XLSX.read(data).Sheets)[0];
                        const arr = sheet2arr(sheet); // Conversion de feuille excel en array JS
                        // Initialisation des variables
                        Object.keys(importRef).forEach(key => importRef[key].label = arr[0][importRef[key].col] || null); // Initialisation des labels des colonnes dans l'objet importRef
                        const importDatas = arr.slice(1); // Retire l'entête de l'import
                        const maxDate = importDatas.reduce((acc, row) => acc = ((row[5] !== undefined ? ((new Date(row[5].reverseDayMonth())).getTime() > acc || acc === true ? (new Date(row[5].reverseDayMonth())).getTime() : acc) : acc) || acc), true); // Récupère date de commande maximale
                        const minDate = importDatas.reduce((acc, row) => acc = ((row[5] !== undefined ? ((new Date(row[5].reverseDayMonth())).getTime() < acc || acc === true ? (new Date(row[5].reverseDayMonth())).getTime() : acc) : acc) || acc), true); // Récupère date de commande minimale
                        const listNoDossier = importDatas.map((row) => Number(row[1].replace("0728-", ""))); // Récupère la liste des noDossier
                        const importForms = []; // Création de la liste des formulaires
                        const skip = (it, occ = 1) => { for (let i = 0; i < occ; i++) { it.next(); } }; // Fonction pour skip un nb d'itération définie par occ (occurrence)
                        // Si le titre de la deuxième colonne slugify est "no-dossier" et que tout les noDossier sont valides
                        if (arr[0][1].slugify() === "no-dossier" && importDatas.reduce((acc, row) => acc && /^0728-\d+$/g.exec(row[1]) != null, true)) {
                            // Parcours toutes les lignes du fichier d'import
                            for (let row = 0; row < importDatas.length; row++) {
                                /**
                                 * Récupération des infos
                                 * @description DÉBUT
                                 * if ((currentDataValue = importDataIt.next().value) !== undefined) {}
                                 * La condition ci-dessus récupére la nouvelle valeur actuelle et vérifie qu'elle n'est pas undefined
                                 */

                                // Initialisation de l'itérateur, du formulaire et de la variable de la valeur actuelle
                                const importDataIt = importDatas[row][Symbol.iterator](); // Création de l'itérateur
                                const importForm = new FormData(); // Création du formulaire
                                importForm.append("maxDate", maxDate); // Ajout de la date maximale
                                importForm.append("minDate", minDate); // Ajout de la date minimale
                                let currentDataValue = null; // Initialisation de la valeur actuelle à null
                                // On parcours les valeurs de l'iterateur pour les mettre dans le formulaire
                                skip(importDataIt, 1); // Acheteur
                                if ((currentDataValue = importDataIt.next().value) !== undefined) { importForm.append("noDossier", Number(currentDataValue.replace("0728-", ""))); } // No dossier
                                if ((currentDataValue = importDataIt.next().value) !== undefined) { importForm.append("noPolice", Number(currentDataValue.replace("LP/", ""))); } // No LP
                                skip(importDataIt, 2); // Source + Disponibilité
                                if ((currentDataValue = importDataIt.next().value) !== undefined) { importForm.append("dateCommande", (new Date(currentDataValue.reverseDayMonth())).getTime()); } // Date de commande
                                skip(importDataIt, 3); // Date de création + Avec financement + Etat financement
                                if ((currentDataValue = importDataIt.next().value) !== undefined) { importForm.append("financement", Number(currentDataValue.unformatNumber())); } // Montant financé
                                if ((currentDataValue = importDataIt.next().value) !== undefined) { importForm.append("noFacture", Number(currentDataValue.replace("N/FAV", "").replace(" (d)", ""))); } // No Facture
                                if ((currentDataValue = importDataIt.next().value) !== undefined) { importForm.append("dateFacture", (new Date(currentDataValue.reverseDayMonth())).getTime()); } // Date Facture
                                skip(importDataIt, 2); // Type vhc + Destination
                                if ((currentDataValue = importDataIt.next().value) !== undefined) { importForm.append("fournisseurNomEntreprise", currentDataValue); } // Fournisseur
                                if ((currentDataValue = importDataIt.next().value) !== undefined) { importForm.append("fournisseurAdresse", currentDataValue); } // Adresse Founisseur
                                if ((currentDataValue = importDataIt.next().value) !== undefined) { importForm.append("fournisseurCodePostal", currentDataValue); } // CO Founisseur
                                if ((currentDataValue = importDataIt.next().value) !== undefined) { importForm.append("fournisseurVille", currentDataValue); } // Ville Founisseur
                                if ((currentDataValue = importDataIt.next().value) !== undefined) { importForm.append("fournisseurPays", currentDataValue); } // Pays Founisseur
                                if ((currentDataValue = importDataIt.next().value) !== undefined) { importForm.append("fournisseurTelFixe", currentDataValue.replaceAll(" ", "").replaceAll(".", "")); } // Tel Founisseur
                                if ((currentDataValue = importDataIt.next().value) !== undefined) { importForm.append("fournisseurTelMobile", currentDataValue.replaceAll(" ", "").replaceAll(".", "")); } // Tel.Mobile Founisseur
                                if ((currentDataValue = importDataIt.next().value) !== undefined) { importForm.append("fournisseurEmail", currentDataValue); } // Email Founisseur
                                skip(importDataIt, 1); // Acheteur
                                if ((currentDataValue = importDataIt.next().value) !== undefined) { importForm.append("vendeur", currentDataValue); } // Vendeur
                                if ((currentDataValue = importDataIt.next().value) !== undefined) { importForm.append("clientNom", currentDataValue); } // Client
                                if ((currentDataValue = importDataIt.next().value) !== undefined) { importForm.append("clientAdresse", currentDataValue); } // Adresse Client
                                if ((currentDataValue = importDataIt.next().value) !== undefined) { importForm.append("clientVille", currentDataValue); } // CP client
                                if ((currentDataValue = importDataIt.next().value) !== undefined) { importForm.append("clientCodePostal", currentDataValue); } // Ville Client
                                if ((currentDataValue = importDataIt.next().value) !== undefined) { importForm.append("clientPays", currentDataValue); } // Pays Client
                                if ((currentDataValue = importDataIt.next().value) !== undefined) { importForm.append("clientTelFixe", currentDataValue.replaceAll(" ", "").replaceAll(".", "")); } // Tel Client
                                if ((currentDataValue = importDataIt.next().value) !== undefined) { importForm.append("clientTelMobile", currentDataValue.replaceAll(" ", "").replaceAll(".", "")); } // Tel.Mobile Client
                                if ((currentDataValue = importDataIt.next().value) !== undefined) { importForm.append("clientEmail", currentDataValue); } // Email Client
                                skip(importDataIt, 1); // VN/VO
                                if ((currentDataValue = importDataIt.next().value) !== undefined) { importForm.append("vehiculeMarque", currentDataValue); } // Marque Véhicule
                                if ((currentDataValue = importDataIt.next().value) !== undefined) { importForm.append("vehiculeModele", currentDataValue); } // Modele Véhicule
                                if ((currentDataValue = importDataIt.next().value) !== undefined) { importForm.append("vehiculeImmatriculation", currentDataValue); } // Immat Véhicule
                                if ((currentDataValue = importDataIt.next().value) !== undefined) { importForm.append("vehiculeVin", currentDataValue); } // Vin Véhicule
                                if ((currentDataValue = importDataIt.next().value) !== undefined) { importForm.append("vehiculeKm", currentDataValue > 0 ? currentDataValue : 0); } // KM Véhicule
                                if ((currentDataValue = importDataIt.next().value) !== undefined) { importForm.append("vehiculeEnergie", currentDataValue); } // Energie Véhicule
                                if ((currentDataValue = importDataIt.next().value) !== undefined) { importForm.append("vehiculeTva", currentDataValue); } // TVA achat
                                skip(importDataIt, 1); // TVA vente
                                if ((currentDataValue = importDataIt.next().value) !== undefined) { importForm.append("vehiculePrixAchat", Number(currentDataValue.unformatNumber())); } // Prix d'achat HT
                                if ((currentDataValue = importDataIt.next().value) !== undefined) { importForm.append("vehiculePrixVente", Number(currentDataValue.unformatNumber())); } // Prix de vente HT
                                if ((currentDataValue = importDataIt.next().value) !== undefined) { importForm.append("montantFmr", Number(currentDataValue.unformatNumber())); } // FMR
                                skip(importDataIt, 4); // Marge brute HT + FRE + Marge produits& services HT + Valorisations Achat HT
                                if ((currentDataValue = importDataIt.next().value) !== undefined) { importForm.append("montantAccessoires", Number(currentDataValue.unformatNumber())); } // Valorisations Vente HT
                                if ((currentDataValue = importDataIt.next().value) !== undefined) { importForm.append("montantCarteGrise", Number(currentDataValue.unformatNumber())); } // Carte grise
                                if ((currentDataValue = importDataIt.next().value) !== undefined) { importForm.append("transportAchat", Number(currentDataValue.unformatNumber())); } // Transport achat HT
                                skip(importDataIt, 2); // Cession HT + Marge nette HT
                                if ((currentDataValue = importDataIt.next().value) !== undefined) { importForm.append("vehiculeNbJoursStock", Number(currentDataValue.unformatNumber())); } // Nb jours stockage
                                skip(importDataIt, 2); // Montant HT stockage + FRE prévus
                                if ((currentDataValue = importDataIt.next().value) !== undefined) { importForm.append("repriseMarqueModele", currentDataValue); } // Reprise
                                if ((currentDataValue = importDataIt.next().value) !== undefined) { importForm.append("repriseImmatriculation", currentDataValue); } // Immat Reprise
                                if ((currentDataValue = importDataIt.next().value) !== undefined) { importForm.append("repriseVin", currentDataValue); } // VIN Reprise
                                if ((currentDataValue = importDataIt.next().value) !== undefined) { importForm.append("repriseMontant", Number(currentDataValue.unformatNumber())); } // Reprise Montant TTC
                                if ((currentDataValue = importDataIt.next().value) !== undefined) { importForm.append("montantMalus", Number(currentDataValue.unformatNumber())); } // Malus ECO
                                if ((currentDataValue = importDataIt.next().value) !== undefined) { importForm.append("entiteCommerciale", currentDataValue.replace("7-", " ")); } // Entitée commerciale
                                skip(importDataIt, 6); // Nb jours vente + Etat règlements client + Date Règlement Client + Date Règlement Fournisseur + Achat Produits HT + Vente Produits HT
                                if ((currentDataValue = importDataIt.next().value) !== undefined) { if (!isNaN(importForm.get("dateCommande"))) { importForm.append("dateLivraison", parseInt(importForm.get("dateCommande")) + (currentDataValue * 86400000)); } } // Délai de livraison
                                // Ajout du formulaire dans la liste des formulaires
                                importForms.push(importForm);

                                /**
                                 * Récupération des infos
                                 * @description FIN
                                 */

                                /**
                                 * Vérification des infos
                                 * @description DÉBUT
                                 */

                                // Initialisation de la liste des erreurs
                                const importError = [];
                                // Vérification des infos
                                if (importForm.get("noDossier") == null) { importError.push({ fatal: true, col: importRef.noDossier.col, msg: "Valeur manquante" }); } else if (isNaN(importForm.get("noDossier"))) { importError.push({ fatal: true, col: importRef.noDossier.col, msg: "Valeur invalide" }); }
                                if (importForm.get("noPolice") == null) { importError.push({ col: importRef.noPolice.col, msg: "Valeur manquante" }); } else if (isNaN(importForm.get("noPolice"))) { importError.push({ fatal: true, col: importRef.noPolice.col, msg: "Valeur invalide" }); }
                                if (importForm.get("dateCommande") == null) { importError.push({ col: importRef.dateCommande.col, msg: "Date manquante" }); } else if (isNaN(importForm.get("dateCommande"))) { importError.push({ fatal: true, col: importRef.dateCommande.col, msg: "Date invalide" }); }
                                if (importForm.get("financement") != null && isNaN(importForm.get("financement"))) { importError.push({ fatal: true, col: importRef.financement.col, msg: "Valeur invalide" }); }
                                if (importForm.get("noFacture") != null && importForm.get("noFacture").length > 255) { importError.push({ fatal: true, col: importRef.noFacture.col, msg: "Valeur trop longue" }); }
                                if (importForm.get("dateFacture") != null && isNaN(importForm.get("dateFacture"))) { importError.push({ fatal: true, col: importRef.dateFacture.col, msg: "Date invalide" }); }
                                if (importForm.get("fournisseurNomEntreprise") == null) { importError.push({ col: importRef.fournisseurNomEntreprise.col, msg: "Valeur manquante" }); } else if (importForm.get("fournisseurNomEntreprise").length > 255) { importError.push({ fatal: true, col: importRef.fournisseurNomEntreprise.col, msg: "Valeur trop longue" }); }
                                if (importForm.get("fournisseurAdresse") != null && importForm.get("fournisseurAdresse").length > 255) { importError.push({ fatal: true, col: importRef.fournisseurAdresse.col, msg: "Valeur trop longue" }); }
                                if (importForm.get("fournisseurCodePostal") != null && importForm.get("fournisseurCodePostal").length > 255) { importError.push({ fatal: true, col: importRef.fournisseurCodePostal.col, msg: "Valeur trop longue" }); }
                                if (importForm.get("fournisseurVille") != null && importForm.get("fournisseurVille").length > 255) { importError.push({ fatal: true, col: importRef.fournisseurVille.col, msg: "Valeur trop longue" }); }
                                if (importForm.get("fournisseurPays") != null && importForm.get("fournisseurPays").length > 255) { importError.push({ fatal: true, col: importRef.fournisseurPays.col, msg: "Valeur trop longue" }); }
                                if (importForm.get("fournisseurTelFixe") != null && importForm.get("fournisseurTelFixe").length > 255) { importError.push({ fatal: true, col: importRef.fournisseurTelFixe.col, msg: "Valeur trop longue" }); }
                                if (importForm.get("fournisseurTelMobile") != null && importForm.get("fournisseurTelMobile").length > 255) { importError.push({ fatal: true, col: importRef.fournisseurTelMobile.col, msg: "Valeur trop longue" }); }
                                if (importForm.get("fournisseurEmail") != null && importForm.get("fournisseurEmail").length > 255) { importError.push({ fatal: true, col: importRef.fournisseurEmail.col, msg: "Valeur trop longue" }); }
                                if (importForm.get("vendeur") == null) { importError.push({ col: importRef.vendeur.col, msg: "Valeur manquante" }); }
                                if (importForm.get("clientNom") == null) { importError.push({ col: importRef.clientNom.col, msg: "Valeur manquante" }); } else if (importForm.get("clientNom").length > 255) { importError.push({ fatal: true, col: importRef.clientNom.col, msg: "Valeur trop longue" }); }
                                if (importForm.get("clientAdresse") != null && importForm.get("clientAdresse").length > 255) { importError.push({ fatal: true, col: importRef.clientAdresse.col, msg: "Valeur trop longue" }); }
                                if (importForm.get("clientVille") != null && importForm.get("clientVille").length > 255) { importError.push({ fatal: true, col: importRef.clientVille.col, msg: "Valeur trop longue" }); }
                                if (importForm.get("clientCodePostal") != null && importForm.get("clientCodePostal").length > 255) { importError.push({ fatal: true, col: importRef.clientCodePostal.col, msg: "Valeur trop longue" }); }
                                if (importForm.get("clientPays") != null && importForm.get("clientPays").length > 255) { importError.push({ fatal: true, col: importRef.clientPays.col, msg: "Valeur trop longue" }); }
                                if (importForm.get("clientTelFixe") != null && importForm.get("clientTelFixe").length > 255) { importError.push({ fatal: true, col: importRef.clientTelFixe.col, msg: "Valeur trop longue" }); }
                                if (importForm.get("clientTelMobile") != null && importForm.get("clientTelMobile").length > 255) { importError.push({ fatal: true, col: importRef.clientTelMobile.col, msg: "Valeur trop longue" }); }
                                if (importForm.get("clientEmail") != null && importForm.get("clientEmail").length > 255) { importError.push({ fatal: true, col: importRef.clientEmail.col, msg: "Valeur trop longue" }); }
                                if (importForm.get("vehiculeMarque") == null) { importError.push({ col: importRef.vehiculeMarque.col, msg: "Valeur manquante" }); } else if (importForm.get("vehiculeMarque").length > 255) { importError.push({ fatal: true, col: importRef.vehiculeMarque.col, msg: "Valeur trop longue" }); }
                                if (importForm.get("vehiculeModele") == null) { importError.push({ col: importRef.vehiculeModele.col, msg: "Valeur manquante" }); } else if (importForm.get("vehiculeModele").length > 255) { importError.push({ fatal: true, col: importRef.vehiculeModele.col, msg: "Valeur trop longue" }); }
                                if (importForm.get("vehiculeImmatriculation") != null && importForm.get("vehiculeImmatriculation").length > 50) { importError.push({ fatal: true, col: importRef.vehiculeImmatriculation.col, msg: "Valeur trop longue" }); }
                                if (importForm.get("vehiculeVin") == null) { importError.push({ col: importRef.vehiculeVin.col, msg: "Valeur manquante" }); } else if (!matchVIN(importForm.get("vehiculeVin"))) { importError.push({ col: importRef.vehiculeVin.col, msg: "Valeur inconforme" }); }
                                if (importForm.get("vehiculeKm") == null) { importError.push({ col: importRef.vehiculeKm.col, msg: "Valeur manquante" }); } else if (isNaN(importForm.get("vehiculeKm"))) { importError.push({ fatal: true, col: importRef.vehiculeKm.col, msg: "Valeur invalide" }); }
                                if (importForm.get("vehiculeEnergie") == null) { importError.push({ col: importRef.vehiculeEnergie.col, msg: "Valeur manquante" }); }
                                if (importForm.get("vehiculeTva") == null) { importError.push({ col: importRef.vehiculeTva.col, msg: "Valeur manquante" }); } else if (importForm.get("vehiculeTva").length > 50) { importError.push({ fatal: true, col: importRef.vehiculeTva.col, msg: "Valeur trop longue" }); }
                                if (importForm.get("vehiculePrixAchat") == null) { importError.push({ col: importRef.vehiculePrixAchat.col, msg: "Valeur manquante" }); } else if (isNaN(importForm.get("vehiculePrixAchat"))) { importError.push({ fatal: true, col: importRef.vehiculePrixAchat.col, msg: "Valeur invalide" }); }
                                if (importForm.get("vehiculePrixVente") == null) { importError.push({ col: importRef.vehiculePrixVente.col, msg: "Valeur manquante" }); } else if (isNaN(importForm.get("vehiculePrixVente"))) { importError.push({ fatal: true, col: importRef.vehiculePrixVente.col, msg: "Valeur invalide" }); }
                                if (importForm.get("montantFmr") == null) { importError.push({ col: importRef.montantFmr.col, msg: "Valeur manquante" }); } else if (isNaN(importForm.get("montantFmr"))) { importError.push({ fatal: true, col: importRef.montantFmr.col, msg: "Valeur invalide" }); }
                                if (importForm.get("montantAccessoires") == null) { importError.push({ col: importRef.montantAccessoires.col, msg: "Valeur manquante" }); } else if (isNaN(importForm.get("montantAccessoires"))) { importError.push({ fatal: true, col: importRef.montantAccessoires.col, msg: "Valeur invalide" }); }
                                if (importForm.get("montantCarteGrise") == null) { importError.push({ col: importRef.montantCarteGrise.col, msg: "Valeur manquante" }); } else if (isNaN(importForm.get("montantCarteGrise"))) { importError.push({ fatal: true, col: importRef.montantCarteGrise.col, msg: "Valeur invalide" }); }
                                if (importForm.get("transportAchat") == null) { importError.push({ col: importRef.transportAchat.col, msg: "Valeur manquante" }); } else if (isNaN(importForm.get("transportAchat"))) { importError.push({ fatal: true, col: importRef.transportAchat.col, msg: "Valeur invalide" }); }
                                if (importForm.get("vehiculeNbJoursStock") != null && isNaN(importForm.get("vehiculeNbJoursStock"))) { importError.push({ fatal: true, col: importRef.vehiculeNbJoursStock.col, msg: "Valeur invalide" }); }
                                if (importForm.get("repriseMarqueModele") != null && importForm.get("repriseMarqueModele").length > 255) { importError.push({ fatal: true, col: importRef.repriseMarqueModele.col, msg: "Valeur trop longue" }); }
                                if (importForm.get("repriseImmatriculation") != null && importForm.get("repriseImmatriculation").length > 50) { importError.push({ fatal: true, col: importRef.repriseImmatriculation.col, msg: "Valeur trop longue" }); }
                                if (importForm.get("repriseVin") != null && !matchVIN(importForm.get("repriseVin"))) { importError.push({ col: importRef.repriseVin.col, msg: "Valeur inconforme" }); }
                                if (importForm.get("repriseMontant") != null && isNaN(importForm.get("repriseMontant"))) { importError.push({ fatal: true, col: importRef.repriseMontant.col, msg: "Valeur invalide" }); }
                                if (importForm.get("montantMalus") == null) { importError.push({ col: importRef.montantMalus.col, msg: "Valeur manquante" }); } else if (isNaN(importForm.get("montantMalus"))) { importError.push({ fatal: true, col: importRef.montantMalus.col, msg: "Valeur invalide" }); }
                                if (importForm.get("entiteCommerciale") == null) { importError.push({ col: importRef.entiteCommerciale.col, msg: "Valeur manquante" }); } else if (importForm.get("entiteCommerciale").length > 50) { importError.push({ fatal: true, col: importRef.entiteCommerciale.col, msg: "Valeur trop longue" }); }
                                if (importForm.get("dateLivraison") != null && isNaN(importForm.get("dateLivraison"))) { importError.push({ fatal: true, col: importRef.dateLivraison.col, msg: "Date invalide" }); }
                                importForm.append("error", importError.filter(err => err.fatal === true).length != 0); // Ajout de la presence d'une erreur fatal dans le formulaire pour prévenir l'echec de la requete
                                if (importError.length != 0) { // Si le fichier contient des erreurs fatal ou non
                                    if (readError.classList.contains("opacity-0")) readError.classList.remove("opacity-0"); // Affichage des erreurs si elles ne sont pas déjà affichées
                                    if (readError.classList.contains("h-0px")) readError.classList.remove("h-0px"); // Affichage des erreurs si elles ne sont pas déjà affichées
                                }
                                importError.forEach((err) => { // Pour chaque erreur
                                    const row = document.createElement("tr"); // Créer une nouvelle ligne de tableau
                                    row.appendChild(Object.assign(document.createElement("td"), { textContent: importForm.get("noDossier") })); // Créer la cellule pour le noDossier
                                    row.appendChild(Object.assign(document.createElement("td"), { textContent: importRefGetLabel(err.col) })); // Créer la cellule pour le nom de la colonne
                                    row.appendChild(Object.assign(document.createElement("td"), { textContent: err.msg })); // Créer la cellule pour le message d'erreur
                                    readErrorTbody.appendChild(row); // Ajouter la ligne au tbody
                                });

                                /**
                                 * Vérification des infos
                                 * @description FIN
                                 */

                                // Calcul de la progression réelle ou la différence avec 100 pour la dernière progression
                                const readProgressReal = Math.round(((((row + 1) / (importDatas.length + 1)) * 100) - readProgressPrevious) * 100) / 100;
                                // Mise à jour de la progression précédente et de la progression totale
                                readProgressPrevious += readProgressReal;
                                // Mise à jour de la barre de progression
                                readProgress.appendChild(createProgressBar(readProgressReal + "%", importError.length == 0 ? "success" : (importError.filter(err => err.fatal === true).length == 0 ? "warning" : "danger")));
                            }
                            const form = new FormData(); // Création du formulaire
                            form.append("maxDate", maxDate); // Ajout de la date maximale
                            form.append("minDate", minDate); // Ajout de la date minimale
                            $.ajax({
                                type: 'POST',
                                url: siteURL + 'admin/comptabilite/facturation/create',
                                contentType: false,
                                processData: false,
                                data: form,
                                success: function (data) {
                                    readProgress.classList.add("bg-" + (data.success === true ? "success" : "danger"));
                                    if (data.success === true) {
                                        const importId = data.importId;
                                        resolve({ importForms, maxDate, minDate, listNoDossier, importId }); // Lancement de la fonction d'envoi
                                    } else {
                                        reject("Historisation impossible");
                                    }
                                },
                                error: function () {
                                    readProgress.classList.add("bg-danger");
                                    reject("Historisation impossible");
                                },
                            });
                        } else {
                            if (readError.classList.contains("opacity-0")) readError.classList.remove("opacity-0"); // Affichage des erreurs si elles ne sont pas déjà affichées
                            if (readError.classList.contains("h-0px")) readError.classList.remove("h-0px"); // Affichage des erreurs si elles ne sont pas déjà affichées
                            const row = document.createElement("tr"); // Créer une nouvelle ligne de tableau
                            row.appendChild(Object.assign(document.createElement("td"), { textContent: "Le fichier semble invalide, import impossible", colSpan: 3 })); // Créer la cellule pour l'erreur
                            readErrorTbody.appendChild(row); // Ajouter la ligne au tbody
                            reject("Fichier invalide");
                        }
                    });
                } catch (err) {
                    if (readError.classList.contains("opacity-0")) readError.classList.remove("opacity-0"); // Affichage des erreurs si elles existent et qu'elles ne sont pas affichées
                    if (readError.classList.contains("h-0px")) readError.classList.remove("h-0px"); // Affichage des erreurs si elles existent et qu'elles ne sont pas affichées
                    readErrorTbody.innerHTML = "<tr><td colspan='3'>Erreur d'origine inconnue</td></tr>"; // Affichage d'une ligne d'erreur d'origine inconnue
                    reject(err);
                }
            });
        }

        function importRecapFactureSend(importForms, importId) { // Fonction d'envoi de l'import
            // Préparation de la barre de progression
            const sendProgress = document.getElementById("comptabilite-facturation-progress-send"); // Initialisation de la barre de progression
            let sendProgressTotal = 0, sendProgressPrevious = 0;
            // Préparation du tableau des erreurs
            const sendError = document.getElementById("comptabilite-facturation-progress-send-error"); // Initialisation de l'élement contenant le tableau des erreurs
            const sendErrorTbody = sendError.querySelector("tbody"); // Initialisation de l'élement contenant le corps du tableau
            sendErrorTbody.innerHTML = ""; // Vide le corps du tableau
            const importFormIt = importForms[Symbol.iterator](); // Création de l'itérateur
            return new Promise((resolve, reject) => {
                try {
                    function sendRecapFacture(form, index = 0) {
                        form.append("import", importId);
                        $.ajax({
                            type: 'POST',
                            url: siteURL + 'admin/comptabilite/facturation/create',
                            contentType: false,
                            processData: false,
                            data: form,
                            success: function (data) {
                                // Calcul de la progression réelle ou la différence avec 100 pour la dernière progression
                                const sendProgressReal = index + 1 == importForms.length ? 100 - sendProgressPrevious : Math.round(((((index + 1) / importForms.length) * 100) - sendProgressPrevious) * 100) / 100;
                                // Mise à jour de la progression précédente et de la progression totale
                                sendProgressPrevious += sendProgressReal; sendProgressTotal += sendProgressReal;
                                if (data.success != true) {
                                    if (sendError.classList.contains("opacity-0")) sendError.classList.remove("opacity-0"); // Affichage des erreurs si elles ne sont pas affichées
                                    if (sendError.classList.contains("h-0px")) sendError.classList.remove("h-0px"); // Affichage des erreurs si elles ne sont pas affichées
                                    const row = document.createElement("tr"); // Créer une nouvelle ligne de tableau
                                    row.appendChild(Object.assign(document.createElement("td"), { textContent: form.get("noDossier") })); // Créer la cellule pour le noDossier
                                    row.appendChild(Object.assign(document.createElement("td"), { textContent: data.error.code == 0 ? "Inconnu" : importRef[data.error.message].label })); // Créer la cellule pour le nom de la colonne
                                    row.appendChild(Object.assign(document.createElement("td"), { textContent: "Erreur d'enregistrement" })); // Créer la cellule pour le message d'erreur
                                    sendErrorTbody.appendChild(row); // Ajouter la ligne au tbody
                                    toastr.error("L'enregistrement de la facture " + form.get("noDossier") + " a echoué", "Erreur");
                                }
                                // Mise à jour de la barre de progression
                                sendProgress.appendChild(createProgressBar(sendProgressReal + "%", data.success === true ? "success" : "danger"));
                            },
                            error: function () {
                                // Calcul de la progression réelle ou la différence avec 100 pour la dernière progression
                                const sendProgressReal = index + 1 == importForms.length ? 100 - sendProgressPrevious : Math.round(((((index + 1) / importForms.length) * 100) - sendProgressPrevious) * 100) / 100;
                                // Mise à jour de la progression précédente et de la progression totale
                                sendProgressPrevious += sendProgressReal; sendProgressTotal += sendProgressReal;
                                // Mise à jour de la barre de progression
                                sendProgress.appendChild(createProgressBar(sendProgressReal + "%", "danger"));
                            },
                            complete: function () {
                                const nextForm = importFormIt.next().value; // Passage à la prochaine requête
                                if (nextForm === undefined) resolve(); else sendRecapFacture(nextForm, index + 1); // Si la requête suivante existe, on passe à la requête suivante, sinon on resolve la promesse
                            }
                        });
                    }
                    const nextForm = importFormIt.next().value;
                    sendRecapFacture(nextForm);
                } catch (err) {
                    if (sendError.classList.contains("opacity-0")) sendError.classList.remove("opacity-0"); // Affichage des erreurs si elles existent et qu'elles ne sont pas affichées
                    if (sendError.classList.contains("h-0px")) sendError.classList.remove("h-0px"); // Affichage des erreurs si elles existent et qu'elles ne sont pas affichées
                    sendErrorTbody.innerHTML = "<tr><td colspan='3'>Erreur d'origine inconnue</td></tr>"; // Affichage d'une ligne d'erreur d'origine inconnue
                    reject(err);
                }
            });
        }

        function importRecapFactureFnpc(importForms, importId) { // Fonction d'envoi de l'import
            // Préparation de la barre de progression
            const fnpcProgress = document.getElementById("comptabilite-facturation-progress-fnpc"); // Initialisation de la barre de progression
            let fnpcProgressTotal = 0, fnpcProgressPrevious = 0;
            // Préparation du tableau des erreurs
            const fnpcError = document.getElementById("comptabilite-facturation-progress-fnpc-error"); // Initialisation de l'élement contenant le tableau des erreurs
            const fnpcErrorTbody = fnpcError.querySelector("tbody"); // Initialisation de l'élement contenant le corps du tableau
            fnpcErrorTbody.innerHTML = ""; // Vide le corps du tableau
            const importFormIt = importForms[Symbol.iterator](); // Création de l'itérateur
            return new Promise((resolve, reject) => {
                try {
                    function fnpcRecapFacture(form, index = 0) {
                        form.append("import", importId);
                        $.ajax({
                            type: 'POST',
                            url: siteURL + 'admin/comptabilite/facturation/neutralisation',
                            contentType: false,
                            processData: false,
                            data: form,
                            success: function (data) {
                                // Calcul de la progression réelle ou la différence avec 100 pour la dernière progression
                                const fnpcProgressReal = index + 1 == importForms.length ? 100 - fnpcProgressPrevious : Math.round(((((index + 1) / importForms.length) * 100) - fnpcProgressPrevious) * 100) / 100;
                                // Mise à jour de la progression précédente et de la progression totale
                                fnpcProgressPrevious += fnpcProgressReal; fnpcProgressTotal += fnpcProgressReal;
                                if (data.success != true) {
                                    if (fnpcError.classList.contains("opacity-0")) fnpcError.classList.remove("opacity-0"); // Affichage des erreurs si elles ne sont pas affichées
                                    if (fnpcError.classList.contains("h-0px")) fnpcError.classList.remove("h-0px"); // Affichage des erreurs si elles ne sont pas affichées
                                    const row = document.createElement("tr"); // Créer une nouvelle ligne de tableau
                                    row.appendChild(Object.assign(document.createElement("td"), { textContent: form.get("noDossier") })); // Créer la cellule pour le noDossier
                                    row.appendChild(Object.assign(document.createElement("td"), { textContent: data.error.code == 0 ? "Inconnu" : importRef[data.error.message].label })); // Créer la cellule pour le nom de la colonne
                                    row.appendChild(Object.assign(document.createElement("td"), { textContent: "Erreur d'enregistrement" })); // Créer la cellule pour le message d'erreur
                                    fnpcErrorTbody.appendChild(row); // Ajouter la ligne au tbody
                                    toastr.error("La neutralisation des FREs de la facture " + form.get("noDossier") + " a echoué", "Erreur");
                                }
                                // Mise à jour de la barre de progression
                                fnpcProgress.appendChild(createProgressBar(fnpcProgressReal + "%", data.success === true ? "success" : "danger"));
                            },
                            error: function () {
                                // Calcul de la progression réelle ou la différence avec 100 pour la dernière progression
                                const fnpcProgressReal = index + 1 == importForms.length ? 100 - fnpcProgressPrevious : Math.round(((((index + 1) / importForms.length) * 100) - fnpcProgressPrevious) * 100) / 100;
                                // Mise à jour de la progression précédente et de la progression totale
                                fnpcProgressPrevious += fnpcProgressReal; fnpcProgressTotal += fnpcProgressReal;
                                // Mise à jour de la barre de progression
                                fnpcProgress.appendChild(createProgressBar(fnpcProgressReal + "%", "danger"));
                            },
                            complete: function () {
                                const nextForm = importFormIt.next().value; // Passage à la prochaine requête
                                if (nextForm === undefined) resolve(); else fnpcRecapFacture(nextForm, index + 1); // Si la requête suivante existe, on passe à la requête suivante, sinon on resolve la promesse
                            }
                        });
                    }
                    const nextForm = importFormIt.next().value;
                    fnpcRecapFacture(nextForm);
                } catch (err) {
                    if (fnpcError.classList.contains("opacity-0")) fnpcError.classList.remove("opacity-0"); // Affichage des erreurs si elles existent et qu'elles ne sont pas affichées
                    if (fnpcError.classList.contains("h-0px")) fnpcError.classList.remove("h-0px"); // Affichage des erreurs si elles existent et qu'elles ne sont pas affichées
                    fnpcErrorTbody.innerHTML = "<tr><td colspan='3'>Erreur d'origine inconnue</td></tr>"; // Affichage d'une ligne d'erreur d'origine inconnue
                    reject(err);
                }
            });
        }

        function importRecapFactureLoad(maxDate, minDate, listNoDossier) { // Fonction de mise à jour du tableau
            // Préparation de la barre de progression
            const loadProgress = document.getElementById("comptabilite-facturation-progress-load"); // Initialisation de la barre de progression
            return new Promise((resolve, reject) => {
                try {
                    const form = new FormData(); // Création du formulaire
                    form.append("maxDate", maxDate); // Ajout de la date maximale
                    form.append("minDate", minDate); // Ajout de la date minimale
                    form.append("listNoDossier", listNoDossier); // Ajout de la liste des noDossier
                    $.ajax({
                        type: 'POST',
                        url: siteURL + 'admin/comptabilite/facturation/delete',
                        contentType: false,
                        processData: false,
                        data: form,
                        success: function (data) {
                            if (data.success != true) {
                                toastr.error("La suppression des ventes annulées entre " + timestampToFormatedDate(minDate) + " et " + timestampToFormatedDate(maxDate) + " a echoué", "Erreur");
                            }
                            // Mise à jour de la barre de progression
                            loadProgress.appendChild(createProgressBar("100%", data.success === true ? "success" : "danger"));
                        },
                        error: function () {
                            // Mise à jour de la barre de progression
                            loadProgress.appendChild(createProgressBar("100%", "danger"));
                        },
                        complete: function () {
                            resolve(); // Resolve la promesse
                        }
                    });
                } catch (err) {
                    reject(err);
                }
            });
        }

        /**
         * Import des ventes depuis Spider VO
         * @description FIN
         */

        // Fonction de reset des filtres
        function resetFilter() {
            $("#filtersfacturation-datecommande1").val($("#firstCommande").val());
            $("#filtersfacturation-datecommande2").val($("#lastCommande").val());
            $("#filtersfacturation-datefacture1").val("");
            $("#filtersfacturation-datefacture2").val("");
            $("#filtersfacturation-facture").val('all').selectpicker("refresh");
            $("#filtersfacturation-reglement").val('all').selectpicker("refresh");
            $("#filtersfacturation-livre").val('all').selectpicker("refresh");
            $("#filtersfacturation-finance").val('all').selectpicker("refresh");
            $("#filtersfacturation-emplacement").val('default').selectpicker("refresh");
            $("#filtersfacturation-entite").val('default').selectpicker("refresh");
            $("#filtersfacturation-vendeur").val('default').selectpicker("refresh");
            $("#filtersfacturation-client").val('all').selectpicker("refresh");
            $("#filtersfacturation-tva").val('default').selectpicker("refresh");
            $("#filtersfacturation-reprise").val('all').selectpicker("refresh");
            $("#filtersfacturation-jourstock").val('all').selectpicker("refresh");
            $("#filtersfacturation-erreur").val('all').selectpicker("refresh");
            $("#filtersfacturation-marque").val('default').selectpicker("refresh");
            $("#filtersfacturation-modele").find("optgroup").prop('disabled', true);
            $('#filtersfacturation-modele').prop("disabled", true);
            $("#filtersfacturation-modele").val('default').selectpicker("refresh");
            $("#filtersfacturation-energie").val('default').selectpicker("refresh");
            $("#filtersfacturation-boite").val('default').selectpicker("refresh");
        }

        $('body').on('click dblclick', "span[id^='comptabilite-facturation-etatReglement-']", function (e) {
            const regexId = /\d+/g;
            // Récupère l'id de la facturation
            let idFacturation = this.id.match(regexId)[0];
            const step = this.id.match(regexId)[1];
            let form = new FormData();
            form.append('id', idFacturation);
            form.append('etatReglement', step);
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/comptabilite/facturation/etatReglement',
                contentType: false,
                processData: false,
                data: form,
                success: function (etatReglement) {
                    if (etatReglement != "Error") {
                        toastr.success("L'état de règlement a bien été enregistré", "Succès");
                        $("#comptabilite-facturation-etatReglement-" + idFacturation).removeClass("btn-success").removeClass("btn-warning").removeClass("btn-danger").addClass("btn-" + (etatReglement == 2 ? "success" : (etatReglement == 1 ? "warning" : "danger")));
                        $("#comptabilite-facturation-etatReglement-" + idFacturation).html((etatReglement == 2 ? "Réglé" : (etatReglement == 1 ? "Partiel" : "Pas réglé")));
                        $("#comptabilite-facturation-dateReglement-" + idFacturation).prop("disabled", etatReglement == 2 ? false : true);
                        if (etatReglement == 2) {
                            const now = timestampToFormatedDate(Date.now()).replace(/(\d{2})\/(\d{2})\/(\d{4})/g, "$3-$2-$1");
                            $("#comptabilite-facturation-dateReglement-" + idFacturation).val(now);
                            $("#comptabilite-facturation-dateReglement-" + idFacturation).trigger("change");
                        }
                    } else {
                        toastr.error("Erreur lors de l'enregistrement", "Erreur");
                    }
                },
                error: function () {
                    toastr.error("Erreur lors de l'enregistrement", "Erreur");
                }
            });
        });

        $('body').on('change', "[id^='comptabilite-facturation-dateReglement-']", function (e) {
            const regexId = /\d+/g;
            // Récupère l'id de la facturation
            let idFacturation = this.id.match(regexId)[0];
            let form = new FormData();
            form.append('id', idFacturation);
            form.append('dateReglement', $("#comptabilite-facturation-dateReglement-" + idFacturation).val());
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/comptabilite/facturation/dateReglement',
                contentType: false,
                processData: false,
                data: form,
                success: function (dateReglement) {
                    if (dateReglement != "Error") {
                        toastr.success("La date de règlement a bien été enregistré", "Succès");
                    } else {
                        toastr.error("Erreur lors de l'enregistrement", "Erreur");
                    }
                },
                error: function () {
                    toastr.error("Erreur lors de l'enregistrement", "Erreur");
                }
            });
        });

        ["commentaire", "commentaireReglement"].forEach((type) => {
            // Ajout d'un horodatage lors de l'ouverture du champ de commentaire
            $('body').on('click', "[id^=comptabilite-facturation-" + type + "-btn-]", function () {
                const idVehicule = this.id.replace("comptabilite-facturation-" + type + "-btn-", "");
                const today = new Date();
                const dd = String(today.getDate()).padStart(2, '0');
                const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
                const yyyy = today.getFullYear();
                const hh = String(today.getHours()).padStart(2, '0');
                const ii = String(today.getMinutes()).padStart(2, '0');
                const now = 'Le ' + mm + '/' + dd + '/' + yyyy + ' à ' + hh + ':' + ii;
                const commentaire = $('#comptabilite-facturation-' + type + '-textarea-' + idVehicule).data(type.toLowerCase()).trim();
                $('#comptabilite-facturation-' + type + '-textarea-' + idVehicule).val(commentaire + (commentaire.length > 0 ? "\n\n" : "") + now + "\n\n");
            });

            // Enregistrement du champ commentaire
            $('body').on('click', "[id^=comptabilite-facturation-" + type + "-save-]", function () {
                let idFacturation = this.id.replace("comptabilite-facturation-" + type + "-save-", "");
                let form = new FormData();
                form.append('id', idFacturation);
                form.append(type, $("#comptabilite-facturation-" + type + "-textarea-" + idFacturation).val());
                $.ajax({
                    type: 'POST',
                    url: siteURL + 'admin/comptabilite/facturation/' + type,
                    contentType: false,
                    processData: false,
                    data: form,
                    success: function (commentaire) {
                        if (commentaire != "Error") {
                            toastr.success("Le commentaire a bien été enregistré", "Succès");
                            $("#comptabilite-facturation-" + type + "-textarea-" + idFacturation).val("");
                            $("#comptabilite-facturation-" + type + "-textarea-" + idFacturation).data(type.toLowerCase(), commentaire);
                            $("#comptabilite-facturation-" + type + "-modal-" + idFacturation).modal("hide");
                            if (commentaire.length) { $("[id^=comptabilite-facturation-" + type + "-indicator-" + idFacturation + "]").removeClass("d-none"); }
                            else { $("[id^=comptabilite-facturation-" + type + "-indicator-" + idFacturation + "]").addClass("d-none"); }
                            $('[id^=comptabilite-facturation-' + type + '-btn-' + idFacturation + "]").parent().find(".adminTableTooltip").html(commentaire.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br />$2'));
                            if (comptabiliteFacturationTable != null) { comptabiliteFacturationTable.rows().invalidate(); }
                        } else {
                            toastr.error("Erreur lors de l'enregistrement", "Erreur");
                        }
                    },
                    error: function () {
                        toastr.error("Erreur lors de l'enregistrement", "Erreur");
                    }
                });
            });
        });

        // Édition montantGarantie et transportAchat
        const typeChampEditable = ["montantGarantie", "transportAchat"];
        $('body').on('click dblclick', "[id^='comptabilite-facturation-edit-']", function (e) {
            const regexId = /\d+/g;
            // Récupère l'id de la facturation
            let idFacturation = this.id.match(regexId)[0];
            // Extrait le type de champ
            let typeChamp = this.id.replace("comptabilite-facturation-edit-", "").replace("-" + idFacturation, "");
            // Si le type de champ fait parti des types de champ
            if (typeChampEditable.includes(typeChamp)) {
                // Deviens un input modifiable
                let originalContent = $(this).text().match(regexId);
                $(this).parent().html("<input type='number' size='10' min='0' step='100' id='comptabilite-facturation-input-" + typeChamp + "-" + idFacturation + "' value='" + (originalContent != null ? originalContent.join("") : "") + "' />");
                $("#comptabilite-facturation-input-" + typeChamp + "-" + idFacturation).trigger("focus");
            }
        });
        $('body').on('click dblclick', "[id^='comptabilite-facturation-input-']", function (e) { e.stopPropagation(); });
        $('body').on('keyup blur', "[id^='comptabilite-facturation-input-']", function (e) {
            const regexId = /\d+/g;
            // Récupère l'id de la facturation
            let idFacturation = this.id.match(regexId)[0];
            // Extrait le type de champ
            let typeChamp = this.id.replace("comptabilite-facturation-input-", "").replace("-" + idFacturation, "");
            // Si le type de champ fait parti des types de champ
            if (typeChampEditable.includes(typeChamp)) {
                // Si pas de key (donc sorti du champ) ou touche "Entré"
                if (e.key === undefined || e.key == "Enter") {
                    // Enregistre la nouvelle valeur
                    let form = new FormData();
                    form.append('id', idFacturation);
                    form.append(typeChamp, $(this).val());
                    $.ajax({
                        type: 'POST',
                        url: siteURL + 'admin/comptabilite/facturation/' + typeChamp,
                        contentType: false,
                        processData: false,
                        data: form,
                        success: function (commentaire) {
                            if (commentaire != "Error") {
                                toastr.success("La nouvelle valeur a bien été enregistré", "Succès");
                            } else {
                                toastr.error("Erreur lors de l'enregistrement", "Erreur");
                            }
                        },
                        error: function () {
                            toastr.error("Erreur lors de l'enregistrement", "Erreur");
                        }
                    });
                    // Deviens un bouton non modifiable
                    let newBtnStr = "<button type='button' id='comptabilite-facturation-edit-" + typeChamp + "-" + idFacturation + "' class='btn btn-outline-dark btn-sm text-11'>" + (parseFloat($(this).val()) ? $(this).val().replace(/(?=(\d\d\d)+(?!\d))/g, " ").trim() + " €" : "Éditer <i class='fal fa-pen text-10'></i>") + "</button>";
                    $(this).parent().html(newBtnStr);
                    $("#comptabilite-facturation-parent-" + typeChamp + "-" + idFacturation).html(newBtnStr);
                    $("#comptabilite-facturation-parent-" + typeChamp + "-" + idFacturation)[0].dataset.order = $(this).val();
                    if (comptabiliteFacturationTable != null) { comptabiliteFacturationTable.rows().invalidate(); }
                }
            }
        });

        ["emplacement", "entite"].forEach(field => { // Création d'évenements sur les selects "emplacement" et "entite"
            $('body').on('change', "[id^='comptabilite-facturation-select-" + field + "']", function (e) {
                this.parentElement.setAttribute("data-order", $(this).val()); // Mise à jour de l'ordre Datatable de la cellule
                let form = new FormData();
                form.append('id', this.id.replace("comptabilite-facturation-select-" + field + "-", ""));
                form.append(field, $(this).val());
                $.ajax({
                    type: 'POST',
                    url: siteURL + 'admin/comptabilite/facturation/' + field, // Enregistre la nouvelle valeur
                    contentType: false,
                    processData: false,
                    data: form,
                    success: function () { toastr.success("La nouvelle valeur a bien été enregistré", "Succès"); },
                    error: function () { toastr.error("Erreur lors de l'enregistrement", "Erreur"); }
                });
            });
        });

        $('body').on('click', "#comptabilite-facturation-graph-compare", function (e) {
            const compare = facturationGraphCompare();
            const form = getParams();
            if (compare) {
                $.ajax({
                    type: 'POST',
                    url: siteURL + 'admin/stock/stock_graph',
                    contentType: false,
                    processData: false,
                    data: form,
                    success: function (datas) {
                        facturationGraphInit(form, datas);
                    }
                });
            } else {
                facturationGraphInit(form);
            }
        });

        const goToRecapFacture = $("#comptabilite-facturation-results").data("gotorecapfacture");
        if (goToRecapFacture != "") {
            loadAjax({ goToRecapFacture: goToRecapFacture });
            $("#comptabilite-facturation-results").data("gotorecapfacture", "");
        } else {
            filtreConfigFunOnEvent();
        }
    }
});

function facturationGraphCompare(compare = null) {
    const compareBtn = $("#comptabilite-facturation-graph-compare");
    if (compare == null) { compare = compareBtn.attr("data-compare") != "true"; }
    compareBtn.attr("data-compare", compare);
    compareBtn.addClass(compare ? "btn-outline-primary" : "btn-primary");
    compareBtn.removeClass(compare ? "btn-primary" : "btn-outline-primary");
    return compare;
}
function facturationGraphInit(form, compareDatas = null) {
    $.ajax({
        type: 'POST',
        url: siteURL + 'admin/comptabilite/facturation/loadGraph',
        contentType: false,
        processData: false,
        data: form,
        success: function (datas) {
            const doughnutColor = ['#f56954', '#00a65a', '#f39c12', '#00c0ef', '#3c8dbc', '#d2d6de', '#dd8888', '#88dddd', '#88dd88', '#dd88dd', '#dddd88', '#8888dd', '#b3ff66'];
            const doughnutConfig = {
                baseId: "DonutChart", type: "doughnut", unit: "%",
                options: {
                    maintainAspectRatio: false,
                    responsive: true,
                    tooltips: { callbacks: { label: function (tooltipItem, data) { return data.labels[tooltipItem.index].match(/.+[ ]/g) + ": " + data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] + "%"; }, title: function () { return ""; } } }
                }, data: { labels: [], datasets: [{ data: [], backgroundColor: doughnutColor }, { data: [], backgroundColor: doughnutColor.map(c => c + "7F") }] }
            };
            const barConfig = {
                baseId: "BarChart", type: "bar", unit: "",
                options: {
                    maintainAspectRatio: false,
                    responsive: true,
                    scales: { yAxes: [{ ticks: { beginAtZero: true } }] },
                    legend: { display: false },
                    tooltips: { callbacks: { label: function (tooltipItem, data) { return data.labels[tooltipItem.index].match(/.+[ ]/g) + ": " + data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]; }, title: function () { return ""; } } }
                }, data: { labels: [], datasets: [{ data: [] }, { data: [], backgroundColor: '#CFE2FF' }] }
            };
            [doughnutConfig, barConfig].forEach(config => {
                Object.keys(datas).forEach(key => {
                    config.data.datasets[0].data = [], config.data.datasets[1].data = [], config.data.labels = [];
                    const hasCompareData = compareDatas != null && typeof compareDatas[key] !== "undefined";
                    if (hasCompareData) {
                        compareDatas[key].data.forEach((v, i) => {
                            i = datas[key].label.findIndex(l => l == compareDatas[key].label[i]);
                            if (i != -1) {
                                config.data.datasets[1].data[i] = config.unit == '%' ? Math.round((v / compareDatas[key].data.reduce((a, v) => a + v, 0)) * 100) : v;
                            }
                        });
                    }
                    datas[key].data.forEach((v, i) => { config.data.datasets[0].data[i] = config.unit == '%' ? Math.round((v / datas[key].data.reduce((a, v) => a + v, 0)) * 100) : v; });
                    datas[key].label.forEach((l, i) => { config.data.labels[i] = (l.toString().length ? l : "Inconnu") + " (" + config.data.datasets[0].data[i] + config.unit + ")"; });
                    document.getElementById(key + config.baseId).replaceWith(document.getElementById(key + config.baseId).cloneNode(true)); // Remise à zero du graphique
                    new Chart(document.getElementById(key + config.baseId).getContext('2d'), {
                        type: config.type,
                        options: config.options,
                        data: {
                            labels: config.data.labels, datasets: !hasCompareData
                                ? [{ data: config.data.datasets[0].data, backgroundColor: config.data.datasets[0].backgroundColor }]
                                : [{ data: config.data.datasets[0].data, backgroundColor: config.data.datasets[0].backgroundColor }, { data: config.data.datasets[1].data, backgroundColor: config.data.datasets[1].backgroundColor }]
                        }
                    });
                });
            });
        }
    });
}