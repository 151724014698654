let vehicules_listingpro_table = null;
let timerBeforeRefreshData = null;
let selectedListingproCache = [];

$(document).ready(function() {
    if ($("#listingpro_results").length) {

        function loadAjax() {
            if (vehicules_listingpro_table != null) {
                vehicules_listingpro_table.rows().invalidate();
                let all_data = vehicules_listingpro_table.rows().data();
                id_vehicules = [];
                $.each(all_data, function(key, value) {
                    if (value[0].includes("vehicule_listingpro_selection_") && value[0].includes("fa-check-square")) {
                        regex = new RegExp(/id="(.*?)"/g);
                        id = value[0].match(regex);
                        if (id == null) {
                            regex = new RegExp(/id='(.*?)'/g);
                            id = value[0].match(regex);
                        }
                        temp = id[0].split("_");
                        id_vehicules.push(temp[temp.length - 1].slice(0, -1));
                    }
                });
                for (let index = 0; index < id_vehicules.length; index++) {
                    selectedListingproCache.push(id_vehicules[index]);
                }
                selectedListingproCache = selectedListingproCache.filter((value, index, array) => array.indexOf(value) === index);
            }
            let optionsRecherche = new FormData();
            optionsRecherche.append('identifiant', $("#filterslistingpro-identifiant").val());
            optionsRecherche.append('parcs', $("#filterslistingpro-parc").val());
            optionsRecherche.append('emplacements', $("#filterslistingpro-emplacement").val());
            optionsRecherche.append('marques', $("#filterslistingpro-marque").val());
            optionsRecherche.append('modeles', $("#filterslistingpro-modele").val());
            optionsRecherche.append('carrosserie', $("#filterslistingpro-carrosserie").val());
            optionsRecherche.append('jourstock', $("#filterslistingpro-jourstock").val());
            optionsRecherche.append('energie', $("#filterslistingpro-energie").val());
            optionsRecherche.append('boite', $("#filterslistingpro-boite").val());
            optionsRecherche.append('prixremise', $("#filterslistingpro-prixremise").val());
            $("#listingpro_results").addClass("d-none");
            $.ajax({
                type:'POST',
                url: siteURL + 'admin/outils/listingpro/loadResults',
                contentType: false,
                processData: false,
                data: optionsRecherche,
                success: function(data) {
                    $("#listingpro_results").html(data);
                    for (let index = 0; index < selectedListingproCache.length; index++) {
                        $("#vehicule_listingpro_selection_"+selectedListingproCache[index]).parent().html('<span class="d-none">true</span><i class="far fa-check-square fa-2x cursor-pointer" id="vehicule_listingpro_selection_'+selectedListingproCache[index]+'"></i>');
                    }
                }
            })
        };

        $('body').on('keyup', "[id^='filterslistingpro-identifiant']", function() {
            clearTimeout(timerBeforeRefreshData);
            timerBeforeRefreshData = setTimeout(loadAjax, 800);
        });

        $('body').on('change', "[id^='filterslistingpro-']", function() {
            clearTimeout(timerBeforeRefreshData);
            timerBeforeRefreshData = setTimeout(loadAjax, 800);
        });

        // Bouton reset
        $('body').on('click', '#vehicules_listingpro-reset', function() {
            $("#filterslistingpro-identifiant").val('').selectpicker("refresh");
            $("#filterslistingpro-parc").val('default').selectpicker("refresh");
            $("#filterslistingpro-emplacement").val('default').selectpicker("refresh");
            $("#filterslistingpro-marque").val('default').selectpicker("refresh");
            $("#filterslistingpro-modele").find("optgroup").prop('disabled', true);
            $('#filterslistingpro-modele').prop("disabled", true);
            $("#filterslistingpro-modele").val('default').selectpicker('refresh');
            $("#filterslistingpro-carrosserie").val('default').selectpicker("refresh");
            $("#filterslistingpro-jourstock").val('default').selectpicker("refresh");
            $("#filterslistingpro-energie").val('default').selectpicker("refresh");
            $("#filterslistingpro-boite").val('default').selectpicker("refresh");
            $("#filterslistingpro-prixremise").val('null').selectpicker("refresh");
            loadAjax();
        });

        $('body').on('click', "[id^='vehicules_listingpro_export_']", function() {
            if (vehicules_listingpro_table != null) {
                exporttype = this.id.replace("vehicules_listingpro_export_", "");
                vehicules_listingpro_table.rows().invalidate();
                let all_data = vehicules_listingpro_table.rows().data();
                id_vehicules = [];
                $.each(all_data, function(key, value) {
                    if (value[0].includes("vehicule_listingpro_selection_") && value[0].includes("fa-check-square")) {
                        regex = new RegExp(/id="(.*?)"/g);
                        id = value[0].match(regex);
                        if (id == null) {
                            regex = new RegExp(/id='(.*?)'/g);
                            id = value[0].match(regex);
                        }
                        temp = id[0].split("_");
                        id_vehicules.push(temp[temp.length - 1].slice(0, -1));
                    }
                });

                // Si aucun véhicule est rentré
                if (id_vehicules.length == 0) {
                    toastr.error('Merci de sélectionner au moins un véhicule', 'Erreur');
                    return false;
                }

                var form = new FormData();
                form.append('ids', id_vehicules);
                today = new Date();

                switch (exporttype) {
                    case 'excel':
                        $.ajax({
                            type: 'POST',
                            url: siteURL + 'admin/outils/listingpro/ficheExcel',
                            contentType: false,
                            processData: false,
                            data: form,
                            success: function(data) {
                                toastr.success('Le fichier excel est fini', 'Succès');
                                var element = document.createElement('a');
                                element.setAttribute('href',siteURL + "assets/docs/listing_pro/results.xlsx");
                                element.setAttribute('download', "Liste Vehicule Ora7 "+today.toLocaleDateString("fr")+".xlsx");
                                document.body.appendChild(element);
                                element.click();
                                document.body.removeChild(element);
                            }
                        });
                        break;
                    case 'pdf':
                        $.ajax({
                            type: 'POST',
                            url: siteURL + 'admin/outils/listingpro/fichePDF',
                            contentType: false,
                            processData: false,
                            data: form,
                            success: function(data) {
                                toastr.success('Le fichier pdf est fini', 'Succès');
                                var element = document.createElement('a');
                                element.setAttribute('href',siteURL + "assets/docs/listing_pro/results.pdf");
                                element.setAttribute('download', "Liste Vehicule Ora7 "+today.toLocaleDateString("fr")+".pdf");
                                document.body.appendChild(element);
                                element.click();
                                document.body.removeChild(element);
                            }
                        });
                        break;
                    case 'excel_prix_mini':
                        $.ajax({
                            type: 'POST',
                            url: siteURL + 'admin/outils/listingpro/ficheExcelPrixMini',
                            contentType: false,
                            processData: false,
                            data: form,
                            success: function(data) {
                                toastr.success('Le fichier excel est fini', 'Succès');
                                var element = document.createElement('a');
                                element.setAttribute('href',siteURL + "assets/docs/listing_pro/results_prix_mini.xlsx");
                                element.setAttribute('download', "Liste Vehicule Ora7 "+today.toLocaleDateString("fr")+".xlsx");
                                document.body.appendChild(element);
                                element.click();
                                document.body.removeChild(element);
                            }
                        });
                        break;
                    case 'pdf_prix_mini':
                        $.ajax({
                            type: 'POST',
                            url: siteURL + 'admin/outils/listingpro/fichePDFPrixMini',
                            contentType: false,
                            processData: false,
                            data: form,
                            success: function(data) {
                                toastr.success('Le fichier pdf est fini', 'Succès');
                                var element = document.createElement('a');
                                element.setAttribute('href',siteURL + "assets/docs/listing_pro/results_prix_mini.pdf");
                                element.setAttribute('download', "Liste Vehicule Ora7 "+today.toLocaleDateString("fr")+".pdf");
                                document.body.appendChild(element);
                                element.click();
                                document.body.removeChild(element);
                            }
                        });
                        break;
                    case 'excel_custom':
                        $.ajax({
                            type: 'POST',
                            url: siteURL + 'admin/outils/listingpro/ficheExcelCustom',
                            contentType: false,
                            processData: false,
                            data: form,
                            success: function(data) {
                                toastr.success('Le fichier excel est fini', 'Succès');
                                var element = document.createElement('a');
                                element.setAttribute('href',siteURL + "assets/docs/listing_pro/results_custom.xlsx");
                                element.setAttribute('download', "liste_vehicule_ora7_personnalisee.xlsx");
                                document.body.appendChild(element);
                                element.click();
                                document.body.removeChild(element);
                            }
                        });
                        break;
                    case 'pdf_custom':
                        $.ajax({
                            type: 'POST',
                            url: siteURL + 'admin/outils/listingpro/fichePDFCustom',
                            contentType: false,
                            processData: false,
                            data: form,
                            success: function(data) {
                                toastr.success('Le fichier pdf est fini', 'Succès');
                                var element = document.createElement('a');
                                element.setAttribute('href',siteURL + "assets/docs/listing_pro/results_custom.pdf");
                                element.setAttribute('download', "liste_vehicule_ora7_personnalisee.pdf");
                                document.body.appendChild(element);
                                element.click();
                                document.body.removeChild(element);
                            }
                        });
                        break;
                    default:
                        break;
                }
                return true;
            }
        });

        $('body').on('click', "#button-listing-proposition-commercial", function() {
            if (vehicules_listingpro_table != null) {
                vehicules_listingpro_table.rows().invalidate();
                let all_data = vehicules_listingpro_table.rows().data();
                id_vehicules = [];
                $.each(all_data, function(key, value) {
                    if (value[0].includes("vehicule_listingpro_selection_") && value[0].includes("fa-check-square")) {
                        regex = new RegExp(/id="(.*?)"/g);
                        id = value[0].match(regex);
                        if (id == null) {
                            regex = new RegExp(/id='(.*?)'/g);
                            id = value[0].match(regex);
                        }
                        temp = id[0].split("_");
                        id_vehicules.push(temp[temp.length - 1].slice(0, -1));
                    }
                });
                // Si aucun véhicule est rentré
                if (id_vehicules.length == 0) {
                    toastr.error('Merci de sélectionner au moins un véhicule', 'Erreur');
                    return false;
                }
                if (id_vehicules.length > 30) {
                    toastr.error('Merci de renseigner moins de 30 véhicules', 'Erreur');
                    return false;
                }
                Lobibox.window({
                    width: 1500,
                    title: 'Liste vehicule prix remisé',
                    baseClass: "modal-content modal-lobistrap",
                    url: siteURL+'admin/outils/listingpro/propositionCommercial',
                    params: {'ids': id_vehicules},
                    showAfterLoad: false,
                });
                return false;
            }
        });

        $('body').on('change', "#filterslistingpro-marque", function() {
            $("#filterslistingpro-modele").find("optgroup").prop('disabled', true);
            if($('#filterslistingpro-marque').val().length != 0) {
                $("#filterslistingpro-modele").prop('disabled', false);
                $('#filterslistingpro-marque').val().forEach(element => {
                    $("[id='opt_"+element+"']").prop('disabled', false);
                });
                $("#filterslistingpro-modele").selectpicker('refresh');
            } else {
                $('#filterslistingpro-modele').prop("disabled", true);
                $("#filterslistingpro-modele").val('default').selectpicker('refresh');
            }
        });

        $('body').on('click', "[id^='chevron-equipement-']", function() {
            type = this.id.replace("chevron-equipement-", "");
            if (type == "more") {
                $(this).css("display", "none");
                $(this).closest(".equipement-and-option").find(".equipement-truncate").css('display', 'none');

                $(this).closest(".equipement-and-option").find("#chevron-equipement-less").css('display', 'inline');
                $(this).closest(".equipement-and-option").find(".equipement-full").css('display', 'inline');
            } else {
                $(this).closest(".equipement-and-option").find("#chevron-equipement-more").css('display', 'inline');
                $(this).closest(".equipement-and-option").find(".equipement-truncate").css('display', 'inline');

                $(this).css("display", "none");
                $(this).closest(".equipement-and-option").find(".equipement-full").css('display', 'none');
            }
        });

        // Reservation véhcule
        $('body').on('click', '[id^="vehicule_listingpro_reservation_"]', function() {
            clickedId = this.id.replace("vehicule_listingpro_reservation_", "");
            if ($(this).hasClass('fa-check-square')) { var reservationEtat = 0;}
            else { var reservationEtat = 1;}

            button_reservation = $(this);

            var form = new FormData();
            form.append('id', clickedId);
            form.append('reservation', reservationEtat);
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/outils/listingpro/reservation',
                contentType: false,
                processData: false,
                data: form,
                success: function(data) {
                    if (data == 0) {
                        toastr.error('Le véhicule n\'est maintenant plus indiqué comme réservé', 'Succès');
                        // button_reservation.parent().parent().css("background-color", "white");
                    }
                    else if (data == 1) {
                        toastr.success('Le véhicule est maintenant indiqué comme réservé', 'Succès');
                        // button_reservation.parent().parent().css("background-color", "#61b854");
                    }
                }
            });

            if ($(this).closest('td').find("span").text() == "true") {
                $(this).parent().parent().css("background-color", "white");
                $(this).parent().html('<span class="d-none">false</span><i class="far fa-2x cursor-pointer fa-square" id="vehicule_listingpro_reservation_'+clickedId+'"></i>');
            } else {
                $(this).parent().parent().css("background-color", "#61b854");
                $(this).parent().html('<span class="d-none">true</span><i class="far fa-2x cursor-pointer fa-check-square" id="vehicule_listingpro_reservation_'+clickedId+'"></i>');
            }
        });

        // Selection d'un véhicule
        $('body').on('click', "[id^='vehicule_listingpro_selection_']", function(e) {
            clickedId = this.id.replace("vehicule_listingpro_selection_", "");
            // Permet de rajouter la nouvelle value dans le DataTable
            if ($(this).hasClass("fa-check-square")) {
                $(this).parent().html('<span class="d-none">false</span><i class="far fa-square fa-2x cursor-pointer" id="vehicule_listingpro_selection_'+clickedId+'"></i>');
            } else {
                $(this).parent().html('<span class="d-none">true</span><i class="far fa-check-square fa-2x cursor-pointer" id="vehicule_listingpro_selection_'+clickedId+'"></i>');
            }
        });

        // Bouton gestion des demandes
        $('body').on('click dblclick', "[id^='inputGroup-ajout-demande-']", function(e) {
            let idRegex = e.target.id.match(/\d+/g);
            if (idRegex == null) {
                idRegex = e.currentTarget.id.match(/\d+/g);
            }
            if (idRegex != null) {
                let idVehicule = idRegex[0];
                let nomClient = $("#inputGroup-input-demande-"+idVehicule).val();
                if (nomClient != "") {
                    $("#inputGroup-ajout-demande-"+idVehicule).attr("disabled", true);
                    $("#inputGroup-ajout-demande-"+idVehicule).html("<i class=\"fas fa-sync-alt\"></i>");
                    var form = new FormData();
                    form.append('id', idVehicule);
                    form.append('client', nomClient);
                    $.ajax({
                        type: 'POST',
                        url: siteURL + 'admin/outils/listingpro/reservationSurVendu',
                        contentType: false,
                        processData: false,
                        data: form,
                        success: function(idDemande) {
                            $("#list-demande-client-"+idVehicule).append("<div class=\"text-left mb-1\" style=\"font-size: small;\"><button class=\"btn btn-danger btn-sm mr-2\" type=\"button\" id=\"delete-demande-"+ idVehicule +"-"+ idDemande +"\"><i class=\"fas fa-times\"></i></button> "+ $(".sidebar .user-panel .info a").text() +" - client : "+ nomClient +"</div>");
                            updateInfoBulle(idVehicule);
                            $("#inputGroup-ajout-demande-"+idVehicule).attr("disabled", false);
                            $("#inputGroup-ajout-demande-"+idVehicule).html("Ajouter <i class=\"fas fa-plus\"></i>");
                            toastr.success('Vous avez indiqué une demande pour le véhicule vendu n° '+ idVehicule +'.', 'Succès');
                        }
                    });
                }
            }
        });

        // Bouton gestion des demandes
        $('body').on('click dblclick', "[id^='delete-demande-']", function(e) {
            let idRegex = e.target.id.match(/\d+/g);
            if (idRegex == null) {
                idRegex = e.currentTarget.id.match(/\d+/g);
            }
            if (idRegex != null) {
                let idVehicule = idRegex[0];
                let idDemande = idRegex[1];
                $("#delete-demande-"+ idVehicule +"-"+ idDemande).attr("disabled", true);
                $("#delete-demande-"+ idVehicule +"-"+ idDemande).html("<i class=\"fas fa-sync-alt\"></i>");
                var form = new FormData();
                form.append('id', idVehicule);
                form.append('idDemande', idDemande);
                $.ajax({
                    type: 'POST',
                    url: siteURL + 'admin/outils/listingpro/deleteReservationSurVendu',
                    contentType: false,
                    processData: false,
                    data: form,
                    success: function(data) {
                        $("#delete-demande-"+ idVehicule +"-"+ idDemande).parent().remove();
                        updateInfoBulle(idVehicule);
                        if (data) {
                            toastr.success('Vous avez retiré la demande pour le véhicule vendu n° '+ idVehicule +'.', 'Succès');
                        } else {
                            toastr.error('Erreur, la demande pour le véhicule vendu n° '+ idVehicule +' avait déjà été rétirée.', 'Erreur');
                        }
                    }
                });
            }
        });

        // Bouton édition prix minimum marchand
        $('body').on('click dblclick', "[id^='edit_prix_mini_marchand_']", function(e) {
            let regexPrixMiniMarchand = /\d+/g;
            let idVehicule = $(this)[0].id.match(regexPrixMiniMarchand)[0];
            let OriginalContent = $(this).text();
            let OriginalContentMatched = OriginalContent.match(regexPrixMiniMarchand);
            if (OriginalContentMatched != null) {
                $(this).parent().html("<input type='text' id='input_prix_mini_marchand_"+idVehicule+"' value='"+OriginalContentMatched.join("")+"' />");
            } else {
                $(this).parent().html("<input type='text' id='input_prix_mini_marchand_"+idVehicule+"' value='' />");
            }
            $("#input_prix_mini_marchand_"+idVehicule).trigger("focus");
            $("#input_prix_mini_marchand_"+idVehicule).on("keyup", function(e) {
                let prix_mini_marchand = setPrixMiniMarchand($(this), regexPrixMiniMarchand, idVehicule);
                if (e.key == "Enter") {
                    if (prix_mini_marchand == 0) {
                        $("#prix_mini_marchand_"+idVehicule).html("<button type='button' id='edit_prix_mini_marchand_"+idVehicule+"' class='btn btn-outline-dark btn-sm'>Éditer <i class='fas fa-pen'></i></button>");
                        $("#prix_mini_marchand_"+idVehicule)[0].dataset.order = 0;
                        if (vehicules_listingpro_table != null) {vehicules_listingpro_table.rows().invalidate();}
                        $("#listingpro_button_infobulle_"+idVehicule).addClass("d-none");
                    } else {
                        $("#prix_mini_marchand_"+idVehicule).html("<button type='button' id='edit_prix_mini_marchand_"+idVehicule+"' class='btn btn-outline-dark btn-sm'>" + prix_mini_marchand.replace(/(?=(\d\d\d)+(?!\d))/g, " ").trim() + " €</button>");
                        $("#prix_mini_marchand_"+idVehicule)[0].dataset.order = prix_mini_marchand;
                        if (vehicules_listingpro_table != null) {vehicules_listingpro_table.rows().invalidate();}
                        $("#listingpro_infobulle_"+idVehicule).text("Prix min marchand : " + prix_mini_marchand.replace(/(?=(\d\d\d)+(?!\d))/g, " ").trim() + " €");
                        $("#listingpro_button_infobulle_"+idVehicule).removeClass("d-none");
                    }
                }
            });
            $("#input_prix_mini_marchand_"+idVehicule).on("blur", function() {
                let prix_mini_marchand = setPrixMiniMarchand($(this), regexPrixMiniMarchand, idVehicule);
                if (prix_mini_marchand == 0) {
                    $("#prix_mini_marchand_"+idVehicule).html("<button type='button' id='edit_prix_mini_marchand_"+idVehicule+"' class='btn btn-outline-dark btn-sm'>Éditer <i class='fas fa-pen'></i></button>");
                    $("#prix_mini_marchand_"+idVehicule)[0].dataset.order = 0;
                    if (vehicules_listingpro_table != null) {vehicules_listingpro_table.rows().invalidate();}
                    $("#listingpro_button_infobulle_"+idVehicule).addClass("d-none");
                } else {
                    $("#prix_mini_marchand_"+idVehicule).html("<button type='button' id='edit_prix_mini_marchand_"+idVehicule+"' class='btn btn-outline-dark btn-sm'>" + prix_mini_marchand.replace(/(?=(\d\d\d)+(?!\d))/g, " ").trim() + " €</button>");
                    $("#prix_mini_marchand_"+idVehicule)[0].dataset.order = prix_mini_marchand;
                    if (vehicules_listingpro_table != null) {vehicules_listingpro_table.rows().invalidate();}
                    $("#listingpro_infobulle_"+idVehicule).text("Prix min marchand : " + prix_mini_marchand.replace(/(?=(\d\d\d)+(?!\d))/g, " ").trim() + " €");
                    $("#listingpro_button_infobulle_"+idVehicule).removeClass("d-none");
                }
            });
            $("#input_prix_mini_marchand_"+idVehicule).click(function(e) {
                e.stopPropagation();
            });
        });

        // Bouton édition prix marchand remisé
        $('body').on('click dblclick', "[id^='edit_prix_remise_marchand_']", function(e) {
            let regexPrixRemiseMarchand = /\d+/g;
            let idVehicule = $(this)[0].id.match(regexPrixRemiseMarchand)[0];
            let OriginalContent = $(this).text();
            let OriginalContentMatched = OriginalContent.match(regexPrixRemiseMarchand);
            if (OriginalContentMatched != null) {
                $(this).parent().html("<input type='text' id='input_prix_remise_marchand_"+idVehicule+"' value='"+OriginalContentMatched.join("")+"' />");
            } else {
                $(this).parent().html("<input type='text' id='input_prix_remise_marchand_"+idVehicule+"' value='' />");
            }
            $("#input_prix_remise_marchand_"+idVehicule).trigger("focus");
            $("#input_prix_remise_marchand_"+idVehicule).on("keyup", function(e) {
                let prix_remise_marchand = setPrixMarchandRemise($(this), regexPrixRemiseMarchand, idVehicule);
                if (e.key == "Enter") {
                    if (prix_remise_marchand == 0) {
                        $("#prix_remise_marchand_"+idVehicule).html("<button type='button' id='edit_prix_remise_marchand_"+idVehicule+"' class='btn btn-outline-dark btn-sm'>Éditer <i class='fas fa-pen'></i></button>");
                        $("#prix_remise_marchand_"+idVehicule)[0].dataset.order = 0;
                        if (vehicules_listingpro_table != null) {vehicules_listingpro_table.rows().invalidate();}
                        $("#prix_marchand_remise_"+idVehicule).addClass("d-none");
                        $("#prix_marchand_"+idVehicule).removeClass("prix-barre");
                        updateMargeHT(idVehicule);
                    } else {
                        $("#prix_remise_marchand_"+idVehicule).html("<button type='button' id='edit_prix_remise_marchand_"+idVehicule+"' class='btn btn-outline-dark btn-sm'>" + prix_remise_marchand.replace(/(?=(\d\d\d)+(?!\d))/g, " ").trim() + " €</button>");
                        $("#prix_remise_marchand_"+idVehicule)[0].dataset.order = prix_remise_marchand;
                        if (vehicules_listingpro_table != null) {vehicules_listingpro_table.rows().invalidate();}
                        $("#prix_marchand_remise_"+idVehicule).text(prix_remise_marchand.replace(/(?=(\d\d\d)+(?!\d))/g, " ").trim() + " €");
                        $("#prix_marchand_remise_"+idVehicule).removeClass("d-none");
                        $("#prix_marchand_"+idVehicule).addClass("prix-barre");
                        updateMargeHT(idVehicule);
                    }
                }
            });
            $("#input_prix_remise_marchand_"+idVehicule).on("blur", function() {
                let prix_remise_marchand = setPrixMarchandRemise($(this), regexPrixRemiseMarchand, idVehicule);
                if (prix_remise_marchand == 0) {
                    $("#prix_remise_marchand_"+idVehicule).html("<button type='button' id='edit_prix_remise_marchand_"+idVehicule+"' class='btn btn-outline-dark btn-sm'>Éditer <i class='fas fa-pen'></i></button>");
                    $("#prix_remise_marchand_"+idVehicule)[0].dataset.order = 0;
                    if (vehicules_listingpro_table != null) {vehicules_listingpro_table.rows().invalidate();}
                    $("#prix_marchand_remise_"+idVehicule).addClass("d-none");
                    $("#prix_marchand_"+idVehicule).removeClass("prix-barre");
                    updateMargeHT(idVehicule);
                } else {
                    $("#prix_remise_marchand_"+idVehicule).html("<button type='button' id='edit_prix_remise_marchand_"+idVehicule+"' class='btn btn-outline-dark btn-sm'>" + prix_remise_marchand.replace(/(?=(\d\d\d)+(?!\d))/g, " ").trim() + " €</button>");
                    $("#prix_remise_marchand_"+idVehicule)[0].dataset.order = prix_remise_marchand;
                    if (vehicules_listingpro_table != null) {vehicules_listingpro_table.rows().invalidate();}
                    $("#prix_marchand_remise_"+idVehicule).text(prix_remise_marchand.replace(/(?=(\d\d\d)+(?!\d))/g, " ").trim() + " €");
                    $("#prix_marchand_remise_"+idVehicule).removeClass("d-none");
                    $("#prix_marchand_"+idVehicule).addClass("prix-barre");
                    updateMargeHT(idVehicule);
                }
            });
            $("#input_prix_remise_marchand_"+idVehicule).click(function(e) {
                e.stopPropagation();
            });
        });

        loadAjax();
    }
})

function updateMargeHT(idVehicule) {
    const prixMarchand = parseFloat($("#prix_marchand_"+idVehicule).html().replaceAll(" ", "").replaceAll("€", "").replaceAll("-", "0").trim());
    const prixMarchandRemise = parseFloat($("#prix_marchand_remise_"+idVehicule).html().replaceAll(" ", "").replaceAll("€", "").replaceAll("-", "0").trim());
    const prixAchat = parseFloat($("#prix_remise_marchand_"+idVehicule).parent().find(".marge-ht").attr("data-prix-achat"));
    $("#prix_remise_marchand_"+idVehicule).parent().find(".marge-ht").html(
        (
            (
                Math.round(
                    (
                        (
                            (prixMarchandRemise > 0 ? prixMarchandRemise : prixMarchand) - prixAchat
                        ) / 1.20
                    ) * 100
                )
            ) / 100
        ).toString().replace(/(?=(\d\d\d)+(?!\d))/g, " ").replace(".", ",").trim() + " €"
    );
}

// SELECT and DESELECT
function checkVehiculeListing(evt) {
    if (evt.stopPropagation !== undefined) {
        // evt.preventDefault();
        $("[id^='vehicule_listingpro_selection_']").each(function() {
            clickedId = this.id.replace("vehicule_listingpro_selection_", "");
            if ($("#button-select-vehicule").is(':checked')) {
                if ($(this).hasClass("fa-square")) {
                    $(this).parent().html('<span class="d-none">true</span><i class="far fa-check-square fa-2x cursor-pointer" id="vehicule_listingpro_selection_'+clickedId+'"></i>');
                }
            } else {
                if ($(this).hasClass("fa-check-square")) {
                    $(this).parent().html('<span class="d-none">false</span><i class="far fa-square fa-2x cursor-pointer" id="vehicule_listingpro_selection_'+clickedId+'"></i>');
                }
            }
        });
        evt.stopPropagation();
    } else {
        evt.cancelBubble = true;
    }
}

// Permet d'éviter de trier en même temps
function checkDisplayMargeHT(evt) {
    if (evt.stopPropagation !== undefined) {
        // evt.preventDefault();
        if ($("#marge-ht-blur").is(':checked')) {
            $(".marge-ht").each( function() {
                $(this).css({"filter" : "none", "-webkit-filter": "none"})
            });
        } else {
            $(".marge-ht").each( function() {
                $(this).css({"filter" : "blur(3px)", "-webkit-filter": "blur(3px)"})
            });
        }
        evt.stopPropagation();
    } else {
        evt.cancelBubble = true;
    }
}

function updateInfoBulle(idVehicule) {
    let content = $("#list-demande-client-"+idVehicule).html();
    content = content.replace(/<div.*<\/button> /g, "");
    content = content.replace(/<\/div>/g, "&#013;");
    content = content.replace(/\s\s/g, "");
    content = content.replace(/&#013;\s/g, "&#013;");
    $("#vehicule_listingpro_demande_reservation_apres_vente_"+idVehicule).parent().html('<button type="button" data-toggle="modal" data-target="#modal_listingpro_demande_reservation_apres_vente_'+idVehicule+'" id="vehicule_listingpro_demande_reservation_apres_vente_'+idVehicule+'" class="btn btn-light btn-sm" title="'+content+'">Gestion des demandes</button>');
}
