let prospects_prospects_table = null;
let timerBeforeRefreshDataProspects = null;

$(document).ready(function() {
    if ($("#prospects_results").length) {

        function loadAjax() {
            let optionsRecherche = new FormData();
            optionsRecherche.append('prospect', $("#filtersprospect-prospect").val());
            optionsRecherche.append('commercial', $("#filtersprospect-commercial").val());
            optionsRecherche.append('garage', $("#filtersprospect-garage").val());
            optionsRecherche.append('societe', $("#filtersprospect-societe").val());
            optionsRecherche.append('dep', $("#filtersprospect-dep").val());
            $("#prospects_results").addClass("d-none");
            $.ajax({
                type:'POST',
                url: siteURL + 'admin/outils/prospect/loadResults',
                contentType: false,
                processData: false,
                data: optionsRecherche,
                success: function(data) {
                    $("#prospects_results").html(data);
                }
            })
        };

        $('body').on('change keyup', "[id^='filtersprospect-']", function() {
            clearTimeout(timerBeforeRefreshDataProspects);
            timerBeforeRefreshDataProspects = setTimeout(loadAjax, 800);
        });

        // Bouton reset
        $('body').on('click', '#prospecttable-reset', function() {
            $("#filtersprospect-prospect").val('').selectpicker("refresh");
            $("#filtersprospect-commercial").val('').selectpicker("refresh");
            $("#filtersprospect-garage").val('').selectpicker("refresh");
            $("#filtersprospect-societe").val('').selectpicker("refresh");
            $("#filtersprospect-dep").val('').selectpicker("refresh");
            loadAjax();
        });

        $('body').on('click', "#btnProspectExportExcel", function() {
            if (prospects_prospects_table != null) {
                prospects_prospects_table.rows().invalidate();
                let all_data = prospects_prospects_table.rows().data();
                id_prospects = [];
                $.each(all_data, function(key, value) {
                    id_prospects.push(value[0]);
                });

                var form = new FormData();
                form.append('ids', id_prospects);
                today = new Date();

                $.ajax({
                    type: 'POST',
                    url: siteURL + 'admin/outils/prospect/ficheExcel',
                    contentType: false,
                    processData: false,
                    data: form,
                    success: function(data) {
                        toastr.success('Le fichier excel est fini', 'Succès');
                        var element = document.createElement('a');
                        element.setAttribute('href', siteURL + data);
                        element.setAttribute('download', "Liste Prospects Ora7 "+today.toLocaleDateString("fr")+".xlsx");
                        document.body.appendChild(element);
                        element.click();
                        document.body.removeChild(element);
                    }
                });
            }
        });

        // Bouton reset
        $('body').on('click', "#prospect-tabs-list-tab", function() {
            loadAjax();
        });

        //Envoi de modification prospect
        $('body').on('click', "button[id^='prospect-send-modify-']", function() {
            var clickedId = this.id.split(/[- ]+/).pop();
            if (makeSureFields(this.id) == true) {
                var form = new FormData();
                // On extrait le contenu du formulaire dans la variable 'form'
                form.append('id', clickedId);
                form.append('prospect_nom_garage', $('#prospect_nom_garage_' + clickedId).val());
                form.append('prospect_nom_societe', $('#prospect_nom_societe_' + clickedId).val());
                form.append('prospect_adresse', $('#prospect_adresse_' + clickedId).val());
                form.append('prospect_ville', $('#prospect_ville_' + clickedId).val());
                form.append('prospect_code_postal', $('#prospect_code_postal_' + clickedId).val());
                form.append('prospect_tva', $('#prospect_tva_' + clickedId).val());
                form.append('prospect_structure', $("select#prospect_structure_" + clickedId).children("option:selected").val());
                form.append('prospect_marques', $('select[name="prospect_marques_' + clickedId + '"]').val());
                form.append('prospect_commercial', $('select[name="prospect_commercial_' + clickedId + '"]').val());
                form.append('prospect_civilite', $('select[name="prospect_civilite_' + clickedId + '"]').val());
                form.append('prospect_nom', $('#prospect_nom_' + clickedId).val());
                form.append('prospect_prenom', $('#prospect_prenom_' + clickedId).val());
                form.append('prospect_mail', $('#prospect_mail_' + clickedId).val());
                form.append('prospect_telephone_fixe', $('#prospect_telephone_fixe_' + clickedId).val());
                form.append('prospect_telephone_portable', $('#prospect_telephone_portable_' + clickedId).val());
                form.append('prospect_fonction', $('#prospect_fonction_' + clickedId).val());
                form.append('prospect_valid', $('input[name="prospect_valid_' + clickedId + '"]:checked').val());

                // Requète ajax d'envoi du formulaire : /src/Controller/AdminController.php -> admin_prospect()


                $.ajax({
                    type: 'POST',
                    url: siteURL + 'admin/outils/prospect/modify',
                    contentType: false,
                    processData: false,
                    data: form,
                    success: function(data) {
                        // Si il s'agit de l'ajout d'un nouveau prospect
                        if (data == "exists") {
                            toastr.error('Un utilisateur avec l\'adresse mail saisie existe déjà', 'Erreur');
                        } else {
                            if (clickedId == 0) {
                                toastr.success('Le nouveau prospect a bien été enregistrée', 'Succès');
                                $('#formprospect_0')[0].reset();
                            }
                            // Si il s'agit d'une modification de prospect
                            else {
                                toastr.success('Le prospect a bien été modifié', 'Succès');
                                // On nettoye le html en supprimant l'onglet 'Modifier/Nouveau'
                                $('#prospect-tabs-modify-' + clickedId).remove();
                                $('#prospect-close-modify-' + clickedId).parent().remove();
                            }
                            // Retour à la liste de prospects en rechargeant la datatable
                            $('#prospect-tabs-list-tab').trigger('click');
                        }
                    }
                });
            }
            return false;
        });

        // Permets de supprimer une entité
        // Nécessite que l'id du bouton cliqué soit de la forme "user-delete-689"
        $('body').on('click', "[id*='prospect-password-']", function() {
            id_clicked = this.id.split('-')[2];
            Lobibox.confirm({
                title: 'Ré-initialiser le mot de passe',
                msg: "Êtes-vous sûr de vouloir envoyer un nouveau mot de passe aléatoire à l'utilisateur sélectionné ? Son mot de passe actuel sera ecrasé au passage.",
                buttons : {
                    ok : {
                        type: 'ok',
                        text: 'Oui'
                    },
                    cancel : {
                        type: 'cancel',
                        text: 'Non'
                    }
                },
                callback: function(lobibox, type){
                    if (type === 'ok'){
                        $.ajax({
                            type: 'POST',
                            url: siteURL + 'admin/outils/prospect/send_password',
                            data: { id: id_clicked},
                            success: function(data) {
                                if (data == "done") {
                                    toastr.success('Le nouveau mot de passe a été généré et envoyé à l\'utilisateur', 'Succès');
                                }
                                else {
                                    toastr.error('Une erreur inconnue est survenue', 'Erreur');
                                }
                            }
                        });
                    }
                },
            });
            return false;
        });

        // STATISTIQUE POUR TOUS LES PROSPECTS
        //
        $('body').on('change', '#prospects-select-choice-statistiques', function() {
            option = $('#prospects-select-choice-statistiques').val();
            console.log(option);
            if (option == "all") {
                $('#prospects-statistiques-unique').removeClass('d-block');
                $('#prospects-statistiques-unique').addClass('d-none');
                $('#prospects-statistiques-all').removeClass('d-none');
                $('#prospects-statistiques-all').addClass('d-block');
            } else if (option == "unique") {
                $('#prospects-statistiques-all').removeClass('d-block');
                $('#prospects-statistiques-all').addClass('d-none');
                $('#prospects-statistiques-unique').removeClass('d-none');
                $('#prospects-statistiques-unique').addClass('d-block');
            }
        });

        // Créer les deux graphes au click du bouton
        $('body').on ('click', '#prospects-statistiques', function () {

            var statistiques = $('#prospects-statistiques').data('value');
            var statistiquesUnique = $('#prospects-statistiques').data('unique');


            labelStat = [];
            dataStat = [];
            $.each(statistiques, function(index, value) {
                $.each(statistiques[index], function(index1, value1) {
                    labelStat.push(index+'-'+index1);
                    dataStat.push(value1);
                });
            });
            // Nombre de connexion total
            totalConnexion = 0;
            $.each(dataStat, function(index, value) {
                totalConnexion += parseInt(value);
            });
            textConnexion = $('#prospects-nombre-connexion').text();
            $('#prospects-nombre-connexion').text(textConnexion.split("(")[0]+ " (Total : "+ totalConnexion+")");
            createStatistiquesProspects('prospects-chart', labelStat, dataStat);


            // Créer le second graphe unique
            labelStat = [];
            dataStat = [];
            $.each(statistiquesUnique, function(index, value) {
                $.each(statistiquesUnique[index], function(index1, value1) {
                    labelStat.push(index+'-'+index1);
                    dataStat.push(value1);
                });
            });
            // Nombre de connexion total
            totalConnexion = 0;
            $.each(dataStat, function(index, value) {
                totalConnexion += parseInt(value);
            });
            textConnexion = $('#prospects-nombre-connexion-unique').text();
            $('#prospects-nombre-connexion-unique').text(textConnexion.split("(")[0]+ " (Total : "+ totalConnexion+")");
            createStatistiquesProspects('prospects-chart-unique', labelStat, dataStat);
            $('#prospects-modal').show();
            return false;
        });

        // Prospect connexion tous
        $('body').on('change', '#prospects-select-statistiques', function() {
            option = $('#prospects-select-statistiques').val();

            var statistiques = $('#prospects-statistiques').data('value');

            labelStat = [];
            dataStat = [];


            $.each(statistiques, function(index, value) {
                if (option == 'all' || option == '6' || option == '12' || option == index) {
                    $.each(statistiques[index], function(index1, value1) {
                        labelStat.push(index+'-'+index1);
                        dataStat.push(value1);
                    });
                }
            });

            if (option == '6' || option == '12') {
                tempLabel = [];
                tempData = []
                for (i = parseInt(option); i >= 1; i--) {
                    tempLabel.push(labelStat[labelStat.length - i]);
                    tempData.push(dataStat[dataStat.length - i]);
                }
                labelStat = tempLabel;
                dataStat = tempData;

            }

            // Nombre de connexion total
            totalConnexion = 0;
            $.each(dataStat, function(index, value) {
                totalConnexion += parseInt(value);
            });
            textConnexion = $('#prospects-nombre-connexion').text();
            $('#prospects-nombre-connexion').text(textConnexion.split("(")[0]+ "(Total : "+ totalConnexion+")");

            createStatistiquesProspects('prospects-chart', labelStat, dataStat);

        });

        // Prospect connexion unique
        $('body').on('change', '#prospects-select-unique-statistiques', function() {
            option = $('#prospects-select-unique-statistiques').val();

            var statistiques = $('#prospects-statistiques').data('unique');

            labelStat = [];
            dataStat = [];


            $.each(statistiques, function(index, value) {
                if (option == 'all' || option == '6' || option == '12' || option == index) {
                    $.each(statistiques[index], function(index1, value1) {
                        labelStat.push(index+'-'+index1);
                        dataStat.push(value1);
                    });
                }


            });

            if (option == '6' || option == '12') {
                tempLabel = [];
                tempData = []
                for (i = parseInt(option); i >= 1; i--) {
                    tempLabel.push(labelStat[labelStat.length - i]);
                    tempData.push(dataStat[dataStat.length - i]);
                }
                labelStat = tempLabel;
                dataStat = tempData;

            }

            // Nombre de connexion total
            totalConnexion = 0;
            $.each(dataStat, function(index, value) {
                totalConnexion += parseInt(value);
            });
            textConnexion = $('#prospects-nombre-connexion-unique').text();
            $('#prospects-nombre-connexion-unique').text(textConnexion.split("(")[0]+ "(Total : "+ totalConnexion+")");

            createStatistiquesProspects('prospects-chart-unique', labelStat, dataStat);

        });

        $('body').on('click', '#prospects-statistiques-close', function () {
            $('#prospects-modal').hide();
            // return false;
        });


        // STATISTIQUE POUR UN PROSPECT
        //
        $('body').on ('click', 'button[id^="prospect-statistiques-"]', function () {
            var clickedId = this.id.split(/[- ]+/).pop();
            var statistiques = $('#prospect-statistiques-'+clickedId).data('value');

            labelStat = [];
            dataStat = [];

            $.each(statistiques, function(index, value) {
                $.each(statistiques[index], function(index1, value1) {
                    labelStat.push(index+'-'+index1);
                    dataStat.push(value1);
                });
            });

            // Nombre de connexion total
            totalConnexion = 0;
            $.each(dataStat, function(index, value) {
                totalConnexion += parseInt(value);
            });
            textConnexion = $('#prospect-nombre-connexion-'+clickedId).text();
            $('#prospect-nombre-connexion-'+clickedId).text(textConnexion.split("(")[0]+ " (Total : "+ totalConnexion+")");


            createStatistiquesProspects('prospect-chart-'+clickedId, labelStat, dataStat);


            $('#prospect-modal-'+clickedId).show();


            return false;

        });

        $('body').on('change', 'select[id^="prospect-select-statistiques-"]', function() {
            var clickedId = this.id.split(/[- ]+/).pop();
            option = $('#prospect-select-statistiques-'+clickedId).val();

            var statistiques = $('#prospect-statistiques-'+clickedId).data('value');
            console.log(statistiques);

            labelStat = [];
            dataStat = [];


            $.each(statistiques, function(index, value) {
                if (option == 'all' || option == '6' || option == '12' || option == index) {
                    $.each(statistiques[index], function(index1, value1) {
                        labelStat.push(index+'-'+index1);
                        dataStat.push(value1);
                    });
                }


            });

            if (option == '6' || option == '12') {
                tempLabel = [];
                tempData = []
                for (i = parseInt(option); i >= 1; i--) {
                    tempLabel.push(labelStat[labelStat.length - i]);
                    tempData.push(dataStat[dataStat.length - i]);
                }
                labelStat = tempLabel;
                dataStat = tempData;

            }


            // Nombre de connexion total
            totalConnexion = 0;
            $.each(dataStat, function(index, value) {
                totalConnexion += parseInt(value);
            });
            textConnexion = $('#prospect-nombre-connexion-'+clickedId).text();
            $('#prospect-nombre-connexion-'+clickedId).text(textConnexion.split("(")[0]+ "(Total : "+ totalConnexion+")");

            createStatistiquesProspects('prospect-chart-'+clickedId, labelStat, dataStat);

        });

        $('body').on('click', 'span[id^="prospectstatistiqueclose-"]', function () {
            var clickedId = this.id.split(/[- ]+/).pop();
            $('#prospect-modal-'+clickedId).hide();
            // return false;
        });

        // Creer le graphique des statistiques
        function createStatistiquesProspects(canvasId, label, data) {

            $.each(label, function(index, value) {
                switch(value.split('-')[1]) {
                    case '1':
                        label[index] = value.split('-')[0] + '-Janvier';
                        break;
                    case '2':
                        label[index] = value.split('-')[0] + '-Février';
                        break;
                    case '3':
                        label[index] = value.split('-')[0] + '-Mars';
                        break;
                    case '4':
                        label[index] = value.split('-')[0] + '-Avril';
                        break;
                    case '5':
                        label[index] = value.split('-')[0] + '-Mai';
                        break;
                    case '6':
                        label[index] = value.split('-')[0] + '-Juin';
                        break;
                    case '7':
                        label[index] = value.split('-')[0] + '-Juillet';
                        break;
                    case '8':
                        label[index] = value.split('-')[0] + '-Août';
                        break;
                    case '9':
                        label[index] = value.split('-')[0] + '-Septembre';
                        break;
                    case '10':
                        label[index] = value.split('-')[0] + '-Octobre';
                        break;
                    case '11':
                        label[index] = value.split('-')[0] + '-Novembre';
                        break;
                    case '12':
                        label[index] = value.split('-')[0] + '-Décembre';
                        break;
                    default:
                        break;
                }

            });
            var ctx = document.getElementById(canvasId).getContext('2d');
            var chart = new Chart(ctx, {
                // The type of chart we want to create
                type: 'line',

                // The data for our dataset
                data: {
                    labels: label,
                    datasets: [{
                        label: 'Nombre de connexions',
                        backgroundColor: 'rgb(0, 174, 239)',
                        borderColor: 'rgb(0, 137, 189)',
                        data: data
                    }]
                },

                // Configuration options go here
                options: {
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true
                            }
                        }]
                    }
                }
            });
        }

        loadAjax();
    }
})
