$(document).ready(function() {


    $("#backgroundsearch-activebackgroundsearch").change(function() {
        if(this.checked) {
            $("#backgroundsearch-backgroundsearch").prop('disabled', false);
        } else {
            $("#backgroundsearch-backgroundsearch").prop('disabled', 'disabled');
        }
    });

    // Upload les images
    $('body').on('change', "#background_search_upload", function(e){

        var form = new FormData();
        form.append('data', $(this)[0].files[0]);

        $.ajax({
            url: 'backgroundsearch/upload',
            type: 'POST',
            cache: false,
            contentType: false,
            processData: false,
            data: form,
            success: function(data) {;
                // data deviens un tableau
                var data = data.split(" ; ");
                if (data[0] != "error") {
                    toastr.success('La photo a bien été envoyée', "Succès");

                    // Afficher l'image avec les autres
                    $('#all-background').append('<div class="col-12 col-md-4"><img src="'+siteURL+data[0]+'" data-bandeau="'+data[0]+'" class="col-12 pb-1 background_exists"><p class="text-center">'+data[1]+'<i class="fas fa-times float-right mr-5 delete_search_bg cursor-pointer" id="delete_search_bg_'+data[1].toLowerCase()+'" data-id="'+data[1].toLowerCase()+'"></i></p></div>');
                    // Ajout de l'option dans le select
                    var option = new Option(data[1], data[1].toLowerCase());
                    $(option).html(data[1]);
                    $("#backgroundsearch-backgroundsearch").append(option);
                    // Cacher le texte de fond
                    $('#no-background').hide();
                }
                else {
                    toastr.error('Une erreur est survenue', data[1]);
                }
            },
        });
    });

    // Supprimer une image
    $('body').on('click', '[id^=delete_search_bg_]', function(e){

        var url_delete = $(this).data("id");
        var form = new FormData();
        form.append('url_delete', this.id.replace('delete_search_bg_', ''));

        $.ajax({
            url: 'backgroundsearch/delete',
            type: 'POST',
            cache: false,
            contentType: false,
            processData: false,
            data: form,
            success: function(data) {;
                // data deviens un tableau

                var data = data.split(" ; ");
                if (data[0] != "error") {
                    toastr.success('La photo a bien été supprimée', "Succès");

                    // Supprime l'image supprimer
                    $('#delete_search_'+data[1].replace(".jpg", "")).parent().parent().remove();
                    // Supprime l'option du select
                    selected = $("#backgroundsearch-backgroundsearch option:selected").val();
                    if (selected == data[2].toLowerCase()) {
                        toastr.warning('Enregistrer pour prendre en compte votre choix', "Attention");
                    }
                    $("#backgroundsearch-backgroundsearch option[value='"+ data[2].toLowerCase() +"']").remove();
                }
                else {
                    toastr.error('Une erreur est survenue', data[1]);
                }
            },
        });
    });


    // Envoi de modification generateurclickandlease
    $('body').on('click', "#backgroundsearch-send-modify", function() {

        var form = new FormData();
        // On extrait le contenu du formulaire dans la variable 'form'

        if($("#backgroundsearch-activebackgroundsearch").prop( "checked" ) == true) {
            form.append('active-backgroundsearch', 1);
        } else {
            form.append('active-backgroundsearch', 0);
        }

        var backgroundsearch = $('#backgroundsearch-backgroundsearch option:selected').map(function(i,v) {
            return this.value;
        }).get();
        form.append('backgroundsearch', backgroundsearch[0]);


        $.ajax({
            type: 'POST',
            url: '../admin/backgroundsearch/modify',
            contentType: false,
            processData: false,
            data: form,
            success: function(data) {
                if (data == "exists") {
                    toastr.error('Erreur', 'Erreur');
                }
                else {
                    toastr.success('Les choix ont bien été enregisté', 'Succès');
                }
            }
        });

        return false;

    });

});