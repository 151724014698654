let frePrestation_table = null;
$(document).ready(function () {
    if ($("#frePrestation-results").length) {
        function loadAjax() {
            $("#frePrestation-results").addClass("d-none");
            $("#frePrestation-loader").removeClass("d-none");
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/fre/frePrestation/ajax',
                contentType: false,
                processData: false,
                data: {},
                success: function (data) {
                    $("#frePrestation-results").html(data);
                    frePrestation_table = $('#frePrestation-table').DataTable({
                        "autoWidth": false,
                        "language": {
                            "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/fr-FR.json"
                        },
                        "paging": true,
                        "responsive": true,
                        "order": [
                            [0, "asc"]
                        ],
                        "columnDefs": [
                            {
                                "targets": [5, 7],
                                "orderable": false,
                            },
                            { type: 'num', targets: [0, 3, 6] },
                        ],
                        "conditionalPaging": true,
                    });
                    $('#frePrestation-table').on('draw.dt', function () {
                        $("#frePrestation-loader").addClass("d-none");
                        $("#frePrestation-results").removeClass("d-none");
                    });
                }
            })
        };

        // Envoi de modification d'un frePrestation
        $('body').on('click', "[id^='frePrestation-send-modify-']", function (e) {
            e.preventDefault();
            let id_clicked = this.id.replace('frePrestation-send-modify-', '');
            let frePrestation_label = $('#frePrestation-label-' + id_clicked).val();
            let frePrestation_categorie = $('#frePrestation-categorie-' + id_clicked).val();
            let frePrestation_dynamique = $('#frePrestation-dynamique-' + id_clicked).val();
            let frePrestation_prixHt = $('#frePrestation-prixHt-' + id_clicked).val();
            let frePrestation_duree = $('#frePrestation-duree-' + id_clicked).val();
            let frePrestation_isDispoQuickExpertise = $('#frePrestation-isDispoQuickExpertise-' + id_clicked).val();
            let frePrestation_isRecurrentQuickExpertise = $('#frePrestation-isRecurrentQuickExpertise-' + id_clicked).val();
            let frePrestation_isDispoReception = $('#frePrestation-isDispoReception-' + id_clicked).val();
            let frePrestation_ordreReception = $('#frePrestation-ordreReception-' + id_clicked).val();
            let frePrestation_controlePresenceReception = $('#frePrestation-controlePresenceReception-' + id_clicked).val();
            let frePrestation_questionPresenceReception = $('#frePrestation-questionPresenceReception-' + id_clicked).val();
            let frePrestation_imageReception_idFileUpload = $('#frePrestation-imageReception-idFileUpload-' + id_clicked).val();

            let frePrestation_freElement = getElement(id_clicked);
            let error_message = "";
            if (frePrestation_label.length == 0) {
                error_message += "Le libellé de la prestation n\'a pas été renseigné.<br/>"
            }
            if (frePrestation_categorie == "") {
                error_message += "La catégorie de la prestation n\'a pas été renseigné.<br/>"
            }
            if (frePrestation_dynamique == "") {
                error_message += "Le type de prix de la prestation n\'a pas été renseigné.<br/>"
            }
            if (!parseInt(frePrestation_dynamique) && frePrestation_prixHt.length == 0) {
                error_message += "Le prix HT de la prestation n\'a pas été renseigné.<br/>"
            }
            if (error_message != "") {
                toastr.error(error_message, 'Erreur');
            } else {
                let form = new FormData();
                form.append('frePrestation_id', id_clicked);
                form.append('frePrestation_label', frePrestation_label);
                form.append('frePrestation_categorie', frePrestation_categorie);
                form.append('frePrestation_dynamique', frePrestation_dynamique);
                form.append('frePrestation_prixHt', frePrestation_prixHt);
                form.append('frePrestation_isDispoQuickExpertise', frePrestation_isDispoQuickExpertise);
                form.append('frePrestation_isRecurrentQuickExpertise', frePrestation_isRecurrentQuickExpertise);
                form.append('frePrestation_duree', frePrestation_duree);
                form.append('frePrestation_isDispoReception', frePrestation_isDispoReception);
                form.append('frePrestation_ordreReception', frePrestation_ordreReception);
                form.append('frePrestation_controlePresenceReception', frePrestation_controlePresenceReception);
                form.append('frePrestation_questionPresenceReception', frePrestation_questionPresenceReception);
                form.append('frePrestation_imageReception_idFileUpload', frePrestation_imageReception_idFileUpload);
                form.append('frePrestation_label_traduit', getLabelTraduit(id_clicked, "Prestation"));

                form.append('frePrestation_freElement', frePrestation_freElement);
                $.ajax({
                    type: 'POST',
                    url: siteURL + 'admin/fre/frePrestation/modify',
                    contentType: false,
                    processData: false,
                    data: form,
                    success: function (data) {
                        toastr.success('Le prestation a bien été enregistrée', 'Succès');
                        $("#frePrestation-tabs-add").html(data);
                        selectpickerReset(selectpickerList);
                        $('#frePrestation-tabs-modify-' + id_clicked).remove();
                        $('#frePrestation-close-modify-' + id_clicked).parent().remove();
                        $('#frePrestation-tabs-list-tab').trigger('click');
                    },
                    error: function () {
                        toastr.error('Une erreur s\'est produite.', 'Erreur');
                    }
                });
            }
        });

        // Affichage ou non des champs pour les FRE lié à la réception
        $('body').on('click', "[id^='frePrestation-isDispoReception-']", function (e) {
            let id_clicked = this.id.replace('frePrestation-isDispoReception-', '');
            if ($(this).is(':checked')){
                $('.frePrestation-receptionAffichage-' + id_clicked).each(function () {
                    $(this).removeClass('d-none');
                });
            } else {
                $('.frePrestation-receptionAffichage-' + id_clicked).each(function () {
                    $(this).addClass('d-none');
                });
                $('#frePrestation-ordreReception-' + id_clicked).val('');
                $('#frePrestation-controlePresenceReception-' + id_clicked).val('');
                $('#frePrestation-imageReception-' + id_clicked).val('');
                $('#frePrestation-imageReception-idFileUpload-' + id_clicked).val('');
                $('#file_display_image-' + id_clicked + " img").attr("src", '');
            }
        });

        // Pour l'upload de l'image réception
        $('body').on('change', "[id^='frePrestation-imageReception-']", function (e) {
            let idPrestation = this.id.replace("frePrestation-imageReception-", "");
            let input = $(this);
            const [file] = this.files;
            let extensionFichier = [file][0]["name"].match(/\.(jpg|jpeg|png)/g)[0];
            if (file) {
                input.prop('disabled', true);
                let form = new FormData();
                form.append('data', input[0].files[0]);
                form.append('filemime', input.data("mime"));
                form.append('filetypes', input.data("filetypes"));
                form.append('imageratio', input.data("imageRatio"));
                form.append("extensionFichier", extensionFichier);
                form.append("idPrestation", idPrestation);
                form.append("fichier", $('#frePrestation-label-' + idPrestation).val().toString().slugify());
                $.ajax({
                    url: 'file/upload',
                    type: 'POST',
                    cache: false,
                    contentType: false,
                    processData: false,
                    data: form,
                    success: function (data) {
                        // success == true
                        if (data.success == true) {
                            toastr.success('Le fichier a bien été enregistré.', 'Success');
                            // On enregistre l'id du fichier sur l'annuaire
                            let idFileImage = data.id;
                            $('#frePrestation-imageReception-idFileUpload-' + idPrestation).val(idFileImage);
                            $('#file_display_image-' + idPrestation + " img").attr("src", data.path + data.file);
                        }
                        // success == false
                        else {
                            if (data.error == 'exists') {
                                toastr.error('Erreur lors de l\'enregistrement, le fichier existe déjà.', 'Erreur');
                            } else if (data.error == 'type') {
                                toastr.error('Erreur lors de l\'enregistrement, le type de fichier n\'est pas accepté.', 'Erreur');
                            } else if (data.error == 'ratio') {
                                toastr.error('Erreur lors de l\'enregistrement, le ratio de taille du fichier n\'est pas accepté.', 'Erreur');
                            }
                        }
                    },
                });
            }
        });


        // Filtre les éléments
        function filtreElement(id) {
            let search = $("#frePrestation-search-" + id).val().slugify();
            let selected = $("#frePrestation-selected-" + id).is(":checked");
            $("[name^='frePrestation-searchItem-" + id + "-']").toArray()
                .map(function (el) { el.classList.add("d-none"); return el; })
                .filter((el) => el.getAttribute("data-label").slugify().includes(search) && (selected ? $(el).find("#" + el.getAttribute("name")).is(":checked") : true))
                .map((el) => el.classList.remove("d-none")).length;
        }

        // Retourne les éléments sélectionnés
        function getElement(id) {
            return $("input[type='checkbox'][id^='frePrestation-searchItem-" + id + "-']").toArray()
                .filter((el) => $(el).is(":checked"))
                .map((el) => el.id.replace("frePrestation-searchItem-" + id + "-", ""));
        }

        $('body').on('keyup change', "[id^='frePrestation-search-']", function (e) { filtreElement(this.id.replace('frePrestation-search-', '')); });
        $('body').on('change', "[id^='frePrestation-selected-']", function (e) { filtreElement(this.id.replace('frePrestation-selected-', '')); });

        const selectpickerList = [
            "frePrestation-categorie-",
            "frePrestation-dynamique-"
        ];
        selectpickerReset(selectpickerList);

        // Bouton refresh
        $('body').on('click', "#frePrestation-tabs-list-tab", function () { loadAjax(); });
        loadAjax();
    }
});