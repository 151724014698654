// Génération du lien lacentrale
function lacentraleLinkGenerator(vehicule_fournisseur_marque, vehicule_fournisseur_modele, vehicule_fournisseur_boite, vehicule_fournisseur_carburant, vehicule_fournisseur_date_mec, vehicule_fournisseur_kilometrage) {
    // URL de base
    let url_lacentrale = "https://www.lacentrale.fr/listing?sortBy=priceAsc";

    // Les marques passent en MAJUSCULE
    if (vehicule_fournisseur_marque.length != 0) {
        url_lacentrale = url_lacentrale + "&makesModelsCommercialNames=" + vehicule_fournisseur_marque.toUpperCase();
    }
    // Les modèles passent en MAJUSCULE
    if (vehicule_fournisseur_modele.length != 0) {
        url_lacentrale = url_lacentrale + "%3A" + vehicule_fournisseur_modele.toUpperCase();
    }
    // Les boites {A -> AUTO, M -> on ne met rien}
    if (vehicule_fournisseur_boite.length != 0) {
        if (vehicule_fournisseur_boite == "A") {
            url_lacentrale = url_lacentrale + "&gearbox=" + "AUTO";
        }
    }
    // Les carburants {
    //    ES -> ess
    //    GO -> dies
    //    EH -> hyb
    //    EL -> elec
    //    GP -> gpl
    // }
    if (vehicule_fournisseur_carburant.length != 0) {
        url_lacentrale = url_lacentrale + "&energies=" + (vehicule_fournisseur_carburant == "ES" ? "ess" : (vehicule_fournisseur_carburant == "GO" ? "dies" : (vehicule_fournisseur_carburant == "EH" ? "hyb" : (vehicule_fournisseur_carburant == "EL" ? "elec" : (vehicule_fournisseur_carburant == "GP" ? "gpl" : "")))));
    }
    // Kilométrage :
    // Arrondi à la dizaine de millier si ni essence et ni diesel
    //
    // kmPerYearMin = {essence -> 10000, diesel -> 17500}
    // kmPerYearMax = {essence -> 15000, diesel -> 25000}
    // distMin = (année actuel - année MEC) * kmPerYearMin
    // distMax = (année actuel - année MEC) * kmPerYearMax
    // Arrondi à la dizaine de millier si essence ou diesel et si le kilometrage est compris entre distMin et distMax
    // Si < distMin alors distMin
    // Si > distMax alors viellir le véhicule de (année actuel - (kilometrage du véhicule / kmPerYearMax))
    if (vehicule_fournisseur_kilometrage.length != 0) {
        if (vehicule_fournisseur_carburant != "ES" && vehicule_fournisseur_carburant != "GO" && vehicule_fournisseur_carburant != "EH") {
            url_lacentrale = url_lacentrale + "&mileageMax=" + Math.ceil(parseFloat(vehicule_fournisseur_kilometrage) / 10000) * 10000;
        } else if (vehicule_fournisseur_date_mec.length != 0) {
            yearDiff = (new Date().getFullYear()) - parseInt(vehicule_fournisseur_date_mec) + 1;
            if (vehicule_fournisseur_carburant == "ES" || vehicule_fournisseur_carburant == "EH") {
                let kmPerYearMin = 10000;
                let kmPerYearMax = 15000;
                distMin = yearDiff * kmPerYearMin;
                distMax = yearDiff * kmPerYearMax;
                if (parseFloat(vehicule_fournisseur_kilometrage) < distMin) {
                    vf_kilometrage_url = distMin;
                } else if (parseFloat(vehicule_fournisseur_kilometrage) <= distMax) {
                    vf_kilometrage_url = Math.ceil(parseFloat(vehicule_fournisseur_kilometrage) / 10000) * 10000;
                } else {
                    vf_kilometrage_url = Math.ceil(parseFloat(vehicule_fournisseur_kilometrage) / 10000) * 10000;
                    vehicule_fournisseur_date_mec = parseInt(vehicule_fournisseur_date_mec) - Math.floor(parseFloat(vehicule_fournisseur_kilometrage) / kmPerYearMax);
                }
            } else if (vehicule_fournisseur_carburant == "GO") {
                let kmPerYearMin = 17500;
                let kmPerYearMax = 25000;
                distMin = yearDiff * kmPerYearMin;
                distMax = yearDiff * kmPerYearMax;
                if (parseFloat(vehicule_fournisseur_kilometrage) < distMin) {
                    vf_kilometrage_url = distMin;
                } else if (parseFloat(vehicule_fournisseur_kilometrage) <= distMax) {
                    vf_kilometrage_url = Math.ceil(parseFloat(vehicule_fournisseur_kilometrage) / 10000) * 10000;
                } else {
                    vf_kilometrage_url = Math.ceil(parseFloat(vehicule_fournisseur_kilometrage) / 10000) * 10000;
                    vehicule_fournisseur_date_mec = parseInt(vehicule_fournisseur_date_mec) - Math.floor(parseFloat(vehicule_fournisseur_kilometrage) / kmPerYearMax);
                }
            }
            url_lacentrale = url_lacentrale + "&mileageMax=" + vf_kilometrage_url;
        }
    }
    // Date de MEC
    if (vehicule_fournisseur_date_mec.length != 0) {
        url_lacentrale = url_lacentrale + "&yearMin=" + vehicule_fournisseur_date_mec;
    }

    return url_lacentrale;
}
