if ($("#widget-action-prix").length) {
    let compteurSansPhoto = 0;
    let compteurSansPrix = 0;
    let compteurSansEquipement = 0;
    let compteurPhoto15j = 0;
    let compteurLead30j = 0;
    let compteurPrix30j = 0;
    let compteurPrix60j = 0;
    let compteurCycle60j = 0;
    function widgetActionPrixAjax() {
        $("#loader-widget-action-prix").removeClass('d-none');

        $.ajax({
            type:'POST',
            url: siteURL + 'admin/widget/action-prix',
            contentType: false,
            processData: false,
            success: function(data){
                $.each(data.vehicules, function (key, value) {
                    if (value.badgeSansPhoto != null && value.badgeSansPhoto == "sansPhoto") {
                        compteurSansPhoto++;
                    }
                    if (value.badgeSansPrix != null && value.badgeSansPrix == "sansPrix") {
                        compteurSansPrix++;
                    }
                    if (value.badgeSansEquipement != null && value.badgeSansEquipement == "sansEquipement") {
                        compteurSansEquipement++;
                    }
                    if (value.badgePhoto15j != null && value.badgePhoto15j == "photo15j") {
                        compteurPhoto15j++;
                    }
                    if (value.badgeLead30j != null && value.badgeLead30j == "lead30j") {
                        compteurLead30j++;
                    }
                    if (value.badgePrix30j != null && value.badgePrix30j == "prix30j") {
                        compteurPrix30j++;
                    }
                    if (value.badgePrix60j != null && value.badgePrix60j == "prix60j") {
                        compteurPrix60j++;
                    }
                    if (value.badgeCycle60j != null && value.badgeCycle60j == "cycle60j") {
                        compteurCycle60j++;
                    }
                });

                let config = {
                    type: 'doughnut',
                    data: {
                        labels: [
                            "Sans Photo (" + compteurSansPhoto + ")",
                            "Sans Prix (" + compteurSansPrix + ")",
                            "Sans équipement (" + compteurSansEquipement + ")",
                            "Photo > 15j (" + compteurPhoto15j + ")",
                            "Lead > 30j (" + compteurLead30j + ")",
                            "Action Prix > 30j (" + compteurPrix30j + ")",
                            "Action Prix > 60j (" + compteurPrix60j + ")",
                            "Cycle co. > 60j (" + compteurCycle60j + ")",
                        ],
                        datasets: [{
                            data: [
                                compteurSansPhoto,
                                compteurSansPrix,
                                compteurSansEquipement,
                                compteurPhoto15j,
                                compteurLead30j,
                                compteurPrix30j,
                                compteurPrix60j,
                                compteurCycle60j,
                            ],
                            backgroundColor: [
                                '#f56954',
                                '#00a65a',
                                '#f39c12',
                                '#00c0ef',
                                '#3c8dbc',
                                '#d2d6de',
                                "#dd8888",
                                '#88dddd'
                            ]
                        }]
                    }
                };

                new Chart(document.getElementById('action-prix-DonutChart'), config);
                $("#widget-action-prix-title a").html(data.compteurVehicule + ' véhicules en alerte <i class="fas fa-arrow-circle-right"></i>');
                $("#loader-widget-action-prix").addClass('d-none');

                // On appelle la requête du prochain widget
                nextWidgetRequeteAjax();
            }
        })
    }
}