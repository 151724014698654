let annuaire_table = null;
let annuaire_table_page = 0;
let annuaire_table_page_length = 10;
let timerBeforeRefreshDataAnnuaire = null;
let tmpStoreGeo = [];
let goToTab = null;
let selectedAnnuaireCache = [];
const isAnnuairePage = window.location.href.includes("/admin/annuaire/annuaires");

$(document).ready(function () {
    if ($("#annuaire_results").length) {
        if (isAnnuairePage) {
            window.history.pushState("", "", siteURL + "admin/annuaire/annuaires");
            colvisFunOnEvent();
        } else {
            if ($("#campagne-page-form").length && $("#campagne-page-form").data("annuairecache") != undefined) {
                selectedAnnuaireCache = $("#campagne-page-form").data("annuairecache").toString().split(",");
            }
        }
        let trieColonne = [];

        function loadAjax({ isPageReload = false, goToAnnuaire = "" } = {}) {
            $("#annuaire_results").addClass("d-none");
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/annuaire/annuaire_ajax',
                contentType: false,
                processData: false,
                data: getParams(),
                success: function (data) {
                    $("#annuaire_results").html(data);
                    for (let index = 0; index < selectedAnnuaireCache.length; index++) {
                        $("#annuaire-selection-" + selectedAnnuaireCache[index]).prop("checked", true);
                    }
                    // Initialise la datatable
                    annuaire_table = $('#annuaire-table').DataTable({
                        "oSearch": { "sSearch": (goToAnnuaire ? "id:[" + goToAnnuaire.toString() + "]" : "") },
                        "dom": (isAnnuairePage ? dataTablesCustomDom() : dataTablesCustomDom({ scrollable: true })),
                        "buttons": [dataTablesColvisBtn('th:nth-child(n+2)')],
                        "autoWidth": false,
                        "language": { "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/fr-FR.json" },
                        "paging": true,
                        "pageLength": annuaire_table_page_length,
                        "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, "All"]],
                        "responsive": isAnnuairePage,
                        "order": [[(isAnnuairePage ? 0 : 1), "desc"]],
                        "conditionalPaging": true,
                        "columnDefs": [
                            { type: 'num', targets: (isAnnuairePage ? 0 : 1) },
                            { "className": "text-center align-middle", "targets": '_all' },
                            { "targets": (isAnnuairePage ? [] : [0]), "orderable": false }
                        ],
                        "columns": (isAnnuairePage ? getColvisColumn("annuaire-table") : null)
                    });
                    $('#annuaire-table').on('init.dt', function () { initMirrorBtn(); setColvisTable(annuaire_table); colvisFunSelectRefresh(); });
                    // Quand la datatable a fini de charger
                    $('#annuaire-table').on('draw.dt', function () {
                        if (isPageReload) {
                            annuaire_table_page = annuaire_table.page();
                            annuaireLastDateActionGenerator(getAnnuaireListId());
                            annuaireIndicatorGenerator(getAnnuaireListId());
                            // On affiche le tableau
                            $("#annuaire_results").removeClass("d-none");
                            if (goToAnnuaire) {
                                $("#annuaire-open-modify-" + goToAnnuaire).trigger("click");
                            }
                        } else {
                            isPageReload = true;
                            if (trieColonne != null && trieColonne.length > 0) {
                                annuaire_table.order([trieColonne[0][0], trieColonne[0][1]]).page(annuaire_table_page).draw(false);
                            } else {
                                annuaire_table.page(annuaire_table_page).draw(false);
                            }
                        }
                    });
                    $('#annuaire-table').on('page.dt', function () {
                        annuaire_table_page = annuaire_table.page();
                        annuaireLastDateActionGenerator(getAnnuaireListId());
                        annuaireIndicatorGenerator(getAnnuaireListId());
                    });
                    $('#annuaire-table').on('length.dt', function () {
                        annuaire_table_page = annuaire_table.page();
                        annuaire_table_page_length = annuaire_table.page.len();
                        annuaireLastDateActionGenerator(getAnnuaireListId());
                        annuaireIndicatorGenerator(getAnnuaireListId());
                    });
                    $('#annuaire-table').on('buttons-action.dt', function () {
                        annuaireLastDateActionGenerator(getAnnuaireListId());
                        annuaireIndicatorGenerator(getAnnuaireListId());
                    });
                }
            })
        };

        // Pour garder l'ordre de tri des colonnes
        $('body').on('click', "th", function () {
            if (trieColonne[0] == annuaire_table.order()[0]) {
                if (trieColonne[0][1] == 'desc') {
                    trieColonne[0][1] = 'asc';
                } else if (trieColonne[0][1] == 'asc') {
                    trieColonne[0][1] = 'desc';
                }
            } else {
                trieColonne[0] = annuaire_table.order()[0];
            }
            annuaire_table.order.neutral();
            annuaire_table.order([0, "desc"]).order([trieColonne[0][0], trieColonne[0][1]]);
            annuaire_table.draw(false);
        });

        if (isAnnuairePage) {
            setupLoadAjax(loadAjax);
            setupResetFilter(resetFilter);
            setupMiseAJourFiltreIsPro(annuaireUpdateFiltreIsPro);
            setupMiseAJourFiltreTypeEntreprise(annuaireUpdateFiltreTypeEntreprise);
        }

        function getParams() {
            let qualification = [];
            if ($("#filtersannuaire-typeEntreprise").val() == "1") {
                qualification = $("#filtersannuaire-qualification-marchand").val();
            } else if ($("#filtersannuaire-typeEntreprise").val() == "2") {
                qualification = $("#filtersannuaire-qualification-fournisseur").val();
            }
            let marques = [];
            let marquesPanneaux = [];
            if ($("#filtersannuaire-typeEntreprise").val() == "1") {
                marques = $("#filtersannuaire-marques-marchand").val();
                marquesPanneaux = $("#filtersannuaire-marquesPanneaux-marchand").val();
            } else if ($("#filtersannuaire-typeEntreprise").val() == "2") {
                marques = $("#filtersannuaire-marques-fournisseur").val();
            }
            //? Test de généralisation, problème : lorsqu'on laisse sélectionné le type d'entreprise et qu'on en sélectionne un autre, ça garde la qualification du premier
            // $("[id^='filtersannuaire-qualification'").each(function() {
            //     $(this).val().forEach(element => {
            //         qualification.push(element);
            //     });
            // })
            let optionsRecherche = new FormData();
            optionsRecherche.append('isAnnuairePage', isAnnuairePage);
            optionsRecherche.append('isPro', $("#filtersannuaire-isPro").val());
            optionsRecherche.append('typeEntreprise', $("#filtersannuaire-typeEntreprise").val());
            optionsRecherche.append('nomEntreprise', $("#filtersannuaire-nomEntreprise").val());
            optionsRecherche.append('nom', $("#filtersannuaire-nom").val());
            optionsRecherche.append('email', $("#filtersannuaire-email").val());
            optionsRecherche.append('pays', $("#filtersannuaire-pays").val());
            optionsRecherche.append('vendeur', $("#filtersannuaire-vendeur").val());
            optionsRecherche.append('indicateur', $("#filtersannuaire-indicateur").val());
            optionsRecherche.append('codePostal', $("#filtersannuaire-codePostal").val());
            optionsRecherche.append('qualification', qualification);
            optionsRecherche.append('marques', marques);
            optionsRecherche.append('agentMarque', $("#filtersannuaire-agentMarque-marchand").val());
            optionsRecherche.append('marquesPanneaux', marquesPanneaux);
            return optionsRecherche;
        }

        /**
         * @name Export
         */
        $('body').on('click', "#annuaire-export-excel", function (e) { annuaireExportGenerator(getParams()); });
        // Export

        // Suppression de l'annuaire
        $('body').on('click', "[id^='confirmation-suppression-annuaire-']", function (e) { annuaireSuppression(e); });

        /**
         * @name Filter
         */
        $('body').on('change', "[id^='filtersannuaire-']", function () {
            // Rafraichi les données du tableau de l'annuaire si il n'y a pas eu d'autre modification de filtre dans les 500 ms.
            clearTimeout(timerBeforeRefreshDataAnnuaire);
            timerBeforeRefreshDataAnnuaire = setTimeout(loadAjax({ isPageReload: true }), 500);
        });

        // Affiche les champs adapté des filtres
        $('body').on('change', "#filtersannuaire-isPro", annuaireUpdateFiltreIsPro);

        // Affiche les champs adapté des filtres
        $('body').on('change', "#filtersannuaire-typeEntreprise", annuaireUpdateFiltreTypeEntreprise);

        // Bouton reset
        $('body').on('click', '#filtersannuaire-reset', function () {
            resetFilter();
            if (isAnnuairePage) {
                reloadLoadAjax({ isPageReload: true });
            } else {
                loadAjax({ isPageReload: true });
            }
        });

        function resetFilter() {
            let listOnlyPro = $("[class~='display-filtersannuaire-onlyPro']");
            for (let index = 0; index < listOnlyPro.length; index++) {
                $(listOnlyPro[index]).addClass('d-none');
            }
            let listOnlyPart = $("[class~='display-filtersannuaire-onlyPart']");
            for (let index = 0; index < listOnlyPart.length; index++) {
                $(listOnlyPart[index]).addClass('d-none');
            }
            let listOnlyMarchand = $("[class~='display-filtersannuaire-onlyMarchand']");
            for (let index = 0; index < listOnlyMarchand.length; index++) {
                $(listOnlyMarchand[index]).addClass('d-none');
            }
            let listOnlyFournisseur = $("[class~='display-filtersannuaire-onlyFournisseur']");
            for (let index = 0; index < listOnlyFournisseur.length; index++) {
                $(listOnlyFournisseur[index]).addClass('d-none');
            }
            let listNoMarchand = $("[class~='display-filtersannuaire-noMarchand']");
            for (let index = 0; index < listNoMarchand.length; index++) {
                $(listNoMarchand[index]).removeClass('d-none');
            }
            $("#filtersannuaire-isPro").val('ALL').selectpicker("refresh");
            $("#filtersannuaire-typeEntreprise").val('default').selectpicker("refresh");
            $("#filtersannuaire-categories").val('default').selectpicker("refresh");
            $("#filtersannuaire-nomEntreprise").val('');
            $("#filtersannuaire-indicateur").val('default').selectpicker("refresh");
            $("#filtersannuaire-nom").val('');
            $("#filtersannuaire-email").val('');
            $("#filtersannuaire-vendeur").val('default').selectpicker("refresh");
            $("#filtersannuaire-codePostal").val('default').selectpicker("refresh");
            $("[id^='filtersannuaire-marques']").each(function () {$(this).val('default').selectpicker("refresh")});
            $("#filtersannuaire-pays").val('default').selectpicker("refresh");
            $("[id^='filtersannuaire-qualification']").each(function () {$(this).val('default').selectpicker("refresh")});
            $("#filtreConfigSelect-filtreConfig-aucun").val('aucun').prop("selected", true);
        }
        // Filter

        /**
         * @name Tabulation
         */
        // Bouton refresh
        $('body').on('click', "#annuaire-tabs-list-tab", function () {
            loadAjax();
        });

        // Bouton refresh
        $('body').on('click', "#annuaire-tabs-add-tab", function () {
            $("#formannuaire_0").find("#annuaire-sub-tab-info").trigger('click');
        });

        // Update sub tab
        $('body').on('click', "[id^='annuaire-sub-tab-']", function (e) {
            e.preventDefault();
            const idTab = this.id.replace("annuaire-sub-tab-", "");
            const idAnnuaire = idTab.match(/[\d]+/).join('');
            // Reset
            $("[id^='annuaire-sub-tab_pane-'][id$='-" + idAnnuaire + "']").each((k, el) => { $(el).addClass('d-none'); });
            $("[id^='annuaire-sub-tab-'][id$='-" + idAnnuaire + "'][annuaire-sub-tab]").each((k, el) => { $(el).removeClass('btn-outline-primary'); $(el).addClass('btn-primary'); });
            // Display
            $("[id='annuaire-sub-tab_pane-" + idTab + "']").each((k, el) => { $(el).removeClass('d-none'); });
            $("[id='annuaire-sub-tab-" + idTab + "'][annuaire-sub-tab]").each((k, el) => { $(el).removeClass('btn-primary'); $(el).addClass('btn-outline-primary'); });
        });
        // Tabulation

        // Selection des véhicules
        $('body').on('change', "[id^='annuaire-selection-']", function (evt) {
            if (evt.stopPropagation !== undefined) { evt.stopPropagation(); } else { evt.cancelBubble = true; }
            if (this.id.replace("annuaire-selection-", "") == "all") {
                $("[id^='annuaire-selection-']").each(function () {
                    if ($("#annuaire-selection-all").is(':checked')) {
                        $(this).prop("checked", true);
                    } else {
                        $(this).prop("checked", false);
                    }
                });
            } else {
                if ($(this).is(":checked")) {
                    $(this).prop("checked", true);
                } else {
                    $(this).prop("checked", false);
                }
            }
            annuaire_table.rows().invalidate();
            let all_data = annuaire_table.rows().data();
            let index = null;
            id_annuaires = [];
            $.each(all_data, function (key, value) {
                let tr = $('#annuaire-tr-' + $(value[0])[0].id.replace("annuaire-selection-", ""));
                if (tr.length != 0) {
                    if ($(value[0])[0].id.includes("annuaire-selection-") && $("#" + $(value[0])[0].id).is(":checked")) {
                        id_annuaires.push($(value[0])[0].id.replace("annuaire-selection-", ""));
                    } else {
                        index = selectedAnnuaireCache.findIndex((el) => el == $(value[0])[0].id.replace("annuaire-selection-", ""));
                        if (index != -1) { selectedAnnuaireCache.splice(index, 1); }
                    }
                }
            });
            for (let index = 0; index < id_annuaires.length; index++) {
                selectedAnnuaireCache.push(id_annuaires[index]);
            }
            selectedAnnuaireCache = selectedAnnuaireCache.filter((value, index, array) => array.indexOf(value) === index);
        });

        function annuaireSuppression(e) {
            let idAnnuaire = e.target.id.replace('confirmation-suppression-annuaire-', '');
            $('#confirmation-suppression-annuaire-' + idAnnuaire).html('<i class="fas fa-spinner fa-spin"></i>')

            let form = new FormData();
            form.append('idAnnuaire', idAnnuaire);
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/annuaire/annuaire/delete',
                contentType: false,
                processData: false,
                data: form,
                success: function () {
                    toastr.success('Annuaire supprimé.', 'Succès');
                    $('#confirmation-suppression-annuaire-' + idAnnuaire).html('Supprimer')
                    $('#modal_suppression_annuaire_' + idAnnuaire).modal('hide');
                }
            });
        }

        // Lancement du loadAjax lors de la fermeture de la modal de suppression d'annuaire
        $('body').on('hidden.bs.modal', "[id^='modal_suppression_annuaire_']", loadAjax);

        const goToAnnuaire = $("#annuaire_results").data("gotoannuaire");
        if (goToAnnuaire != "") {
            loadAjax({ goToAnnuaire: goToAnnuaire });
        } else if (isAnnuairePage) {
            filtreConfigFunOnEvent();
        } else {
            loadAjax();
        }

        // Aller à tel sous-menu
        goToTab = $("#annuaire_results").data("gototab");
    }
});

function annuaireSetup(idAnnuaire) {
    if (goToTab != null) {
        annuaireGoToTab(goToTab, idAnnuaire);
        goToTab = null;
    }
    annuaireInitialModalOpen(idAnnuaire);
    annuaireStatsConnexionSetupAll(idAnnuaire, annuaireSetupAfterStatsConnexion);
    annuaireSelectPickerInit(idAnnuaire);
    annuaireDisabledField(idAnnuaire);
    loadLastDate(idAnnuaire);
    loadLastDateFournisseur(idAnnuaire);
}

function annuaireSetupAfterStatsConnexion(canvas, stats) {
    if (canvas.getAttribute("annuaire-stats-label") == "0") {
        const inYear = stats[Object.keys(stats)[Object.keys(stats).length - 1]];
        const inMonth = inYear[Object.keys(inYear)[Object.keys(inYear).length - 1]];
        const total = Object.values(stats).reduce((a1, c1) => a1 + parseInt(Object.values(c1).reduce((a2, c2) => a2 + c2, 0)), 0);
        $("#annuaire-statsConnexion-month-" + canvas.getAttribute("annuaire-id")).html(inMonth);
        $("#annuaire-statsConnexion-total-" + canvas.getAttribute("annuaire-id")).html(total);
    }
}

function annuaireSelectPickerInit(idAnnuaire) {
    $('#annuaire_isPro_' + idAnnuaire).selectpicker();
    $('#annuaire_typeEntreprise_' + idAnnuaire).selectpicker();
    $('#annuaire_categories_' + idAnnuaire).selectpicker();
    $('#annuaire_marqueCommercialisee_' + idAnnuaire).selectpicker();
    $('#annuaire_marquePanneau_' + idAnnuaire).selectpicker();
    $('#annuaire_civilite_' + idAnnuaire).selectpicker();
    $('#annuaire_situationFamilliale_' + idAnnuaire).selectpicker();
    $('#annuaire_pays_' + idAnnuaire).selectpicker();
    $('#annuaire_qualification_' + idAnnuaire).selectpicker();
    $('#annuaire_qualification_fournisseur_' + idAnnuaire).selectpicker();
    $('#annuaire_typeFre_' + idAnnuaire).selectpicker();
    $('#annuaire_typeVente_' + idAnnuaire).selectpicker();
}

function annuaireDisabledField(idAnnuaire) {
    const typeentreprise = $($("#annuaire_typeEntreprise_" + idAnnuaire)[0].selectedOptions[0]).attr("annuaire-typeentreprise")
    const hasSiret = typeentreprise == "marchand" || typeentreprise == "partenaire financier" || typeentreprise == "prestataire";
    $("#annuaire_nomEntreprise_" + idAnnuaire).prop('disabled', hasSiret);
    $("#annuaire_siren_" + idAnnuaire).prop('disabled', hasSiret);
    $("#annuaire_codePostal_" + idAnnuaire).prop('disabled', hasSiret);
    $("#annuaire_adresse_" + idAnnuaire).prop('disabled', hasSiret);
    $("#annuaire_ville_" + idAnnuaire).prop('disabled', hasSiret);
    if (hasSiret) { $('#annuaire_pays_' + idAnnuaire).prop('selectedIndex', $('#annuaire_fr_id').val()); }
    $("#annuaire_pays_" + idAnnuaire).prop('disabled', hasSiret);
    $("#annuaire_pays_" + idAnnuaire).selectpicker('refresh');
    $("#annuaire_codeNaf_" + idAnnuaire).prop('disabled', hasSiret);
    $("#annuaire_formeJuridique_" + idAnnuaire).prop('disabled', hasSiret);
    $("#annuaire_dateCreationEntreprise_" + idAnnuaire).prop('disabled', hasSiret);
    $("#annuaire_effectif_" + idAnnuaire).prop('disabled', hasSiret);
}

function callApiSiret(idAnnuaire) {
    if (document.getElementById("annuaire_siret_" + idAnnuaire).value.length == 14) {
        const xhr = new XMLHttpRequest();
        xhr.open("GET", "https://public.opendatasoft.com/api/records/1.0/search/?dataset=economicref-france-sirene-v3&q=" + document.getElementById("annuaire_siret_" + idAnnuaire).value + "&rows=10&facet=statutdiffusionetablissement&facet=trancheeffectifsetablissement&facet=etablissementsiege&facet=libellecommuneetablissement&facet=etatadministratifetablissement&facet=activiteprincipaleetablissement&facet=nomenclatureactiviteprincipaleetablissement&facet=caractereemployeuretablissement&facet=unitepurgeeunitelegale&facet=sexeunitelegale&facet=trancheeffectifsunitelegale&facet=categorieentreprise&facet=epcietablissement&facet=departementetablissement&facet=regionetablissement&facet=sectionetablissement&facet=soussectionetablissement&facet=divisionetablissement&facet=groupeetablissement&facet=classeetablissement&facet=sectionunitelegale&facet=soussectionunitelegale&facet=classeunitelegale&facet=naturejuridiqueunitelegale");
        xhr.send();
        xhr.responseType = "json";
        xhr.onload = () => {
            if (xhr.readyState == 4 && xhr.status == 200) {
                const data = xhr.response;
                console.log(data);
                if (data.records.length == 0) {
                    console.log("Pas de résultat");
                    document.getElementById("annuaire_nomEntreprise_" + idAnnuaire).value = document.getElementById("annuaire_siren_" + idAnnuaire).value = document.getElementById("annuaire_codePostal_" + idAnnuaire).value = document.getElementById("annuaire_adresse_" + idAnnuaire).value = document.getElementById("annuaire_ville_" + idAnnuaire).value = document.getElementById("annuaire_codeNaf_" + idAnnuaire).value = document.getElementById("annuaire_formeJuridique_" + idAnnuaire).value = document.getElementById("annuaire_dateCreationEntreprise_" + idAnnuaire).value = document.getElementById("annuaire_effectif_" + idAnnuaire).value = "";
                } else {
                    data.records.forEach(records => {
                        if (records.fields.siret == document.getElementById("annuaire_siret_" + idAnnuaire).value) {
                            document.getElementById("annuaire_nomEntreprise_" + idAnnuaire).value = (typeof records.fields.denominationunitelegale === 'undefined' ? records.fields.nomunitelegale : records.fields.denominationunitelegale);
                            document.getElementById("annuaire_siren_" + idAnnuaire).value = records.fields.siren;
                            if (document.getElementById("annuaire_nomEnseigne_" + idAnnuaire).value == "") {
                                document.getElementById("annuaire_nomEnseigne_" + idAnnuaire).value = (typeof records.fields.enseigne1etablissement === 'undefined' ? "" : records.fields.enseigne1etablissement);
                            }
                            document.getElementById("annuaire_codePostal_" + idAnnuaire).value = records.fields.codepostaletablissement;
                            document.getElementById("annuaire_adresse_" + idAnnuaire).value = (typeof records.fields.complementadresseetablissement !== 'undefined' ? records.fields.complementadresseetablissement + " " + records.fields.adresseetablissement : records.fields.adresseetablissement);
                            document.getElementById("annuaire_ville_" + idAnnuaire).value = records.fields.libellecommuneetablissement;
                            document.getElementById("annuaire_codeNaf_" + idAnnuaire).value = records.fields.activiteprincipaleunitelegale;
                            document.getElementById("annuaire_formeJuridique_" + idAnnuaire).value = records.fields.naturejuridiqueunitelegale;
                            document.getElementById("annuaire_dateCreationEntreprise_" + idAnnuaire).value = records.fields.datecreationunitelegale;
                            document.getElementById("annuaire_effectif_" + idAnnuaire).value = (typeof records.fields.trancheeffectifsunitelegale === 'undefined' ? "" : records.fields.trancheeffectifsunitelegale);
                            tmpStoreGeo[idAnnuaire] = records.fields.geolocetablissement;
                        }
                    });
                }
            } else {
                console.log(`Error: ${xhr.status}`);
            }
        };
    } else {
        document.getElementById("annuaire_nomEntreprise_" + idAnnuaire).value = document.getElementById("annuaire_siren_" + idAnnuaire).value = document.getElementById("annuaire_codePostal_" + idAnnuaire).value = document.getElementById("annuaire_adresse_" + idAnnuaire).value = document.getElementById("annuaire_ville_" + idAnnuaire).value = document.getElementById("annuaire_codeNaf_" + idAnnuaire).value = document.getElementById("annuaire_formeJuridique_" + idAnnuaire).value = document.getElementById("annuaire_dateCreationEntreprise_" + idAnnuaire).value = document.getElementById("annuaire_effectif_" + idAnnuaire).value = "";
    }
}

function annuaireUpdateFiltreIsPro() {
    if ($("#filtersannuaire-isPro").val() == "0") {
        let listOnlyMarchand = $("[class~='display-filtersannuaire-onlyMarchand']");
        for (let index = 0; index < listOnlyMarchand.length; index++) {
            $(listOnlyMarchand[index]).addClass('d-none');
        }
        let listOnlyFournisseur = $("[class~='display-filtersannuaire-onlyFournisseur']");
        for (let index = 0; index < listOnlyFournisseur.length; index++) {
            $(listOnlyFournisseur[index]).addClass('d-none');
        }
        let listOnlyPro = $("[class~='display-filtersannuaire-onlyPro']");
        for (let index = 0; index < listOnlyPro.length; index++) {
            $(listOnlyPro[index]).addClass('d-none');
        }
        let listOnlyPart = $("[class~='display-filtersannuaire-onlyPart']");
        for (let index = 0; index < listOnlyPart.length; index++) {
            $(listOnlyPart[index]).removeClass('d-none');
        }
        let listNoMarchand = $("[class~='display-filtersannuaire-noMarchand']");
        for (let index = 0; index < listNoMarchand.length; index++) {
            $(listNoMarchand[index]).removeClass('d-none');
        }
    } else if ($("#filtersannuaire-isPro").val() == "1") {
        let listOnlyPart = $("[class~='display-filtersannuaire-onlyPart']");
        for (let index = 0; index < listOnlyPart.length; index++) {
            $(listOnlyPart[index]).addClass('d-none');
        }
        let listOnlyPro = $("[class~='display-filtersannuaire-onlyPro']");
        for (let index = 0; index < listOnlyPro.length; index++) {
            $(listOnlyPro[index]).removeClass('d-none');
        }
    } else {
        let listOnlyMarchand = $("[class~='display-filtersannuaire-onlyMarchand']");
        for (let index = 0; index < listOnlyMarchand.length; index++) {
            $(listOnlyMarchand[index]).addClass('d-none');
        }
        let listOnlyFournisseur = $("[class~='display-filtersannuaire-onlyFournisseur']");
        for (let index = 0; index < listOnlyFournisseur.length; index++) {
            $(listOnlyFournisseur[index]).addClass('d-none');
        }
        let listOnlyPro = $("[class~='display-filtersannuaire-onlyPro']");
        for (let index = 0; index < listOnlyPro.length; index++) {
            $(listOnlyPro[index]).addClass('d-none');
        }
        let listOnlyPart = $("[class~='display-filtersannuaire-onlyPart']");
        for (let index = 0; index < listOnlyPart.length; index++) {
            $(listOnlyPart[index]).addClass('d-none');
        }
    }
    annuaireUpdateFiltreTypeEntreprise();
}

function annuaireUpdateFiltreTypeEntreprise() {
    if ($("#filtersannuaire-isPro").val() == "1" && $("#filtersannuaire-typeEntreprise")[0].selectedOptions.length <= 1 && $($("#filtersannuaire-typeEntreprise")[0].selectedOptions[0]).data("typeentreprise") == "marchand") {
        let listOnlyMarchand = $("[class~='display-filtersannuaire-onlyMarchand']");
        for (let index = 0; index < listOnlyMarchand.length; index++) {
            $(listOnlyMarchand[index]).removeClass('d-none');
        }
        let listNoMarchand = $("[class~='display-filtersannuaire-noMarchand']");
        for (let index = 0; index < listNoMarchand.length; index++) {
            $(listNoMarchand[index]).addClass('d-none');
        }
    } else if ($("#filtersannuaire-isPro").val() == "1" && $("#filtersannuaire-typeEntreprise")[0].selectedOptions.length <= 1 && $($("#filtersannuaire-typeEntreprise")[0].selectedOptions[0]).data("typeentreprise") == "fournisseur") {
        let listOnlyFournisseur = $("[class~='display-filtersannuaire-onlyFournisseur']");
        for (let index = 0; index < listOnlyFournisseur.length; index++) {
            $(listOnlyFournisseur[index]).removeClass('d-none');
        }
    } else {
        let listOnlyMarchand = $("[class~='display-filtersannuaire-onlyMarchand']");
        for (let index = 0; index < listOnlyMarchand.length; index++) {
            $(listOnlyMarchand[index]).addClass('d-none');
        }
        let listOnlyFournisseur = $("[class~='display-filtersannuaire-onlyFournisseur']");
        for (let index = 0; index < listOnlyFournisseur.length; index++) {
            $(listOnlyFournisseur[index]).addClass('d-none');
        }
        let listNoMarchand = $("[class~='display-filtersannuaire-noMarchand']");
        for (let index = 0; index < listNoMarchand.length; index++) {
            $(listNoMarchand[index]).removeClass('d-none');
        }
    }
}

function getAnnuaireListId() {
    // On récupère les id des lignes affichées
    annuaire_table.rows().invalidate();
    let all_data = annuaire_table.rows().data();
    let listId = [];
    $.each(all_data, function (key, value) {
        let tr = $('#annuaire-tr-' + value[(isAnnuairePage ? 0 : 1)]["@data-search"].replace("id:[", "").replace("]", ""));
        if (tr.length != 0) {
            listId.push(value[(isAnnuairePage ? 0 : 1)]["@data-search"].replace("id:[", "").replace("]", ""));
        }
    });
    return listId;
}

function annuaireLastDateActionGenerator(listId, i = 0, chunkSize = 2) {
    let j = i + chunkSize;
    let optionsRecherche = new FormData();
    optionsRecherche.append('listId', listId.slice(i, j));
    // Envoi une requète pour calculer les status des lignes affichées
    $.ajax({
        type: 'POST',
        url: siteURL + 'admin/annuaire/annuaire_lastDateAction_generator',
        contentType: false,
        processData: false,
        data: optionsRecherche,
        success: function (data) {
            // On boucle sur chacun des id
            Object.keys(data).forEach(element => {
                // Si la ligne est affichée
                if ($("#annuaire-tr-" + element).length != 0) {
                    if (data[element] != "-") {
                        $("#annuaire-tr-" + element).find(".annuaire-date-derniere-action-container").parent()[0].setAttribute("data-order", data[element].replace(/(\d+)\D+(\d+)\D+(\d+)\D+(\d+)\D+(\d+)/g, "$3-$2-$1 $4:$5"));
                    }
                    $("#annuaire-tr-" + element).find(".annuaire-date-derniere-action-container").html(data[element]);
                }
            });
        },
        complete: function () {
            if (j < listId.length) {
                annuaireLastDateActionGenerator(listId, j, chunkSize);
            }
        }
    });
}

function annuaireIndicatorGenerator(listId, i = 0, chunkSize = 5) {
    let j = i + chunkSize;
    let optionsRecherche = new FormData();
    optionsRecherche.append('listId', listId.slice(i, j));
    // Envoi une requète pour calculer les status des lignes affichées
    $.ajax({
        type: 'POST',
        url: siteURL + 'admin/annuaire/annuaire_indicator_generator',
        contentType: false,
        processData: false,
        data: optionsRecherche,
        success: function (data) {
            // On boucle sur chacun des id
            Object.keys(data).forEach(element => {
                // Si la ligne est affichée
                if ($("#annuaire-tr-" + element).length != 0) {
                    if (data[element]) {
                        // Si on a un résultat de statut
                        let diff = dateDiff(Date.parse(data[element].date), Date.now());
                        let lastConnexion = new Date(data[element].date);
                        $("#annuaire-tr-" + element).find(".annuaire-indicateur-connexion").attr("data-indicateur", (diff.day <= 5 ? 1 : (diff.day <= 10 ? 2 : (diff.day <= 15 ? 3 : (diff.day <= 20 ? 4 : 5)))));
                        $("#annuaire-tr-" + element).find(".annuaire-indicateur-connexion").attr(
                            "data-title",
                            "Dernière connexion le " + lastConnexion.toLocaleDateString('fr-FR', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) +
                            " | Il y a " + drawDateDiff(diff)
                        );
                        $("#annuaire-tr-" + element).find(".annuaire-indicateur-connexion-col").html(
                            lastConnexion.toLocaleDateString('fr-FR', {
                                day: '2-digit',
                                month: '2-digit',
                                year: 'numeric'
                            }) + " à " +
                            lastConnexion.getHours().toString().padStart(2, '0') + ":" +
                            lastConnexion.getMinutes().toString().padStart(2, '0')
                        );
                    } else if ($("#annuaire-tr-" + element).attr("data-actif")) {
                        // Si on a pas de résultat de statut mais que la boutique est actif
                        $("#annuaire-tr-" + element).find(".annuaire-indicateur-connexion").attr("data-indicateur", 5);
                        $("#annuaire-tr-" + element).find(".annuaire-indicateur-connexion").attr("data-title", "Il n'y a eu aucune connexion")
                        $("#annuaire-tr-" + element).find(".annuaire-indicateur-connexion-col").html("Aucune connexion");
                    } else {
                        // Si la boutique est inactif
                        $("#annuaire-tr-" + element).find(".annuaire-indicateur-connexion").attr("data-indicateur", 0);
                        $("#annuaire-tr-" + element).find(".annuaire-indicateur-connexion").attr("data-title", "Cette boutique n'est pas activé");
                        $("#annuaire-tr-" + element).find(".annuaire-indicateur-connexion-col").html("Boutique non active");
                    }
                }
            });
        },
        complete: function () {
            if (j < listId.length) {
                annuaireIndicatorGenerator(listId, j, chunkSize);
            }
        }
    });
}

function annuaireExportGenerator(optionsRecherche, annuaireExportData = [], i = 0, chunkSize = 100) {
    let j = i + chunkSize;
    optionsRecherche.append('offset', i);
    optionsRecherche.append('limit', chunkSize);
    $.ajax({
        type: 'POST',
        url: siteURL + 'admin/annuaire/export/excel/preload',
        contentType: false,
        processData: false,
        data: optionsRecherche,
        success: function (data) {
            annuaireExportData.push(...data);
            console.log(annuaireExportData);
            if (data.length) {
                annuaireExportGenerator(optionsRecherche, annuaireExportData, j, chunkSize);
            } else {
                // Conversion des données à exporter pour éviter les erreurs du serveur sur les SELECT dans les noms d'entreprise, etc
                let utf8Array = new TextEncoder().encode(annuaireExportData.join(";||;"));
                let chunkSize = 500;
                let result = '';

                for (let i = 0; i < utf8Array.length; i += chunkSize) {
                    result += String.fromCharCode(...utf8Array.slice(i, i + chunkSize));
                }
                // let utf8String = String.fromCharCode(...utf8Array);
                let encoded = window.btoa(result);

                let annuaireExportDataForm = new FormData();
                annuaireExportDataForm.append("exportdata", encoded);
                $.ajax({
                    type: 'POST',
                    url: siteURL + 'admin/annuaire/export/excel',
                    contentType: false,
                    processData: false,
                    data: annuaireExportDataForm,
                    success: function (data) {
                        toastr.success('Le fichier excel est fini', 'Succès');
                        var element = document.createElement('a');
                        element.setAttribute('href', siteURL + data);
                        element.setAttribute('download', "Export " + (new Date()).toLocaleDateString("fr") + ".xlsx");
                        document.body.appendChild(element);
                        element.click();
                        document.body.removeChild(element);
                    },
                    error: function () {
                        toastr.error("Erreur lors de l'export", "Erreur");
                    }
                })
            }
        }
    });
}

function annuaireRefreshSideCard(idAnnuaire) {
    if ($("#annuaire-side-card-" + idAnnuaire).length) {
        $.ajax({
            type: 'GET',
            url: siteURL + 'admin/annuaire/side-card/' + idAnnuaire,
            success: function (data) {
                $("#annuaire-side-card-" + idAnnuaire).html(data);
                annuaireStatsConnexionSetup(idAnnuaire, annuaireSetupAfterStatsConnexion);
            }
        });
    }
}

function annuaireInitialModalOpen(idAnnuaire) {
    const annuaireInitialModal = $("#annuaire-initial-modal-" + idAnnuaire);
    if (annuaireInitialModal.length) { annuaireInitialModal.modal('show'); }
}

function annuaireGoToTab(goToTab, idAnnuaire) {
    $("#annuaire-sub-tab-" + goToTab + "-" + idAnnuaire).trigger('click');
}