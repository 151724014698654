$(document).ready( function () {

	// Génération de la datatable des factures
    facturemandat_table = $('#facturemandat_table').DataTable( {
        "autoWidth": false,
    	"language": {
            "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/fr-FR.json"
        },
        "responsive": true,
    	"ajax": {
            "url": siteURL+'json/facturemandat',
            "type": "POST",
        },
    	"order": [[ 1, "desc" ]],
    	"columnDefs": [
    		{
                "className": "text-center align-middle",
                "targets": [4,5],
            },
    		{
                "className": "text-right",
                "targets": [2],
            },
            // ALIGN MIDDLE
            {
                "className": "align-middle",
                "targets": '_all',
            },

            // DATE
    		{
    			"width": "80px",
    			"targets": 0,
    			"render": function ( data, type, row ) {
    				return row[1];
                },
    		},
            // NUMERO
    		{
    			"width": "80px",
    			"targets": 1,
    			"render": function ( data, type, row ) {
    				return row[2];
                },
    		},
    		// MONTANT
    		{
    			"width": "90px",
                "targets": 2,
    			"render": function ( data, type, row ) {
    				if (row[3].startsWith('-')) {
    					return '<b style="color:red;">'+row[3]+' &euro;</b>';
    				}
    				else {
    					return '<b style="color:green;">&nbsp;'+row[3]+' &euro;</b>';
    				}
                },
    		},
    		// VEHICULE
    		{
                "targets": 3,
    			"render": function ( data, type, row ) {
    				return row[4];
                },
    		},
    		// AVOIR
    		{
    			"width": "90px",
                "targets": 4,
    			"render": function ( data, type, row ) {
    				if (row[3].startsWith('-')) {
    					return '<b>AVOIR</b>';
    				}
    				else {
    					return '<b>FACTURE</b>';
    				}
                },
    		},
    		// PDF
    		{
    			"width": "60px",
                "targets": 5,
    			"render": function ( data, type, row ) {
                    return '<a target="_blank" href="'+siteURL+'admin/outils/facturemandat/pdf/'+row[0]+'"><i class="far fa-file-pdf fa-2x"></i></a>';
                },
                "orderable": false,
    		},
    		// AVOIR
    		{
    			"width": "150px",
                "targets": 6,
    			"render": function ( data, type, row ) {
    				if (row[5] == null) {
    					return '<a class="cursor-pointer" id="facturemandat-createavoir-'+row[0]+'" data-facture="'+row[2]+'" style="font-size:10px"><i class="fad fa-recycle pr-2"></i>G&Eacute;N&Eacute;RER UN AVOIR</a>';
    				}
    				if (row[3].startsWith('-')) {
                    	return '<a target="_blank" href="'+siteURL+'admin/outils/facturemandat/pdf/'+row[6]+'"><i class="far fa-file-pdf pr-2"></i><b style="font-size:10px">'+row[5]+' (FACTURE)</b></a>';
                    }
                    return '<a target="_blank" href="'+siteURL+'admin/outils/facturemandat/pdf/'+row[6]+'"><i class="far fa-file-pdf pr-2"></i><b style="font-size:10px">'+row[5]+' (AVOIR)</b></a>';
                },
                "orderable": false,
    		},
    	],
		"createdRow": function( row, data, dataIndex ) {
						if ( data[3].startsWith('-') ) {
		         			$(row).addClass('bg-lightred');
		       			}
		       			else {
		       				$(row).addClass('bg-lightgreen');
		       			}
					}
    });

    // Envoi de modification d'une marque
    $('body').on('click', "#facturemandat-send-modify", function() {
        var form = new FormData();
        // On extrait le contenu du formulaire dans la variable 'form'
        $('[id^="facture_mandat_"]').each(function () {
        	form.append(this.id, $(this).val());
        })
        $.ajax({
            type: 'POST',
            url: siteURL + 'admin/outils/facturemandat/save',
            cache: false,
            contentType: false,
            processData: false,
            data: form,
            success: function(data) {
                // TOAST
                toastr.success('La facture a bien été enregistrée', 'Succès');
                $('[id^="facture_mandat_"]').each(function () {
        			$(this).val("");
        		})
                // Retour à la liste d'actualités en rechargeant la datatable
                $('#facturemandat-tabs-list-tab').trigger('click');
                //facturemandat_table.ajax.reload();
            }
        });
         // Empèche le 'button' de fonctionner
        return false;
    });

    $('body').on('keyup', '[id^="facture_mandat_"]', function() {
    	if ($(this).hasClass('bg-danger')) { $(this).removeClass('bg-danger');}
    })

    $('body').on('click', "#facturemandat-verify", function() {
     var form = new FormData();
     var complete = true;
        // On extrait le contenu du formulaire dans la variable 'form'
        $('[id^="facture_mandat_"]').each(function () {
        	if ($(this).val() == "") {
        		$(this).addClass('bg-danger');
        		complete = false;
        	}
        	form.append(this.id, $(this).val());
        })
        if (complete) {
	        $.ajax({
	            type: 'POST',
	            url: siteURL + 'admin/outils/facturemandat/preview',
	            cache: false,
	            contentType: false,
	            processData: false,
	            data: form,
	            success: function(data) {
	            	var lobibox_preview = Lobibox.window({
	            		width: "700",
						title: 'Prévisualisation',
						baseClass: "modal-content modal-lobistrap",
						content: data,
						buttons: {
						    accept: {
						        text: 'Valider'
						    },
						    close: {
						        text: 'Modifier',
						    }
						},
						callback: function($this, type, ev){
						    if (type === 'accept'){
						        $('#facturemandat-send-modify').prop("disabled", false);
						        lobibox_preview.destroy();
						    }
						    if (type === 'close'){
						    	lobibox_preview.destroy();
						    }
						}
					});

	            }
	        });
	    }
	    else {
	        var lobibox_error = Lobibox.alert('warning', {
	        	title: 'Erreur',
				msg: 'Des informations sont manquantes sur votre facture, merci de bien vouloir les saisir pour être en mesure de la générer.',
				buttons: {
				    oui: {
				        'class': 'btn btn-info',
				        text: 'OK'
				    },
				},
				callback: function(lobibox, type){
				    if (type === 'oui'){
				    	lobibox_error.destroy();
				    }
				}
			});
	    }
	    // Empèche le 'button' de fonctionner
        return false;
    });


    $('body').on('click', "[id^='facturemandat-createavoir-']", function() {
    	that = this;
		var lobibox_confirm = Lobibox.alert('warning', {
			title: 'Attention',
			msg: 'Etes-vous sûr de vouloir créer un avoir sur la facture '+$(this).data('facture')+' ?',
			buttons: {
			    oui: {
			        'class': 'btn btn-info',
			        text: 'OUI'
			    },
			    non: {
			        'class': 'btn btn-danger',
			        text: 'NON'
			    },
			},
			callback: function(lobibox, type){
			    if (type === 'oui'){
			    	lobibox_confirm.destroy();
			    	var form = new FormData();
			        form.append("id", that.id.replace('facturemandat-createavoir-', ""));
			        $.ajax({
			            type: 'POST',
			            url: siteURL + 'admin/outils/facturemandat/createavoir',
			            cache: false,
			            contentType: false,
			            processData: false,
			            data: form,
			            success: function(data) {
			            	$('#facturemandat-tabs-list-tab').trigger('click');
			            }
			        });
			         // Empèche le 'button' de fonctionner
			    }
			    else if (type === 'non'){
			    	lobibox_confirm.destroy();
			    }
			}
		});
		return false;
    });
});