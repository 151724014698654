function getMargeStatus(marge, achat) {
    marge = parseFloat(marge); achat = parseFloat(achat);
    let status = "gris", up = 0, down = 0;
    if (!isNaN(marge) && !isNaN(achat)) {
        if (achat <= 10000) { up = 1800; down = 800; }
        else if (achat <= 15000) { up = 2100; down = 960; }
        else if (achat <= 20000) { up = 2400; down = 960; }
        else if (achat <= 25000) { up = 2700; down = 960; }
        else if (achat <= 30000) { up = 3000; down = 1460; }
        else if (achat <= 35000) { up = 3300; down = 1460; }
        else if (achat <= 40000) { up = 3600; down = 1460; }
        else { up = 4200; down = 1460; }
        if (marge >= up) { status = "vert"; } else if (marge > down) { status = "jaune"; } else { status = "rouge"; }
    }
    return {
        status: status,
        up: up,
        down: down
    };
}

function getMargeScale(marge, up) {
    marge = parseFloat(marge);
    let min = 0;
    let max = up * 1.5;
    if (marge - min > max) { max = marge - min; }
    return {
        min: min,
        max: max
    };
}

function getMargeWidth(marge, status, scale) {
    const width = marge > scale.min ? Math.round(((marge - scale.min) / scale.max) * 100) : 0;
    const bad = scale.max > 0 ? Math.round(((status.down - scale.min) / scale.max) * 100) : 0;
    const avg = scale.max > 0 ? Math.round(((status.up - scale.min) / scale.max) * 100) - bad : 0;
    const good = scale.max > 0 ? 100 - bad - avg : 0;
    return {
        bad: bad < width ? bad : Math.max(width, 0),
        avg: bad + avg < width ? avg : Math.max(width - bad, 0),
        good: bad + avg + good < width ? good : Math.max(width - bad - avg, 0),
        width: width
    };
}

function getMargeDisplay(marge, achat) {
    achat = parseFloat(achat); marge = parseFloat(marge);
    const status = getMargeStatus(marge, achat);
    const scale = getMargeScale(marge, status.up);
    const width = getMargeWidth(marge, status, scale);
    return {
        display: {
            bad: { color: "#EA4335", width: width.bad },
            avg: { color: "#FBBC05", width: width.avg },
            good: { color: "#34A853", width: width.good },
            leftover: { color: "#BDBDBD", width: 100 - width.bad - width.avg - width.good }
        },
        status: status,
        scale: scale,
        width: width
    };
}