/**
 * Update complète du formulaire VF
 * @param {number} idVF
 * @param {number} oldIdOF
 * @param {number} newIdOF
 * @param {array} tabIdVf
 * @param {number} index
 * @param {boolean} tabClose
 * @returns {any}
 */
const updateVF = (idVF, oldIdOF = null, newIdOF = null, tabIdVf = null, index = null, tabClose = true) => {
    const goToNext = (oldIdOF = null, newIdOF = null, tabIdVf = null, index = null) => {
        if (tabIdVf != null) {
            index++;
            if (tabIdVf.length == index) {
                if (oldIdOF == 0) {
                    ofResetAddForm();
                    ofShowModify(newIdOF);
                } else {
                    $('#offre_fournisseur-tabs-modify-' + oldIdOF).remove();
                    $('#offre_fournisseur-close-modify-' + oldIdOF).parent().remove();
                    $("#offre_fournisseur-tabs-list-tab").trigger('click');
                }
            } else {
                updateVF(tabIdVf[index], oldIdOF, newIdOF, tabIdVf, index);
            }
        }
    }
    if ($('#vehicule_fournisseur_input_vin_' + idVF).length) {
        if (tabClose == false) {
            updateBodyworkDamage(idVF)
        }
        const vf = {};
        vf.id = idVF;
        vf.immatriculation = $('#vehicule_fournisseur_input_immatriculation_' + idVF).val();
        vf.vin = $('#vehicule_fournisseur_input_vin_' + idVF).val();
        vf.marque = $('#vehicule_fournisseur_input_marque_' + idVF).val();
        vf.modele = $('#vehicule_fournisseur_input_modele_' + idVF).val();
        vf.version = $('#vehicule_fournisseur_input_version_' + idVF).val();
        vf.boite = $('#vehicule_fournisseur_input_boite_' + idVF).val();
        vf.carburant = $('#vehicule_fournisseur_input_carburant_' + idVF).val();
        vf.date_mec = $('#vehicule_fournisseur_input_date_mec_' + idVF).val();
        if (newIdOF != null) { vf.date_mec = vf.date_mec.replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$3-$2-$1"); }
        vf.year = $('#vehicule_fournisseur_input_year_' + idVF).val();
        vf.kilometrage = $('#vehicule_fournisseur_input_kilometrage_' + idVF).val();
        vf.lien_expertise = $('#vehicule_fournisseur_input_lien_expertise_' + idVF).val();
        vf.couleur = $('#vehicule_fournisseur_input_couleur_' + idVF).val();
        vf.puissance_din = $('#vehicule_fournisseur_input_puissance_din_' + idVF).val();
        vf.option_v = $('#vehicule_fournisseur_input_option_v_' + idVF).val();
        vf.commentaire = $('#vehicule_fournisseur_input_commentaire_' + idVF).val();
        vf.fournisseur = $('#' + (oldIdOF == null ? "vehicule_fournisseur" : "offre_fournisseur") + '_input_fournisseur_' + (oldIdOF == null ? idVF : oldIdOF)).val();
        vf.images = {};
        for (let element of $('[id^="file_result_image"][id$="_' + idVF + '"]')) { vf.images[element.id.replace("file_result_image", "").replace("_" + idVF, "")] = $(element).val(); }
        if (idVF != 0) {
            vf.lien_annonce_fournisseur = $('#vehicule_fournisseur_input_lien_annonce_fournisseur_' + idVF).val();
            vf.prix_achat_ht_monnaie_locale = $('#vehicule_fournisseur_input_prix_achat_ht_monnaie_locale_' + idVF).val();
            vf.tva = $('#vehicule_fournisseur_input_tva_' + idVF).val();
            vf.prix_achat_ht = $('#vehicule_fournisseur_input_prix_achat_ht_' + idVF).val();
            vf.prix_achat_ttc = $('#vehicule_fournisseur_input_prix_achat_ttc_' + idVF).val();
            vf.prix_transport_ht = $('#vehicule_fournisseur_input_prix_transport_ht_' + idVF).val();
            vf.prix_transport_ttc = $('#vehicule_fournisseur_input_prix_transport_ttc_' + idVF).val();
            vf.malus = $('#vehicule_fournisseur_input_malus_' + idVF).val();
            vf.emissionCO2 = $('#vehicule_fournisseur_input_emissionCO2_' + idVF).val();
            vf.prix_marche_ttc = $('#vehicule_fournisseur_input_prix_marche_ttc_' + idVF).val();
            vf.prix_proposition = $('#vehicule_fournisseur_input_prix_proposition_' + idVF).val();
            vf.marge_ht = $('#vehicule_fournisseur_input_marge_ht_' + idVF).val();
            vf.marge_ttc = $('#vehicule_fournisseur_input_marge_ttc_' + idVF).val();
            vf.prix_revient_marchand_ttc = $('#vehicule_fournisseur_input_prix_revient_marchand_ttc_' + idVF).val();
            vf.prix_revient_particulier_ttc = $('#vehicule_fournisseur_input_prix_revient_particulier_ttc_' + idVF).val();
            vf.input_lien_etude_prix_marche_lacentrale = $('#vehicule_fournisseur_lacentrale_' + idVF).val();
            vf.input_lien_etude_prix_marche_leboncoin = $('#vehicule_fournisseur_leboncoin_' + idVF).val();
            vf.mechanical_damage_ht = $('#vehicule_fournisseur_input_mechanical_damage_ht_' + idVF).val();
            vf.mechanical_damage_ttc = $('#vehicule_fournisseur_input_mechanical_damage_ttc_' + idVF).val();
            vf.bodywork_damage_ht = $('#vehicule_fournisseur_input_bodywork_damage_ht_' + idVF).val();
            vf.bodywork_damage_ttc = $('#vehicule_fournisseur_input_bodywork_damage_ttc_' + idVF).val();
            vf.fre_ht = $('#vehicule_fournisseur_input_fre_ht_' + idVF).val();
            vf.fre_ttc = $('#vehicule_fournisseur_input_fre_ttc_' + idVF).val();
            vf.nbPlace = $('#vehicule_fournisseur_input_nbPlace_' + idVF).val();
            vf.delaiProjet = $('#vehicule_fournisseur_input_delaiProjet_' + idVF).val();
            if ($('#vehicule_fournisseur_input_messageWhatsapp_' + idVF).length) { vf.messageWhatsapp = $('#vehicule_fournisseur_input_messageWhatsapp_' + idVF).val(); }
            if ($("#vehicule_fournisseur_quick_expertise_" + idVF).length) { vf.quick_expertise = JSON.stringify(quickExpertiseValue("vehicule_fournisseur_quick_expertise_" + idVF)); }
            if (newIdOF == null) { // Si pas OF
                vf.is_verif_by = [];
                $('#vehicule_fournisseur_input_is_verif_by_' + idVF).find("option").each((k, o) => {
                    if ($(o).is(":selected")) { vf.is_verif_by.push(o.value); }
                });
                vf.is_envoi = ($('#vehicule_fournisseur_input_is_envoi_' + idVF).val() == "on" ? "1" : "0");
                vf.is_visible_pro = ($('#vehicule_fournisseur_input_is_visible_pro_' + idVF).val() == "on" ? "1" : "0");
                vf.date_fin_dispo_pro = $('#vehicule_fournisseur_input_date_fin_dispo_pro_' + idVF).val();
                vf.partslink = $('#file_result_partslink_' + idVF).val();
            } else { // Si OF
                vf.idOF = newIdOF;
            }
            vf.statut = getQuickStudyStatus(idVF);
        }

        // Check
        let error_message = "";
        error_message += vfCheckVin(vf.vin);
        error_message += vfCheckMarque(vf.marque);
        error_message += vfCheckModele(vf.modele);
        error_message += vfCheckVersion(vf.version);
        error_message += vfCheckBoite(vf.boite);
        error_message += vfCheckCarburant(vf.carburant);
        error_message += vfCheckDateMec(vf.date_mec);
        error_message += vfCheckYear(vf.year);
        error_message += vfCheckKilometrage(vf.kilometrage);
        error_message += vfCheckCouleur(vf.couleur);
        error_message += vfCheckPuissanceDin(vf.puissance_din);
        error_message += vfCheckFournisseur(vf.fournisseur);
        if (idVF != 0 && (oldIdOF == null || oldIdOF !=0)) {
            error_message += vfCheckLienExpertise(vf.lien_expertise);
            error_message += vfPrixAchat(vf.prix_achat_ht_monnaie_locale);
            error_message += vfCheckOra7ProAvailability(vf.is_visible_pro, vf.date_fin_dispo_pro);
        }

        // Envoi
        if (error_message != "") {
            toastr.error(error_message, 'Erreur');
            $("#vehicule_fournisseur-send-modify-" + idVF).removeAttr("disabled");
            // } else if (vf.tva == "1" && (parseInt(vf.kilometrage) <= 6000 && ((new Date().getFullYear() - new Date(vf.date_mec).getFullYear()) * 12) + new Date().getMonth() - new Date(vf.date_mec).getMonth() <= 6)) {
            //     toastr.error("Impossible to register a vehicle of less than 6 months and less than 6000 kms with a VAT on margin.", 'Erreur');
        } else {
            let form = new FormData();
            form.append('vf', JSON.stringify(vf));
            form.append('sendwhatsapp', $("#vehicule_fournisseur-send-modify-" + idVF).attr('data-sendwhatsapp'));
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/vehicule_fournisseur/update/modify',
                contentType: false,
                processData: false,
                data: form,
                success: function (newId) {
                    if ($('#vehicule_fournisseur-send-modify-' + idVF).attr("data-sendwhatsapp") == "1") {
                        $('#vehicule_fournisseur-send-modify-' + idVF).attr("data-sendwhatsapp", "0");
                    }
                    if (tabClose) {
                        if (newIdOF == null) {
                            if (idVF != 0) {
                                toastr.success('The supplier vehicle has been registered', 'Success');
                                $("#vehicule_fournisseur-send-modify-" + idVF).removeAttr("disabled");
                            } else {
                                // On modifie le lien pour le nouveau VF et on clique dessus
                                window.location.replace(siteURL + "admin/vehicule_fournisseur/" + newId);
                            }
                        }
                        goToNext(oldIdOF, newIdOF, tabIdVf, index);
                    }
                },
                error: function () {
                    $("#vehicule_fournisseur-send-modify-" + idVF).removeAttr("disabled");
                }
            });
        }
    } else {
        goToNext(oldIdOF, newIdOF, tabIdVf, index);
    }
}
