// Mise à jour d'une carrosserie de modele
$('body').on('click', "[id^='change_carrosserie_button_']", function() {
    let modifiedModele = this.id.replace("change_carrosserie_button_", "");
    let selectedCarrosserie = $("#change_carrosserie_choice_"+modifiedModele).find('option:selected').val();

    // Si aucune carrosserie n'a été selectionnée
    if (selectedCarrosserie == "") {
        toastr.error('Merci de selectionner une couleur', "Erreur");
        return false;
    }

    // Initialisation du Form à envoyer
    var form = new FormData();
    form.append('id', modifiedModele);
    form.append('carrosserie', selectedCarrosserie);

    // Requète Ajax
    $.ajax({
        type: 'POST',
        url: siteURL+'admin/vehicules/modeles_carrosserie/update',
        data: form,
        contentType: false,
        processData: false,
        success: function(data) {
            if (data == "ok") {
                toastr.success('La carrosserie a été modifiée ainsi que pour ses véhicules associés', "Succès");
                $("#change_carrosserie_button_"+modifiedModele).addClass('bg-success').html('<i class="fas fa-check"></i>');
            }
            else {
                toastr.error('Une erreur est survenue', "Erreur");
            }
        }
    });
});