$(document).ready( function () {

	// Génération de la table des évènements
    centre_table = $('#centres_table').DataTable( {
        "autoWidth": false,
    	"language": {
            "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/fr-FR.json"
        },
        "responsive": true,
    	"ajax": siteURL + 'json/centres',
    	"order": [[ 1, "asc" ]],
    	"columnDefs": [
            { responsivePriority: 1, targets: 5 },
            { responsivePriority: 2, targets: 0 },
            { responsivePriority: 3, targets: 1 },
            { responsivePriority: 4, targets: 2 },
            { responsivePriority: 5, targets: 3 },
            { responsivePriority: 6, targets: 4 },
            // TEXT CENTER
            {
                "className": "text-center align-middle",
                "targets": [5],
            },
            // ALIGN MIDDLE
            {
                "className": "align-middle",
                "targets": '_all',
            },
            // NOM
            {
                "targets": 0,
                "render": function ( data, type, row ) {
                    return row[1];
                },
            },
            // CODE
            {
                "targets": 1,
                "render": function ( data, type, row ) {
                    return row[2];
                },
            },
            // ADRESSE
    		{
                "targets": 2,
    			"render": function ( data, type, row ) {
    				if (row[4] != null) {
                     return row[3] + '<br/>' + row[4] + ' ' + row[5];
    				}
    				else { return '--';}
                 }
    		},
            // TELEPHONE
    		{
                "targets": 3,
    			"render": function ( data, type, row ) {
    				return row[6];
                 },
    		},
            // EMAIL
    		{
                "targets": 4,
 	 			"render": function ( data, type, row ) {
    				return "<span class='text-lowercase'>"+row[7]+"</span>";
                 },
    		},
            // IS VISIBLE SEO
            {
                "targets": 5,
                "render": function ( data, type, row ) {
                    if (row[8] == 1) {
                    return '<i class="fas fa-check" style="color:green" title="Validé"></i><span class="d-inline d-lg-none pl-2">OUI</span>';
                } else {
                    return '<i class="fas fa-times" style="color:red" title="Non Validé"></i><span class="d-inline d-lg-none pl-2">NON</span>';
                }
                 },
            },
            // MODIFY
    		{
                "width": "50px",
                "targets": 6,
    			"render": function ( data, type, row ) {
                    return '<button class="btn w-100 p-0" id="centre-open-modify-'+row[0]+'" data-category="web"><i class="fad fa-edit"></i>';
                },
                "orderable": false,
    		},
    	],
        "conditionalPaging": true,
    });

    // Envoi de modification d'un centre
    $('body').on('click', "button[id^='centre-send-modify-']", function() {
        var id_selected = extractId(this.id);

        var form = new FormData();
        // On extrait le contenu du formulaire dans la variable 'form'
        form.append('id', id_clicked);
        form.append('centre_name', $('#centre_name_'+id_clicked).val());
        form.append('centre_namebeautify', $('#centre_namebeautify_'+id_clicked).val());
        form.append('centre_adresse', $('#centre_adresse_'+id_clicked).val());
        form.append('centre_codepostal', $('#centre_codepostal_'+id_clicked).val());
        form.append('centre_ville', $('#centre_ville_'+id_clicked).val());
        form.append('centre_telephone', $('#centre_telephone_'+id_clicked).val());
        form.append('centre_email', $('#centre_email_'+id_clicked).val());
        form.append('centre_namerewrite', $('#centre_namerewrite_'+id_clicked).val());
        form.append('centre_video', $('#centre_video_'+id_clicked).val());
        form.append('centre_image', $('#file_result_0').val());
        form.append('centre_image_interieur', $('#file_result_1').val());
        form.append('centre_image_exterieur', $('#file_result_2').val());
        form.append('centre_image_360', $('#file_result_3').val());

        // On mets les différents formulaires des horaires dans un tableau
        var centre_horaires = new Array;
        $('[id^="centre_horaire_"][id$="'+id_clicked+'"]').each( function() {
            clean = this.id.replace('centre_horaire_', '').replace('_'+id_clicked, '');
            centre_horaires.push(clean+';'+$(this).val());
        });
        form.append('centre_horaires', centre_horaires);

        // Formulaire SEO
        form.append('centre_title', $('#centre_title_'+id_clicked).val());

        form.append('centre_balise_h1', $('#centre_balise_h1_'+id_clicked).val());
        if (!IE11) {
            form.append('centre_meta_description', $('#centre_meta_description_'+id_clicked).next().find('.ck-editor__editable').text());
            form.append('centre_tagline_1', $('#centre_tagline_1_'+id_clicked).next().find('.ck-editor__editable').html().replace(/(<p[^>]+?>|<p>|<\/p>)/img, ""));
            form.append('centre_tagline_2', $('#centre_tagline_2_'+id_clicked).next().find('.ck-editor__editable').html().replace(/(<p[^>]+?>|<p>|<\/p>)/img, ""));
            form.append('centre_description_courte', $('#centre_description_courte_'+id_clicked).next().find('.ck-editor__editable').html());

            form.append('centre_contenu_1', $('#centre_contenu_1_'+id_clicked).next().find('.ck-editor__editable').html());
        }
        else {
            form.append('centre_meta_description', $('#centre_meta_description_'+id_clicked).val());
            form.append('centre_tagline_1', $('#centre_tagline_1_'+id_clicked).val().replace(/(<p[^>]+?>|<p>|<\/p>)/img, ""));
            form.append('centre_tagline_2', $('#centre_tagline_2_'+id_clicked).val().replace(/(<p[^>]+?>|<p>|<\/p>)/img, ""));
            form.append('centre_description_courte', $('#centre_description_courte_'+id_clicked).val());

            form.append('centre_contenu_1', $('#centre_contenu_1_'+id_clicked).val());
        }

        form.append('centre_is_visible', $('input[name="centre_visible_' + id_clicked + '"]:checked').val());

        // Verifie la longeur du texte
        if ($("#centre_title_"+id_clicked).val().length > 200) {
            toastr.error('Merci de reduire le title', 'Erreur');
            return false;
        }
        // Verifie la longeur du texte
        if ($("#centre_meta_description_"+id_clicked).val().length > 249) {
            toastr.error('Merci de reduire la meta description', 'Erreur');
            return false;
        }
        // Verifie la longeur du texte
        if ($("#centre_balise_h1_"+id_clicked).val().length > 200) {
            toastr.error('Merci de reduire la balise h1', 'Erreur');
            return false;
        }

        // Requète ajax d'envoi du formulaire : /src/Controller/AdminController.php -> admin_actualites()
        $.ajax({
            type: 'POST',
            url: siteURL + 'admin/web/centre/modify',
            cache: false,
            contentType: false,
            processData: false,
            data: form,
            success: function(data) {
                // TOAST
                toastr.success('Le centre a bien été enregistré', 'Succès');
                // On nettoye le html en supprimant l'onglet 'Modifier/Nouveau
                $('#centre-tabs-modify-'+id_clicked).remove();
                $('#centre-close-modify-'+id_clicked).parent().remove();
                // Si nous sommes sur un nouveau centre (id == 0)
                if (id_clicked == 0) {
                    $('#formcentre_'+id_clicked)[0].reset();
                }
                // Retour à la liste des centres en rechargeant la datatable
                $('#centre-tabs-list-tab').trigger('click');
                centre_table.ajax.reload();
            }
        });
        // Empèche le 'button' de fonctionner
        return false;
    });

    // Mets à jour la vidéo sur la page centres à chaque appui de clavier
    $('body').on('keyup', "[id^='centre_video_']", function(e){
        var id_selected = extractId(this.id);
        $("#centre_youtube").html('<iframe class="embed-responsive-item" src="https://www.youtube.com/embed/'+$(this).val()+'?rel=0" allowfullscreen></iframe>');
    });


    // Mets à jour la description du champ 'url' sur la page centres à chaque appui de clavier
    $('body').on('keyup', "[id^='centre_namerewrite_']", function(e){
        var id_selected = extractId(this.id);
        // Si la valeur n'est pas vide
        if ($(this).val() != '') {
            $(this).val($(this).val().replace(' ', '-').normalize("NFD").replace(/[\u0300-\u036f]/g, ""));
            $("#rewrite_"+id_selected).html($(this).val());
        }
        else {
            $("#rewrite_"+id_selected).html('{rewrite}');
        }
    });
});