let freCategorie_table = null;
$(document).ready(function () {
    if ($("#freCategorie-results").length) {
        function loadAjax() {
            $("#freCategorie-results").addClass("d-none");
            $("#freCategorie-loader").removeClass("d-none");
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/fre/freCategorie/ajax',
                contentType: false,
                processData: false,
                data: {},
                success: function (data) {
                    $("#freCategorie-results").html(data);
                    freCategorie_table = $('#freCategorie-table').DataTable({
                        "autoWidth": false,
                        "language": {
                            "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/fr-FR.json"
                        },
                        "paging": true,
                        "responsive": true,
                        "order": [
                            [0, "asc"]
                        ],
                        "columnDefs": [
                            {
                                "targets": 2,
                                "orderable": false,
                            },
                            { type: 'num', targets: [0] },
                        ],
                        "conditionalPaging": true,
                    });
                    $('#freCategorie-table').on('draw.dt', function () {
                        $("#freCategorie-loader").addClass("d-none");
                        $("#freCategorie-results").removeClass("d-none");
                    });
                }
            })
        };

        // Envoi de modification d'un freCategorie
        $('body').on('click', "[id^='freCategorie-send-modify-']", function (e) {
            e.preventDefault();
            let id_clicked = this.id.replace('freCategorie-send-modify-', '');
            let freCategorie_label = $('#freCategorie-label-'+id_clicked).val();
            let error_message = "";
            if (freCategorie_label.length == 0) {
                error_message += "Le libellé de la categorie n\'a pas été renseigné.<br/>"
            }
            if (error_message != "") {
                toastr.error(error_message, 'Erreur');
            } else {
                let form = new FormData();
                form.append('freCategorie_id', id_clicked);
                form.append('freCategorie_label', freCategorie_label);
                form.append('freCategorie_label_traduit', getLabelTraduit(id_clicked, "Categorie"));
                $.ajax({
                    type: 'POST',
                    url: siteURL + 'admin/fre/freCategorie/modify',
                    contentType: false,
                    processData: false,
                    data: form,
                    success: function (data) {
                        toastr.success('Le categorie a bien été enregistrée', 'Succès');
                        $("#freCategorie-tabs-add").html(data);
                        $('#freCategorie-tabs-modify-'+id_clicked).remove();
                        $('#freCategorie-close-modify-'+id_clicked).parent().remove();
                        $('#freCategorie-tabs-list-tab').trigger('click');
                    },
                    error: function () {
                        toastr.error('Une erreur s\'est produite.', 'Erreur');
                    }
                });
            }
        });

        // Bouton refresh
        $('body').on('click', "#freCategorie-tabs-list-tab", function () { loadAjax(); });
        loadAjax();
    }
});