let freElement_table = null;
$(document).ready(function () {
    if ($("#freElement-results").length) {
        function loadAjax() {
            $("#freElement-results").addClass("d-none");
            $("#freElement-loader").removeClass("d-none");
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/fre/freElement/ajax',
                contentType: false,
                processData: false,
                data: {},
                success: function (data) {
                    $("#freElement-results").html(data);
                    freElement_table = $('#freElement-table').DataTable({
                        "autoWidth": false,
                        "language": {
                            "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/fr-FR.json"
                        },
                        "paging": true,
                        "responsive": true,
                        "order": [
                            [0, "asc"]
                        ],
                        "columnDefs": [
                            {
                                "targets": 4,
                                "orderable": false,
                            },
                            { type: 'num', targets: [0] },
                        ],
                        "conditionalPaging": true,
                    });
                    $('#freElement-table').on('draw.dt', function () {
                        $("#freElement-loader").addClass("d-none");
                        $("#freElement-results").removeClass("d-none");
                    });
                }
            })
        };

        // Envoi de modification d'un freElement
        $('body').on('click', "[id^='freElement-send-modify-']", function (e) {
            e.preventDefault();
            let id_clicked = this.id.replace('freElement-send-modify-', '');
            let freElement_label = $('#freElement-label-' + id_clicked).val();
            let freElement_exterieur = $('#freElement-exterieur-' + id_clicked).val();
            let freElement_frePrestation = getPrestation(id_clicked);
            let error_message = "";
            if (freElement_label.length == 0) {
                error_message += "Le libellé de l\'élément n\'a pas été renseigné.<br/>"
            }
            if (freElement_exterieur == null) {
                error_message += "Le type d\'élément de l\'élément n\'a pas été renseigné.<br/>"
            }
            if (error_message != "") {
                toastr.error(error_message, 'Erreur');
            } else {
                let form = new FormData();
                form.append('freElement_id', id_clicked);
                form.append('freElement_label', freElement_label);
                form.append('freElement_exterieur', freElement_exterieur);
                form.append('freElement_frePrestation', freElement_frePrestation);
                form.append('freElement_label_traduit', getLabelTraduit(id_clicked, "Element"));
                $.ajax({
                    type: 'POST',
                    url: siteURL + 'admin/fre/freElement/modify',
                    contentType: false,
                    processData: false,
                    data: form,
                    success: function (data) {
                        toastr.success('L\'élément a bien été enregistrée', 'Succès');
                        $("#freElement-tabs-add").html(data);
                        selectpickerReset(selectpickerList);
                        $('#freElement-tabs-modify-' + id_clicked).remove();
                        $('#freElement-close-modify-' + id_clicked).parent().remove();
                        $('#freElement-tabs-list-tab').trigger('click');
                    },
                    error: function () {
                        toastr.error('Une erreur s\'est produite.', 'Erreur');
                    }
                });
            }
        });

        // Filtre les prestations
        function filtrePrestation(id) {
            let search = $("#freElement-search-" + id).val().slugify();
            let selected = $("#freElement-selected-" + id).is(":checked");
            $("[name^='freElement-searchItem-" + id + "-']").toArray()
                .map(function (el) { el.classList.add("d-none"); return el; })
                .filter((el) => el.getAttribute("data-label").slugify().includes(search) && (selected ? $(el).find("#" + el.getAttribute("name")).is(":checked") : true))
                .map((el) => el.classList.remove("d-none")).length;
        }

        // Retourne les prestations sélectionnés
        function getPrestation(id) {
            return $("input[type='checkbox'][id^='freElement-searchItem-" + id + "-']").toArray()
                .filter((el) => $(el).is(":checked"))
                .map((el) => el.id.replace("freElement-searchItem-" + id + "-", ""));
        }

        $('body').on('keyup change', "[id^='freElement-search-']", function (e) { filtrePrestation(this.id.replace('freElement-search-', '')); });
        $('body').on('change', "[id^='freElement-selected-']", function (e) { filtrePrestation(this.id.replace('freElement-selected-', '')); });

        const selectpickerList = [
            "freElement-categorie-",
            "freElement-exterieur-"
        ];
        selectpickerReset(selectpickerList);

        // Bouton refresh
        $('body').on('click', "#freElement-tabs-list-tab", function () { loadAjax(); });
        loadAjax();
    }
});