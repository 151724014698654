let espacemarchandconnexion_table = null;
$(document).ready(function() {
    if ($("#espacemarchandconnexion_results").length) {

        function loadAjax() {
            $("#espacemarchandconnexion_results").addClass("d-none");
            $.ajax({
                type:'POST',
                url: siteURL + 'admin/kpi/espace-marchand-connexion-ajax',
                success: function(data){
                    $("#espacemarchandconnexion_results").html(data);
                    espacemarchandconnexion_table = $('#espacemarchandconnexion_table').DataTable({
                        "autoWidth": false,
                        "language": {
                            "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/fr-FR.json"
                        },
                        "responsive": true,
                        "order": [
                            [13, "desc"]
                        ],
                        "columnDefs": [
                            {
                                "className": "text-center align-middle",
                                "targets": "_all",
                            }
                        ],
                        "conditionalPaging": true,
                    });
                    // Quand la datatable a fini de charger
                    $('#espacemarchandconnexion_table').on('draw.dt', function () {
                        $("#espacemarchandconnexion_results").removeClass("d-none");
                    });
                }
            });
        };

        $('body').on('click', "[id^='espacemarchandconnexion_excel']", function() {
            today = new Date();
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/kpi/espace-marchand-connexion/excel',
                contentType: false,
                processData: false,
                success: function(data) {
                    toastr.success('Le fichier excel est fini', 'Succès');

                    var element = document.createElement('a');
                    element.setAttribute('href',siteURL + data);
                    element.setAttribute('download', "Liste Connexion Marchand "+today.toLocaleDateString("fr")+".xlsx");
                    document.body.appendChild(element);
                    element.click();
                    document.body.removeChild(element);

                }
            });
        });

        $('body').on('click', '#espacemarchandconnexion_carte', function(event) {
            event.preventDefault();
            let baseUrl = $(this).attr('href');
            let newUrl = baseUrl.includes('?') ? baseUrl + '&gotocarte=1&gotoconnexions=1' : baseUrl + '?gotocarte=1&gotoconnexions=1';
            window.location.href = newUrl;
        });
        
        //---------------------------------------------------------------------------------------------------------------------------
        //
        //                                                  ZONE STATS
        //
        //---------------------------------------------------------------------------------------------------------------------------

        $('body').on ('click', 'button[id^="espacemarchandconnexion_stats_stats"]', function () {
            var statistiques = $('#espacemarchandconnexion_stats_stats').data('value');
            labelStat = [];
            dataStat = [];
            $.each(statistiques, function(index, value) {
                $.each(statistiques[index], function(index1, value1) {
                    labelStat.push(index+'-'+index1);
                    dataStat.push(value1);
                });
            });
            // Nombre de connexion total
            totalConnexion = 0;
            $.each(dataStat, function(index, value) {
                totalConnexion += parseInt(value);
            });
            textConnexion = $('#espacemarchandconnexion_stats_nombre_connexion').text();
            $('#espacemarchandconnexion_stats_nombre_connexion').text(textConnexion.split("(")[0]+ " (Total : "+ totalConnexion+")");
            createStatistiquesProspects('espacemarchandconnexion_stats_chart', labelStat, dataStat);
            $('#espacemarchandconnexion_stats_modal').show();
            return false;
        });

        $('body').on('change', 'select[id^="espacemarchandconnexion_stats_select"]', function() {
            option = $('#espacemarchandconnexion_stats_select').val();
            var statistiques = $('#espacemarchandconnexion_stats_stats').data('value');
            console.log(statistiques);
            labelStat = [];
            dataStat = [];
            $.each(statistiques, function(index, value) {
                if (option == 'all' || option == '6' || option == '12' || option == index) {
                    $.each(statistiques[index], function(index1, value1) {
                        labelStat.push(index+'-'+index1);
                        dataStat.push(value1);
                    });
                }
            });
            if ((option == '6' || option == '12') && (parseInt(option) <= labelStat.length)) {
                tempLabel = [];
                tempData = []
                for (i = parseInt(option); i >= 1; i--) {
                    tempLabel.push(labelStat[labelStat.length - i]);
                    tempData.push(dataStat[dataStat.length - i]);
                }
                labelStat = tempLabel;
                dataStat = tempData;
            }
            // Nombre de connexion total
            totalConnexion = 0;
            $.each(dataStat, function(index, value) {
                totalConnexion += parseInt(value);
            });
            textConnexion = $('#espacemarchandconnexion_stats_nombre_connexion').text();
            $('#espacemarchandconnexion_stats_nombre_connexion').text(textConnexion.split("(")[0]+ "(Total : "+ totalConnexion+")");
            createStatistiquesProspects('espacemarchandconnexion_stats_chart', labelStat, dataStat);
        });

        $('body').on('click', 'span[id^="espacemarchandconnexion_stats_close"]', function () {
            $('#espacemarchandconnexion_stats_modal').hide();
            // return false;
        });

        // Creer le graphique des statistiques
        function createStatistiquesProspects(canvasId, label, data) {
            $.each(label, function(index, value) {
                switch(value.split('-')[1]) {
                    case '1':
                        label[index] = value.split('-')[0] + '-Janvier';
                        break;
                    case '2':
                        label[index] = value.split('-')[0] + '-Février';
                        break;
                    case '3':
                        label[index] = value.split('-')[0] + '-Mars';
                        break;
                    case '4':
                        label[index] = value.split('-')[0] + '-Avril';
                        break;
                    case '5':
                        label[index] = value.split('-')[0] + '-Mai';
                        break;
                    case '6':
                        label[index] = value.split('-')[0] + '-Juin';
                        break;
                    case '7':
                        label[index] = value.split('-')[0] + '-Juillet';
                        break;
                    case '8':
                        label[index] = value.split('-')[0] + '-Août';
                        break;
                    case '9':
                        label[index] = value.split('-')[0] + '-Septembre';
                        break;
                    case '10':
                        label[index] = value.split('-')[0] + '-Octobre';
                        break;
                    case '11':
                        label[index] = value.split('-')[0] + '-Novembre';
                        break;
                    case '12':
                        label[index] = value.split('-')[0] + '-Décembre';
                        break;
                    default:
                        break;
                }

            });
            var ctx = document.getElementById(canvasId).getContext('2d');
            var chart = new Chart(ctx, {
                // The type of chart we want to create
                type: 'line',

                // The data for our dataset
                data: {
                    labels: label,
                    datasets: [{
                        label: 'Nombre de connexions',
                        backgroundColor: 'rgb(0, 174, 239)',
                        borderColor: 'rgb(0, 137, 189)',
                        data: data
                    }]
                },

                // Configuration options go here
                options: {
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true
                            }
                        }]
                    }
                }
            });
        }

        loadAjax();
    }
});
