const annuaireSuiviRelationFournisseurId = "annuaire-suiviRelationFournisseur-edit-";
const annuaireSuiviRelationFournisseurEventChangeDisplay = ["type-", "typeAppelTelephonique", "typeStock", "repondu-", "typeAgenda", "isRelance-"];
const annuaireSuiviRelationFournisseurButtonChangePrefix = ["type", "repondu", "isRelance"];

if ($(".annuaire-page").length) {
    /**
     * Event listener related to "annuaireSuiviRelationFournisseurEdit" feature
     * @event annuaireSuiviRelationFournisseurEdit
     * @param {Event} e
     * @summary This event is used to manage all user input on the "annuaireSuiviRelationFournisseurEdit" feature.
     */
    $('body').on('click change', "[id^='" + annuaireSuiviRelationFournisseurId + "']", function (e) {
        const isSameId = this.id == e.target.id;
        const isClickEvent = e.type == "click";
        const isCheckboxEvent = (e.target.tagName == "INPUT" && e.target.type == "checkbox") || e.target.tagName == "A";
        const isColorEvent = e.target.tagName == "I" && $(this).parent().data("hexa-color") != undefined;
        const isDateEvent = $(this).hasClass("datetimepicker");
        if (!isCheckboxEvent) {
            e.preventDefault();
        }
        if (isSameId && (isCheckboxEvent || !(isClickEvent && ["SELECT", "TEXTAREA", "INPUT"].includes(e.target.tagName)))) {
            const idAnnuaire = e.target.id.replace(/^.*\D+(\d*)$/g, '$1');
            // Get the "parent" element with jQuery
            const parent = $(this).parents("#annuaire-tab-suivi_relation_fournisseur-" + idAnnuaire + "-edit");
            if (parent.length) {
                const extract = e.target.id.replace(annuaireSuiviRelationFournisseurId, "").replace("-" + idAnnuaire, "").replace(/-\d+$/, "");
                if (extract == "modify") {
                    annuaireSuiviRelationFournisseurEditModify(parent, idAnnuaire, parent.find("[id^='annuaire-suiviRelationFournisseur-edit-modify-']").attr("suivi-relation-fournisseur-edit-type"), $("#" + annuaireSuiviRelationFournisseurId + "modify-" + idAnnuaire).data("id"));
                } else if (extract == "cancel") {
                    annuaireSuiviRelationFournisseurEditCancel(parent, idAnnuaire);
                } else {
                    const buttonChange = annuaireSuiviRelationFournisseurButtonChangePrefix.filter(str => extract.startsWith(str + "-"));
                    // Input update
                    if (buttonChange.length) { annuaireSuiviRelationFournisseurEditButtonChange(parent, extract, buttonChange[0]); }
                    else if (isCheckboxEvent) { annuaireSuiviRelationFournisseurEditDatatablesCheckboxChange(parent, extract, $(this)); }
                    else if (isColorEvent) { annuaireSuiviRelationFournisseurEditColorpickerChange(parent, extract, $(this), idAnnuaire); }
                    else if (isDateEvent) { annuaireSuiviRelationFournisseurEditDatetimepickerChange(parent, extract, $(this), idAnnuaire); }
                    else if (!isClickEvent) { annuaireSuiviRelationFournisseurEditInputChange(parent, extract, $(this)); }
                    // Display update
                    if (annuaireSuiviRelationFournisseurEventChangeDisplay.some(item => extract.startsWith(item))) {
                        annuaireSuiviRelationFournisseurEditDisplayUpdate(parent, idAnnuaire, parent.find("[id^='annuaire-suiviRelationFournisseur-edit-modify-']").attr("suivi-relation-fournisseur-edit-type"), parent.find("[id^='annuaire-suiviRelationFournisseur-edit-modify-']").attr("suivi-relation-fournisseur-edit-id"));
                    }
                }
            } else {
                // This idAnnuaire is not the annuaire id
                $("#" + annuaireSuiviRelationFournisseurId + "modify-" + e.target.getAttribute("data-annuaire")).data("id", idAnnuaire);
                annuaireSuiviRelationFournisseurEditDisplayUpdate($("#annuaire-tab-suivi_relation_fournisseur-" + e.target.getAttribute("data-annuaire") + "-edit"), e.target.getAttribute("data-annuaire"), e.target.getAttribute("data-type"), idAnnuaire, true);
            }
        }
    });

    $('body').on('click', "[id^='display-suiviRelationFournisseurObjet-']", function (e) {
        let idSuiviRelationObject = e.target.id.replace('display-suiviRelationFournisseurObjet-', '');
        console.log('idSuiviRelationObject :', idSuiviRelationObject);
        if ($("#h3-suiviRelationFournisseurObjet-" + idSuiviRelationObject).hasClass('d-none')) {
            $("#h3-suiviRelationFournisseurObjet-" + idSuiviRelationObject).removeClass('d-none');
            $("#h3-suiviRelationFournisseurObjet-" + idSuiviRelationObject).addClass('d-flex');
            $("#timelineBody-suiviRelationFournisseurObjet-" + idSuiviRelationObject).addClass('d-none');
            $("#display-suiviRelationFournisseurObjet-" + idSuiviRelationObject).html("<i class='fas fa-plus pointer-events-none'></i>");
        } else {
            $("#h3-suiviRelationFournisseurObjet-" + idSuiviRelationObject).addClass('d-none');
            $("#h3-suiviRelationFournisseurObjet-" + idSuiviRelationObject).removeClass('d-flex');
            $("#timelineBody-suiviRelationFournisseurObjet-" + idSuiviRelationObject).removeClass('d-none');
            $("#display-suiviRelationFournisseurObjet-" + idSuiviRelationObject).html("<i class='fas fa-minus pointer-events-none'></i>");
        }
    });
}

/**
 * Edit related function
 */

/**
 * @function annuaireSuiviRelationFournisseurEditInputChange
 * @param {jQuery} parent
 * @param {string} extract
 * @param {jQuery} el
 * @summary This function is used to change value when input, textarea or select is used
 */
function annuaireSuiviRelationFournisseurEditInputChange(parent, extract, el) {
    parent.find("[id^='annuaire-suiviRelationFournisseur-edit-modify-']").attr("suivi-relation-fournisseur-edit-" + extract, el.val());
}

/**
 * @function annuaireSuiviRelationFournisseurEditButtonChange
 * @param {jQuery} parent
 * @param {string} extract
 * @param {string} prefix
 * @summary This function is used to change value when button is used
 */
function annuaireSuiviRelationFournisseurEditButtonChange(parent, extract, prefix) {
    parent.find("[id^='" + annuaireSuiviRelationFournisseurId + extract + "-']").prop("selected", true);
    parent.find("[id^='annuaire-suiviRelationFournisseur-edit-modify-']").attr("suivi-relation-fournisseur-edit-" + prefix, extract.replace(prefix + "-", ""));
}

/**
 * @function annuaireSuiviRelationFournisseurEditDatatablesCheckboxChange
 * @param {jQuery} parent
 * @param {string} extract
 * @param {jQuery} el
 * @summary This function is used to change value when checkbox is used
 */
function annuaireSuiviRelationFournisseurEditDatatablesCheckboxChange(parent, extract, el) {
    const table = el.parents("table[id^='" + annuaireSuiviRelationFournisseurId + extract + "-table-']");
    const listId = table.dataTable().api().rows(function (idx, data, node) {
        // Get all the checkboxes in the row
        let cells = $(node).find("input[id^='" + annuaireSuiviRelationFournisseurId + extract + "-']");
        // Keep the rows with checked checkboxes
        return cells.filter(function (index) {
            return $(cells[index]).prop('checked');
        }).length;
    }).data().toArray().map((r) => r["DT_RowId"].replace(annuaireSuiviRelationFournisseurId + extract + "-tr-", "").replace(/(\d+)-(\d+)/, "$1"));
    parent.find("[id^='annuaire-suiviRelationFournisseur-edit-modify-']").attr("suivi-relation-fournisseur-edit-" + extract, listId);
}

/**
 * @function annuaireSuiviRelationFournisseurEditColorpickerChange
 * @param {jQuery} parent
 * @param {string} extract
 * @param {jQuery} el
 * @param {number} idAnnuaire
 * @summary This function is used to change value when color is used
 */
function annuaireSuiviRelationFournisseurEditColorpickerChange(parent, extract, el, idAnnuaire) {
    const colorpicker = $("#" + annuaireSuiviRelationFournisseurId + extract + "-" + idAnnuaire);
    colorpicker.val(el.parent().data("hexa-color"));
    parent.find("[id^='annuaire-suiviRelationFournisseur-edit-modify-']").attr("suivi-relation-fournisseur-edit-" + extract, colorpicker.val());
}

/**
 * @function annuaireSuiviRelationFournisseurEditDatetimepickerChange
 * @param {jQuery} parent
 * @param {string} extract
 * @param {jQuery} el
 * @param {number} idAnnuaire
 * @summary This function is used to change value when datetimepicker is used
 */
function annuaireSuiviRelationFournisseurEditDatetimepickerChange(parent, extract, el, idAnnuaire) {
    el.datetimepicker(annuaireSuiviRelationFournisseurEditDatetimepickerOptions(annuaireSuiviRelationFournisseurId + extract + "-" + idAnnuaire, el.val()));
    parent.find("[id^='annuaire-suiviRelationFournisseur-edit-modify-']").attr("suivi-relation-fournisseur-edit-" + extract, el.val());
}

/**
 * @param {number} id
 * @param {string} date
 * @returns Datetimepicker options object
 */
const annuaireSuiviRelationFournisseurEditDatetimepickerOptions = (id, date = null) => {
    return {
        lang: 'fr',
        format: (date == null ? "d/m/Y" : "d/m/Y H:i"),
        dayOfWeekStart: 1,
        step: 15,
        validateOnBlur: false,
        timepicker: (!(date == null)),
        onSelectDate: function () {
            $("#" + id).datetimepicker('setOptions', { format: "d/m/Y H:i", timepicker: true });
        }
    }
};

// Edit related function

/**
 * Display related function
 */

/**
 * @function annuaireSuiviRelationFournisseurEditDisplayUpdate
 * @param {jQuery} parent
 * @summary This function update internal display of "parent" elements
 */
function annuaireSuiviRelationFournisseurEditDisplayUpdate(parent, idAnnuaire, type = 0, id = 0, edit = false) {
    $.ajax({
        type: 'POST',
        url: siteURL + 'admin/annuaire/suiviRelationFournisseur/edit/load',
        contentType: false,
        processData: false,
        data: annuaireSuiviRelationFournisseurEditGetFormData(parent, idAnnuaire, type, id, edit),
        success: function (data) {
            parent.html(data);
            $("#" + annuaireSuiviRelationFournisseurId + "vehiculesProposes-table-" + idAnnuaire).DataTable({
                "dom": "<'d-flex justify-content-end'f><tr>",
                "autoWidth": false,
                "language": {
                    "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/fr-FR.json"
                },
                "paging": false,
                "responsive": true,
                "order": [[2, "asc"], [3, "asc"], [6, "desc"]],
                "columnDefs": [
                    {
                        "className": "text-center align-middle",
                        "targets": '_all',
                    },
                    {
                        "targets": 0,
                        "orderable": false,
                    },
                ],
                "conditionalPaging": true,
            });
            $("#" + annuaireSuiviRelationFournisseurId + "vehiculesFournisseurProposes-table-" + idAnnuaire).DataTable({
                "dom": "<'d-flex justify-content-end'f><tr><'d-flex'<'flex-1'i><'flex-1'p>>",
                "autoWidth": false,
                "language": {
                    "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/fr-FR.json"
                },
                "paging": true,
                "pageLength": 25,
                "responsive": true,
                "order": [[3, "asc"], [4, "asc"]],
                "columnDefs": [
                    {
                        "className": "text-center align-middle",
                        "targets": '_all',
                    },
                    {
                        "targets": 0,
                        "orderable": false,
                    },
                    { type: 'num', targets: [1, 2, 6, 7] }
                ],
                "conditionalPaging": true,
            });
            $("#" + annuaireSuiviRelationFournisseurId + "dateDebut-" + idAnnuaire).datetimepicker(annuaireSuiviRelationFournisseurEditDatetimepickerOptions(annuaireSuiviRelationFournisseurId + "dateDebut-" + idAnnuaire));
            $("#" + annuaireSuiviRelationFournisseurId + "relanceDateDebut-" + idAnnuaire).datetimepicker(annuaireSuiviRelationFournisseurEditDatetimepickerOptions(annuaireSuiviRelationFournisseurId + "relanceDateDebut-" + idAnnuaire));
        }
    });
}

// Display related function


/**
 * Register related function
 */

/**
 * @function annuaireSuiviRelationFournisseurEditGetFormData
 * @param {jQuery} parent
 * @summary This function set FormData for request
 */
function annuaireSuiviRelationFournisseurEditGetFormData(parent, idAnnuaire, type, id, edit = false) {
    type = (type ? type : $("[id^='" + annuaireSuiviRelationFournisseurId + "type-'][selected]").data("value"))
    let form = new FormData();
    form.append('idAnnuaire', idAnnuaire);
    form.append('edit', edit);
    form.append('id', id);
    form.append('type', type);
    form.append('typeAppelTelephonique', parent.find("[id^='annuaire-suiviRelationFournisseur-edit-modify-']").attr("suivi-relation-fournisseur-edit-typeAppelTelephonique"));
    form.append('typeStock', parent.find("[id^='annuaire-suiviRelationFournisseur-edit-modify-']").attr("suivi-relation-fournisseur-edit-typeStock"));
    form.append('typeAgenda', parent.find("[id^='annuaire-suiviRelationFournisseur-edit-modify-']").attr("suivi-relation-fournisseur-edit-typeAgenda"));
    form.append('repondu', parent.find("[id^='annuaire-suiviRelationFournisseur-edit-modify-']").attr("suivi-relation-fournisseur-edit-repondu"));
    form.append('label', parent.find("[id^='annuaire-suiviRelationFournisseur-edit-modify-']").attr("suivi-relation-fournisseur-edit-label"));
    form.append('contenu', parent.find("[id^='annuaire-suiviRelationFournisseur-edit-modify-']").attr("suivi-relation-fournisseur-edit-contenu"));
    form.append('vehiculesProposes', parent.find("[id^='annuaire-suiviRelationFournisseur-edit-modify-']").attr("suivi-relation-fournisseur-edit-vehiculesProposes"));
    form.append('vehiculesFournisseurProposes', parent.find("[id^='annuaire-suiviRelationFournisseur-edit-modify-']").attr("suivi-relation-fournisseur-edit-vehiculesFournisseurProposes"));
    form.append('couleur', parent.find("[id^='annuaire-suiviRelationFournisseur-edit-modify-']").attr("suivi-relation-fournisseur-edit-couleur"));
    form.append('dateDebut', new Date(parent.find("[id^='annuaire-suiviRelationFournisseur-edit-modify-']").attr("suivi-relation-fournisseur-edit-dateDebut").replace(/(\d+)[/](\d+)[/](\d+)/, '$3-$2-$1')).toISOString());
    form.append('dateFin', (new Date((new Date(parent.find("[id^='annuaire-suiviRelationFournisseur-edit-modify-']").attr("suivi-relation-fournisseur-edit-dateDebut").replace(/(\d+)[/](\d+)[/](\d+)/, '$3-$2-$1'))).getTime() + (parseInt(parent.find("[id^='annuaire-suiviRelationFournisseur-edit-modify-']").attr("suivi-relation-fournisseur-edit-dateFin")) * 1000))).toISOString());
    form.append('isRelance', (type == "appelTelephonique" ? parent.find("[id^='annuaire-suiviRelationFournisseur-edit-modify-']").attr("suivi-relation-fournisseur-edit-isRelance") : "off"));
    form.append('idRelance', parent.find("[id^='annuaire-suiviRelationFournisseur-edit-modify-']").attr("suivi-relation-fournisseur-edit-idRelance"));
    form.append('relanceCouleur', parent.find("[id^='annuaire-suiviRelationFournisseur-edit-modify-']").attr("suivi-relation-fournisseur-edit-relanceCouleur"));
    form.append('relanceDateDebut', new Date(parent.find("[id^='annuaire-suiviRelationFournisseur-edit-modify-']").attr("suivi-relation-fournisseur-edit-relanceDateDebut").replace(/(\d+)[/](\d+)[/](\d+)/, '$3-$2-$1')).toISOString());
    form.append('relanceDateFin', (new Date((new Date(parent.find("[id^='annuaire-suiviRelationFournisseur-edit-modify-']").attr("suivi-relation-fournisseur-edit-relanceDateDebut").replace(/(\d+)[/](\d+)[/](\d+)/, '$3-$2-$1'))).getTime() + (parseInt(parent.find("[id^='annuaire-suiviRelationFournisseur-edit-modify-']").attr("suivi-relation-fournisseur-edit-relanceDateFin")) * 1000))).toISOString());
    return form;
}

/**
 * @function annuaireSuiviRelationFournisseurEditCheckFormData
 * @param {FormData} formData
 * @summary This function check FormData for request
 */
function annuaireSuiviRelationFournisseurEditCheckFormData(formData) {
    const errorValue = [undefined, "undefined", null, "null", ""];
    const error = {
        "type": { "value": null, "message": "Le type d'action n'est pas renseigné" },
        "typeAppelTelephonique": { "value": null, "message": "Le type d'appel téléphonique d'action n'est pas renseigné" },
        "typeStock": { "value": null, "message": "Le type de stock n'est pas renseigné" },
        "typeAgenda": { "value": null, "message": "Le type d'agenda n'est pas renseigné" },
        "repondu": { "value": null, "message": "Le succès de l'appel n'est pas renseigné" },
        "label": { "value": null, "message": "Le titre n'est pas renseigné" },
        "contenu": { "value": null, "message": "Le commentaire n'est pas renseigné" },
        "vehiculesProposes": { "value": null, "message": "Les véhicules proposés ne sont pas renseignés" },
        "vehiculesFournisseurProposes": { "value": null, "message": "Les véhicules proposés ne sont pas renseignés" },
        "couleur": { "value": null, "message": "La couleur n'est pas renseignée" },
        "dateDebut": { "value": null, "message": "La date de début n'est pas renseignée" },
        "dateFin": { "value": null, "message": "Le temps nécessaire n'est pas renseigné" },
        "isRelance": { "value": null, "message": "Le choix d'une relance n'est pas renseigné" },
        "relanceCouleur": { "value": null, "message": "La couleur de la relance n'est pas renseignée" },
        "relanceDateDebut": { "value": null, "message": "La date de début de la relance n'est pas renseigné" },
        "relanceDateFin": { "value": null, "message": "Le temps nécessaire de la relance n'est pas renseigné" },
    };
    error["type"]["value"] = (formData.get("id") ? false : errorValue.includes(formData.get("type")));
    if (!error["type"]["value"]) {
        if (["appelTelephonique"].includes(formData.get("type"))) {
            error["typeAppelTelephonique"]["value"] = errorValue.includes(formData.get("typeAppelTelephonique"));
            if (!error["typeAppelTelephonique"]["value"]) {
                if (["sav"].includes(formData.get("typeAppelTelephonique"))) {
                    error["typeStock"]["value"] = errorValue.includes(formData.get("typeStock"));
                }
                if (["prospection", "commerce"].includes(formData.get("typeAppelTelephonique"))) {
                    error["repondu"]["value"] = errorValue.includes(formData.get("repondu"));
                }
                if (["sav"].includes(formData.get("typeAppelTelephonique"))) {
                    if (["ora7"].includes(formData.get("typeStock"))) {
                        if (!error["repondu"]["value"]) {
                            error["vehiculesProposes"]["value"] = errorValue.includes(formData.get("vehiculesProposes"));
                        }
                    }
                    if (["fournisseur"].includes(formData.get("typeStock"))) {
                        if (!error["repondu"]["value"]) {
                            error["vehiculesFournisseurProposes"]["value"] = errorValue.includes(formData.get("vehiculesFournisseurProposes"));
                        }
                    }
                }
            }
        }
        if (["agenda"].includes(formData.get("type"))) {
            error["typeAgenda"]["value"] = errorValue.includes(formData.get("typeAgenda"));
            error["label"]["value"] = errorValue.includes(formData.get("label"));
            error["couleur"]["value"] = errorValue.includes(formData.get("couleur"));
            error["dateDebut"]["value"] = errorValue.includes(formData.get("dateDebut"));
            error["dateFin"]["value"] = errorValue.includes(formData.get("dateFin"));
        }
        if (["appelTelephonique", "agenda"].includes(formData.get("type"))) {
            error["isRelance"]["value"] = errorValue.includes(formData.get("isRelance"));
            if (!error["isRelance"]["value"]) {
                if (["on"].includes(formData.get("isRelance"))) {
                    error["relanceCouleur"]["value"] = errorValue.includes(formData.get("relanceCouleur"));
                    error["relanceDateDebut"]["value"] = errorValue.includes(formData.get("relanceDateDebut"));
                    error["relanceDateFin"]["value"] = errorValue.includes(formData.get("relanceDateFin"));
                }
            }
        }
        error["contenu"]["value"] = errorValue.includes(formData.get("contenu"));
    }
    const isError = Object.values(error).some(item => item["value"] == true);
    if (isError) {
        let errorMsg = "";
        Object.values(error).forEach(item => {
            if (item["value"] == true) {
                errorMsg = errorMsg + item["message"] + "<br>";
            }
        });
        toastr.error(errorMsg, 'Erreur');
    }
    return !isError;
}

/**
 * @function annuaireSuiviRelationFournisseurEditModify
 * @param {jQuery} parent
 * @summary This function update internal display of "parent" elements
 */
function annuaireSuiviRelationFournisseurEditModify(parent, idAnnuaire, type = 0, id = 0) {
    const formData = annuaireSuiviRelationFournisseurEditGetFormData(parent, idAnnuaire, type, id);
    if (annuaireSuiviRelationFournisseurEditCheckFormData(formData)) {
        $.ajax({
            type: 'POST',
            url: siteURL + 'admin/annuaire/suiviRelationFournisseur/edit/modify',
            contentType: false,
            processData: false,
            data: formData,
            success: function (data) {
                parent.html(data.html);
                annuaireSuiviRelationFournisseurLoad(idAnnuaire, 0, true);
                if (formData.get("type") == "appelTelephonique") {
                    if (formData.get("typeAppelTelephonique") == "sav") {
                        if (formData.get("typeStock") == "ora7") {
                            if (formData.get("repondu") == "on") {
                                window.open(parent.data("src") + "?listId=" + formData.get("vehiculesProposes") + "&idAnnuaire=" + idAnnuaire + "&idAppel=" + data.id, "_blank");
                            }
                        }
                        if (formData.get("typeStock") == "fournisseur") {
                            if (formData.get("repondu") == "on") {
                                window.open(parent.data("src") + "?listId=" + formData.get("vehiculesFournisseurProposes") + "&idAnnuaire=" + idAnnuaire + "&idAppel=" + data.id, "_blank");
                            }
                        }
                    }
                }
                if (formData.get("type") == "agenda") {
                    if (formData.get("typeAgenda") == "rendez-vous") {
                        annuaireSuiviRelationFournisseurIcs(idAnnuaire, data.id);
                    }
                }
            }
        });
    }
}

/**
 * @function annuaireSuiviRelationFournisseurEditCancel
 * @param {jQuery} parent
 * @summary This function update internal display of "parent" elements
 */
function annuaireSuiviRelationFournisseurEditCancel(parent, idAnnuaire) {
    let form = new FormData();
    form.append('idAnnuaire', idAnnuaire);
    $.ajax({
        type: 'POST',
        url: siteURL + 'admin/annuaire/suiviRelationFournisseur/edit/cancel',
        contentType: false,
        processData: false,
        data: form,
        success: function (data) {
            parent.html(data);
        }
    });
}

// Register related function