let repriseTable = null;

$(document).ready(function () {
    if ($("#reprise-results").length) {
        /**
         * Initialisation des configurations de wapp pour la reprise
         * ⚠️⚠️⚠️ Attention ⚠️⚠️⚠️
         * Les configs sont chargées au chargement de la page.
         * Si elles sont modifiées sur la base de données, elles ne seront prises en compte qu'au prochain chargement de la page.
         */
        wappInit().then(() => {
            // Verification de la connexion avec l'API
            wappGet("instance/status").then((response) => {
                if (JSON.parse(response).status.accountStatus.status === "authenticated") {
                    $("#reprise-wapp-status").removeClass("fa-wifi-slash text-danger").addClass("fa-wifi text-success").attr("title", "Instance WhatsApp connecté");
                } else { wappDisconnected("wapp status check failed"); }
            }).catch(() => wappDisconnected("wapp status check failed"));
        });

        /**
         * Charge la liste des reprises
         * @return {void}
         */
        function loadAjax() {
            $("#reprise-results").addClass("d-none");
            $("#reprise-loader").removeClass("d-none");
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/achat/reprise/ajax',
                success: function (data) {
                    $("#reprise-results").html(data);
                    repriseTable = $('#reprise-table').DataTable({
                        "dom": dataTablesCustomDom(),
                        "autoWidth": false,
                        "language": {
                            "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/en-GB.json"
                        },
                        "paging": true,
                        "pageLength": 50,
                        "lengthMenu": [[10, 25, 50, 100, -1], [10, 25, 50, 100, "All"]],
                        "responsive": true,
                        "order": [
                            [11, "desc"]
                        ],
                        "columnDefs": [
                            { type: 'num', targets: [0, 3, 7, 8, 9] },
                            // ALIGN MIDDLE
                            {
                                "className": "text-center align-middle",
                                "targets": '_all',
                            }
                        ],
                        "conditionalPaging": true,
                    });
                    $('#reprise-table').on('draw.dt', function () {
                        $("#reprise-loader").addClass("d-none");
                        $("#reprise-results").removeClass("d-none");
                    });
                }
            })
        };

        loadAjax(); // Charge la liste des reprises

        /**
         * ----------
         * Dialog
         * ----------
         */

        /**
         * Quand une dialog est ouverte (une étape de la reprise), on met à jour le contenu de la dialog
         */
        $('body').on('click', "[id^='reprise-'][id*='-dialog-'][id$='-open'][channel-dialog-open]", function () {
            const dialog = $(channelGetDialog(this.getAttribute("data-step"), this.id.replace(/-dialog-\d+-open/g, "")));
            updateStep(dialog.data("step"), dialog.data("id"), false);
        });

        /**
         * Lance le retour sur la dialog précedente
         */
        $('body').on('click', "[channel-dialog-prev]", function () {
            const dialog = $(this).parents("dialog"), step = dialog.data("step"), name = dialog.data("name");
            channelPrev(step, name);
        });

        /**
         * Lance le passage sur la dialog suivante si l'attribut enabled est vérifié ou si la dialog possède l'attribut data-expertise
         */
        $('body').on('click', "[channel-dialog-next]", function () {
            const dialog = $(this).parents("dialog"), step = dialog.data("step"), name = dialog.data("name"), id = dialog.data("id");
            if (this.hasAttribute("enabled") || dialog[0].hasAttribute("data-expertise")) { // Check enabled except if expertise
                // Si la dialog suivante n'existe pas et que l'id de la dialog actuelle est 0, on lance le chargement de la dialog suivante
                // Exemple: On est dialog 2, pendant l'ajout, on lance le chargement du tunnel de reprise, en modification, puis on passe sur la dialog 3
                if (id == 0 && channelGetDialog(step + 1, name) === null) {
                    loadingNextBtn(dialog);
                    repriseLoadChannel(dialog.find("#reprise-id").val(), step + 1);
                } else { channelNext(step, name); }
            } else { updateStep(step, id, false, true); }
        });

        /**
         * Enregistrement de la reprise à la fin (qui n'enregitre pas, ferme juste la modal)
         */
        $('body').on('click', "[id^='reprise-fermeture-modal']", function () {
            const dialog = $(this).parents("dialog");
            dialogClose(dialog[0]); // Ferme le dialog
        });

        /**
         * Finalise le tunnel de reprise en envoyant les messages et les photos sur Whatsapp
         */
        $('body').on('click', "[channel-dialog-end]", function () {
            const dialog = $(this).parents("dialog"), step = dialog.data("step"), name = dialog.data("name"), id = dialog.data("id"), channel = $("#reprise-channel");
            if (this.hasAttribute("enabled")) { // Check enabled
                loadingEndBtn(dialog);
                const repriseWappData = { message: null, photoAvant: null, photoArriere: null, photoInterieur: null, coteArgus: null } // Objet qui accumule les Base64 avant l'envoi
                const repriseWappDataProxy = new Proxy(repriseWappData, { // Proxy pour repérer les changement de repriseWappData (en réalité on change directement le proxy mais bon...)
                    set: function (target, key, value) { // Si une affectation est effectuée sur le proxy
                        target[key] = value; // Set la value du proxy sur la référence originelle
                        const repriseWappDataFullLength = Object.keys(target).length, // Get le nombre d'élément à préparer
                            repriseWappDataNullLength = Object.keys(target).reduce((acc, key) => acc + (target[key] == null), 0), // Get le nombre d'élément vide
                            repriseWappDataDiffLength = Math.round(100 - (repriseWappDataNullLength * (100 / repriseWappDataFullLength))), // Get le pourcentage de progression
                            repriseWappDataWidthStep = Array.from({ length: repriseWappDataFullLength + 1 }, (_, i) => "w-" + Math.round((i / repriseWappDataFullLength) * 100)); // Crée une série de valeurs entre 0 et 100, divisées de manière égale selon un nombre donné. Exemple : 4 -> ["w-0", "w-25", "w-50", "w-75", "w-100"]
                        dialog.find("#reprise-wapp-progress-prepa .progress > .progress-bar").removeClass(repriseWappDataWidthStep).addClass("w-" + repriseWappDataDiffLength); // Inscris la progression de la préparation
                        if (Object.values(target).every((v) => v != null)) { // Si toutes les valeurs sont remplies
                            repriseWappPost(repriseWappData) // Démarre l'envoi à WhatsApp
                                .then(msg => {
                                    dialogClose(dialog[0]); // Ferme le dialog
                                    toastr.success(msg, "Success"); // Message de réussite de l'envoi
                                    loadAjax(); // Rechargement du tableau de reprise
                                }) // Envoi réussi
                                .catch(msg => dialogToastrError(dialog, name, msg)) // Envoi échoué
                                .finally(() => loadingEndBtn(dialog, false)); // Stop le loading
                        }
                        return true;
                    }
                });

                // Reprise Wapp Message
                // Initialisation des variables utilisées pour la création du message
                const dialog1 = channelGetDialog(1, "reprise"),
                    dialog2 = channelGetDialog(2, "reprise"),
                    dialog4 = channelGetDialog(4, "reprise"),
                    marque = dialog2.querySelector("[id^='reprise-marque-'] option:checked").textContent,
                    modele = dialog2.querySelector("[id^='reprise-modele-'] option:checked").textContent,
                    version = dialog2.querySelector("[id^='reprise-version-']").value,
                    carburant = dialog2.querySelector("[id^='reprise-carburant-'] option:checked").textContent,
                    kilometrage = dialog2.querySelector("[id^='reprise-kilometrage-']").value,
                    place = dialog2.querySelector("[id^='reprise-nbPlace-']").value,
                    boite = dialog2.querySelector("[id^='reprise-boite-'] option:checked").textContent,
                    puissanceDIN = dialog2.querySelector("[id^='reprise-puissance_din-']").value,
                    expertise = $(dialog4).data("expertise").reduce((a, c) => (c.toDo ? a + parseFloat(c.prixHtTotal) : a), 0),
                    vehiculeId = dialog1.querySelector("[id^='reprise-vehicule-'] option:checked").value,
                    vehiculeMarque = dialog1.querySelector("[id^='reprise-vehicule-'] option:checked").getAttribute("data-marque"),
                    vehiculeModele = dialog1.querySelector("[id^='reprise-vehicule-'] option:checked").getAttribute("data-modele"),
                    vehiculeVersion = dialog1.querySelector("[id^='reprise-vehicule-'] option:checked").getAttribute("data-version"),
                    vehiculeJstock = dialog1.querySelector("[id^='reprise-vehicule-'] option:checked").getAttribute("data-jstock"),
                    vfURL = dialog.find("#vf-url").val(),
                    repriseUser = dialog.find("#reprise-user").val(),
                    message = [],
                    achatNewVehicule = dialog1.querySelector("[id^='reprise-type-'] .active[reprise-type]").classList.contains("reprise-type-achat-nouveau-vehicule");
                // Création du message de reprise
                message.push("\u26A0\uFE0F Demande" + (achatNewVehicule ? " de reprise de " : " achat cash de ") + repriseUser.split(' ')[0] + " \u26A0\uFE0F");
                message.push("------------------------");
                message.push("• " + marque + " / " + modele + (version != "" ? " / " + version : ""));
                message.push("• " + place + " places" + " / " + carburant + " / " + boite );
                message.push("• " + parseInt(puissanceDIN).formatNumber() + " ch" + " / " + parseInt(kilometrage).formatNumber() + " km");
                message.push("------------------------");
                message.push("• FRE : " + expertise + "€");
                message.push("------------------------");
                if (achatNewVehicule) {
                    message.push("Intéressé par le véhicule n°" + parseInt(vehiculeId).formatNumber());
                    message.push(vehiculeMarque + " " + vehiculeModele + " " + vehiculeVersion + " - " + parseInt(vehiculeJstock).formatNumber() + "j de stock");
                    message.push("------------------------");
                }
                message.push("Lien SEVEN : " + vfURL);

                repriseWappDataProxy.message = message.join("\n");

                // Reprise Wapp Base64, on converti les images en Base64 avant de les envoyer, car WhatsApp n'accepte pas les images en provenance d'ORA7.
                getBase64FromUrl(channel.find("[id^='reprise-photo-photoAvant-'][id$='-display'] img").attr('src'))
                    .then(base64String => repriseWappDataProxy.photoAvant = base64String)
                    .catch(() => { loadingEndBtn(dialog, false); dialogToastrError(dialog, name, "Une erreur lors de la conversion de la photo 3/4 avant est survenu."); });
                getBase64FromUrl(channel.find("[id^='reprise-photo-photoArriere-'][id$='-display'] img").attr('src'))
                    .then(base64String => repriseWappDataProxy.photoArriere = base64String)
                    .catch(() => { loadingEndBtn(dialog, false); dialogToastrError(dialog, name, "Une erreur lors de la conversion de la photo 3/4 arrière est survenu."); });
                getBase64FromUrl(channel.find("[id^='reprise-photo-photoInterieur-'][id$='-display'] img").attr('src'))
                    .then(base64String => repriseWappDataProxy.photoInterieur = base64String)
                    .catch(() => { loadingEndBtn(dialog, false); dialogToastrError(dialog, name, "Une erreur lors de la conversion de la photo interieur est survenu."); });
                getBase64FromUrl(channel.find("[id^='reprise-photoVF-coteArgus-'][id$='-display'] img").attr('src'))
                    .then(base64String => repriseWappDataProxy.coteArgus = base64String)
                    .catch(() => { loadingEndBtn(dialog, false); dialogToastrError(dialog, name, "Une erreur lors de la conversion de la photo de la cote Argus est survenu."); });
            } else { updateStep(step, id, false, true); }
        });

        /**
         * Bouton Ajouter
         */
        $('body').on('click', "[id^='reprise-add-']", function () { const btn = $(this); repriseLoadChannel(btn.data("id"), btn.data("step")); });

        /**
         * Charge le tunnel de la reprise, en fonction de l'id de la reprise et du step qui sera ouvert.
         * @param {int} id - L'id de la reprise
         * @param {int} step - Le step de la reprise
         */
        function repriseLoadChannel(id, step) {
            let form = new FormData();
            form.append('id', id);
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/achat/reprises/reprise/channel', // Chargement du tunnel de reprise
                contentType: false,
                processData: false,
                data: form,
                success: function (data) {
                    $("#reprise-channel").html(data);
                    $("#reprise-channel .selectpicker").selectpicker(); // Initialisation des selectpicker du tunnel de reprise nouvellement chargé
                    repriseModeleSelectBackUp = null; // Remise à zéro de la sauvegarde de modeleSelect
                    const jSign = $("#reprise-channel").find("[sign]#sign-reprise"); // Récupère la zone de signature
                    if (jSign.length) { // Si la zone de signature existe
                        initSign(jSign[0]); // Initialisation de la zone de signature
                        DOMSubtreeModified(jSign[0].querySelector("#sign-capture-reprise"), repriseSign); // Ajout de l'event de modification de la zone de signature
                    }
                    channelOpen(step, "reprise"); // Ouverture de la dialog à la bonne étape
                },
                error: function () { toastr.error("Erreur de chargement", "Erreur"); }
            });
        }

        /**
         * ----------
         * Input step 1
         * ----------
         */

        $('body').on('click', "[id^='reprise-type-'][reprise-type]", function () {
            const groups = /reprise-type-(?<repriseId>\d+)-(?<typeId>\d+)/g.exec(this.id).groups; // Utilise une RegExp pour obtenir l'id et le type de la reprise
            const repriseId = groups.repriseId; // Récupère l'id de la reprise
            const typeId = groups.typeId; // Récupère le type de la reprise
            const repriseTypeContainer = $("#reprise-type-" + repriseId); // Récupère l'élément qui contient les types de reprise
            repriseTypeContainer.find("[reprise-type]").toArray().forEach(repriseType => { // Parcourt tous les types de reprise
                $(repriseType).removeClass("active").addClass("notactive"); // Suppression de la séléction de tous les type de reprise
            });
            $(this).removeClass("notactive").addClass("active"); // Activation du type de reprise cliqué
            repriseTypeContainer.find("[reprise-type-collapse]").toArray().forEach(collapse => { // Parcourt tous les collapse des types de reprise
                $(collapse).removeClass("show"); // Cache tous les éléments bouclés
            });
            const repriseTypeCollapse = $("#reprise-type-" + repriseId + "-" + typeId + "-collapse"); // Récupère le collapse du type de reprise cliqué
            if (repriseTypeCollapse.length) { repriseTypeCollapse.addClass("show"); } // Si le collapse existe, on l'affiche
            updateStep(1, repriseId); // Mise à jour de l'étape 1
        });

        $('body').on('change', "[id^='reprise-vehicule-']", function () {
            const repriseId = this.id.replace("reprise-vehicule-", ""); // Récupère l'id de la reprise
            const selectedOptions = this.selectedOptions[0]; // Récupère l'option séléctionné du select du vehicule qui intéresse le client
            const vehiculeInfos = $("#reprise-vehiculeInfos-" + repriseId); // Récupère les infos du vehicule qui interesse le client
            vehiculeInfos.attr("href", vehiculeInfos.attr("href").replace(/(.*\/)(\w+)$/ig, "$1" + selectedOptions.value)); // Mise à jour du href pour afficher la première photo du vehicule
            vehiculeInfos.find("#reprise-vehiculeInfos-url-" + repriseId).attr("src", selectedOptions.getAttribute("data-url")); // Mise à jour de l'url vers la page stock du vehicule
            vehiculeInfos.find("#reprise-vehiculeInfos-marque-" + repriseId).html(selectedOptions.getAttribute("data-marque")); // Mise à jour de la marque du vehicule
            vehiculeInfos.find("#reprise-vehiculeInfos-modele-" + repriseId).html(selectedOptions.getAttribute("data-modele")); // Mise à jour du modele du vehicule
            vehiculeInfos.find("#reprise-vehiculeInfos-version-" + repriseId).html(selectedOptions.getAttribute("data-version")); // Mise à jour de la version du vehicule
            vehiculeInfos.find("#reprise-vehiculeInfos-boite-" + repriseId).html(selectedOptions.getAttribute("data-boite")); // Mise à jour de la boite du vehicule
            vehiculeInfos.find("#reprise-vehiculeInfos-kilometrage-" + repriseId).html(selectedOptions.getAttribute("data-kilometrage")); // Mise à jour du kilometrage du vehicule
            vehiculeInfos.find("#reprise-vehiculeInfos-prix-" + repriseId).html(selectedOptions.getAttribute("data-prix")); // Mise à jour du prix du vehicule
            vehiculeInfos.find("#reprise-vehiculeInfos-jstock-" + repriseId).html(selectedOptions.getAttribute("data-jstock")); // Mise à jour du jstock du vehicule
            vehiculeInfos.removeClass("d-none"); // Affichage des infos du vehicule après leur mise à jour
            updateStep(1, repriseId); // Mise à jour de l'étape 1
        });

        /**
         * ----------
         * Input step 2
         * ----------
         */

        // Pour la modal et la rentrée du VIN via l'OCR
        $('body').on('shown.bs.modal', "[id^='reprise'][id$='-canva-photo-vin']", function () {
            $('.modal-backdrop').css('z-index', 1035);
            lancementScriptVideoVIN('#' + this.id.replace(/(reprise-)(\d+)-canva-photo-vin/g, "$1vin-$2"), this.id.replace("-canva-photo-vin", "")); // Initialisation de l'OCR pour la lecture du VIN
        });

        $('body').on('input', "[id^='reprise-vin-']", function () { this.value = this.value.toUpperCase(); }); // Mise en majuscule du VIN durant la saisie
        $('body').on('change', "[id^='reprise-infos-'] [id^='reprise-']", function () {
            const repriseId = this.id.replace(/.*-(\d+)$/g, "$1"); // Récupère l'id de la reprise
            if (this.id == "reprise-marque-" + repriseId) { // Si c'est la marque de la reprise qui a changé
                repriseMarque(this); // On appel la fonction qui modifie le modele en fonction de la marque
            }
            updateStep(2, repriseId); // Mise à jour de l'étape 2
        });

        let repriseModeleSelectBackUp = null; // Sauvegarde de modeleSelect
        function repriseMarque(marqueSelect) {
            let modeleSelect = document.getElementById(marqueSelect.id.replace("marque", "modele")); // Récupère le select du modele
            const marque = marqueSelect.value, modele = modeleSelect.value; // Sauvegarde de la valeur des inputs marque et modele
            if (repriseModeleSelectBackUp == null) { repriseModeleSelectBackUp = modeleSelect.cloneNode(true); } // Créer une sauvegarde de modeleSelect
            else { modeleSelect.parentElement.replaceChild(repriseModeleSelectBackUp.cloneNode(true), modeleSelect); } // Restaure la sauvegarde de modeleSelect
            modeleSelect = document.getElementById(marqueSelect.id.replace("marque", "modele")); // Remplace l'ancien modeleSelect par la sauvegarde
            modeleSelect.querySelectorAll("option").forEach(option => { if (marque != option.getAttribute("data-marque")) { option.remove(); } }); // Supprime les options qui ne correspondent pas à la marque
            if (modeleSelect.querySelector("option[value='" + modele + "']")) { modeleSelect.value = modele; } // Restore la valeur séléctionné
            if (!(modeleSelect.selectedOptions.length && modeleSelect.selectedOptions[0].getAttribute("data-marque") == marque)) { modeleSelect.value = ""; } // Si la marque séléctionné n'est pas celle du modele, on deselectionne le modele
        }

        /**
         * ----------
         * Input step 3
         * ----------
         */

        $('body').on('change', "[id^='reprise-photo-']", function () { repriseFileUpload(this); }); // Si une photo est ajoutée, on appel la fonction qui upload la photo
        $('body').on('change', "[id^='reprise-facultativePhoto-']", function () { repriseFileUpload(this, (data) => repriseMultiPhoto(data.id, "add", "facultativePhoto")); }); // Ajoute une photo de dashboard
        $('body').on('click', "[id^='reprise-facultativePhoto-'][id$='-delete']", function () { repriseMultiPhoto(this.id.replace("reprise-facultativePhoto-", "").replace("-delete", ""), "del", "facultativePhoto"); }); // Supprime une photo de dashboard

        /**
         * ----------
         * Input step 5
         * ----------
         */

        $('body').on('input', "[id^='reprise-controles-'] [id^='reprise-revision']", repriseRevisionRequiredCheck); // Si une info concernant une revision est modifiée, on met à jour l'obligation des champs de revisions

        /**
         * Met à jour l'obligation des champs de revisions en fonction de si au moins un des champs est valide.
         * Au moins un champ de revision doit obligatoirement avoir une valeur pour pouvoir passer à l'étape suivante.
         */
        function repriseRevisionRequiredCheck() {
            const revisions = $("[id^='reprise-controles-'] [id^='reprise-revision']").toArray(); // Récupère tous les champs de revisions
            revisions.forEach(revision => { revision.setAttribute("required", ""); }); // Met tous les champs de revisions obligatoires
            const isValid = revisions.some(input => input.checkValidity()); // Récupère si au moins un champ de revision est valide
            if (isValid) { // Si au moins un champ de revision est valide
                revisions.forEach(revision => { revision.removeAttribute("required"); }); // Met tous les champs de revisions non obligatoires
            }
        }

        $('body').on('change', "[id^='reprise-controles-'] [id^='reprise-']", function () {
            updateStep(5, this.id.replace(/.*-(\d+)$/g, "$1")); // Mise à jour de l'étape 5 en cas de changement sur l'un des champs
        });

        $('body').on('click', "[id^='reprise-hasMesures-']", function () { updateStep(5, repriseBtnSwitch(this).data("id")); }); // Mise à jour de l'étape 5 en cas de clique sur l'un des champs de mesures des pneus
        $('body').on('click', "[id^='reprise-entretienHistorique-']", function () { updateStep(5, repriseBtnSwitch(this).data("id")); }); // Mise à jour de l'étape 5 en cas de clique sur le champ d'historique d'entretien
        $('body').on('click', "[id^='reprise-entretienReseau-']", function () { updateStep(5, repriseBtnSwitch(this).data("id")); }); // Mise à jour de l'étape 5 en cas de clique sur le champ du réseau d'entretien
        $('body').on('click', "[id^='reprise-hasct-']", function () { updateStep(5, repriseBtnSwitch(this).data("id")); }); // Mise à jour de l'étape 5 en cas de clique sur la réalisation du CT avant ou pas

        /**
         * Switch entre des boutons à deux etats (value = 0 ou 1) et affiche ou cache les inputs qui dépendent de l'input cliqué.
         * @param {HTMLElement} input Le bouton qui a été cliqué.
         * @returns {jQuery} Le bouton jquery pour ne pas avoir à le rechargé après la fonction. Opti inutile mais amusante.
         */
        function repriseBtnSwitch(input) {
            const jInput = $(input); // Récupère le jquery de l'input
            const value = jInput.removeClass("notactive").removeClass("btn-outline-secondary").addClass("btn-outline-primary").data("value"); // Récupère la valeur de l'input
            if (value !== undefined) { // Si l'input a une valeur
                $("#" + input.id.replace("-" + value, "-" + (1 - value))).removeClass("notactive").removeClass("btn-outline-primary").addClass("btn-outline-secondary"); // Change l'etat de l'input à la valeur opposé
                $("dialog [" + input.id.replace("-" + value, "") + "='" + (1 - value) + "']").addClass("d-none"); // Cache les inputs qui dépendent de la valeur opposé
                $("dialog [" + input.id.replace("-" + value, "") + "='" + value + "']").removeClass("d-none"); // Affiche les inputs qui dépendent de la valeur actuelle
            } else { $("dialog [" + input.replace("-" + value, "") + "]").addClass("d-none"); } // Cache tous les inputs qui dépendent de l'input cliqué
            return jInput; // Retourne l'input jquery pour ne pas avoir à le rechargé après la fonction. Opti inutile mais amusante.
        }

        $('body').on('change', "[id^='reprise-dashboardPhoto-']", function () { repriseFileUpload(this, (data) => repriseMultiPhoto(data.id, "add", "dashboardPhoto")); }); // Ajoute une photo de dashboard
        $('body').on('click', "[id^='reprise-dashboardPhoto-'][id$='-delete']", function () { repriseMultiPhoto(this.id.replace("reprise-dashboardPhoto-", "").replace("-delete", ""), "del", "dashboardPhoto"); }); // Supprime une photo de dashboard
        $('body').on('change', "[id^='reprise-pneuPhoto-']", function () { repriseFileUpload(this, (data) => repriseMultiPhoto(data.id, "add", "pneuPhoto")); }); // Ajoute une photo de pneu
        $('body').on('click', "[id^='reprise-pneuPhoto-'][id$='-delete']", function () { repriseMultiPhoto(this.id.replace("reprise-pneuPhoto-", "").replace("-delete", ""), "del", "pneuPhoto"); }); // Supprime une photo de pneu

        /**
         * ----------
         * Input step 6
         * ----------
         */

        $('body').on('change', "[id^='reprise-photoVF-']", function () { repriseFileUpload(this); }); // Si une photo est ajoutée, on appel la fonction qui upload la photo

        /**
         * ----------
         * Input step 7
         * ----------
         */

        $('body').on('change', "[id^='reprise-delaiProjet-']", function () { updateStep(7, this.id.replace(/.*-(\d+)$/g, "$1")); }); // Mise à jour de l'étape 7 en cas de changement du délai de projet
        $('body').on('change', "[id^='reprise-isCertif-']", function () { updateStep(7, this.id.replace(/.*-(\d+)$/g, "$1")); }); // Mise à jour de l'étape 7 en cas de clique sur le champ de certification sur l'honneur

        /**
         * Enregistre la signature sur le serveur et met à jour l'étape 7 si la signature est complète.
         * @param {HTMLElement} node Le noeud qui a déclenché la fonction (le bouton "Valider" de la signature, ce bouton n'existe que de manière fictive à travers un input hidden).
         */
        function repriseSign(node) {
            const capture = node; // Le bouton "Valider" de la signature
            const canvas = capture.parentElement.querySelector("[sign-canvas]"); // Le canvas de la signature
            const repriseId = $(capture).parents("dialog").data("id"); // L'id de la reprise
            if (getRepriseSignCanvasIsComplete(canvas)) { // Check le minimum de completude du canvas
                canvas.setAttribute("signed", "");
                canvas.toBlob(function (blob) {
                    const form = new FormData();
                    form.append('data', new File([blob], "sign.png", { type: "image/png" })); // Ajoute la capture de la signature au FormData
                    form.append('repriseId', repriseId);
                    $.ajax({
                        type: 'POST',
                        url: siteURL + 'admin/achat/reprises/reprise/sign', // Envoie la signature au serveur
                        contentType: false,
                        processData: false,
                        data: form,
                        success: function (data) {
                            if (data.success) { updateStep(7, repriseId); } // Mise à jour de l'étape 7 si la requête d'enregistrement a fonctionné
                            else { updateStepError(dialog); }
                        },
                        error: function () { updateStepError(dialog); }
                    });
                }, "image/png");
            } else { canvas.removeAttribute("signed"); }
        }

        /**
         * ----------
         * Update step
         * ----------
         */

        /**
         * Met à jour l'étape de la reprise en fonction de l'id de la reprise et envoi la requête d'enregistrement avec les informations de l'étape.
         * @param {Number} step L'étape à mettre à jour.
         * @param {Number} repriseId L'id de la reprise, donc du tunnel.
         * @param {Boolean} [update=true] Si la requête d'enregistrement de la reprise doit être envoyée.
         * @param {Boolean} [check=false] Si l'erreur doit être affichée.
         */
        function updateStep(step, repriseId, update = true, check = false) {
            const dialog = $(channelGetDialog(step, "reprise")), // Récupère la dialog de l'étape
                reprise = { id: repriseId }, // Initialisation de l'objet contenant les données de la reprise
                error = []; // Initialisation de l'array contenant les erreurs
            reprise.step = step; // Ajoute de l'étape dans l'objet contenant les données de la reprise
            switch (step) { // Recherche le code à exécuter en fonction de l'étape
                case 1: // Étape 1
                    reprise.type = getRepriseTypeValue(repriseId);
                    reprise.vehicule = getRepriseVehiculeValue(repriseId);
                    if (displayNextBtn(dialog, reprise.type == 1 || (reprise.type == 2 && reprise.vehicule != null))) {
                        if (reprise.id != 0) {
                            if (update) { updateStepRequest(dialog, reprise); }
                        }
                    } else if (check) {
                        repriseCheckForm(dialog);
                        if (reprise.type == null) { error.push("Le type de reprise n'a pas été renseigné."); }
                        else if (reprise.type == 2 && reprise.vehicule == null) { error.push("Le véhicule qui intéresse le client n'a pas été renseigné."); }
                        checkStepError(dialog, error);
                    } else {
                        repriseCheckForm(dialog, false);
                    }
                    break;

                case 2: // Étape 2
                    reprise.type = getRepriseTypeValue(repriseId);
                    reprise.vehicule = getRepriseVehiculeValue(repriseId);
                    reprise.vin = getRepriseVINValue(repriseId);
                    reprise.pays = getReprisePaysValue(repriseId);
                    reprise.immatriculation = getRepriseImmatriculationValue(repriseId);
                    reprise.dureeDetention = getRepriseDureeDetentionValue(repriseId);
                    reprise.dateMEC = getRepriseDateMECValue(repriseId);
                    repriseMarque(document.getElementById("reprise-marque-" + repriseId));
                    reprise.marque = getRepriseMarqueValue(repriseId);
                    reprise.modele = getRepriseModeleValue(repriseId);
                    reprise.version = getRepriseVersionValue(repriseId);
                    reprise.boite = getRepriseBoiteValue(repriseId);
                    reprise.carburant = getRepriseCarburantValue(repriseId);
                    reprise.kilometrage = getRepriseKilometrageValue(repriseId);
                    reprise.puissanceDIN = getReprisePuissanceDINValue(repriseId);
                    reprise.couleur = getRepriseCouleurValue(repriseId);
                    reprise.tva = getRepriseTVAValue(repriseId);
                    reprise.malus = getRepriseMalusValue(repriseId);
                    reprise.nbPlace = getRepriseNbPlaceValue(repriseId);
                    reprise.commentaire = getRepriseCommentaireValue(repriseId);
                    reprise.optionV = getRepriseOptionVValue(repriseId);
                    if (
                        displayNextBtn(
                            dialog,
                            matchVIN(reprise.vin)
                            && reprise.immatriculation.length > 0
                            && reprise.dureeDetention != null
                            && reprise.dateMEC.length > 0
                            && reprise.marque != null
                            && reprise.modele != null
                            && reprise.boite != null
                            && reprise.carburant != null
                            && reprise.kilometrage.length > 0
                            && reprise.puissanceDIN.length > 0
                            && reprise.couleur != null
                            && reprise.malus.length > 0
                            && reprise.nbPlace != null
                        )
                    ) {
                        if (update) { updateStepRequest(dialog, reprise); }
                    } else if (check) {
                        repriseCheckForm(dialog);
                        if (reprise.vin.length == 0) { error.push("Le VIN n'a pas été renseigné."); }
                        else if (reprise.vin.length != 17) { error.push("Le VIN renseigné n'a pas 17 caractères."); }
                        else if (!matchVIN(reprise.vin)) { error.push("Le VIN renseigné comporte des caractères non autorisés."); }
                        if (reprise.immatriculation.length == 0) { error.push("L'immatriculation n'a pas été renseignée."); }
                        if (reprise.dureeDetention == null) { error.push("La durée de détention n'a pas été renseignée."); }
                        if (reprise.dateMEC.length == 0) { error.push("La date de MEC n'a pas été renseignée."); }
                        if (reprise.marque == null) { error.push("La marque n'a pas été renseignée."); }
                        if (reprise.modele == null) { error.push("Le modèle n'a pas été renseigné."); }
                        if (reprise.boite == null) { error.push("La boite n'a pas été renseignée."); }
                        if (reprise.carburant == null) { error.push("L'énergie n'a pas été renseignée."); }
                        if (reprise.kilometrage.length == 0) { error.push("Le kilometrage n'a pas été renseigné."); }
                        if (reprise.puissanceDIN.length == 0) { error.push("La puissance DIN n'a pas été renseignée."); }
                        if (reprise.couleur == null) { error.push("La couleur n'a pas été renseignée."); }
                        if (reprise.malus.length == 0) { error.push("Le malus n'a pas été renseigné."); }
                        if (reprise.nbPlace == null) { error.push("Le nombre de place n'a pas été renseigné."); }
                        checkStepError(dialog, error);
                    } else {
                        repriseCheckForm(dialog, false);
                    }
                    break;

                case 3: // Étape 3
                    reprise.photoAvant = getReprisePhotoAvantValue(repriseId);
                    reprise.photoArriere = getReprisePhotoArriereValue(repriseId);
                    reprise.photoInterieur = getReprisePhotoInterieurValue(repriseId);
                    if (displayNextBtn(dialog, reprise.photoAvant.length > 0 && reprise.photoArriere.length > 0 && reprise.photoInterieur.length > 0)) {
                        if (update) { updateStepRequest(dialog, reprise); }
                    } else if (check) {
                        repriseCheckForm(dialog);
                        if (reprise.photoAvant.length == 0) { error.push("La photo 3/4 avant n'a pas été prise."); }
                        if (reprise.photoArriere.length == 0) { error.push("La photo 3/4 arrière n'a pas été prise."); }
                        if (reprise.photoInterieur.length == 0) { error.push("Le photo interieur n'a pas été prise."); }
                        checkStepError(dialog, error);
                    } else {
                        repriseCheckForm(dialog, false);
                    }
                    break;

                case 5: // Étape 5
                    repriseRevisionRequiredCheck();
                    reprise.revisionDuree = getRepriseRevisionDureeValue(repriseId);
                    reprise.revisionDistance = getRepriseRevisionDistanceValue(repriseId);
                    reprise.dateDernierControleTechnique = getRepriseDateDernierControleTechniqueValue(repriseId);
                    reprise.typePneuAv = getRepriseTypePneuAvValue(repriseId);
                    reprise.typePneuAr = getRepriseTypePneuArValue(repriseId);
                    reprise.hasMesures = getRepriseHasMesuresValue(repriseId);
                    reprise.pneuAvG = getReprisePneuAvGValue(repriseId);
                    reprise.pneuAvD = getReprisePneuAvDValue(repriseId);
                    reprise.pneuArG = getReprisePneuArGValue(repriseId);
                    reprise.pneuArD = getReprisePneuArDValue(repriseId);
                    reprise.diametrePneuAv = getRepriseDiametrePneuAvValue(repriseId);
                    reprise.diametrePneuAr = getRepriseDiametrePneuArValue(repriseId);
                    reprise.entretienHistorique = getRepriseEntretienHistoriqueValue(repriseId);
                    reprise.entretienReseau = getRepriseEntretienReseauValue(repriseId);
                    if (
                        displayNextBtn(
                            dialog,
                            (reprise.revisionDuree.length > 0 || reprise.revisionDistance.length > 0)
                            && reprise.dateDernierControleTechnique != ""
                            && reprise.hasMesures != undefined
                            && (reprise.hasMesures ? reprise.pneuAvG.length > 0 && reprise.pneuAvD.length > 0 && reprise.pneuArG.length > 0 && reprise.pneuArD.length > 0 : true)
                            && reprise.entretienHistorique != undefined
                            && (reprise.entretienHistorique ? reprise.entretienReseau != undefined : true)
                        )
                    ) {
                        if (update) { updateStepRequest(dialog, reprise); }
                    } else if (check) {
                        repriseCheckForm(dialog);
                        if (reprise.revisionDuree.length == 0 && reprise.revisionDistance.length == 0) { error.push("L'échéance de la prochaine révision n'a pas été renseignée."); }
                        if (reprise.dateDernierControleTechnique == '') { error.push("La date de dernier contrôle technique n'a pas été renseignée."); }
                        if (reprise.hasMesures == undefined) { error.push("La faisabilité des mesures n'a pas été renseignée."); }
                        else if (reprise.hasMesures) {
                            if (reprise.pneuAvG.length == 0) { error.push("La mesure du pneu avant gauche n'a pas été renseignée."); }
                            if (reprise.pneuAvD.length == 0) { error.push("La mesure du pneu avant droit n'a pas été renseignée."); }
                            if (reprise.pneuArG.length == 0) { error.push("La mesure du pneu arrière gauche n'a pas été renseignée."); }
                            if (reprise.pneuArD.length == 0) { error.push("La mesure du pneu arrière droit n'a pas été renseignée."); }
                        }
                        if (reprise.entretienHistorique == undefined) { error.push("La présence de l'historique d'entretien n'a pas été renseignée."); }
                        else if (reprise.entretienHistorique && reprise.entretienReseau == undefined) { error.push("Le réseau d'entretien du véhicule n'a pas été renseignée."); }
                        checkStepError(dialog, error);
                    } else {
                        repriseCheckForm(dialog, false);
                    }
                    break;

                case 6: // Étape 6
                    reprise.carteGrise = getRepriseCarteGriseValue(repriseId);
                    reprise.coteArgus = getRepriseCoteArgusValue(repriseId);
                    if (displayNextBtn(dialog, reprise.carteGrise.length > 0 && reprise.coteArgus.length > 0)) {
                        if (update) { updateStepRequest(dialog, reprise); }
                    } else if (check) {
                        repriseCheckForm(dialog);
                        if (reprise.carteGrise.length == 0) { error.push("La photo de la carte grise n'a pas été prise."); }
                        if (reprise.coteArgus.length == 0) { error.push("La photo de la cote Argus n'a pas été prise."); }
                        checkStepError(dialog, error);
                    } else {
                        repriseCheckForm(dialog, false);
                    }
                    break;

                case 7: // Étape 7
                    reprise.delaiProjet = getRepriseDelaiProjetValue(repriseId);
                    reprise.isCertif = getRepriseIsCertifValue(repriseId);
                    const jCanvas = dialog.find("[sign-canvas]#sign-canvas-reprise");
                    const canvas = jCanvas.length ? jCanvas[0] : null;
                    if (displayNextBtn(dialog, reprise.delaiProjet != null && reprise.isCertif && getRepriseSignCanvasIsComplete(canvas))) {
                        if (update) { updateStepRequest(dialog, reprise); }
                    } else if (check) {
                        repriseCheckForm(dialog);
                        if (reprise.delaiProjet) { error.push("Le délai du projet n'a pas été renseigné."); }
                        if (!reprise.isCertif) { error.push("Le client doit certifier la comformité de son véhicule."); }
                        if (!getRepriseSignCanvasIsComplete(canvas)) { error.push("Le client doit signer la comformité de son véhicule."); }
                        checkStepError(dialog, error);
                    } else {
                        repriseCheckForm(dialog, false);
                    }
                    break;

                default:
                    // Pour le reset ? Inutile pour l'instant mais présent par convention.
                    break;
            }
        }
        let lastUpdateStepRequest = null;
        let workingUpdateStepRequest = false;
        function updateStepRequest(dialog, reprise) {
            loadingNextBtn(dialog);
            const repriseIdInput = dialog.find("#reprise-id");
            if (repriseIdInput.length) { reprise.id = repriseIdInput.val(); }
            reprise.requestId = Date.now()
            const form = new FormData();
            form.append('reprise', JSON.stringify(reprise));
            lastUpdateStepRequest = reprise;
            if (!workingUpdateStepRequest) { // Si il n'y a pas de requête en cours
                workingUpdateStepRequest = true; // On annonce qu'il y a un requête en cours
                $.ajax({
                    type: 'POST',
                    url: siteURL + 'admin/achat/reprises/reprise/modify',
                    contentType: false,
                    processData: false,
                    data: form,
                    success: function (data) {
                        workingUpdateStepRequest = false; // On annonce qu'il n'y a plus de requête en cours
                        if (data.success) {
                            if (repriseIdInput.length) { repriseIdInput.val(data.id); }
                            loadingNextBtn(dialog, false);
                        } else { updateStepError(dialog); }

                        // Si il y a une requête suivante et si la requête n'est pas la même que la requête suivante, on relance une requête
                        if (lastUpdateStepRequest != null && lastUpdateStepRequest.requestId != data.requestId) { updateStepRequest(dialog, lastUpdateStepRequest); }
                        lastUpdateStepRequest = null; // On supprime l'info qu'il y a un requête suivante pour éviter les boucles infinie de requête
                    },
                    error: function () { workingUpdateStepRequest = false; lastUpdateStepRequest = null; updateStepError(dialog); }
                });
            }
        }

        /**
         * Affiche un message d'erreur sur la dialog donnée
         * @param {object} dialog - La dialog concernée par le message
         * @param {string} [msg="Erreur lors de l'enregistrement, si le problème persiste, veuillez contacter le service WEB."] - Le message d'erreur
         */
        function updateStepError(dialog, msg = "Erreur lors de l'enregistrement, si le problème persiste, veuillez contacter le service WEB.") {
            dialogToastrError(dialog, dialog.data("name"), msg); // Lance la fonction qui affiche le message d'erreur
        }

        /**
         * Affiche un message d'erreur sur la dialog donnée si le tableau d'erreur n'est pas vide
         * @param {object} dialog - La dialog concernée par le message
         * @param {array} error - Le tableau d'erreur
         */
        function checkStepError(dialog, error) {
            if (error.length) { dialogToastrError(dialog, dialog.data("name"), error.join("<br>")); }
        }

        /**
         * Ajoute ou supprime l'attribut reprise-check-form pour indiquer si la dialog doit vérifier les champs de formulaire avant de lancer la prochaine étape
         * Sert à l'affichage des bordures rouge des champs manquants
         * @param {object} dialog - La dialog concernée
         * @param {boolean} [check=true] - Indique si la dialog doit vérifier les champs de formulaire
         * @returns {boolean} - Si l'attribut est ajouté ou supprimé
         */
        function repriseCheckForm(dialog, check = true) {
            if (check) { dialog.attr("reprise-check-form", ""); }
            else { dialog.removeAttr("reprise-check-form"); }
            return check;
        }

        /**
         * Affiche ou cache le bouton de passage à l'étape suivante
         * @param {object} dialog - La dialog concernée
         * @param {boolean} [display=true] - Indique si le bouton doit être affiché ou non
         * @returns {boolean} - Si le bouton est affiché ou non
         */
        function displayNextBtn(dialog, display) {
            if (display) { dialog.find("[channel-dialog-next], [channel-dialog-end]").attr("enabled", ""); }
            else { dialog.find("[channel-dialog-next], [channel-dialog-end]").removeAttr("enabled"); }
            return display;
        }

        /**
         * Change l'affichage du bouton de passage à l'étape suivante avec un indicateur de chargement
         * @param {object} dialog - La dialog concernée
         * @param {boolean} [loading=true] - Indique si le bouton doit afficher l'indicateur de chargement
         * @returns {boolean} - Si le bouton affiche l'indicateur de chargement
         */
        function loadingNextBtn(dialog, loading = true) {
            if (loading) { dialog.find("[channel-dialog-next]").attr("disabled", "").find("i").removeClass("fa-arrow-right").addClass("fa-spinner fa-spin"); }
            else { dialog.find("[channel-dialog-next]").removeAttr("disabled").find("i").removeClass("fa-spinner fa-spin").addClass("fa-arrow-right"); }
            return loading;
        }

        /**
         * Change l'affichage du bouton de fin de la reprise avec un indicateur de chargement et change l'affichage de la modal de progression de l'envoi de la reprise sur wapp
         * @param {object} dialog - La dialog concernée
         * @param {boolean} [loading=true] - Indique si le bouton doit afficher l'indicateur de chargement
         * @returns {boolean} - Si le bouton affiche l'indicateur de chargement
         */
        function loadingEndBtn(dialog, loading = true) {
            const repriseWappLoading = dialog.find("#reprise-wapp-loading");
            if (loading) {
                dialog.find("[channel-dialog-end]").attr("disabled", "").find("i").removeClass("fab fa-whatsapp").addClass("far fa-spinner fa-spin");
                repriseWappLoading.modal('show');
            } else {
                repriseWappLoading.modal('hide');
                dialog.find("[channel-dialog-end]").removeAttr("disabled").find("i").removeClass("far fa-spinner fa-spin").addClass("fab fa-whatsapp");
            }
            return loading;
        }

        /**
         * Envoie un fichier à la fonction de upload de fichier, redimensionne l'image si elle est trop grande
         * @param {object} input - L'input file qui contient le fichier à envoyer
         * @param {function} fileUploadResult - La fonction qui sera appelée avec le résultat de l'upload, permet d'assurer une réception personnalisée du résultat de l'upload
         */
        function repriseFileUpload(input, fileUploadResult = null) {
            const jInput = $(input); // Récupérer le jquery de l'input
            const displayArea = $("#" + input.id + "-display"); // Récupérer le lieu d'affichage du fichier après l'upload
            const resultArea = $("#" + input.id + "-result"); // Récupérer l'input où sera stocké l'id du fichier après l'upload
            const repriseFileUploadResult = fileUploadResult ?? function (data) { repriseFileUploadDisplay(displayArea, data); resultArea.val(data.id); }; // Si aucune fonction de gestion du resultat n'est fourni, utiliser la fonction par defaut
            const file = jInput.data("rename") === undefined ? input.files[0] : new File([input.files[0]], jInput.data("rename") + "." + input.files[0].type.split("/")[1], { type: input.files[0].type }); // Récupère le fichier et le renomme si besoin
            const fr = new FileReader(); // Créer un lecteur de fichier
            fr.onload = function (e) { // Lorsque le lecteur de fichier a chargé le fichier
                const img = new Image(); // Créer une image
                img.onload = function () { // Lorsque l'image a chargé
                    // Créer un canvas pour redimensionner l'image
                    const canvas = document.createElement('canvas');
                    // Définir les nouvelles dimensions
                    canvas.width = (img.width >= 3000 ? img.width / 4 : (img.width >= 1500 ? img.width / 2 : img.width));
                    canvas.height = (img.width >= 3000 ? img.height / 4 : (img.width >= 1500 ? img.height / 2 : img.height));
                    // Dessiner l'image redimensionnée dans le canvas
                    canvas.getContext('2d').drawImage(img, 0, 0, canvas.width, canvas.height);
                    // Convertir le canvas en un blob
                    canvas.toBlob(function (blob) {
                        // Créer un nouveau fichier à partir du blob redimensionné
                        let newFile = new File([blob], file.name, { type: file.type });
                        newFile = new File([newFile], newFile.name.replace(new RegExp("(.+)[.](.+[^.])", "g"), "$1").slugify() + "." + newFile.name.replace(new RegExp("(.+)[.](.+[^.])", "g"), "$2").slugify(), { type: newFile.type });
                        const form = new FormData();
                        form.append('data', newFile);
                        form.append('filemime', jInput.data("mime"));
                        form.append('filetypes', jInput.data("filetypes"));
                        form.append('imageratio', jInput.data("imageRatio"));
                        form.append('fileexist', jInput.data("fileexist"));
                        $.ajax({
                            url: siteURL + 'admin/file/upload',
                            type: 'POST',
                            cache: false,
                            contentType: false,
                            processData: false,
                            data: form,
                            success: function (data) {
                                const step = $(input).parents("dialog").data("step");
                                const dialog = $(channelGetDialog(step, "reprise"));
                                if (data.success) {
                                    dialogToastrSuccess(dialog, dialog.data("name"), "Le fichier a bien été envoyé.");
                                    repriseFileUploadResult(data);
                                } else {
                                    switch (data.error) { // Gestion des erreurs d'upload
                                        case "type":
                                            repriseFileUploadType(dialog, data);
                                            break;
                                        case "ratio":
                                            repriseFileUploadRatio(dialog, data);
                                            break;
                                        case "exists":
                                            repriseFileUploadExists(dialog, data);
                                            repriseFileUploadResult(data);
                                            break;
                                        default:
                                            repriseFileUploadDefault(dialog);
                                            break;
                                    }
                                }
                                updateStep(step, dialog.data("id"));
                            },
                        });
                    }, file.type);
                }
                img.src = e.target.result; // Demander à l'image le résultat de la lecture du fichier par le lecteur
            }
            fr.readAsDataURL(file); // Demander au lecteur de lire le fichier
        }

        /**
         * Affiche le résultat d'un upload de fichier et scroll vers le resultat
         * @param {jQuery} displayArea - La zone de contenu qui va afficher le résultat
         * @param {Object} data - Les données du fichier uploadé
         * @param {String} data.path - Le chemin du fichier
         * @param {String} data.file - Le nom du fichier
         * @param {Object} data.image - Les informations de l'image
         * @param {Number} data.image.width - La largeur de l'image
         * @param {Number} data.image.height - La hauteur de l'image
         * @param {Number} data.size - La taille du fichier en bytes
         */
        function repriseFileUploadDisplay(displayArea, data) {
            displayArea.slideUp('slow', function () {
                displayArea.html('<div class="w-100 text-center"><img src="' + data.path + data.file + '" class="mt-2 img-fluid border border-secondary rounded-sm"><div class="file-info">' + data.file + ' - ' + data.image.width + " <i class=\"fal fa-times\"></i> " + data.image.height + " - " + data.size + ' bytes</div></div>');
                displayArea.slideDown();
            });
        }

        /**
         * Affiche une erreur dans un dialogue concernant le type de fichier attendu
         * @param {jQuery} dialog - Le dialogue qui affichera l'erreur
         * @param {Object} data - Les données du fichier uploadé
         * @param {Array} data.expected - Les types de fichiers attendus
         */
        function repriseFileUploadType(dialog, data) {
            let expectedType = "";
            data.expected.forEach(function (e) { expectedType = expectedType + "<br /> - " + e; });
            dialogToastrError(dialog, dialog.data("name"), "Le fichier que vous avez envoyé n'est pas d'un format attendu :" + expectedType);
        }

        /**
         * Affiche une erreur dans un dialogue concernant le ratio attendu
         * @param {jQuery} dialog - Le dialogue qui affichera l'erreur
         * @param {Object} data - Les données du fichier uploadé
         * @param {Number} data.expected - Le ratio attendu
         */
        function repriseFileUploadRatio(dialog, data) {
            dialogToastrError(dialog, dialog.data("name"), "Le fichier que vous avez envoyé n'est pas au bon ratio attendu: " + data.expected);
        }

        /**
         * Affiche un message de succès dans un dialog concernant le fait que le fichier uploadé existe déjà en base de données
         * @param {jQuery} dialog - Le dialogue qui affichera le message de succès
         */
        function repriseFileUploadExists(dialog) {
            dialogToastrSuccess(dialog, dialog.data("name"), "Le fichier que vous avez envoyé existe déjà en base de données.");
        }

        /**
         * Affiche un message d'erreur dans un dialog concernant le fait qu'une erreur non identifiée soit survenue lors de l'enregistrement du fichier
         * @param {jQuery} dialog - Le dialogue qui affichera le message d'erreur
         */
        function repriseFileUploadDefault(dialog) {
            dialogToastrError(dialog, dialog.data("name"), "Erreur lors de l'enregistrement, si le problème persiste, veuillez contacter le service WEB.");
        }

        function repriseMultiPhoto(val, act, typePhoto) {
            const dialog = $(channelGetDialog(5, "reprise"));
            let form = new FormData();
            form.append("id", dialog.data("id"));
            form.append("val", val);
            form.append("act", act);
            $.ajax({
                type: "POST",
                url: siteURL + "admin/achat/reprises/reprise/" + typePhoto,
                contentType: false,
                processData: false,
                data: form,
                success: function (data) {
                    if (data.success) {
                        const refEl = document.getElementById("reprise-" + typePhoto + "-__id__-item");
                        const listEl = $("dialog #reprise-" + typePhoto + "-list")[0];
                        if (act == "add") {
                            dialogToastrSuccess(dialog, "expertise", 'Enregistrement réussi');
                            const newEl = refEl.cloneNode(true);
                            newEl.id = newEl.id.replace("__id__", val);
                            newEl.innerHTML = newEl.innerHTML.replace("__id__", val).replace("__file__", data.path + data.filename);
                            newEl.firstElementChild.setAttribute("custom-fancy", "reprise-" + typePhoto + "-item");
                            if (listEl.childElementCount > 0) { listEl.firstElementChild.before(newEl); } else { listEl.appendChild(newEl); }
                        } else {
                            dialogToastrSuccess(dialog, "expertise", 'Suppression réussi');
                            $("[id='reprise-" + typePhoto + "-" + val + "-item']").remove();
                        }
                        updateStep(5, dialog.data("id"));
                    } else { updateStepError(dialog); }
                },
                error: function () { updateStepError(dialog); }
            });
        }

        /**
         * ----------
         * Get value
         * ----------
         */

        function getRepriseTypeValue(repriseId) {
            return $("#reprise-type-" + repriseId).find(".active[reprise-type]").length
                ? $("#reprise-type-" + repriseId).find(".active[reprise-type]")[0].id.replace("reprise-type-" + repriseId + "-", "")
                : null;
        }

        function getRepriseVehiculeValue(repriseId) {
            return $("#reprise-vehicule-" + repriseId).val();
        }

        function getRepriseVINValue(repriseId) {
            return $("#reprise-vin-" + repriseId).val();
        }

        function getReprisePaysValue(repriseId) {
            return $("#reprise-pays-" + repriseId).val();
        }

        function getRepriseImmatriculationValue(repriseId) {
            return $("#reprise-immatriculation-" + repriseId).val();
        }

        function getRepriseDureeDetentionValue(repriseId) {
            return $("#reprise-dureeDetention-" + repriseId).val();
        }

        function getRepriseDateMECValue(repriseId) {
            return $("#reprise-date_mec-" + repriseId).val();
        }

        function getRepriseMarqueValue(repriseId) {
            return $("#reprise-marque-" + repriseId).val();
        }

        function getRepriseModeleValue(repriseId) {
            return $("#reprise-modele-" + repriseId).val();
        }

        function getRepriseVersionValue(repriseId) {
            return $("#reprise-version-" + repriseId).val();
        }

        function getRepriseBoiteValue(repriseId) {
            return $("#reprise-boite-" + repriseId).val();
        }

        function getRepriseCarburantValue(repriseId) {
            return $("#reprise-carburant-" + repriseId).val();
        }

        function getRepriseKilometrageValue(repriseId) {
            return $("#reprise-kilometrage-" + repriseId).val();
        }

        function getReprisePuissanceDINValue(repriseId) {
            return $("#reprise-puissance_din-" + repriseId).val();
        }

        function getRepriseCouleurValue(repriseId) {
            return $("#reprise-couleur-" + repriseId).val();
        }

        function getRepriseTVAValue(repriseId) {
            return $("#reprise-tva-" + repriseId).val() == "on";
        }

        function getRepriseMalusValue(repriseId) {
            return $("#reprise-malus-" + repriseId).val();
        }

        function getRepriseNbPlaceValue(repriseId) {
            return $("#reprise-nbPlace-" + repriseId).val();
        }

        function getRepriseCommentaireValue(repriseId) {
            return $("#reprise-commentaire-" + repriseId).val();
        }

        function getRepriseOptionVValue(repriseId) {
            return $("#reprise-option_v-" + repriseId).val();
        }

        function getReprisePhotoAvantValue(repriseId) {
            return $("#reprise-photo-photoAvant-" + repriseId + "-result").val();
        }

        function getReprisePhotoArriereValue(repriseId) {
            return $("#reprise-photo-photoArriere-" + repriseId + "-result").val();
        }

        function getReprisePhotoInterieurValue(repriseId) {
            return $("#reprise-photo-photoInterieur-" + repriseId + "-result").val();
        }

        function getRepriseRevisionDureeValue(repriseId) {
            return $("#reprise-revisionDuree-" + repriseId).val();
        }

        function getRepriseRevisionDistanceValue(repriseId) {
            return $("#reprise-revisionDistance-" + repriseId).val();
        }

        function getRepriseDateDernierControleTechniqueValue(repriseId) {
            return $("#reprise-dateDernierControleTechnique-" + repriseId).parent().hasClass('d-none') ? null : $("#reprise-dateDernierControleTechnique-" + repriseId).val();
        }

        function getRepriseTypePneuAvValue(repriseId) {
            return $("#reprise-typePneuAv-" + repriseId).val();
        }

        function getRepriseTypePneuArValue(repriseId) {
            return $("#reprise-typePneuAr-" + repriseId).val();
        }

        function getRepriseHasMesuresValue(repriseId) {
            return repriseBtnSwitch(document.querySelector("[id^='reprise-hasMesures-'][data-id='" + repriseId + "'].btn-outline-primary") ?? "reprise-hasMesures").data("value");
        }

        function getReprisePneuAvGValue(repriseId) {
            return $("#reprise-pneuAvG-" + repriseId).val();
        }

        function getReprisePneuAvDValue(repriseId) {
            return $("#reprise-pneuAvD-" + repriseId).val();
        }

        function getReprisePneuArGValue(repriseId) {
            return $("#reprise-pneuArG-" + repriseId).val();
        }

        function getReprisePneuArDValue(repriseId) {
            return $("#reprise-pneuArD-" + repriseId).val();
        }

        function getRepriseDiametrePneuAvValue(repriseId) {
            return $("#reprise-diametrePneuAv-" + repriseId).val();
        }

        function getRepriseDiametrePneuArValue(repriseId) {
            return $("#reprise-diametrePneuAr-" + repriseId).val();
        }

        function getRepriseEntretienHistoriqueValue(repriseId) {
            return repriseBtnSwitch(document.querySelector("[id^='reprise-entretienHistorique-'][data-id='" + repriseId + "'].btn-outline-primary") ?? "reprise-entretienHistorique").data("value");
        }

        function getRepriseEntretienReseauValue(repriseId) {
            return repriseBtnSwitch(document.querySelector("[id^='reprise-entretienReseau-'][data-id='" + repriseId + "'].btn-outline-primary") ?? "reprise-entretienReseau").data("value");
        }

        function getRepriseCarteGriseValue(repriseId) {
            return $("#reprise-photoVF-carteGrise-" + repriseId + "-result").val();
        }

        function getRepriseCoteArgusValue(repriseId) {
            return $("#reprise-photoVF-coteArgus-" + repriseId + "-result").val();
        }

        function getRepriseDelaiProjetValue(repriseId) {
            return $("#reprise-delaiProjet-" + repriseId).val();
        }

        function getRepriseIsCertifValue(repriseId) {
            return $("#reprise-isCertif-" + repriseId).val() == "on";
        }

        function getRepriseSignCanvasComplete(canvas) {
            const imageData = canvas.getContext("2d", { willReadFrequently: true }).getImageData(0, 0, canvas.width, canvas.height);
            const pixels = imageData.data;
            let drawnPixelCount = 0;
            // Les données de pixels sont un tableau où chaque pixel est représenté par 4 valeurs (RGBA), le canal alpha est la 4e valeur.
            for (let i = 3; i < pixels.length; i += 4) {
                if (pixels[i] > 0) {
                    drawnPixelCount++;
                }
            }
            return (drawnPixelCount / (pixels.length / 4)) * 100; // Pourcentage de complétude effectif
        }

        function getRepriseSignCanvasCompleteLimit() {
            return 0.5; // Pourcentage de complétude minimal requis
        }

        function getRepriseSignCanvasIsComplete(canvas) { // Si pas de canvas, on est en modification donc on a déjà la signature
            return canvas == null ? true : getRepriseSignCanvasComplete(canvas) >= getRepriseSignCanvasCompleteLimit(); // Pourcentage de complétude effectif >= complétude minimal requis
        }

        /**
         * ----------
         * WhatsApp
         * ----------
         */

        async function repriseWappPost(repriseWappData) {
            return new Promise((resolve, reject) => {
                wappPost("messages/chat", wapp.to_reprise, { body: repriseWappData.message })
                    .then(() => {
                        $("#reprise-wapp-progress-message .progress > .progress-bar").removeClass("w-0").addClass("w-100");
                        wappPost("messages/image", wapp.to_reprise, { image: repriseWappData.photoAvant, caption: "Photo 3/4 avant" })
                            .then(() => {
                                $("#reprise-wapp-progress-photoAvant .progress > .progress-bar").removeClass("w-0").addClass("w-100");
                                wappPost("messages/image", wapp.to_reprise, { image: repriseWappData.photoArriere, caption: "Photo 3/4 arrière" })
                                    .then(() => {
                                        $("#reprise-wapp-progress-photoArriere .progress > .progress-bar").removeClass("w-0").addClass("w-100");
                                        wappPost("messages/image", wapp.to_reprise, { image: repriseWappData.photoInterieur, caption: "Photo interieur" })
                                            .then(() => {
                                                $("#reprise-wapp-progress-photoInterieur .progress > .progress-bar").removeClass("w-0").addClass("w-100");
                                                wappPost("messages/image", wapp.to_reprise, { image: repriseWappData.coteArgus, caption: "Cote Argus" })
                                                    .then(() => {
                                                        $("#reprise-wapp-progress-coteArgus .progress > .progress-bar").removeClass("w-0").addClass("w-100");
                                                        resolve("La reprise a été envoyé avec succès");
                                                    }).catch(() => reject("Une erreur est survenue lors de l'envoi de la photo de la cote Argus"));
                                            }).catch(() => reject("Une erreur est survenue lors de l'envoi de la photo interieur"));
                                    }).catch(() => reject("Une erreur est survenue lors de l'envoi de la photo 3/4 arrière"));
                            }).catch(() => reject("Une erreur est survenue lors de l'envoi de la photo 3/4 avant"));
                    }).catch(() => reject("Une erreur est survenue lors de l'envoi du message"));
            });
        }
    }
});
