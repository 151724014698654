$(document).ready(function() { 
 
    $("input[name='chkEntiteFin']").on('click', function(e) { 
        let chk = $(this).prop('checked');
        let elts = $(this).closest('.form-group').find('.form-group-sub').find('.form-check-input');
        let check = false;
        if ( chk ) check = true;
        $.each(elts, function(){
            $(this).prop("checked", check); 
        }); 
    });

    $('#savePersFinConf').on('click', function(e) {
        e.preventDefault(); 
         let entities = [];
         let values = [];  
        $.each($('input[name="chkEntiteFin"]:checked'), function(){
            entities.push($(this).val());
            values.push($("input[name='chkUserFin"+$(this).val()+"']:checked").map(function() {
                return $(this).val();
            }).get().join(',')); 
        });  
        setConfigPersonnelFinancement('personnel-fin', entities, values);  
        setTimeout(() => {
            location.reload(true);
        }, 1000 ); 
    }); 
     
    $('#saveModeleConfig').on('click', function(e) {
        e.preventDefault();
        var cli = [];
        $.each($("input[name='chkEntitesClient']:checked"), function(){
            cli.push($(this).val());
        }); 
        setConfigModele('entite-cli', cli);
        var pro = [];
        $.each($("input[name='chkEntitesPro']:checked"), function(){
            pro.push($(this).val());
        }); 
        setConfigModele('entite-pro', pro); 
        setTimeout(() => {
            location.reload(true);
        }, 1000 ); 
    }); 
 
    $('.update_ent_com').on('click', function() {
        let orig = $(this).closest('.row').find('.update_ent_com_orig').val();
        let dest = $(this).closest('.row').find('.update_ent_com_dest').val(); 
        let parcours = $(this).closest('.row').find('.file').val();
        if (!dest || dest == 0) {
            toastr.error('Merci de selectionner une entité correspondante', "Erreur");
            return false;
        }
        setConfigCorrespondance(orig, dest, $(this), 'entite-com', parcours);
        setTimeout(() => {
            location.reload(true);
        }, 1000 );
    });
 
    $('.update_empl_orig').on('click', function() {
        let orig = $(this).closest('.row').find('.update_empl_orig_orig').val();
        let dest = $(this).closest('.row').find('.update_empl_orig_dest').val();
        let parcours = $(this).closest('.row').find('.file').val();
        if (!dest || dest == 0) {
            toastr.error('Merci de selectionner un emplacement origine correspondant', "Erreur");
            return false;
        }
        setConfigCorrespondance(orig, dest, $(this), 'emplacement-ori', parcours);
        setTimeout(() => {
            location.reload(true);
        }, 1000 );
    });

    $('.delModal').on('click', function() {
        let name = $(this).attr('data-name');
        let value = $(this).attr('data-key');
        let action = $(this).attr('data-action');
        let target = $(this).attr('data-target');
        if (!name || !value || !action) {
            toastr.error('Donnée incorrecte', "Erreur");
            return false;
        }
        removeConfigValue(name, value, target);
    });

    if( window.location.hash ){
        let hash = $(location).attr('hash'); 
        if (hash != '') {
            let id = $(hash).closest('.tab-pane').attr('id');
            $('.tab-content.config .tab-pane').removeClass('active');
            $('.tab-content.config .tab-pane').removeClass('show');
            $('#'+id).addClass('active');
            $('#'+id).addClass('show');
            $('.nav-tabs.config .nav-link').removeClass('active');
            $('#'+id+'-tab').addClass('active');
        }
    }

    $('.nav-tabs.config .nav-link').on('click', function() {
        // get hash value
        let hash = $(this).attr('data-hash');
        // set new hash value
        window.location.hash = hash; 
    });
       
    setTimeout(() => {
        $('.recapconfig_page .toggle-on').html('Oui'); 
        $('.recapconfig_page .toggle-off').html('Non');
    }, 1000 );

});

function removeConfigValue(name, value, target) {
    if (!name || !value || !target) {
        toastr.error('Donnée incorrecte 2', "Erreur");
        return false;
    } 
    let form = new FormData();
    form.append('name', name);
    form.append('value', value); 
    $.ajax({ 
        type:'POST',
        url: siteURL + 'admin/recap/factures/config-removevalue-ajax',
        contentType: false,
        processData: false,
        data: form,
        success: function(data){
            if (data=='OK') {  
                $('#' + target + value).modal('hide'); 
                setTimeout(() => {
                    location.reload(true);
                }, 1000 );
            }
        }
    });
}

function showChkState(obj) { 
    let id = $(obj).attr("id");  
    let status = document.getElementById(id).checked;
    let arr = id.split('_'); 
    let type = arr[1];
    let ent = arr[2];
    let form = new FormData();
    form.append('type', type);
    form.append('entite', ent);
    form.append('status',  status); 
    $.ajax({ 
        type:'POST',
        url: siteURL + 'admin/recap/factures/config-noconfig-ajax',
        contentType: false,
        processData: false,
        data: form,
        success: function(data){
            setTimeout(() => {
                location.reload(true);
            }, 1000 );
        }
    });
}

function setConfigModele(name, value) {
    if (!name || !value)
        return false;
    let form = new FormData();
    form.append('name', name);
    form.append('value',  value);
    $.ajax({ 
        type:'POST',
        url: siteURL + 'admin/recap/factures/config-modele-ajax',
        contentType: false,
        processData: false,
        data: form,
        success: function(data){
            // OK
        }
    });
}

function setConfigCorrespondance(orig, dest, obj, name, parcours) {
    if (!orig || !dest || !name)
        return false;
    var form = new FormData(); 
    form.append('ora_origine', orig);
    form.append('ora_destination', dest);
    form.append('ora_name', name);
    form.append('ora_parcours', parcours);
    $.ajax({
        type: 'POST',
        url: siteURL + 'admin/recap/factures/config-correspondance-ajax',
        data: form,
        contentType: false,
        processData: false,
        success: function(data) {
            if (data == "OK") {
                toastr.success('Donnée modifiée avec succès !', "Done");
                obj.find('span').addClass('bg-success').html('<i class="fas fa-check"></i>');
            }
            else {
                toastr.error('Une erreur est survenue', "Erreur");
            }
        }
    });
}

function setConfigPersonnelFinancement(name, entities, values) {
    if (!name || !entities || !values)
        return false;
    let form = new FormData();
    form.append('name', name);
    entities.forEach(element => form.append('entities[]', element) );
    values.forEach(value => form.append('values[]', value) );
    $.ajax({ 
        type:'POST',
        url: siteURL + 'admin/recap/factures/config-personnelfinancement-ajax',
        contentType: false,
        processData: false,
        data: form,
        success: function(data){
            // OK
        }
    });
}