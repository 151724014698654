$(document).ready(function () {
    if ($("#annuaire_results").length) {
        function loadAjax() {
            let idAnnuaire = this.id.replace("annuaire-sub-tab-activite_web-", "");
            let form = new FormData();
            form.append('idAnnuaire', idAnnuaire);
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/annuaire/annuaires/activites_web-vehicules_consultes',
                contentType: false,
                processData: false,
                data: form,
                success: function (data) {
                    if (data == null) {
                        toastr.error('Erreur lors du chargement', 'Erreur');
                    } else {
                        $('#annuaire-tab-activite_web-' + idAnnuaire + '-vehiculesConsultes').html(data.page);
                        initDatatable(idAnnuaire);
                        let vehiculesConsultes = data.vehiculesConsultes;

                        // Liste les prefix d'id pour les construire dynamiquement
                        let allIdDoughnut = ["marque", "modele", "energie", "carrosserie"];
                        let allIdBar = ["kilometrage", "prix"];
                        // Initialise les Canvas
                        let DonutChartCanvas = [];
                        let BarChartCanvas = [];
                        // Options générique
                        let donutOptions = {
                            maintainAspectRatio: false,
                            responsive: true,
                            tooltips: {
                                callbacks: {
                                    label: function (tooltipItem, data) {
                                        var dataset = data.datasets[tooltipItem.datasetIndex];
                                        var currentValue = dataset.data[tooltipItem.index];
                                        return data.labels[tooltipItem.index].replace(/\(\d+\)/g, '') + ": " + currentValue + "%";
                                    }
                                }
                            }
                        }
                        let barOptions = {
                            maintainAspectRatio: false,
                            responsive: true,
                            scales: {
                                yAxes: [{
                                    ticks: {
                                        beginAtZero: true
                                    }
                                }]
                            },
                            legend: {
                                display: false
                            },
                            tooltips: {
                                callbacks: {
                                    label: function (tooltipItem, data) {
                                        var dataset = data.datasets[tooltipItem.datasetIndex];
                                        var currentValue = dataset.data[tooltipItem.index];
                                        return data.labels[tooltipItem.index] + ": " + currentValue;
                                    }
                                }
                            }
                        }
                        // Créer les donuts
                        let colorSet = ['#f56954', '#00a65a', '#f39c12', '#00c0ef', '#3c8dbc', '#d2d6de', '#dd8888', '#88dddd', '#88dd88', '#dd88dd', '#dddd88', '#8888dd', '#b3ff66'];

                        // On transforme nos données des véhicules pour que la fonction générique des bar et donut puissent être correct
                        function transformerDonnees(vehicules, champs) {
                            let resultat = {};
                            champs.forEach(champ => {
                                resultat[champ] = {};
                                vehicules.forEach(vehicule => {
                                    let valeur = vehicule[champ];
                                    if (valeur !== undefined) {
                                        resultat[champ][valeur] = (resultat[champ][valeur] || 0) + 1;
                                    }
                                });
                            });
                            return resultat;
                        }
                        let donneeTransformees = transformerDonnees(vehiculesConsultes, [...allIdDoughnut, ...allIdBar]);

                        function processTop5(data, keys) {
                            const result = {};
                            keys.forEach(key => {
                                const entries = Object.entries(data[key]);
                                // Trier par valeurs décroissantes
                                entries.sort(([, valueA], [, valueB]) => valueB - valueA);
                                // Garder les 5 premiers et regrouper les autres
                                const top5 = entries.slice(0, 5);
                                // Construire un nouvel objet
                                result[key] = Object.fromEntries(top5);
                            });
                            return result;
                        }
                        let transformedDataDoughnut = processTop5(donneeTransformees, allIdDoughnut);

                        let dataValue = null;
                        let dataValueTotal = null;
                        for (let index = 0; index < allIdDoughnut.length; index++) {
                            DonutChartCanvas[allIdDoughnut[index]] = document.getElementById('vehiculesConsultes-graphiques-' + allIdDoughnut[index]).getContext('2d');
                            dataValue = Object.keys(transformedDataDoughnut[allIdDoughnut[index]]).map(function ($k) { return transformedDataDoughnut[allIdDoughnut[index]][$k]; });
                            dataValueTotal = dataValue.reduce((a, c) => a + c, 0);
                            new Chart(DonutChartCanvas[allIdDoughnut[index]], {
                                type: 'doughnut',
                                data: { labels: Object.keys(transformedDataDoughnut[allIdDoughnut[index]]).map((type, index) => {return `${type} (${dataValue[index]})`;}), datasets: [{ data: dataValue.map(el => Math.round((el / dataValueTotal) * 100)), backgroundColor: colorSet }] },
                                options: donutOptions
                            });
                        }

                        let label = [];
                        let sortedData = [];
                        label['kilometrage'] = ["- de 5k km", "5k km à 14999 km", "15k km à 24999 km", "25k km à 49999 km", "50k km à 74999 km", "75k km à 99999 km", "+ de 100k km"];
                        sortedData['kilometrage'] = new Array(label['kilometrage'].length);
                        sortedData['kilometrage'].fill(0)
                        Object.keys(donneeTransformees["kilometrage"]).forEach(element => {
                            if (element < 5000) {
                                sortedData['kilometrage'][0]++;
                            } else if (element < 15000) {
                                sortedData['kilometrage'][1]++;
                            } else if (element < 25000) {
                                sortedData['kilometrage'][2]++;
                            } else if (element < 50000) {
                                sortedData['kilometrage'][3]++;
                            } else if (element < 75000) {
                                sortedData['kilometrage'][4]++;
                            } else if (element < 100000) {
                                sortedData['kilometrage'][5]++;
                            } else {
                                sortedData['kilometrage'][6]++;
                            }
                        });

                        label['prix'] = ["- 15k €", "15k € à 24999 €", "25k € à 49999 €", "50k € à 74999 €", "75k € à 100k €", "+ de 100k €"];
                        sortedData['prix'] = new Array(label['prix'].length);
                        sortedData['prix'].fill(0)
                        Object.keys(donneeTransformees["prix"]).forEach(element => {
                            if (element <= 0) {
                                // On l'enlève des stats
                            } else if (element < 15000) {
                                sortedData['prix'][0]++;
                            } else if (element < 25000) {
                                sortedData['prix'][1]++;
                            } else if (element < 50000) {
                                sortedData['prix'][2]++;
                            } else if (element < 75000) {
                                sortedData['prix'][3]++;
                            } else if (element < 100000) {
                                sortedData['prix'][4]++;
                            } else {
                                sortedData['prix'][6]++;
                            }
                        });

                        for (let index = 0; index < allIdBar.length; index++) {
                            BarChartCanvas[allIdBar[index]] = document.getElementById('vehiculesConsultes-graphiques-' + allIdBar[index]).getContext('2d');
                            dataValue = Object.keys(donneeTransformees[allIdBar[index]]).map(function ($k) { return donneeTransformees[allIdBar[index]][$k]; });
                            new Chart(BarChartCanvas[allIdBar[index]], {
                                type: 'bar',
                                data: { labels: label[allIdBar[index]], datasets: [{ data: sortedData[allIdBar[index]], borderColor: 'rgb(54, 162, 235)', backgroundColor: 'rgba(54, 162, 235, 0.2)' }] },
                                options: barOptions
                            });
                        }

                        $("#annuaire-tab-activite_web-" + idAnnuaire + "-vehiculesConsultes-graphique-loader").addClass('d-none');
                        $("#annuaire-tab-activite_web-" + idAnnuaire + "-vehiculesConsultes-graphiques").removeClass('d-none');
                    }
                }
            });
        }

        function initDatatable(idAnnuaire) {
            $("#annuaire-activite_web-table-vehiculesConsultes-" + idAnnuaire).DataTable({
                "autoWidth": false,
                "language": { "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/fr-FR.json" },
                "paging": true,
                "responsive": true,
                "order": [[8, "desc"], [9, "desc"]],
                "conditionalPaging": true,
                "columnDefs": [
                    { type: 'num', targets: [0, 4, 8] },
                    { "className": "text-center align-middle py-2", "targets": '_all' },
                ],
            });
        }

        $('body').on('click', "[id^='annuaire-sub-tab-activite_web-']", loadAjax);
    }
});
