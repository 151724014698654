/*
  /admin/vehicules/listing
  /admin/vehicules/marchand
  /admin/vehicules/particulier
 */

// /admin/vehicules/listing
// selectpicker()
$('select[id^="vehicules_listingtable"]').selectpicker();
// dataTable()
vehicules_particulier_table = $('#vehicules_particulier_table').DataTable({
    "autoWidth": false,
    "language": {
        "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/fr-FR.json"
    },
    "responsive": true,
    "order": [
        [10, "desc"], [1, "desc"]
    ],
    "columnDefs": [
        // TEXT CENTER
        {
            "className": "text-center align-middle",
            "targets": [0,1,5,6,7,8,9,10],
        },
        // ALIGN MIDDLE
        {
            "className": "align-middle",
            "targets": '_all',
        },
        // CLICK AND LEASE
        {
            "width": "25px",
            "targets": 0,
            "orderable": false,
        },

    ],
    "pageLength": 50,
    "conditionalPaging": true,
    "initComplete": function() {
        $(this).show();
        $('#vehicules_particulier_table_loading').remove();
        $('#vehicules_particulier_collapse').click();
    }
});
// Champs de recherche
$('[id^="vehicules_listingtable-"').on( 'change keyup', function () {
    column = this.id.replace('vehicules_listingtable-', '');

    if (column == 2) {
        var marque = this.value;
        if (marque != "") {
            var form = new FormData();
            form.append('marque', marque);

            $.ajax({
                type: 'POST',
                url: siteURL + "json/modeles_marque",
                data: form,
                processData: false,
                contentType: false,
                success: function(data, status) {
                    $('#vehicules_listingtable-3').empty();
                    $('#vehicules_listingtable-3').append('<option value="">Tous les modèles</option>');
                    for (id in data) {
                        if (data[id]['nb'] > 0) {
                            $('#vehicules_listingtable-3').append("<option value='" + id + "'>" + id + "</option>");
                        }
                    };
                    $('#vehicules_listingtable-3').val('default').selectpicker("refresh");
                }
            });
            vehicules_listing_table.column(3).search( '' ).draw();
            vehicules_listing_table.column( column ).search(
                '^'+$(this).val()+'$',
                true,
                false,
            ).draw();
        } else {
            vehicules_listing_table.column(3).search( '' ).draw();
            $('#vehicules_listingtable-3').empty();
            $('#vehicules_listingtable-3').append('<option value="">Tous les modèles</option>');
            $('#vehicules_listingtable-3').val('default').selectpicker("refresh");
            vehicules_listing_table.column(2).search( '' ).draw();
        }
    }
    if (column == 9 && $(this).val() == "AUTRES") {
        terms = [
            $.fn.dataTable.util.escapeRegex( "ORA 1" ),
            $.fn.dataTable.util.escapeRegex( "DEPOTSEREZIN" ),
            $.fn.dataTable.util.escapeRegex( "TAMPON SEREZIN" ),
            $.fn.dataTable.util.escapeRegex( "MANDAT SEREZIN" ),
            $.fn.dataTable.util.escapeRegex( "ORA 2" ),
            $.fn.dataTable.util.escapeRegex( "DEPOTNEUVILLE" ),
            $.fn.dataTable.util.escapeRegex( "TAMPON NEUVILLE" ),
            $.fn.dataTable.util.escapeRegex( "MANDAT NEUVILLE" ),
            $.fn.dataTable.util.escapeRegex( "ORA 6" ),
            $.fn.dataTable.util.escapeRegex( "DEPOTMONTELIMAR" ),
            $.fn.dataTable.util.escapeRegex( "TAMPON MONTELIMAR" ),
            $.fn.dataTable.util.escapeRegex( "MANDAT MONTELIMAR" ),
        ]

        vehicules_listing_table.column( column ).search("");

        $.fn.dataTable.ext.search.push(
            function(settings, data, dataIndex) {
            for (term in terms) {
                if (data[column] == terms[term]) {
                    return false;
                }
            }
            return true;
        }
      );
      vehicules_listing_table.draw();
      $.fn.dataTable.ext.search.pop();
    }
    else {
        vehicules_listing_table.column( column ).search(
            $(this).val(), true, false
        ).draw();
    }
} );
// Bouton reset
$('body').on('click', '#vehicules_listing-reset', function() {
    $("#vehicules_listingtable-1").val('').selectpicker("refresh");
    $("#vehicules_listingtable-2").val('default').selectpicker("refresh");
    $("#vehicules_listingtable-3").val('default').selectpicker("refresh");
    $("#vehicules_listingtable-5").val('default').selectpicker("refresh");
    $("#vehicules_listingtable-9").val('default').selectpicker("refresh");
    vehicules_listing_table.columns().search( '' ).draw();
    return false;
});

// /admin/vehicules/marchand
// selectpicker()
$('select[id^="vehicules_marchandtable"]').selectpicker();
// dataTable()
vehicules_marchand_table = $('#vehicules_marchand_table').DataTable({
    "autoWidth": false,
    "language": {
        "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/fr-FR.json"
    },
    "responsive": true,
    "order": [
        [10, "desc"], [1, "desc"]
    ],
    "columnDefs": [
        // TEXT CENTER
        {
            "className": "text-center align-middle",
            "targets": [0,1,5,6,7,8,9,10],
        },
        // ALIGN MIDDLE
        {
            "className": "align-middle",
            "targets": '_all',
        },
        // CLICK AND LEASE
        {
            "width": "25px",
            "targets": 0,
            "orderable": false,
        },

    ],
    "pageLength": 50,
    "conditionalPaging": true,
    "initComplete": function() {
        $(this).show();
        $('#vehicules_marchand_table_loading').remove();
        $('#vehicules_marchand_collapse').click();
    }
});
// Champs de recherche
$('[id^="vehicules_marchandtable-"').on( 'change keyup', function () {
    column = this.id.replace('vehicules_marchandtable-', '');
    if (column == 2) {
        var marque = this.value;
        if (marque != "") {
            var form = new FormData();
            form.append('marque', marque);

            $.ajax({
                type: 'POST',
                url: siteURL + "json/modeles_marque",
                data: form,
                processData: false,
                contentType: false,
                success: function(data, status) {
                    $('#vehicules_marchandtable-3').empty();
                    $('#vehicules_marchandtable-3').append('<option value="">Tous les modèles</option>');
                    for (id in data) {
                        if (data[id]['nb'] > 0) {
                            $('#vehicules_marchandtable-3').append("<option value='" + id + "'>" + id + "</option>");
                        }
                    };
                    $('#vehicules_marchandtable-3').val('default').selectpicker("refresh");
                }
            });
            vehicules_marchand_table.column(3).search( '' ).draw();
            vehicules_marchand_table.column( column ).search(
                '^'+$(this).val()+'$',
                true,
                false,
            ).draw();
        } else {
            vehicules_marchand_table.column(3).search( '' ).draw();
            $('#vehicules_marchandtable-3').empty();
            $('#vehicules_marchandtable-3').append('<option value="">Tous les modèles</option>');
            $('#vehicules_marchandtable-3').val('default').selectpicker("refresh");
            vehicules_marchand_table.column(2).search( '' ).draw();
        }
    }
    if (column == 9 && $(this).val() == "AUTRES") {
        terms = [
            $.fn.dataTable.util.escapeRegex( "ORA 1" ),
            $.fn.dataTable.util.escapeRegex( "DEPOTSEREZIN" ),
            $.fn.dataTable.util.escapeRegex( "TAMPON SEREZIN" ),
            $.fn.dataTable.util.escapeRegex( "MANDAT SEREZIN" ),
            $.fn.dataTable.util.escapeRegex( "ORA 2" ),
            $.fn.dataTable.util.escapeRegex( "DEPOTNEUVILLE" ),
            $.fn.dataTable.util.escapeRegex( "TAMPON NEUVILLE" ),
            $.fn.dataTable.util.escapeRegex( "MANDAT NEUVILLE" ),
            $.fn.dataTable.util.escapeRegex( "ORA 6" ),
            $.fn.dataTable.util.escapeRegex( "DEPOTMONTELIMAR" ),
            $.fn.dataTable.util.escapeRegex( "TAMPON MONTELIMAR" ),
            $.fn.dataTable.util.escapeRegex( "MANDAT MONTELIMAR" ),
        ]

        vehicules_marchand_table.column( column ).search("");

        $.fn.dataTable.ext.search.push(
            function(settings, data, dataIndex) {
            for (term in terms) {
                if (data[column] == terms[term]) {
                    return false;
                }
            }
            return true;
        }
      );
      vehicules_marchand_table.draw();
      $.fn.dataTable.ext.search.pop();
    }
    else {
        vehicules_marchand_table.column( column ).search(
            $(this).val(), true, false
        ).draw();
    }
} );
// Bouton reset
$('body').on('click', '#vehicules_marchand-reset', function() {
    $("#vehicules_marchandtable-1").val('').selectpicker("refresh");
    $("#vehicules_marchandtable-2").val('default').selectpicker("refresh");
    $("#vehicules_marchandtable-3").val('default').selectpicker("refresh");
    $("#vehicules_marchandtable-5").val('default').selectpicker("refresh");
    $("#vehicules_marchandtable-9").val('default').selectpicker("refresh");
    vehicules_marchand_table.columns().search( '' ).draw();
    return false;
});

// /admin/vehicules/particulier
// selectpicker()
$('select[id^="vehicules_particuliertable"]').selectpicker();
// dataTable()
vehicules_listing_table = $('#vehicules_listing_table').DataTable({
    "autoWidth": false,
    "language": {
        "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/fr-FR.json"
    },
    "responsive": true,
    "order": [
        [10, "desc"], [1, "desc"]
    ],
    "columnDefs": [
        // TEXT CENTER
        {
            "className": "text-center align-middle",
            "targets": [0,1,5,6,7,8,9,10],
        },
        // ALIGN MIDDLE
        {
            "className": "align-middle",
            "targets": '_all',
        },
        // CLICK AND LEASE
        {
            "width": "25px",
            "targets": 0,
            "orderable": false,
        },

    ],
    "pageLength": 50,
    "conditionalPaging": true,
    "initComplete": function() {
        $(this).show();
        $('#vehicules_listing_table_loading').remove();
        $('#vehicules_listing_collapse').click();
    }
});
// Champs de recherche
$('[id^="vehicules_particuliertable-"').on( 'change keyup', function () {
    column = this.id.replace('vehicules_particuliertable-', '');
    if (column == 2) {
        var marque = this.value;
        if (marque != "") {
            var form = new FormData();
            form.append('marque', marque);

            $.ajax({
                type: 'POST',
                url: siteURL + "json/modeles_marque",
                data: form,
                processData: false,
                contentType: false,
                success: function(data, status) {
                    $('#vehicules_particuliertable-3').empty();
                    $('#vehicules_particuliertable-3').append('<option value="">Tous les modèles</option>');
                    for (id in data) {
                        if (data[id]['nb'] > 0) {
                            $('#vehicules_particuliertable-3').append("<option value='" + id + "'>" + id + "</option>");
                        }
                    };
                    $('#vehicules_particuliertable-3').val('default').selectpicker("refresh");
                }
            });
            vehicules_particulier_table.column(3).search( '' ).draw();
            vehicules_particulier_table.column( column ).search(
                '^'+$(this).val()+'$',
                true,
                false,
            ).draw();
        } else {
            vehicules_particulier_table.column(3).search( '' ).draw();
            $('#vehicules_particuliertable-3').empty();
            $('#vehicules_particuliertable-3').append('<option value="">Tous les modèles</option>');
            $('#vehicules_particuliertable-3').val('default').selectpicker("refresh");
            vehicules_particulier_table.column(2).search( '' ).draw();
        }
    }
    if (column == 9 && $(this).val() == "AUTRES") {
        terms = [
            $.fn.dataTable.util.escapeRegex( "ORA 1" ),
            $.fn.dataTable.util.escapeRegex( "DEPOTSEREZIN" ),
            $.fn.dataTable.util.escapeRegex( "TAMPON SEREZIN" ),
            $.fn.dataTable.util.escapeRegex( "MANDAT SEREZIN" ),
            $.fn.dataTable.util.escapeRegex( "ORA 2" ),
            $.fn.dataTable.util.escapeRegex( "DEPOTNEUVILLE" ),
            $.fn.dataTable.util.escapeRegex( "TAMPON NEUVILLE" ),
            $.fn.dataTable.util.escapeRegex( "MANDAT NEUVILLE" ),
            $.fn.dataTable.util.escapeRegex( "ORA 6" ),
            $.fn.dataTable.util.escapeRegex( "DEPOTMONTELIMAR" ),
            $.fn.dataTable.util.escapeRegex( "TAMPON MONTELIMAR" ),
            $.fn.dataTable.util.escapeRegex( "MANDAT MONTELIMAR" ),
        ]

        vehicules_particulier_table.column( column ).search("");

        $.fn.dataTable.ext.search.push(
            function(settings, data, dataIndex) {
            for (term in terms) {
                if (data[column] == terms[term]) {
                    return false;
                }
            }
            return true;
        }
      );
      vehicules_particulier_table.draw();
      $.fn.dataTable.ext.search.pop();
    }
    else {
        vehicules_particulier_table.column( column ).search(
            $(this).val(), true, false
        ).draw();
    }
} );
// Bouton reset
$('body').on('click', '#vehicules_particulier-reset', function() {
    $("#vehicules_particuliertable-1").val('').selectpicker("refresh");
    $("#vehicules_particuliertable-2").val('default').selectpicker("refresh");
    $("#vehicules_particuliertable-3").val('default').selectpicker("refresh");
    $("#vehicules_particuliertable-5").val('default').selectpicker("refresh");
    $("#vehicules_particuliertable-9").val('default').selectpicker("refresh");
    vehicules_particulier_table.columns().search( '' ).draw();
    return false;
});



// /admin/vehicules/vendu
// selectpicker()
$('select[id^="vehicules_vendutable"]').selectpicker();
// dataTable()
vehicules_vendu_table = $('#vehicules_vendu_table').DataTable({
    "ajax": siteURL+"json/vehicules_lastvendus",
    "autoWidth": false,
    "language": {
        "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/fr-FR.json"
    },
    "responsive": true,
    "order": [
        [9, "desc"], [0, "desc"]
    ],
    "columnDefs": [
        // TEXT CENTER
        {
            "className": "text-center align-middle",
            "targets": [0,4,5,6,7,8,9],
        },
        // ALIGN MIDDLE
        {
            "className": "align-middle",
            "targets": '_all',
        },
    ],
    "pageLength": 50,
    "conditionalPaging": true,
    "initComplete": function() {
        $(this).show();
        $('#vehicules_vendu_table_loading').remove();
        //$('#vehicules_vendu_collapse').click();
    }
});
// Champs de recherche
$('[id^="vehicules_vendutable-"').on( 'change keyup', function () {
    column = this.id.replace('vehicules_vendutable-', '');
    if (column == 2) {
        var marque = this.value;
        if (marque != "") {
            var form = new FormData();
            form.append('marque', marque);

            $.ajax({
                type: 'POST',
                url: siteURL + "json/modeles_marque",
                data: form,
                processData: false,
                contentType: false,
                success: function(data, status) {
                    $('#vehicules_vendutable-3').empty();
                    $('#vehicules_vendutable-3').append('<option value="">Tous les modèles</option>');
                    for (id in data) {
                        if (data[id]['nb'] > 0) {
                            $('#vehicules_vendutable-3').append("<option value='" + id + "'>" + id + "</option>");
                        }
                    };
                    $('#vehicules_vendutable-3').val('default').selectpicker("refresh");
                }
            });
            vehicules_vendu_table.column(3).search( '' ).draw();
            vehicules_vendu_table.column( column ).search(
                '^'+$(this).val()+'$',
                true,
                false,
            ).draw();
        } else {
            vehicules_vendu_table.column(3).search( '' ).draw();
            $('#vehicules_vendutable-3').empty();
            $('#vehicules_vendutable-3').append('<option value="">Tous les modèles</option>');
            $('#vehicules_vendutable-3').val('default').selectpicker("refresh");
            vehicules_vendu_table.column(2).search( '' ).draw();
        }
    }
    if (column == 9 && $(this).val() == "AUTRES") {
        terms = [
            $.fn.dataTable.util.escapeRegex( "ORA 1" ),
            $.fn.dataTable.util.escapeRegex( "DEPOTSEREZIN" ),
            $.fn.dataTable.util.escapeRegex( "TAMPON SEREZIN" ),
            $.fn.dataTable.util.escapeRegex( "MANDAT SEREZIN" ),
            $.fn.dataTable.util.escapeRegex( "ORA 2" ),
            $.fn.dataTable.util.escapeRegex( "DEPOTNEUVILLE" ),
            $.fn.dataTable.util.escapeRegex( "TAMPON NEUVILLE" ),
            $.fn.dataTable.util.escapeRegex( "MANDAT NEUVILLE" ),
            $.fn.dataTable.util.escapeRegex( "ORA 6" ),
            $.fn.dataTable.util.escapeRegex( "DEPOTMONTELIMAR" ),
            $.fn.dataTable.util.escapeRegex( "TAMPON MONTELIMAR" ),
            $.fn.dataTable.util.escapeRegex( "MANDAT MONTELIMAR" ),
        ]

        vehicules_vendu_table.column( column ).search("");

        $.fn.dataTable.ext.search.push(
            function(settings, data, dataIndex) {
            for (term in terms) {
                if (data[column] == terms[term]) {
                    return false;
                }
            }
            return true;
        }
      );
      vehicules_vendu_table.draw();
      $.fn.dataTable.ext.search.pop();
    }
    else {
        vehicules_vendu_table.column( column ).search(
            $(this).val(), true, false
        ).draw();
    }
} );
// Bouton reset
$('body').on('click', '#vehicules_vendu-reset', function() {
    $("#vehicules_vendutable-1").val('').selectpicker("refresh");
    $("#vehicules_vendutable-2").val('default').selectpicker("refresh");
    $("#vehicules_vendutable-3").val('default').selectpicker("refresh");
    $("#vehicules_vendutable-5").val('default').selectpicker("refresh");
    $("#vehicules_vendutable-9").val('default').selectpicker("refresh");
    vehicules_vendu_table.columns().search( '' ).draw();
    return false;
});


vehicules_vendustats_table = $('#vehicules_vendustats_table').DataTable({
    "ajax": siteURL+"json/vehicules_stats",
    "autoWidth": false,
    "language": {
        "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/fr-FR.json"
    },
    "order": [
        [2, "desc"], [0, "desc"]
    ],
    "responsive": true,
    "pageLength": 50,
    "conditionalPaging": true,
    "initComplete": function() {
        $(this).show();
        $('#vehicules_vendustats_table_loading').remove();
        $('#vehicules_vendustats_collapse').click();
    }
});



// Champs de recherche
$('#vehicules_vendustatstableyear').on( 'change keyup', function () {
    var year = this.value;
    var month = $('#vehicules_vendustatstablemonth').val();
    if (year != "") {
        if (month == "") {
            vehicules_vendustats_table.ajax.url( siteURL+"json/vehicules_stats/"+year ).load();
        }
        else {
            vehicules_vendustats_table.ajax.url( siteURL+"json/vehicules_stats/"+year+'/'+ month).load();
        }
    }
    else {
        if (month == "") {
            vehicules_vendustats_table.ajax.url( siteURL+"json/vehicules_stats/" ).load();
        }
        else {
            vehicules_vendustats_table.ajax.url( siteURL+"json/vehicules_stats/0000/"+ month).load();
        }
    }
});

$('#vehicules_vendustatstablemonth').on( 'change keyup', function () {
    var month = this.value;
    var year = $('#vehicules_vendustatstableyear').val();
    if (month != "") {
        if (year == "") {
            vehicules_vendustats_table.ajax.url( siteURL+"json/vehicules_stats/0000/"+month ).load();
        }
        else {
            vehicules_vendustats_table.ajax.url( siteURL+"json/vehicules_stats/"+year+'/'+ month).load();
        }
    }
    else {
        if (year == "") {
            vehicules_vendustats_table.ajax.url( siteURL+"json/vehicules_stats/" ).load();
        }
        else {
            vehicules_vendustats_table.ajax.url( siteURL+"json/vehicules_stats/"+ year).load();
        }
    }
});


$('#vehicules_vendustatstablemarque').on( 'change keyup', function () {
    vehicules_vendustats_table.column( 0 ).search(
        '^'+$(this).val()+'$',
        true,
        false,
    ).draw();
});

$('body').on('click', '#vehicules_vendustats-reset', function() {
    $("#vehicules_vendustatstablemonth").val('default').selectpicker("refresh");
    $("#vehicules_vendustatstableyear").val('default').selectpicker("refresh");
    $("#vehicules_vendustatstablemarque").val('default').selectpicker("refresh");
    vehicules_vendustats_table.ajax.url( siteURL+"json/vehicules_stats/").load();
    vehicules_vendustats_table.column( 0 ).search('').draw();
    return false;
});

// /admin/vehicules/toutvendu
// selectpicker()
$('select[id^="vehicules_toutvendutable"]').selectpicker();
// dataTable()
vehicules_toutvendu_table = $('#vehicules_toutvendu_table').DataTable({
    "ajax": siteURL+"json/vehicules_allvendus",
    "autoWidth": false,
    "language": {
        "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/fr-FR.json"
    },
    "responsive": true,
    "order": [
        [9, "desc"], [0, "desc"]
    ],
    "columnDefs": [
        // TEXT CENTER
        {
            "className": "text-center align-middle",
            "targets": [0,4,5,6,7,8,9],
        },
        // ALIGN MIDDLE
        {
            "className": "align-middle",
            "targets": '_all',
        },
    ],
    "pageLength": 50,
    "conditionalPaging": true,
    "initComplete": function() {
        $(this).show();
        $('#vehicules_toutvendu_table_loading').remove();
        //$('#vehicules_toutvendu_collapse').click();
    }
});
// Champs de recherche
$('[id^="vehicules_toutvendutable-"').on( 'change keyup', function () {
    column = this.id.replace('vehicules_toutvendutable-', '');
    if (column == 2) {
        var marque = this.value;
        if (marque != "") {
            var form = new FormData();
            form.append('marque', marque);

            $.ajax({
                type: 'POST',
                url: siteURL + "json/modeles_marque",
                data: form,
                processData: false,
                contentType: false,
                success: function(data, status) {
                    $('#vehicules_toutvendutable-3').empty();
                    $('#vehicules_toutvendutable-3').append('<option value="">Tous les modèles</option>');
                    for (id in data) {
                        if (data[id]['nb'] > 0) {
                            $('#vehicules_toutvendutable-3').append("<option value='" + id + "'>" + id + "</option>");
                        }
                    };
                    $('#vehicules_toutvendutable-3').val('default').selectpicker("refresh");
                }
            });
            vehicules_toutvendu_table.column(3).search( '' ).draw();
            vehicules_toutvendu_table.column( column ).search(
                '^'+$(this).val()+'$',
                true,
                false,
            ).draw();
        } else {
            vehicules_toutvendu_table.column(3).search( '' ).draw();
            $('#vehicules_toutvendutable-3').empty();
            $('#vehicules_toutvendutable-3').append('<option value="">Tous les modèles</option>');
            $('#vehicules_toutvendutable-3').val('default').selectpicker("refresh");
            vehicules_toutvendu_table.column(2).search( '' ).draw();
        }
    }
    if (column == 9 && $(this).val() == "AUTRES") {
        terms = [
            $.fn.dataTable.util.escapeRegex( "ORA 1" ),
            $.fn.dataTable.util.escapeRegex( "DEPOTSEREZIN" ),
            $.fn.dataTable.util.escapeRegex( "TAMPON SEREZIN" ),
            $.fn.dataTable.util.escapeRegex( "MANDAT SEREZIN" ),
            $.fn.dataTable.util.escapeRegex( "ORA 2" ),
            $.fn.dataTable.util.escapeRegex( "DEPOTNEUVILLE" ),
            $.fn.dataTable.util.escapeRegex( "TAMPON NEUVILLE" ),
            $.fn.dataTable.util.escapeRegex( "MANDAT NEUVILLE" ),
            $.fn.dataTable.util.escapeRegex( "ORA 6" ),
            $.fn.dataTable.util.escapeRegex( "DEPOTMONTELIMAR" ),
            $.fn.dataTable.util.escapeRegex( "TAMPON MONTELIMAR" ),
            $.fn.dataTable.util.escapeRegex( "MANDAT MONTELIMAR" ),
        ]

        vehicules_toutvendu_table.column( column ).search("");

        $.fn.dataTable.ext.search.push(
            function(settings, data, dataIndex) {
            for (term in terms) {
                if (data[column] == terms[term]) {
                    return false;
                }
            }
            return true;
        }
      );
      vehicules_toutvendu_table.draw();
      $.fn.dataTable.ext.search.pop();
    }
    if (column == 12 || column == 13 ) {

        vehicules_toutvendu_table.column(10).search(
            '^(?=.*'+$('#vehicules_toutvendutable-12').val()+')(?=.*'+$('#vehicules_toutvendutable-13').val()+').*$',
            true,
            false
        ).draw();

    }
    else {
        vehicules_toutvendu_table.column( column ).search(
            $(this).val(), true, false
        ).draw();
    }
} );
// Bouton reset
$('body').on('click', '#vehicules_toutvendu-reset', function() {
    $("#vehicules_toutvendutable-1").val('').selectpicker("refresh");
    $("#vehicules_toutvendutable-2").val('default').selectpicker("refresh");
    $("#vehicules_toutvendutable-3").val('default').selectpicker("refresh");
    $("#vehicules_toutvendutable-5").val('default').selectpicker("refresh");
    $("#vehicules_toutvendutable-9").val('default').selectpicker("refresh");
    vehicules_toutvendu_table.columns().search( '' ).draw();
    return false;
});
