let ordre_reparation_table = null;
let timerBeforeRefreshDataOrdreReparation = null;
let selectedOrdreReparationCache = [];

$(document).ready(function () {
    if ($("#ordre_reparation_results").length) {

        colvisFunOnEvent();

        function loadAjax() {
            let optionsRecherche = new FormData();
            optionsRecherche.append('marque', $("#filtersordre_reparation-marque").val());
            optionsRecherche.append('modele', $("#filtersordre_reparation-modele").val());
            optionsRecherche.append('prestataire', $("#filtersordre_reparation-prestataire").val());
            optionsRecherche.append('statutOR', $("#filtersordre_reparation-statutOR").val());
            optionsRecherche.append('typeOrdre', $("#filtersordre_reparation-typeOrdre").val());
            optionsRecherche.append('dateDebutCreation', $("#filtersordre_reparation-dateDebutCreation").val());
            optionsRecherche.append('dateFinCreation', $("#filtersordre_reparation-dateFinCreation").val());
            optionsRecherche.append('dateDebutEnvoi', $("#filtersordre_reparation-dateDebutEnvoi").val());
            optionsRecherche.append('dateFinEnvoi', $("#filtersordre_reparation-dateFinEnvoi").val());
            $("#ordre_reparation_results").addClass("d-none");
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/ordre_reparation/ordre_reparation_ajax',
                contentType: false,
                processData: false,
                data: optionsRecherche,
                success: function (data) {
                    $("#ordre_reparation_results").html(data);
                    for (let index = 0; index < selectedOrdreReparationCache.length; index++) {
                        $("#ordre_reparation-selection-" + selectedOrdreReparationCache[index]).prop("checked", true);
                    }
                    ordre_reparation_table = $('#ordre_reparation-table').DataTable({
                        "dom": dataTablesCustomDom(),
                        "buttons": [dataTablesColvisBtn('th:nth-child(n+3)')],
                        "autoWidth": false,
                        "language": { "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/fr-FR.json" },
                        "paging": true,
                        "pageLength": 25,
                        "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, "All"]],
                        "responsive": true,
                        "order": [[0, "desc"]],
                        "conditionalPaging": true,
                        "columnDefs": [
                            { type: 'num', targets: [0, 1] },
                            { "className": "text-center align-middle", "targets": '_all' }, // ALIGN MIDDLE
                        ],
                        "columns": getColvisColumn("ordre_reparation-table")
                    });
                    $('#ordre_reparation-table').on('init.dt', function () { initMirrorBtn(); setColvisTable(ordre_reparation_table); colvisFunSelectRefresh(); });
                    $('#ordre_reparation-table').on('draw.dt', function () { $("#ordre_reparation_results").removeClass("d-none"); });
                }
            })
        };

        setupLoadAjax(loadAjax);
        setupLoadModele(loadModele);
        setupResetFilter(resetFilter);

        $('body').on('change', "[id^='filtersordre_reparation-']", function () { clearTimeout(timerBeforeRefreshDataOrdreReparation); timerBeforeRefreshDataOrdreReparation = setTimeout(loadAjax, 500); });

        // On charge les modèles en fonction des marques
        $('body').on('change', "#filtersordre_reparation-marque", loadModele);

        // Bouton reset
        $('body').on('click', '#filtersordre_reparation-reset', function () {
            resetFilter();
            loadAjax();
        });

        function resetFilter() {
            $("#filtersordre_reparation-marque").val('default').selectpicker("refresh");
            $("#filtersordre_reparation-modele").find("optgroup").prop('disabled', true);
            $('#filtersordre_reparation-modele').prop("disabled", true);
            $("#filtersordre_reparation-modele").val('default').selectpicker("refresh");
            $("#filtersordre_reparation-statutOR").val('default').selectpicker("refresh");
            $("#filtersordre_reparation-prestataire").val('default').selectpicker("refresh");
            $("#filtersordre_reparation-typeOrdre").val('default').selectpicker("refresh");
            $("#filtersordre_reparation-dateDebutCreation").val('').selectpicker("refresh");
            $("#filtersordre_reparation-dateFinCreation").val('').selectpicker("refresh");
            $("#filtersordre_reparation-dateDebutEnvoi").val('').selectpicker("refresh");
            $("#filtersordre_reparation-dateFinEnvoi").val('').selectpicker("refresh");
            $("#filtreConfigSelect-filtreConfig-aucun").val('aucun').prop("selected", true);
        }

        function loadModele() {
            if ($('#filtersordre_reparation-marque').val().length != 0) {
                $("#filtersordre_reparation-modele").prop('disabled', false);
                $('#filtersordre_reparation-marque').val().forEach(element => { $("#opt_" + element).prop('disabled', false); });
                $("[id^='opt_']").each(function () {
                    let marqueEnCours = $(this).attr('id').replace('opt_', '');
                    if (!$('#filtersordre_reparation-marque').val().includes(marqueEnCours)) {
                        $(this).prop('disabled', true);
                    }
                })
                $("#filtersordre_reparation-modele").selectpicker('refresh');
            } else {
                $('#filtersordre_reparation-modele').prop("disabled", true);
                $("#filtersordre_reparation-modele").find("optgroup").prop('disabled', true);
                $("#filtersordre_reparation-modele").selectpicker('refresh');
            }
        }

        filtreConfigFunOnEvent();
    }
});