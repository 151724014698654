document.addEventListener("DOMContentLoaded", function() {
    if (document.getElementById("modal-suiviRelationBtoB")) {
        document.getElementById("modal-suiviRelationBtoB-button").click();
        $('#suiviRelationBtoB_table').DataTable({
            "autoWidth": false,
            "language": {
                "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/fr-FR.json"
            },
            "paging": false,
            "responsive": true,
            "order": [
                [2, "asc"]
            ],
            "columnDefs": [
                {
                    "className": "text-center align-middle",
                    "targets": [0, 1, 2, 4, 5],
                },
                {
                    "className": "align-middle",
                    "targets": '_all',
                }
            ],
            "conditionalPaging": true,
        });
        document.getElementById("modal-suiviRelationBtoB-check").addEventListener("click", function(e) {
            if ($("#modal-suiviRelationBtoB-check").is(':checked')) {
                $("#modal-suiviRelationBtoB-valid").removeClass("d-none");
            } else {
                $("#modal-suiviRelationBtoB-valid").addClass("d-none");
            }
        })
        document.getElementById("modal-suiviRelationBtoB-valid").addEventListener("click", function(e) {
            document.cookie = "suiviRelationBtoB_date="+$("#modal-suiviRelationBtoB").attr("data-date")+"; path=/";
        })
    }
});