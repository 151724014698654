$(document).ready(function() {
    makeSummerNote('article_texteIntro_0', 200, ['full']);
    makeSummerNote('article_contenu1_0', 200, ['full']);
    makeSummerNote('article_contenu2_0', 200, ['full']);
    makeSummerNote('article_contenu3_0', 200, ['full']);
    makeSummerNote('article_contenu4_0', 200, ['full']);

    // Génération de la datatable des article
    article_table = $('#article_table').DataTable( {
        "autoWidth": false,
        "language": {
            "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/fr-FR.json"
        },
        "responsive": true,
        "ajax": siteURL + 'json/article',
        "order": [[ 0, "desc" ]],
        "columnDefs": [
            // TEXT CENTER
            {
                "className": "text-center align-middle",
                "targets": [0,3,4],
            },
            //TEXT START
            {
                "className": "text-start",
                "targets": [1,2],
            },
            {
                "width": "40px",
                "targets": 0,
                "render": function ( data, type, row ) {
                    return row[0];
                },
                "orderable": true,
            },
            {
                "width": "150px",
                "targets": 1,
                "render": function ( data, type, row ) {
                    return row[1];
                },
            },
            {
                "targets": 2,
                "render": function ( data, type, row ) {
                    return "<span class='text-start'>"+row[2]+"</span>";
                },
                "orderable": true,
            },
            {
                "width": "100px",
                "targets": 3,
                "render": function ( data, type, row ) {
                    return row[3];
                },
                "orderable": true,
            },
            {
                "width": "100px",
                "targets": 4,
                "render": function ( data, type, row ) {
                    return (
                        row[4] == 1
                        ? '<i class="fas fa-check" style="color:green" title="Validé"></i><span class="d-inline d-lg-none pl-2">OUI</span>'
                        : '<i class="fas fa-times" style="color:red" title="Non Validé"></i><span class="d-inline d-lg-none pl-2">NON</span>'
                    );
                },
                "orderable": true,
            },
            {
                "width": "50px",
                "targets": 5,
                "render": function ( data, type, row ) {
                    return '<button class="btn w-100 p-0" id="article-open-modify-'+row[0]+'" data-category="web"><i class="fad fa-edit"></i>';
                },
                "orderable": false,
            },
        ],
        "conditionalPaging": true,
    });

    $('body').on('change', "select[id^='article_site_']", function(e) {
        var clickedId = this.id.replace("article_site_", "");
        $("#article_categorie_"+clickedId).val(null);
        $("#article_categorie_"+clickedId+" option").attr('disabled','disabled');
        $("#article_categorie_"+clickedId+" option[categorie-site='"+ $(this).val() +"']").removeAttr('disabled');
    });

    // Envoi de modification d'un article
    $('body').on('click', "button[id^='article-send-modify-']", function(e) {
        e.preventDefault();
        var clickedId = this.id.split(/[- ]+/).pop();

        let error = "";
        if ($('#article_title_'+clickedId).val() == "") {
            error += "Le \"title\" n'est pas renseigné<br/>";
        }
        if ($('#article_metaDescription_'+clickedId).val() == "") {
            error += "La \"meta description\" n'est pas renseignée<br/>";
        }
        if ($('#article_auteur_'+clickedId).val() == null) {
            error += "L'\"auteur\" n'est pas renseigné<br/>";
        }
        if ($('#article_site_'+clickedId).val() == null) {
            error += "Le \"site\" n'est pas renseigné<br/>";
        }
        if ($('#article_categorie_'+clickedId).val() == null) {
            error += "La \"catégorie\" n'est pas renseignée<br/>";
        }
        if ($('#article_baliseH1_'+clickedId).val() == "") {
            error += "La \"balise H1\" n'est pas renseignée<br/>";
        }

        if (error != "") {
            toastr.error(error, 'Erreur');
        } else {
            var form = new FormData();
            // On extrait le contenu du formulaire dans la variable 'form'
            form.append('id', clickedId);
            form.append('article_title', $('#article_title_'+clickedId).val());
            form.append('article_metaDescription', $('#article_metaDescription_'+clickedId).val());
            form.append('article_auteur', $('#article_auteur_'+clickedId).val());
            form.append('article_site', $('#article_site_'+clickedId).val());
            form.append('article_isVisible', ($('#article_isVisible_'+clickedId).val() == "on" ? 1 : 0));
            form.append('article_categorie', $('#article_categorie_'+clickedId).val());
            form.append('article_imagePrincipale', $('#file_result_imagePrincipale_'+clickedId).val());
            form.append('article_baliseH1', $('#article_baliseH1_'+clickedId).val());
            form.append('article_texteIntro', $('#article_texteIntro_'+clickedId).val());
            form.append('article_baliseH2N1', $('#article_baliseH2N1_'+clickedId).val());
            form.append('article_contenu1', $('#article_contenu1_'+clickedId).val());
            form.append('article_baliseH2N2', $('#article_baliseH2N2_'+clickedId).val());
            form.append('article_contenu2', $('#article_contenu2_'+clickedId).val());
            form.append('article_baliseH2N3', $('#article_baliseH2N3_'+clickedId).val());
            form.append('article_contenu3', $('#article_contenu3_'+clickedId).val());
            form.append('article_baliseH2N4', $('#article_baliseH2N4_'+clickedId).val());
            form.append('article_contenu4', $('#article_contenu4_'+clickedId).val());
            form.append('article_titreCard1', $('#article_titreCard1_'+clickedId).val());
            form.append('article_textBtnCard1', $('#article_textBtnCard1_'+clickedId).val());
            form.append('article_lienBtnCard1', $('#article_lienBtnCard1_'+clickedId).val());
            form.append('article_imageCard1', $('#file_result_imageCard1_'+clickedId).val());
            form.append('article_titreCard2', $('#article_titreCard2_'+clickedId).val());
            form.append('article_textBtnCard2', $('#article_textBtnCard2_'+clickedId).val());
            form.append('article_lienBtnCard2', $('#article_lienBtnCard2_'+clickedId).val());
            form.append('article_imageCard2', $('#file_result_imageCard2_'+clickedId).val());

            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/web/article/modify',
                cache: false,
                contentType: false,
                processData: false,
                data: form,
                success: function(data) {
                    // Si il s'agit de l'ajout d'un nouveau article
                    if (clickedId == 0) {
                        toastr.success('Le nouvel article a bien été enregistrée', 'Succès');
                        $('#formarticle_0')[0].reset();
                        $("#article_texteIntro_0").each(function(e) { $(this).summernote('reset'); });
                        $("#article_contenu1_0").each(function(e) { $(this).summernote('reset'); });
                        $("#article_contenu2_0").each(function(e) { $(this).summernote('reset'); });
                        $("#article_contenu3_0").each(function(e) { $(this).summernote('reset'); });
                        $("#article_contenu4_0").each(function(e) { $(this).summernote('reset'); });
                    }
                    // Si il s'agit d'une modification de article
                    else {
                        toastr.success('L\'article a bien été modifié', 'Succès');
                        // On nettoye le html en supprimant l'onglet 'Modifier/Nouveau'
                        $('#article-tabs-modify-'+clickedId).remove();
                        $('#article-close-modify-'+clickedId).parent().remove();
                    }
                    // Retour à la liste des article en rechargeant la datatable
                    article_table.ajax.reload();
                    $('#article-tabs-list-tab').trigger('click');
                }
            });
        }
        // Empèche le 'button' de fonctionner
        return false;
    });
});