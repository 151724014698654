let observerReceptionExpertise = null;
$(document).ready(function () {
    if ($("#stock_vehicule_page").length) {
        const vehiculeId = $("#stock_vehicule_page").data("id");
        let lobibox_display_pregeneration_ordre_reparation = null;

        // Initialisation des liens concurrents
        initLienConcurrent();

        // Initialisation des graphiques
        initDonutMarge('particulier');
        initDonutMarge('marchand');
        initAllBarEvolutionPrix();

        // Pour afficher les informations du véhicule à particulier ou marchand
        $('body').on('click', "[id^='stock_vehicule-general-button-info-']", function (e) {
            let infoClic = e.target.id.replace('stock_vehicule-general-button-info-', '');
            let infoNonClic = infoClic == "particulier" ? "marchand" : "particulier";

            $('#stock_vehicule-general-content-info-' + infoClic).removeClass('d-none');
            $('#stock_vehicule-general-content-info-' + infoNonClic).addClass('d-none');
            $('#stock_vehicule-general-button-info-' + infoClic).removeClass('btn-outline-primary').addClass('btn-primary');
            $('#stock_vehicule-general-button-info-' + infoNonClic).removeClass('btn-primary').addClass('btn-outline-primary');
        });

        // Pour afficher les informations de rentabilité du véhicule à particulier ou marchand sur la page Commerce
        $('body').on('click', "[id^='stock_vehicule-commerce-button-info-']", function (e) {
            let infoClic = e.target.id.replace('stock_vehicule-commerce-button-info-', '');
            let infoNonClic = infoClic == "particulier" ? "marchand" : "particulier";

            $('#stock_vehicule-commerce-content-info-' + infoClic).removeClass('d-none');
            $('#stock_vehicule-commerce-content-info-' + infoNonClic).addClass('d-none');
            $('#stock_vehicule-commerce-button-info-' + infoClic).removeClass('btn-outline-primary').addClass('btn-primary');
            $('#stock_vehicule-commerce-button-info-' + infoNonClic).removeClass('btn-primary').addClass('btn-outline-primary');
        });

        $("#stock_vehicule-input-millesime").datepicker({
            minViewMode: 2,
            format: 'yyyy',
        });

        $('body').on('click', "[id^='stock_vehicule-offrePrixPossible-']", function (e) {
            let form = new FormData();
            form.append('id', vehiculeId);
            form.append('value', this.value);
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/stock/update/offre-prix-possible',
                contentType: false,
                processData: false,
                data: form,
                success: function (data) { if (data.success) { toastr.success('La possibilité de faire des offres prix sur ce véhicule a bien été mis à jour', 'Succès'); } },
                error: function () { toastr.error('Une erreur s\'est produite', 'Erreur'); }
            });
        });

        $("#stock_vehicule-commerce-table-offre").DataTable({
            "dom": dataTablesCustomDom({ scrollable: true }),
            "autoWidth": false,
            "language": { "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/fr-FR.json" },
            "paging": true,
            "responsive": true,
            "order": [[0, "desc"]],
            "conditionalPaging": true,
            "columnDefs": [
                { type: 'num', targets: [0, 4, 5, 6] },
                { "className": "text-center align-middle", "targets": '_all' }
            ],
        });

        $("#stock_vehicule-commerce-table-propo").DataTable({
            "dom": dataTablesCustomDom({ scrollable: true }),
            "autoWidth": false,
            "language": { "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/fr-FR.json" },
            "paging": true,
            "responsive": true,
            "order": [[0, "desc"]],
            "conditionalPaging": true,
            "columnDefs": [
                { type: 'num', targets: [0, 5, 6, 7] },
                { "className": "text-center align-middle", "targets": '_all' }
            ],
        });

        $("#stock_vehicule-commerce-table-commande").DataTable({
            "dom": dataTablesCustomDom({ scrollable: true }),
            "autoWidth": false,
            "language": { "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/fr-FR.json" },
            "paging": true,
            "responsive": true,
            "order": [[1, "desc"]],
            "conditionalPaging": true,
            "columnDefs": [
                { type: 'num', targets: [0, 3] },
                { "className": "text-center align-middle", "targets": '_all' }
            ],
        });

        // Pour créer une nouvelle proposition
        $('body').on('click', "[id^='stock_vehicule-commerce-nouvelle-proposition-']", function (e) {
            let idVehicule = this.id.replace("stock_vehicule-commerce-nouvelle-proposition-", "");
            window.open($(this).data("src") + "?listId=" + idVehicule, "_blank");
        });

        $('body').on('click', "[id^=stock_vehicule-reset-cycle-co-]", function () {
            let idVehicule = this.id.replace("stock_vehicule-reset-cycle-co-", "");
            $("[data-target='#stock_vehicule-modal-cycle-co-" + idVehicule + "']").find("i").addClass("fa-spin");
            let form = new FormData();
            form.append('id', idVehicule);
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/stock/update/reset-cycle-co',
                contentType: false,
                processData: false,
                data: form,
                success: function (success) {
                    if (success == "ok") {
                        toastr.success("La nouvelle date a bien été enregistré", "Succès");
                        $("#stock_vehicule-cycle-co-days-" + idVehicule).html("0");
                        $("#stock_vehicule-cycle-co-date-" + idVehicule).html(moment().format('DD/MM/YYYY'));
                    } else { toastr.error("Une erreur s'est produite", "Erreur"); }
                },
                complete: function () { $("[data-target='#stock_vehicule-modal-cycle-co-" + idVehicule + "']").find("i").removeClass("fa-spin"); }
            });
        });

        $('body').on('click', "[data-target^='#stock_vehicule-commentaire-modal-']", function () {
            let idVehicule = $(this).data("target").replace("#stock_vehicule-commentaire-modal-", "");
            let commentaire = $("#stock_vehicule-commentaire-textarea-" + idVehicule).data("commentaire");
            let today = new Date();
            let dd = String(today.getDate()).padStart(2, '0');
            let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            let yyyy = today.getFullYear();
            let hh = String(today.getHours()).padStart(2, '0');
            let ii = String(today.getMinutes()).padStart(2, '0');
            let now = 'Le ' + mm + '/' + dd + '/' + yyyy + ' à ' + hh + ':' + ii;
            $("#stock_vehicule-commentaire-textarea-" + idVehicule).val(commentaire.length ? commentaire + "\n\n" + now + "\n\n" : commentaire + now + "\n\n");
        });

        $('body').on('click', "[id^=stock_vehicule-commentaire-save-]", function () {
            let idVehicule = this.id.replace("stock_vehicule-commentaire-save-", "");
            let form = new FormData();
            form.append('id', idVehicule);
            form.append('commentaire', $("#stock_vehicule-commentaire-textarea-" + idVehicule).val());
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/stock/update/commentaire',
                contentType: false,
                processData: false,
                data: form,
                success: function (commentaire) {
                    toastr.success("Le commentaire a bien été enregistré", "Succès");
                    $("#stock_vehicule-commentaire-textarea-" + idVehicule).val("");
                    $("#stock_vehicule-commentaire-textarea-" + idVehicule).data("commentaire", commentaire);
                    $("#stock_vehicule-commentaire-modal-" + idVehicule).modal("hide");
                    $("[data-target='#stock_vehicule-commentaire-modal-" + idVehicule + "']").html(commentaire);
                }
            });
        });

        // Pour copier le vin
        $('body').on('click', "#stock_vehicule-copy-vin", function () {
            let vin = $('#stock_vehicule-vin').html();
            navigator.clipboard.writeText(vin);
            toastr.success('VIN copié', 'Succès');
        });

        // Update sub tab
        $('body').on('click', "[id^='stock_vehicule-tab-']", function (e) {
            e.preventDefault();
            const idTab = this.id.replace("stock_vehicule-tab-", "");
            // Reset
            $("[id^='stock_vehicule-tab_pane-']").each((k, el) => { $(el).addClass('d-none'); });
            $("[id^='stock_vehicule-tab-']").each((k, el) => { $(el).removeClass('btn-outline-primary'); $(el).addClass('btn-primary'); });
            // Display
            $("[id='stock_vehicule-tab_pane-" + idTab + "']").each((k, el) => { $(el).removeClass('d-none'); });
            $("[id='stock_vehicule-tab-" + idTab + "']").each((k, el) => { $(el).removeClass('btn-primary'); $(el).addClass('btn-outline-primary'); if (idTab == "photo") { $(el).addClass('bg-white'); }; });
            // Ancre sur la div si sur téléphone
            if (window.innerWidth <= 750) {
                let targetId = "stock_vehicule-tab_pane-" + idTab;
                let targetElement = document.getElementById(targetId); // Trouve l'élément correspondant

                if (targetElement) {
                    targetElement.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start'
                    });
                }
            }
        });

        // Event de gestion des chargements en AJAX au click sur les onglets
        $("[stock-vehicule-tab-ajax]").each((i, e) => {
            const tab = e.id.replace("stock_vehicule-tab_pane-", "")
            const attr = e.getAttribute("stock-vehicule-tab-ajax")
            const hasLoader = tab == attr;
            $('body').on('click', "#stock_vehicule-tab-" + tab, function (e) {
                // Dans le cas de la vue client, pour gérer si on doit faire l'ajax ou non si on sort du la vue client
                if (attr == "client" && $('#stock_vehicule-tab-general').hasClass('d-none')) {
                    $('#stock_vehicule-tab-general').trigger('click');
                    $.each($('[id^="stock_vehicule-tab-"]'), function (item) {
                        if (this.id != 'stock_vehicule-tab-client') {
                            $(this).removeClass("d-none")
                        }
                    })
                } else {
                    if (hasLoader) {
                        $("#stock_vehicule-tab_pane-loader").removeClass("d-none");
                        $("#stock_vehicule-tab_pane-" + attr).html("");
                    }
                    let form = new FormData();
                    form.append('id', vehiculeId);
                    $.ajax({
                        type: 'POST',
                        url: siteURL + 'admin/stock/update/' + attr,
                        contentType: false,
                        processData: false,
                        data: form,
                        success: function (data) {
                            if (hasLoader) {
                                $("#stock_vehicule-tab_pane-loader").addClass("d-none");
                            }
                            $("#stock_vehicule-tab_pane-" + attr).html(data);
                            $("#stock_vehicule-tab_pane-" + attr + " .selectpicker").selectpicker();
                            if (attr == "reception") {
                                onOpenChange(document.querySelector("#reception-dialog-" + vehiculeId + "-dialog[data-step='6']"), (observer) => {
                                    let expertise = $("#reception-dialog-" + vehiculeId + "-dialog[data-step='6']").data("expertise");
                                    // Retire les points du schéma
                                    receptionExpertisePointRemove();
                                    // Ajoute les nouveaux points du schéma
                                    expertise.forEach(element => {
                                        if (element.point.x != null && element.point.y != null) {
                                            receptionExpertisePointAdd(element);
                                        }
                                    });
                                });
                            } else if (attr == "etudeVF") {
                                initLienConcurrent();
                                initDonutMarge('vf');
                            } else if (attr == "frais") {
                                expertiseDisplayUpdate($("#freFrais_modify"));
                                loadVehiculeFraisTotal();
                            } else if (attr == "livraison") {
                                const livraisonInitialModal = $("#livraison-initial-modal");
                                if (livraisonInitialModal.length) { livraisonInitialModal.modal('show'); }
                            } else if (attr == "commerce") {
                                // Pagination pour les offres et propositions mail
                                stock_vehicule_commerce_table_offre_table = $('#stock_vehicule-commerce-table-offre').DataTable({
                                    "dom": dataTablesCustomDom({ scrollable: true }),
                                    "buttons": [dataTablesColvisBtn('th:nth-child(n+4)')],
                                    "autoWidth": false,
                                    "language": { "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/fr-FR.json" },
                                    "paging": true,
                                    "pageLength": 10,
                                    "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, "All"]],
                                    "responsive": true,
                                    "order": [[4, "asc"], [5, "asc"]],
                                    "conditionalPaging": true,
                                    "columnDefs": [].push(...getColumnDefsType("stock_vehicule-commerce-table-offre")),
                                });
                                $('#stock_vehicule_commerce_table_offre_table').on('init.dt', function () { initMirrorBtn(); setColvisTable(stock_vehicule_commerce_table_offre_table); });

                                stock_vehicule_commerce_table_propo_table = $('#stock_vehicule-commerce-table-propo').DataTable({
                                    "dom": dataTablesCustomDom({ scrollable: true }),
                                    "buttons": [dataTablesColvisBtn('th:nth-child(n+4)')],
                                    "autoWidth": false,
                                    "language": { "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/fr-FR.json" },
                                    "paging": true,
                                    "pageLength": 10,
                                    "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, "All"]],
                                    "responsive": true,
                                    "order": [[4, "asc"], [5, "asc"]],
                                    "conditionalPaging": true,
                                    "columnDefs": [].push(...getColumnDefsType("stock_vehicule-commerce-table-propo")),
                                });
                                $('#stock_vehicule_commerce_table_propo_table').on('init.dt', function () { initMirrorBtn(); setColvisTable(stock_vehicule_commerce_table_propo_table); });

                                if ($('#stock_vehicule-rentabilite-marge-commerceParticulier-chartjs').length) { initDonutMarge('commerceParticulier'); }
                                if ($('#stock_vehicule-rentabilite-marge-commerceMarchand-chartjs').length) { initDonutMarge('commerceMarchand'); }
                                if ($('#stock_vehicule-rentabilite-marge-commerce-chartjs').length) { initDonutMarge('commerce'); }
                                initAllLineEvolutionCommerce();
                                getStatusMailAsynchrone($('[id^="page-vehicule-commerce-statut-mail-"]'));
                            } else if (attr == "general") {
                                initLienConcurrent();
                                initDonutMarge('particulier');
                                initDonutMarge('marchand');
                                initAllBarEvolutionPrix();
                            }

                            // On cache les autres onglets si on est sur l'onglet client
                            if (attr == "client") {
                                $.each($('[id^="stock_vehicule-tab-"]'), function (item) {
                                    if (this.id != 'stock_vehicule-tab-client') {
                                        $(this).addClass("d-none")
                                    }
                                })
                            }
                        }
                    });
                }


            });
        });

        /**
         * Expertise
         */

        $('body').on('click', "#stock_vehicule-expertise", function () {
            channelOpen($(this).data("step"), "expertise");
            onOpenChange(document.querySelector("#expertise-dialog-" + vehiculeId + "-dialog[data-step='5']"), (observer) => {
                // Prends les accessoires uniquement
                let expertise = $("#expertise-dialog-" + vehiculeId + "-dialog[data-step='5']").data("expertise").filter(f => f.categorie == 4);
                // Fusionne et rends unique suivant l'id du frais
                expertise = [...new Map($("#expertise-dialog-" + vehiculeId + "-dialog[data-step='10']").data("expertise").filter(f => f.categorie != 4).concat(expertise).map(frais => [frais.id, frais])).values()];
                $("#expertise-dialog-" + vehiculeId + "-dialog[data-step='10']").data("expertise", expertise);
                expertiseFraisCancel($("#expertise-dialog-" + vehiculeId + "-dialog[data-step='5']"));
            });
            onOpenChange(document.querySelector("#expertise-dialog-" + vehiculeId + "-dialog[data-step='10']"), (observer) => {
                // Prends les accessoires uniquement
                let expertise = $("#expertise-dialog-" + vehiculeId + "-dialog[data-step='10']").data("expertise").filter(f => f.categorie == 4);
                // Fusionne et rends unique suivant l'id du frais
                expertise = [...new Map($("#expertise-dialog-" + vehiculeId + "-dialog[data-step='5']").data("expertise").filter(f => f.categorie != 4).concat(expertise).map(frais => [frais.id, frais])).values()];
                $("#expertise-dialog-" + vehiculeId + "-dialog[data-step='5']").data("expertise", expertise);
                expertiseFraisCancel($("#expertise-dialog-" + vehiculeId + "-dialog[data-step='10']"));
            });
        });

        $('body').on('click', "#expertise-channel-dialog-check-step-1", function () {
            checkExpertiseStep1();
        });

        $('body').on('change', "#stock_vehicule-revision-duree", function (e) {
            // Update le temps avant la révision
            const el = $(this);
            const value = el.val();
            let form = new FormData();
            form.append('id', vehiculeId);
            form.append('value', value);
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/stock/update/revision-duree',
                contentType: false,
                processData: false,
                data: form,
                success: function () {
                    if (el.parents("dialog").length) {
                        $("[id='" + el[0].id + "']").toArray().forEach(element => { $(element).val(el.val()); });
                        dialogToastrSuccess(el.parents("dialog"), "expertise", "La date de révision a bien été enregistré");
                    } else {
                        $("#stock_vehicule-tab-expertise").trigger("click"); toastr.success('La date de révision a bien été enregistré', 'Succès');
                    }
                },
                error: function () { if (el.parents("dialog").length) { toastr.error('Une erreur s\'est produite', 'Erreur'); } else { dialogToastrError(el.parents("dialog"), "expertise", "Une erreur s\'est produite"); } }
            });
        });

        $('body').on('change', "#stock_vehicule-revision-distance", function (e) {
            // Update la distance avant la révision
            const el = $(this);
            const value = el.val();
            let form = new FormData();
            form.append('id', vehiculeId);
            form.append('value', value);
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/stock/update/revision-distance',
                contentType: false,
                processData: false,
                data: form,
                success: function () {
                    if (el.parents("dialog").length) {
                        $("[id='" + el[0].id + "']").toArray().forEach(element => { $(element).val(el.val()); });
                        dialogToastrSuccess(el.parents("dialog"), "expertise", "La date de révision a bien été enregistré");
                    } else {
                        $("#stock_vehicule-tab-expertise").trigger("click"); toastr.success('La date de révision a bien été enregistré', 'Succès');
                    }
                },
                error: function () { if (el.parents("dialog").length) { toastr.error('Une erreur s\'est produite', 'Erreur'); } else { dialogToastrError(el.parents("dialog"), "expertise", "Une erreur s\'est produite"); } }
            });
        });

        // Pour afficher ou non la date de dernier CT si on l'a
        $('body').on('change', "#stock_vehicule-expertise-affichage-dernier-ct", function (e) {
            let value = $(this).val();
            if (value == "on") {
                $('.stock_vehicule-affichage-dernier-ct-on').removeClass('d-none');
                $('.stock_vehicule-affichage-dernier-ct-on').addClass('d-flex');
            } else {
                $('.stock_vehicule-affichage-dernier-ct-on').removeClass('d-flex');
                $('.stock_vehicule-affichage-dernier-ct-on').addClass('d-none');
            }
            // On passe à vide les valeurs dans la date et dans la photo
            $('#stock_vehicule-date-dernier-controle-technique').val('').trigger('change');
            $('#stock_vehicule-date-dernier-controle-technique').val('').trigger('change');
            $("[id^='expertise-controle-technique-'][id$='-delete']").each(function (index) {
                const val = this.id.replace("expertise-controle-technique-", "").replace("-delete", "");
                expertisePhoto("controle-technique", val, "del");
            });
        });

        $('body').on('change', "#stock_vehicule-date-dernier-controle-technique", function (e) {
            // Update le temps avant la révision
            const el = $(this);
            const value = el.val();
            let form = new FormData();
            form.append('id', vehiculeId);
            form.append('value', value);
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/stock/update/date-dernier-controle-technique',
                contentType: false,
                processData: false,
                data: form,
                success: function () {
                    if (el.parents("dialog").length) {
                        $("[id='" + el[0].id + "']").toArray().forEach(element => { $(element).val(el.val()); });
                        dialogToastrSuccess(el.parents("dialog"), "expertise", "La date de dernier contrôle technique a bien été enregistrée");
                    } else {
                        $("#stock_vehicule-tab-expertise").trigger("click"); toastr.success('La date de dernier contrôle technique a bien été enregistrée', 'Succès');
                    }
                },
                error: function () { if (el.parents("dialog").length) { toastr.error('Une erreur s\'est produite', 'Erreur'); } else { dialogToastrError(el.parents("dialog"), "expertise", "Une erreur s\'est produite"); } }
            });
        });

        $('body').on('change', "[id^='stock_vehicule-type-pneu']", function (e) {
            // Update le type de pneu du véhicule
            const el = $(this);
            const value = el.val();
            let form = new FormData();
            form.append('id', vehiculeId);
            form.append('value', value);
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/stock/update/' + this.id.replace("stock_vehicule-", ""),
                contentType: false,
                processData: false,
                data: form,
                success: function () {
                    if (el.parents("dialog").length) {
                        $("[id='" + el[0].id + "']").toArray().forEach(element => { $(element).val(el.val()); });
                        dialogToastrSuccess(el.parents("dialog"), "expertise", "Le type de pneu a bien été enregistré");
                    } else {
                        $("#stock_vehicule-tab-expertise").trigger("click"); toastr.success('Le type de pneu a bien été enregistré', 'Succès');
                    }
                },
                error: function () { if (el.parents("dialog").length) { toastr.error('Une erreur s\'est produite', 'Erreur'); } else { dialogToastrError(el.parents("dialog"), "expertise", "Une erreur s\'est produite"); } }
            });
        });

        $('body').on('change', "[id^='stock_vehicule-diametre-pneu']", function (e) {
            // Update le type de pneu du véhicule
            const el = $(this);
            const value = el.val();
            let form = new FormData();
            form.append('id', vehiculeId);
            form.append('value', value);
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/stock/update/' + this.id.replace("stock_vehicule-", ""),
                contentType: false,
                processData: false,
                data: form,
                success: function () {
                    if (el.parents("dialog").length) {
                        $("[id='" + el[0].id + "']").toArray().forEach(element => { $(element).val(el.val()); });
                        dialogToastrSuccess(el.parents("dialog"), "expertise", "Le diamètre de pneu a bien été enregistré");
                    } else {
                        $("#stock_vehicule-tab-expertise").trigger("click"); toastr.success('Le diamètre de pneu a bien été enregistré', 'Succès');
                    }
                },
                error: function () { if (el.parents("dialog").length) { toastr.error('Une erreur s\'est produite', 'Erreur'); } else { dialogToastrError(el.parents("dialog"), "expertise", "Une erreur s\'est produite"); } }
            });
        });

        $('body').on('change', "[id^='mesure-']", function (e) {
            // Update les mesure des consommables
            const el = $(this);
            const type = this.id.replace("mesure-", "");
            let value = el.val();
            value = parseFloat(value.replace(',', '.'));
            let form = new FormData();
            form.append('id', vehiculeId);
            form.append('type', type);
            form.append('value', value);
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/stock/update/mesure',
                contentType: false,
                processData: false,
                data: form,
                success: function (data) {
                    if (el.parents("dialog").length) {
                        $("[id='" + el[0].id + "']").toArray().forEach(element => { $(element).val(el.val()); });
                        dialogToastrSuccess(el.parents("dialog"), "expertise", "La mesure a bien été enregistré");
                    } else {
                        if (type.startsWith("pneu-") && (parseInt(data) ^ $("#stock_vehicule-expertise").length)) {
                            $("#stock_vehicule-tab-expertise").trigger("click");
                        }
                        toastr.success('La mesure a bien été enregistré', 'Succès');
                    }
                },
                error: function () { if (el.parents("dialog").length) { toastr.error('Une erreur s\'est produite', 'Erreur'); } else { dialogToastrError(el.parents("dialog"), "expertise", "Une erreur s\'est produite"); } }
            });
        });

        // Expertise

        $('body').on('click', "#stock_vehicule-reception", function () {
            $("#reception_input_marque").selectpicker();
            $("#reception_input_modele").selectpicker();
            $("#reception_input_energie").selectpicker();
            $("#reception_input_boite").selectpicker();
            $("#reception_input_couleur").selectpicker();
            channelOpen($(this).data("step"), "reception");
        });

        $('body').on('click', "#stock_vehicule-papier", function () {
            channelOpen($(this).data("step"), "papier");
        });

        /**
         * Documents
         */

        $('body').on('click', ".stock_vehicule-document-item-tools-item", function () {
            const tool = $(this).data("tool");
            const infos = $(this).data("infos");
            const uid = $(this).data("uid");
            if (["create", "print"].includes(tool)) {
                if (["fiche-fre", "fiche-vehicule-part", "fiche-vehicule-pro", "qr-code", "fiche-resume-preparation"].includes(infos.printType)) {
                    let element = document.createElement('a');
                    element.setAttribute('href', infos.printUrl);
                    element.setAttribute('download', "");
                    document.body.appendChild(element);
                    element.click();
                    document.body.removeChild(element);
                } else {
                    let form = new FormData();
                    form.append('id', vehiculeId);
                    form.append('printType', infos.printType);
                    form.append('prestataireId', infos.prestataireId);
                    $.ajax({
                        type: 'POST',
                        url: siteURL + 'admin/' + infos.printType + '/' + tool,
                        contentType: false,
                        processData: false,
                        data: form,
                        success: function () {
                            if (tool == "print") {
                                toastr.success('Le fichier pdf est fini', 'Succès');
                                let element = document.createElement('a');
                                element.setAttribute('href', siteURL + "assets/docs/" + infos.printType + "-" + infos.id + ".pdf");
                                element.setAttribute('download', infos.printType + "-" + infos.id + ".pdf");
                                document.body.appendChild(element);
                                element.click();
                                document.body.removeChild(element);
                            } else if (tool == "create") {
                                toastr.success('Enregistrement terminé', 'Succès');
                                $("#stock_vehicule-tab-document").trigger("click");
                            }
                        },
                        error: function () {
                            toastr.error('Une erreur s\'est produite', 'Erreur');
                        }
                    });
                }
            } else if (tool == "view") {
                if (["fiche-fre", "fiche-vehicule-part", "fiche-vehicule-pro", "qr-code", "fiche-resume-preparation"].includes(infos.printType)) {
                    Lobibox.window({
                        title: 'Prévisualisation',
                        baseClass: "modal-content modal-lobistrap",
                        content: '<embed src="' + infos.printUrl + '" width="100%" height="100%" type="application/pdf">'
                    });
                } else {
                    Lobibox.window({
                        title: "Prévisualisation de l'e-mail",
                        baseClass: "modal-content modal-lobistrap",
                        width: 823, // Pour une prévisualisation de 600px + largeur des paddings de la modal + largeur de la modal
                        url: siteURL + "admin/" + infos.printType + "/" + tool,
                        autoload: true,
                        loadMethod: "POST",
                        params: { "id": vehiculeId, "prestataireId": infos.prestataireId, "printType": infos.printType }
                    });
                }
            } else if (tool == "facture") {
                Lobibox.window({
                    title: 'Prévisualisation',
                    baseClass: "modal-content modal-lobistrap",
                    content: (
                        infos.facture.isImage
                            ? '<div class="d-flex justify-content-center align-items-center"><img src="' + infos.facture.path + '" style="max-height: -webkit-fill-available; max-width: -webkit-fill-available;"></div>'
                            : '<embed src="' + infos.facture.path + '" width="100%" height="100%" type="application/pdf">'
                    )
                });
            } else if (tool == "send") {
                $('#document-send-modal-' + uid).modal('show');
            } else if (tool == "modal") {
                lobibox_display_pregeneration_ordre_reparation = Lobibox.window({
                    title: "Génération d'ordre de réparation pour " + $(this).parent().parent().find(".stock_vehicule-document-item-sub").html(),
                    baseClass: "modal-content modal-lobistrap",
                    width: 1000, // Pour une prévisualisation de 600px + largeur des paddings de la modal + largeur de la modal
                    url: siteURL + "admin/ordre-reparation/modalOrdreReparation",
                    autoload: true,
                    loadMethod: "POST",
                    params: { "id": vehiculeId, "prestataireId": infos.prestataireId },
                    shown: function (lobi) {
                        ordreReparationPregeneration(lobi.$el[0]);
                    }
                });
            }
        });

        $('body').on('change', ".lobibox [id='ordre-reparation-lieu']", function () { ordreReparationPregeneration(lobibox_display_pregeneration_ordre_reparation.$el[0]); });
        $('body').on('change', ".lobibox [id='ordre-reparation-agence']", function () { ordreReparationPregeneration(lobibox_display_pregeneration_ordre_reparation.$el[0]); });
        $('body').on('change', ".lobibox [id='ordre-reparation-prestataire']", function () { ordreReparationPregeneration(lobibox_display_pregeneration_ordre_reparation.$el[0]); });

        function ordreReparationPregeneration(lobi) {
            // const ordreReparationAgenceFacturation = lobi.querySelector("#ordre-reparation-agence-facturation");
            const ordreReparationLieu = lobi.querySelector("#ordre-reparation-lieu");
            const ordreReparationAgence = lobi.querySelector("#ordre-reparation-agence");
            const ordreReparationAgenceOption = ordreReparationAgence.querySelector("option[value='" + ordreReparationAgence.value + "']")
            const ordreReparationPrestataire = lobi.querySelector("#ordre-reparation-prestataire");
            const ordreReparationPrestataireOption = ordreReparationPrestataire.querySelector("option[value='" + ordreReparationPrestataire.value + "']")
            const ordreReparationAdresse = lobi.querySelector("#ordre-reparation-adresse");
            const ordreReparationVille = lobi.querySelector("#ordre-reparation-ville");
            const ordreReparationCodePostal = lobi.querySelector("#ordre-reparation-codePostal");
            const ordreReparationTelephone = lobi.querySelector("#ordre-reparation-telephone");
            switch (document.querySelector("[id^='" + ordreReparationLieu.id + "-']:checked").value) {
                case "ora":
                    ordreReparationAgence.parentElement.classList.remove("d-none");
                    ordreReparationPrestataire.parentElement.classList.add("d-none");
                    ordreReparationAgence.disabled = false;
                    ordreReparationPrestataire.disabled = true;
                    ordreReparationAdresse.disabled = true;
                    ordreReparationVille.disabled = true;
                    ordreReparationCodePostal.disabled = true;
                    ordreReparationTelephone.disabled = true;
                    ordreReparationAdresse.value = ordreReparationAgenceOption.getAttribute("data-adresse");
                    ordreReparationVille.value = ordreReparationAgenceOption.getAttribute("data-ville");
                    ordreReparationCodePostal.value = ordreReparationAgenceOption.getAttribute("data-codePostal");
                    ordreReparationTelephone.value = ordreReparationAgenceOption.getAttribute("data-telephone");
                    break;

                case "presta":
                    ordreReparationAgence.parentElement.classList.add("d-none");
                    ordreReparationPrestataire.parentElement.classList.remove("d-none");
                    ordreReparationAgence.disabled = true;
                    ordreReparationPrestataire.disabled = false;
                    ordreReparationAdresse.disabled = true;
                    ordreReparationVille.disabled = true;
                    ordreReparationCodePostal.disabled = true;
                    ordreReparationTelephone.disabled = true;
                    ordreReparationAdresse.value = ordreReparationPrestataireOption.getAttribute("data-adresse");
                    ordreReparationVille.value = ordreReparationPrestataireOption.getAttribute("data-ville");
                    ordreReparationCodePostal.value = ordreReparationPrestataireOption.getAttribute("data-codePostal");
                    ordreReparationTelephone.value = ordreReparationPrestataireOption.getAttribute("data-telephone");
                    break;

                default:
                    ordreReparationAgence.parentElement.classList.add("d-none");
                    ordreReparationPrestataire.parentElement.classList.add("d-none");
                    ordreReparationAgence.disabled = true;
                    ordreReparationPrestataire.disabled = true;
                    ordreReparationAdresse.disabled = false;
                    ordreReparationVille.disabled = false;
                    ordreReparationCodePostal.disabled = false;
                    ordreReparationTelephone.disabled = false;
                    ordreReparationAdresse.value = "";
                    ordreReparationVille.value = "";
                    ordreReparationCodePostal.value = "";
                    ordreReparationTelephone.value = "";
                    break;
            }
        }

        $('body').on('click', "#create-ordre-reparation-frais-commentaitre-isPublic", function () {
            let prestataireId = $(this).data("prestataireid");
            let tableauFraisCommentaireIsPublic = {};
            $("[id^=frais_commentaire_isPublic_]").each(function () {
                let idFrais = $(this).attr("id").replace("frais_commentaire_isPublic_", "");
                tableauFraisCommentaireIsPublic[idFrais] = $(this).val() == 'on' ? 1 : 0;
            });
            let form = new FormData();
            form.append('tableauFraisCommentaireIsPublic', JSON.stringify(tableauFraisCommentaireIsPublic));
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/ordre-reparation/frais-commentaire-isPublic',
                contentType: false,
                processData: false,
                data: form,
                success: function () {
                    let form = new FormData();
                    form.append('id', vehiculeId);
                    form.append('prestataireId', prestataireId);
                    form.append('agenceFacturation', $(".lobibox #ordre-reparation-agence-facturation").val());
                    form.append('adresse', $(".lobibox #ordre-reparation-adresse").val());
                    form.append('ville', $(".lobibox #ordre-reparation-ville").val());
                    form.append('codePostal', $(".lobibox #ordre-reparation-codePostal").val());
                    form.append('telephone', $(".lobibox #ordre-reparation-telephone").val());
                    $.ajax({
                        type: 'POST',
                        url: siteURL + 'admin/ordre-reparation/create',
                        contentType: false,
                        processData: false,
                        data: form,
                        success: function () {
                            lobibox_display_pregeneration_ordre_reparation.destroy();
                            toastr.success('Enregistrement terminé', 'Succès');
                            $("#stock_vehicule-tab-document").trigger("click");
                        },
                        error: function () {
                            toastr.error('Une erreur s\'est produite', 'Erreur');
                        }
                    });
                },
                error: function () {
                    toastr.error('Une erreur s\'est produite', 'Erreur');
                }
            });
        });

        $('body').on('click', "[id^='document-send-modal-'][id$='-send']", function () {
            const el = $(this);
            el.prop("disabled", true);
            const uid = el.data("uid");
            const baseId = "document-send-modal-" + uid;
            const destinataireEl = $("[id^='" + baseId + "-destinataire-']:checked");
            const destinataire = [];
            for (let index = 0; index < destinataireEl.length; index++) { destinataire[index] = destinataireEl[index].getAttribute("data-email"); }
            const copyCacheEl = $("[id^='" + baseId + "-copy-']:checked");
            const copyCache = [];
            for (let index = 0; index < copyCacheEl.length; index++) { copyCache[index] = copyCacheEl[index].getAttribute("data-email"); }
            if (destinataire.length) {
                let form = new FormData();
                form.append('id', vehiculeId);
                form.append('printType', el.data("printtype"));
                form.append('ordreReparationId', el.data("ordrereparationid"));
                form.append('destinataire', destinataire);
                form.append('objet', $("#" + baseId + "-objet").val());
                form.append('message', $("#" + baseId + "-message").val());
                form.append('copy', $("#" + baseId + "-copy").val());
                form.append('copyCache', copyCache);
                $.ajax({
                    type: 'POST',
                    url: siteURL + 'admin/' + el.data("printtype") + '/send',
                    contentType: false,
                    processData: false,
                    data: form,
                    success: function (data) { if (data) { $("#" + baseId).modal('hide'); toastr.success("Envoi réussi", "Succès"); } else { toastr.error("L'envoi a échoué", "Erreur"); } },
                    error: function () { toastr.error('Une erreur s\'est produite', 'Erreur'); },
                    complete: function () { el.prop("disabled", false); }
                });
            } else {
                toastr.error("Aucun destinataire désigné.", "Erreur");
                el.prop("disabled", false);
            }
        });

        $('body').on('hidden.bs.modal', "[id^='document-send-modal-']", function () {
            $("#stock_vehicule-tab-document").trigger("click");
        });

        // Documents

        /**
         * goToTab
        */
        const goToTab = $("#stock_vehicule_page").data("gototab");
        if (goToTab != "") {
            window.history.pushState("", "", window.location.href.replace(window.location.search, ""));
            $("#stock_vehicule-tab-" + goToTab).trigger("click");
        }
        // goToTab
    }

    // Pour créer les liens concurrents
    function initLienConcurrent() {
        let info = $("#stock_vehicule_info_lien_lc_lbc");
        $("[id='stock_vehicule_lacentrale']").each((i, e) => $(e).attr("href", lacentraleLinkGenerator(info.attr("data-marque"), info.attr("data-modele"), info.attr("data-boite"), info.attr("data-carburant"), info.attr("data-datemec"), info.attr("data-kilometrage"))));
        $("[id='stock_vehicule_leboncoin']").each((i, e) => $(e).attr("href", leboncoinLinkGenerator(info.attr("data-marque"), info.attr("data-modele"), info.attr("data-boite"), info.attr("data-carburant"), info.attr("data-datemec"), info.attr("data-kilometrage"))));
        $("[id='stock_vehicule_starterre']").each((i, e) => $(e).attr("href", starterreLinkGenerator(info.attr("data-marque"), info.attr("data-modele"), info.attr("data-boite"), info.attr("data-carburant"), info.attr("data-datemec"), info.attr("data-kilometrage"))));
        $("[id='stock_vehicule_aramisauto']").each((i, e) => $(e).attr("href", aramisautoLinkGenerator(info.attr("data-marque"), info.attr("data-modele"), info.attr("data-boite"), info.attr("data-carburant"), info.attr("data-datemec"), info.attr("data-kilometrage"))));
    }

    // Initialise les donut des marges particuliers et marchand
    function initDonutMarge(typePrix) {
        const achat = $('#stock_vehicule-rentabilite-montant-marge-' + typePrix).data("achat");
        const marge = $('#stock_vehicule-rentabilite-montant-marge-' + typePrix).data("marge");
        const margeData = getMargeDisplay(marge, achat);
        let configMarge = {
            type: 'doughnut',
            data: {
                labels: [
                    "Marge",
                ],
                datasets: [{
                    data: [margeData.display.bad.width, margeData.display.avg.width, margeData.display.good.width, margeData.display.leftover.width],
                    backgroundColor: [margeData.display.bad.color, margeData.display.avg.color, margeData.display.good.color, margeData.display.leftover.color],
                    borderWidth: 0,
                }]
            },
            options: {
                circumference: Math.PI,
                rotation: -Math.PI,
                cutoutPercentage: 90,
                legend: {
                    display: false,
                },
                tooltips: {
                    enabled: false,
                },
                hover: false,
            }
        };
        new Chart(document.getElementById('stock_vehicule-rentabilite-marge-' + typePrix + '-chartjs').getContext('2d'), configMarge);
    }

    // Initialise les graphiques d'évolution de prix
    function initAllBarEvolutionPrix() {
        initBarEvolutionPrix('particulier');
        initBarEvolutionPrix('marchand');
    }

    function initBarEvolutionPrix(typePrix) {
        // On va chercher les historiques de prix du véhicule pour construire les datas du graphique
        let dataGraphique = [];
        $('#stock_vehicule-historique-prix-total-' + typePrix + ' li').each(function (index) {
            let date = $(this).find("[id^='stock_vehicule-historique-prix-date']").html();
            let value = parseFloat($(this).find("[id^='stock_vehicule-historique-prix-value']").html().replace(" ", "").replace(',', '.'));
            dataGraphique.push({ t: moment(date, "DD/MM/YYYY"), y: value });
        });

        // Si on a aucun historique de prix, on va prendre la date d'import et le prix de vente pour faire le premier point
        if (dataGraphique.length == 0) {
            dataGraphique.push({ t: moment($('#stock_vehicule-evolution-prix-' + typePrix + '-content').data('dateimport'), "DD/MM/YYYY"), y: $('#stock_vehicule-prix-vente-' + typePrix).html().replace('€', '').replace(" ", "").replace(',', '.') });
        }

        // On ajoute la même dernière valeur à aujourd'hui ou à la date de désactivation pour faire un tracé plat
        let lastPoint = dataGraphique[dataGraphique.length - 1];
        let dateFinGraph = "";
        if ($('#stock_vehicule-evolution-prix-' + typePrix + '-content').data('datedesactivation') != "") {
            dateFinGraph = moment($('#stock_vehicule-evolution-prix-' + typePrix + '-content').data('datedesactivation'), "DD/MM/YYYY");
        } else {
            dateFinGraph = moment().startOf('day');
        }
        dataGraphique.push({ t: dateFinGraph, y: lastPoint.y });

        // Calcul des min/max pour l'axe Y avec 10% de marge
        // Déterminer le pas d'arrondi en fonction de l'écart
        let minY = Math.min(...dataGraphique.map(d => d.y));
        let maxY = Math.max(...dataGraphique.map(d => d.y));
        let range = maxY - minY;
        let step;
        if (range < 10000) {
            step = 1000;  // Petites valeurs : arrondi au millier
        } else if (range < 50000) {
            step = 5000;  // Écart moyen : arrondi à 5000
        } else {
            step = 10000; // Grand écart : arrondi à 10k
        }

        // Application de l'arrondi dynamique
        let yMin = roundToDynamicStep(minY - step, 'down', step);
        let yMax = roundToDynamicStep(maxY + step, 'up', step);

        let configEvolutionPrix = {
            type: 'line',
            data: {
                datasets: [{
                    data: dataGraphique,
                    backgroundColor: 'transparent',
                    borderColor: '#4285F4',
                    lineTension: 0,
                }]
            },
            options: {
                legend: {
                    display: false,
                },
                tooltips: {
                    callbacks: {
                        label: function (tooltipItem, data) {
                            return tooltipItem.yLabel.toLocaleString() + " €"; // Affichage formaté
                        }
                    }
                },
                scales: {
                    xAxes: [{
                        type: 'time',
                        time: {
                            unit: 'month',
                            tooltipFormat: 'DD/MM/YYYY',
                            displayFormats: {
                                month: 'MMM YYYY'
                            }
                        },
                        ticks: { // Permet de faire le graphique de la première valeur à aujourd'hui
                            min: moment(dataGraphique[0].t),
                            max: dateFinGraph
                        }
                    }],
                    yAxes: [{
                        ticks: {
                            // beginAtZero: true,
                            min: yMin,
                            max: yMax,
                            callback: function (value) { // Format en "k"
                                return value >= 1000 ? value / 1000 + 'k' : value;
                            }
                        }
                    }]
                }
            }
        };

        new Chart(document.getElementById('stock_vehicule-evolution-prix-' + typePrix + '-chartjs').getContext('2d'), configEvolutionPrix);
    }

    // Fonction pour arrondir les valeurs sur le graphique des historiques de prix
    function roundToDynamicStep(value, direction = 'up', step) {
        return direction === 'up' ? Math.ceil(value / step) * step : Math.floor(value / step) * step;
    }

    // Fonction pour l'initialisation des graphiques de lignes sur l'onglet commerce
    function initAllLineEvolutionCommerce() {
        $('[id^="stock_vehicule-commerce-evolution-"] [id$="-chartjs"]').each(function (index) {
            let typeEvolution = this.id.replace('stock_vehicule-commerce-evolution-', '').replace('-chartjs', '');
            initLineEvolutionCommerce(typeEvolution);
        });
    }

    function initLineEvolutionCommerce(typeEvolution) {
        // On choisi les couleurs de courbe et background en fonction du type d'évolution
        let bgColorEvolution = {
            'leads': '#D1E7DD',
            'offreParticulier': '#CFE2FF',
            'offreMarchand': '#FFF3CD',
        };
        let colorEvolution = {
            'leads': '#34A853',
            'offreParticulier': '#4285F4',
            'offreMarchand': '#FBBC05',
        };

        // On détermine la fin pour faire le graphique jusqu'à la date de désactivation ou d'aujourd'hui
        let dateFinGraph = "";
        if ($('#stock_vehicule-commerce-evolution-' + typeEvolution + '-content').data('datedesactivation') != "") {
            dateFinGraph = moment($('#stock_vehicule-commerce-evolution-' + typeEvolution + '-content').data('datedesactivation'), "DD/MM/YYYY");
        } else {
            dateFinGraph = moment().startOf('day');
        }
        // On va chercher les données des offres/leads du véhicule pour construire les datas du graphique si on en a en prenant depuis la date d'import
        let dataGraphique = "";
        if ($('#stock_vehicule-commerce-evolution-' + typeEvolution + '-content').data('donneegraph') != []) {
            dataGraphique = fillMissingMonths(
                $('#stock_vehicule-commerce-evolution-' + typeEvolution + '-content').data('donneegraph'),
                moment($('#stock_vehicule-commerce-evolution-' + typeEvolution + '-content').data('dateimport'), "DD/MM/YYYY"),
                dateFinGraph);
        }

        // Si on a 0 leads/offres, on rajoute à aujourd'hui à 0
        if (dataGraphique.length == 0) {
            dataGraphique.push({ t: dateFinGraph, y: 0 });
        }

        // On détermine le max pour ajouter un au graphique
        let maxY = Math.max(...dataGraphique.map(d => d.y));

        let configEvolutionPrix = {
            type: 'line',
            data: {
                datasets: [{
                    data: dataGraphique,
                    backgroundColor: bgColorEvolution[typeEvolution],
                    borderColor: colorEvolution[typeEvolution],
                    lineTension: 0,
                }]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: false,
                },
                scales: {
                    xAxes: [{
                        type: 'time',
                        time: {
                            unit: 'month',
                            tooltipFormat: 'MMM YYYY',
                            displayFormats: {
                                month: 'MMM YYYY',
                            },
                        },
                        ticks: { // Permet de faire le graphique de la première valeur à aujourd'hui
                            min: moment(dataGraphique[0].t),
                            max: dateFinGraph,
                            display: false,
                        },
                        gridLines: {
                            display: false,
                        },
                    }],
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            display: false,
                            max: (maxY + 1),
                        },
                        gridLines: {
                            display: false,
                        },
                    }],
                },
            }
        };

        new Chart(document.getElementById('stock_vehicule-commerce-evolution-' + typeEvolution + '-chartjs').getContext('2d'), configEvolutionPrix);
    }

});

function fillMissingMonths(data, dateDebut, dateFin) {
    stats = (data ? data : {});
    let dataStats = [];
    const moisArray = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];

    let minDate = dateDebut.clone();
    let maxDate = dateFin.clone();
    let existingDates = new Set();

    // Extraction des données et détermination de la plage min/max
    Object.entries(stats).forEach(([annee, listMois]) => {
        Object.entries(listMois).forEach(([mois, stat]) => {
            let date = moment(moisArray[parseInt(mois) - 1] + '/' + annee, "MM/YYYY");
            dataStats.push({ t: date, y: stat });
            existingDates.add(date.format("YYYY-MM"));
        });
    });

    // Ajout des mois manquants avec y: 0
    if (minDate && maxDate) {
        let current = minDate.clone();
        while (current.isSameOrBefore(maxDate, 'month')) {
            let formatted = current.format("YYYY-MM");
            if (!existingDates.has(formatted)) {
                dataStats.push({ t: current.clone(), y: 0 });
            }
            current.add(1, 'month');
        }
    }

    // On trie les données par date
    dataStats.sort((a, b) => a.t - b.t);
    return dataStats;
}