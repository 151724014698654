let vehicule_fournisseur_table = null;
let vehicule_fournisseur_table_page = 0;
let timerBeforeRefreshDataVehiculeFournisseur = null;

const idannuairecreationdepuisannuairefournisseur = ($("#vehicule_fournisseur-tabs").length ? $("#vehicule_fournisseur-tabs").data("idannuairecreationdepuisannuairefournisseur") : "");
$(document).ready(function () {
    if ($("#vehicule_fournisseur_results").length) {
        window.history.pushState("", "", siteURL + "admin/vehicule_fournisseur");

        let trieColonne = [];

        colvisFunOnEvent();

        function loadAjax({isPageReload = false, idOffset = null, idannuairecreationdepuisannuairefournisseur = ""} = {}) {
            if (!idOffset) {
                idOffset = parseInt($("#vehicule_fournisseur-tabs-list-tab").attr("data-load-ajax-id"));
            }
            $("#vehicule_fournisseur-tabs-list-tab").attr("data-load-ajax-id", 0);
            let optionsRecherche = new FormData();
            optionsRecherche.append('identifiant', $("#filtersvehiculefournisseur-identifiant").val());
            optionsRecherche.append('marques', $("#filtersvehiculefournisseur-marque").val());
            optionsRecherche.append('modeles', $("#filtersvehiculefournisseur-modele").val());
            optionsRecherche.append('statut', $("#filtersvehiculefournisseur-statut").val());
            optionsRecherche.append('is_verif', $("#filtersvehiculefournisseur-is_verif").val());
            optionsRecherche.append('is_envoi', $("#filtersvehiculefournisseur-is_envoi").val().join());
            optionsRecherche.append('active', ($("#filtersvehiculefournisseur-active").val() == "on" ? 0 : 1));
            optionsRecherche.append('createur', $("#filtersvehiculefournisseur-createur").val());
            optionsRecherche.append('fournisseur', $("#filtersvehiculefournisseur-fournisseur").val());
            $("#vehicule_fournisseur_results").addClass("d-none");
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/vehicule_fournisseur_ajax',
                contentType: false,
                processData: false,
                data: optionsRecherche,
                success: function (data) {
                    $("#vehicule_fournisseur_results").html(data);
                    vehicule_fournisseur_table = $('#vehicule_fournisseur_table').DataTable({
                        "dom": dataTablesCustomDom({ addbtn: true }),
                        "buttons": [dataTablesColvisBtn('th:nth-child(n+3):not(:last-child)')],
                        "columns": getColvisColumn("vehicule_fournisseur_table"),
                        "autoWidth": false,
                        "language": {
                            "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/en-GB.json"
                        },
                        "paging": true,
                        "pageLength": 50,
                        "lengthMenu": [[10, 25, 50, 100, -1], [10, 25, 50, 100, "All"]],
                        "responsive": true,
                        "order": [
                            [1, "desc"]
                        ],
                        "columnDefs": [
                            { type: 'num', targets: [1, 10, 11, 12, 13, 14, 15, 16, 18] },
                            // ALIGN MIDDLE
                            {
                                "className": "text-center align-middle",
                                "targets": '_all',
                            },
                            // MODIFY
                            {
                                "targets": [0, 17, 20],
                                "orderable": false,
                            },
                        ],
                        "conditionalPaging": true,
                    });
                    $('#vehicule_fournisseur_table').on('init.dt', function () { initMirrorBtn(); setColvisTable(vehicule_fournisseur_table); colvisFunSelectRefresh(); });
                    $('#vehicule_fournisseur_table').on('draw.dt', function () {
                        // Génération dynamique des liens sur le tableau
                        vehicule_fournisseur_table.rows().invalidate();
                        let all_data = vehicule_fournisseur_table.rows().data();
                        $.each(all_data, function (key, value) {
                            let idCheckBox = value[1]["@data-order"];
                            let tr = $('#vehicule_fournisseur_table_tr_' + idCheckBox);
                            if (tr.length) {
                                $("#vehicule_fournisseur_lacentrale_" + idCheckBox.replace("selection_vehicule_fournisseur_", "")).attr("href", lacentraleLinkGenerator(tr.attr("data-marque"), tr.attr("data-modele"), tr.attr("data-boite"), tr.attr("data-carburant"), tr.attr("data-datemec"), tr.attr("data-kilometrage")));
                                $("#vehicule_fournisseur_leboncoin_" + idCheckBox.replace("selection_vehicule_fournisseur_", "")).attr("href", leboncoinLinkGenerator(tr.attr("data-marque"), tr.attr("data-modele"), tr.attr("data-boite"), tr.attr("data-carburant"), tr.attr("data-datemec"), tr.attr("data-kilometrage")));
                                $("#vehicule_fournisseur_starterre_" + idCheckBox.replace("selection_vehicule_fournisseur_", "")).attr("href", starterreLinkGenerator(tr.attr("data-marque"), tr.attr("data-modele"), tr.attr("data-boite"), tr.attr("data-carburant"), tr.attr("data-datemec"), tr.attr("data-kilometrage")));
                                $("#vehicule_fournisseur_aramisauto_" + idCheckBox.replace("selection_vehicule_fournisseur_", "")).attr("href", aramisautoLinkGenerator(tr.attr("data-marque"), tr.attr("data-modele"), tr.attr("data-boite"), tr.attr("data-carburant"), tr.attr("data-datemec"), tr.attr("data-kilometrage")));
                            }
                        });
                        $("#vehicule_fournisseur_results").removeClass("d-none");
                        if ($('#selection_vehicule_fournisseur_' + idOffset).length) {
                            $('html,body').animate({ scrollTop: $('#selection_vehicule_fournisseur_' + idOffset).parent().offset().top - $('.navbar').height() - $($("#vehicule_fournisseur_table")[0].firstElementChild).height() }, 'slow');
                        }
                        if (isPageReload) {
                            vehicule_fournisseur_table_page = vehicule_fournisseur_table.page();
                            // On affiche le tableau
                            $("#vehicule_fournisseur_results").removeClass("d-none");
                            if (idannuairecreationdepuisannuairefournisseur) {
                                $("#vehicule_fournisseur-tabs-add-tab").trigger("click");
                                $("#vehicule_fournisseur_input_fournisseur_0").val(idannuairecreationdepuisannuairefournisseur);
                                $("#vehicule_fournisseur_input_fournisseur_0").trigger('change');
                            }
                        } else {
                            isPageReload = true;
                            if (trieColonne != null && trieColonne.length > 0) {
                                vehicule_fournisseur_table.order([trieColonne[0][0], trieColonne[0][1]]).page(vehicule_fournisseur_table_page).draw(false);
                            } else {
                                vehicule_fournisseur_table.page(vehicule_fournisseur_table_page).draw(false);
                            }
                        }
                    });
                }
            })
        };

        // Pour garder l'ordre de tri des colonnes
        $('body').on('click', "th", function() {
            if (trieColonne[0] == vehicule_fournisseur_table.order()[0]) {
                if (trieColonne[0][1] == 'desc') {
                    trieColonne[0][1] = 'asc';
                } else if (trieColonne[0][1] == 'asc') {
                    trieColonne[0][1] = 'desc';
                }
            } else {
                trieColonne[0] = vehicule_fournisseur_table.order()[0];
            }
            vehicule_fournisseur_table.order.neutral();
            vehicule_fournisseur_table.order([1, "desc"]).order([trieColonne[0][0], trieColonne[0][1]]);
            vehicule_fournisseur_table.draw(false);
        });

        setupLoadAjax(loadAjax);
        setupLoadModele(loadModele);
        setupResetFilter(resetFilter);

        $('body').on('keyup', "[id^='filtersvehiculefournisseur-identifiant']", function () {
            clearTimeout(timerBeforeRefreshDataVehiculeFournisseur);
            timerBeforeRefreshDataVehiculeFournisseur = setTimeout(loadAjax({isPageReload: true}), 500);
        });

        $('body').on('change', "[id^='filtersvehiculefournisseur-']", function () {
            clearTimeout(timerBeforeRefreshDataVehiculeFournisseur);
            timerBeforeRefreshDataVehiculeFournisseur = setTimeout(loadAjax({isPageReload: true}), 500);
        });

        // Bouton reset
        $('body').on('click', '#filtersvehiculefournisseur-reset', function () {
            resetFilter();
            loadAjax({isPageReload: true});
        });

        $('body').on('click', "[id^='menu-vehicule-fournisseur-etat-']", function () {
            if (vehicule_fournisseur_table != null) {
                let etat = this.id.replace("menu-vehicule-fournisseur-etat-", "").replace(/-\d+/g, "");
                vehicule_fournisseur_table.rows().invalidate();
                let all_data = vehicule_fournisseur_table.rows().data();
                let id_vehicules = [];
                let id_vehicules_disabled = [];
                $.each(all_data, function (key, value) {
                    let idCheckBox = value[1]["@data-order"];
                    if ($("#selection_vehicule_fournisseur_" + idCheckBox).is(':checked')) {
                        id_vehicules.push(idCheckBox.replace("selection_vehicule_fournisseur_", ""));
                        if ($("#selection_vehicule_fournisseur_" + idCheckBox).data('isactif') == 0) {
                            id_vehicules_disabled.push(idCheckBox.replace("selection_vehicule_fournisseur_", ""));
                        }
                    }
                });
                // Si aucun véhicule est rentré
                if (id_vehicules.length == 0) {
                    toastr.error('Merci de sélectionner au moins un véhicule', 'Erreur');
                    return false;
                }
                // Si des véhicules disabled sont sélectionné
                if (id_vehicules_disabled.length > 0) {
                    toastr.error('Le' + id_vehicules_disabled.length > 1 ? "s": "" + ' véhicule' + id_vehicules_disabled.length > 1 ? "s ": " " + id_vehicules_disabled.join(',') + ' sont désactivés.', 'Erreur');
                    return false;
                }
                let form = new FormData();
                form.append('id_vehicules', id_vehicules);
                vfEtatModify(form, etat, true);
            }
        });

        $('body').on('click', "[id^='vehicule-fournisseur-etat-']", function () {
            let id = this.id.replace(/\w+-/g, "");
            let etat = this.id.replace("vehicule-fournisseur-etat-", "").replace(/-\d+/g, "");
            let form = new FormData();
            form.append('id_vehicules', [id]);
            vfEtatModify(form, etat);
        });

        $('body').on('click', "[id^='vehicule_fournisseur-enable-']", function () {
            let id_vehicule = this.id.replace(/\w+-/g, "");
            let form = new FormData();
            form.append('id_vehicule', id_vehicule);
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/vehicule_fournisseur/update/enable',
                contentType: false,
                processData: false,
                data: form,
                success: function (data) {
                    toastr.success('Véhicule fournisseur réactivé', 'Success');
                    loadAjax();
                    return false;
                },
                error: function () {
                    toastr.error('Erreur lors de la réactivation du véhicule fournisseur', 'Erreur');
                }
            });
        });

        $('body').on('click', "#menu-vehicule-fournisseur-enable", function () {
            vehicule_fournisseur_table.rows().invalidate();
            let all_data = vehicule_fournisseur_table.rows().data();
            let id_vehicules = [];
            $.each(all_data, function (key, value) {
                let idCheckBox = value[1]["@data-order"];
                if ($("#selection_vehicule_fournisseur_" + idCheckBox).is(':checked')) {
                    id_vehicules.push(idCheckBox.replace("selection_vehicule_fournisseur_", ""));
                }
            });
            // Si aucun véhicule est rentré
            if (id_vehicules.length == 0) {
                toastr.error('Merci de sélectionner au moins un véhicule', 'Erreur');
                return false;
            }
            let form = new FormData();
            form.append('id_vehicules', id_vehicules);
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/vehicule_fournisseur/update/enableSeveral',
                contentType: false,
                processData: false,
                data: form,
                success: function (data) {
                    if (data.split(',').length > 1) {
                        toastr.success('Véhicules fournisseur réactivés', 'Success');
                    } else {
                        toastr.success('Véhicule fournisseur réactivé', 'Success');
                    }
                    loadAjax();
                    return false;
                },
                error: function () {
                    toastr.error('Erreur lors de la réactivation des véhicules fournisseur', 'Erreur');
                }
            });
        });

        $('body').on('click', "#menu-vehicule-fournisseur-delete", function () {
            if (vehicule_fournisseur_table != null) {
                Lobibox.confirm({
                    title: 'Suppression',
                    baseClass: "modal-content modal-lobistrap",
                    msg: "Êtes-vous sûr de vouloir supprimer cet élément <strong>définitivement</strong> de la base de données ? Attention, il sera <strong>impossible</strong> de le restaurer ensuite.",
                    buttons: {
                        cancel: {
                            type: 'ok',
                            text: 'Supprimer'
                        },
                        ok: {
                            type: 'cancel',
                            text: 'Conserver'
                        }
                    },
                    callback: function (lobibox, type) {
                        if (type === 'cancel') {
                            vehicule_fournisseur_table.rows().invalidate();
                            let all_data = vehicule_fournisseur_table.rows().data();
                            let id_vehicules = [];
                            $.each(all_data, function (key, value) {
                                let idCheckBox = value[1]["@data-order"];
                                if ($("#selection_vehicule_fournisseur_" + idCheckBox).is(':checked')) {
                                    id_vehicules.push(idCheckBox.replace("selection_vehicule_fournisseur_", ""));
                                }
                            });
                            // Si aucun véhicule est rentré
                            if (id_vehicules.length == 0) {
                                toastr.error('Merci de sélectionner au moins un véhicule', 'Erreur');
                                return false;
                            }
                            let form = new FormData();
                            form.append('id_vehicules', id_vehicules);
                            $.ajax({
                                type: 'POST',
                                url: siteURL + 'admin/vehicule_fournisseur/update/deleteSeveral',
                                contentType: false,
                                processData: false,
                                data: form,
                                success: function (data) {
                                    if (data.split(',').length > 1) {
                                        toastr.success('Véhicules fournisseur désactivés', 'Success');
                                    } else {
                                        toastr.success('Véhicule fournisseur désactivé', 'Success');
                                    }
                                    loadAjax();
                                    return false;
                                },
                                error: function () {
                                    toastr.error('Erreur lors de la désactivation des véhicules fournisseur', 'Erreur');
                                }
                            });
                        }
                    },
                });
            }
        });

        function resetFilter() {
            $("#filtersvehiculefournisseur-identifiant").val('').selectpicker("refresh");
            $("#filtersvehiculefournisseur-marque").val('default').selectpicker("refresh");
            $("#filtersvehiculefournisseur-modele").find("optgroup").prop('disabled', true);
            $('#filtersvehiculefournisseur-modele').prop("disabled", true);
            $("#filtersvehiculefournisseur-modele").val('default').selectpicker('refresh');
            $("#filtersvehiculefournisseur-statut").val('default').selectpicker("refresh");
            $("#filtersvehiculefournisseur-is_verif").val('default').selectpicker("refresh");
            $("#filtersvehiculefournisseur-active").val('off').prop("checked", false);
            $("#filtersvehiculefournisseur-createur").val('default').selectpicker("refresh");
            $("#filtersvehiculefournisseur-fournisseur").val('default').selectpicker("refresh");
            $("#filtersvehiculefournisseur-is_envoi").val('default').selectpicker("refresh");
            $("#filtreConfigSelect-filtreConfig-aucun").val('aucun').prop("selected", true);
        }

        if (idannuairecreationdepuisannuairefournisseur != "") {
            loadAjax({ idannuairecreationdepuisannuairefournisseur: idannuairecreationdepuisannuairefournisseur });
        } else {
            filtreConfigFunOnEvent();
        }
    }
});

// SELECT and DESELECT
function checkVehiculeFournisseurTab(evt) {
    if (evt.stopPropagation !== undefined) {
        // evt.preventDefault();
        $("[id^='selection_vehicule_fournisseur_']").each(function () {
            if ($("#selection_vehicule_fournisseur").is(':checked')) {
                $(this).prop("checked", true);
            } else {
                $(this).prop("checked", false);
            }
        });
        evt.stopPropagation();
    } else {
        evt.cancelBubble = true;
    }
}

// SELECT and DESELECT
function checkVehiculeFournisseur(evt) {
    if (evt.stopPropagation !== undefined) {
        evt.stopPropagation();
    } else {
        evt.cancelBubble = true;
    }
}

function loadModele() {
    $("#filtersvehiculefournisseur-modele").find("optgroup").prop('disabled', true);
    if ($('#filtersvehiculefournisseur-marque').val().length != 0) {
        $("#filtersvehiculefournisseur-modele").prop('disabled', false);
        $('#filtersvehiculefournisseur-marque').val().forEach(element => { $("[id='opt_" + element + "']").prop('disabled', false); });
        $("[id^='opt_']").each(function () {
            let marqueEnCours = $(this).attr('id').replace('opt_', '');
            if (!$('#filtersvehiculefournisseur-marque').val().includes(marqueEnCours)) {
                $(this).prop('disabled', true);
            }
        })
        $("#filtersvehiculefournisseur-modele").selectpicker('refresh');
    } else {
        $('#filtersvehiculefournisseur-modele').prop("disabled", true);
        $("#filtersvehiculefournisseur-modele").val('default').selectpicker('refresh');
    }
}

function loadVF() {
    loadAjax();
}