$(document).ready(function () {
    if ($("#annuaire_results").length) {
        function loadAjax() {
            let idAnnuaire = this.id.replace("annuaire-sub-tab-fichiers-", "");
            let form = new FormData();
            form.append('idAnnuaire', idAnnuaire);
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/annuaire/annuaires/fichiers',
                contentType: false,
                processData: false,
                data: form,
                success: function (data) {
                    if (data == null) {
                        toastr.error('Erreur lors du chargement', 'Erreur');
                    } else {
                        $('#annuaire_fichiers-document-list-' + idAnnuaire).html(data);
                    }
                }
            });
        }

        $('body').on('click', "[id^='annuaire-sub-tab-fichiers-']", loadAjax);

        $('body').on('change', "[id^='annuaire_fichiers-create-']", function (e) {
            let fichierEtIdAnnuaire = this.id.replace("annuaire_fichiers-create-", "");
            let fichier = fichierEtIdAnnuaire.split('-')[0];
            let idAnnuaire = fichierEtIdAnnuaire.split('-')[1];
            let input = $(this);
            const [file] = this.files;
            let extensionFichier = [file][0]["name"].match(/\.(jpg|jpeg|png|pdf)/g)[0];
            if (file) {
                input.prop('disabled', true);
                let form = new FormData();
                form.append('data', input[0].files[0]);
                form.append('filemime', input.data("mime"));
                form.append('filetypes', input.data("filetypes"));
                form.append('imageratio', input.data("imageRatio"));
                form.append("fichier", fichier);
                form.append("extensionFichier", extensionFichier);
                form.append("idAnnuaire", idAnnuaire);
                $.ajax({
                    url: 'file/upload',
                    type: 'POST',
                    cache: false,
                    contentType: false,
                    processData: false,
                    data: form,
                    success: function (data) {
                        console.log("data", data);
                        // success == true
                        if (data.success == true) {
                            toastr.success('Le fichier a bien été enregistré.', 'Success');
                            // On enregistre l'id du fichier sur l'annuaire
                            let formSaveFichierAnnuaire = new FormData();
                            formSaveFichierAnnuaire.append('idAnnuaire', idAnnuaire);
                            formSaveFichierAnnuaire.append('idFichier', data.id);
                            formSaveFichierAnnuaire.append('typeFichier', fichier);
                            $.ajax({
                                url: siteURL + 'admin/annuaire/annuaires/fichier/save',
                                type: 'POST',
                                cache: false,
                                contentType: false,
                                processData: false,
                                data: formSaveFichierAnnuaire,
                                success: function (data) {
                                    if (data.success == true) {
                                        $("#annuaire-sub-tab-fichiers-" + idAnnuaire).trigger('click');
                                    } else {
                                        toastr.error('Erreur lors de l\'attribution du fichier à l\'annuaire.', 'Erreur');
                                    }
                                },
                            });
                        }
                        // success == false
                        else {
                            if (data.error == 'exists') {
                                toastr.error('Erreur lors de l\'enregistrement, le fichier existe déjà.', 'Erreur');
                            } else if (data.error == 'type') {
                                toastr.error('Erreur lors de l\'enregistrement, le type de fichier n\'est pas accepté.', 'Erreur');
                            } else if (data.error == 'ratio') {
                                toastr.error('Erreur lors de l\'enregistrement, le ratio de taille du fichier n\'est pas accepté.', 'Erreur');
                            }
                        }
                    },
                });
            }
        });

        $('body').on('click', "[id^='annuaire_fichiers-document-item-btn-suppression-']", function () {
            let fichierEtIdAnnuaire = this.id.replace("annuaire_fichiers-document-item-btn-suppression-", "");
            let typeFichier = fichierEtIdAnnuaire.split('-')[0];
            let idAnnuaire = fichierEtIdAnnuaire.split('-')[1];

            let form = new FormData();
            form.append('idAnnuaire', idAnnuaire);
            form.append('typeFichier', typeFichier);
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/annuaire/annuaires/fichier/delete',
                contentType: false,
                processData: false,
                data: form,
                success: function (data) {
                    if (data == null) {
                        toastr.error('Erreur lors du chargement', 'Erreur');
                    } else {
                        toastr.success('Le fichier a bien été supprimé.', 'Success');
                        $('#modale-suppression-fichier-' + typeFichier + '-' + idAnnuaire).modal('hide');
                        $("#annuaire-sub-tab-fichiers-" + idAnnuaire).trigger('click');
                    }
                }
            });

        });

        $('body').on('click', ".annuaire_fichiers-document-item-tools-item", function () {
            const tool = $(this).data("tool");
            const infos = $(this).data("infos");
            const uid = $(this).data("uid");
            if (tool == "print") {
                let element = document.createElement('a');
                element.setAttribute('href', infos.printUrl);
                element.setAttribute('download', "");
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);
            } else if (tool == "view") {
                Lobibox.window({
                    title: 'Prévisualisation',
                    baseClass: "modal-content modal-lobistrap",
                    content: '<embed src="' + infos.printUrl + '" width="100%" height="100%" type="application/pdf">'
                });
            }
        });
    }
});