let raccourcis_table = null;

$(document).ready(function() {
    if ($("#raccourcis_results").length) {

        function loadAjax() {
            $("#raccourcis_results").addClass("d-none");
            $.ajax({
                type:'POST',
                url: siteURL + 'admin/configuration/raccourcis_ajax',
                contentType: false,
                processData: false,
                data: {},
                success: function(data){
                    $("#raccourcis_results").html(data);
                    raccourcis_table = $('#raccourcis_table').DataTable({
                        "autoWidth": false,
                        "language": {
                            "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/fr-FR.json"
                        },
                        "paging": false,
                        "responsive": true,
                        "order": [
                            [0, "asc"]
                        ],
                        "columnDefs": [],
                        "conditionalPaging": true,
                    });
                    $('#raccourcis_table').on('draw.dt', function () {
                        $("#raccourcis_results").removeClass("d-none");
                    });
                }
            })
        };

        // Envoi de modification d'un raccourcis
        $('body').on('click', "button[id^='raccourcis_opt-send-modify-']", function(e) {
            e.preventDefault();
            let id_clicked = this.id.replace('raccourcis_opt-send-modify-', '');
            let raccourcis_nom = $('#raccourcis_nom_'+id_clicked).val();
            let raccourcis_url = $('#raccourcis_url_'+id_clicked).val();
            let raccourcis_icone = $('#raccourcis_icone_'+id_clicked).val();
            let raccourcis_ordre = $('#raccourcis_ordre_'+id_clicked).val();
            let raccourcis_color = $('#raccourcis_color_'+id_clicked).val();
            let raccourcis_nouvelOnglet = ($('#raccourcis_nouvelOnglet_'+id_clicked).val() == "on" ? "1" : "0");
            let error_message = "";
            if (raccourcis_nom.length == 0) {
                error_message += "Le nom du raccourcis n\'a pas été renseigné.<br/>"
            }
            if (raccourcis_url.length == 0) {
                error_message += "L'URL du raccourcis n\'a pas été renseigné.<br/>"
            }
            if (error_message != "") {
                toastr.error(error_message, 'Erreur');
            } else {
                let form = new FormData();
                form.append('id', id_clicked);
                form.append('raccourcis_nom', raccourcis_nom);
                form.append('raccourcis_url', raccourcis_url);
                form.append('raccourcis_icone', raccourcis_icone);
                form.append('raccourcis_ordre', raccourcis_ordre);
                form.append('raccourcis_color', raccourcis_color);
                form.append('raccourcis_nouvelOnglet', raccourcis_nouvelOnglet);
                $.ajax({
                    type: 'POST',
                    url: siteURL + 'admin/configuration/raccourcis_opt/modify',
                    contentType: false,
                    processData: false,
                    data: form,
                    success: function() {
                        toastr.success('Le raccourcis a bien été enregistrée', 'Succès');
                        $.ajax({
                            type: 'POST',
                            url: siteURL + 'admin/configuration/raccourcis_opt/loadAdd',
                            data: {},
                            success: function(data) {
                                $("#raccourcis_opt-tabs-add").html(data);
                            }
                        });
                        $.ajax({
                            type: 'POST',
                            url: siteURL + 'admin/configuration/raccourcis_opt/majRaccourcis',
                            data: {},
                            success: function(data) {
                                $("#nav-raccourcis-list").html(data);
                            }
                        });
                        $('#raccourcis_opt-tabs-modify-'+id_clicked).remove();
                        $('#raccourcis_opt-close-modify-'+id_clicked).parent().remove();
                        $('#raccourcis_opt-tabs-list-tab').trigger('click');
                    },
                });
            }
        });

        // Bouton refresh
        $('body').on('click', "#raccourcis_opt-tabs-list-tab", function() {
            loadAjax();
        });

        loadAjax();
    }
});