$(document).ready(function() {  
 
    $('.button-filter').on('click', function(e) {
        e.preventDefault();
  
        $('#ajax-content').html('&nbsp;');
 
        let periodeInput = $('input[name=periodeCmde]');
        let periode = periodeInput.val();

        let id_vue = $(this).attr('data-vue');
        let cat = $(this).attr('data-cat');
        let modele = $('input[name=modeles]:checked').val(); 
        let params = [];

        if ( id_vue == 1 ) {

            if (!periode) {
                periodeInput.css('background-color','rgba(255, 87, 87, .5)');
                periodeInput.css('border','1px solid red');
                periodeInput.focus(); 
                toastr.error('Merci de saisir une période de commande', 'Erreur');
                return false;
            } else {
                periodeInput.css('background-color','#fff');
                periodeInput.css('border','1px solid #ced4da');
            }  
    
            if (!modele) {
                toastr.error('Merci de sélectionner un modèle : A PARTICULIER ou A MARCHAND', 'Erreur'); 
                $('.btn-group-toggle').css('border','2px solid #ff5757'); 
                $('.btn-group-toggle').css('border-radius','5px');  
                return false;  
            } else {
                $('.btn-group-toggle').css('border','0'); 
            }
 
            params = [
                ["modele",      modele],
                ["vue",         id_vue], 
                ["cat",         cat],
                ["client",      $('#filtersfactures-15').val()],     
                ["regle",       $('#filtersfactures-16').val()],   
                ["livre",       $('#filtersfactures-17').val()],   
                ["site",        $('#filtersfactures-18').val()], 
                ["vendeur",     $('#filtersfactures-19').val()], 
                ["tva",         $('#filtersfactures-110').val()],
                ["finance",     $('#filtersfactures-111').val()],
                ["searchv",     $('#filtersfactures-112').val()],
                ["searchc",     $('#filtersfactures-113').val()],
                ["periodec",    $('#filtersfactures-114').val()],
                ["periodef",    $('#filtersfactures-115').val()],
                ["emplorig",    $('#filtersfactures-116').val()],
                ["jourstock",   $('#filtersfactures-117').val()],
                ["facture",     $('#filtersfactures-118').val()]
            ]; 
        } else {
            params = [
                ["modele",      modele],
                ["vue",         id_vue], 
                ["cat",         cat], 
                ["site",        $('#filtersfactures-21').val()], 
                ["vendeur",     $('#filtersfactures-22').val()],
                ["annee",       $('#filtersfactures-23').val()],
                ["prix",        $('#filtersfactures-24').val()], 
                ["type",        $('#filtersfactures-25').val()]
            ];
        }
        showResults(id_vue, cat, params); 
    });

    $('#btnExportPDF').on('click', function(e) {
        e.preventDefault();
        let id_vue = $(this).attr('data-vue');
        let cat = $(this).attr('data-cat');
        let modele = $('input[name=modeles]:checked').val();
        let params = false; 
        if ( id_vue == 1 ) {
            params = {
                'modele':       modele,
                'vue':          id_vue,
                'cat':          cat,
                'client':       $('#filtersfactures-15').val(),
                'regle':        $('#filtersfactures-16').val(),
                'livre':        $('#filtersfactures-17').val(),
                'site':         $('#filtersfactures-18').val(),
                'vendeur':      $('#filtersfactures-19').val(),
                'tva':          $('#filtersfactures-110').val(),
                'finance':      $('#filtersfactures-111').val(),
                'searchv':      $('#filtersfactures-112').val(),
                'searchc':      $('#filtersfactures-113').val(),
                'periodec':     $('#filtersfactures-114').val(),
                'periodef':     $('#filtersfactures-115').val()
            };
        } else {
            params = {
                'modele':   modele,
                'vue':      id_vue,
                'cat':      cat,
                'site':     $('#filtersfactures-21').val(),
                'vendeur':  $('#filtersfactures-22').val(),
                'annee':    $('#filtersfactures-23').val(),
                'prix':     $('#filtersfactures-24').val(),
                'type':     $('#filtersfactures-25').val()
            };
        }
        exportPDF(params);
    });

    $('#btnExportExcel').on('click', function(e) {
        e.preventDefault();
        let id_vue = $(this).attr('data-vue');
        let cat = $(this).attr('data-cat');
        let modele = $('input[name=modeles]:checked').val();
        let params = false; 
        if ( id_vue == 1 ) {
            params = {
                'modele':       modele,
                'vue':          id_vue,
                'cat':          cat,
                'client':       $('#filtersfactures-15').val(),
                'regle':        $('#filtersfactures-16').val(),
                'livre':        $('#filtersfactures-17').val(),
                'site':         $('#filtersfactures-18').val(),
                'vendeur':      $('#filtersfactures-19').val(),
                'tva':          $('#filtersfactures-110').val(),
                'finance':      $('#filtersfactures-111').val(),
                'searchv':      $('#filtersfactures-112').val(),
                'searchc':      $('#filtersfactures-113').val(),
                'periodec':     $('#filtersfactures-114').val(),
                'periodef':     $('#filtersfactures-115').val()
            };
        } else {
            params = {
                'modele':   modele,
                'vue':      id_vue,
                'cat':      cat,
                'site':     $('#filtersfactures-21').val(),
                'vendeur':  $('#filtersfactures-22').val(),
                'annee':    $('#filtersfactures-23').val(),
                'prix':     $('#filtersfactures-24').val(),
                'type':     $('#filtersfactures-25').val()
            };
        } 
        exportEXCEL(params);
    });

    $('.button-reset').on('click', function(e) {
        e.preventDefault();
        window.location.href = $("#uri").val();
    });

    let startdate = moment().startOf("month");
    let enddate = moment().endOf("month"); 
    let agodate = new Date(startdate); 
    agodate.setMonth(agodate.getMonth() - 3); 

    $('#filtersfactures-114').daterangepicker({
        startDate: agodate.toLocaleDateString(),
        endDate: enddate,
        "locale": {
            "format": "DD/MM/YYYY",
            "separator": " - ",
            "applyLabel": "Valider",
            "cancelLabel": "Effacer",
            "fromLabel": "Du",
            "toLabel": "au",
            "customRangeLabel": "Custom",
            "daysOfWeek": [
                "Di",
                "Lu",
                "Ma",
                "Me",
                "Je",
                "Ve",
                "Sa"
            ],
            "monthNames": [
                "Janvier",
                "Février",
                "Mars",
                "Avril",
                "Mai",
                "Juin",
                "Juillet",
                "Août",
                "Septembre",
                "Octobre",
                "Novembre",
                "Décembre"
            ],
            "firstDay": 1
        }
    }); 

    $('#filtersfactures-115').daterangepicker({
        autoUpdateInput: false,
        "locale": {
            "format": "DD/MM/YYYY",
            "separator": " - ",
            "applyLabel": "Valider",
            "cancelLabel": "Effacer",
            "fromLabel": "Du",
            "toLabel": "au",
            "customRangeLabel": "Custom",
            "daysOfWeek": [
                "Di",
                "Lu",
                "Ma",
                "Me",
                "Je",
                "Ve",
                "Sa"
            ],
            "monthNames": [
                "Janvier",
                "Février",
                "Mars",
                "Avril",
                "Mai",
                "Juin",
                "Juillet",
                "Août",
                "Septembre",
                "Octobre",
                "Novembre",
                "Décembre"
            ],
            "firstDay": 1
        }
    });

    $('.applyBtn').removeClass('btn-success').addClass('btn-primary');
  
    $('#filtersfactures-115').on('apply.daterangepicker', function(ev, picker) {
        $(this).val(picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY'));
    });

    $('#filtersfactures-114, #filtersfactures-115').on('cancel.daterangepicker', function(ev, picker) {
        $(this).val('');
    }); 

});

function showResults(id_vue, cat, params) { 
    if (!id_vue || !cat)
        return false; 
    $('#datasFactures').addClass('d-none');
    $('#datasVentes-ma').addClass('d-none');
    $('#datasVentes-fi').addClass('d-none');
    $('#datasVentes-ca').addClass('d-none'); 
    switch (id_vue) {
        case "1":
            loadAjaxContent('facture-ajax', params);
            break; 
        case "2": 
            if (cat == "ma") loadAjaxContent('marge-ajax', params);
            if (cat == "fi") loadAjaxContent('financement-ajax', params);
            if (cat == "ca") loadAjaxContent('chiffreaffaire-ajax', params); 
            break;
        default:
            return true;
    } 
    return true;
}

function exportEXCEL(params) {
    if(!params)
        return false; 
    let vue = params['vue'];
    let cat = params['cat']; 
    if (!vue || !cat)
        return false;   
    
    let label = ''; 
    if (vue == 1) {
        label = 'Factures';
    } else {
        if (cat == 'ma') label = 'Marge';
        if (cat == 'fi') label = 'Financement';
        if (cat == 'ca') label = 'Chiffre d\'affaire';
    }  

    let today = new Date();
        today = today.toLocaleDateString("fr");
        today = today.replaceAll('/',''); 
    let modele = params['modele']; 
    let periodec = params['periodec']; 
    if (periodec != undefined) { 
        periodec = periodec.replaceAll('/', '');
        periodec = periodec.replaceAll(' - ', '');
    } 
    let periodef = params['periodef'];
    if (periodef != undefined) { 
        periodef = periodef.replaceAll('/', '');
        periodef = periodef.replaceAll(' - ', '');
    }  
    let pdfFile = excelFile = ""; 
    let form = new FormData();
    if (vue == 1) form.append('modele', modele);
    form.append('vue', vue);
    form.append('cat', cat); 
    if (vue == 1) { 
        form.append('client', params['client']);
        form.append('regle', params['regle']);
        form.append('livre', params['livre']);
        form.append('site', params['site']);
        form.append('vendeur', params['vendeur']); 
        form.append('tva', params['tva']); 
        form.append('finance', params['finance']); 
        form.append('searchv', params['searchv']); 
        form.append('searchc', params['searchc']); 
        form.append('periodec', params['periodec']); 
        form.append('periodef', params['periodef']); 
        excelFile = "recap_"+modele+vue+cat+params['client']+params['regle']+params['livre']+params['site']+params['vendeur']+params['tva']+params['finance']+params['searchv']+params['searchc']+periodec+periodef+"_"+today+".xlsx";        ;
    } else { 
        form.append('emporig', params['site']);
        form.append('vendeur', params['vendeur']);
        form.append('annee', params['annee']);
        form.append('prix', params['prix']);
        form.append('type', params['type']);
        excelFile = "recap_"+vue+cat+params['site']+params['vendeur']+params['annee']+params['prix']+params['type']+"_"+today+".xlsx";
    } 
    $.ajax({ 
        type:'POST',
        url: siteURL + 'admin/recap/factures/exportexcel/'+vue,
        contentType: false,
        processData: false,
        data: form,
        success: function(data){
            toastr.success('Fichier EXCEL exporté avec succès !', 'Succès');
            var element = document.createElement('a');
            element.setAttribute('href', siteURL + "assets/docs/recap_factures/"+excelFile);
            element.setAttribute('download', "Export EXCEL Recap "+label+" "+today+".xlsx");
            document.getElementById('ajaxPDF').appendChild(element);
            element.click();
            document.getElementById('ajaxPDF').removeChild(element);
        }
    });
}

function exportPDF(params) { 
    if(!params)
        return false; 
    let vue = params['vue'];
    let cat = params['cat']; 
    if (!vue || !cat)
        return false;   
    
    let label = ''; 
    if (vue == 1) {
        label = 'Factures';
    } else {
        if (cat == 'ma') label = 'Marge';
        if (cat == 'fi') label = 'Financement';
        if (cat == 'ca') label = 'Chiffre d\'affaire';
    }  

    let today = new Date();
        today = today.toLocaleDateString("fr");
        today = today.replaceAll('/',''); 
    let modele = params['modele']; 
    let periodec = params['periodec']; 
    if (periodec != undefined) { 
        periodec = periodec.replaceAll('/', '');
        periodec = periodec.replaceAll(' - ', '');
    } 
    let periodef = params['periodef'];
    if (periodef != undefined) { 
        periodef = periodef.replaceAll('/', '');
        periodef = periodef.replaceAll(' - ', '');
    }  
    let pdfFile = ""; 
    let form = new FormData();
    if (vue == 1) form.append('modele', modele);
    form.append('vue', vue);
    form.append('cat', cat); 
    if (vue == 1) { 
        form.append('client', params['client']);
        form.append('regle', params['regle']);
        form.append('livre', params['livre']);
        form.append('site', params['site']);
        form.append('vendeur', params['vendeur']); 
        form.append('tva', params['tva']); 
        form.append('finance', params['finance']); 
        form.append('searchv', params['searchv']); 
        form.append('searchc', params['searchc']); 
        form.append('periodec', params['periodec']); 
        form.append('periodef', params['periodef']); 
        pdfFile = "recap_"+modele+vue+cat+params['client']+params['regle']+params['livre']+params['site']+params['vendeur']+params['tva']+params['finance']+params['searchv']+params['searchc']+periodec+periodef+"_"+today+".pdf";
    } else { 
        form.append('emporig', params['site']);
        form.append('vendeur', params['vendeur']);
        form.append('annee', params['annee']);
        form.append('prix', params['prix']);
        form.append('type', params['type']);
        pdfFile = "recap_"+vue+cat+params['site']+params['vendeur']+params['annee']+params['prix']+params['type']+"_"+today+".pdf";
    }
    $.ajax({ 
        type:'POST',
        url: siteURL + 'admin/recap/factures/exportpdf/'+vue,
        contentType: false,
        processData: false,
        data: form,
        success: function(data){
            toastr.success('Fichier PDF exporté avec succès !', 'Succès');
            var element = document.createElement('a');
            element.setAttribute('href', siteURL + "assets/docs/recap_factures/"+pdfFile);
            element.setAttribute('download', "Export PDF Recap "+label+" "+today+".pdf");
            document.getElementById('ajaxPDF').appendChild(element);
            element.click();
            document.getElementById('ajaxPDF').removeChild(element);
        }
    });
} 

function loadAjaxContent(url, params) { 
    if (!url)
        return false;  
    let form = new FormData(); 
    params.forEach(function (element, index) {
        let cle = element[0];
        let valeur = element[1];
        form.append(cle, valeur); 
    }); 
    $.ajax({ 
        type:'POST',
        url: siteURL + 'admin/recap/factures/' + url,
        contentType: false,
        processData: false,
        data: form,
        success: function(data){
            $('#ajax-content').prepend('<div id="loader"><svg class="car" width="102" height="40" xmlns="http://www.w3.org/2000/svg"> <g transform="translate(2 1)" stroke="#002742" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"> <path class="car__body" d="M47.293 2.375C52.927.792 54.017.805 54.017.805c2.613-.445 6.838-.337 9.42.237l8.381 1.863c2.59.576 6.164 2.606 7.98 4.531l6.348 6.732 6.245 1.877c3.098.508 5.609 3.431 5.609 6.507v4.206c0 .29-2.536 4.189-5.687 4.189H36.808c-2.655 0-4.34-2.1-3.688-4.67 0 0 3.71-19.944 14.173-23.902zM36.5 15.5h54.01" stroke-width="3"/> <ellipse class="car__wheel--left" stroke-width="3.2" fill="#FFF" cx="83.493" cy="30.25" rx="6.922" ry="6.808"/> <ellipse class="car__wheel--right" stroke-width="3.2" fill="#FFF" cx="46.511" cy="30.25" rx="6.922" ry="6.808"/> <path class="car__line car__line--top" d="M22.5 16.5H2.475" stroke-width="3"/> <path class="car__line car__line--middle" d="M20.5 23.5H.4755" stroke-width="3"/> <path class="car__line car__line--bottom" d="M25.5 9.5h-19" stroke-width="3"/> </g> </svg></div>');
            setTimeout(() => {
                    let loader = document.getElementById("loader");
                    loader.style.transition = "opacity " + 1 + "s";
                    loader.style.opacity = 0;
                    loader.addEventListener("transitionend", function() {
                        loader.style.display = "none";
                        $('#ajax-content').html(data);
                        $('.ajaxDatas').removeClass('d-none'); 
                        initEditCells();

                        var table = $('#recapfactures_table');
                        if (table.length>0) {
                            table.DataTable({  
                                "fixedHeader": true, 
                                "order": [[ 0, 'desc' ]], 
                                "searching": true,
                                "responsive": {
                                    details: false
                                }, 
                                "pageLength": 50,
                                "drawCallback": function( settings ) {
                                    $('#recapfactures_table th.sorting').css('width',''); 
                                    $('#recapfactures_table th.sorting').css('display','');
                                    $('#recapfactures_table td').css('display',''); 
                                    $('div.dataTables_wrapper div.dataTables_paginate ul.pagination').css('justify-content','flex-start');
                                    $('.dataTables_filter').css('text-align','left');
                                    $('.wrapper2').scrollLeft($('.wrapper1').scrollLeft());
                                    $('.wrapper1').scrollLeft($('.wrapper2').scrollLeft()); 
                                    $('.wrapper1').on('scroll', function (e) {
                                        $('.wrapper2').scrollLeft($('.wrapper1').scrollLeft()); 
                                        $('.fixedHeader-floating').css('left', '-' + ($('.wrapper1').scrollLeft()==0 ? 0 : ($('.wrapper1').scrollLeft() +25)) + 'px');
                                    }); 
                                    $('.wrapper2').on('scroll', function (e) {
                                        $('.wrapper1').scrollLeft($('.wrapper2').scrollLeft());
                                        $('.fixedHeader-floating').css('left', '-' + ($('.wrapper2').scrollLeft()==0 ? 0 : ($('.wrapper2').scrollLeft() +25)) + 'px');
                                    });
                                    $('.div1').width($('table').width());
                                    $('.div2').width($('table').width());

                                    $('table').width($('table').width() -25);

                                    // let nbRows = $('#recapfactures_table').find('tbody').find('tr').length; 
                                    // $('#datasFactures').find('h4').html(nbRows + ' résultat' + (nbRows > 0 ? 's' : ''));

                                },
                                "dom": '<"filters"pfl><t><"filters"pfl>',
                                "language": {
                                    "search": "Rechercher",
                                    "lengthMenu": "Afficher _MENU_ vente(s) par page",
                                    "zeroRecords": "Pas de vente(s) - sorry",
                                    "info": "Page _PAGE_ à _PAGES_",
                                    "infoEmpty": "Pas de données disponibles",
                                    "infoFiltered": "(filtrer par _MAX_ vente(e))",
                                    "loadingRecords": "Chargement ...",
                                    "paginate": {
                                        "first":      "Premier",
                                        "last":       "Dernier",
                                        "next":       "Suivant",
                                        "previous":   "Précédent"
                                    },
                                }
                            }); 

                            $('#recapfactures_table th.sorting').css('width',''); 
                            $('#recapfactures_table th.sorting').css('display','');
                            $('#recapfactures_table td').css('display',''); 
                            $('div.dataTables_wrapper div.dataTables_paginate ul.pagination').css('justify-content','flex-start'); 
                            $('.dataTables_filter').css('text-align','left');

                            $('.wrapper1').on('scroll', function (e) {
                                $('.wrapper2').scrollLeft($('.wrapper1').scrollLeft()); 
                                $('.fixedHeader-floating').css('left', '-' + ($('.wrapper1').scrollLeft()==0 ? 0 : ($('.wrapper1').scrollLeft() +25)) + 'px');
                            }); 
                            $('.wrapper2').on('scroll', function (e) {
                                $('.wrapper1').scrollLeft($('.wrapper2').scrollLeft()); 
                                $('.fixedHeader-floating').css('left', '-' + ($('.wrapper2').scrollLeft()==0 ? 0 : ($('.wrapper2').scrollLeft() +25)) + 'px');
                            });
                            $('.div1').width($('table').width());
                            $('.div2').width($('table').width());

                            $('table').width($('table').width() -25);
} 
                    });  
                }, 5000
            );
        }
    }); 
}

function initEditCells() {
    var btnFiltre = document.getElementById('btnFiltre');
    $("td.edit").click(function () {
        var OriginalContent = $(this).text();
        $(this).addClass("cellEditing");
        $(this).html("<span><input type='text' value='"+OriginalContent+"' /></span>");
        $(this).children().find('input').focus();
        $(this).children().find('input').keypress(function (e) {
            if (e.which == 13) {
                var newContent = $(this).val(); 
                var id = $(this).closest('td').attr('data-idfacturation');
                var field = $(this).closest('td').attr('data-field');
                var value = newContent;   
                setDatasAjax(id, field, value); 
                if ( newContent == "" ) {
                    $(this).parent().html("<span><i class='fas fa-pen'></i></span>");
                } else {  
                    $(this).parent().text(newContent);
                }
                $(this).parent().removeClass("cellEditing"); 
                setTimeout(() => {
                        btnFiltre.click();
                    }, 1000
                ); 
            }
        });
        $(this).children().find('input').blur(function(){
            var newContent = $(this).val(); 
            var id = $(this).closest('td').attr('data-idfacturation');
            var field = $(this).closest('td').attr('data-field');
            var value = newContent;   
            setDatasAjax(id, field, value); 
            if ( newContent == "" ) {
                $(this).parent().html("<span><i class='fas fa-pen'></i></span>");
            } else {  
                $(this).parent().text(newContent);
            }
            $(this).parent().removeClass("cellEditing");
            setTimeout(() => {
                btnFiltre.click();
            }, 1000
        ); 
        });
        $(this).find('input').click(function(e){
            e.stopPropagation(); 
        });
    });
}

function setDatasAjax(id, field, value) { 
    if (!id || !field)
        return false; 
    let form = new FormData();
    form.append('idFacturation', id);
    form.append('field', field);
    form.append('value', value); 
    $.ajax({ 
        type:'POST',
        url: siteURL + 'admin/recap/factures/savedatas-ajax',
        contentType: false,
        processData: false,
        data: form,
        success: function(data){
            // OK
        }
    }); 
}