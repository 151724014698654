let currentWidgetFunctionId;
let functionWidgetRequeteAjax = [];
// Mettre dans l'ordre (le plus haut = le premier) l'exécution des requêtes Ajax pour les widgets sur le dashboard
$(document).ready(function () {
    currentWidgetFunctionId = 0;
    // Tableau contenant les fonctions de requête Ajax des widgets
    // Bien mettre les fonctions à charger en première tout en haut
    functionWidgetRequeteAjax = [
        widgetMajPrixAjax,
        widgetTauxRemplissageParcAjax,
        widgetActionPrepaAjax,
        widgetRappelAgendaAjax,
        widgetLoadKpiMarchand,
        widgetActionPrixAjax,
    ];

    if ($("#dashboard-widget").length) {
        executeWidgetRequeteAjax();
    }
});

function executeWidgetRequeteAjax() {
    if (typeof functionWidgetRequeteAjax[currentWidgetFunctionId] == "function") {
        functionWidgetRequeteAjax[currentWidgetFunctionId]();
    } else if (currentWidgetFunctionId < functionWidgetRequeteAjax.length) {
        nextWidgetRequeteAjax();
    }
}

function nextWidgetRequeteAjax() {
    currentWidgetFunctionId++;
    if (currentWidgetFunctionId < functionWidgetRequeteAjax.length) {
        executeWidgetRequeteAjax();
    }
}