$(document).ready(function () {
    if ($("#annuaire_results").length) {
        // Update Espace Presta date de validation
        $('body').on('click', "[id^='annuaire_boutiquePresta_isActiv_']", function (e) {
            let idAnnuaire = this.id.replace("annuaire_boutiquePresta_isActiv_", "");
            let input = $(this);
            input.prop('disabled', true);
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/annuaire/espacePresta/activation',
                data: { idAnnuaire: idAnnuaire, activation: this.value },
                success: function (data) {
                    input.prop('disabled', false);
                    if (input.val() == "on") {
                        toastr.success('L\'espace marchand a bien été activé', 'Success');
                        $("#annuaire-side-stats-" + idAnnuaire + "-isActiv").html("<i class='far fa-check text-success'></i> Actif");
                    } else {
                        toastr.success('L\'espace marchand a bien été désactivé', 'Success');
                        $("#annuaire-side-stats-" + idAnnuaire + "-isActiv").html("<i class='far fa-times text-danger'></i> Inactif");
                    }
                    if (data) {
                        $("#annuaire-side-stats-" + idAnnuaire + "-datePremiereValidation").html((new Date(Date.now())).toLocaleString('fr-FR', { timeZone: 'Europe/Paris' }).replace(/(.*):\d{2}/, "$1") + " <span class='text-muted text-10 fw-normal'>0 seconde</span>");
                    }
                },
                error: function () {
                    toastr.error('Erreur lors de l\'enregistrement', 'Erreur');
                }
            });
        });

        // Update Utilisateur Espace Presta isActiv
        $('body').on('click', "[id^='annuaire_utilisateurEspacePresta_isActiv_']", function (e) {
            let idUtilisateurEspacePresta = this.id.replace("annuaire_utilisateurEspacePresta_isActiv_", "");
            let input = $(this);
            input.prop('disabled', true);
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/annuaire/utilisateurEspacePresta/activation',
                data: { idUtilisateurEspacePresta: idUtilisateurEspacePresta, activation: this.value },
                success: function (data) {
                    input.prop('disabled', false);
                    if (input.val() == "on") {
                        toastr.success('L\'utilisateur de l\'espace marchand a bien été activé', 'Success');
                    } else {
                        toastr.success('L\'utilisateur de l\'espace marchand a bien été désactivé', 'Success');
                    }
                },
                error: function () {
                    toastr.error('Erreur lors de l\'enregistrement', 'Erreur');
                }
            });
        });

        // Update Utilisateur Espace Presta MDP
        $('body').on('click', "[id^='annuaire_utilisateurEspacePresta_reinitialiserMDP_']", function (e) {
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/annuaire/utilisateurEspacePresta/reinitialisation',
                data: { email: $(this).attr("data-mail") },
                success: function (data) {
                    console.log(data)
                    if (data == "ok") {
                        toastr.success('Le mail de reinitialisation à bien été envoyé', 'Success');
                    } else {
                        toastr.error(data, 'Erreur');
                    }
                },
                error: function () {
                    toastr.error('Erreur lors de l\'envoi', 'Erreur');
                }
            });
        });
    }
});
