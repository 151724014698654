$(document).ready( function () {

    makeSummerNote('slider_content_0', 200, ['text']);

    // Génération de la datatable des changelog
    changelog_table = $('#changelog_table').DataTable( {
        "autoWidth": false,
        "language": {
            "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/fr-FR.json"
        },
        "responsive": true,
        "ajax": siteURL + 'json/changelog',
        "order": [[ 0, "desc" ]],
        "columnDefs": [
            // TEXT CENTER
            {
                "className": "text-center align-middle",
                "targets": [0,2,3,4],
            },
            //TEXT START
            {
                "className": "text-start",
                "targets": [1],
            },
            {
                "width": "40px",
                "targets": 0,
                "render": function ( data, type, row ) {
                    return row[0];
                },
                "orderable": true,
            },
            {
                "targets": 1,
                "render": function ( data, type, row ) {
                    let retour = "<strong class='text-start'>"+row[1]+"</strong>";
                    row[4].forEach(element => {
                        retour = retour + "<br /><span style='font-size:10px;'>" + element.type + " : " + element.msg + "</span>";
                    });
                    return retour;
                },
            },
            {
                "width": "100px",
                "targets": 2,
                "render": function ( data, type, row ) {
                    return new Date(row[2].date).toLocaleDateString();
                },
                "orderable": true,
            },
            {
                "width": "100px",
                "targets": 3,
                "render": function ( data, type, row ) {
                    return '<span class="text-uppercase">'+row[3]+'</span>';
                },
                "orderable": true,
            },
            {
                "width": "50px",
                "targets": 4,
                "render": function ( data, type, row ) {
                    return '<button class="btn w-100 p-0" id="changelog-open-modify-'+row[0]+'" data-category="web"><i class="fad fa-edit"></i>';
                },
                "orderable": false,
            },
        ],
        "conditionalPaging": true,
    });

    function setEventRemoveChangement() {
        $('body').on('click', "button[id^='changelog_remove_changement_']", function() {
            $(this).parents(".changelog_changement_container").detach();
        });
    }

    setEventRemoveChangement();

    $('body').on('click', "button[id^='changelog_add_changement_']", function() {
        $(this).parents(".changelog_changement_container")
            .after($(this).parents(".changelog_changement_container").clone())
            .find(".input-group-append")
            .html("<button class=\"btn btn-danger\" type=\"button\" id=\"changelog_remove_changement_"+this.id.replace("changelog_add_changement_", "")+"\"><i class=\"fas fa-times\"></i></button>");

        $("[id^='changelog_changement_input_"+this.id.replace("changelog_add_changement_", "")+"']").last().val("");
        setEventRemoveChangement();
    });

    // Envoi de modification d'un changelog
    $('body').on('click', "button[id^='changelog-send-modify-']", function(e) {
        e.preventDefault();
        var clickedId = this.id.split(/[- ]+/).pop();

        var form = new FormData();
        // On extrait le contenu du formulaire dans la variable 'form'
        form.append('id', clickedId);
        form.append('changelog_version', $('#changelog_version_'+clickedId).val());
        form.append('changelog_site', $('#changelog_site_'+clickedId).val());
        let changelog_changement_array = $("[id^='changelog_changement_input_"+clickedId+"']");
        let changelog_changement_input = [];
        for (let index = 0; index < changelog_changement_array.length - 1; index++) {
            if ($(changelog_changement_array[index]).val() != "") {
                changelog_changement_input.push({
                    "type": $(changelog_changement_array[index]).parents(".changelog_changement_container").find("select").val(),
                    "msg": $(changelog_changement_array[index]).val()
                });
            }
        }
        form.append('changelog_changement', JSON.stringify(changelog_changement_input));

        $.ajax({
            type: 'POST',
            url: siteURL + 'admin/web/changelog/modify',
            cache: false,
            contentType: false,
            processData: false,
            data: form,
            success: function(data) {
                // Si il s'agit de l'ajout d'un nouveau changelog
                if (clickedId == 0) {
                    toastr.success('Le nouveau changelog a bien été enregistrée', 'Succès');
                    $('#formchangelog_0')[0].reset();
                }
                // Si il s'agit d'une modification de changelog
                else {
                    toastr.success('Le changelog a bien été modifié', 'Succès');
                    // On nettoye le html en supprimant l'onglet 'Modifier/Nouveau'
                    $('#changelog-tabs-modify-'+clickedId).remove();
                    $('#changelog-close-modify-'+clickedId).parent().remove();
                }
                // Retour à la liste des changelog en rechargeant la datatable
                changelog_table.ajax.reload();
                $('#changelog-tabs-list-tab').trigger('click');
            }
        });
        // Empèche le 'button' de fonctionner
        return false;
    });
});