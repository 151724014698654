const baseIds = ["expertise", "reception", "reprise"];
const freFormId = "freFrais_modify";
const isFreForm = (dialog) => { return dialog[0].id === freFormId };
const isStock = $("#stock_vehicule_page").data("id") !== undefined;
baseIds.forEach(baseId => {
    $('body').on('dragover', "[id^='" + baseId + "-frais-photo-label']", function (e) { e.preventDefault(); });

    /**
     * Event listener related to "expertise" feature
     * @event expertise
     * @param {Event} e
     * @summary This event is used to manage all user input on the "expertise" feature.
     */
    $('body').on('click change drop', "[id^='" + baseId + "-']", function (e) {
        const isException = isFileInputLabel(e) || isFileInput(e) || isCheckboxInputLabel(e) || isCheckboxInput(e) || isDateInputLabel(e) || isDateInput(e);
        if (!isException) {
            e.preventDefault();
        }
        if (this.id === e.target.id && !isException) {
            // Get the "dialog" element with jQuery
            let dialogSearch = (this.id.startsWith(baseId + "-dialog-") && this.id.endsWith("-open") ? $(this).next() : $(this).parents("[name='" + baseId + "-dialog']"));
            // If dialog doesn't exist take #freFrais_modify instead because it is fre form
            const dialog = (dialogSearch.length ? dialogSearch : $(this).parents("#" + freFormId));
            if (dialog.length) {
                if (dialog.data("expertise") !== undefined) {
                    // Events related to the "dialog" element are separated from the others because the "dialog" element contains an id to identify it in the page.
                    // Elements internal to the "dialog" element will be identified in the page by their parent.
                    if (this.id.startsWith(baseId + "-dialog-")) {
                        // If event is related to "dialog" element
                        if (e.target.id.endsWith("-close")) {
                            if (!isStock && baseId == "expertise") { updateBodyworkDamage(e.target.id.replace(baseId + "-dialog-", "").replace("-close", "")); }
                            const container = $("#stock_vehicule-expertise-container");
                            if (container.length) {
                                var form = new FormData();
                                form.append("id", $("#stock_vehicule_page").data("id"));
                                $.ajax({
                                    type: "POST",
                                    url: siteURL + "admin/stock/update/expertise-container",
                                    contentType: false,
                                    processData: false,
                                    data: form,
                                    success: function (data) {
                                        container.html(data);
                                    }
                                });
                            }
                        } else if (e.target.id.endsWith("-open")) {
                            const carrosserieId = dialog.find("#" + dialog.data("name") + "-frais").attr("expertise-frais-carrosserie");
                            const prestationsId = dialog.find("#" + dialog.data("name") + expertiseFnObj.element.id).find("option:not([value='0'])").toArray().map((o) => JSON.parse(o.getAttribute("data-prestations"))).flat().distinct();
                            dialog.find("#" + dialog.data("name") + expertiseFnObj.prestation.id).find("option[data-categorie='" + carrosserieId + "']").each((k, o) => {
                                if (!prestationsId.includes(parseInt(o.value))) { $(o).prop("hidden", true); }
                            });
                            dialog.find("#" + dialog.data("name") + expertiseFnObj.prestation.id).selectpicker("refresh");
                            expertiseDisplayUpdate(dialog);
                        }
                    } else {
                        // If event does not relate to "dialog" element
                        switch (e.target.id) {
                            case baseId + "-frais-categories":
                                expertiseFraisModify(dialog);
                                break;

                            case baseId + "-frais-prestations":
                                expertiseFraisModify(dialog);
                                break;

                            case baseId + "-frais-elements":
                                expertiseFraisModify(dialog);
                                break;

                            case baseId + "-frais-quantite":
                                expertiseFraisModify(dialog);
                                break;

                            case baseId + "-frais-cancel":
                                expertiseFraisCancel(dialog);
                                break;

                            case baseId + "-frais-quantite-minus":
                                expertiseQuantiteChange(dialog, "minus", 1, true);
                                break;

                            case baseId + "-frais-quantite-plus":
                                expertiseQuantiteChange(dialog, "plus", 1, true);
                                break;

                            case baseId + "-frais-commentaire":
                                if (e.type === "change") { expertiseFraisModify(dialog); }
                                break;

                            case baseId + "-frais-isCommentairePublic":
                                expertiseFraisModify(dialog);
                                break;

                            case baseId + "-frais-toDo-1":
                                expertiseToDoChange(dialog, "1", true);
                                break;

                            case baseId + "-frais-toDo-0":
                                expertiseToDoChange(dialog, "0", true);
                                break;

                            case baseId + "-frais-schema-img":
                                expertiseSchema(dialog, e, true);
                                break;

                            default:
                                if (e.target.id.startsWith(baseId + "-resume-item-")) { // SHOW ET DELETE
                                    const id = e.target.id.replace(baseId + "-resume-item-", "")
                                        .replace("-show", "")
                                        .replace("-delete", "");
                                    if (e.target.id.endsWith("-show")) {
                                        expertiseFraisShow(dialog, id);
                                    } else if (e.target.id.endsWith("-delete")) {
                                        expertiseFraisLoaderOn(dialog);
                                        expertiseFraisDelete(dialog, id);
                                    } else if (e.target.id.endsWith("-remove")) {
                                        expertiseFraisCancel(dialog);
                                        expertiseDisplayUpdate(dialog);
                                        return;
                                    }
                                } else if (e.target.id.startsWith(baseId + "-frais-photo")) {
                                    if (e.target.id.startsWith(baseId + "-frais-photo-remove-")) { // PHOTO REMOVE
                                        const id = e.target.id.replace(baseId + "-frais-photo-remove-", "");
                                        expertiseFraisFileRemove(dialog, id);
                                    } else if (e.target.id.startsWith(baseId + "-frais-photo-label")) { // PHOTO LABEL
                                        if (e.type === "drop") {
                                            if (e.dataTransfer === undefined) {
                                                e.dataTransfer = e.originalEvent.dataTransfer;
                                            }
                                            if (e.dataTransfer !== undefined) {
                                                if (e.dataTransfer.items) {
                                                    // Use DataTransferItemList interface to access the file(s)
                                                    [...e.dataTransfer.items].forEach((item, i) => {
                                                        // If dropped items aren't files, reject them
                                                        if (item.kind === "file") {
                                                            const file = item.getAsFile();
                                                            expertiseFraisFileUpload(dialog, file);
                                                        }
                                                    });
                                                } else {
                                                    // Use DataTransfer interface to access the file(s)
                                                    [...e.dataTransfer.files].forEach((file, i) => {
                                                        expertiseFraisFileUpload(dialog, file);
                                                    });
                                                }
                                            }
                                        }
                                    } else { // PHOTO
                                        if (FileReader && e.target.files && e.target.files.length) {
                                            Array.from(e.target.files).forEach(file => {
                                                expertiseFraisFileUpload(dialog, file);
                                            });
                                        }
                                    }
                                }
                                break;
                        }
                        // Update internal display of "dialog" elements
                        expertiseDisplayUpdate(dialog);
                    }
                }
            }
        }
    });
});

const expertiseFnObj = {
    categorie: {
        id: "-frais-categories",
        errorMsg: "",
        value: (dialog) => { return parseInt(dialog.find("#" + dialog.data("name") + expertiseFnObj.categorie.id).selectpicker("val")); },
        isCarrosserie: (dialog) => { return expertiseFnObj.categorie.value(dialog) == dialog.find("#" + dialog.data("name") + "-frais").attr("expertise-frais-carrosserie"); },
        getEl: (dialog, search = null) => {
            let el = null;
            dialog.find("#" + dialog.data("name") + expertiseFnObj.categorie.id).find("option").each((k, o) => {
                if ((search == null && $(o).is(":selected")) || (search == o.value)) { el = o; }
            });
            return el;
        }
    },
    element: {
        id: "-frais-elements",
        errorMsg: "L'élément n\'a pas été renseigné.",
        value: (dialog) => { return parseInt(dialog.find("#" + dialog.data("name") + expertiseFnObj.element.id).selectpicker("val")); },
        exist: (dialog) => { return dialog.find("#" + dialog.data("name") + expertiseFnObj.element.id).length; },
        check: (dialog) => { return expertiseFnObj.element.value(dialog) == 0; },
        getEl: (dialog, search = null) => {
            let el = null;
            dialog.find("#" + dialog.data("name") + expertiseFnObj.element.id).find("option").each((k, o) => {
                if ((search == null && $(o).is(":selected")) || (search == o.value)) { el = o; }
            });
            return el;
        }
    },
    prestation: {
        id: "-frais-prestations",
        errorMsg: "La prestation n\'a pas été renseigné.",
        value: (dialog) => { return parseInt(dialog.find("#" + dialog.data("name") + expertiseFnObj.prestation.id).selectpicker("val")); },
        exist: (dialog) => { return dialog.find("#" + dialog.data("name") + expertiseFnObj.prestation.id).length; },
        check: (dialog) => { return expertiseFnObj.prestation.value(dialog) == 0; },
        getEl: (dialog, search = null) => {
            let el = null;
            dialog.find("#" + dialog.data("name") + expertiseFnObj.prestation.id).find("option").each((k, o) => {
                if ((search == null && $(o).is(":selected")) || (search == o.value)) { el = o; }
            });
            return el;
        }
    },
    quantite: {
        id: "-frais-quantite",
        errorMsg: "La quantité n\'a pas été renseigné.",
        value: (dialog) => { return parseInt(dialog.find("#" + dialog.data("name") + expertiseFnObj.quantite.id).html()); },
        check: (dialog) => { return expertiseFnObj.quantite.value(dialog) <= 0; }
    },
    commentaire: {
        id: "-frais-commentaire",
        value: (dialog) => { return dialog.find("#" + dialog.data("name") + expertiseFnObj.commentaire.id).val(); },
        check: (dialog) => { return expertiseFnObj.commentaire.value(dialog).length > 0; }
    },
    isCommentairePublic: {
        id: "-frais-isCommentairePublic",
        value: (dialog) => { return dialog.find("#" + dialog.data("name") + expertiseFnObj.isCommentairePublic.id).prop("checked"); },
        check: (dialog) => { return expertiseFnObj.isCommentairePublic.value(dialog) != null; }
    },
    toDo: {
        id: "-frais-toDo",
        errorMsg: "Le frais est-il à faire ?",
        value: (dialog) => { return dialog.find("[id^='" + dialog.data("name") + expertiseFnObj.toDo.id + "-'][selected]").length ? parseInt(dialog.find("[id^='" + dialog.data("name") + expertiseFnObj.toDo.id + "-'][selected]")[0].id.replace(dialog.data("name") + expertiseFnObj.toDo.id + "-", "")) : true; },
        check: (dialog) => { return expertiseFnObj.toDo.value(dialog) != null; }
    },
    point: {
        id: "-frais-schema-img-point",
        errorMsg: "Le point n\'a pas été renseigné.",
        value: (dialog, key = "all") => {
            const el = dialog.find("#" + dialog.data("name") + expertiseFnObj.point.id);
            if (el.length) { const obj = { x: el.data("x"), y: el.data("y") }; return (key == "all" ? obj : obj[key]); } else { return null; }
        },
        check: (dialog) => { return expertiseFnObj.point.value(dialog) == null; }
    }
}

/**
 * @function expertiseQuantiteChange
 * @param {jQuery} dialog
 * @param {string} type
 * @param {number} step
 * @param {Boolean} update
 * @summary This function is used to change value of "quantite" input
 */
function expertiseQuantiteChange(dialog, type, step = 1, update = false) {
    const newValue = expertiseFnObj.quantite.value(dialog) + (type == "plus" ? step : step * -1);
    dialog.find("#" + dialog.data("name") + expertiseFnObj.quantite.id).html((newValue <= 0 ? 1 : newValue));
    if (update) { expertiseFraisModify(dialog); }
}

/**
 * @function expertiseToDoChange
 * @param {jQuery} dialog
 * @param {string} value
 * @param {Boolean} update
 * @summary This function is used to change value of "toDo" input
 */
function expertiseToDoChange(dialog, value, update = false) {
    // Reset color button
    dialog.find("[id^='" + dialog.data("name") + expertiseFnObj.toDo.id + "-']").removeClass("btn-primary");
    dialog.find("[id^='" + dialog.data("name") + expertiseFnObj.toDo.id + "-']").addClass("btn-outline-secondary");
    dialog.find("[id^='" + dialog.data("name") + expertiseFnObj.toDo.id + "-']").removeAttr("selected");
    // Set color button
    dialog.find("#" + dialog.data("name") + expertiseFnObj.toDo.id + "-" + value).addClass("btn-primary");
    dialog.find("#" + dialog.data("name") + expertiseFnObj.toDo.id + "-" + value).removeClass("btn-outline-secondary");
    dialog.find("#" + dialog.data("name") + expertiseFnObj.toDo.id + "-" + value).attr("selected", "");
    if (update) { expertiseFraisModify(dialog); }
}

/**
 * @function expertiseSchema
 * @param {jQuery} dialog
 * @param {Event} e
 * @param {Boolean} update
 * @summary This function is used to set a point on "expertise" diagram
 */
function expertiseSchema(dialog, e, update = false) {
    expertisePointRemove(dialog);
    const schema = dialog.find("#" + dialog.data("name") + "-frais-schema-img");
    const w = Math.round(e.offsetX / schema.width() * 100);
    const h = Math.round(e.offsetY / schema.height() * 100);
    expertisePointAdd(dialog, w, h, update);
}

/**
 * @function expertisePointAdd
 * @param {jQuery} dialog
 * @param {number} w
 * @param {number} h
 * @param {Boolean} update
 * @summary This function is used to set a point on "expertise" diagram
 */
function expertisePointAdd(dialog, w, h, update = false) {
    const schema = dialog.find("#" + dialog.data("name") + "-frais-schema-img");
    const newEl = document.createElement("div");
    newEl.id = dialog.data("name") + "-frais-schema-img-point";
    newEl.setAttribute("data-x", w);
    newEl.classList.add("l-" + w);
    newEl.setAttribute("data-y", h);
    newEl.classList.add("t-" + h);
    schema.append(newEl);
    if (update) { expertiseFraisModify(dialog); }
}

/**
 * @function expertisePointRemove
 * @param {jQuery} dialog
 * @param {Boolean} update
 * @summary This function is used to set a point on "expertise" diagram
 */
function expertisePointRemove(dialog, update = false) {
    const oldEl = dialog.find("#" + dialog.data("name") + "-frais-schema-img-point");
    if (oldEl.length) { oldEl.remove(); }
    if (update) { expertiseFraisModify(dialog); }
}

/**
 * @function expertiseFraisFileUpload
 * @param {jQuery} dialog
 * @param {File} file
 * @summary This function is used to upload file
 */
function expertiseFraisFileUpload(dialog, file) {
    var fr = new FileReader();
    fr.onload = function (e) {
        var img = new Image();
        img.onload = function () {
            // Créer un canvas pour redimensionner l'image
            var canvas = document.createElement('canvas');
            var ctx = canvas.getContext('2d');
            // Définir les nouvelles dimensions
            canvas.width = (img.width >= 3000 ? img.width / 4 : (img.width >= 1500 ? img.width / 2 : img.width));
            canvas.height = (img.width >= 3000 ? img.height / 4 : (img.width >= 1500 ? img.height / 2 : img.height));
            // Dessiner l'image redimensionnée dans le canvas
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
            // Convertir le canvas en un blob
            canvas.toBlob(function (blob) {
                // Créer un nouveau fichier à partir du blob redimensionné
                let newFile = new File([blob], file.name, { type: file.type });
                expertiseFraisLoaderOn(dialog);
                newFile = new File([newFile], newFile.name.replace(new RegExp("(.+)[.](.+[^.])", "g"), "$1").slugify() + "." + newFile.name.replace(new RegExp("(.+)[.](.+[^.])", "g"), "$2").slugify(), { type: newFile.type });
                var form = new FormData();
                form.append('data', newFile);
                form.append('frais_id', dialog.find("div[expertise-frais-id]").attr("expertise-frais-id"));
                $.ajax({
                    type: 'POST',
                    url: siteURL + 'admin/fre/freFrais/fraisPhotoAdd',
                    contentType: false,
                    processData: false,
                    data: form,
                    success: function (data) {
                        const frais = dialog.data("expertise").find((f) => f.id == data.frais);
                        frais.photos.push(data.id);
                        dialog.data("expertise", dialog.data("expertise").map((f) => (f.id == frais.id ? frais : f)));
                        expertiseFraisFileDisplay(dialog, data.id);
                        expertiseDisplayUpdate(dialog);
                    },
                    error: function () {
                        toastr.error('Une erreur s\'est produite.', 'Erreur');
                    },
                    complete: function () {
                        expertiseFraisLoaderOff(dialog);
                    }
                });
            }, file.type);
        }
        img.src = e.target.result;
    }
    fr.readAsDataURL(file);
}

/**
 * @function expertiseFraisFileRemove
 * @param {jQuery} dialog
 * @param {number} id
 * @summary This function is used to upload file
 */
function expertiseFraisFileRemove(dialog, id) {
    var form = new FormData();
    form.append('id', id);
    $.ajax({
        type: 'POST',
        url: siteURL + 'admin/fre/freFrais/fraisPhotoDelete',
        contentType: false,
        processData: false,
        data: form,
        success: function (data) {
            toastr.success('Le fichier bien été supprimé', 'Succès');
            const frais = dialog.data("expertise").find((f) => f.id == data.frais);
            frais.photos = frais.photos.filter(photo => photo != id);
            dialog.data("expertise", dialog.data("expertise").map((f) => (f.id == frais.id ? frais : f)));
            $("#" + dialog.data("name") + "-frais-photo-display-" + id).remove();
            expertiseDisplayUpdate(dialog);
        },
        error: function () {
            toastr.error('Une erreur s\'est produite.', 'Erreur');
        }
    });
}

/**
 * @function expertiseFraisFileDisplay
 * @param {jQuery} dialog
 * @param {number} id
 * @summary This function is used to display file
 */
function expertiseFraisFileDisplay(dialog, id) {
    const item = dialog.find("#" + dialog.data("name") + "-frais-photo-display-__id__").clone();
    item.attr("id", item[0].id.replaceAll("__id__", id));
    item.html(item.html().replaceAll("__id__", id));
    item.find("img").attr("src", item.find("img").data("url"));
    item.find("img").removeAttr("custom-fancy-disabled");
    dialog.find("#" + dialog.data("name") + "-frais-photo" + (dialog.data("step") ? "-" + dialog.data("step") : "")).before(item);
}

/**
 * @function expertiseFraisShow
 * @param {jQuery} dialog
 * @param {number} id
 * @summary This function is used to show a "frais" in form
 */
function expertiseFraisShow(dialog, id) {
    const frais = dialog.data("expertise").find((f) => f.id == id);
    dialog.find("#" + dialog.data("name") + expertiseFnObj.categorie.id).selectpicker("val", frais.categorie);
    dialog.find("#" + dialog.data("name") + expertiseFnObj.element.id).selectpicker("val", frais.element);
    dialog.find("#" + dialog.data("name") + expertiseFnObj.prestation.id).selectpicker("val", frais.prestation);
    dialog.find("#" + dialog.data("name") + expertiseFnObj.quantite.id).html(frais.quantite);
    expertisePointRemove(dialog);
    if (frais.point != null) {
        expertisePointAdd(dialog, frais.point.x, frais.point.y);
    }
    dialog.find("[id^='" + dialog.data("name") + "-frais-photo-display-'][id!='" + dialog.data("name") + "-frais-photo-display-__id__']").remove();
    frais.photos.forEach((id) => { expertiseFraisFileDisplay(dialog, id); });
    expertiseToDoChange(dialog, frais.toDo >>> 0);
    dialog.find("#" + dialog.data("name") + expertiseFnObj.commentaire.id).val(frais.commentaire);
    dialog.find("div[expertise-frais-id]").attr("expertise-frais-id", frais.id);
    dialog.find("div[expertise-frais-type]").attr("expertise-frais-type", frais.type);
    dialog.find("div[expertise-frais-id]").find("button[expertise-frais-id!='0']").attr("expertise-frais-id", frais.id);
    dialog.find("#" + dialog.data("name") + expertiseFnObj.toDo.id + "-0").prop("disabled", frais.isDone);
    dialog.find("#" + dialog.data("name") + expertiseFnObj.toDo.id + "-1").prop("disabled", frais.isDone);
    dialog.find("#" + dialog.data("name") + expertiseFnObj.isCommentairePublic.id).prop("checked", frais.isCommentairePublic == 1 ? true : false);
    if (isFreForm(dialog)) { $("#" + dialog.data("name") + "-frais").removeClass('collapsed-card'); $("#" + dialog.data("name") + "-frais-form")[0].style.display = ""; }
}

/**
 * @function expertiseFraisCancel
 * @param {jQuery} dialog
 * @summary This function is used to cancel modification of a "frais"
 */
function expertiseFraisCancel(dialog) {
    expertiseFraisReset(dialog);
    dialog.find("div[expertise-frais-id]").attr("expertise-frais-id", "0");
    dialog.find("div[expertise-frais-type]").attr("expertise-frais-type", (isStock ? "vs" : "vf"));
    dialog.find("div[expertise-frais-id]").find("button[expertise-frais-id!='0']").attr("expertise-frais-id", "");
}

/**
 * @function expertiseFraisModify
 * @param {jQuery} dialog
 * @summary This function is used to save a "frais" element from form
 */
function expertiseFraisModify(dialog) {
    let error_message = "";
    if (expertiseFnObj.categorie.isCarrosserie(dialog)) {
        if (expertiseFnObj.element.check(dialog)) { error_message += expertiseFnObj.element.errorMsg + "<br/>" }
        if (expertiseFnObj.prestation.check(dialog)) { error_message += expertiseFnObj.prestation.errorMsg + "<br/>" }
        if (expertiseFnObj.quantite.check(dialog)) { error_message += expertiseFnObj.quantite.errorMsg + "<br/>" }
        if (expertiseFnObj.point.check(dialog)) { error_message += expertiseFnObj.point.errorMsg + "<br/>"; }
    } else {
        if (expertiseFnObj.prestation.check(dialog)) { error_message += expertiseFnObj.prestation.errorMsg + "<br/>" }
        if (expertiseFnObj.quantite.check(dialog)) { error_message += expertiseFnObj.quantite.errorMsg + "<br/>" }
    }
    if (error_message === "") {
        expertiseFraisLoaderOn(dialog);
        const type = (dialog.find("div[expertise-frais-type]").attr("expertise-frais-type") == "vf" ? "vf" : "vs");
        // AJAX MODIFY
        let form = new FormData();
        form.append('freFrais_vehicule', (
            type == "vs"
                ? $("#stock_vehicule_page").data("id") // Véhicule stock
                : (
                    dialog.parents("[id^='formvehicule_fournisseur_']").length
                        ? dialog.parents("[id^='formvehicule_fournisseur_']").attr("id").replace("formvehicule_fournisseur_", "") // VF
                        : $(channelGetDialog(4, "reprise")).data("id") // Reprise
                )
        ));
        form.append('freFrais_id', dialog.find("div[expertise-frais-id]").attr("expertise-frais-id"));
        form.append('freFrais_type', type);
        form.append('freFrais_prestation', expertiseFnObj.prestation.value(dialog));
        form.append('freFrais_element', expertiseFnObj.element.value(dialog));
        form.append('freFrais_quantite', expertiseFnObj.quantite.value(dialog));
        form.append('freFrais_isCommentairePublic', expertiseFnObj.isCommentairePublic.value(dialog));
        form.append('freFrais_coordX', expertiseFnObj.point.value(dialog, "x"));
        form.append('freFrais_coordY', expertiseFnObj.point.value(dialog, "y"));
        form.append('expertiseReturn', true);
        if (expertiseFnObj.commentaire.check(dialog)) { form.append('freFrais_commentaire', expertiseFnObj.commentaire.value(dialog)); }
        if (expertiseFnObj.commentaire.check(dialog)) { form.append('freFrais_isCommentairePublic', expertiseFnObj.isCommentairePublic.value(dialog)); }
        if (expertiseFnObj.toDo.check(dialog)) { form.append('freFrais_toDo', expertiseFnObj.toDo.value(dialog)); }
        $.ajax({
            type: 'POST',
            url: siteURL + 'admin/fre/freFrais/modify',
            contentType: false,
            processData: false,
            data: form,
            success: function (data) {
                if (data.success) {
                    delete data.success;
                    const obj = data;
                    if (dialog.find("div[expertise-frais-id]").attr("expertise-frais-id") == "0") {
                        dialog.data("expertise", dialog.data("expertise").concat([obj]));
                    } else {
                        dialog.data("expertise", dialog.data("expertise").map((f) => (f.id == obj.id ? obj : f)));
                    }
                    if (isFreForm(dialog)) { loadVehiculeFrais(); }
                    expertiseFraisShow(dialog, obj.id);
                    expertiseDisplayUpdate(dialog);
                    if (dialog.parents("[id^='formvehicule_fournisseur_']").length && type == "vf") {
                        updateVF(dialog.parents("[id^='formvehicule_fournisseur_']").attr("id").replace("formvehicule_fournisseur_", ""), null, null, null, null, false)
                    }
                } else {
                    dialogToastrError(dialog, "expertise", 'Une erreur s\'est produite.');
                }
            },
            error: function () {
                dialogToastrError(dialog, "expertise", 'Une erreur s\'est produite.');
            },
            complete: function () {
                expertiseFraisLoaderOff(dialog);
            }
        });
    }
}

/**
 * @function expertiseFraisDelete
 * @param {jQuery} dialog
 * @param {number} id
 * @summary This function is used to delete a "frais" element by id
 */
function expertiseFraisDelete(dialog, id) {
    let form = new FormData();
    form.append('id', id);
    $.ajax({
        type: 'POST',
        url: siteURL + 'admin/fre/freFrais/delete',
        contentType: false,
        processData: false,
        data: form,
        success: function (data) {
            if (data.success) {
                const frais = dialog.data("expertise");
                dialog.data("expertise", frais.filter((f) => f.id != id));
                expertiseFraisCancel(dialog);
                expertiseDisplayUpdate(dialog);
                dialogToastrSuccess(dialog, "expertise", 'Le frais bien été supprimé');
            } else {
                dialogToastrError(dialog, "expertise", 'Une erreur s\'est produite.');
            }
        },
        error: function () {
            dialogToastrError(dialog, "expertise", 'Une erreur s\'est produite.');
        },
        complete: function () {
            expertiseFraisLoaderOff(dialog);
        }
    });
}

/**
 * @function expertiseFraisReset
 * @param {jQuery} dialog
 * @summary This function is used to reset the "frais" form
 */
function expertiseFraisReset(dialog) {
    dialog.find("#" + dialog.data("name") + expertiseFnObj.element.id).selectpicker("val", 0);
    dialog.find("#" + dialog.data("name") + expertiseFnObj.prestation.id).selectpicker("val", 0);
    dialog.find("#" + dialog.data("name") + expertiseFnObj.quantite.id).html(1);
    dialog.find("[id^='" + dialog.data("name") + expertiseFnObj.toDo.id + "-']").removeClass("btn-primary");
    dialog.find("[id^='" + dialog.data("name") + expertiseFnObj.toDo.id + "-']").addClass("btn-outline-secondary");
    dialog.find("[id^='" + dialog.data("name") + expertiseFnObj.toDo.id + "-']").removeAttr("selected");
    dialog.find("#" + dialog.data("name") + expertiseFnObj.toDo.id + "-0").prop("disabled", false);
    dialog.find("#" + dialog.data("name") + expertiseFnObj.toDo.id + "-1").prop("disabled", false);
    expertisePointRemove(dialog);
    dialog.find("[id^='" + dialog.data("name") + "-frais-photo-display-'][id!='" + dialog.data("name") + "-frais-photo-display-__id__']").remove();
    dialog.find("#" + dialog.data("name") + expertiseFnObj.commentaire.id).val("");
}

/**
 * @function expertiseDisplayUpdate
 * @param {jQuery} dialog
 * @summary This function update internal display of "dialog" elements
 */
function expertiseDisplayUpdate(dialog) {
    // Initialize selectpicker
    dialog.find("#" + dialog.data("name") + expertiseFnObj.categorie.id).selectpicker();
    dialog.find("#" + dialog.data("name") + expertiseFnObj.element.id).selectpicker();
    dialog.find("#" + dialog.data("name") + expertiseFnObj.prestation.id).selectpicker();

    // Determines the possible "prestation" according to the selected categorie
    dialog.find("#" + dialog.data("name") + expertiseFnObj.prestation.id).find("option").each((k, o) => {
        const categorieId = JSON.parse(o.getAttribute("data-categorie"));
        if (categorieId != null) {
            const isDisplayable = categorieId == expertiseFnObj.categorie.value(dialog);
            if (!isDisplayable) { $(o).addClass("d-none"); } else { $(o).removeClass("d-none"); }
            if ($(o).is(":selected") && !isDisplayable) {
                // Reset selectpicker if current value is not displayable
                dialog.find("#" + dialog.data("name") + expertiseFnObj.prestation.id).selectpicker("val", "0");
            }
        }
    });

    // Determines the possible "element" according to the selected prestation
    dialog.find("#" + dialog.data("name") + expertiseFnObj.element.id).find("option").each((k, o) => {
        const prestationsId = JSON.parse(o.getAttribute("data-prestations"));
        if (prestationsId != null) {
            const isDisplayable = prestationsId.includes(expertiseFnObj.prestation.value(dialog));
            if (!isDisplayable) { $(o).addClass("d-none"); } else { $(o).removeClass("d-none"); }
            if ($(o).is(":selected") && !isDisplayable) {
                // Reset selectpicker if current value is not displayable
                dialog.find("#" + dialog.data("name") + expertiseFnObj.element.id).selectpicker("val", "0");
            }
        }
    });

    // Hide optgroup if no option displayable for the optgroup
    dialog.find("#" + dialog.data("name") + expertiseFnObj.element.id).find("optgroup").each((k, og) => {
        // If every option of optgroup contains "d-none" class
        if ($(og).find("option").toArray().map((o) => o.classList.contains("d-none")).every((v) => v)) { $(og).addClass("d-none"); } else { $(og).removeClass("d-none"); }
    });

    // Refresh "element" and "prestation" selectpicker
    dialog.find("#" + dialog.data("name") + expertiseFnObj.prestation.id).selectpicker("refresh");
    dialog.find("#" + dialog.data("name") + expertiseFnObj.element.id).selectpicker("refresh");

    // Display "element" selectpicker depend on "categorie" and "prestation" value
    if (expertiseFnObj.categorie.isCarrosserie(dialog) && expertiseFnObj.prestation.value(dialog)) { dialog.find("#" + dialog.data("name") + expertiseFnObj.element.id + "-display").removeClass("d-none"); } else { dialog.find("#" + dialog.data("name") + expertiseFnObj.element.id + "-display").addClass("d-none"); }

    // Determines the display of the rest of the form depending on the selection in "select"
    dialog.find("#" + dialog.data("name") + "-frais").attr("expertise-frais-categorie", expertiseFnObj.categorie.value(dialog));
    dialog.find("#" + dialog.data("name") + "-frais").attr("expertise-frais-prestation", (expertiseFnObj.prestation.value(dialog) ? 1 : 0));
    dialog.find("#" + dialog.data("name") + "-frais").attr("expertise-frais-element", (expertiseFnObj.element.value(dialog) ? 1 : 0));
    if (expertiseFnObj.element.check(dialog)) {
        expertisePointRemove(dialog);
    }
    dialog.find("#" + dialog.data("name") + "-frais").attr("expertise-frais-point", (dialog.find("#" + dialog.data("name") + "-frais-schema-img-point").length ? 1 : 0));
    const elementEl = expertiseFnObj.element.getEl(dialog);
    const prestationEl = expertiseFnObj.prestation.getEl(dialog);
    const quantite = expertiseFnObj.quantite.value(dialog);
    dialog.find("#" + dialog.data("name") + "-frais-total-quantite").html(quantite);
    if (elementEl != null) {
        dialog.find("#" + dialog.data("name") + "-frais-schema").attr("expertise-frais-exterieur", elementEl.getAttribute("data-exterieur"));
        dialog.find("#" + dialog.data("name") + "-frais-total-element").html(elementEl.innerHTML);
    }
    if (prestationEl != null) {
        dialog.find("#" + dialog.data("name") + "-frais-total-prixHtTotal").html(prestationEl.getAttribute("data-prixht") * quantite);
        dialog.find("#" + dialog.data("name") + "-frais-total-prestation").html(prestationEl.innerHTML);
    }

    if (dialog.find("div[expertise-frais-id]").attr("expertise-frais-id") == 0) {
        $("#" + dialog.data("name") + expertiseFnObj.categorie.id).removeAttr("disabled").selectpicker("refresh");
        $("#" + dialog.data("name") + expertiseFnObj.prestation.id).removeAttr("disabled").selectpicker("refresh");
    } else {
        $("#" + dialog.data("name") + expertiseFnObj.categorie.id).attr("disabled", "").selectpicker("refresh");
        if (expertiseFnObj.categorie.isCarrosserie(dialog)) {
            $("#" + dialog.data("name") + expertiseFnObj.prestation.id).attr("disabled", "").selectpicker("refresh");
        } else {
            $("#" + dialog.data("name") + expertiseFnObj.prestation.id).removeAttr("disabled").selectpicker("refresh");
        }
    }

    // If it is not fre form
    if (!isFreForm(dialog)) {
        // Determines the display of the list of "frais"
        dialog.find("#" + dialog.data("name") + "-resume-list").html("");
        const frais = dialog.data("expertise");
        frais.sort((a, b) => a.categorie - b.categorie);
        frais.sort((a, b) => b.toDo - a.toDo);
        let fraisPrixTotal = 0;
        frais.forEach(frais => {
            const item = dialog.find("#" + dialog.data("name") + "-resume-item-__id__").clone();
            item.attr("id", item[0].id.replaceAll("__id__", frais.id));
            item.attr("expertise-resume-item-type", frais.type);
            item.html(item.html().replaceAll("__id__", frais.id));
            if (frais.photos.length) {
                item.find("#" + dialog.data("name") + "-resume-item-" + frais.id + "-photo").attr("src", item.find("#" + dialog.data("name") + "-resume-item-" + frais.id + "-photo").data("url") + frais.photos[0]);
            }
            item.find("#" + dialog.data("name") + "-resume-item-" + frais.id + "-element").html(expertiseFnObj.element.getEl(dialog, frais.element).innerHTML);
            item.find("#" + dialog.data("name") + "-resume-item-" + frais.id + "-prestation").html(expertiseFnObj.prestation.getEl(dialog, frais.prestation).innerHTML);
            item.find("#" + dialog.data("name") + "-resume-item-" + frais.id + "-toDo").html(frais.toDo ? "À faire &#x1F6A7;" : "Pour mémoire &#x274C;");
            item.find("#" + dialog.data("name") + "-resume-item-" + frais.id + "-quantite").html(frais.quantite);
            item.find("#" + dialog.data("name") + "-resume-item-" + frais.id + "-prixht").html(expertiseFnObj.prestation.getEl(dialog, frais.prestation).getAttribute("data-prixht"));
            item.find("#" + dialog.data("name") + "-resume-item-" + frais.id + "-prixHtTotal").html(expertiseFnObj.prestation.getEl(dialog, frais.prestation).getAttribute("data-prixht") * frais.quantite);
            if (frais.toDo) {
                fraisPrixTotal += expertiseFnObj.prestation.getEl(dialog, frais.prestation).getAttribute("data-prixht") * frais.quantite;
            }
            item.removeClass("d-none");
            dialog.find("#" + dialog.data("name") + "-resume-list").append(item);
        });
        dialog.find("#" + dialog.data("name") + "-resume").attr("expertise-frais-list", (frais.length ? 1 : 0));
        dialog.find("#" + dialog.data("name") + "-resume-nbfrais").html(frais.length);
        dialog.find("#" + dialog.data("name") + "-resume-prixHtTotal").html(fraisPrixTotal);
        dialog.trigger("change");
    }
}

/**
 * @function expertiseFraisLoaderOn
 * @param {jQuery} dialog
 * @summary This function is used to display loader and hide form
 */
function expertiseFraisLoaderOn(dialog) {
    // Frais
    dialog.find("#" + dialog.data("name") + "-frais-form").addClass("d-none");
    dialog.find("#" + dialog.data("name") + "-frais-load").removeClass("d-none");
    // Résumé
    dialog.find("#" + dialog.data("name") + "-resume").addClass("d-none");
    dialog.find("#" + dialog.data("name") + "-resume-load").removeClass("d-none");
}

/**
 * @function expertiseFraisLoaderOff
 * @param {jQuery} dialog
 * @summary This function is used to display form and hide loader
 */
function expertiseFraisLoaderOff(dialog) {
    // Frais
    dialog.find("#" + dialog.data("name") + "-frais-load").addClass("d-none");
    dialog.find("#" + dialog.data("name") + "-frais-form").removeClass("d-none");
    // Résumé
    dialog.find("#" + dialog.data("name") + "-resume-load").addClass("d-none");
    dialog.find("#" + dialog.data("name") + "-resume").removeClass("d-none");
}
