$('body').on('change', "#bandeau_upload", function(e){
    $('#bandeau_file').html('vide');
    var form = new FormData();

    $.each($(this)[0].files, function(i, file) {
        form.append('data-'+i, file);
    });
    $('#bandeau_result').slideUp( function () {
        $('#bandeau_result').html('<div class="col-12 text-center pb-2"><strong>Envoi des photos en cours, merci de bien vouloir patienter.</strong></div><div><i class="fas fa-spinner fa-spin fa-5x"></i></div>');
        $('#bandeau_result').slideDown();
    });
    

    $.ajax({
        url: siteURL + 'admin/outils/bandeauphoto/upload_photo',
        type: 'POST',
        cache: false,
        contentType: false,
        processData: false,
        data: form,
        success: function(data) {
            if (data != "error") {
                var images = data.split(';');
                if (images.length == 1) { var col = "col-12"; toastr.success('La photo a bien été envoyée', "Succès");}
                else if (images.length == 2) { var col = "col-6"; toastr.success('Les '+images.length+' photos ont bien été envoyées', "Succès"); }
                else { var col = "col-4"; toastr.success('Les '+images.length+' photos ont bien été envoyées', "Succès"); }

                $('#bandeau_result').slideUp(1000, function() {
                    $('#bandeau_result').empty();
                    images.forEach( function( image) {
                        $('#bandeau_result').append('<div class="'+col+' text-center"><img src="'+siteURL+image+'" class="mt-2 img-fluid border border-secondary rounded-sm"></div>')
                        $('#bandeau_file').html($('#bandeau_file').html()+";"+image);
                    });
                    $('#bandeau_result').slideDown();
                });
        $('#bandeaupaste_upload').prop("disabled", false);
            }
            else {
                toastr.error('Une erreur est survenue', "Erreur");
            }
        },
    });
});

$('body').on('change', "#bandeaupaste_upload", function(e){

    var form = new FormData();
    form.append('data', $(this)[0].files[0]);
    form.append('photo', $('#bandeau_file').html())

    $('#bandeau_result').slideUp( function () {
        $('#bandeau_result').html('<div class="col-12 text-center pb-2"><strong>Conversion des photos en cours, merci de bien vouloir patienter.</strong></div><div><i class="fas fa-spinner fa-spin fa-5x"></i></div>');
        $('#bandeau_result').slideDown();
    });

    if ($('#bandeau_file').html() != "vide") {
        $.ajax({
            url: siteURL + 'admin/outils/bandeauphoto/upload_bandeau',
            type: 'POST',
            cache: false,
            contentType: false,
            processData: false,
            data: form,
            success: function(data) {
                var images = data.split(';');
                if (images.length == 1) { var col = "col-12"; toastr.success('La photo a bien été convertie', "Succès");}
                else if (images.length == 2) { var col = "col-6"; toastr.success('Les '+images.length+' photos ont bien été converties', "Succès"); }
                else { var col = "col-4"; toastr.success('Les '+images.length+' photos ont bien été converties', "Succès"); }

                $('#bandeau_result').slideUp(1000, function() {
                    $('#bandeau_result').empty();
                    images.forEach( function( image){
                        $('#bandeau_result').append('<div class="'+col+' text-center"><a href="'+siteURL+image+'" download><div class="w-100 text-center"><img src="'+siteURL+image+'?'+Math.random().toString(36).substring(2, 15)+'" class="mt-2 img-fluid border border-secondary rounded-sm"></div><div class="mr-2 p-1 rounded" style="position: absolute; right: 3px; bottom: 3px; background-color: #00aeef;"><i class="fas fa-download fa-2x" style="color: white;"></i></div></a></div>')
                    });
                    $('#bandeau_result').slideDown();
                });
            },
        });
    }
    else {
        toastr.error('Il manque la photo du véhicule', "Erreur");
    }
});

$('body').on('click', '.bandeau_exists', function() {
    var form = new FormData();
    form.append('bandeau',$(this).data('bandeau'));
    form.append('photo', $('#bandeau_file').html());

    $('#bandeau_result').slideUp( function () {
        $('#bandeau_result').html('<div class="col-12 text-center pb-2"><strong>Conversion des photos en cours, merci de bien vouloir patienter.</strong></div><div><i class="fas fa-spinner fa-spin fa-5x"></i></div>');
        $('#bandeau_result').slideDown();
    });

    if ($('#bandeau_file').html() != "vide") {
        $.ajax({
            url: siteURL + 'admin/outils/bandeauphoto/use_bandeau',
            type: 'POST',
            cache: false,
            contentType: false,
            processData: false,
            data: form,
            success: function(data) {
                var images = data.split(';');
                if (images.length == 1) { var col = "col-12"; toastr.success('La photo a bien été convertie', "Succès");}
                else if (images.length == 2) { var col = "col-6"; toastr.success('Les '+images.length+' photos ont bien été converties', "Succès"); }
                else { var col = "col-4"; toastr.success('Les '+images.length+' photos ont bien été converties', "Succès"); }
                
                $('#bandeau_result').slideUp(1000, function() {
                    $('#bandeau_result').empty();
                    images.forEach( function( image){
                        $('#bandeau_result').append('<div class="'+col+' text-center"><a href="'+siteURL+image+'" download><div class="w-100 text-center"><img src="'+siteURL+image+'?'+Math.random().toString(36).substring(2, 15)+'" class="mt-2 img-fluid border border-secondary rounded-sm"></div><div class="mr-2 p-1 rounded" style="position: absolute; right: 3px; bottom: 3px; background-color: #00aeef;"><i class="fas fa-download fa-2x" style="color: white;"></i></div></a></div>')
                    });
                    $('#bandeau_result').slideDown();
                });
            },
        });
    }
    else {
        toastr.error('Il manque la photo du véhicule', "Erreur");
    }
});
