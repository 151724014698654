$(document).ready(function() {

    makeSummerNote('actualite_intro_content_0', 100, ['text']);
    makeSummerNote('actualite_content_0', 300, ['full']);

    // Génération de la datatable des actualités
    actualite_table = $('#actualites_table').DataTable({
        "autoWidth": false,
        "language": {
            "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/fr-FR.json"
        },
        "responsive": true,
        "ajax": siteURL + 'json/actualites',
        "order": [
            [0, "desc"]
        ],
        "columnDefs": [
            { responsivePriority: 1, targets: 1 },
            { responsivePriority: 2, targets: 3 },
            { responsivePriority: 3, targets: 2 },
            { responsivePriority: 4, targets: 0 },
            { responsivePriority: 5, targets: 4 },
            // TEXT CENTER
            {
                "className": "text-center align-middle",
                "targets": [4, 5],
            },
            // ALIGN MIDDLE
            {
                "className": "align-middle",
                "targets": [0, 3, 4],
            },
            // DATE
            {
                "targets": 0,
                "render": function(data, type, row) {
                    var monthNames = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];
                    if (row[8] != '') {
                        var actualiteDate = row[8].split('/');
                    }
                    else {
                        var actualiteDate = row[5].split('/');
                    }
                    if (actualiteDate != '') {
                        return actualiteDate[0] + '/' + actualiteDate[1] + '/' + actualiteDate[2];
                    } else {
                        return '--';
                    }
                },
            },
            // TITRE + SOUS-TITRE
            {
                "className": "text-start",
                "targets": 1,
                "render": function(data, type, row) {
                    return '<strong>' + row[1] + '</strong><br /> ' + row[2];
                },
                "orderable": false,
            },
            // REDACTEUR
            {
                "targets": 2,
                "render": function(data, type, row) {
                    return row[4];
                },
            },
            // SITE
            {
                "targets": 3,
                "render": function(data, type, row) {
                    if (row[6] == 0) { return "TOUS"; }
                    if (row[6] == 1) { return "PARTICULIER"; }
                    if (row[6] == 2) { return "PRO"; }
                },
                "orderable": false,
            },
            // VISIBLE
            {
                "width": "50px",
                "targets": 4,
                "render": function(data, type, row) {
                    if (row[7] == 1) {
                        return '<i class="fal fa-check-square"></i><span class="d-inline d-lg-none pl-2">OUI</span>';
                    } else {
                        return '<i class="fal fa-square"></i><span class="d-inline d-lg-none pl-2">NON</span>';
                    }
                },
                "orderable": false,
            },
            // MODIFY
            {
                "width": "50px",
                "targets": 5,
                "render": function(data, type, row) {
                    return '<button class="btn w-100 p-0" id="actualite-open-modify-' + row[0] + '" data-category="web"><i class="fad fa-edit"></i>';
                },
                "orderable": false,
            },
        ],
        "conditionalPaging": true,
    });

    // Envoi de modification d'une actualité
    $('body').on('click', "button[id^='actualite-send-modify-']", function() {
        var clickedId = this.id.split(/[- ]+/).pop();
        if (makeSureFields(this.id) == true) {
            var form = new FormData();
            // On extrait le contenu du formulaire dans la variable 'form'
            form.append('id', clickedId);
            form.append('actu_title_seo', $('#actualite_title_seo_'+ clickedId).val());
            form.append('actu_meta_description', $('#actualite_meta_description_'+clickedId).val());
            form.append('actu_title', $('#actualite_title_' + clickedId).val());
            form.append('actu_subtitle', $('#actualite_subtitle_' + clickedId).val());
            form.append('actu_content', $('#actualite_content_' + clickedId).val());
            form.append('actu_intro_content', $('#actualite_intro_' + clickedId).val());
            form.append('actu_datepublication', $('#actualite_datepublication_' + clickedId).val());
            form.append('actu_urlrewrite', $('#actualite_urlrewrite_' + clickedId).val());
            form.append('actu_isvisible', $('input[name="actualite_visible_' + clickedId + '"]:checked').val());
            form.append('actu_image', $('#file_result_' + clickedId + '_0').val());
            form.append('actu_user', $('select[name="actualite_user_' + clickedId + '"]').val());
            form.append('actu_categorie', $('select[name="actualite_categorie_' + clickedId + '"]').val());
            form.append('actu_site', $('select[name="actualite_site_' + clickedId + '"]').val());
            // Requète ajax d'envoi du formulaire : /src/Controller/AdminController.php -> admin_actualites()
            var actu_slideshow = Array();

            $("input[id^='file_result_" + clickedId + "_']").each(function(e) {
                count = this.id.replace('file_result_' + clickedId + '_', '');
                if (count >= 1000) {
                    actu_slideshow.push($(this).val());
                }
            });
            form.append('actu_slideshow', actu_slideshow);

            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/web/actualite/modify',
                contentType: false,
                processData: false,
                data: form,
                success: function(data) {
                    // Si il s'agit de l'ajout d'une nouvelle actualité
                    if (clickedId == 0) {
                        toastr.success('La nouvelle actualité a bien été enregistrée', 'Succès');
                        $('#formactualite_0')[0].reset();

                        $("[id^='file_upload_0_']", "[id^='file_result_0_']").each(function() { $(this).val('') });
                        // Récupère la liste des sliders ajoutés (techniquement tous ceux qui ne sont pas le premier)
                        $("#actualite-addsliderimage").parent().siblings().not(":first").remove();
                        $("[id^='file_display_0_").each(function() { $(this).html('') });
                        $("[id*='_content_']").each(function(e) { $(this).summernote('reset'); });
                    }
                    // Si il s'agit d'une modification d'actualité
                    else {
                        toastr.success('L\ actualité a bien été modifiée', 'Succès');
                        // On nettoye le html en supprimant l'onglet 'Modifier/Nouveau'
                        $('#actualite-tabs-modify-' + clickedId).remove();
                        $('#actualite-close-modify-' + clickedId).parent().remove();
                    }
                    // Retour à la liste d'actualités en rechargeant la datatable
                    actualite_table.ajax.reload();
                    $('#actualite-tabs-list-tab').trigger('click');
                }
            });
        }
        return false;
    });

    $('body').on('click', '#actualite-addsliderimage', function() {
        var last = $(this).parent().siblings().last();

        var id = last.children().find("input[type='file']")[0].id.replace('file_upload_', '');
        var current_id = id.split('_')[0];
        var last_id = id.split('_')[1];
        last.after('<div class="col-12 col-xl-3">' + addFileForm(current_id, parseInt(last_id) + 1, 'images', "Choisir une image de slideshow (format .jpg/.jpeg)", 'image', 'jpg,jpeg') + '</div>');
    })
});

function addFileForm(id, idImage, icon, text, mime, filetypes) {
    output = '<div class="form-group">';
    output += '<form role="form" enctype="multipart/form-data">';
    output += '<label for="file_upload_' + id + '_' + idImage + '"><i class="fad fa-' + icon + ' pl-1 pr-2"></i>' + text + '</label>';
    output += '<div class="input-group">';
    output += '<div class="custom-file">';
    output += '<input type="file" class="custom-file-input" id="file_upload_' + id + '_' + idImage + '" data-mime="' + mime + '" data-filetypes="' + filetypes + '">';
    output += '<label class="custom-file-label mt-0" for="file_upload_' + id + '_' + idImage + '">Choisir un fichier à envoyer</label>';
    output += '</div>';
    output += '<div class="input-group-append">';
    output += '<span class="input-group-text" id="file_choice_' + id + '_' + idImage + '"><i class="fas fa-images"></i></span>';
    output += '</div>';
    output += '<div class="input-group-append">';
    output += '<span class="input-group-text" id="file_remove_' + id + '_' + idImage + '"><i class="fas fa-trash-alt"></i></span>';
    output += '</div>';
    output += '</div>';
    output += '<input type="hidden" id="file_result_' + id + '_' + idImage + '">';
    output += '<div id="file_display_' + id + '_' + idImage + '" class="text-center">';
    output += '</div>';
    output += '</form>';
    output += '</div>';
    return output;
};