$(document).ready( function () {

	// Génération de la table des catégories d'option
    optionspro_admin_table = $('#optionspro_admin_table').DataTable( {
        "autoWidth": false,
    	"language": {
            "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/fr-FR.json"
        },
        "responsive": true,
    	"ajax": siteURL + 'json/generateuroptionspro_admin',
    	"order": [[ 1, "asc" ], [0, "asc"]],
    	"columnDefs": [
            // TEXT CENTER
            {
                "className": "text-center align-middle",
                "targets": [3,4,5,6],
            },
            // ALIGN MIDDLE
            {
                "className": "align-middle",
                "targets": '_all',
            },
            // NOM
    		{
                "targets": 0,
    			"render": function ( data, type, row ) {
                    return row[1];
                },
    		},
            // CATEGORIE
            {
                "targets": 1,
                "render": function ( data, type, row ) {
                    return row[3];
                },
            },
            // TYPE
            {
                "targets": 2,
                "render": function ( data, type, row ) {
                    if (row[2] == "tv") { return "TOUS"; }
                    else if (row[2] == "vl") { return "VL"; }
                    else if(row[2] == "vu") { return "VU"; }
                },
            },
            // ORDRE
            {
                "width": "90px",
                "targets": 3,
                "render": function ( data, type, row ) {
                    return row[5];
                },
            },
            // CUSTOM
            {
                "width": "90px",
                "targets": 4,
                "render": function ( data, type, row ) {
                    if (row[4] == 1) {
                        return '<i class="fal fa-check-square"></i><span class="d-inline d-lg-none pl-2">OUI</span>';
                    }
                    else {
                        return '<i class="fal fa-square"></i><span class="d-inline d-lg-none pl-2">NON</span>';
                    }
                },
            },
            // IMAGE
            {
                "width": "90px",
                "targets": 5,
                "render": function ( data, type, row ) {
                    if (row[6] == null) {
                        return '-';
                    }
                    else {
                        return '<img style="width:36px; height:36px" src="'+row[6]+'">';
                    }
                },
            },
            // MODIFY
    		{
                "width": "50px",
                "targets": 6,
    			"render": function ( data, type, row ) {
                    return '<button class="btn w-100 p-0" id="optionspro_admin-open-modify-'+row[0]+'" data-category="outils"><i class="fad fa-edit"></i>';
                },
                "orderable": false,
    		},
    	],
        "conditionalPaging": true,
    });



    optionspro_admin_categorie_table = $('#optionspro_admin_categorie_table').DataTable( {
        "autoWidth": false,
        "language": {
            "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/fr-FR.json"
        },
        "responsive": true,
        "ajax": siteURL + 'json/generateuroptionspro_categorie_admin',
        "order": [[ 1, "asc" ], [0, "asc"]],
        "columnDefs": [
            // ALIGN MIDDLE
            {
                "className": "align-middle",
                "targets": '_all',
            },
            // NOM
            {
                "targets": 0,
                "render": function ( data, type, row ) {
                    return row[1];
                },
            },
            // MODIFY
            {
                "width": "50px",
                "targets": 1,
                "render": function ( data, type, row ) {
                    return '<button class="btn w-100 p-0" id="optionspro_admin_categorie-open-modify-'+row[0]+'" data-category="outils"><i class="fad fa-edit"></i>';
                },
                "orderable": false,
            },
        ],
        "conditionalPaging": true,
    });

    // Envoi de modification d'une option
    $('body').on('click', "button[id^='generateuroptionspro-send-modify-']", function() {
        var id_clicked = this.id.replace('generateuroptionspro-send-modify-', '');

        var form = new FormData();
        // On extrait le contenu du formulaire dans la variable 'form'
        form.append('id', id_clicked);
        form.append('nom', $('#generateuroptionspro_nom_'+id_clicked).val());
        form.append('type', $('select[name="generateuroptionspro_type_'+id_clicked+'"]').val());
        form.append('categorie', $('select[name="generateuroptionspro_categorie_'+id_clicked+'"]').val());
        form.append('ordre', $('select[name="generateuroptionspro_ordre_'+id_clicked+'"]').val());
        form.append('custom', $('select[name="generateuroptionspro_custom_'+id_clicked+'"]').val());
        form.append('image', $('#file_result_0').val());

        // Requète ajax d'envoi du formulaire : /src/Controller/AdminController.php -> admin_optionspro_admin()
        $.ajax({
            type: 'POST',
            url: siteURL + 'admin/outils/optionspro_admin/modify',
            cache: false,
            contentType: false,
            processData: false,
            data: form,
            success: function(data) {
                // TOAST
                toastr.success('L\'option a bien été enregistrée', 'Succès');
                // On nettoye le html en supprimant l'onglet 'Modifier/Nouveau'
                $('#optionspro_admin-tabs-modify-'+id_clicked).remove();
                $('#optionspro_admin-close-modify-'+id_clicked).parent().remove();
                // Si nous sommes sur une nouvelle option
                if (id_clicked == 0) {
                    $('#formoptionspro_admin_'+id_clicked)[0].reset();
                }
                // Retour à la liste des options en rechargeant la datatable
                $('#optionspro_admin-tabs-list-tab').trigger('click');
                optionspro_admin_table.ajax.reload();
            }
        });
        // Empèche le 'button' de fonctionner
        return false;
    });

    // Envoi de modification d'une catégorie d'option
    $('body').on('click', "button[id^='generateuroptionscategorie-send']", function() {
        var form = new FormData();
        // On extrait le contenu du formulaire dans la variable 'form'
        form.append('nom', $('#generateuroptionscategorie').val());

        // Requète ajax d'envoi du formulaire : /src/Controller/AdminController.php -> admin_optionspro_admin()
        $.ajax({
            type: 'POST',
            url: siteURL + 'admin/outils/optionspro_admin/add-category',
            cache: false,
            contentType: false,
            processData: false,
            data: form,
            success: function(data) {
                // TOAST
                result = data.split(";");
                if (result == "exists") {
                    toastr.error('L\'option en question existe déjà', 'Succès');
                    $('#formcategorie')[0].reset();
                }
                else if (result != "exists") {
                    toastr.success('L\'option a bien été ajoutée', 'Succès');
                    $('select[name="generateuroptionspro_categorie_0"]').append('<option value="'+data+'">'+$('#generateuroptionscategorie').val()+'</option>');
                    // Si nous sommes sur une nouvelle catégorie d'option
                    $('#formcategorie')[0].reset();
                    // Retour à la liste des catégories d'option en rechargeant la datatable
                    $('#optionspro_admin-tabs-list-tab').trigger('click');
                    optionspro_admin_table.ajax.reload();
                }

            }
        });
        // Empèche le 'button' de fonctionner
        return false;
    });


    $('body').on('click', "button[id^='optionspro_admin_categorie-send-modify-']", function() {
        var id_clicked = this.id.replace('optionspro_admin_categorie-send-modify-', '');
        var form = new FormData();
        // On extrait le contenu du formulaire dans la variable 'form'
        form.append('id', id_clicked);
        form.append('libelle', $('#optionspro_admin_categorie_libelle_'+id_clicked).val());

        // Requète ajax d'envoi du formulaire : /src/Controller/AdminController.php -> admin_optionspro_admin()
        $.ajax({
            type: 'POST',
            url: siteURL + 'admin/outils/optionspro_admin_categorie/modify',
            cache: false,
            contentType: false,
            processData: false,
            data: form,
            success: function(data) {
                // TOAST
                toastr.success('La catégorie a bien été modifiée', 'Succès');
                // On nettoye le html en supprimant l'onglet 'Modifier/Nouveau'
                $('#optionspro_admin_categorie-tabs-modify-'+id_clicked).remove();
                $('#optionspro_admin_categorie-close-modify-'+id_clicked).parent().remove();
                // Si nous sommes sur une nouvelle catégorie d'option
                if (id_clicked == 0) {
                    $('#formoptionspro_admin_categorie_'+id_clicked)[0].reset();
                }
                // Retour à la liste des catégories d'option en rechargeant la datatable
                $('#optionspro_admin_categorie-tabs-list-tab').trigger('click');
                optionspro_admin_categorie_table.ajax.reload();
            }
        });
        // Empèche le 'button' de fonctionner
        return false;
    });


});