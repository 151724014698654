// Génération du lien leboncoin
function leboncoinLinkGenerator(vehicule_fournisseur_marque, vehicule_fournisseur_modele, vehicule_fournisseur_boite, vehicule_fournisseur_carburant, vehicule_fournisseur_date_mec, vehicule_fournisseur_kilometrage) {
    // URL de base
    let url_leboncoin = "https://www.leboncoin.fr/recherche?category=2&sort=price&order=asc";

    // Les marques passent en MAJUSCULE
    if (vehicule_fournisseur_marque.length != 0) {
        url_leboncoin = url_leboncoin + "&u_car_brand=" + vehicule_fournisseur_marque.toUpperCase();
    }
    // Les modèles passent en Camel Case avec espace
    if (vehicule_fournisseur_modele.length != 0) {
        vf_leboncoin_modele = vehicule_fournisseur_modele.toLowerCase().split(" ");
        for (let i = 0; i < vf_leboncoin_modele.length; i++) {
            if (vf_leboncoin_modele[i].length) {
                vf_leboncoin_modele[i] = vf_leboncoin_modele[i][0].toUpperCase() + vf_leboncoin_modele[i].substring(1);
            }
        }
        url_leboncoin = url_leboncoin + "&u_car_model=" + vehicule_fournisseur_marque.toUpperCase() + "_" + vf_leboncoin_modele.join(" ");
    }
    // Les boites {A -> 2, M -> on ne met rien}
    if (vehicule_fournisseur_boite.length != 0) {
        if (vehicule_fournisseur_boite == "A") {
            url_leboncoin = url_leboncoin + "&gearbox=" + "2";
        }
    }
    // Les carburants {
    //    ES -> 1
    //    GO -> 2
    //    EH -> 3
    //    EL -> 4
    //    GP -> 5
    // }
    if (vehicule_fournisseur_carburant.length != 0) {
        url_leboncoin = url_leboncoin + "&fuel=" + (vehicule_fournisseur_carburant == "ES" ? "1" : (vehicule_fournisseur_carburant == "GO" ? "2" : (vehicule_fournisseur_carburant == "EH" ? "6" : (vehicule_fournisseur_carburant == "EL" ? "4" : (vehicule_fournisseur_carburant == "GP" ? "3" : "")))));
    }
    // Kilométrage :
    // Arrondi à la dizaine de millier si ni essence et ni diesel
    //
    // kmPerYearMin = {essence -> 10000, diesel -> 17500}
    // kmPerYearMax = {essence -> 15000, diesel -> 25000}
    // distMin = (année actuel - année MEC) * kmPerYearMin
    // distMax = (année actuel - année MEC) * kmPerYearMax
    // Arrondi à la dizaine de millier si essence ou diesel et si le kilometrage est compris entre distMin et distMax
    // Si < distMin alors distMin
    // Si > distMax alors viellir le véhicule de (année actuel - (kilometrage du véhicule / kmPerYearMax))
    if (vehicule_fournisseur_kilometrage.length != 0) {
        if (vehicule_fournisseur_carburant != "ES" && vehicule_fournisseur_carburant != "GO" && vehicule_fournisseur_carburant != "EH") {
            url_leboncoin = url_leboncoin + "&mileage=min-" + Math.ceil(parseFloat(vehicule_fournisseur_kilometrage) / 10000) * 10000;
        } else if (vehicule_fournisseur_date_mec.length != 0) {
            yearDiff = (new Date().getFullYear()) - parseInt(vehicule_fournisseur_date_mec) + 1;
            if (vehicule_fournisseur_carburant == "ES" || vehicule_fournisseur_carburant == "EH") {
                let kmPerYearMin = 10000;
                let kmPerYearMax = 15000;
                distMin = yearDiff * kmPerYearMin;
                distMax = yearDiff * kmPerYearMax;
                if (parseFloat(vehicule_fournisseur_kilometrage) < distMin) {
                    vf_kilometrage_url = distMin;
                } else if (parseFloat(vehicule_fournisseur_kilometrage) <= distMax) {
                    vf_kilometrage_url = Math.ceil(parseFloat(vehicule_fournisseur_kilometrage) / 10000) * 10000;
                } else {
                    vf_kilometrage_url = Math.ceil(parseFloat(vehicule_fournisseur_kilometrage) / 10000) * 10000;
                    vehicule_fournisseur_date_mec = parseInt(vehicule_fournisseur_date_mec) - Math.floor(parseFloat(vehicule_fournisseur_kilometrage) / kmPerYearMax);
                }
            } else if (vehicule_fournisseur_carburant == "GO") {
                let kmPerYearMin = 17500;
                let kmPerYearMax = 25000;
                distMin = yearDiff * kmPerYearMin;
                distMax = yearDiff * kmPerYearMax;
                if (parseFloat(vehicule_fournisseur_kilometrage) < distMin) {
                    vf_kilometrage_url = distMin;
                } else if (parseFloat(vehicule_fournisseur_kilometrage) <= distMax) {
                    vf_kilometrage_url = Math.ceil(parseFloat(vehicule_fournisseur_kilometrage) / 10000) * 10000;
                } else {
                    vf_kilometrage_url = Math.ceil(parseFloat(vehicule_fournisseur_kilometrage) / 10000) * 10000;
                    vehicule_fournisseur_date_mec = parseInt(vehicule_fournisseur_date_mec) - Math.floor(parseFloat(vehicule_fournisseur_kilometrage) / kmPerYearMax);
                }
            }
            url_leboncoin = url_leboncoin + "&mileage=min-" + vf_kilometrage_url;
        }
    }
    // Date de MEC
    if (vehicule_fournisseur_date_mec.length != 0) {
        url_leboncoin = url_leboncoin + "&regdate=" + vehicule_fournisseur_date_mec + "-max";
    }

    return url_leboncoin;
}
