if ($("#widget-kpi-marchand").length) {
    // Objet de gestion du chargement
    // Étapes de chargement : Tous les utilisateurs pour toutes les fonctionnalités de tous les onglets
    // tab = onglets
    // fn = fonctionnalités
    // user = utilisateurs
    // Architecture de chargement : { tab: { fn: { user: { } } } }
    const widgetKpiMarchand = {
        idTab: 1,
        listTab: $("[name^='widget-kpi-marchand-tab-']").toArray().map((el) => el.getAttribute("name").replace("widget-kpi-marchand-tab-", "")).distinct(),
        nextTab: () => { if (widgetKpiMarchand.idTab < widgetKpiMarchand.listTab.length) { widgetKpiMarchand.idTab++; return false; } else { widgetKpiMarchand.idTab = 1; return true; } },
        getTab: ($id = null) => { return widgetKpiMarchand.listTab[($id == null ? widgetKpiMarchand.idTab : $id) - 1]; },
        idFn: 1,
        listFn: $("[name^='widget-kpi-marchand-fn-'][data-ajax='true']").toArray().map((el) => el.getAttribute("name").replace("widget-kpi-marchand-fn-", "")).distinct(),
        nextFn: () => { if (widgetKpiMarchand.idFn < widgetKpiMarchand.listFn.length) { widgetKpiMarchand.idFn++; return false; } else { widgetKpiMarchand.idFn = 1; return true; } },
        getFn: ($id = null) => { return widgetKpiMarchand.listFn[($id == null ? widgetKpiMarchand.idFn : $id) - 1]; },
        idUser: 1,
        listUser: $("[name^='widget-kpi-marchand-user-']").toArray().map((el) => el.getAttribute("name").replace("widget-kpi-marchand-user-", "")).distinct().filter((item) => parseInt(item)),
        nextUser: () => { if (widgetKpiMarchand.idUser < widgetKpiMarchand.listUser.length) { widgetKpiMarchand.idUser++; return false; } else { widgetKpiMarchand.idUser = 1; return true; } },
        getUser: ($id = null) => { return widgetKpiMarchand.listUser[($id == null ? widgetKpiMarchand.idUser : $id) - 1]; },
    }

    /**
     * Fonction de loading
     * @param {Object} reload
     */
    function widgetLoadKpiMarchand({ reload = null } = {}) {
        // Détermine l'onglet, la fonctionnalité et l'utilisateur concerné par le chargement.
        let tab = (reload == null ? widgetKpiMarchand.getTab() : widgetKpiMarchand.getTab((reload.tab)));
        let fn = (reload == null ? widgetKpiMarchand.getFn() : widgetKpiMarchand.getFn((reload.fn)));
        let user = (reload == null ? widgetKpiMarchand.getUser() : widgetKpiMarchand.getUser((reload.user)));

        // Construit le corps de la requête ajax
        var form = new FormData();
        form.append('tab', tab);
        form.append('fn', fn);
        form.append('user', user);
        let filtre = $("#widget-kpi-marchand")
            .find("[name='widget-kpi-marchand-tab-" + tab + "']")
            .find("[name='widget-kpi-marchand-fn-" + fn + "']")
            .find("[name='widget-kpi-marchand-filtre-" + fn + "']");
        if (filtre.length) { form.append('filtre', filtre.val()); }

        // Requête ajax
        $.ajax({
            type: 'POST',
            url: siteURL + 'admin/widget/kpi-marchand',
            contentType: false,
            processData: false,
            data: form,
            success: function (data) {
                if (data != null) {
                    // Utilisateur concerné par la requête
                    const elToUpdate = $("#widget-kpi-marchand")
                        .find((data.tab === false ? "[name^='widget-kpi-marchand-tab-']" : "[name='widget-kpi-marchand-tab-" + tab + "']"))
                        .find("[name='widget-kpi-marchand-fn-" + fn + "']")
                        .find("[name='widget-kpi-marchand-user-" + user + "']");

                    // Sauvegarde les résultats brut en HTML
                    elToUpdate.data("results", JSON.stringify(data.value));

                    // Affiche l'objectif si il existe
                    if (typeof data.objectif !== undefined) { elToUpdate.find("[name='widget-kpi-marchand-objectifToUpdate']").html(data.objectif); }

                    // Détermine la valeur chiffré
                    let dataC = data.value == null ? 0 : data.value.length;
                    // Si la valeur n'est pas un tableau, transforme en chiffre
                    if (typeof data.value != "object") { dataC = parseInt(data.value); elToUpdate.data("results", parseInt(data.value)); }

                    // Détermine la valeur en pourcentage (arrondi inferieur à 2 chiffre après la virgule)
                    let dataP = (parseInt(elToUpdate.find("[name='widget-kpi-marchand-objectifToUpdate']").html()) != 0 ? Math.floor(((dataC / parseInt(elToUpdate.find("[name='widget-kpi-marchand-objectifToUpdate']").html())) * 100) * 100) / 100 : 0);

                    // Affiche les valeurs et la progress bar
                    elToUpdate.find("[name='widget-kpi-marchand-chiffreToUpdate']").html(dataC);
                    elToUpdate.find("[name='widget-kpi-marchand-pourcentageToUpdate']").html(dataP + "%");
                    elToUpdate.find("[name='widget-kpi-marchand-progressToUpdate']").css("width", dataP + "%");

                    // Total de l'équipe
                    const elToTeamUpdate = $("#widget-kpi-marchand")
                        .find((data.tab === false ? "[name^='widget-kpi-marchand-tab-']" : "[name='widget-kpi-marchand-tab-" + tab + "']"))
                        .find("[name='widget-kpi-marchand-fn-" + fn + "']")
                        .find("[name='widget-kpi-marchand-user-all']");

                    // Récupère les valeurs de tous les utilisateurs pour la fonctionnalité, si la valeur n'a pas été chargé, considère un tableau vide.
                    let dataTotal = $("#widget-kpi-marchand")
                        .find((data.tab === false ? "[name^='widget-kpi-marchand-tab-']" : "[name='widget-kpi-marchand-tab-" + tab + "']"))
                        .find("[name='widget-kpi-marchand-fn-" + fn + "']")
                        .find("[name^='widget-kpi-marchand-user-']").toArray()
                        .filter((item) => item.getAttribute("name") != "widget-kpi-marchand-user-all")
                        .map((el) => JSON.parse(($(el).data("results") == "" ? "[]" : $(el).data("results"))));

                    // Créé un tableau sans les valeurs qui sont des tableaux
                    let dataTotalWithoutArray = dataTotal.distinct().filter((item) => typeof item != "object");
                    // Si ce tableau a une longeur, alors c'est un tableau de valeur (même si le chargement de tous les utilisateurs n'est pas terminé)
                    if (dataTotalWithoutArray.length) {
                        // Additionne toutes les valeurs du tableau
                        dataTotal = dataTotalWithoutArray.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
                    } else {
                        // Concatène tous les tableaux du tableau et distinct pour ne pas compté deux fois les mêmes valeurs
                        dataTotal = [].concat(...dataTotal).distinct();
                    }

                    // Si un objectif est définie
                    if (data.objectif !== undefined) {
                        // Récupère les objectifs de tous les utilisateurs et les cumuls
                        let objectifTotal = $("#widget-kpi-marchand")
                            .find("[name='widget-kpi-marchand-tab-" + tab + "']")
                            .find("[name='widget-kpi-marchand-fn-" + fn + "']")
                            .find("[name^='widget-kpi-marchand-user-']").toArray()
                            .filter((item) => item.getAttribute("name") != "widget-kpi-marchand-user-all")
                            .map((el) => parseInt($(el).find("[name='widget-kpi-marchand-objectifToUpdate']").html()))
                            .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
                        // Affiche la somme des objectifs
                        elToTeamUpdate.find("[name='widget-kpi-marchand-objectifToUpdate']").html(objectifTotal);
                    }

                    // Détermine la valeur chiffré de l'équipe
                    let dataTotalC = dataTotal.length;
                    // Si la valeur n'est pas un tableau, transforme en chiffre
                    if (typeof dataTotal != "object") { dataTotalC = parseInt(dataTotal); }

                    // Détermine la valeur en pourcentage (arrondi inferieur à 2 chiffre après la virgule)
                    let dataTotalP = Math.floor(((dataTotalC / parseInt(elToTeamUpdate.find("[name='widget-kpi-marchand-objectifToUpdate']").html())) * 100) * 100) / 100;

                    // Affiche les valeurs total et la progress bar
                    elToTeamUpdate.find("[name='widget-kpi-marchand-chiffreToUpdate']").html(dataTotalC);
                    elToTeamUpdate.find("[name='widget-kpi-marchand-pourcentageToUpdate']").html(dataTotalP + "%");
                    elToTeamUpdate.find("[name='widget-kpi-marchand-progressToUpdate']").css("width", dataTotalP + "%");
                }
            },
            complete: function () {
                // Réussit ou non, passe à la prochaine requête
                let next = true;

                // Si ce n'est pas une requête de reload
                if (reload == null) {
                    // Passe à l'utilisateur suivant
                    if (widgetKpiMarchand.nextUser()) {
                        // Si il n'y a pas d'utilisateur suivant, passe à la fonction suivante
                        if (widgetKpiMarchand.nextFn()) {
                            // Si il n'y a pas de fonction suivante, passe à l'onglet suivant
                            if (widgetKpiMarchand.nextTab()) {
                                // Si il n'y a pas d'onglet suivant, on stop les requêtes
                                next = false;
                            }
                        }
                    }
                } else {
                    // Si c'est une requête de reload
                    // Si l'utilisateur n'est pas le dernier
                    if (reload.user < widgetKpiMarchand.listUser.length) {
                        // On passe à la prochaine requête avec le prochain utilisateur
                        widgetLoadKpiMarchand({ reload: { tab: reload.tab, fn: reload.fn, user: ++reload.user } });
                    }
                    // On empeche la suite de requête initial
                    next = false;
                }

                // Si on doit passer à la prochaine requête, on passe à la prochaine requête
                if (next) {
                    widgetLoadKpiMarchand();
                } else {
                    // On appelle la requête du prochain widget
                    nextWidgetRequeteAjax();
                }
            }
        });
    }

    // Reload si filtre
    $('body').on('change', "[name^='widget-kpi-marchand-filtre-']", function (e) {
        let tab = widgetKpiMarchand.listTab.findIndex((item) => item == $(this).data("tab")) + 1;
        let fn = widgetKpiMarchand.listFn.findIndex((item) => item == $(this).data("fn")) + 1;
        widgetLoadKpiMarchand({ reload: { tab: tab, fn: fn, user: 1 } });
    });

    // Changement d'affichage pour chiffre
    $('body').on('click', "#widget-kpi-marchand-chiffre", function (e) {
        $("[name='" + this.id.replace("chiffre", "pourcentage") + "']").addClass("d-none");
        $("[name='" + this.id + "']").removeClass("d-none");
    });

    // Changement d'affichage pour pourcentage
    $('body').on('click', "#widget-kpi-marchand-pourcentage", function (e) {
        $("[name='" + this.id.replace("pourcentage", "chiffre") + "']").addClass("d-none");
        $("[name='" + this.id + "']").removeClass("d-none");
    });
};