$(document).ready( function () {

    makeSummerNote('slider_content_0', 200, ['text']);

    // Génération de la datatable des sliders
    slider_table = $('#sliders_table').DataTable( {
        "autoWidth": false,
    	"language": {
            "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/fr-FR.json"
        },
        "responsive": true,
    	"ajax": siteURL + 'json/sliders',
    	"order": [[ 0, "desc" ]],
    	"columnDefs": [
            // TEXT CENTER
            {
                "className": "text-center align-middle",
                "targets": [0,2,3,4],
            },
            //ALIGN MIDDLE
            {
                "className": "text-start",
                "targets": '_all',
            },

            {
                "width": "40px",
                "targets": 0,
                "render": function ( data, type, row ) {
                    return row[0];
                },
                "orderable": true,
            },

    		{
                "targets": 1,
    			"render": function ( data, type, row ) {
                    return "<strong>"+row[1]+"</strong><br />"+row[2];
                },
    		},
            {
                "width": "100px",
                "targets": 2,
                "render": function ( data, type, row ) {
                    return '<span class="text-uppercase">'+row[7]+'</span>';
                },
                "orderable": true,
            },
            {
                "width": "100px",
                "targets": 3,
                "render": function ( data, type, row ) {
                    return row[5];
                },
                "orderable": true,
            },
    		{
                "width": "50px",
                "targets": 4,
                "render": function (data, type, row) {
                    if (row[6] == 1) {
                        return '<i class="far fa-check-square"></i><span class="d-inline d-lg-none pl-2">OUI</span>';
                    }
                    else {
                        return '<i class="far fa-square"></i><span class="d-inline d-lg-none pl-2">NON</span>';
                    }
                },
                "orderable": false,
            },

    		{
                "width": "50px",
                "targets": 5,
    			"render": function ( data, type, row ) {
                    return '<button class="btn w-100 p-0" id="slider-open-modify-'+row[0]+'" data-category="web"><i class="fad fa-edit"></i>';
                },
                "orderable": false,
    		},
    	],
        "conditionalPaging": true,
    });

    // Envoi de modification d'un slider
    $('body').on('click', "button[id^='slider-send-modify-']", function() {
        var clickedId = this.id.split(/[- ]+/).pop();

        var form = new FormData();
        // On extrait le contenu du formulaire dans la variable 'form'
        form.append('id', clickedId);
        form.append('slider_site', $('#slider_site_'+clickedId).val());
        form.append('slider_title', $('#slider_title_'+clickedId).val());
        form.append('slider_subtitle', $('#slider_subtitle_'+clickedId).val());
        // form.append('slider_content', $('#slider_content_'+clickedId).val());
        form.append('slider_isvisible', $('input[name="slider_visible_'+clickedId+'"]:checked').val());
        form.append('slider_link', $('#slider_link_'+clickedId).val());
        form.append('slider_ordre', $('select[name="slider_ordre_'+clickedId+'"]').val());
        form.append('slider_image', $('#file_result_0').val());

        // Requète ajax d'envoi du formulaire : /src/Controller/AdminController.php -> admin_sliders()
        $.ajax({
            type: 'POST',
            url: siteURL + 'admin/web/slider/modify',
            cache: false,
            contentType: false,
            processData: false,
            data: form,
            success: function(data) {
                // Si il s'agit de l'ajout d'un nouveau slider
                if (clickedId == 0) {
                    toastr.success('Le nouveau slider a bien été enregistrée', 'Succès');
                    $('#formslider_0')[0].reset();

                    $("[id^='file_upload_0_']","[id^='file_result_0_']").each( function() {$(this).val('')});
                    $("[id^='file_display_0_").each( function() {$(this).html('')});
                    $("[id*='_content_']").each( function(e) { $(this).summernote('reset'); });
                }
                // Si il s'agit d'une modification de slider
                else {
                    toastr.success('Le slider a bien été modifié', 'Succès');
                    // On nettoye le html en supprimant l'onglet 'Modifier/Nouveau'
                    $('#slider-tabs-modify-'+clickedId).remove();
                    $('#slider-close-modify-'+clickedId).parent().remove();
                }
                // Retour à la liste des sliders en rechargeant la datatable
                publicite_table.ajax.reload();
                $('#slider-tabs-list-tab').trigger('click');
            }
        });
        // Empèche le 'button' de fonctionner
        return false;
    });
});