
$(document).ready(function () {
    if ($("[id^='formvehicule_fournisseur_']").length || $("#vehicule_fournisseur_results").length || $("#vehicule_fournisseur_achete_results").length || $("#offre_fournisseur_results").length) {
        /**
         * CRUD
         */
        $('body').on('click', "button[id^='vehicule_fournisseur-send-modify-']", function (e) {
            e.preventDefault();
            e.target.setAttribute("disabled", "");
            updateVF(e.target.id.replace('vehicule_fournisseur-send-modify-', ''));
        });

        // Ouverture de la modal pour l'envoi par whatsapp
        $('body').on('click', "button[id^='ouverture_modal_message_whatsapp_vf_']", function (e) {
            e.preventDefault();
            let idVF = e.target.id.replace('ouverture_modal_message_whatsapp_vf_', '');

            $('#modal_message_whatsapp_vf_' + idVF).modal('show');
        });

        // Envoi du message de whatsapp
        $('body').on('click', "button[id^='vehicule_fournisseur-send-whatsapp-']", function (e) {
            e.preventDefault();
            let idVF = e.target.id.replace('vehicule_fournisseur-send-whatsapp-', '');

            $('#vehicule_fournisseur-send-modify-' + idVF).attr("data-sendwhatsapp", "1");
            $('#vehicule_fournisseur-send-modify-' + idVF).attr("data-messagewhatsapp", $('#vehicule_fournisseur_input_messageWhatsapp_' + idVF));
            $('#vehicule_fournisseur-send-modify-' + idVF).trigger('click');
            $('#modal_message_whatsapp_vf_' + idVF).modal('hide');
        });
        // CRUD

        /**
         * MAJ Fields
         */

        $('body').on('change keyup', "[id^='vehicule_fournisseur_input_']", updateCalculVF);
        $('body').on('change', "[id^='vehicule_fournisseur_input_vin_']", checkVin);

        // Ouverture de la modal de changement de fournisseur
        $('body').on('change', "[id^='vehicule_fournisseur_input_fournisseur_']", vfOpenModalChangementFournisseur);

        // Changement de fournisseur confirmé, avec update de la devise du pays si besoin
        $('body').on('click', "[id^='vehicule_fournisseur-change-fournisseur-accepte-']", vfChangeFournisseur);

        // Fermeture de la modal de changement de fournisseur
        $('body').on('hidden.bs.modal', "[id^='vehicule_fournisseur-modal-changement-fournisseur-']", vfCloseModalChangementFournisseur);

        // Pour vider le VIN s'il est déjà prit et qu'on s'est trompé
        $('body').on('click', "#vehicule_fournisseur-stay-wrong-vin", function() {
            $('#vehicule_fournisseur_input_vin_0').val('');
        });

        // Update la norme CO² suivant la date d'immat
        $('body').on('change', "[id^='vehicule_fournisseur_input_date_mec_']", updateCO2);

        // Update le champ envoi au commerciaux si le champ d'envoi sur le site pro est séléctionné
        $('body').on('change', "[id^='vehicule_fournisseur_input_is_visible_pro_']", updateAvailable);

        $('body').on('change', "#filtersvehiculefournisseur-marque", loadModele);

        // Controle l'activation et la désactivation du champ modele suivant le champ marque
        $('body').on('change', "[id^='vehicule_fournisseur_input_marque_']", updateMarque);

        $('body').on('click', "label[for^='file_upload_image'] img", function (e) {
            e.preventDefault();
            e.stopPropagation();
            // Stop l'input file pour le clique sur l'image
        });

        // MAJ Fields

        /**
         * MAJ Result
         */

        // Update le champ nom_client_marchand dans la pop-up achat
        $('body').on('change', "[id^='type_achat_']", function () {
            let idVF = this.id.replace("type_achat_", "");
            if ($(this).val() == "achat_client_marchand") {
                $("#nom_client_marchand_" + idVF).removeClass("d-none");
            } else {
                $("#nom_client_marchand_" + idVF).addClass("d-none");
            }
        });

        // Update le champ nom_client_marchand dans la pop-up achat
        $('body').on('change', "#menu-type_achat", function () {
            if ($(this).val() == "achat_client_marchand") {
                $("#menu-nom_client_marchand").removeClass("d-none");
            } else {
                $("#menu-nom_client_marchand").addClass("d-none");
            }
        });

        // Rajoute le click du select sur tout le badge de la sélection des fournisseurs
        $('body').on('click', "[id^='vehicule_fournisseur_select_fournisseur']", function () {
            let idVF = $(this).attr("data-id");
            $('#vehicule_fournisseur_input_fournisseur_' + idVF).selectpicker('toggle');
        });

        // Pour mettre à jour les DIN dans le result
        $('body').on('change', "[id^='vehicule_fournisseur_input_puissance_din_']", function (e) {
            let idVF = e.target.id.replace("vehicule_fournisseur_input_puissance_din_", "");
            $('#result_vehicule_fournisseur_input_puissance_din_' + idVF).html($('#vehicule_fournisseur_input_puissance_din_' + idVF).val() + " ch");
        });

        // Pour mettre à jour la version dans le result
        $('body').on('change', "[id^='vehicule_fournisseur_input_version_']", function (e) {
            let idVF = e.target.id.replace("vehicule_fournisseur_input_version_", "");
            $('#result_vehicule_fournisseur_input_version_' + idVF).html($('#vehicule_fournisseur_input_version_' + idVF).val());
        });

        // Pour mettre à jour la boite dans le result
        $('body').on('change', "[id^='vehicule_fournisseur_input_boite_']", function (e) {
            let idVF = e.target.id.replace("vehicule_fournisseur_input_boite_", "");
            let boite = '';
            if ($('#vehicule_fournisseur_input_boite_' + idVF).val() == "A") {
                boite = "Automatic";
            }
            if ($('#vehicule_fournisseur_input_boite_' + idVF).val() == "M") {
                boite = "Manual";
            }
            $('#result_vehicule_fournisseur_input_boite_' + idVF).html(boite);
        });

        // MAJ Result
    }
});
