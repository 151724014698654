$(document).ready(function () {
    if ($("#annuaire_results").length) {
        function loadAjax() {
            let idAnnuaire = this.id.replace("annuaire-sub-tab-activite_web-", "");
            $('.employePrincipal').prop('selected', true);
            $("#annuaire-tab-activite_web-vehicules_favoris-id_employe_principale-" + idAnnuaire).selectpicker("refresh");
            let form = new FormData();
            form.append('idAnnuaire', idAnnuaire);
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/annuaire/annuaires/activites_web-vehicules_favoris',
                contentType: false,
                processData: false,
                data: form,
                success: function (data) {
                    if (data == null) {
                        toastr.error('Erreur lors du chargement', 'Erreur');
                    } else {
                        $('#annuaire-tab-activite_web-' + idAnnuaire + '-vehiculesFavoris').html(data);
                        initDatatable(idAnnuaire);
                    }
                }
            });
        }

        function initDatatable(idAnnuaire) {
            $("[id^='annuaire-tab-activite_web-table-vehiculesFavoris-" + idAnnuaire + "']").each(function() {
                $(this).DataTable({
                    "autoWidth": false,
                    "language": { "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/fr-FR.json" },
                    "paging": true,
                    "responsive": true,
                    "order": [[0, "desc"]],
                    "conditionalPaging": true,
                    "columnDefs": [
                        { type: 'num', targets: [1, 7, 8] },
                        { "className": "text-center align-middle py-2", "targets": '_all' },
                        { "targets": [0, 9], "orderable": false }
                    ],
                });
            });
        }

        $('body').on('click', "[id^='annuaire-sub-tab-activite_web-']", loadAjax);

        // Pour afficher le tableau des véhicules mis en favori correspondant à l'utilisateur sélectionné
        $('body').on('change', "[id^='annuaire-tab-activite_web-vehicules_favoris-id_employe_principale-']", function (e) {
            let idAnnuaire = this.id.replace("annuaire-tab-activite_web-vehicules_favoris-id_employe_principale-", "");
            let idUtilisateurEspaceMarchandVoulu = $("#annuaire-tab-activite_web-vehicules_favoris-id_employe_principale-" + idAnnuaire).val();

            $("[id^='annuaire-tab-activite_web-table_user-vehiculesFavoris-" + idAnnuaire + "-']").each(function() {
                let idUtilisateurEspaceMarchand = $(this).attr("data-idutilisateurespacemarchand");
                if(idUtilisateurEspaceMarchand != idUtilisateurEspaceMarchandVoulu) {
                    $(this).addClass("d-none");
                } else {
                    $(this).removeClass("d-none");
                }
            });
        });

        // Supprime un véhicule des favoris de l'utilisateur sélectionné
        $('body').on('click', "[id^='annuaire-tab-activite_web-tab-suppression-vehiculesFavoris-']", function (e) {
            let idVehicule = this.id.replace("annuaire-tab-activite_web-tab-suppression-vehiculesFavoris-", "");
            let idUtilisateurEspaceMarchand = $(this).attr("data-idutilisateurespacemarchand");
            let idPanier = $(this).attr("data-idpanier");

            let form = new FormData();
            form.append('idVehicule', idVehicule);
            form.append('idUtilisateurEspaceMarchand', idUtilisateurEspaceMarchand);
            form.append('idPanier', idPanier);
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/annuaire/annuaires/activite_web-vehicules_favoris/suppressionfavori',
                contentType: false,
                processData: false,
                data: form,
                success: function () {
                    $("#annuaire-tab-activite_web-ligne-utilisateur-" + idUtilisateurEspaceMarchand + "-vehicule-" + idVehicule).remove();
                    toastr.success('Le véhicule a été retiré.', 'Succes');
                }
            });
        });

        // Affichage de la modal d'ajout de véhicule en favori
        $('body').on('click', "[id^='annuaire-tab-activite_web-tab-ouverture_modal-vehiculesFavoris-']", function (e) {
            let idAnnuaire = this.id.replace("annuaire-tab-activite_web-tab-ouverture_modal-vehiculesFavoris-", "");
            let idUtilisateurEspaceMarchand = $("#annuaire-tab-activite_web-vehicules_favoris-id_employe_principale-" + idAnnuaire).val();

            $("#proposition_vehicule_email-modaladd").find(".proposition_vehicule_email-modal-body").addClass("d-none");
            $("#proposition_vehicule_email-modaladd").find(".proposition_vehicule_email-modal-load").removeClass("d-none");
            let form = new FormData();
            form.append('component', "proposition_vehicule_email_add");
            form.append('listId', $("#annuaire-tab-activite_web-table-vehiculesFavoris-" + idAnnuaire + "-" + idUtilisateurEspaceMarchand).data("list"));
            form.append('isPro', $("#proposition_vehicule_email-annuaire").find(":selected").data("ispro"));
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/stock/proposition/component',
                contentType: false,
                processData: false,
                data: form,
                success: function (html) {
                    $("#favori_espace_marchand-modaladd").find(".favori_espace_marchand-modal-body").html(html);
                    proposition_vehicule_email_table = $("#proposition_vehicule_email-tableadd").DataTable({
                        "autoWidth": false,
                        "language": { "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/fr-FR.json" },
                        "paging": false,
                        "responsive": true,
                        "order": [[1, "asc"], [2, "asc"]],
                        "conditionalPaging": true,
                        "columnDefs": [
                            { type: 'num', targets: [1, 4, 5] },
                            { "className": "text-center align-middle", "targets": '_all' }, // ALIGN MIDDLE
                            { "targets": [8], "orderable": false }
                        ]
                    });
                    $('#proposition_vehicule_email-tableadd').on('draw.dt', function () {
                        $("#favori_espace_marchand-modaladd").find(".favori_espace_marchand-modal-load").addClass("d-none");
                        $("#favori_espace_marchand-modaladd").find(".favori_espace_marchand-modal-body").removeClass("d-none");
                    });
                }
            });
        });

        // Ajoute au tableau et à l'utilisateur sélectionné le véhicule en favori
        $('body').on('click', "[id^='proposition_vehicule_email-tableadd-add-']", function (e) {
            let idAnnuaire = $(this).parentsUntil("#favori_espace_marchand-modaladd").last().parent().data("idannuaire");
            let idVehicule = this.id.replace("proposition_vehicule_email-tableadd-add-", "");
            let idUtilisateurEspaceMarchand = $("#annuaire-tab-activite_web-vehicules_favoris-id_employe_principale-" + idAnnuaire).val();

            let form = new FormData();
            form.append('idAnnuaire', idAnnuaire);
            form.append('idVehicule', idVehicule);
            form.append('idUtilisateurEspaceMarchand', idUtilisateurEspaceMarchand);
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/annuaire/annuaires/activite_web-vehicules_favoris/ajoutfavori',
                contentType: false,
                processData: false,
                data: form,
                success: function (data) {
                    if (data != "") {
                        proposition_vehicule_email_table.row($("#proposition_vehicule_email-tableadd-add-" + idVehicule).parents("tr")).node().remove();
                        toastr.success('Le véhicule a été ajouté.', 'Succes');
                    } else {
                        toastr.error('Le véhicule n\'a pas pu être ajouté.', 'Erreur');
                    }
                }
            });
        });

        // Pour rafraîchir l'onglet Activité Web
        $('body').on('hidden.bs.modal', "#favori_espace_marchand-modaladd", function (e) {
            let idAnnuaire = $(this).data("idannuaire");
            let idUtilisateurEspaceMarchand = $("#annuaire-tab-activite_web-vehicules_favoris-id_employe_principale-" + idAnnuaire).val();

            let form = new FormData();
            form.append('idAnnuaire', idAnnuaire);
            form.append('idUtilisateurEspaceMarchand', idUtilisateurEspaceMarchand);
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/annuaire/annuaires/activite_web-vehicules_favoris/refreshfavori',
                contentType: false,
                processData: false,
                data: form,
                success: function (data) {
                    if (data != "") {
                        $('#annuaire-tab-activite_web-' + idAnnuaire + '-vehiculesFavoris').html(data);
                        initDatatable(idAnnuaire);
                    } else {
                        toastr.error('Erreur dans le rafraîchissement de l\'onglet', 'Erreur');
                    }
                }
            });
        });
    }
});
