$(document).ready( function () {

    // Génération de la datatable des publicites
    accueilpublicite_table = $('#accueilpublicites_table').DataTable( {
        "autoWidth": false,
    	"language": {
            "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/fr-FR.json"
        },
        "responsive": true,
    	"ajax": siteURL + 'json/accueilpublicites',
    	"order": [[ 0, "desc" ]],
    	"columnDefs": [
            { responsivePriority: 1, targets: 0 },
            { responsivePriority: 2, targets: 3 },
            { responsivePriority: 3, targets: 1 },
            // TEXT CENTER
            {
                "className": "text-center align-middle",
                "targets": [0,2,5,6],
            },
            //ALIGN MIDDLE
            {
                    "className": "text-start",
                    "targets": '_all',
            },
            // ID
            {
                "width": "70px",
                "targets": 0,
                "render": function ( data, type, row ) {
                    return row[0];
                },
            },
            // TITLE
    		{
                "targets": 1,
    			"render": function ( data, type, row ) {
                    return "<strong>"+row[1]+"</strong>";
                },
    		},
            // TEXTE
            {
                "className": "text-start",
                "targets": 2,
                "render": function ( data, type, row ) {
                    return row[2];
                },
            },
            // DATES
            {
                "width": "120px",
                "targets": 3,
                "render": function (data, type, row) {
                    if (row[4] != "") {
                        return "<code><b>Début&nbsp;</b>:&nbsp;</code>" + row[4] + "<br /> <code><b>Fin&nbsp;&nbsp;&nbsp;</b>:&nbsp;</code>" + row[5];
                    }
                    else {
                        return "";
                    }
                },
                "orderable": false,
            },
            // LIEN
            {
                "targets": 4,
                "render": function (data, type, row) {
                    return "<span class='text-lowercase'>"+row[3]+"</span>";
                },
                "orderable": false,
            },
            // SITE
            {
                "targets": 5,
                "render": function (data, type, row) {
                    return row[7];
                },
                "orderable": false,
            },
            // VISIBLE
    		{
                "width": "50px",
                "targets": 6,
                "render": function (data, type, row) {
                    if (row[6] == 1) {
                        return '<i class="far fa-check-square"></i><span class="d-inline d-lg-none pl-2">OUI</span>';
                    }
                    else {
                        return '<i class="far fa-square"></i><span class="d-inline d-lg-none pl-2">NON</span>';
                    }
                },
                "orderable": false,
            },
            // MODIFY
    		{
                "width": "50px",
                "targets": 7,
    			"render": function ( data, type, row ) {
                    return '<button class="btn w-100 p-0" id="accueilpublicite-open-modify-'+row[0]+'" data-category="web"><i class="fad fa-edit"></i>';
                },
                "orderable": false,
    		},
    	],
        "conditionalPaging": true,
    });

    // Envoi de modification d'une publicité
    $('body').on('click', "button[id^='accueilpublicite-send-modify-']", function() {
        var clickedId = this.id.split(/[- ]+/).pop();

        var form = new FormData();
        // On extrait le contenu du formulaire dans la variable 'form'
        form.append('id', clickedId);
        form.append('publicite_titre', $('#accueilpublicite_titre_'+clickedId).val());
        form.append('publicite_texte', $('#accueilpublicite_texte_'+clickedId).val());
        form.append('publicite_lien', $('#accueilpublicite_lien_'+clickedId).val());
        form.append('publicite_datedebut', $('#accueilpublicite_datedebut_'+clickedId).val());
        form.append('publicite_datefin', $('#accueilpublicite_datefin_'+clickedId).val());
        form.append('publicite_isvisible', $('input[name="accueilpublicite_visible_'+clickedId+'"]:checked').val());
        form.append('publicite_image', $('#file_result_0').val());
        form.append('publicite_site', $('#accueilpublicite_site_'+clickedId).val());

        // Requète ajax d'envoi du formulaire : /src/Controller/AdminController.php -> admin_publicites()
        $.ajax({
            type: 'POST',
            url: siteURL + 'admin/web/accueilpublicite/modify',
            cache: false,
            contentType: false,
            processData: false,
            data: form,
            success: function(data) {
                // Si il s'agit de l'ajout d'une nouvelle publicité
                if (clickedId == 0) {
                    toastr.success('La nouvelle publicité a bien été enregistrée', 'Succès');
                    $('#formaccueilpublicite_0')[0].reset();

                    $("[id^='file_upload_0_']","[id^='file_result_0_']").each( function() {$(this).val('')});
                    $("[id^='file_display_0_").each( function() {$(this).html('')});
                }
                // Si il s'agit d'une modification de publicité
                else {
                    toastr.success('La publicité a bien été modifiée', 'Succès');
                    // On nettoye le html en supprimant l'onglet 'Modifier/Nouveau'
                    $('#accueilpublicite-tabs-modify-'+clickedId).remove();
                    $('#accueilpublicite-close-modify-'+clickedId).parent().remove();
                }
                // Retour à la liste d'actualités en rechargeant la datatable
                accueilpublicite_table.ajax.reload();
                $('#accueilpublicite-tabs-list-tab').trigger('click');
            }
        });
        // Empèche le 'button' de fonctionner
        return false;
    });
});