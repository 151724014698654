/*
  /admin/vehicules/boites
 */

// Sauvegarde d'une boîte de vitesse
$('[id^="change_boite_"]:not([id^="change_boite_choice"]):not([id^="change_boite_button"]):not([id^="change_boite_current"])').on('click', function() {

    var clickedId = this.id.replace('change_boite_', '');
    var selectedboite = $('#change_boite_choice_'+clickedId+" option:selected").val();
    var currentboite = $('#change_boite_current_'+clickedId).val();

    // Si aucune boite n'a été selectionnée
    if (selectedboite == "") {
        toastr.error('Merci de selectionner une boite', "Erreur");
        return false;
    }

    // Initialisation du Form à envoyer
    var form = new FormData();
    form.append('id', clickedId);
    form.append('boite_origine', currentboite);
    form.append('boite_remplacement', selectedboite);

    // Requète Ajax
    $.ajax({
        type: 'POST',
        url: siteURL+ 'admin/vehicules/boite/save',
        data: form,
        contentType: false,
        processData: false,
        success: function(data) {
            if (data == "ok") {
                toastr.success('La boite a été modifiée', "Done");
                $('#change_boite_button_'+clickedId).addClass('bg-success').html('<i class="fas fa-check"></i>');
            }
            else {
                toastr.error('Une erreur est survenue', "Erreur");
            }
        }
    });

});


// Mise à jour d'une boîte de vitesse
$('.update_boite').on('click', function() {

    var currentboite = $(this).parent().parent().prev().find('div:not(.update_boite)').find('input').val();
    var selectedboite = $(this).prev().find('option:selected').val();
    var toModify = $(this).find('span');

    // Si aucune boite n'a été selectionnée
    if (selectedboite == "") {
        toastr.error('Merci de selectionner une boite', "Erreur");
        return false;
    }

    // Initialisation du Form à envoyer
    var form = new FormData();
    form.append('boite_origine', currentboite);
    form.append('boite_remplacement', selectedboite);

    // Requète Ajax
    $.ajax({
        type: 'POST',
        url: siteURL + 'admin/vehicules/boite/update',
        data: form,
        contentType: false,
        processData: false,
        success: function(data) {
            if (data == "ok") {
                toastr.success('La boite a été modifiée ainsi que pour ses véhicules associés', "Done");
                toModify.addClass('bg-success').html('<i class="fas fa-check"></i>');
            }
            else {
                toastr.error('Une erreur est survenue', "Erreur");
            }
        }
    });

});