$(document).ready(function () {
    if ($("#annuaire_results").length) {
        // Affiche les champs adapté du formulaire
        $('body').on('change', "[id^='annuaire_isPro_']", function () {
            let idAnnuaire = this.id.replace("annuaire_isPro_", "");
            $("#annuaire-sub-tab_pane-info-" + idAnnuaire)[0].setAttribute("annuaire-ispro", this.value);
        });

        // Affiche les champs adapté du formulaire
        $('body').on('change', "[id^='annuaire_typeEntreprise_']", function () {
            let idAnnuaire = this.id.replace("annuaire_typeEntreprise_", "");
            let typeEntreprise = $($("#annuaire_typeEntreprise_" + idAnnuaire)[0].selectedOptions[0]).attr("annuaire-typeEntreprise");
            $("#annuaire-sub-tab_pane-info-" + idAnnuaire)[0].setAttribute("annuaire-typeentreprise", typeEntreprise);
            annuaireDisabledField(idAnnuaire);
        });

        // Mise en forme des numéros de téléphone
        $('body').on('focusout', "[id^='annuaire_telephone']", function () {
            // Comme c'est une fonction "générique" aux numéros de téléphone, on doit voir quel est le type de numéro conserné
            let morceauIdContenu;
            if (this.id.includes('annuaire_telephoneFixe_')) {
                morceauIdContenu = "annuaire_telephoneFixe_";
            } else {
                morceauIdContenu = "annuaire_telephone_";
            }
            let idAnnuaire = this.id.replace(morceauIdContenu, "");
            if ($($("#annuaire_pays_" + idAnnuaire)[0].selectedOptions[0]).attr("data-codepays") == "FR") {
                $(this).val($(this).val().trim().replace(/\D/g, '').replace(/(\d{0,2})(\d{0,2})(\d{0,2})(\d{0,2})(\d{0,2})/g, "$1 $2 $3 $4 $5").trim());
            }
        });

        // Mise en forme des numéros de téléphone lorsque copier/coller dans le champs
        $('body').on('paste', "[id^='annuaire_telephone']", function (e) {
            // Comme c'est une fonction "générique" aux numéros de téléphone, on doit voir quel est le type de numéro conserné
            let morceauIdContenu;
            if (this.id.includes('annuaire_telephoneFixe_')) {
                morceauIdContenu = "annuaire_telephoneFixe_";
            } else {
                morceauIdContenu = "annuaire_telephone_";
            }
            let idAnnuaire = this.id.replace(morceauIdContenu, "");
            if ($($("#annuaire_pays_" + idAnnuaire)[0].selectedOptions[0]).attr("data-codepays") == "FR") {
                e.stopPropagation();
                e.preventDefault();
                let pastedData = e.originalEvent.clipboardData.getData('text');
                e.target.value = pastedData.trim().replace(/\D/g, '').replace(/(\d{0,2})(\d{0,2})(\d{0,2})(\d{0,2})(\d{0,2})/g, "$1 $2 $3 $4 $5").trim();
            }
        });

        // Update du bouton d'appel si le numéro change
        $('body').on('change', "[id^='annuaire_telephone']", function () {
            // Comme c'est une fonction "générique" au numéro de téléphone, on doit voir quel est le nom de l'id réellement pour avoir son id annuaire
            let idAnnuaire;
            // On check où on est pour prendre les bons id
            if (this.id.includes('annuaire_telephoneFixe_')) { // Dans le cas où on est sur le téléphone fixe
                idAnnuaire = this.id.replace("annuaire_telephoneFixe_", ''); // On récupère l'id sur le champs qui a changé
                idChampTelephone = "annuaire_lien_telephoneFixe_" + idAnnuaire; // On construit l'id du champ téléphone qu'on modifie
            } else { // Sinon, c'est qu'on est sur le téléphone tout cours
                idAnnuaire = this.id.replace("annuaire_telephone_", ''); // On récupère l'id sur le champs qui a changé
                idChampTelephone = "annuaire_lien_telephone_" + idAnnuaire; // On construit l'id du champ téléphone qu'on modifie
            }
            // On récupère le nouveau numéro de téléphone en récupérant seulement les numéros (pas d'espace, de point ou autre)
            let nouveauTelephone = $(this).val().trim().replace(/\D/g, '');
            // On récupère le préfixe de numéro du pays
            let prefixPays = $($("#annuaire_pays_" + idAnnuaire)[0].selectedOptions[0]).attr("data-prefix");
            // On enlève, si besoin, le (0) dans le préfixe
            if (prefixPays.includes("(0)")) {
                prefixPays = prefixPays.replace("(0)", "");
                // Et on enlève le premier zéro du numéro si on est dans un pays ayant le préfixe avec (0)
                if (nouveauTelephone.charAt(0) == '0') {
                    nouveauTelephone = nouveauTelephone.substring(1);
                }
            }
            // On construit le nouveau href
            let nouveauHref = "tel:+" + prefixPays + nouveauTelephone;
            // On définit le nouvel href dans la balise du bouton d'appel
            $("#" + idChampTelephone).attr('href', nouveauHref);
        });

        // Update le préfixe téléphonique suivant le pays et update du bouton d'appel
        $('body').on('change', "[id^='annuaire_pays_']", function () {
            let idAnnuaire = this.id.replace("annuaire_pays_", "");
            $("#btnGroupTel_" + idAnnuaire).html("+" + $($(this)[0].selectedOptions[0]).attr("data-prefix"));
            $("#btnGroupTelFixe_" + idAnnuaire).html("+" + $($(this)[0].selectedOptions[0]).attr("data-prefix"));

            // On récupère les numéros de téléphone en récupérant seulement les numéros (pas d'espace, de point ou autre)
            let nouveauTelephoneFixe = $("#annuaire_telephoneFixe_" + idAnnuaire).val().trim().replace(/\D/g, '');
            let nouveauTelephone = $("#annuaire_telephone_" + idAnnuaire).val().trim().replace(/\D/g, '');
            // On récupère le préfixe de numéro du pays
            let prefixPays = $($("#annuaire_pays_" + idAnnuaire)[0].selectedOptions[0]).attr("data-prefix");
            // On enlève, si besoin, le (0) dans le préfixe
            if (prefixPays.includes("(0)")) {
                prefixPays = prefixPays.replace("(0)", "");
                // Et on enlève le premier zéro du numéro si on est dans un pays ayant le préfixe avec (0)
                if (nouveauTelephone.charAt(0) == '0') {
                    nouveauTelephone = nouveauTelephone.substring(1);
                }
                if (nouveauTelephoneFixe.charAt(0) == '0') {
                    nouveauTelephoneFixe = nouveauTelephoneFixe.substring(1);
                }
            }
            // On construit le nouveau href
            let nouveauHrefFixe = "tel:+" + prefixPays + nouveauTelephoneFixe;
            let nouveauHref = "tel:+" + prefixPays + nouveauTelephone;
            // On définit le nouvel href dans la balise du bouton d'appel
            $("#annuaire_lien_telephoneFixe_" + idAnnuaire).attr('href', nouveauHrefFixe);
            $("#annuaire_lien_telephone_" + idAnnuaire).attr('href', nouveauHref);
        });

        // Update la devise suivant le pays
        $('body').on('change', "[id^='annuaire_pays_']", function () {
            let idAnnuaire = this.id.replace("annuaire_pays_", "");
            $("#btnGroupDevise_" + idAnnuaire).html($($(this)[0].selectedOptions[0]).attr("data-devisename"));
        });

        // Update les champs suivant le numéro de siret
        $('body').on('keyup', "[id^='annuaire_siret_']", function () {
            let idAnnuaire = this.id.replace("annuaire_siret_", "");
            callApiSiret(idAnnuaire);
        });

        // Update le lien suivant le lien siteWeb
        $('body').on('keyup', "[id^='annuaire_siteWeb_']", function () {
            let idAnnuaire = this.id.replace("annuaire_siteWeb_", "");
            $("#btnGroupAnnuaireSiteWeb_" + idAnnuaire + " > a").attr("href", $(this).val());
        });

        $('body').on('change', "[id^='annuaire-rgpd-']", function () {
            let form = new FormData();
            form.append('id', this.id.replace("annuaire-rgpd-" + this.id.replace("annuaire-rgpd-", "").replace(/-\d+/g, "") + "-", ""));
            form.append('rgpd', this.id.replace("annuaire-rgpd-", "").replace(/-\d+/g, ""));
            form.append('checked', (this.value == "on" ? "1" : "0"));
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/annuaire/annuaire/rgpd',
                contentType: false,
                processData: false,
                data: form,
                success: function () {
                    toastr.success('Le changement des paramètres de rgpd a bien été pris en compte.', 'Succès');
                }
            });
        });

        $('body').on('click', "[id^='annuaire-badge-']", function () {
            const clickedEl = this;
            let form = new FormData();
            form.append('id', clickedEl.id.replace("annuaire-badge-" + clickedEl.id.replace("annuaire-badge-", "").replace(/-\d+/g, "") + "-", ""));
            form.append('badge', clickedEl.id.replace("annuaire-badge-", "").replace(/-\d+/g, ""));
            form.append('value', clickedEl.getAttribute("data-value"));
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/annuaire/annuaire/badge',
                contentType: false,
                processData: false,
                data: form,
                success: function () {
                    toastr.success('Le changement a bien été pris en compte.', 'Succès');
                    const btn = $(clickedEl).parent().prev();
                    btn.removeClass("btn-success");
                    if ($(clickedEl).hasClass("bg-success")) { btn.addClass("btn-success"); }
                    btn.removeClass("btn-hover-success");
                    if ($(clickedEl).hasClass("bg-hover-success")) { btn.addClass("btn-hover-success"); }
                    btn.removeClass("btn-info");
                    if ($(clickedEl).hasClass("bg-info")) { btn.addClass("btn-info"); }
                    btn.removeClass("btn-hover-info");
                    if ($(clickedEl).hasClass("bg-hover-info")) { btn.addClass("btn-hover-info"); }
                    btn.removeClass("btn-warning");
                    if ($(clickedEl).hasClass("bg-warning")) { btn.addClass("btn-warning"); }
                    btn.removeClass("btn-hover-warning");
                    if ($(clickedEl).hasClass("bg-hover-warning")) { btn.addClass("btn-hover-warning"); }
                    btn.removeClass("btn-danger");
                    if ($(clickedEl).hasClass("bg-danger")) { btn.addClass("btn-danger"); }
                    btn.html($(clickedEl).html());
                }
            });
        });

        // Affichage dynamique des marques Panneaux
        $('body').on('change', "[id^='annuaire_haveMarquePanneau_']", function () {
            let idAnnuaire = this.id.replace("annuaire_haveMarquePanneau_", "");
            if ($(this).val() =="on") {
                $("#annuaire_div_marquePanneau_" + idAnnuaire).removeClass("d-none");
            } else {
                $("#annuaire_div_marquePanneau_" + idAnnuaire).addClass("d-none");
            }
        });

        // Envoi de modification d'un annuaire
        $('body').on('click', "button[id^='annuaire-send-modify-']", function (e) {
            e.preventDefault();
            let idAnnuaire = this.id.replace('annuaire-send-modify-', '');
            let annuaire_isPro = $('#annuaire_isPro_' + idAnnuaire).val();
            let annuaire_typeEntreprise = $('#annuaire_typeEntreprise_' + idAnnuaire).val();
            let annuaire_categories = $('#annuaire_categories_' + idAnnuaire).val();
            let annuaire_siret = $('#annuaire_siret_' + idAnnuaire).val();
            let annuaire_marqueCommercialisee = $('#annuaire_marqueCommercialisee_' + idAnnuaire).val();
            let annuaire_marquePanneau = ($('#annuaire_haveMarquePanneau_' + idAnnuaire).val() == "on" ? $('#annuaire_marquePanneau_' + idAnnuaire).val() : null);
            let annuaire_nomEntreprise = $('#annuaire_nomEntreprise_' + idAnnuaire).val();
            let annuaire_siren = $('#annuaire_siren_' + idAnnuaire).val();
            let annuaire_nomEnseigne = $('#annuaire_nomEnseigne_' + idAnnuaire).val();
            let annuaire_siteWeb = $('#annuaire_siteWeb_' + idAnnuaire).val();
            let annuaire_rcs = $('#annuaire_rcs_' + idAnnuaire).val();
            let annuaire_codeNaf = $('#annuaire_codeNaf_' + idAnnuaire).val();
            let annuaire_formeJuridique = $('#annuaire_formeJuridique_' + idAnnuaire).val();
            let annuaire_tvaIntra = $('#annuaire_tvaIntra_' + idAnnuaire).val();
            let annuaire_dateCreationEntreprise = $('#annuaire_dateCreationEntreprise_' + idAnnuaire).val();
            let annuaire_effectif = $('#annuaire_effectif_' + idAnnuaire).val();
            let annuaire_capitalSocial = $('#annuaire_capitalSocial_' + idAnnuaire).val();
            let annuaire_civilite = $('#annuaire_civilite_' + idAnnuaire).val();
            let annuaire_situationFamilliale = $('#annuaire_situationFamilliale_' + idAnnuaire).val();
            let annuaire_nom = $('#annuaire_nom_' + idAnnuaire).val();
            let annuaire_prenom = $('#annuaire_prenom_' + idAnnuaire).val();
            let annuaire_dateNaissance = $('#annuaire_dateNaissance_' + idAnnuaire).val();
            let annuaire_lieuNaissance = $('#annuaire_lieuNaissance_' + idAnnuaire).val();
            let annuaire_email = $('#annuaire_email_' + idAnnuaire).val();
            let annuaire_telephone = $('#annuaire_telephone_' + idAnnuaire).val();
            annuaire_telephone = annuaire_telephone.trim().replace(/\D/g, ''); // On enlève tout les formatages possibles de numéro
            let annuaire_telephoneFixe = $('#annuaire_telephoneFixe_' + idAnnuaire).val();
            annuaire_telephoneFixe = annuaire_telephoneFixe.trim().replace(/\D/g, ''); // On enlève tout les formatages possibles de numéro
            let annuaire_adresse = $('#annuaire_adresse_' + idAnnuaire).val();
            let annuaire_codePostal = $('#annuaire_codePostal_' + idAnnuaire).val();
            let annuaire_ville = $('#annuaire_ville_' + idAnnuaire).val();
            let annuaire_pays = $('#annuaire_pays_' + idAnnuaire).val();
            let annuaire_isConfiance = ($('#annuaire_isConfiance_' + idAnnuaire).length ? ($('#annuaire_isConfiance_' + idAnnuaire).val() == "on" ? "1" : "0") : null);
            let annuaire_qualification = ($('#annuaire_qualification_' + idAnnuaire).length ? $('#annuaire_qualification_' + idAnnuaire).val() : null);
            let annuaire_qualification_fournisseur = ($('#annuaire_qualification_fournisseur_' + idAnnuaire).length ? $('#annuaire_qualification_fournisseur_' + idAnnuaire).val() : null);
            let annuaire_fraisPlateforme = $('#annuaire_fraisPlateforme_' + idAnnuaire).val();
            let annuaire_typeFre = $('#annuaire_typeFre_' + idAnnuaire).val();
            let annuaire_typeVente = $('#annuaire_typeVente_' + idAnnuaire).val();
            annuaire_geolocalisation = tmpStoreGeo[idAnnuaire];
            let annuaire_commentaire = $('#annuaire_commentaire_' + idAnnuaire).val().trim();
            let annuaire_nomEntrepriseZeendoc = $('#annuaire_nomEntrepriseZeendoc_' + idAnnuaire).val();
            let employeEntreprise_fonctionEmployeEntreprise = employeEntreprise_civilite = employeEntreprise_nom = employeEntreprise_prenom = employeEntreprise_email = employeEntreprise_telephone = "";
            if (idAnnuaire == "0" && annuaire_isPro == "1" && ["marchand", "prestataire"].includes($($('#annuaire_typeEntreprise_' + idAnnuaire)[0].selectedOptions[0]).attr("annuaire-typeentreprise"))) {
                employeEntreprise_fonctionEmployeEntreprise = $('#employeEntreprise_fonctionEmployeEntreprise_' + idAnnuaire).val();
                employeEntreprise_civilite = $('#employeEntreprise_civilite_' + idAnnuaire).val();
                employeEntreprise_nom = $('#employeEntreprise_nom_' + idAnnuaire).val();
                employeEntreprise_prenom = $('#employeEntreprise_prenom_' + idAnnuaire).val();
                employeEntreprise_email = $('#employeEntreprise_email_' + idAnnuaire).val();
                employeEntreprise_telephone = $('#employeEntreprise_telephone_' + idAnnuaire).val();
            }
            let error_message = "";
            if (annuaire_isPro == "0") {
                if (annuaire_nom.length == 0) {
                    error_message += "Le nom n\'a pas été renseigné.<br/>"
                }
                if (annuaire_prenom.length == 0) {
                    error_message += "Le prénom n\'a pas été renseigné.<br/>"
                }
                if (annuaire_email.length == 0) {
                    error_message += "L\'adresse email n\'a pas été renseigné.<br/>"
                } else {
                    const mailReg = /^(([^<>()\/\[\]\\\\.,;:\s@"\'!$]+(\.[^<>()\/\[\]\\\\.,;:\s@"\'!$]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
                    if (!mailReg.test(annuaire_email)) {
                        error_message += "L\'adresse email renseigné n\'est pas valide.<br/>";
                    }
                }
                if (annuaire_telephone.length == 0 && annuaire_telephoneFixe.length == 0) {
                    error_message += "Aucun numéro de téléphone n\'a pas été renseigné.<br/>"
                } else {
                    const telReg = /\d{4,13}/;
                    if (annuaire_telephone.length != 0) {
                        if (!telReg.test(annuaire_telephone)) {
                            error_message += "Le numéro de téléphone portable renseigné n\'est pas valide.<br/>";
                        }
                    }
                    if (annuaire_telephoneFixe.length != 0) {
                        if (!telReg.test(annuaire_telephoneFixe)) {
                            error_message += "Le numéro de téléphone fixe renseigné n\'est pas valide.<br/>";
                        }
                    }
                }
                if (annuaire_civilite.length == 0) {
                    error_message += "La civilité n\'a pas été séléctionné.<br/>"
                }
            } else if (annuaire_isPro == "1") {
                if (annuaire_typeEntreprise.length == 0) {
                    error_message += "Le type d'entreprise n\'a pas été séléctionné.<br/>"
                } else if ($($('#annuaire_typeEntreprise_' + idAnnuaire)[0].selectedOptions[0]).attr("annuaire-typeentreprise") == "marchand") {
                    if (annuaire_siret.length == 0) {
                        error_message += "Le siret de l\'entreprise n\'a pas été renseigné.<br/>"
                    }
                    if (annuaire_marqueCommercialisee.length == 0) {
                        error_message += "Les marques commercialisées de l\'entreprise n\'ont pas été renseigné.<br/>"
                    } else if (annuaire_marqueCommercialisee.length == $('#annuaire_marqueCommercialisee_' + idAnnuaire)[0].options.length) {
                        annuaire_marqueCommercialisee = "all";
                    }
                    if (idAnnuaire == "0") {
                        if (employeEntreprise_fonctionEmployeEntreprise.length == 0) {
                            error_message += "La fonction de l'employé n\'a pas été séléctionné.<br/>"
                        }
                        if (employeEntreprise_civilite.length == 0) {
                            error_message += "La civilité n\'a pas été séléctionné.<br/>"
                        }
                        if (employeEntreprise_nom.length == 0) {
                            error_message += "Le nom n\'a pas été renseigné.<br/>"
                        }
                        if (employeEntreprise_prenom.length == 0) {
                            error_message += "Le prénom n\'a pas été renseigné.<br/>"
                        }
                        if (employeEntreprise_email.length == 0) {
                            error_message += "L\'adresse email n\'a pas été renseigné.<br/>"
                        } else {
                            const mailReg = /^(([^<>()\/\[\]\\\\.,;:\s@"\'!$]+(\.[^<>()\/\[\]\\\\.,;:\s@"\'!$]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
                            if (!mailReg.test(employeEntreprise_email)) {
                                error_message += "L\'adresse email renseigné n\'est pas valide.<br/>";
                            }
                        }
                    }
                    if (annuaire_qualification != null && annuaire_qualification.length == 0) {
                        error_message += "La qualification n\'a pas été séléctionné.<br/>"
                    }
                } else if ($($('#annuaire_typeEntreprise_' + idAnnuaire)[0].selectedOptions[0]).attr("annuaire-typeentreprise") == "prestataire") {
                    if (annuaire_siret.length == 0) {
                        error_message += "Le siret de l\'entreprise n\'a pas été renseigné.<br/>"
                    }
                    if (idAnnuaire == "0") {
                        if (employeEntreprise_fonctionEmployeEntreprise.length == 0) {
                            error_message += "La fonction de l'employé n\'a pas été séléctionné.<br/>"
                        }
                        if (employeEntreprise_civilite.length == 0) {
                            error_message += "La civilité n\'a pas été séléctionné.<br/>"
                        }
                        if (employeEntreprise_nom.length == 0) {
                            error_message += "Le nom n\'a pas été renseigné.<br/>"
                        }
                        if (employeEntreprise_prenom.length == 0) {
                            error_message += "Le prénom n\'a pas été renseigné.<br/>"
                        }
                        if (employeEntreprise_email.length == 0) {
                            error_message += "L\'adresse email n\'a pas été renseigné.<br/>"
                        } else {
                            const mailReg = /^(([^<>()\/\[\]\\\\.,;:\s@"\'!$]+(\.[^<>()\/\[\]\\\\.,;:\s@"\'!$]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
                            if (!mailReg.test(employeEntreprise_email)) {
                                error_message += "L\'adresse email renseigné n\'est pas valide.<br/>";
                            }
                        }
                    }
                    if (annuaire_categories.length == 0) {
                        error_message += "Les categories de prestation de l\'entreprise n\'ont pas été renseigné.<br/>"
                    }
                } else if ($($('#annuaire_typeEntreprise_' + idAnnuaire)[0].selectedOptions[0]).attr("annuaire-typeentreprise") == "fournisseur") {
                    if (annuaire_marqueCommercialisee.length == 0) {
                        error_message += "Les marques commercialisées de l\'entreprise n\'ont pas été renseigné.<br/>"
                    } else if (annuaire_marqueCommercialisee.length == $('#annuaire_marqueCommercialisee_' + idAnnuaire)[0].options.length) {
                        annuaire_marqueCommercialisee = "all";
                    }
                    if (annuaire_qualification_fournisseur != null && annuaire_qualification_fournisseur.length == 0) {
                        error_message += "La qualification n\'a pas été séléctionné.<br/>"
                    }
                    if (annuaire_typeFre != null && annuaire_typeFre.length == 0) {
                        error_message += "Le type de FRE fournit n\'a pas été séléctionné.<br/>"
                    }
                    if (annuaire_typeVente != null && annuaire_typeVente.length == 0) {
                        error_message += "Le type de vente n\'a pas été séléctionné.<br/>"
                    }
                }
                if ($($('#annuaire_typeEntreprise_' + idAnnuaire)[0].selectedOptions[0]).attr("annuaire-typeentreprise") != "marchand") {
                    if (annuaire_email.length == 0) {
                        error_message += "L\'adresse email n\'a pas été renseigné.<br/>"
                    }
                }
                if (annuaire_email.length != 0) {
                    const mailReg = /^(([^<>()\/\[\]\\\\.,;:\s@"\'!$]+(\.[^<>()\/\[\]\\\\.,;:\s@"\'!$]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
                    if (!mailReg.test(annuaire_email)) {
                        error_message += "L\'adresse email renseigné n\'est pas valide.<br/>";
                    }
                }
                if (annuaire_telephoneFixe.length != 0) {
                    const telReg = /\d{4,13}/;
                    if (!telReg.test(annuaire_telephoneFixe)) {
                        error_message += "Le numéro de téléphone fixe renseigné n\'est pas valide.<br/>";
                    }
                }
                if (annuaire_tvaIntra.length == 0) {
                    error_message += "Le numéro de TVA intracommunautaire n\'a pas été renseigné.<br/>"
                }
                if (annuaire_adresse.length == 0) {
                    error_message += "L'adresse n\'a pas été renseigné.<br/>"
                }
                if (annuaire_codePostal.length == 0) {
                    error_message += "Le code postal n\'a pas été renseigné.<br/>"
                }
                if (annuaire_ville.length == 0) {
                    error_message += "La ville n\'a pas été renseigné.<br/>"
                }
            } else {
                error_message += "Le type de contact n\'a pas été séléctionné.<br/>"
            }
            if (error_message != "") {
                toastr.error(error_message, 'Erreur');
            } else {
                let form = new FormData();
                form.append('id', idAnnuaire);
                form.append('annuaire_isPro', annuaire_isPro);
                form.append('annuaire_typeEntreprise', annuaire_typeEntreprise);
                form.append('annuaire_categories', annuaire_categories);
                form.append('annuaire_siret', annuaire_siret);
                form.append('annuaire_marqueCommercialisee', annuaire_marqueCommercialisee);
                form.append('annuaire_marquePanneau', annuaire_marquePanneau);
                form.append('annuaire_nomEntreprise', annuaire_nomEntreprise);
                form.append('annuaire_siren', annuaire_siren);
                form.append('annuaire_nomEnseigne', annuaire_nomEnseigne);
                form.append('annuaire_siteWeb', annuaire_siteWeb);
                form.append('annuaire_rcs', annuaire_rcs);
                form.append('annuaire_codeNaf', annuaire_codeNaf);
                form.append('annuaire_formeJuridique', annuaire_formeJuridique);
                form.append('annuaire_tvaIntra', annuaire_tvaIntra);
                form.append('annuaire_dateCreationEntreprise', annuaire_dateCreationEntreprise);
                form.append('annuaire_effectif', annuaire_effectif);
                form.append('annuaire_capitalSocial', annuaire_capitalSocial);
                form.append('annuaire_civilite', annuaire_civilite);
                form.append('annuaire_situationFamilliale', annuaire_situationFamilliale);
                form.append('annuaire_nom', annuaire_nom);
                form.append('annuaire_prenom', annuaire_prenom);
                form.append('annuaire_dateNaissance', annuaire_dateNaissance);
                form.append('annuaire_lieuNaissance', annuaire_lieuNaissance);
                form.append('annuaire_email', annuaire_email);
                form.append('annuaire_telephone', annuaire_telephone);
                form.append('annuaire_telephoneFixe', annuaire_telephoneFixe);
                form.append('annuaire_adresse', annuaire_adresse);
                form.append('annuaire_codePostal', annuaire_codePostal);
                form.append('annuaire_ville', annuaire_ville);
                form.append('annuaire_pays', annuaire_pays);
                form.append('annuaire_isConfiance', annuaire_isConfiance);
                form.append('annuaire_qualification', annuaire_qualification);
                form.append('annuaire_qualification_fournisseur', annuaire_qualification_fournisseur);
                form.append('annuaire_fraisPlateforme', annuaire_fraisPlateforme);
                form.append('annuaire_typeFre', annuaire_typeFre);
                form.append('annuaire_typeVente', annuaire_typeVente);
                form.append('annuaire_commentaire', annuaire_commentaire);
                form.append('annuaire_geolocalisation', annuaire_geolocalisation);
                form.append('annuaire_nomEntrepriseZeendoc', annuaire_nomEntrepriseZeendoc);
                if (idAnnuaire == "0" && annuaire_isPro == "1" && ["marchand", "prestataire"].includes($($('#annuaire_typeEntreprise_' + idAnnuaire)[0].selectedOptions[0]).attr("annuaire-typeentreprise"))) {
                    form.append('employeEntreprise_fonctionEmployeEntreprise', employeEntreprise_fonctionEmployeEntreprise);
                    form.append('employeEntreprise_civilite', employeEntreprise_civilite);
                    form.append('employeEntreprise_nom', employeEntreprise_nom);
                    form.append('employeEntreprise_prenom', employeEntreprise_prenom);
                    form.append('employeEntreprise_email', employeEntreprise_email);
                    form.append('employeEntreprise_telephone', employeEntreprise_telephone);
                }
                $.ajax({
                    type: 'POST',
                    url: siteURL + 'admin/annuaire/annuaire/modify',
                    contentType: false,
                    processData: false,
                    data: form,
                    success: function (id_annuaire) {
                        if (isNaN(parseInt(id_annuaire))) { // Si id_annuaire n'est pas un nombre alors c'est un message d'erreur.
                            toastr.error(id_annuaire, 'Erreur');
                        } else {
                            toastr.success('Le contact a bien été enregistrée', 'Succès');
                            annuaireRefreshSideCard(idAnnuaire);
                            if ($("#annuaire-tabs-modify-" + id_annuaire + "-tab").length == 0) {
                                $('#annuaire_isPro_' + idAnnuaire).val('').selectpicker('refresh');
                                $('#annuaire_typeEntreprise_' + idAnnuaire).val('').selectpicker('refresh');
                                $('#annuaire_categories_' + idAnnuaire).val('').selectpicker('refresh');
                                $('#annuaire_siret_' + idAnnuaire).val('');
                                $('#annuaire_marqueCommercialisee_' + idAnnuaire).val('').selectpicker('refresh');
                                $('#annuaire_marquePanneau_' + idAnnuaire).val('').selectpicker('refresh');
                                $('#annuaire_nomEntreprise_' + idAnnuaire).val('');
                                $('#annuaire_siren_' + idAnnuaire).val('');
                                $('#annuaire_nomEnseigne_' + idAnnuaire).val('');
                                $('#annuaire_siteWeb_' + idAnnuaire).val('');
                                $('#annuaire_rcs_' + idAnnuaire).val('');
                                $('#annuaire_codeNaf_' + idAnnuaire).val('');
                                $('#annuaire_formeJuridique_' + idAnnuaire).val('');
                                $('#annuaire_tvaIntra_' + idAnnuaire).val('');
                                $('#annuaire_dateCreationEntreprise_' + idAnnuaire).val('');
                                $('#annuaire_effectif_' + idAnnuaire).val('');
                                $('#annuaire_capitalSocial_' + idAnnuaire).val('');
                                $('#annuaire_civilite_' + idAnnuaire).val('').selectpicker('refresh');
                                $('#annuaire_situationFamilliale_' + idAnnuaire).val('').selectpicker('refresh');
                                $('#annuaire_nom_' + idAnnuaire).val('');
                                $('#annuaire_prenom_' + idAnnuaire).val('');
                                $('#annuaire_dateNaissance_' + idAnnuaire).val('');
                                $('#annuaire_lieuNaissance_' + idAnnuaire).val('');
                                $('#annuaire_email_' + idAnnuaire).val('');
                                $('#annuaire_telephone_' + idAnnuaire).val('');
                                $('#annuaire_telephoneFixe_' + idAnnuaire).val('');
                                $('#annuaire_adresse_' + idAnnuaire).val('');
                                $('#annuaire_codePostal_' + idAnnuaire).val('');
                                $('#annuaire_ville_' + idAnnuaire).val('');
                                $('#annuaire_pays_' + idAnnuaire).prop('selectedIndex', $('#annuaire_fr_id').val());
                                $('#annuaire_isConfiance_' + idAnnuaire).val('off');
                                $('#annuaire_isConfiance_' + idAnnuaire).prop("checked", false);
                                $('#annuaire_doubleCle_attendu_' + idAnnuaire).val('off');
                                $('#annuaire_doubleCle_attendu_' + idAnnuaire).prop("checked", false);
                                $('#annuaire_carteGrise_attendu_' + idAnnuaire).val('off');
                                $('#annuaire_carteGrise_attendu_' + idAnnuaire).prop("checked", false);
                                $('#annuaire_certificatConformiteEuropeen_attendu_' + idAnnuaire).val('off');
                                $('#annuaire_certificatConformiteEuropeen_attendu_' + idAnnuaire).prop("checked", false);
                                $('#annuaire_qualification_' + idAnnuaire).val('').selectpicker('refresh');
                                $('#annuaire_qualification_fournisseur_' + idAnnuaire).val('').selectpicker('refresh');
                                $('#annuaire_typeFre_' + idAnnuaire).val('').selectpicker('refresh');
                                $('#annuaire_typeVente_' + idAnnuaire).val('').selectpicker('refresh');

                                if (idAnnuaire == "0") {
                                    $.ajax({
                                        type: 'POST',
                                        url: siteURL + 'admin/annuaire/annuaire/show_modify',
                                        data: { id: 0 },
                                        success: function (data) {
                                            $("#annuaire-tabs-add").html(data);
                                            annuaireSelectPickerInit("0");
                                        }
                                    });
                                }

                                if (annuaire_isPro == "0") {
                                    $('#annuaire-tabs').append('<li class="nav-item"><a class="nav-link" id="annuaire-tabs-modify-' + id_annuaire + '-tab" data-toggle="pill" href="#annuaire-tabs-modify-' + id_annuaire + '" role="tab" aria-controls="annuaire-tabs-modify-' + id_annuaire + '" aria-selected="false"><i class="pr-2 fal fa-pen"></i>' + annuaire_prenom + ' ' + annuaire_nom.toUpperCase() + ' <span class="d-none d-md-inline"></span><i class="p-2 fal fa-times" id="annuaire-close-modify-' + id_annuaire + '"></i></a></li>');
                                } else {
                                    $('#annuaire-tabs').append('<li class="nav-item"><a class="nav-link" id="annuaire-tabs-modify-' + id_annuaire + '-tab" data-toggle="pill" href="#annuaire-tabs-modify-' + id_annuaire + '" role="tab" aria-controls="annuaire-tabs-modify-' + id_annuaire + '" aria-selected="false"><i class="pr-2 fal fa-pen"></i>' + annuaire_nomEntreprise + ' <span class="d-none d-md-inline"></span><i class="p-2 fal fa-times" id="annuaire-close-modify-' + id_annuaire + '"></i></a></li>');
                                }
                                $.ajax({
                                    type: 'POST',
                                    url: siteURL + 'admin/annuaire/annuaire/show_modify',
                                    data: { id: id_annuaire },
                                    success: function (data) {
                                        $('#annuaire-tabs-list').after('<div class="tab-pane fade" id="annuaire-tabs-modify-' + id_annuaire + '" role="tabpanel" aria-labelledby="annuaire-tabs-modify-' + id_annuaire + '-tab">' + data + '</div>');
                                        $('#annuaire-tabs-modify-' + id_annuaire + '-tab').trigger('click');
                                        annuaireSelectPickerInit(id_annuaire);
                                        annuaireDisabledField(id_annuaire);
                                    }
                                });
                            }
                        }
                    }
                });
            }
        });
    }
});
