// Génération de la table des modeles
modele_table = $('#modele_table').DataTable( {
    "autoWidth": false,
	"language": {
        "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/fr-FR.json"
    },
    "responsive": true,
	"ajax": siteURL + 'json/all_modeles',
	"order": [[ 0, "asc" ]],
	"columnDefs": [
        // TEXT CENTER
        {
            "className": "text-center align-middle",
            "targets": [2,3],
        },
        // ALIGN MIDDLE
        {
            "className": "align-middle",
            "targets": '_all',
        },
        // NOM MODELE
		{
            "targets": 0,
			"render": function ( data, type, row ) {
                return row['modele_name'];
            },
		},
        // NOM MARQUE
        {
            "targets": 1,
            "render": function ( data, type, row ) {
                return row['marque_name'];
            },
        },
        // VISIBLE
        {
            "width": "90px",
            "targets": 2,
            "render": function(data, type, row) {
                if (row["modele_is_visible"] == 1) {
                    return '<i class="fas fa-check" style="color:green" title="Validé"></i><span class="d-inline d-lg-none pl-2">OUI</span>';
                } else {
                    return '<i class="fas fa-times" style="color:red" title="Non Validé"></i><span class="d-inline d-lg-none pl-2">NON</span>';
                }
            },
        },
        // MODIFY
		{
            "width": "50px",
            "targets": 3,
			"render": function ( data, type, row ) {
                return '<button class="btn w-100 p-0" id="modele-open-modify-'+row['id']+'" data-category="vehicules"><i class="fad fa-edit"></i>';
            },
            "orderable": false,
		},
	],
    "conditionalPaging": true,
});


// Envoi de modification d'une modele
$('body').on('click', "button[id^='modele-send-modify-']", function() {
    var id_clicked = this.id.replace('modele-send-modify-', '');

    var form = new FormData();
    // On extrait le contenu du formulaire dans la variable 'form'
    form.append('id', id_clicked);

    form.append('modele_carrosserie', $('#modele_carrosserie_'+id_clicked).val());

    // SEO
    form.append('modele_title', $('#modele_title_'+id_clicked).val());
    // if (!IE11) {
    //     form.append('modele_meta_description', $('#modele_meta_description_'+id_clicked).next().find('.ck-editor__editable').text());
    // }
    // else {
    //     form.append('modele_meta_description', $('#modele_meta_description_'+id_clicked).val());
    // }
    form.append('modele_balise_h1', $('#modele_balise_h1_'+id_clicked).val());
    // form.append('modele_tagline_1', $('#modele_tagline_1_'+id_clicked).val());
    // form.append('modele_tagline_2', $('#modele_tagline_2_'+id_clicked).val());
    if (!IE11) {
        form.append('modele_meta_description', $('#modele_meta_description_'+id_clicked).next().find('.ck-editor__editable').text());
        form.append('modele_tagline_1', $('#modele_tagline_1_'+id_clicked).next().find('.ck-editor__editable').html().replace(/(<p[^>]+?>|<p>|<\/p>)/img, ""));
        form.append('modele_tagline_2', $('#modele_tagline_2_'+id_clicked).next().find('.ck-editor__editable').html().replace(/(<p[^>]+?>|<p>|<\/p>)/img, ""));
        form.append('modele_description_courte', $('#modele_description_courte_'+id_clicked).next().find('.ck-editor__editable').html());

        form.append('modele_contenu_1', $('#modele_contenu_1_'+id_clicked).next().find('.ck-editor__editable').html());
    }
    else {
        form.append('modele_meta_description', $('#modele_meta_description_'+id_clicked).val());
        form.append('modele_tagline_1', $('#modele_tagline_1_'+id_clicked).val().replace(/(<p[^>]+?>|<p>|<\/p>)/img, ""));
        form.append('modele_tagline_2', $('#modele_tagline_2_'+id_clicked).val().replace(/(<p[^>]+?>|<p>|<\/p>)/img, ""));
        form.append('modele_description_courte', $('#modele_description_courte_'+id_clicked).val());

        form.append('modele_contenu_1', $('#modele_contenu_1_'+id_clicked).val());
    }

    form.append('modele_is_visible', $('input[name="modele_visible_' + id_clicked + '"]:checked').val());

    // Verifie la longeur du texte
    if ($("#modele_title_"+id_clicked).val().length > 200) {
        toastr.error('Merci de reduire le title', 'Erreur');
        return false;
    }
    // Verifie la longeur du texte
    if ($("#modele_meta_description_"+id_clicked).val().length > 249) {
        toastr.error('Merci de reduire la meta description', 'Erreur');
        return false;
    }
    // Verifie la longeur du texte
    if ($("#modele_balise_h1_"+id_clicked).val().length > 200) {
        toastr.error('Merci de reduire la balise h1', 'Erreur');
        return false;
    }
    // Verifie la longeur du texte
    // if ($("#modele_tagline_1_"+id_clicked).val().length > 249) {
    //     toastr.error('Merci de reduire la tagline 1', 'Erreur');
    //     return false;
    // }
    // // Verifie la longeur du texte
    // if ($("#modele_tagline_2_"+id_clicked).val().length > 249) {
    //     toastr.error('Merci de reduire la tagline 2', 'Erreur');
    //     return false;
    // }

    // Verifie la longeur du texte
    // if ($("#modele_titre_1_"+id_clicked).val().length > 249) {
    //     toastr.error('Merci de reduire le titre 1', 'Erreur');
    //     return false;
    // }
    // // Verifie la longeur du texte
    // if ($("#modele_titre_2_"+id_clicked).val().length > 249) {
    //     toastr.error('Merci de reduire le titre 2', 'Erreur');
    //     return false;
    // }
    // // Verifie la longeur du texte
    // if ($("#modele_titre_3_"+id_clicked).val().length > 249) {
    //     toastr.error('Merci de reduire le titre 3', 'Erreur');
    //     return false;
    // }

    // // Verifie la longeur du texte
    // if ($("#modele_description_courte_"+id_clicked).val().length > 999) {
    //     toastr.error('Merci de reduire la description courte', 'Erreur');
    //     return false;
    // }
    // // Verifie la longeur du texte
    // if ($("#modele_contenu_1_"+id_clicked).val().length > 999) {
    //     toastr.error('Merci de reduire la description de la première partie', 'Erreur');
    //     return false;
    // }
    // // Verifie la longeur du texte
    // if ($("#modele_contenu_2_"+id_clicked).val().length > 999) {
    //     toastr.error('Merci de reduire la description de la deuxième partie', 'Erreur');
    //     return false;
    // }
    // // Verifie la longeur du texte (plus long a cause des balises)
    // if ($("#modele_contenu_3_"+id_clicked).val().length > 9999) {
    //     toastr.error('Merci de reduire la description de la troisème partie', 'Erreur');
    //     return false;
    // }


    $.ajax({
        type: 'POST',
        url: siteURL + 'admin/vehicules/modele/modify',
        cache: false,
        contentType: false,
        processData: false,
        data: form,
        success: function(data) {
            // TOAST
            toastr.success('La modele a bien été enregistrée', 'Succès');
            // On nettoye le html en supprimant l'onglet 'Modifier/Nouveau'
            $('#modele-tabs-modify-'+id_clicked).remove();
            $('#modele-close-modify-'+id_clicked).parent().remove();
            if (id_clicked == 0) {
                // On réinitialise le formulaire
                $('#formmodele_'+id_clicked)[0].reset();
                $("[id*='_content_']").each( function(e) { $(this).summernote('reset'); });
            }
            // Retour à la liste d'actualités en rechargeant la datatable
            $('#modele-tabs-list-tab').trigger('click');
            modele_table.ajax.reload();
        }
    });
     // Empèche le 'button' de fonctionner
    return false;
});

// Recharge la table
$('[id^="modeletable-"').on( 'change keyup', function () {
    column = this.id.replace('modeletable-', '');
    // Au changement de la marque afficher les modeles correspondants
    if (column == 1) {
        var marque = this.value;
        if (marque != "") {
            var form = new FormData();
            form.append('marque', marque);

            $.ajax({
                type: 'POST',
                url: siteURL + "json/modeles_marque",
                data: form,
                processData: false,
                contentType: false,
                success: function(data, status) {
                    $("#modeletable-0").empty();
                    $("#modeletable-0").append('<option value=>Tous les modèles</option>');
                    for (id in data) {
                        if (data[id]['nb'] > 0) {
                            $("#modeletable-0").append("<option value='" + id + "'>" + id + "</option>");
                        }
                    };
                    $("#modeletable-0").val('default').selectpicker("refresh");
                }
            });
            modele_table.column(0).search( '' ).draw()
            modele_table.column( column ).search(
                '^'+$(this).val()+'$',
                true,
                false,
            ).draw();
        } else {
            modele_table.column(0).search( '' ).draw();
            $("#modeletable-0").empty();
            $("#modeletable-0").append('<option value=>Tous les modèles</option>');
            $("#modeletable-0").val('default').selectpicker("refresh");
            modele_table.column(1).search( '' ).draw();
        }
    }
    else {
        if (this.value != "") {
            modele_table.column( column ).search(
                '^'+$(this).val()+'$',
                true,
                false,
            ).draw();
        } else {
            modele_table.column(column).search( '' ).draw();
        }
    }
});

// RESET
$('#modele-reset').on('click', function() {
    $('#modele-tablesearch').trigger("reset");
    $("#modeletable-0").val('default').selectpicker("refresh");
    $("#modeletable-1").val('default').selectpicker("refresh");
    modele_table.columns().search( '' ).draw();
    return false;
})