/*
  /admin/vehicules/couleurs
 */

// Sauvegarde d'une couleur
$('[id^="change_color_"]:not([id^="change_color_choice"]):not([id^="change_color_button"]):not([id^="change_color_current"])').on('click', function() {

    var clickedId = this.id.replace('change_color_', '');
    var selectedColor = $('#change_color_choice_'+clickedId+" option:selected").val();
    var currentColor = $('#change_color_current_'+clickedId).val();

    // Si aucune couleur n'a été selectionnée
    if (selectedColor == "") {
        toastr.error('Merci de selectionner une couleur', "Erreur");
        return false;
    }

    // Initialisation du Form à envoyer
    var form = new FormData();
    form.append('id', clickedId);
    form.append('couleur_origine', currentColor);
    form.append('couleur_remplacement', selectedColor);

    // Requète Ajax
    $.ajax({
        type: 'POST',
        url: siteURL + 'admin/vehicules/couleur/save',
        data: form,
        contentType: false,
        processData: false,
        success: function(data) {
            if (data == "ok") {
                toastr.success('La couleur a été modifiée', "Done");
                $('#change_color_button_'+clickedId).addClass('bg-success').html('<i class="fas fa-check"></i>');
            }
            else {
                toastr.error('Une erreur est survenue', "Erreur");
            }
        }
    });

});


// Mise à jour d'une couleur
$('.update_color').on('click', function() {
    
    var currentColor = $(this).parent().parent().prev().find('div:not(.update_color)').find('input').val();
    var selectedColor = $(this).prev().find('option:selected').val();
    var toModify = $(this).find('span');

    // Si aucune couleur n'a été selectionnée
    if (selectedColor == "") {
        toastr.error('Merci de selectionner une couleur', "Erreur");
        return false;
    }

    // Initialisation du Form à envoyer
    var form = new FormData();
    form.append('couleur_origine', currentColor);
    form.append('couleur_remplacement', selectedColor);

    // Requète Ajax
    $.ajax({
        type: 'POST',
        url: siteURL+'admin/vehicules/couleur/update',
        data: form,
        contentType: false,
        processData: false,
        success: function(data) {
            if (data == "ok") {
                toastr.success('La couleur a été modifiée ainsi que pour ses véhicules associés', "Done");
                toModify.addClass('bg-success').html('<i class="fas fa-check"></i>');
            }
            else {
                toastr.error('Une erreur est survenue', "Erreur");
            }
        }
    });

});