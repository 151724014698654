let lastRequest = null;
let workingRequest = false;
document.addEventListener("DOMContentLoaded", function() {
    document.getElementById("search-input").addEventListener("click", searchOnChange, false); // Si on click sur le champ de recherche
    document.getElementById("search-input").addEventListener("focus", searchOnChange, false); // Si le champ de recherche obtient le focus
    document.getElementById("search-input").addEventListener("input", searchOnChange, false); // Si on écrit dans le champ de recherche
    document.getElementById("search-reset-container").addEventListener("click", searchReset, false); // Si on click sur le reset

    // Pour la modale et la rentrée du VIN via l'OCR
    $('body').on('shown.bs.modal', "#search-canva-photo-vin", function (e) {
        $('.modal-backdrop').css('z-index', 1035);
        lancementScriptVideoVIN('#search-input', 'search');
    });

    // Pour afficher ou non la barre de recherche sur mobile
    $('body').on('click', "#search-button-display-mobile", function (e) {
        if ($('#search-box').hasClass('d-none d-lg-block')) {
            $('#search-box').removeClass('d-none d-lg-block');
            $("#search-box").animate({
                width: "3000%"
            }, 500, function() {
                // $('#search-box').addClass('w-25rem');
            });
        } else {
            $("#search-box").animate({
                width: "0"
            }, 500, function() {
                $('#search-box').addClass('d-none d-lg-block');
                // $('#search-box').addClass('w-0');
            });
        }
    });
});

/**
 * @param {boolean} isPageChange - Si la requête demande un changement de page, alors true, sinon false
 * @param {string} search - Contient le contenu d'une la recherche textuelle
 */
window.loadData = function loadData(search = []) {
    let valueArray = document.getElementById("search-input").value.toLowerCase().replace(/\s{2,}/g, " ").split(" ").filter(v => v != "").map((valeur) => valeur.replaceAll('-', '')); // Transforme la recherche en minuscule, remplace les multiples espaces en un seul, puis sépare la chaîne en tableau en fonction des espaces. Par sécurité, on retire les chaînes vides du tableau.

    const body = JSON.stringify({
        id: Date.now(),
        search: valueArray,
    }); // Construit le corps de la requête
    lastRequest = body;
    if (!workingRequest) { // Si il n'y a pas de requête en cours
        workingRequest = true; // On annonce qu'il y a un requête en cours
        const xhr = new XMLHttpRequest();
        xhr.open("POST", siteURL+"admin/search_ajax", true);
        xhr.setRequestHeader("Content-Type", "application/json; charset=UTF-8");
        xhr.responseType = "json";
        xhr.onload = () => {
            if (xhr.readyState == 4 && xhr.status == 200) {
                const data = xhr.response;
                if (lastRequest != null) { // Si il y a une requête suivante
                    if (JSON.parse(lastRequest).id == data.id) { // Si la requête est la même que la requête suivante, on stop.
                        workingRequest = false; // On annonce qu'il n'y a plus de requête en cours
                        lastRequest = null; // On supprime l'info qu'il y a un requête suivante pour éviter les boucles infinie de requête
                    } else { // Si la requête n'est pas la même que la requête suivante, on continue.
                        workingRequest = false; // On annonce qu'il n'y a plus de requête en cours
                        loadData(); // On relance une requête
                        lastRequest = null; // On supprime l'info qu'il y a un requête suivante pour éviter les boucles infinie de requête
                    }
                } else { // Si il n'y a pas de requête suivante
                    workingRequest = false; // On annonce qu'il n'y a plus de requête en cours
                    lastRequest = null; // On supprime l'info qu'il y a un requête suivante pour éviter les boucles infinie de requête
                }
                displayResultSearch({resultSearch: data.results}); // Lance la fonction pour écrire les résultats de recherche
            } else {
                workingRequest = false; // On annonce qu'il n'y a plus de requête en cours
                lastRequest = null; // On supprime l'info qu'il y a un requête suivante pour éviter les boucles infinie de requête
            }
        };
        xhr.send(lastRequest); // On lance la requête
    }
}

// Fonction pour mettre à jour les résultats de recherche à l'affichage
window.searchOnChange = function searchOnChange(e) {
    e.preventDefault();
    if (!document.getElementById("search-input").parentElement.classList.contains("show")) {document.getElementById("search-input").parentElement.classList.add("show");} // Ajoute des règles CSS qui indique que le champ de recherche est en cours d'utilisation
    for (let element of document.getElementsByClassName("search-results")) {if (!element.classList.contains("show")) {element.classList.add("show");}} // Affiche la zone de résultats de la recherche
    if (this.value == "" || this.value.length < 3) { // Si la recherche est vide
        // Cache la zone de résultat
        document.getElementById("search-results").classList.remove("show");
        if (this.value.length >= 1) {
            document.getElementById("search-reset-container").classList.remove("d-none");
        }
    } else { // Si la recherche n'est pas vide
        // On affiche la croix du reset
        document.getElementById("search-reset-container").classList.remove("d-none");
        // On exécute la requête pour aller chercher et écrire les résultats
        loadData();
    }
}

// Fonction qui reset la recherche et défocuse la barre de recherche
window.displayResultSearch = function displayResultSearch({resultSearch = ""} = {}) {
    // Pour vérifier si on a des résultats, on va sauvegarder les catégories et celle sans résultats
    let nombreResultat = 0;
    // On boucle sur chaque catégorie de recherche
    Object.keys(resultSearch).forEach(function(categorySearch) {
        // On s'assure d'abord qu'on ait des résultats
        if (resultSearch[categorySearch].length != 0) {
            // On supprime la catégorie qu'il y a déjà
            $("#search-category-" + categorySearch).remove();
            // On doit d'abord copier l'HTML de la catégorie avant de mettre les résultats dedans
            const newCategoryResultSearch = $("#search-category-__vierge__").clone();
            newCategoryResultSearch.attr("id", newCategoryResultSearch[0].id.replaceAll("__vierge__", categorySearch));
            newCategoryResultSearch.html(newCategoryResultSearch.html().replaceAll("__categorie_vierge__", categorySearch));
            newCategoryResultSearch.find("#search-liste-result-__result_vierge__").remove();
            // On ajoute l'HTML dans les résultats de recherche et on enlève le d-none
            newCategoryResultSearch.appendTo("#search-have-result");
            document.getElementById("search-category-" + categorySearch).classList.remove("d-none");

            // On boucle ensuite sur chaque résultat de recherche pour les ajouter dans la barre de recherche
            Object.keys(resultSearch[categorySearch]).forEach(function(resultCategorySearch) {
                // On incrémente le compteur de résultat de la catégorie concernée
                nombreResultat = nombreResultat + 1;
                // Pour de la lisibilité, on met dans une variable l'object du résultat
                let result = resultSearch[categorySearch][resultCategorySearch];

                // On supprime la catégorie qu'il y a déjà
                $("#search-liste-result-" + result.id).remove();

                // On va rajouter les résultats dans la catégorie
                const newResultSearch = $("#search-liste-result-__result_vierge__").clone();
                newResultSearch.attr("id", newResultSearch[0].id.replaceAll("__result_vierge__", result.id));
                newResultSearch.html(newResultSearch.html().replaceAll("__categorie_vierge__", categorySearch));
                newResultSearch.html(newResultSearch.html().replaceAll("__result_vierge__", result.id));
                newResultSearch.html(newResultSearch.html().replaceAll("d-none", ""));
                // On construit la valeur, le lien et l'icône à afficher
                let valeur = "";
                let lien = "";
                let image = "";
                if (result.typeResult == "vehicule") {
                    valeur = "<span class='fw-bold'>" + result.id + "</span> - " + result.marqueName + " " + result.modeleName + " - <span class='text-10'>" + result.version + "<br>" + result.vin + " " + result.badgeStock + "</span>";
                    lien = siteURL + "admin/stock/" + result.id;
                    icone = "<i class='fas fa-car'></i>";
					image = siteURL + result.lienImage;
                }
                newResultSearch.html(newResultSearch.html().replaceAll("__result_valeur__", valeur));
                newResultSearch.html(newResultSearch.html().replaceAll("__result_lien__", lien));
                newResultSearch.html(newResultSearch.html().replaceAll("__result_img__", image));

                // On insère dans la catégorie
                newResultSearch.appendTo("#search-category-" + categorySearch);
            });
        }
    });

    // On affiche les résultats, ou le "Aucun résultat" s'il n'y en a pas
    if (nombreResultat > 0) {
        document.getElementById("search-have-result").classList.remove("d-none");
        document.getElementById("search-no-result").classList.add("d-none");
    } else {
        document.getElementById("search-have-result").classList.add("d-none");
        document.getElementById("search-no-result").classList.remove("d-none");
    }
}

// Fonction qui reset la recherche et défocuse la barre de recherche
window.searchReset = function searchReset() {
    document.getElementById("search-input").value = "";
    document.getElementById("search-input").parentElement.classList.remove("show");
    document.getElementById("search-results").classList.remove("show");
    document.getElementById("search-reset-container").classList.add("d-none");
}