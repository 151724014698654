function receptionChannelNext(step, dialog, channel = "next") {
    channelLoader(step, "reception", "on");
    let idVehicule = $("#stock_vehicule-reception").attr("data-idvs");

    if (step == 1) {
        let marqueReception = $("#reception_input_marque").val();
        let modeleReception = $("#reception_input_modele").val();
        let versionReception = $("#reception_input_version").val();
        let vinReception = $("#reception_input_vin").val();
        let couleurReception = $("#reception_input_couleur").val();
        let energieReception = $("#reception_input_energie").val();
        let boiteReception = $("#reception_input_boite").val();
        let kilometrageReception = $("#reception_input_kilometrage").val();
        let photo34Avant = $("#expertise-photo-photo34Avant-" + idVehicule + "-result").val();
        let photo34Arriere = $("#expertise-photo-photo34Arriere-" + idVehicule + "-result").val();
        let photoLateralGauche = $("#expertise-photo-photoLateralGauche-" + idVehicule + "-result").val();
        let photoLateralDroit = $("#expertise-photo-photoLateralDroit-" + idVehicule + "-result").val();

        // Vérification des champs manquants
        let champManquants = [];
        if (marqueReception.length == 0) {
            champManquants.push("Marque");
        }
        if (modeleReception.length == 0) {
            champManquants.push("Modèle");
        }
        if (versionReception.length == 0) {
            champManquants.push("Version");
        }
        if (vinReception.length == 0) {
            champManquants.push("VIN");
        }
        if (couleurReception.length == 0) {
            champManquants.push("Couleur");
        }
        if (energieReception.length == 0) {
            champManquants.push("Énergie");
        }
        if (boiteReception.length == 0) {
            champManquants.push("Boîte");
        }
        if (kilometrageReception.length == 0) {
            champManquants.push("Kilométrage");
        }
        if (photo34Avant.length == 0) {
            champManquants.push("Photo 3/4 Avant");
        }
        if (photo34Arriere.length == 0) {
            champManquants.push("Photo 3/4 Arrière");
        }
        if (photoLateralGauche.length == 0) {
            champManquants.push("Photo latéral gauche");
        }
        if (photoLateralDroit.length == 0) {
            champManquants.push("Photo latéral droit");
        }

        if (champManquants.length > 0) { // On met un toaster pour les champs manquants
            if (champManquants.length == 1) {
                dialogToastrError(dialog, "reception", "Le champ \"" + champManquants.join() + "\" est manquant.");
            } else {
                dialogToastrError(dialog, "reception", "Les champs \"" + champManquants.join(', ') + "\" sont manquants.");
            }
        channelLoader(step, "reception", "off");
        } else { // Sinon, on vérifie le VIN et on enregistre les données si c'est bon
            // Vérification du VIN
            if (vinReception.length != 17) {
                dialogToastrError(dialog, "reception", "Le VIN ne fait pas 17 caractères.");
                channelLoader(step, "reception", "off");
            } else if (vinReception.toLowerCase().match(/(\w)\1\1\1\1\1\1\1\1\1\1\1\1\1\1\1\1/g)) {
                dialogToastrError(dialog, "reception", "Le VIN ne peut pas comporter le même caractère autant de fois.");
                channelLoader(step, "reception", "off");
            } else if (!vinReception.toLowerCase().match(/[\w\d]{17}/g)) {
                dialogToastrError(dialog, "reception", "Le VIN ne doit pas comporter de caractères spéciaux.");
                channelLoader(step, "reception", "off");
            } else { // Si tout est bon, on envoie les données et on passe à la suite
                // Envoie de la requête pour l'enregistrement des données en base
                let form = new FormData();
                form.append('idVehicule', idVehicule);
                form.append('marqueReception', marqueReception);
                form.append('modeleReception', modeleReception);
                form.append('versionReception', versionReception);
                form.append('vinReception', vinReception);
                form.append('couleurReception', couleurReception);
                form.append('energieReception', energieReception);
                form.append('boiteReception', boiteReception);
                form.append('kilometrageReception', kilometrageReception);
                form.append('photo34Avant', photo34Avant);
                form.append('photo34Arriere', photo34Arriere);
                form.append('photoLateralGauche', photoLateralGauche);
                form.append('photoLateralDroit', photoLateralDroit);
                $.ajax({
                    type: 'POST',
                    url: siteURL + 'admin/stock/update/reception-etape1',
                    data: form,
                    processData: false,
                    contentType: false,
                    success: function(response) {
                        $('[id="reception-channel-2"]').html(response.modalVerif);
                        if (response.verifNecessaire == true) {
                            updateReceptionEtape(step, dialog, channel);
                        } else {
                            updateReceptionEtape(step, dialog, channel, 1);
                        }
                    }
                });
            }
        }
    } else if (step == 2) { // Pour les photos des choses non conforme à l'étape 1
        // On récupère tous les inputs de litige, pour savoir s'ils ont bien une valeur
        let champVide = [];
        $.each($('[id^=expertise-litige-list-]'), function () {
            if ($(this).children().toArray().length == 0) {
                let idLitige = this.id.replace('expertise-litige-list-', '');
                let labelManquant = $('[for=reception-litige-photo-' + idLitige + ']').html();
                champVide.push(labelManquant);
            }
        });
        $.each($('#expertise-vin-list'), function () {
            if ($(this).children().toArray().length == 0) {
                champVide.push("Photo VIN");
            }
        });

        if (champVide.length > 0) {
            dialogToastrError(dialog, "reception", 'Il manque les ' + champVide.join(', '));
            channelLoader(step, "reception", "off");
        } else {
            updateReceptionEtape(step, dialog, channel);
        }
    } else if (step == 3 || step == 4) {
        // On vérifie d'abord s'il y a des champs manquants
        // Vérification des champs manquants
        let champManquants = [];

        // On va vérifié que chaque card a bien un bouton de sélectionner pour passer à la suite
        // On sélectionne chaque card
        let selectionne;
        $('[id^="reception-etape' + step + '-card-"]').each(function () {
            selectionne = false;
            // Sur chaque card, on va sélectionner chaque bouton, pour vérifier s'ils sont sélectionnés (qu'ils ont la class btn-primary)
            $(this).find('[id^="btn-prestation-"]').each(function () {
                if($(this).hasClass("btn-primary")) {
                    selectionne = true;
                }
            });
            if (selectionne == false) {
                champManquants.push($(this).attr('data-title'));
            }
        });

        // On va vérifier aussi les boutons spécifiques à la carte grise, COC et double des clés à l'étape 3
        let carteGriseReception = ($("#reception_cg_true").length ? $("#reception_cg_true").hasClass("btn-primary") : null);
        let certificatConformiteEuropeenReception = ($("#reception_coc_true").length ? $("#reception_coc_true").hasClass("btn-primary") : null);
        let doubleCleReception = ($("#reception_doublecle_true").length ? $("#reception_doublecle_true").hasClass("btn-primary") : null);
        let co2certifie = $('#reception_co2_certifie').val();
        if (step == 4) {
            if (carteGriseReception != null && !$("#reception_cg_true").hasClass('btn-primary') && !$("#reception_cg_false").hasClass('btn-primary')) {
                champManquants.push("Carte Grise");
            }
            if (certificatConformiteEuropeenReception != null && !$("#reception_coc_true").hasClass('btn-primary') && !$("#reception_coc_false").hasClass('btn-primary')) {
                champManquants.push("COC");
            }
            // Vérification du CO2 certifié si COC présent
            if (certificatConformiteEuropeenReception && co2certifie == "") {
                champManquants.push("CO2 certifié");
            }
            if (doubleCleReception != null && !$("#reception_doublecle_true").hasClass('btn-primary') && !$("#reception_doublecle_false").hasClass('btn-primary')) {
                champManquants.push("Doublé clé");
            }
        }

        if (champManquants.length > 0) { // On met un toaster pour les champs manquants
            if (champManquants.length == 1) {
                dialogToastrError(dialog, "reception", "Le champ \"" + champManquants.join() + "\" est manquant.");
                channelLoader(step, "reception", "off");
            } else {
                dialogToastrError(dialog, "reception", "Les champs \"" + champManquants.join(', ') + "\" sont manquants.");
                channelLoader(step, "reception", "off");
            }
        } else {
            // Pour la sauvegarde des informations sur la carte grise et COC si besoin
            if (step == 4 && (carteGriseReception != null || certificatConformiteEuropeenReception != null || doubleCleReception != null)) {
                // Envoie de la requête pour l'enregistrement des données de CG et COC en base
                let form = new FormData();
                form.append('idVehicule', idVehicule);
                form.append('carteGriseReception', carteGriseReception);
                form.append('certificatConformiteEuropeenReception', certificatConformiteEuropeenReception);
                form.append('doubleCleReception', doubleCleReception);
                form.append('co2certifie', co2certifie);
                $.ajax({
                    type: 'POST',
                    url: siteURL + 'admin/stock/update/reception-etape4',
                    data: form,
                    processData: false,
                    contentType: false,
                    success: function() {
                        updateReceptionEtape(step, dialog, channel);
                    }
                });
            } else {
                updateReceptionEtape(step, dialog, channel);
            }
        }
    } else {
        updateReceptionEtape(step, dialog, channel);
    }
}

$('body').on('change', "[id^='reception-litige-photo-']", function () {
    const resultEl = document.querySelector("#file_result_" + this.id);
    onValueChange(resultEl, (observer) => {
        const val = resultEl.value;
        const idLitige = resultEl.id.replace('file_result_reception-litige-photo-', '')
        litigePhoto("litige", val, "add", idLitige);
        observer.disconnect();
    });
});

$('body').on('click', "[id^='expertise-litige-'][id$='-delete']", function () {
    const val = this.id.replace("expertise-litige-", "").replace("-delete", "");
    const idLitige = $(this).parent().parent().attr("id").replace("expertise-litige-list-", "");
    litigePhoto("litige", val, "del", idLitige);
});

$('body').on('change', "#reception-photo-vin", function () {
    const resultEl = document.querySelector("#file_result_" + this.id);
    onValueChange(resultEl, (observer) => {
        const val = resultEl.value;
        let form = new FormData();
        form.append('idVehicule', $("#stock_vehicule-reception").attr("data-idvs"));
        form.append('idPhotoVin', val);

        $.ajax({
            url: siteURL + 'admin/stock/update/reception-enregistrement-photo-vin',
            data: form,
            contentType: false,
            processData: false,
            type: 'POST',
            success: function (data) {
                const refEl = document.getElementById("expertise-vin-__id__-item");
                const listEl = $("dialog #expertise-vin-list")[0];
                const dialog = $($(refEl).parents("dialog")[0]);
                if (data.success) {
                    dialogToastrSuccess(dialog, "reception", 'Enregistrement réussi');
                    const newEl = refEl.cloneNode(true);
                    newEl.id = newEl.id.replaceAll("__id__", val);
                    newEl.innerHTML = newEl.innerHTML.replaceAll("__id__", val);
                    newEl.innerHTML = newEl.innerHTML.replace("__file__", data.path + data.filename);
                    newEl.firstElementChild.setAttribute("custom-fancy", "reception-vin-item");
                    if (listEl.childElementCount > 0) { listEl.firstElementChild.before(newEl); } else { listEl.appendChild(newEl); }
                } else {
                    dialogToastrError(dialog, "expertise", 'Une erreur s\'est produite.');
                }
            },
            error: function (error) {
                console.error("Erreur lors de l'enregistrement sur la réception de la photo vin :", error);
            }
        });
        observer.disconnect();
    });
});

$('body').on('click', "[id^='expertise-vin-'][id$='-delete']", function () {
    const val = this.id.replace("expertise-vin-", "").replace("-delete", "");
    const idLitige = $(this).parent().parent().attr("id").replace("expertise-vin-list-", "");
    litigePhoto("litige", val, "del", idLitige);
});

function litigePhoto(type, val, act, idLitige = '') {
    var form = new FormData();
    form.append("idLitige", idLitige);
    form.append("val", val);
    form.append("act", act);
    $.ajax({
        type: "POST",
        url: siteURL + "admin/stock/update/" + type + "-photo",
        contentType: false,
        processData: false,
        data: form,
        success: function (data) {
            const refEl = document.getElementById("expertise-" + type + "-__id__-item");
            const listEl = $("dialog #expertise-litige-list-" + idLitige)[0];
            const dialog = $($(refEl).parents("dialog")[0]);
            if (data.success) {
                if (act == "add") {
                    dialogToastrSuccess(dialog, "reception", 'Enregistrement réussi');
                    const newEl = refEl.cloneNode(true);
                    newEl.id = newEl.id.replaceAll("__id__", val);
                    newEl.innerHTML = newEl.innerHTML.replaceAll("__id__", val);
                    newEl.innerHTML = newEl.innerHTML.replace("__file__", data.path + data.filename);
                    newEl.firstElementChild.setAttribute("custom-fancy", "reception-" + type + "-item");
                    if (listEl.childElementCount > 0) { listEl.firstElementChild.before(newEl); } else { listEl.appendChild(newEl); }
                } else {
                    dialogToastrSuccess(dialog, "reception", 'Suppression réussi');
                    $("[id='expertise-" + type + "-" + val + "-item']").remove();
                }
            } else {
                dialogToastrError(dialog, "expertise", 'Une erreur s\'est produite.');
            }
        },
        error: function () {
            dialogToastrError(dialog, "expertise", 'Une erreur s\'est produite.');
        }
    });
}

function receptionChannelPrev(step, name) {
    if (step == 3 && $('[id^="expertise-litige-list-"]').length == 0 ) {
        channelClose(step, name);
        channelOpen(step - 2, name);
    } else {
        channelPrev(step, name);
    }
}