$(document).ready(function () {
    if ($("#stock_vehicule_page").length) {
        const vehiculeId = $("#stock_vehicule_page").data("id");
        let ouvertureModalAgenda = false;
        let sauvegardeLivraison = false;

        // Pour les checkbox "unique"
        $('body').on('click', "[id^='livraison_input_checkbox_unique_']", function (e) {
            $('#' + this.id.replace($(this).data('name'), $(this).data('inverse'))).prop("checked", false);
        });

        // Pour afficher ou non le select du chargé de livraison
        $('body').on('change', "#livraison_input_autre_charge_livraison", function (e) {
            if ($('#livraison_input_autre_charge_livraison').prop("checked")) {
                $('#livraison_chargeLivraison').removeClass('d-none');
            } else {
                $('#livraison_chargeLivraison').addClass('d-none');
                // En plus de cela, on remet le vendeur sur le chargé de livraison
                $('#livraison_input_chargeLivraison').val($('#livraison_input_vendeur').val());
                $('#livraison_input_chargeLivraison').selectpicker('refresh');
            }
        });

        // Pour mettre à jour l'input de chargé de livraison si c'est la même personne qui vend et livre
        $('body').on('change', "#livraison_input_vendeur", function (e) {
            if ($('#livraison_input_autre_charge_livraison').prop("checked") == false) {
                $('#livraison_input_chargeLivraison').val($('#livraison_input_vendeur').val());
                $('#livraison_input_chargeLivraison').selectpicker('refresh');
            }
        });

        // Pour l'affichage différent sur le lieu de livraison
        $('body').on('click', "[id^='livraison_input_checkbox_unique_lieu_livraison_']", function (e) {
            let typeClientSelectionne = this.id.replace("livraison_input_checkbox_unique_lieu_livraison_", "");
            let inverseTypeClientSelectionne = typeClientSelectionne == "centre" ? "client" : "centre";

            $("#livraison_" + typeClientSelectionne + "_livraison_display").removeClass('d-none');
            $("#livraison_" + inverseTypeClientSelectionne + "_livraison_display").addClass('d-none');
            resetFormDateLieuLivraison();
        });

        // Pour save la livraison
        $('body').on('click', "#save-livraison", function (e) {
            if (sauvegardeLivraison == false) {
                sauvegardeLivraison = true;
                const mailReg = /^(([^<>()\/\[\]\\\\.,;:\s@"\'!$]+(\.[^<>()\/\[\]\\\\.,;:\s@"\'!$]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
                let error_message = '';

                if ($("#livraison_input_nom_client").val() == null || $("#livraison_input_nom_client").val().length == 0) {
                    error_message += 'Le nom du client n\'a pas été renseigné.<br/>';
                }
                if ($("#livraison_input_pays_client").val() == null || $("#livraison_input_pays_client").val().length == 0) {
                    error_message += 'le pays du client n\'a pas été renseigné.<br/>';
                }
                if ($("#livraison_input_adresse_client").val() == null || $("#livraison_input_adresse_client").val().length == 0) {
                    error_message += 'L\'adresse du client n\'a pas été renseignée.<br/>';
                }
                if ($("#livraison_input_ville_client").val() == null || $("#livraison_input_ville_client").val().length == 0) {
                    error_message += 'La ville du client n\'a pas été renseignée.<br/>';
                }
                if ($("#livraison_input_code_postal_client").val() == null || $("#livraison_input_code_postal_client").val().length == 0) {
                    error_message += 'Le code postal du client n\'a pas été renseigné.<br/>';
                }
                if ($("#livraison_input_email_client").val() == null || $("#livraison_input_email_client").val().length == 0) {
                    error_message += 'L\'adresse email du client n\'a pas été renseigné.<br/>';
                } else if (!mailReg.test($("#livraison_input_email_client").val())) {
                    error_message += "L\'adresse email renseignée n\'est pas valide.<br/>";
                }
                if ($("#livraison_input_vendeur").val() == null || $("#livraison_input_vendeur").val().length == 0) {
                    error_message += 'Le vendeur n\'a pas été renseigné.<br/>';
                }
                if ($("#livraison_input_chargeLivraison").val() == null || $("#livraison_input_chargeLivraison").val().length == 0) {
                    error_message += 'Le chargé de livraison n\'a pas été renseigné.<br/>';
                }
                if ($("#livraison_input_checkbox_unique_lieu_livraison_centre").prop("checked") == false && $("#livraison_input_checkbox_unique_lieu_livraison_client").prop("checked") == false) {
                    error_message += 'Le lieu de livraison n\'a pas été renseigné.<br/>';
                } else {
                    if ($("#livraison_input_checkbox_unique_lieu_livraison_centre").prop("checked")) {
                        if ($("#livraison_input_parc_livraison").val() == null || $("#livraison_input_parc_livraison").val().length == 0) {
                            error_message += 'L\'agence de livraison n\'a pas été renseignée.<br/>';
                        }
                    } else if ($("#livraison_input_checkbox_unique_lieu_livraison_client").prop("checked")) {
                        // Pour savoir quel type de transport c'est
                        if ($("#livraison_input_checkbox_unique_type_livraison_transporteur").prop("checked") == false && $("#livraison_input_checkbox_unique_type_livraison_convoyeur").prop("checked") == false) {
                            error_message += 'Le type de transport pour la livraison n\'a pas été renseigné.<br/>';
                        }
                        if ($("#livraison_input_transporteur").val() == null || $("#livraison_input_transporteur").val().length == 0) {
                            error_message += 'Le transporteur pour la livraison n\'a pas été renseigné.<br/>';
                        }
                        if ($("#livraison_input_adresse_livraison").val() == null || $("#livraison_input_adresse_livraison").val().length == 0) {
                            error_message += 'L\'adresse de livraison n\'a pas été renseignée.<br/>';
                        }
                        if ($("#livraison_input_code_postal_livraison").val() == null || $("#livraison_input_code_postal_livraison").val().length == 0) {
                            error_message += 'Le code postal de livraison n\'a pas été renseignée.<br/>';
                        }
                        if ($("#livraison_input_ville_livraison").val() == null || $("#livraison_input_ville_livraison").val().length == 0) {
                            error_message += 'La ville de livraison n\'a pas été renseignée.<br/>';
                        }
                    }
                }

                if (error_message.length > 0) {
                    toastr.error(error_message, 'Erreur');
                    sauvegardeLivraison = false;
                } else {
                    $.ajax({
                        type: 'POST',
                        url: siteURL + 'admin/stock/update/livraison-save',
                        contentType: false,
                        processData: false,
                        data: getParams(),
                        success: function (data) {
                            sauvegardeLivraison = false;
                            if (data.success) {
                                toastr.success('La livraison a bien été enregistrée.', 'La livraison a été enregistrée');
                                $('#stock_vehicule-tab-livraison .stock_vehicule-statut-onglet').removeClass('bg-danger').removeClass('bg-warning').addClass('bg-success');
                                $('#stock_vehicule-tab-livraison .stock_vehicule-statut-onglet').html('<i class="fas fa-check"></i>');
                            }
                        },
                        error: function () { toastr.error('Une erreur s\'est produite', 'Erreur'); sauvegardeLivraison = false; }
                    });
                }
            }
        });

        // Fonction pour remettre à vide les champs dans la partie Date et lieu de livraison
        function resetFormDateLieuLivraison() {
            $('#livraison_input_parc_livraison').val("");
            $('#livraison_input_parc_livraison').selectpicker('refresh');
            $('#livraison_input_transporteur').val("");
            $('#livraison_input_transporteur').selectpicker('refresh');
            $('#livraison_input_parc_livraison').val("25");
            $('#livraison_input_parc_livraison').selectpicker("refresh");
            $('#livraison_input_adresse_livraison').val("");
            $('#livraison_input_code_postal_livraison').val("");
            $('#livraison_input_ville_livraison').val("");
            $("#livraison_input_checkbox_unique_type_livraison_transporteur").prop("checked", true);
            $("#livraison_input_checkbox_unique_type_livraison_convoyeur").prop("checked", false);
        }

        function getParams() {
            let formLivraison = new FormData();

            formLivraison.append('vehiculeId', vehiculeId);
            let dateLivraison = $("#livraison_input_date_livraison").val().replace(/(\d+)[/](\d+)[/](\d+)[\s](\d+)[:](\d+)/, '$3-$2-$1 $4:$5');
            if (dateLivraison != "") {
                dateLivraison = (new Date(dateLivraison)).toISOString()
            } else {
                dateLivraison == null;
            }
            formLivraison.append('dateLivraison', dateLivraison);
            formLivraison.append('vendeurId', $("#livraison_input_vendeur").val());
            formLivraison.append('chargeLivraisonId', $("#livraison_input_chargeLivraison").val());
            formLivraison.append('commentaire', $("#livraison_input_commentaire").val());
            formLivraison.append('typeClient', $("#livraison_input_type_client").val());
            formLivraison.append('nomClient', $("#livraison_input_nom_client").val());
            formLivraison.append('paysClient', $("#livraison_input_pays_client").val());
            formLivraison.append('adresseClient', $("#livraison_input_adresse_client").val());
            formLivraison.append('villeClient', $("#livraison_input_ville_client").val());
            formLivraison.append('codePostalClient', $("#livraison_input_code_postal_client").val());
            formLivraison.append('telephoneClient', $("#livraison_input_telephone_client").val());
            formLivraison.append('emailClient', $("#livraison_input_email_client").val());

            // Suivant si on livre dans un centre ou chez le client
            if ($("#livraison_input_checkbox_unique_lieu_livraison_centre").prop("checked")) {
                // Dans le cas d'un centre, on a juste besoin du parc de livraison
                formLivraison.append('lieuLivraison', 'centre');
                formLivraison.append('parcLivraison', $("#livraison_input_parc_livraison").val());
            } else if ($("#livraison_input_checkbox_unique_lieu_livraison_client").prop("checked")) {
                // Dans le cas d'une livraison chez le client
                formLivraison.append('lieuLivraison', 'client');
                // Pour savoir quel type de transport c'est
                if ($("#livraison_input_checkbox_unique_type_livraison_transporteur").prop("checked")) {
                    formLivraison.append('typeTransport', 'transporteur');
                } else if ($("#livraison_input_checkbox_unique_type_livraison_convoyeur").prop("checked")) {
                    formLivraison.append('typeTransport', 'convoyeur');
                }
                formLivraison.append('transporteur', $("#livraison_input_transporteur").val());
                formLivraison.append('adresseLivraison', $("#livraison_input_adresse_livraison").val());
                formLivraison.append('codePostalLivraison', $("#livraison_input_code_postal_livraison").val());
                formLivraison.append('villeLivraison', $("#livraison_input_ville_livraison").val());
            }

            return formLivraison;
        }

        //----------------------------------------------------------------------------------//
        // Partie pour l'agenda                                                             //
        //----------------------------------------------------------------------------------//

        /* initialize the calendar ---------------------------------------------------------*/
        let calendarEl = null, calendar = null;

        let calendarOpt = {
            locale: "fr",
            initialView: 'timeGridWeek',
            headerToolbar: {
                left: 'prev,next today',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
            },
            expandRows: true,
            contentHeight: 1000,
            themeSystem: 'bootstrap5',
            events: [],
            allDaySlot: false,
            firstDay: 1,
            selectable: true,
            editable: false,
            droppable: false,
            slotMinTime: "08:00:00",
            slotMaxTime: "19:00:00",
            slotDuration: "01:00:00",
            eventDidMount: function(info) {
                const object = info.event._def.extendedProps.object;
                let idVehicule = object.label.match(/(\d+)$/g);
                const eventDisplay =  (
                    object.label.replace(/(\d+)$/g, "") +'<a href="' + siteURL + 'admin/stock/' + idVehicule + '?gototab=livraison" target="_blank">' + idVehicule + '</a></a><br><img src="' + siteURL + object.user.lienImage + '" class="img-fluid rounded-circle w-2rem mr-2" alt="Avatar">' + object.user.fullName + '</div>'
                );
                if (info.el.querySelectorAll('.fc-event-title').length) { info.el.querySelectorAll('.fc-event-title')[0].innerHTML = eventDisplay; }
                else { info.el.querySelectorAll('.fc-list-event-title')[0].innerHTML = eventDisplay; }
                // On rajoute un attribut pour l'id de l'event
                $(info.el).attr("id", "event-" + info.event._def.extendedProps.object.id);
            },
            eventClick: function(eventClickInfo) {
                eventClickExternalise(eventClickInfo.el);
                deselectionDateLivraisonAgenda();
            },
            select: function(info) {
                selectionDateLivraisonAgenda(info.startStr);
            }
        }

        let Calendar = FullCalendar.Calendar;

        /* initialize CRUD function ---------------------------------------------------------*/

        function loadCalendar(firstLoad = false) {
            let options = new FormData();
            options.append('start', calendar.currentData.dateProfile.activeRange.start.toISOString());
            options.append('end', calendar.currentData.dateProfile.activeRange.end.toISOString());
            $.ajax({
                type:'POST',
                url: siteURL + 'admin/stock/update/livraison-agenda',
                contentType: false,
                processData: false,
                data: options,
                success: function(data) { data.forEach(element => {
                    refreshCalendarEvent(element);
                    if ($('#livraison_input_date_livraison').val() != "" && firstLoad) {
                        $('#agenda-livraison-input-dateDebut').val($('#livraison_input_date_livraison').val());
                    }
                }); }
            });
        }

        function eventClickExternalise(element) {
            let dialog = (element.id.startsWith("livraison-dialog-") && element.id.endsWith("-open") ? $(this).next() : $(this).parents("[name='livraison-dialog']"));
            dialogToastrError(dialog, "livraison", "Impossible de mettre ici.");
        }

        function refreshCalendarEvent(agenda, crud = "post") {
            if (agenda.ids !== undefined) {
                agenda.ids.forEach(id => {
                    let event = calendar.getEventById(id);
                    if (event) { event.remove(); }
                });
            } else {
                let event = calendar.getEventById(agenda.id);
                if (event) { event.remove(); }
            }
            if (crud == "post") {
                agenda.start = new Date(agenda.start.date);
                agenda.end = new Date(agenda.end.date);
                calendar.addEvent(agenda);
            }
        }

        function selectionDateLivraisonAgenda(date) {
            let dateSelection = date.replace(/(\d+)[-](\d+)[-](\d+)(T)(\d+)[:](\d+)[:](\d+)[\+](\d+)[:](\d+)/, '$3/$2/$1 $5:$6');

            $('#agenda-livraison-input-dateDebut').val(dateSelection);
            $('#livraison_input_date_livraison').val(dateSelection);
        }

        function deselectionDateLivraisonAgenda() {
            calendar.unselect();
            $('#agenda-livraison-input-dateDebut').val('');
            $('#livraison_input_date_livraison').val('');
        }

        /* initialize event ----------------------------------------------------------------*/

        $("body").on("click", ".fc-prev-button", loadCalendar);
        $("body").on("click", ".fc-next-button", loadCalendar);
        $("body").on("click", ".fc-today-button", loadCalendar);
        $("body").on("click", ".fc-dayGridMonth-button", loadCalendar);
        $("body").on("click", ".fc-timeGridWeek-button", loadCalendar);
        $("body").on("click", ".fc-timeGridDay-button", loadCalendar);
        $("body").on("click", ".fc-listWeek-button", loadCalendar);
        $("body").on("click", ".fc-listDay-button", loadCalendar);

        /* initialize other function ------------------------------------------------------*/

        function refreshDateTimePicker(picker = "all", date = null) {
            const datetimepickerOptions = (id) => {
                return {
                    value: date,
                    lang: 'fr',
                    format: (date == null ? "d/m/Y" : "d/m/Y H:i"),
                    dayOfWeekStart: 1,
                    step: 60,
                    validateOnBlur: false,
                    timepicker: (!(date == null)),
                    onSelectDate: function() {
                        $("#agenda-livraison-input-"+id).datetimepicker('setOptions', { format: "d/m/Y H:i", timepicker: true });
                        datetimepickerSetMinMax();
                    },
                    onSelectTime: function() { datetimepickerSetMinMax(); }
                }
            };

            const datetimepickerSetMinMax = () => {
                const regexDate = /(\d+)[/](\d+)[/](\d+)[\s](\d+)[:](\d+)|(\d+)[/](\d+)[/](\d+)|(\d+)[:](\d+)/;
                if ($('#agenda-livraison-input-dateDebut').val() != "") {
                    $("#agenda-input-dateFin").datetimepicker('setOptions', {
                        minDate: ($('#agenda-livraison-input-dateDebut').val().replace(regexDate, '$3$8/$2$7/$1$6') != "//" ? $('#agenda-livraison-input-dateDebut').val().replace(regexDate, '$3$8/$2$7/$1$6') : false),
                        minTime: ($('#agenda-livraison-input-dateDebut').val().replace(regexDate, '$4$9:$5$10') != ":" ? $('#agenda-livraison-input-dateDebut').val().replace(regexDate, '$4$9:$5$10').replace(/0$/, "1") : false)
                    });
                }
            };

            if (picker == "all" || picker == "dateDebut") {
                $('#agenda-livraison-input-dateDebut').datetimepicker(datetimepickerOptions("dateDebut"));
                if (date == null) { $('#agenda-livraison-input-dateDebut').val(null); $("#agenda-livraison-input-dateDebut").datetimepicker('setOptions', { maxDate: false, maxTime: false }); }
            }

            if (picker == "all" || picker == "dateDebut") { datetimepickerSetMinMax(); }
        }

        function livraisonLoadChannel() {
            ouvertureModalAgenda = true;
            let form = new FormData();
            form.append("id", vehiculeId);
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/stock/update/livraison-channel',
                contentType: false,
                processData: false,
                data: form,
                success: function (data) {
                    $("#livraison-channel").html(data);
                    $("#livraison-channel .selectpicker").selectpicker();
                    channelOpen(1, "livraison");

                    // Initialisation calendrier
                    calendarEl = document.getElementById('agenda-livraison-calendar');
                    calendar = new Calendar(calendarEl, calendarOpt);
                    calendar.render();
                    refreshDateTimePicker();
                    loadCalendar(true);
                    ouvertureModalAgenda = false;
                },
                error: function () { toastr.error("Erreur de chargement", "Erreur"); }
            });
        }

        $("body").on('click', "#livraison_ouverture_modal_date_livraison", function(e) {
            if (ouvertureModalAgenda == false) {
                livraisonLoadChannel();
            }
        });

        $('body').on('click', "[channel-dialog-end]", function () {
            channelClose(1, "livraison");
        });
    }
});
