let offre_fournisseur_table = null;
let offre_fournisseur_table_page = 0;

let timerBeforeRefreshDataOffreFournisseur = null;

$(document).ready(function () {
    if ($("#offre_fournisseur_results").length) {
        window.history.pushState("", "", siteURL + "admin/achat/offres_fournisseur");

        let trieColonne = [];

        colvisFunOnEvent();

        function loadAjax({ isPageReload = false, idOffset = null, goToOffreFournisseur = "", idannuairecreationdepuisannuairefournisseur = "" } = {}) {
            if (!idOffset) {
                idOffset = parseInt($("#offre_fournisseur-tabs-list-tab").attr("data-load-ajax-id"));
            }
            $("#offre_fournisseur-tabs-list-tab").attr("data-load-ajax-id", 0);
            let optionsRecherche = new FormData();
            optionsRecherche.append('createur', $("#filtersoffrefournisseur-createur").val());
            optionsRecherche.append('fournisseur', $("#filtersoffrefournisseur-fournisseur").val());
            $('#offre_fournisseur_loader').removeClass('d-none');
            $('.card-body').addClass('d-none');
            $("#offre_fournisseur_results").addClass("d-none");
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/achat/offre_fournisseur_ajax',
                contentType: false,
                processData: false,
                data: optionsRecherche,
                success: function (data) {
                    $("#offre_fournisseur_results").html(data);
                    offre_fournisseur_table = $('#offre_fournisseur_table').DataTable({
                        "dom": dataTablesCustomDom(),
                        "buttons": [dataTablesColvisBtn('th:nth-child(n+2):not(:last-child)')],
                        "columns": getColvisColumn("offre_fournisseur_table"),
                        "oSearch": { "sSearch": (goToOffreFournisseur ? "id:[" + goToOffreFournisseur.toString() + "]" : "") },
                        "autoWidth": false,
                        "language": { "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/en-GB.json" },
                        "paging": true,
                        "pageLength": 50,
                        "lengthMenu": [[10, 25, 50, 100, -1], [10, 25, 50, 100, "All"]],
                        "responsive": true,
                        "order": [
                            [1, "desc"]
                        ],
                        "columnDefs": [
                            { type: 'num', targets: [0, 4] },
                            // ALIGN MIDDLE
                            {
                                "className": "text-center align-middle",
                                "targets": '_all',
                            },
                            // MODIFY
                            {
                                "targets": [0, 7],
                                "orderable": false,
                            },
                        ],
                        "conditionalPaging": true,
                    });
                    $('#offre_fournisseur_table').on('init.dt', function () { initMirrorBtn(); setColvisTable(offre_fournisseur_table); colvisFunSelectRefresh(); });
                    $('#offre_fournisseur_table').on('draw.dt', function () {
                        // Génération dynamique des liens sur le tableau
                        offre_fournisseur_table.rows().invalidate();
                        $("#offre_fournisseur_results").removeClass("d-none");
                        $('#offre_fournisseur_loader').addClass('d-none');
                        $('.card-body').removeClass('d-none');
                        if ($('#selection_offre_fournisseur_' + idOffset).length) {
                            $('html,body').animate({ scrollTop: $('#selection_offre_fournisseur_' + idOffset).parent().offset().top - $('.navbar').height() - $($("#offre_fournisseur_table")[0].firstElementChild).height() }, 'slow');
                        }
                        if (isPageReload) {
                            offre_fournisseur_table_page = offre_fournisseur_table.page();
                            // On affiche le tableau
                            $("#offre_fournisseur_results").removeClass("d-none");
                            $('#offre_fournisseur_loader').addClass('d-none');
                            $('.card-body').removeClass('d-none');
                            if (goToOffreFournisseur) {
                                $("#offre_fournisseur-open-modify-" + goToOffreFournisseur).trigger("click");
                            }
                            if (idannuairecreationdepuisannuairefournisseur) {
                                $("#offre_fournisseur-tabs-add-tab").trigger("click");
                                $("#offre_fournisseur_input_fournisseur_0").val(idannuairecreationdepuisannuairefournisseur);
                                $("#offre_fournisseur_input_fournisseur_0").trigger('change');
                            }
                        } else {
                            isPageReload = true;
                            if (trieColonne != null && trieColonne.length > 0) {
                                offre_fournisseur_table.order([trieColonne[0][0], trieColonne[0][1]]).page(offre_fournisseur_table_page).draw(false);
                            } else {
                                offre_fournisseur_table.page(offre_fournisseur_table_page).draw(false);
                            }
                        }
                    });
                }
            })
        };

        // Pour garder l'ordre de tri des colonnes
        $('body').on('click', "th", function() {
            if (trieColonne[0] == offre_fournisseur_table.order()[0]) {
                if (trieColonne[0][1] == 'desc') {
                    trieColonne[0][1] = 'asc';
                } else if (trieColonne[0][1] == 'asc') {
                    trieColonne[0][1] = 'desc';
                }
            } else {
                trieColonne[0] = offre_fournisseur_table.order()[0];
            }
            offre_fournisseur_table.order.neutral();
            offre_fournisseur_table.order([1, "desc"]).order([trieColonne[0][0], trieColonne[0][1]]);
            offre_fournisseur_table.draw(false);
        });

        setupLoadAjax(loadAjax);
        setupResetFilter(resetFilter);

        $('body').on('change', "[id^='filtersoffrefournisseur-']", function () {
            clearTimeout(timerBeforeRefreshDataOffreFournisseur);
            timerBeforeRefreshDataOffreFournisseur = setTimeout(loadAjax({isPageReload: true}), 500);
        });

        // Update la norme CO² suivant la date d'immat
        $('body').on('change', "[id^='vehicule_fournisseur_input_date_mec_']", updateCO2);

        // Bouton reset
        $('body').on('click', '#filtersoffrefournisseur-reset', function () {
            resetFilter();
            loadAjax({isPageReload: true});
        });

        // Stop l'ouverture de l'expertise rapide de la ligne vierge
        $('body').on('show.bs.modal', "#vehicule_fournisseur_quick_expertise___id__", function (e) { e.preventDefault(); });

        // On créait une nouvelle ligne dès qu'on touche à un input de la ligne vierge
        $('body').on('change', "[id^='vehicule_fournisseur_input_'][id$='__id__']", createNewLineFromTheLastLine);

        // Pour les copier/coller des tableaux Excel à Seven
        $('body').on('paste', "[id^='vehicule_fournisseur_input_']", function(e) {
            // On récupère l'id de l'offre
            let idOF = $(e.target).attr("data-idof");
            $("#liste_vehicule_fournisseur_offre_fournisseur_loader_" + idOF).removeClass('d-none');
            colleDonnees(e);
            $("#liste_vehicule_fournisseur_offre_fournisseur_loader_" + idOF).addClass('d-none');
        });

        // Bouton refresh
        $('body').on('click', "#offre_fournisseur-tabs-list-tab", function () {
            loadAjax();
        });

        // Envoi de modification d'une offre fournisseur
        $('body').on('click', "button[id^='offre_fournisseur-send-modify-']", updateOF);

        // Pour supprimer une ligne d'un VF d'une offre
        $('body').on('click', "[id^='vehicule_fournisseur_delete_']", deleteVFsurOF);

        // Controle l'activation et la désactivation du champ modele suivant le champ marque
        $('body').on('change', "[id^='vehicule_fournisseur_input_marque_']", updateMarque);

        if ($("#offre_fournisseur_modify_from_listing_id").val() != 0) {
            $("#offre_fournisseur-open-modify-" + $("#offre_fournisseur_modify_from_listing_id").val()).trigger('click');
        }

        // Pour mettre la même valeur de l'input sur l'input descandant
        $('body').on('click', ".copyToTheNext", function(e) {
            copyToNextInput(e, 'next');
        });

        // Pour mettre la même valeur de l'input sur les input descandants
        $('body').on('click', ".copyToTheAllNext", function(e) {
            copyToNextInput(e, 'all');
        });

        // Ouverture de la modal de changement de fournisseur
        $('body').on('change', "[id^='offre_fournisseur_input_fournisseur_']", ofOpenModalChangementFournisseur);

        // Changement de fournisseur confirmé, avec update de la devise du pays si besoin
        $('body').on('click', "[id^='offre_fournisseur-change-fournisseur-accepte-']", ofChangeFournisseur);

        // Fermeture de la modal de changement de fournisseur
        $('body').on('hidden.bs.modal', "[id^='offre_fournisseur-modal-changement-fournisseur-']", ofCloseModalChangementFournisseur);

        $('body').on('click', "[id^='menu-offre-fournisseur-etat-']", function () {
            if (offre_fournisseur_table != null) {
                let etat = this.id.replace("menu-offre-fournisseur-etat-", "").replace(/-\d+/g, "");
                offre_fournisseur_table.rows().invalidate();
                let all_data = offre_fournisseur_table.rows().data();
                let id_offres = [];
                let vehicules_disabled_on_offre = [];
                $.each(all_data, function (key, value) {
                    let idCheckBox = value[1]["@data-order"];
                    if ($("#selection_vehicule_fournisseur_" + idCheckBox).is(':checked')) {
                        id_offres.push(idCheckBox.replace("selection_vehicule_fournisseur_", ""));
                        if ($("#selection_vehicule_fournisseur_" + idCheckBox).data('isonevehiculenotactif')) {
                            vehicules_disabled_on_offre.push(idCheckBox.replace("selection_vehicule_fournisseur_", ""));
                        }
                    }
                });
                // Si aucun véhicule est rentré
                if (id_offres.length == 0) {
                    toastr.error('Merci de sélectionner au moins un véhicule', 'Erreur');
                    return false;
                }
                // Si des véhicules disabled sont sélectionné
                if (vehicules_disabled_on_offre.length > 0) {
                    toastr.error('L' + vehicules_disabled_on_offre.length > 1 ? "es": "'" + 'offre' + vehicules_disabled_on_offre.length > 1 ? "s ": " " + vehicules_disabled_on_offre.join(',') + ' ont des véhicules désactivés.', 'Erreur');
                    return false;
                }
                let form = new FormData();
                form.append('id_offres', id_offres);
                vfEtatModify(form, etat, true);
            }
        });

        $('body').on('click', "[id^='offre-fournisseur-etat-']", function () {
            let id = this.id.replace(/\w+-/g, "");
            let etat = this.id.replace("offre-fournisseur-etat-", "").replace(/-\d+/g, "");
            let form = new FormData();
            form.append('id_offres', [id]);
            vfEtatModify(form, etat);
        });

        function resetFilter() {
            $("#filtersoffrefournisseur-createur").val('default').selectpicker("refresh");
            $("#filtersoffrefournisseur-fournisseur").val('default').selectpicker("refresh");
        }

        const goToOffreFournisseur = $("#offre_fournisseur_results").data("gotooffrefournisseur");
        const idannuairecreationdepuisannuairefournisseur = $("#offre_fournisseur-tabs").data("idannuairecreationdepuisannuairefournisseur");
        if (goToOffreFournisseur != "") {
            loadAjax({ goToOffreFournisseur: goToOffreFournisseur });
        } else if (idannuairecreationdepuisannuairefournisseur != "") {
            loadAjax({ idannuairecreationdepuisannuairefournisseur: idannuairecreationdepuisannuairefournisseur });
        } else {
            filtreConfigFunOnEvent();
        }
    }
});

function loadOF() {
    loadAjax();
}

// Fonction pour créer une nouvelle ligne depuis le changement de la dernière ligne. Utilise la fonction createNewLine
function createNewLineFromTheLastLine() {
    // On récupère l'id de l'offre
    let idOF = $(this).attr('data-idof');
    // On récupère l'id de l'input sans le __id__
    let idInput = $(this).attr('id').replace('__id__', '');
    // On récupère la valeur de l'input mise
    let valeur = $(this).val();
    // On efface la valeur mise de la ligne vierge
    if (idInput == "vehicule_fournisseur_input_boite_" || idInput == "vehicule_fournisseur_input_carburant_") {
        if (idInput == "vehicule_fournisseur_input_boite_") {
            $(this).val('A').selectpicker('refresh');
        }
        if (idInput == "vehicule_fournisseur_input_carburant_") {
            $(this).val('ES').selectpicker('refresh');
        }
    } else {
        $(this).val('').selectpicker("refresh");
    }
    // On créait une nouvelle ligne
    let idNewLine = createNewLine(idOF);
    // On insère la valeur dans l'input de la nouvelle ligne
    $('#' + idInput + idNewLine).val(valeur).selectpicker('refresh');
    // On initialise le modèle si on a sélectionné une marque
    if (idInput =="vehicule_fournisseur_input_marque_") {
        $('#vehicule_fournisseur_input_marque_' + idNewLine).trigger('change');
    }
    // On gère aussi le cas de la dateMEC qui agit sur l'année
    if (idInput == "vehicule_fournisseur_input_date_mec_") {
        $('#vehicule_fournisseur_input_date_mec_' + idNewLine).trigger('change');
    }
}

// Fonction pour créer une nouvelle ligne dans une offre fournisseur
function createNewLine(idOF) {
    // On construit un ID temporaire pour la nouvelle ligne. Il faut qu'on soit sûr qu'il soit quasiment unique, car si on rajoute 50 lignes d'un coup, il ne faut pas que plusieurs lignes est le même ID
    let idTemporaire = Math.floor(Date.now() / (1 - Math.random()));

    // On vérifie que l'idTemporaire n'existe pas déjà, sinon on le change jusqu'à ce qu'il n'y en ai pas
    let lignesExistantes = $("#liste_vehicule_fournisseur_offre_fournisseur_" + idOF + " tbody");
    while (lignesExistantes.find('[id$="' + idTemporaire + '"]').length > 0) {
        idTemporaire = Math.floor(Date.now() / (1 - Math.random()));
    }

    // On va aussi calculer le numéro de la ligne
    let newNumeroLigne = $("#liste_vehicule_fournisseur_offre_fournisseur_" + idOF + " tbody").children().length;

    // On remplace les id "__id__" par les id temporaires
    const newLineVF = $("#liste_vehicule_fournisseur_offre_fournisseur_" + idOF + " #ligne_vehicule_fournisseur___id__").clone();
    newLineVF.attr("id", newLineVF[0].id.replaceAll("__id__", idTemporaire));
    newLineVF.attr("data-numeroLigne", newNumeroLigne);
    newLineVF.attr("data-id", idTemporaire);
    newLineVF.attr("data-idof", idOF);
    newLineVF.removeClass("numeroLigne-ligneVierge bg-soft-success");
    newLineVF.addClass("numeroLigne-" + newNumeroLigne);
    newLineVF.html(newLineVF.html().replaceAll("__id__", idTemporaire));

    // On ajoute les nouvelles lignes et div des d-none dans le tableau
    newLineVF.insertBefore("#liste_vehicule_fournisseur_offre_fournisseur_" + idOF + " tbody #ligne_vehicule_fournisseur___id__");
    // On ajoute le numéro de ligne aussi
    $("#numero_ligne_" + idTemporaire).html(newNumeroLigne);

    // On va enlever tout les selectpicker déjà fait, pour les remettre après correctement, sinon ça fait des bugs d'un selectpicker dans un autre
    let allSelectpicker = $("#ligne_vehicule_fournisseur_" + idTemporaire + " .selectpicker");
    // On boucle dessus pour les remettre à la racine du <td> et effacer les choses que selectpicker a créé en plus dans le <td>
    allSelectpicker.each(function() {
        let selectpickerClone = $(this).clone();
        $(this).parent().before(selectpickerClone);
        $(this).parent().remove();
        // On bloque bien les modèles avant l'initialisation
        if (selectpickerClone.attr("id") == 'vehicule_fournisseur_input_modele_' + idTemporaire) {
            $('#vehicule_fournisseur_input_modele_' + idTemporaire).prop("disabled", true);
        }
        // Et on initialise le selectpicker
        selectpickerClone.selectpicker();
    });

    // On renvoie l'id de la nouvelle ligne
    return idTemporaire;
}

function colleDonnees(e) {
    // On stoppe l'évènement et la propagation de l'évènement
    e.stopPropagation();
    e.preventDefault();
    // On récupère le VRAI évènement, pas celui de ce méchant JQuery
    e = e.originalEvent;
    // On récupère l'id de l'input pour savoir dans quelle colonne vont être coller les données
    let idInputCible = e.target.getAttribute("id").replace(/(__id__|\d+)$/, '');
    // On récupère la ligne auquel l'input appartient
    let ligneActuelle = $("#" + e.target.getAttribute("id")).parentsUntil('tbody').last();
    // On récupère le numéro de ligne
    let numeroLigneActuelle = parseInt(ligneActuelle.attr("data-numeroLigne") == 'ligneVierge' ? (-1) : ligneActuelle.attr("data-numeroLigne"));
    // On récupère l'id de l'offre
    let idOF = $(e.target).attr("data-idof");
    // On récupère la valeur du collage, on enlève les \n à la fin et on découpe pour avoir les différentes cases
    let valeurCollerSepare = e.clipboardData.getData('text/plain').trimEnd().split('\r\n');
    // On compte combien de valeur vont être à coller
    let nombreValeurCollerSepare = parseInt(valeurCollerSepare.length);
    // On compte combien on a de ligne actuellement
    let nombreLignesActuelles = parseInt($("#liste_vehicule_fournisseur_offre_fournisseur_" + idOF + " tbody").children().length - 1);

    // On détermine de combien de ligne on a besoin et on les créait
    if (nombreValeurCollerSepare >= 1 && !(nombreValeurCollerSepare == 1 && numeroLigneActuelle == nombreLignesActuelles)) {
        let nombreLignesDejaSousLigneCiblee = numeroLigneActuelle !== nombreLignesActuelles ? nombreLignesActuelles - numeroLigneActuelle + 1 : 0;
        let nombreLignesManquantes;
        if (numeroLigneActuelle == -1) {
            nombreLignesManquantes = nombreValeurCollerSepare;
        } else {
            nombreLignesManquantes = nombreValeurCollerSepare - nombreLignesDejaSousLigneCiblee;
            if (nombreLignesDejaSousLigneCiblee == 0) {
                nombreLignesManquantes = nombreLignesManquantes - 1;
            }
        }

        // On rajoute les lignes manquantes
        if (nombreLignesManquantes > 0) {
            for (let index = 0; index < nombreLignesManquantes; index++) {
                createNewLine(idOF);
            }
        }
    }

    if (nombreValeurCollerSepare == 1 && numeroLigneActuelle == -1) {
        createNewLine(idOF);
    }

    // Maintenant, on va coller les données
    for (let index = 0; index < nombreValeurCollerSepare; index++) {
        let ligneChange = numeroLigneActuelle == -1 ? (nombreLignesActuelles == 0 ? 1 : nombreLignesActuelles + 1) + index : numeroLigneActuelle + index;

        let valeur = valeurCollerSepare[index];
        // On insère la valeur dans l'input de la nouvelle ligne
        // On check avant si c'est un nombre, pour remplacer les virgules et espace
        if (idInputCible == "vehicule_fournisseur_input_kilometrage_" || idInputCible == "vehicule_fournisseur_input_puissance_din_" || idInputCible == "vehicule_fournisseur_input_emissionCO2_" || idInputCible == "vehicule_fournisseur_input_malus_" || idInputCible == "vehicule_fournisseur_input_prix_transport_ht_" || idInputCible == "vehicule_fournisseur_input_prix_achat_ht_monnaie_locale_" || idInputCible == "vehicule_fournisseur_input_prix_marche_ttc_" || idInputCible == "vehicule_fournisseur_input_fre_ht_") {
            valeur = valeur.replaceAll(' ', '').replaceAll(',', '.');
        }

        $(".numeroLigne-" + ligneChange + " [id^='" + idInputCible + "']").val(valeur).selectpicker('refresh').trigger('change');
    }
}

// Fonction pour la duplication de la valeur de l'input sur le ou les inputs suivants (descendants)
function copyToNextInput(e, all = "") {
    // On récupère la valeur à copier
    let idInput = $(e.target).parentsUntil('tr').last().attr('id').replace('td_', '');
    let valeurACopier = $("#" + idInput).val();
    // On sauvegarde le type d'input où on est
    let input = $(e.target).parentsUntil('tr').last().attr('id').replace('td_', '').replace(/(\d+)$/, '');
    // On récupère la ligne auquel l'input appartient
    let ligneActuelle = $(e.target).parentsUntil('tbody').last();
    // On récupère le numéro de ligne
    let numeroLigneActuelle = parseInt(ligneActuelle.attr("data-numeroLigne") == 'ligneVierge' ? (-1) : ligneActuelle.attr("data-numeroLigne"));
    // On récupère l'id de l'offre
    let idOF = $(ligneActuelle).attr("data-idof");
    // On compte combien on a de ligne actuellement
    let nombreLignesActuelles = parseInt($("#liste_vehicule_fournisseur_offre_fournisseur_" + idOF + " tbody").children().length - 1);

    // Maintenant, on va coller les données
    if (all == "all") {
        for (let index = (numeroLigneActuelle + 1); index <= nombreLignesActuelles; index++) {
            // On insère la valeur dans l'input de la nouvelle ligne
            // On vérifie dans le cas d'un modèle si la marque correspond au modèle
            if (input == "vehicule_fournisseur_input_modele_") {
                if ($(".numeroLigne-" + index + " [id^='vehicule_fournisseur_input_marque_']").val() == $('#vehicule_fournisseur_input_marque_' + idInput.replace('vehicule_fournisseur_input_modele_', '')).val()) {
                    $(".numeroLigne-" + index + " [id^='" + input + "']").val(valeurACopier).selectpicker('refresh').trigger('change');
                }
            } else {
                $(".numeroLigne-" + index + " [id^='" + input + "']").val(valeurACopier).selectpicker('refresh').trigger('change');
            }
        }
    } else if (all == "next") {
        // On vérifie dans le cas d'un modèle si la marque correspond au modèle
        if (input == "vehicule_fournisseur_input_modele_") {
            if ($(".numeroLigne-" + (numeroLigneActuelle + 1) + " [id^='vehicule_fournisseur_input_marque_']").val() == $('#vehicule_fournisseur_input_marque_' + idInput.replace('vehicule_fournisseur_input_modele_', '')).val()) {
                $(".numeroLigne-" + (numeroLigneActuelle + 1) + " [id^='" + input + "']").val(valeurACopier).selectpicker('refresh').trigger('change');
            }
        } else {
            $(".numeroLigne-" + (numeroLigneActuelle + 1) + " [id^='" + input + "']").val(valeurACopier).selectpicker('refresh').trigger('change');
        }
    }
}

