let suiviRelation_table = null;
let suiviRelation_table_page = 0;
let suiviRelation_table_page_length = 100;
let timerBeforeRefreshDataSuiviRelation = null;
let suiviRelationData = null;
let connexionData = null;

$(document).ready(function() {
    if ($("#suiviRelation_results").length) {
        let trieColonne = [];

        function loadAjax({isPageReload = false} = {}) {
            let optionsRecherche = new FormData();
            optionsRecherche.append('user', $("#filtersSuiviRelation-user").val());
            optionsRecherche.append('dateDebutSuiviRelationKPI', $("#filtersSuiviRelation-dateDebutSuiviRelationKPI").val());
            optionsRecherche.append('dateFinSuiviRelationKPI', $("#filtersSuiviRelation-dateFinSuiviRelationKPI").val());
            optionsRecherche.append('action', $("#filtersSuiviRelation-action").val());
            optionsRecherche.append('codePostal', $("#filtersSuiviRelation-codePostal").val());
            optionsRecherche.append('qualification', $("#filtersSuiviRelation-qualification").val());
            $("#suiviRelation_results").addClass("d-none");
            $.ajax({
                type:'POST',
                url: siteURL + 'admin/kpi/suiviRelation_ajax',
                contentType: false,
                processData: false,
                data: optionsRecherche,
                success: function(data){
                    $("#suiviRelation_results").html(data);
                    // Génération de la datatable des generateur reseaux sociaux
                    suiviRelation_table = $('#suiviRelation_table').DataTable({
                        "dom": dataTablesCustomDom({ colvis: true }),
                        "autoWidth": false,
                        "language": {
                            "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/fr-FR.json"
                        },
                        "pageLength": suiviRelation_table_page_length,
                        "responsive": true,
                        "order": [
                            [0, "desc"]
                        ],
                        "columnDefs": [
                            {
                                "className": "text-center align-middle",
                                "targets": [0, 1, 2, 4, 5],
                            },
                            {
                                "className": "align-middle",
                                "targets": '_all',
                            }
                        ],
                        "conditionalPaging": true,
                    });
                    $('#suiviRelation_table').on('init.dt', function () { initMirrorBtn(); });
                    // Quand la datatable a fini de charger
                    $('#suiviRelation_table').on('draw.dt', function () {
                        if (isPageReload) {
                            suiviRelation_table_page = suiviRelation_table.page();
                            // On affiche le tableau
                            $("#suiviRelation_results").removeClass("d-none");
                        } else {
                            isPageReload = true;
                            if (trieColonne != null && trieColonne.length > 0) {
                                suiviRelation_table.order([trieColonne[0][0], trieColonne[0][1]]).page(suiviRelation_table_page).draw(false);
                            } else {
                                suiviRelation_table.page(suiviRelation_table_page).draw(false);
                            }
                        }
                    });
                    $('#suiviRelation_table').on('page.dt', function () {
                        suiviRelation_table_page = suiviRelation_table.page();
                    });
                    $('#suiviRelation_table').on('length.dt', function () {
                        suiviRelation_table_page = suiviRelation_table.page();
                        suiviRelation_table_page_length = suiviRelation_table.page.len();
                    });
                    // Load les résultats bruts de la recherche
                    $.ajax({
                        type:'POST',
                        url: siteURL + 'admin/kpi/suiviRelation_ajax_data',
                        contentType: false,
                        processData: false,
                        data: optionsRecherche,
                        success: function(data) {
                            // Ajustement des données
                            for (let index = 0; index < data.length; index++) {
                                data[index]["userName"] = data[index]["userName"].slice(0, 1).toUpperCase() + data[index]["userName"].slice(1, data[index]["userName"].length).toLowerCase() + " " + data[index]["userSurname"].toUpperCase();

                                data[index]["type"] = data[index]["type"].replace("&apos;", "'");

                                data[index]["annuaireCodePostal"] = (
                                    data[index]["annuaireCodePostal"] == null || data[index]["annuaireCodePostal"] == "null" || data[index]["annuaireCodePostal"] == "undefined" || data[index]["annuaireCodePostal"] == "[ND]"
                                    ? "Inconnu"
                                    : data[index]["annuaireCodePostal"].slice(0, 2)
                                );

                                data[index]["annuaireNomEntreprise"] = (
                                    data[index]["annuaireNomEntreprise"] == null || data[index]["annuaireNomEntreprise"] == "null" || data[index]["annuaireNomEntreprise"] == "undefined" || data[index]["annuaireNomEntreprise"] == "[ND]"
                                    ? (data[index]["annuaireNomEnseigne"] == "" || data[index]["annuaireNomEnseigne"] == null ? "Inconnu" : data[index]["annuaireNomEnseigne"])
                                    : data[index]["annuaireNomEntreprise"]
                                );
                            }

                            suiviRelationData = data;
                            LoadDataConnexion();
                            $("#suiviRelationMap-1-reload").html("Relancer le chargement du graphique").removeAttr("disabled");
                            $("#suiviRelationMap-2-reload").html("Relancer le chargement du graphique").removeAttr("disabled");
                            if ($("#suiviRelation-tabs-map").hasClass("active")) {
                                if ($("#actionsMapsGroup").hasClass("active")) {
                                    loadMapSuiviRelation("1");
                                    loadMapSuiviRelation("2");
                                }
                            }

                            $.ajax({
                                type:'POST',
                                url: siteURL + 'admin/kpi/suiviRelation_pourcentage',
                                success: function(data) {
                                    $("#suiviRelation-tabs-pourcentage").html(data);
                                    $.ajax({
                                        type:'POST',
                                        url: siteURL + 'admin/kpi/suiviRelation_chiffre',
                                        success: function(data) {
                                            $("#suiviRelation-tabs-chiffre").html(data);
                                            loadGraphSuiviRelation();
                                        }
                                    });
                                }
                            });
                        }
                    });
                }
            });
        };

        // Pour garder l'ordre de tri des colonnes
        $('body').on('click', "th", function() {
            if (trieColonne[0] == suiviRelation_table.order()[0]) {
                if (trieColonne[0][1] == 'desc') {
                    trieColonne[0][1] = 'asc';
                } else if (trieColonne[0][1] == 'asc') {
                    trieColonne[0][1] = 'desc';
                }
            } else {
                trieColonne[0] = suiviRelation_table.order()[0];
            }
            suiviRelation_table.order.neutral();
            suiviRelation_table.order([0, "desc"]).order([trieColonne[0][0], trieColonne[0][1]]);
            suiviRelation_table.draw(false);
        });

        setupLoadAjax(loadAjax);
        setupResetFilter(resetFilter);

        $('body').on('click', "#suiviRelation-tabs-map-tab", function() {
            onClassChange(document.querySelector("#suiviRelation-tabs-map"), (observer) => {
                let activeTabId = $('#suiviRelation-tabs-map .active').attr('href').substring(1);
                if (activeTabId === "actionsMapsGroup") {
                    loadMapSuiviRelation("1");
                    loadMapSuiviRelation("2");
                } else if (activeTabId === "connexionsMapsGroup") {
                    loadMapSuiviRelation("3");
                    loadMapSuiviRelation("4");
                }
                
                observer.disconnect();
            });
        });

        $('body').on('click', "[id^='suiviRelation-tabs-map-']", function() {
            const id = this.id;
            onClassChange(document.querySelector("#" + id), (observer) => {
                let activeTabId = id.replace("suiviRelation-tabs-map-", "");
                if (activeTabId === "actions") {
                    loadMapSuiviRelation("1");
                    loadMapSuiviRelation("2");
                } else if (activeTabId === "connexions") {
                    loadMapSuiviRelation("3");
                    loadMapSuiviRelation("4");
                }
                
                observer.disconnect();
            });
        });

        $('body').on('click', "[id^='suiviRelationMap-'][id$='-reload']", function() {
            loadMapSuiviRelation(this.id.replace("suiviRelationMap-", "").replace("-reload", ""));
        });

        $('body').on('change', "[id^='filtersSuiviRelation-']", function() {
            clearTimeout(timerBeforeRefreshDataSuiviRelation);
            timerBeforeRefreshDataSuiviRelation = setTimeout(loadAjax({isPageReload: true}), 500);
        });

        // Bouton reset
        $('body').on('click', '#filtersSuiviRelation-reset', function() {
            resetFilter();
            loadAjax({isPageReload: true});
        });

        function initDateDebutSuiviRelationKPI() {
            $("#filtersSuiviRelation-dateDebutSuiviRelationKPI").val($("#dateDebutSuiviRelationKPI").val());
        }

        function resetFilter() {
            initDateDebutSuiviRelationKPI();
            $("#filtersSuiviRelation-user").val('default').selectpicker("refresh");
            $("#filtersSuiviRelation-dateFinSuiviRelationKPI").val('');
            $("#filtersSuiviRelation-action").val('default').selectpicker("refresh");
            $("#filtersSuiviRelation-codePostal").val('default').selectpicker("refresh");
            $("#filtersSuiviRelation-qualification").val('default').selectpicker("refresh");
            $("#filtreConfigSelect-filtreConfig-aucun").val('aucun').prop("selected", true);
        }

        filtreConfigFunOnEvent();
        initDateDebutSuiviRelationKPI();

        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has('gotocarte')) {
            $('#suiviRelation-tabs-map-tab').trigger('click');
        }
        if (urlParams.has('gotoconnexions')) {
            $('#suiviRelation-tabs-map-connexions').trigger('click');
        }
    }
});

function loadMapSuiviRelation(idMap) {
    if (suiviRelationData != null) {
        let data = [];
        let min = 0;
        let max = 0;
        let diff = 0;
        let slicesObj = null;
        let areasObj = {};
        let plotsObj = {};
        switch (idMap) {
            case '1':
                suiviRelationData.forEach(element => {
                    if (data[element.annuaireCodePostal] === undefined) {
                        data[element.annuaireCodePostal] = 1;
                    } else {
                        data[element.annuaireCodePostal]++;
                    }
                });
                for (const key in tabCodePostal) {
                    if (Object.hasOwnProperty.call(tabCodePostal, key)) {
                        const label = tabCodePostal[key];
                        let value = (data[key] === undefined ? 0 : data[key]);
                        if (value > 0 && (min === 0 || value < min)) { min = value; }
                        if (value > max) { max = value; }
                        areasObj["department-"+key] = {
                            value: value,
                            tooltip: { content: "<span style=\"font-weight:bold;\">" + label + " (" + key + ")</span><br />Activité : " + value }
                        }
                    }
                }
                diff = max - min;
                slicesObj = [{
                    max: min + ((diff / 100) * 10),
                    attrs: { fill: "#000000" },
                    label: "Activité très faible"
                }, {
                    min: min + ((diff / 100) * 10),
                    max: min + ((diff / 100) * 30),
                    attrs: { fill: "#dc3545" },
                    label: "Activité faible"
                }, {
                    min: min + ((diff / 100) * 30),
                    max: min + ((diff / 100) * 50),
                    attrs: { fill: "#ff851b" },
                    label: "Activité médiocre"
                }, {
                    min: min + ((diff / 100) * 50),
                    max: min + ((diff / 100) * 70),
                    attrs: { fill: "#ffc107" },
                    label: "Activité moyenne"
                }, {
                    min: min + ((diff / 100) * 70),
                    max: min + ((diff / 100) * 90),
                    attrs: { fill: "#28a745" },
                    label: "Activité forte"
                }, {
                    min: min + ((diff / 100) * 90),
                    attrs: { fill: "#1e7b34" },
                    label: "Activité très forte"
                }];

                // INIT MAP
                $("#suiviRelationMap-1").mapael({
                    map: {
                        name: "france_departments",
                        defaultArea: {
                            attrs: {
                                stroke: "#fff",
                                "stroke-width": 1
                            },
                            attrsHover: {
                                fill: "#007bff",
                                "stroke-width": 2
                            }
                        }
                    },
                    legend: {
                        area: {
                            title: "Nombre d'action par département",
                            slices: slicesObj
                        }
                    },
                    areas: areasObj
                });
                break;

            case '2':
                let tabSiret = {};
                suiviRelationData.forEach(element => {
                    if (data[element.annuaireSiret] === undefined) {
                        data[element.annuaireSiret] = 1;
                        tabSiret[element.annuaireSiret] = {
                            "annuaireNomEntreprise": element.annuaireNomEntreprise,
                            "annuaireGeolocalisation": element.annuaireGeolocalisation
                        };
                    } else {
                        data[element.annuaireSiret]++;
                    }
                });
                for (const key in tabSiret) {
                    if (Object.hasOwnProperty.call(tabSiret, key)) {
                        const label = tabSiret[key]["annuaireNomEntreprise"];
                        let value = data[key];
                        // MIN
                        if (min != 0) { if (value < min && value != 0) { min = value; } } else { min = value; }
                        // MAX
                        if (value > max) { max = value; }
                        if (!(isNaN(parseFloat(tabSiret[key]["annuaireGeolocalisation"][0])) && isNaN(parseFloat(tabSiret[key]["annuaireGeolocalisation"][1])))) {
                            // SET VALUE ON DEPARTMENT
                            plotsObj["siret-"+key] = {
                                value: value,
                                latitude: parseFloat(tabSiret[key]["annuaireGeolocalisation"][0]),
                                longitude: parseFloat(tabSiret[key]["annuaireGeolocalisation"][1]),
                                tooltip: { content: "<span style=\"font-weight:bold;\">" + label + "</span><br />Activité : " + value }
                            }
                        }
                    }
                }
                diff = max - min;
                slicesObj = [{
                    size: 4, type: "circle",
                    max: min + ((diff / 100) * 10),
                    attrs: { fill: "#000000" },
                    label: "Activité très faible"
                }, {
                    size: 8, type: "circle",
                    min: min + ((diff / 100) * 10),
                    max: min + ((diff / 100) * 30),
                    attrs: { fill: "#dc3545" },
                    label: "Activité faible"
                }, {
                    size: 10, type: "circle",
                    min: min + ((diff / 100) * 30),
                    max: min + ((diff / 100) * 50),
                    attrs: { fill: "#ff851b" },
                    label: "Activité médiocre"
                }, {
                    size: 12, type: "circle",
                    min: min + ((diff / 100) * 50),
                    max: min + ((diff / 100) * 70),
                    attrs: { fill: "#ffc107" },
                    label: "Activité moyenne"
                }, {
                    size: 14, type: "circle",
                    min: min + ((diff / 100) * 70),
                    max: min + ((diff / 100) * 90),
                    attrs: { fill: "#28a745" },
                    label: "Activité forte"
                }, {
                    size: 16, type: "circle",
                    min: min + ((diff / 100) * 90),
                    attrs: { fill: "#1e7b34" },
                    label: "Activité très forte"
                }];

                $("#suiviRelationMap-2").mapael({
                    map: {
                        name: "france_departments",
                        defaultArea: {
                            attrs: {
                                fill: "#ffffe6",
                            },
                            attrsHover: {
                                fill: "#cce6ff",
                                stroke: "#5d5d5d",
                                "stroke-width": 1,
                                "stroke-linejoin": "round"
                            }
                        }
                    },
                    legend: {
                        plot: {
                            title: "Nombre d'action par annuaire",
                            marginBottom: 25,
                            slices: slicesObj
                        }
                    },
                    plots: plotsObj
                });
                break;

            case '3':

                connexionData.forEach(element => {
                    if (data[element.codePostal] === undefined) {
                        data[element.codePostal] = element.nbConnexions;
                    } else {
                        data[element.codePostal] += element.nbConnexions;
                    }
                });

                for (const key in tabCodePostal) {
                    if (Object.hasOwnProperty.call(tabCodePostal, key)) {
                        const label = tabCodePostal[key];
                        let value = (data[key] === undefined ? 0 : data[key]);
                        if (value > 0 && (min === 0 || value < min)) { min = value; }
                        if (value > max) { max = value; }
                        areasObj["department-"+key] = {
                            value: value,
                            tooltip: { content: "<span style=\"font-weight:bold;\">" + label + " (" + key + ")</span><br />Activité : " + value }
                        }
                    }
                }

                diff = max - min;
                slicesObj = [{
                    max: min + ((diff / 100) * 10),
                    attrs: { fill: "#000000" },
                    label: "Activité très faible"
                }, {
                    min: min + ((diff / 100) * 10),
                    max: min + ((diff / 100) * 30),
                    attrs: { fill: "#dc3545" },
                    label: "Activité faible"
                }, {
                    min: min + ((diff / 100) * 30),
                    max: min + ((diff / 100) * 50),
                    attrs: { fill: "#ff851b" },
                    label: "Activité médiocre"
                }, {
                    min: min + ((diff / 100) * 50),
                    max: min + ((diff / 100) * 70),
                    attrs: { fill: "#ffc107" },
                    label: "Activité moyenne"
                }, {
                    min: min + ((diff / 100) * 70),
                    max: min + ((diff / 100) * 90),
                    attrs: { fill: "#28a745" },
                    label: "Activité forte"
                }, {
                    min: min + ((diff / 100) * 90),
                    attrs: { fill: "#1e7b34" },
                    label: "Activité très forte"
                }];

                $("#suiviRelationMap-3").mapael({
                    map: {
                        name: "france_departments",
                        defaultArea: {
                            attrs: {
                                stroke: "#fff",
                                "stroke-width": 1
                            },
                            attrsHover: {
                                fill: "#007bff",
                                "stroke-width": 2
                            }
                        }
                    },
                    legend: {
                        area: {
                            title: "Nombre de connexions par département",
                            slices: slicesObj
                        }
                    },
                    areas: areasObj
                });
                break;
            
            case '4':
                let tabId = {};
                connexionData.forEach(element => {
                    if (data[element.marchandId] === undefined) {
                        data[element.marchandId] = element.nbConnexions;
                        tabId[element.marchandId] = {
                            "annuaireNomEntreprise": element.nomEntreprise,
                            "annuaireGeolocalisation": element.geolocalisation
                        };
                    } else {
                        data[element.marchandId] += element.nbConnexions;
                    }
                });
                for (const key in tabId) {
                    if (Object.hasOwnProperty.call(tabId, key)) {
                        const label = tabId[key]["annuaireNomEntreprise"];
                        let value = data[key];
                        if (value > 0 && (min === 0 || value < min)) { min = value; }
                        if (value > max) { max = value; }
                        if (!(isNaN(parseFloat(tabId[key]["annuaireGeolocalisation"][0])) && isNaN(parseFloat(tabId[key]["annuaireGeolocalisation"][1])))) {
                            plotsObj["siret-"+key] = {
                                value: value,
                                latitude: parseFloat(tabId[key]["annuaireGeolocalisation"][0]),
                                longitude: parseFloat(tabId[key]["annuaireGeolocalisation"][1]),
                                tooltip: { content: "<span style=\"font-weight:bold;\">" + label + "</span><br />Connexions : " + value }
                            }
                        }
                    }
                }
                diff = max - min;
                slicesObj = [{
                    size: 4, type: "circle",
                    max: min + ((diff / 100) * 10),
                    attrs: { fill: "#000000" },
                    label: "Activité très faible"
                }, {
                    size: 8, type: "circle",
                    min: min + ((diff / 100) * 10),
                    max: min + ((diff / 100) * 30),
                    attrs: { fill: "#dc3545" },
                    label: "Activité faible"
                }, {
                    size: 10, type: "circle",
                    min: min + ((diff / 100) * 30),
                    max: min + ((diff / 100) * 50),
                    attrs: { fill: "#ff851b" },
                    label: "Activité médiocre"
                }, {
                    size: 12, type: "circle",
                    min: min + ((diff / 100) * 50),
                    max: min + ((diff / 100) * 70),
                    attrs: { fill: "#ffc107" },
                    label: "Activité moyenne"
                }, {
                    size: 14, type: "circle",
                    min: min + ((diff / 100) * 70),
                    max: min + ((diff / 100) * 90),
                    attrs: { fill: "#28a745" },
                    label: "Activité forte"
                }, {
                    size: 16, type: "circle",
                    min: min + ((diff / 100) * 90),
                    attrs: { fill: "#1e7b34" },
                    label: "Activité très forte"
                }];

                $("#suiviRelationMap-4").mapael({
                    map: {
                        name: "france_departments",
                        defaultArea: {
                            attrs: {
                                fill: "#ffffe6",
                            },
                            attrsHover: {
                                fill: "#cce6ff",
                                stroke: "#5d5d5d",
                                "stroke-width": 1,
                                "stroke-linejoin": "round"
                            }
                        }
                    },
                    legend: {
                        plot: {
                            title: "Nombre de connexion par annuaire",
                            marginBottom: 25,
                            slices: slicesObj
                        }
                    },
                    plots: plotsObj
                });
                break;

            default:
                break;
        }
    }
}
function loadGraphSuiviRelation() {
    let data = suiviRelationData;
    // Liste les prefix d'id pour les construire dynamiquement
    let allId = ["user", "action", "codePostal", "annuaire"];
    // Initialise les Canvas
    let DonutChartCanvas = [];
    for (let index = 0; index < allId.length; index++) {
        $("#" + allId[index] + 'DonutChart').parent().html("<div class=\"chartjs-size-monitor\"><div class=\"chartjs-size-monitor-expand\"><div class=\"\"></div></div><div class=\"chartjs-size-monitor-shrink\"><div class=\"\"></div></div></div><canvas id=\"" + allId[index] + "DonutChart\" style=\"min-height: 250px; height: 250px; max-height: 250px; max-width: 100%; display: block; width: 764px;\" width=\"764\" height=\"250\" class=\"chartjs-render-monitor\"></canvas>");
        DonutChartCanvas[allId[index]] = document.getElementById(allId[index] + 'DonutChart').getContext('2d');
    }
    let BarChartCanvas = [];
    for (let index = 0; index < allId.length; index++) {
        $("#" + allId[index] + 'BarChart').parent().html("<div class=\"chartjs-size-monitor\"><div class=\"chartjs-size-monitor-expand\"><div class=\"\"></div></div><div class=\"chartjs-size-monitor-shrink\"><div class=\"\"></div></div></div><canvas id=\"" + allId[index] + "BarChart\" style=\"min-height: 250px; height: 250px; max-height: 250px; max-width: 100%; display: block; width: 764px;\" width=\"764\" height=\"250\" class=\"chartjs-render-monitor\"></canvas>");
        BarChartCanvas[allId[index]] = document.getElementById(allId[index] + 'BarChart').getContext('2d');
    }
    // Liste des champs utilisable directement
    let genericKeys = ["userName", "type", "annuaireCodePostal", "annuaireNomEntreprise"];
    // Initialise les tableaux des champs utilisable directement
    let chartLabel = [];
    for (let index = 0; index < genericKeys.length; index++) {
        chartLabel[genericKeys[index]] = [];
    }

    // Liste completes des champs
    let allKeys = ["userName", "type", "annuaireCodePostal", "annuaireNomEntreprise"];
    // Initialise le tableau qui va stocker les data set
    let generatedDataSet = [];
    // Parcours les champs utilisable directement et compte les occurences
    genericKeys.forEach(key => {
        generatedDataSet[key] = [];
        data.forEach(element => {
            if (!chartLabel[key].includes(element[key])) {
                chartLabel[key].push(element[key]);
                generatedDataSet[key].push(1);
            } else {
                generatedDataSet[key][chartLabel[key].findIndex(el => el == element[key])]++;
            }
        });
    });

    // Parcours les champs utilisable directement et les tri
    sortedLabel = [];
    sortedDataSet = [];

    let arrayOfObj = null;
    genericKeys.forEach(key => {
        arrayOfObj = chartLabel[key].map(function(d, i) {
            return {
                label: d,
                data: generatedDataSet[key][i]
            };
        });
        sortedArrayOfObj = arrayOfObj.sort(function(a, b) {
            if (a.data > b.data) {
                return -1;
            }
            if (a.data < b.data) {
                return 1;
            }
            return 0;
        });
        sortedLabel[key] = [];
        sortedDataSet[key] = [];
        sortedArrayOfObj.forEach(function(d) {
            sortedLabel[key].push(d.label);
            sortedDataSet[key].push(d.data);
        });
    });

    // Calcul en Pourcentage
    let countCentPourCent = [];
    allKeys.forEach(key => {
        countCentPourCent[key] = 0;
        sortedDataSet[key].forEach(el => {
            countCentPourCent[key] += el;
        });
    });

    let sortedLabelDonut = [];
    let sortedLabelBar = [];
    allKeys.forEach(key => {
        sortedLabelDonut[key] = [];
        sortedLabelBar[key] = [];
        for (let index = 0; index < sortedLabel[key].length; index++) {
            sortedLabelDonut[key][index] = sortedLabel[key][index] + " (" + Math.round((sortedDataSet[key][index] / countCentPourCent[key]) * 100) + "%)";
            sortedLabelBar[key][index] = sortedLabel[key][index] + " (" + sortedDataSet[key][index] + ")";
        }
    });

    // Options générique
    let donutOptions = {
        maintainAspectRatio : false,
        responsive : true,
        tooltips: {
            callbacks: {
                label: function(tooltipItem, data) {
                    var dataset = data.datasets[tooltipItem.datasetIndex];
                    var total = dataset.data.reduce(function(previousValue, currentValue, currentIndex, array) {
                        return previousValue + currentValue;
                    });
                    var currentValue = dataset.data[tooltipItem.index];
                    return data.labels[tooltipItem.index].match(/.+[ ]/g) + ": " + currentValue + "%";
                }
            }
        }
    }
    let barOptions = {
        maintainAspectRatio : false,
        responsive : true,
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true
                }
            }]
        },
        legend: {
            display: false
        },
        tooltips: {
            callbacks: {
                label: function(tooltipItem, data) {
                    var dataset = data.datasets[tooltipItem.datasetIndex];
                    var total = dataset.data.reduce(function(previousValue, currentValue, currentIndex, array) {
                        return previousValue + currentValue;
                    });
                    var currentValue = dataset.data[tooltipItem.index];
                    return data.labels[tooltipItem.index].match(/.+[ ]/g) + ": " + currentValue;
                }
            }
        }
    }
    // Créer les donuts
    let colorSet = ['#f56954', '#00a65a', '#f39c12', '#00c0ef', '#3c8dbc', '#d2d6de', '#dd8888', '#88dddd', '#88dd88', '#dd88dd', '#dddd88', '#8888dd', '#b3ff66'];

    for (let index = 0; index < allId.length; index++) {
        DonutChartCanvas[allId[index]] = document.getElementById(allId[index] + 'DonutChart').getContext('2d');
        new Chart(DonutChartCanvas[allId[index]], {
            type: 'doughnut',
            data: { labels: sortedLabelDonut[allKeys[index]], datasets: [{ data: sortedDataSet[allKeys[index]].map(el => Math.round((el / countCentPourCent[allKeys[index]]) * 100)), backgroundColor: colorSet}]},
            options: donutOptions
        })
    }

    for (let index = 0; index < allId.length; index++) {
        BarChartCanvas[allId[index]] = document.getElementById(allId[index] + 'BarChart').getContext('2d');
        new Chart(BarChartCanvas[allId[index]], {
            type: 'bar',
            data: { labels: sortedLabelBar[allKeys[index]], datasets: [{ data: sortedDataSet[allKeys[index]]}]},
            options: barOptions
        })
    }
}

function LoadDataConnexion() {

    let optionsRecherche = new FormData();
    optionsRecherche.append('dateDebutSuiviRelationKPI', $("#filtersSuiviRelation-dateDebutSuiviRelationKPI").val());
    optionsRecherche.append('dateFinSuiviRelationKPI', $("#filtersSuiviRelation-dateFinSuiviRelationKPI").val());
    optionsRecherche.append('codePostal', $("#filtersSuiviRelation-codePostal").val());
    optionsRecherche.append('qualification', $("#filtersSuiviRelation-qualification").val());
    optionsRecherche.append('user', $("#filtersSuiviRelation-user").val());

    $.ajax({
        type :'POST',
        url: siteURL + 'admin/kpi/suiviRelation_ajax_data_connexion',
        contentType: false,
        processData: false,
        data: optionsRecherche,
        success: function(data){
            for (let i = 0 ; i < data.length ; i++){
                data[i]["codePostal"] = (
                    data[i]["codePostal"] == null || data[i]["codePostal"] == "null" || data[i]["codePostal"] == "undefined" || data[i]["codePostal"] == "[ND]"
                    ? "Inconnu"
                    : data[i]["codePostal"].slice(0, 2)
                );
                data[i]["nomEntreprise"] = (
                    data[i]["nomEntreprise"] == null || data[i]["nomEntreprise"] == "null" || data[i]["nomEntreprise"] == "undefined" || data[i]["nomEntreprise"] == "[ND]"
                    ? (data[i]["nomEnseigne"] == "" || data[i]["nomEnseigne"] == null ? "Inconnu" : data[i]["nomEnseigne"])
                    : data[i]["nomEntreprise"]
                );
                data[i]["nbConnexions"] = parseInt(data[i]["nbConnexions"]);
            }
            connexionData = data;
            $("#suiviRelationMap-3-reload").html("Relancer le chargement du graphique").removeAttr("disabled");
            $("#suiviRelationMap-4-reload").html("Relancer le chargement du graphique").removeAttr("disabled");
            if ($("#suiviRelation-tabs-map").hasClass("active")) {
                if ($("#connexionsMapsGroup").hasClass("active")) {
                    loadMapSuiviRelation("3");
                    loadMapSuiviRelation("4");
                }
            }
        }
    })

}
