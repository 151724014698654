// Génération de la table des marques
marque_table = $('#marque_table').DataTable( {
    "autoWidth": false,
	"language": {
        "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/fr-FR.json"
    },
    "responsive": true,
	"ajax": siteURL + 'json/marques',
	"order": [[ 0, "asc" ]],
	"columnDefs": [
        // TEXT CENTER
        {
            "className": "text-center align-middle",
            "targets": [3,4],
        },
        // ALIGN MIDDLE
        {
            "className": "align-middle",
            "targets": '_all',
        },
        // NOM
		{
            "targets": 0,
			"render": function ( data, type, row ) {
                return row['marque_name'];
            },
		},
        // REWRITE
        {
            "targets": 1,
            "render": function ( data, type, row ) {
                return row['marque_name_rewrite'];
            },
        },
        // IMAGE
        {
            "width": "70px",
            "targets": 2,
            "render": function ( data, type, row ) {
                if (row['marque_image']['marque_image_thumbnail_url'] != null) {
                    return '<img src="../../'+row['marque_image']['marque_image_thumbnail_url']+'" style="width:70px;">';
                }
                else {
                    return '<img src="'+siteURL+'assets/pics/marques/default.png" style="width:70px;">';
                }
            },
        },
        // VISIBLE
        {
            "width": "90px",
            "targets": 3,
            "render": function(data, type, row) {
                let rep = "";
                for (const property in row["marque_is_visible"]) {
                    rep += `${property} : ${(
                        row["marque_is_visible"][property] == 1
                        ? '<i class="fas fa-check" style="color:green" title="Validé"></i><span class="d-inline d-lg-none pl-2">OUI</span>'
                        : '<i class="fas fa-times" style="color:red" title="Non Validé"></i><span class="d-inline d-lg-none pl-2">NON</span>'
                    )}<br />`;
                }
                return rep;
            },
        },
        // MODIFY
		{
            "width": "50px",
            "targets": 4,
			"render": function ( data, type, row ) {
                return '<button class="btn w-100 p-0" id="marque-open-modify-'+row['id']+'" data-category="vehicules"><i class="fad fa-edit"></i>';
            },
            "orderable": false,
		},
	],
    "conditionalPaging": true,
});

// Envoi de modification d'une marque
$('body').on('click', "button[id^='marque-send-modify-']", function() {
    var id_clicked = this.id.replace('marque-send-modify-', '');

    var form = new FormData();
    // On extrait le contenu du formulaire dans la variable 'form'
    form.append('id', id_clicked);

    form.append('marque_name', $('#marque_name_'+id_clicked).val());
    form.append('marque_rewrite', $('#marque_rewrite_'+id_clicked).val());
    form.append('marque_image', $('#file_result_'+id_clicked).val());
    form.append('marque_isVisibleParticulier', $('input[name="marque_isVisibleParticulier_' + id_clicked + '"]:checked').val());
    form.append('marque_isVisiblePro', $('input[name="marque_isVisiblePro_' + id_clicked + '"]:checked').val());

    $.ajax({
        type: 'POST',
        url: siteURL + 'admin/vehicules/marque/modify',
        cache: false,
        contentType: false,
        processData: false,
        data: form,
        success: function(data) {
            // TOAST
            toastr.success('La marque a bien été enregistrée', 'Succès');
            // On nettoye le html en supprimant l'onglet 'Modifier/Nouveau'
            $('#marque-tabs-modify-'+id_clicked).remove();
            $('#marque-close-modify-'+id_clicked).parent().remove();

            if (id_clicked == 0) {
                // On réinitialise le formulaire
                $('#formmarque_'+id_clicked)[0].reset();
                $("[id*='_content_']").each( function(e) { $(this).summernote('reset'); });
            }
            // Retour à la liste d'actualités en rechargeant la datatable
            $('#marque-tabs-list-tab').trigger('click');
            marque_table.ajax.reload();
        }
    });
     // Empèche le 'button' de fonctionner
    return false;
});

$('body').on('click', "button[id^='marque_valider_']", function() {
    var id_clicked = this.id.replace('marque_valider_', '');
    var id_seo_clicked = $(this).attr("data-id-seo");
    console.log(id_seo_clicked);

    var form = new FormData();
    form.append('id', id_clicked);
    switch (id_seo_clicked) {
        case 'particulier_0':
            form.append('marque_titleParticulier', ($('#marque_titleParticulier_'+id_clicked).val() != "" ? $('#marque_titleParticulier_'+id_clicked).val() : null));
            form.append('marque_metaParticulier', ($('#marque_metaParticulier_'+id_clicked).val() != "" ? $('#marque_metaParticulier_'+id_clicked).val() : null));
            break;

        case 'particulier_1':
            form.append('marque_baliseH1Particulier', ($('#marque_baliseH1Particulier_'+id_clicked).val() != "" ? $('#marque_baliseH1Particulier_'+id_clicked).val() : null));
            form.append('marque_texteIntroParticulier', (!$('#marque_texteIntroParticulier_'+id_clicked).next().find('.ck-editor__editable').html().includes("<br data-cke-filler=\"true\">") ? $('#marque_texteIntroParticulier_'+id_clicked).next().find('.ck-editor__editable').html() : null ));
            break;

        case 'particulier_2':
            form.append('marque_baliseH2N1Particulier', ($('#marque_baliseH2N1Particulier_'+id_clicked).val() != "" ? $('#marque_baliseH2N1Particulier_'+id_clicked).val() : null));
            form.append('marque_contenu1Particulier', ($('#marque_contenu1Particulier_'+id_clicked).next().find('.ck-editor__editable').html() != "<p></p>" ? $('#marque_contenu1Particulier_'+id_clicked).next().find('.ck-editor__editable').html() : null ));
            break;

        case 'particulier_3':
            form.append('marque_baliseH2N2Particulier', ($('#marque_baliseH2N2Particulier_'+id_clicked).val() != "" ? $('#marque_baliseH2N2Particulier_'+id_clicked).val() : null));
            form.append('marque_contenu2Particulier', ($('#marque_contenu2Particulier_'+id_clicked).next().find('.ck-editor__editable').html() != "<p></p>" ? $('#marque_contenu2Particulier_'+id_clicked).next().find('.ck-editor__editable').html() : null ));
            break;

        case 'particulier_4':
            form.append('marque_baliseH2N3Particulier', ($('#marque_baliseH2N3Particulier_'+id_clicked).val() != "" ? $('#marque_baliseH2N3Particulier_'+id_clicked).val() : null));
            form.append('marque_contenu3Particulier', ($('#marque_contenu3Particulier_'+id_clicked).next().find('.ck-editor__editable').html() != "<p></p>" ? $('#marque_contenu3Particulier_'+id_clicked).next().find('.ck-editor__editable').html() : null ));
            break;

        case 'pro_0':
            form.append('marque_titlePro', ($('#marque_titlePro_'+id_clicked).val() != "" ? $('#marque_titlePro_'+id_clicked).val() : null));
            form.append('marque_metaPro', ($('#marque_metaPro_'+id_clicked).val() != "" ? $('#marque_metaPro_'+id_clicked).val() : null));
            break;

        case 'pro_1':
            form.append('marque_baliseH1Pro', ($('#marque_baliseH1Pro_'+id_clicked).val() != "" ? $('#marque_baliseH1Pro_'+id_clicked).val() : null));
            form.append('marque_texteIntroPro', ($('#marque_texteIntroPro_'+id_clicked).next().find('.ck-editor__editable').html() != "<p></p>" ? $('#marque_texteIntroPro_'+id_clicked).next().find('.ck-editor__editable').html() : null ));
            break;

        case 'pro_2':
            form.append('marque_baliseH2N1Pro', ($('#marque_baliseH2N1Pro_'+id_clicked).val() != "" ? $('#marque_baliseH2N1Pro_'+id_clicked).val() : null));
            form.append('marque_contenu1Pro', ($('#marque_contenu1Pro_'+id_clicked).next().find('.ck-editor__editable').html() != "<p></p>" ? $('#marque_contenu1Pro_'+id_clicked).next().find('.ck-editor__editable').html() : null ));
            break;

        case 'pro_3':
            form.append('marque_baliseH2N2Pro', ($('#marque_baliseH2N2Pro_'+id_clicked).val() != "" ? $('#marque_baliseH2N2Pro_'+id_clicked).val() : null));
            form.append('marque_contenu2Pro', ($('#marque_contenu2Pro_'+id_clicked).next().find('.ck-editor__editable').html() != "<p></p>" ? $('#marque_contenu2Pro_'+id_clicked).next().find('.ck-editor__editable').html() : null ));
            break;

        case 'pro_4':
            form.append('marque_baliseH2N3Pro', ($('#marque_baliseH2N3Pro_'+id_clicked).val() != "" ? $('#marque_baliseH2N3Pro_'+id_clicked).val() : null));
            form.append('marque_contenu3Pro', ($('#marque_contenu3Pro_'+id_clicked).next().find('.ck-editor__editable').html() != "<p></p>" ? $('#marque_contenu3Pro_'+id_clicked).next().find('.ck-editor__editable').html() : null ));
            break;

        default:
            break;
    }

    $.ajax({
        type: 'POST',
        url: siteURL + 'admin/vehicules/marque/modify_seo',
        cache: false,
        contentType: false,
        processData: false,
        data: form,
        success: function(data) {
            // TOAST
            toastr.success('Le SEO de la marque a bien été enregistrée', 'Succès');
            marque_table.ajax.reload();
        }
    });
     // Empèche le 'button' de fonctionner
    return false;
});