$(document).ready(function () {
    if ($("#annuaire_results").length) {
        function loadAjax() {
            let idAnnuaire = this.id.replace("annuaire-sub-tab-commerce-", "");
            let form = new FormData();
            form.append('idSuiviRelationObject', 0);
            form.append('idAnnuaire', idAnnuaire);
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/annuaire/annuaires/vf',
                contentType: false,
                processData: false,
                data: form,
                success: function (data) {
                    if (data == null) {
                        toastr.error('Erreur lors du chargement', 'Erreur');
                    } else {
                        $('#annuaire-tab-commerce-' + idAnnuaire + '-vf').html(data);
                        initDatatable(idAnnuaire);
                    }
                }
            });
        }

        function initDatatable(idAnnuaire) {
            $("#annuaire-commerce-table-vf-" + idAnnuaire).DataTable({
                "autoWidth": false,
                "language": { "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/fr-FR.json" },
                "paging": true,
                "responsive": true,
                "order": [[0, "desc"]],
                "conditionalPaging": true,
                "columnDefs": [
                    { "className": "text-center align-middle", "targets": '_all' },
                    { type: 'num', targets: [0, 6, 8, 10] },
                ],
            });
        }

        $('body').on('click', "[id^='annuaire-sub-tab-commerce-']", loadAjax);
    }
});
