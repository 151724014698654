$('body').on('click', "[channel-dialog-open]", function (e) {
    dialogOpen($(e.target).next()[0]);
});

$('body').on('click', "[channel-dialog-close]", function (e) {
    dialogClose($(e.target).parents("dialog")[0]);
});

// This regex is used to get dialog by replacing the suffix and preserving the id for identification of dialog in the page.
const dialogRGX = /^(.*)-\w+$/g;

/**
 * @function dialogInOut
 * @param {Element} dialog
 * @param {string} inOut
 * @summary This function is used to get the dialog element
 */
function dialogInOut(dialog, inOut = "open") {
    let querySelector = "[id='" + dialog.id.replace(/-dialog$/, "-" + inOut) + "']";
    if (dialog.getAttribute("data-step") !== undefined) {
        querySelector += "[data-step='" + dialog.getAttribute("data-step") + "']";
    }
    return document.querySelector(querySelector);
}

/**
 * @function dialogOpen
 * @param {Element} dialog
 * @summary This function is used to open the dialog element
 */
function dialogOpen(dialog) { dialog.showModal(); }

/**
 * @function dialogClose
 * @param {Element} dialog
 * @summary This function is used to close the dialog element
 */
function dialogClose(dialog) { dialog.close(); }
