const linkReg = new RegExp(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.\%]+$/);

const vfCheckPreStudy = (idVF) => {
    if (!$('#vehicule_fournisseur_input_vin_' + idVF).length) {
        return true; // Si on skip l'update
    }
    if (getQuickStudyStatus(idVF) == "vert" || getQuickStudyStatus(idVF) == "jaune" || getQuickStudyStatus(idVF) == "rouge") {
        return true;
    }
    return false;
}

const vfCheckLienAnnonceFournisseur = (value) => {
    if (value.length != 0) {
        if (!linkReg.test(value)) {
            return "Supplier link entered is not valid.<br/>";
        }
    }
    return "";
}

const vfCheckCo2 = (value) => {
    if (value.length == 0) {
        return "CO² emissions was not completed.<br/>";
    }
    return "";
}

const vfCheckImmatriculation = (value) => {
    if (value.length == 0) {
        return "Registration number was not completed.<br/>";
    }
    return "";
}

const vfCheckVin = (value) => {
    if (value.length == 0) {
        return "VIN was not completed.<br/>";
    }
    return "";
}

const vfCheckMarque = (value) => {
    if (value.length == 0) {
        return "Brand was not completed.<br/>";
    }
    return "";
}

const vfCheckModele = (value) => {
    if (value.length == 0) {
        return "Modele was not completed.<br/>";
    }
    return "";
}

const vfCheckVersion = (value) => {
    if (value.length == 0) {
        return "Version was not completed.<br/>";
    }
    return "";
}

const vfCheckBoite = (value) => {
    if (value.length == 0) {
        return "Gearbox was not completed.<br/>";
    }
    return "";
}

const vfCheckCarburant = (value) => {
    if (value.length == 0) {
        return "Energy was not completed.<br/>";
    }
    return "";
}

const vfCheckDateMec = (value) => {
    if (value.length == 0) {
        return "Registration date was not completed.<br/>";
    }
    return "";
}

const vfCheckYear = (value) => {
    if (value.length == 0) {
        return "Year was not completed.<br/>";
    }
    return "";
}

const vfCheckKilometrage = (value) => {
    if (value.length == 0) {
        return "Mileage was not completed.<br/>";
    }
    return "";
}

const vfCheckLienExpertise = (value) => {
    if (value.length != 0) {
        if (!linkReg.test(value)) {
            return "Expertise link entered is not valid.<br/>";
        }
    }
    return "";
}

const vfCheckCouleur = (value) => {
    if (value.length == 0) {
        return "Color was not completed.<br/>";
    }
    return "";
}

const vfCheckPuissanceDin = (value) => {
    if (value.length == 0) {
        return "DIN power was not completed.<br/>";
    }
    return "";
}

const vfCheckFournisseur = (value) => {
    if (value.length == 0) {
        return "Provider was not completed.<br/>";
    }
    return "";
}

const vfCheckOra7ProAvailability = (value1, value2) => {
    if (value1 == 1 && value2.length == 0) {
        return "ORA7 PRO availability end date was not completed.<br/>";
    }
    return "";
}

const vfPrixAchat = (value) => {
    if (value.length == 0 || value == 0) {
        return "Buying price doesn't be empty or equal to 0.<br/>";
    }
    return "";
}