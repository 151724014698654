$(document).ready(function() {
    $('body').on('change', "#generateur_token_email_input_file", function() {
        var files = $("#generateur_token_email_input_file")[0].files;
        if (files.length != 0) {
            $('#generateur_token_email_form_message').removeClass('d-none');
            $('.text-pas-de-generation').removeClass('d-none');
            $('.text-fichier-manquant').addClass('d-none');
        } else {
            $('#generateur_token_email_form_message').addClass('d-none');
        }
    });

    $('body').on('click', "#generateur_token_email_submit", function(event) {
        var files = $("#generateur_token_email_input_file")[0].files;
        if (files.length == 0) {
            $('.text-pas-de-generation').addClass('d-none');
            $('.text-fichier-manquant').removeClass('d-none');
            event.preventDefault();
        } else {
            $('.text-pas-de-generation').addClass('d-none');
            $('.text-attente').removeClass('d-none');
        }
    });
})
