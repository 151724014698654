/* admin/spider-annuaire */

// Chargement de la page, ajout des selects
$(document).ready(function () {
    if ($("#spider-annuaire-correspondance").length) {
        // Requète Ajax
        $.ajax({
            type: 'POST',
            url: siteURL + 'admin/spider-annuaire/selectAnnuaire',
            contentType: false,
            processData: false,
            success: function(data) {
                if (data) {
                    $("#spider-annuaire-list-annuaire-__id__").html(data);
                    $("#spider-annuaire-list-annuaire-__id__").selectpicker("refresh");
                    $(".loader-spinner").addClass("d-none");
                    $("[id^='btn-update-correspondance-annuaire-spider-']").removeClass("d-none");
                }
            }
        });
    }
});

// On copie/colle le select vierge pour remplacer le bouton de correspondance
$("[id^='btn-update-correspondance-annuaire-spider-']").on('click', function() {
    let idSpiderEnCours = this.id.replace('btn-update-correspondance-annuaire-spider-', '');

    const newSelectpicker = $("#spider-annuaire-list-annuaire-__id__").clone();
    newSelectpicker.attr("id", newSelectpicker[0].id.replaceAll("__id__", idSpiderEnCours));
    $('#spider-annuaire-liste-annuaire-' + idSpiderEnCours).html(newSelectpicker);
    newSelectpicker.removeClass("d-none");
    newSelectpicker.selectpicker();
    $(".bs-title-option").remove()
});

// Sauvegarde d'une correspondance idSpider-idAnnuaire
$('[id^="change_spider-annuaire_"]:not([id^="change_spider-annuaire_button"]):not([id^="change_spider-annuaire_current"])').on('click', function() {
    let clickedId = this.id.replace('change_spider-annuaire_', '');
    let selectedIdAnnuaire = $('#spider-annuaire-list-annuaire-'+clickedId+" option:selected").val();
    let dejaPris = $('#spider-annuaire-list-annuaire-'+clickedId+" option:selected").attr("data-dejapris");
    let currentIdSpider = $('#change_spider-annuaire_current_'+clickedId).html();

    // Si aucune couleur n'a été selectionnée
    if (selectedIdAnnuaire == "") {
        toastr.error('Merci de selectionner une correspondance  Spider-Annuaire', "Erreur");
        return false;
    }

    // Initialisation du Form à envoyer
    let form = new FormData();
    form.append('idSpider', currentIdSpider);
    form.append('idAnnuaire', selectedIdAnnuaire);
    form.append('dejaPris', dejaPris);

    // Requète Ajax
    $.ajax({
        type: 'POST',
        url: siteURL + 'admin/spider-annuaire/save',
        data: form,
        contentType: false,
        processData: false,
        success: function(data) {
            if (data == "ok") {
                toastr.success('La correspondance Spider-Annuaire a été modifiée', "Succès");
                $('#change_spider-annuaire_button_'+clickedId).addClass('bg-success').html('<i class="fas fa-check"></i>');
            }
            else {
                toastr.error('Une erreur est survenue', "Erreur");
            }
        }
    });
});