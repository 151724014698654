$(document).ready( function () {

    statimports_table = $('#statimports_table').DataTable( {
        "autoWidth": false,
        "language": {
            "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/fr-FR.json"
        },
        "responsive": true,
        "order": [[ 0, "desc" ]],
        "conditionalPaging": true,
    });
});