/**
 * Script JS pour la fonctionnalité colvis (column visibility)
 */

let colvisTable = null;
/**
 * Initialise la table concerné par la fonctionnalité sur la page
 * @param {DataTable} table
 */
function setColvisTable(table) {
    colvisTable = table;
}

/**
 * Lit chacune des colonnes et les compares à la config par défaut (toutes les colonnes s'il n'y a pas de config)
 * @param {String} colvisTableId
 * @returns {Array} Tableau des colonnes avec l'information de leur visibilité pour l'initialisation du tableau
 */
function getColvisColumn(colvisTableId) {
    let colvisColumn = [];
    let config = $('input[name="colvisConfigId"][value="' + $("#colvisConfigApply").data("id") + '"').data("col");
    config = (config == "" ? [] : config.split(","));
    let colvisTableColumns = $("#" + colvisTableId).find("th");
    for (let index = 0; index < colvisTableColumns.length; index++) {
        if (config.length) {
            const element = colvisTableColumns[index];
            if (element.getAttribute("data-colvis") != null) {
                colvisColumn[index] = { "visible": config.includes(element.getAttribute("data-colvis")) };
            } else {
                colvisColumn[index] = null;
            }
        } else {
            colvisColumn[index] = { "visible": true };
        }
    }
    return colvisColumn;
}

/**
 * Met à jour l'interface suivant la config sélectionné
 */
function colvisFunSelectConfig(e) {
    $("#colvisConfigLabel").val($(e.target).data("label"));
    $("#colvisConfigLabel").prop("disabled", false);
    $("#colvisConfigDefaultConfig").prop("checked", $(e.target).data("defaultconfig"));
    $("#colvisConfigDefaultConfig").prop("disabled", parseInt($(e.target).data("defaultconfig")));
    $("#colvisConfigDelete").prop("disabled", (parseInt($(e.target).data("defaultconfig")) || !parseInt($(e.target).val())));
    $("#colvisConfigApply").prop("disabled", !parseInt($(e.target).val()));
}

/**
 * Envoie la requête de suppression de config
 */
function colvisFunDelete() {
    if ($('input[name="colvisConfigId"]:checked').length) {
        let formData = new FormData();
        formData.append('id', $('input[name="colvisConfigId"]:checked').val());
        formData.append('page', $("#colvisConfigPage").val());
        $.ajax({
            type:'POST',
            url: siteURL + 'admin/colvis/delete',
            contentType: false,
            processData: false,
            data: formData,
            success: function(modalContent) {
                toastr.success('Suppression de la configuration réussi', 'Succès');
                $('#colvisConfigModalContent').html(modalContent);
                if ($("#colvisConfigApply").data("id") == formData.get("id")) {
                    colvisFunSelectDefault();
                }
            },
            error: function() { toastr.error('Erreur lors de la suppression', 'Erreur'); }
        });
    }
}

/**
 * Envoie la requête de d'enregistrement de config
 */
function colvisFunSave() {
    if ($('input[name="colvisConfigId"]:checked').length && colvisTable != null) {
        let formData = new FormData();
        formData.append('id', $('input[name="colvisConfigId"]:checked').val());
        formData.append('label', $("#colvisConfigLabel").val());
        formData.append('page', $("#colvisConfigPage").val());
        formData.append('defaultConfig', $("#colvisConfigDefaultConfig").prop("checked"));
        let colvisList = [];
        for (const el of $("#"+colvisTable.table().node().id).find("th[data-colvis]")) { colvisList.push($(el).data("colvis")); }
        formData.append('col', colvisList);
        $.ajax({
            type:'POST',
            url: siteURL + 'admin/colvis/modify',
            contentType: false,
            processData: false,
            data: formData,
            success: function(modalContent) {
                toastr.success('Enregistrement de la configuration réussi', 'Succès');
                $('#colvisConfigModalContent').html(modalContent);
                $('input[name="colvisConfigId"][value="'+formData.get("id")+'"').trigger("click");
            },
            error: function() { toastr.error('Erreur lors de l\'enregistrement', 'Erreur'); }
        });
    }
}

/**
 * Applique une configuration sur le tableau
 */
function colvisFunApply() {
    $("#colvisConfigModalOverlay").removeClass("d-none");
    if ($('input[name="colvisConfigId"]:checked').length && colvisTable != null) {
        $("#colvisConfigApply").data("id", $('input[name="colvisConfigId"]:checked').val());
        let config = $('input[name="colvisConfigId"]:checked').data("col");
        config = (config == "" ? [] : config.split(","));
        if (config.length) {
            for (let index = 0; index < colvisTable.columns()[0].length; index++) {
                const element = colvisTable.column(index);
                if (element.header().getAttribute("data-colvis") != null) {
                    element.visible(config.includes(element.header().getAttribute("data-colvis")));
                }
            }
        }
    }
    $("#colvisConfigModalOverlay").addClass("d-none");
    $('#colvisConfigModal').modal('hide');
}

/**
 * Sélectionne la config par défaut
 */
function colvisFunSelectDefault() {
    for (const el of $("[name='colvisConfigId']")) { if (parseInt($(el).data("defaultconfig"))) { $("#colvisConfigApply").data("id", $(el).val()); } }
}

/**
 * Met à jour le bouton radio sélectionne en fonction de la config
 */
function colvisFunSelectRefresh() {
    $('input[name="colvisConfigId"][value="'+$("#colvisConfigApply").data("id")+'"').prop("checked", true);
    colvisFunSelectConfig({target: $('input[name="colvisConfigId"][value="'+$("#colvisConfigApply").data("id")+'"')[0]});
}

/**
 * Désactive tous les évènements
 */
function colvisFunOffEvent() {
    $("body").off("change", "[name='colvisConfigId']", colvisFunSelectConfig);
    $("body").off("click", "#colvisConfigDelete", colvisFunDelete);
    $("body").off("click", "#colvisConfigSave", colvisFunSave);
    $("body").off("click", "#colvisConfigApply", colvisFunApply);
}

/**
 * Active tous les évènements
 */
function colvisFunOnEvent() {
    $("body").on("change", "[name='colvisConfigId']", colvisFunSelectConfig);
    $("body").on("click", "#colvisConfigDelete", colvisFunDelete);
    $("body").on("click", "#colvisConfigSave", colvisFunSave);
    $("body").on("click", "#colvisConfigApply", colvisFunApply);
    colvisFunSelectDefault();
}
