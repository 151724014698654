$(document).ready(function () {
    if ($("#stock_vehicule_page").length) {
        $('body').on('click', "#freFrais-total-update", function (e) { loadVehiculeFraisTotal(); });

        $('body').on('change', "[name^='stock_vehicule-frais-input-']", function (e) {
            e.preventDefault();
            let id = this.getAttribute("data-id");
            let input = this.getAttribute("name").replace("stock_vehicule-frais-input-", "");
            let value = $(this).val();
            let form = new FormData();
            form.append('freFrais_id', id);
            form.append('freFrais_vehicule', $("#stock_vehicule_page").data("id"));
            form.append('input', input);
            form.append('value', value);
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/fre/freFrais/modify',
                contentType: false,
                processData: false,
                data: form,
                success: function (data) {
                    toastr.success('La modification a bien été enregistrée', 'Succès');
                    loadVehiculeFraisTotal();
                    updateFraisStatut(id);
                },
                error: function () {
                    toastr.error('La modification a echoué', 'Erreur');
                    loadVehiculeFrais();
                },
            });
        });

        $('body').on('click', "[id^='freFrais-modify-']", function (e) {
            $("#freFrais_modify").data("expertise", $(this).data("expertise"));
            $('html,body').animate({ scrollTop: $('#freFrais_modify').offset().top - 100 }, 'slow');
            expertiseFraisShow($("#freFrais_modify"), this.id.replace("freFrais-modify-", ""));
            expertiseDisplayUpdate($("#freFrais_modify"));
        });
        $('body').on('click', "#freFrais-cancel", function (e) { expertiseFraisCancel($("#freFrais_modify")); expertiseDisplayUpdate($("#freFrais_modify")); });

        lobibox_forfait = null;
        $('body').on('click', "#freFrais-forfait", function (e) {
            lobibox_forfait = Lobibox.window({
                width: 1200,
                title: 'Ajouter des forfaits de FRE',
                baseClass: "modal-content modal-lobistrap",
                url: siteURL + 'admin/fre/freFrais/forfaitList',
                showAfterLoad: true,
            });
        });

        $('body').on('click', "#freFrais-forfait-add", function (e) {
            let listId = getSelectedRows("freFrais-forfait-");
            let form = new FormData();
            form.append('freFrais_vehicule', $("#stock_vehicule_page").data("id"));
            form.append('freFrais_listForfait', listId);
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/fre/freFrais/forfaitAdd',
                contentType: false,
                processData: false,
                data: form,
                success: function (data) {
                    lobibox_forfait.destroy();
                    toastr.success('Le' + (listId.length > 1 ? "s" : "") + ' forfait' + (listId.length > 1 ? "s" : "") + ' ' + (listId.length > 1 ? "ont" : "a") + ' bien été enregistrée' + (listId.length > 1 ? "s" : ""), 'Succès');
                    loadVehiculeFrais();
                },
                error: function () {
                    toastr.error('Une erreur s\'est produite.', 'Erreur');
                }
            });
        });

        lobibox_photos = null;

        $('body').on('click', "[id^='freFrais-photos-']", function (e) {
            expertiseFraisCancel($("#freFrais_modify"));
            expertiseDisplayUpdate($("#freFrais_modify"));
            lobibox_photos = Lobibox.window({
                width: 1200,
                title: 'Ajouter des photos de FRE',
                baseClass: "modal-content modal-lobistrap",
                url: siteURL + 'admin/fre/freFrais/fraisPhotos',
                params: { "id": this.id.replace("freFrais-photos-", "") },
                showAfterLoad: true,
            });
        });

        $('body').on('drop', "#freFrais-photoLabel", function (evt) {
            // Prevent default behavior (Prevent file from being opened)
            evt.preventDefault();
            if (evt.dataTransfer === undefined) {
                evt.dataTransfer = evt.originalEvent.dataTransfer;
            }
            if (evt.dataTransfer.items) {
                // Use DataTransferItemList interface to access the file(s)
                [...evt.dataTransfer.items].forEach((item, i) => {
                    // If dropped items aren't files, reject them
                    if (item.kind === "file") {
                        const file = item.getAsFile();
                        sendPhoto(file);
                    }
                });
            } else {
                // Use DataTransfer interface to access the file(s)
                [...evt.dataTransfer.files].forEach((file, i) => {
                    sendPhoto(file);
                });
            }
        });
        $('body').on('dragover', "#freFrais-photoLabel", function (evt) { evt.preventDefault(); })

        $('body').on('change', "#freFrais-photo", function (e) {
            const files = this.files;
            if (FileReader && files && files.length) {
                Array.from(files).forEach(file => {
                    sendPhoto(file);
                });
            }
        });

        function sendPhoto(file) {
            var fr = new FileReader();
            fr.onload = function () {
                file = new File([file], file.name.replace(new RegExp("(.+)[.](.+[^.])", "g"), "$1").slugify() + "." + file.name.replace(new RegExp("(.+)[.](.+[^.])", "g"), "$2").slugify(), { type: file.type });
                var form = new FormData();
                form.append('data', file);
                form.append('frais_id', $("#freFrais-photo").data("frais"));
                $.ajax({
                    type: 'POST',
                    url: siteURL + 'admin/fre/freFrais/fraisPhotoAdd',
                    contentType: false,
                    processData: false,
                    data: form,
                    success: function (data) {
                        toastr.success('Le fichier bien été enregistrée', 'Succès');
                        $("#freFrais-photos").append("<div id='freFrais-photo-" + data.id + "' class='select-none'><div id='freFrais-photoRemove-" + data.id + "'>×</div><img src='" + siteURL + data.path + data.filename + "' /></div>");
                        loadVehiculeFrais();
                    },
                    error: function () {
                        toastr.error('Une erreur s\'est produite.', 'Erreur');
                    }
                });
            }
            fr.readAsDataURL(file);
        }

        $('body').on('click', "[id^='freFrais-photoRemove-']", function (e) {
            var form = new FormData();
            form.append('id', this.id.replace("freFrais-photoRemove-", ""));
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/fre/freFrais/fraisPhotoDelete',
                contentType: false,
                processData: false,
                data: form,
                success: function (data) {
                    toastr.success('Le fichier bien été supprimé', 'Succès');
                    $("#freFrais-photo-" + form.get('id')).remove();
                    loadVehiculeFrais();
                },
                error: function () {
                    toastr.error('Une erreur s\'est produite.', 'Erreur');
                }
            });
        });

        $('body').on('click', "[id^='freFrais-toDo-']", function (e) {
            const idFrais = this.id.replace("freFrais-toDo-", "");
            const value = $(this).is(":checked");
            let optionsRecherche = new FormData();
            optionsRecherche.append('frais_id', idFrais);
            optionsRecherche.append('frais_toDo', (value ? "1" : "0"));
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/fre/freFrais/toDo',
                contentType: false,
                processData: false,
                data: optionsRecherche,
                success: function () {
                    expertiseFraisCancel($("#freFrais_modify"));
                    expertiseDisplayUpdate($("#freFrais_modify"));
                    const frais = $("#freFrais-modify-"+idFrais).data("expertise");
                    frais[0].toDo = value;
                    $("#freFrais-modify-"+idFrais).data("expertise", frais);
                    if (value) {
                        $("#freFrais-toDoLabel-" + idFrais).html("OUI");
                        toastr.success('Le frais est à faire', 'Succès');
                    } else {
                        $("#freFrais-toDoLabel-" + idFrais).html("POUR MÉMOIRE");
                        toastr.success('Le frais n\'est pas à faire', 'Succès');
                    }
                    updateFraisStatut(idFrais);
                    loadVehiculeFraisTotal();
                }
            });
        });

        $('body').on('click', "[id^='freFrais-isDone-']", function (e) {
            const idFrais = this.id.replace("freFrais-isDone-", "");
            const isDone = $("#freFrais-statut-" + idFrais).data("isdone") ? 0 : 1;
            const clickedEl = $(this);
            let optionsRecherche = new FormData();
            optionsRecherche.append('frais_id', idFrais);
            optionsRecherche.append('frais_isDone', isDone);
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/fre/freFrais/isDone',
                contentType: false,
                processData: false,
                data: optionsRecherche,
                success: function () { updateFraisStatut(idFrais); }
            });
        });

        $('body').on('click', "[id^='freFrais-neutralize-']", function (e) {
            const idFrais = this.id.replace("freFrais-neutralize-", "");
            const neutralize = $("#freFrais-statut-" + idFrais).data("neutralize") ? 0 : 1;
            const clickedEl = $(this);
            let optionsRecherche = new FormData();
            optionsRecherche.append('id', $("#stock_vehicule_page").data("id"));
            optionsRecherche.append('frais_id', idFrais);
            optionsRecherche.append('frais_neutralize', neutralize);
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/fre/freFrais/neutralize',
                contentType: false,
                processData: false,
                data: optionsRecherche,
                success: function () { updateFraisStatut(idFrais); }
            });
        });

        function updateFraisStatut(idFrais) {
            let optionsRecherche = new FormData();
            optionsRecherche.append('id', $("#stock_vehicule_page").data("id"));
            optionsRecherche.append('frais_id', idFrais);
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/fre/freFrais/updateRow',
                contentType: false,
                processData: false,
                data: optionsRecherche,
                success: function (data) {
                    $("#stock_vehicule-frais-tr-" + idFrais).replaceWith(data);
                    loadVehiculeFraisTotal();
                    $(".selectpicker").selectpicker("refresh");
                }
            });
        }
    }
});

function loadVehiculeFrais() {
    let optionsRecherche = new FormData();
    optionsRecherche.append('id', $("#stock_vehicule_page").data("id"));
    $("#freFrais_results").addClass("d-none");
    $.ajax({
        type: 'POST',
        url: siteURL + 'admin/fre/freFrais/ajax',
        contentType: false,
        processData: false,
        data: optionsRecherche,
        success: function (data) {
            $("#freFrais_results").html(data);
            loadVehiculeFraisTotal();
            $(".selectpicker").selectpicker("refresh");
            $("#freFrais_results").removeClass("d-none");
        }
    });
}

function loadVehiculeFraisTotal() {
    let optionsRecherche = new FormData();
    optionsRecherche.append('id', $("#stock_vehicule_page").data("id"));
    $.ajax({
        type: 'POST',
        url: siteURL + 'admin/fre/freFrais/ajax_total',
        contentType: false,
        processData: false,
        data: optionsRecherche,
        success: function (data) {
            $("#freFrais_total").html(data.total);
            if (data.stats != null) {
                data = data.stats;
                // Liste les prefix d'id pour les construire dynamiquement
                let allId = ["type", "montantcategorie", "completudefacture", "depensesparpresta"];
                // Initialise les Canvas
                let DonutChartCanvas = [];
                let BarChartCanvas = [];
                // Options générique
                let donutOptions = {
                    maintainAspectRatio: false,
                    responsive: true,
                    tooltips: {
                        callbacks: {
                            label: function (tooltipItem, data) {
                                var dataset = data.datasets[tooltipItem.datasetIndex];
                                var currentValue = dataset.data[tooltipItem.index];
                                return data.labels[tooltipItem.index] + ": " + currentValue + "%";
                            }
                        }
                    }
                }
                let barOptions = {
                    maintainAspectRatio: false,
                    responsive: true,
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true
                            }
                        }]
                    },
                    legend: {
                        display: false
                    },
                    tooltips: {
                        callbacks: {
                            label: function (tooltipItem, data) {
                                var dataset = data.datasets[tooltipItem.datasetIndex];
                                var currentValue = dataset.data[tooltipItem.index];
                                return data.labels[tooltipItem.index] + ": " + currentValue;
                            }
                        }
                    }
                }
                // Créer les donuts
                let colorSet = ['#f56954', '#00a65a', '#f39c12', '#00c0ef', '#3c8dbc', '#d2d6de', '#dd8888', '#88dddd', '#88dd88', '#dd88dd', '#dddd88', '#8888dd', '#b3ff66'];

                let dataValue = null;
                let dataValueTotal = null;
                for (let index = 0; index < allId.length; index++) {
                    DonutChartCanvas[allId[index]] = document.getElementById(allId[index] + 'DonutChart').getContext('2d');
                    dataValue = Object.keys(data[allId[index]]).map(function ($k) { return data[allId[index]][$k]; });
                    dataValueTotal = dataValue.reduce((a, c) => a + c, 0);
                    new Chart(DonutChartCanvas[allId[index]], {
                        type: 'doughnut',
                        data: { labels: Object.keys(data[allId[index]]), datasets: [{ data: dataValue.map(el => Math.round((el / dataValueTotal) * 100)), backgroundColor: colorSet }] },
                        options: donutOptions
                    });
                }

                for (let index = 0; index < allId.length; index++) {
                    BarChartCanvas[allId[index]] = document.getElementById(allId[index] + 'BarChart').getContext('2d');
                    dataValue = Object.keys(data[allId[index]]).map(function ($k) { return data[allId[index]][$k]; });
                    new Chart(BarChartCanvas[allId[index]], {
                        type: 'bar',
                        data: { labels: Object.keys(data[allId[index]]), datasets: [{ data: dataValue }] },
                        options: barOptions
                    });
                }
            }
        }
    });
}
