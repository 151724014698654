// Génération de la datatable des prospects
motorisation_table = $('#motorisation_table').DataTable({
    "autoWidth": false,
    "language": {
        "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/fr-FR.json"
    },
    "responsive": true,
    "ajax": {
        "url": siteURL + 'json/motorisations',
        "type": "POST",
    },
    "order": [
        [0, "desc"]
    ],
    "columnDefs": [
        // TEXT CENTER
        {
            "className": "text-center align-middle",
            "targets": [3,4],
        },
        // ALIGN MIDDLE
        {
            "className": "align-middle",
            "targets": '_all',
        },
        // ID
        {
            "targets": 0,
            "render": function(data, type, row) {
                return row[0];
            },
        },
        // NAME
        {
            "targets": 1,
            "render": function(data, type, row) {
                return row[1];
            },
        },
        // NAME REWRITE
        {
            "targets": 2,
            "render": function(data, type, row) {
                return row[2];
            },
        },
        // VISIBLE
        {
            "targets": 3,
            "render": function(data, type, row) {
                if (row[3] == 1) {
                    return '<i class="fas fa-check" style="color:green" title="Validé"></i><span class="d-inline d-lg-none pl-2">OUI</span>';
                } else {
                    return '<i class="fas fa-times" style="color:red" title="Non Validé"></i><span class="d-inline d-lg-none pl-2">NON</span>';
                }
            },
        },
        // MODIFY
        {
            "width": "50px",
            "targets": 4,
            "render": function ( data, type, row ) {
                return '<button class="btn w-100 p-0" id="motorisation-open-modify-'+row[0]+'" data-category="vehicules"><i class="fad fa-edit"></i>';
            },
            "orderable": false,
        },
    ],
    "conditionalPaging": true,
});


// Envoi de modification d'une motorisation
$('body').on('click', "button[id^='motorisation-send-modify-']", function() {
    var id_clicked = this.id.replace('motorisation-send-modify-', '');

    var form = new FormData();
    // On extrait le contenu du formulaire dans la variable 'form'
    form.append('id', id_clicked);
    form.append('motorisation_name', $('#motorisation_name_'+id_clicked).val());
    form.append('motorisation_rewrite', $('#motorisation_rewrite_'+id_clicked).val());

    // Verifie la longeur du texte
    if ($("#motorisation_name_"+id_clicked).val().length == 0) {
        toastr.error('Merci de remplir le nom', 'Erreur');
        return false;
    }
    if ($("#motorisation_rewrite_"+id_clicked).val().length == 0) {
        toastr.error('Merci de remplir le nom rewrite', 'Erreur');
        return false;
    }

    // SEO
    form.append('motorisation_title', $('#motorisation_title_'+id_clicked).val());
    form.append('motorisation_balise_h1', $('#motorisation_balise_h1_'+id_clicked).val());

    if (!IE11) {
        form.append('motorisation_meta_description', $('#motorisation_meta_description_'+id_clicked).next().find('.ck-editor__editable').text());
        form.append('motorisation_tagline_1', $('#motorisation_tagline_1_'+id_clicked).next().find('.ck-editor__editable').html().replace(/(<p[^>]+?>|<p>|<\/p>)/img, ""));
        form.append('motorisation_tagline_2', $('#motorisation_tagline_2_'+id_clicked).next().find('.ck-editor__editable').html().replace(/(<p[^>]+?>|<p>|<\/p>)/img, ""));
        form.append('motorisation_description_courte', $('#motorisation_description_courte_'+id_clicked).next().find('.ck-editor__editable').html());

        form.append('motorisation_contenu_1', $('#motorisation_contenu_1_'+id_clicked).next().find('.ck-editor__editable').html());
    }
    else {
        form.append('motorisation_meta_description', $('#motorisation_meta_description_'+id_clicked).val());
        form.append('motorisation_tagline_1', $('#motorisation_tagline_1_'+id_clicked).val().replace(/(<p[^>]+?>|<p>|<\/p>)/img, ""));
        form.append('motorisation_tagline_2', $('#motorisation_tagline_2_'+id_clicked).val().replace(/(<p[^>]+?>|<p>|<\/p>)/img, ""));
        form.append('motorisation_description_courte', $('#motorisation_description_courte_'+id_clicked).val());

        form.append('motorisation_contenu_1', $('#motorisation_contenu_1_'+id_clicked).val());
    }

    form.append('motorisation_is_visible', $('input[name="motorisation_visible_' + id_clicked + '"]:checked').val());

    // Verifie la longeur du texte
    if ($("#motorisation_title_"+id_clicked).val().length > 200) {
        toastr.error('Merci de reduire le title', 'Erreur');
        return false;
    }
    // Verifie la longeur du texte
    if ($("#motorisation_meta_description_"+id_clicked).val().length > 249) {
        toastr.error('Merci de reduire la meta description', 'Erreur');
        return false;
    }
    // Verifie la longeur du texte
    if ($("#motorisation_balise_h1_"+id_clicked).val().length > 200) {
        toastr.error('Merci de reduire la balise h1', 'Erreur');
        return false;
    }


    $.ajax({
        type: 'POST',
        url: siteURL + 'admin/vehicules/motorisation/modify',
        cache: false,
        contentType: false,
        processData: false,
        data: form,
        success: function(data) {
            // TOAST
            toastr.success('La motorisation a bien été enregistrée', 'Succès');
            // On nettoye le html en supprimant l'onglet 'Modifier/Nouveau'
            $('#motorisation-tabs-modify-'+id_clicked).remove();
            $('#motorisation-close-modify-'+id_clicked).parent().remove();
            if (id_clicked == 0) {
                // On réinitialise le formulaire
                $('#formmotorisation_'+id_clicked)[0].reset();
                $('#motorisation_contenu_3_0').summernote('reset');

                $('#motorisation_text_title_0').text("0/63 caractère");
                $('#motorisation_text_title_0').css('color', 'black');
                $('#motorisation_text_meta_description_0').text("0/153 caractère");
                $('#motorisation_text_meta_description_0').css('color', 'black');
                $('#motorisation_text_balise_h1_0').text("0/60 caractère");
                $('#motorisation_text_balise_h1_0').css('color', 'black');
                for (i = 0; i <= 2; i++) {
                    $('#motorisation_text_tagline_'+i+'_0').text("0/100 caractère");
                    $('#motorisation_text_tagline_'+i+'_0').css('color', 'black');
                }
                $('#motorisation_text_description_courte_0').text("0/400 caractère");
                $('#motorisation_text_description_courte_0').css('color', 'black');
            }
            // Retour à la liste d'actualités en rechargeant la datatable
            $('#motorisation-tabs-list-tab').trigger('click');
            modele_table.ajax.reload();
        }
    });
     // Empèche le 'button' de fonctionner
    return false;
});