let timerBeforeRefreshDataMarge = null;

$(document).ready(function () {
    if ($("#comptabilite-marge-results").length) {
        colvisFunOnEvent();
        // Chargement du tableau de marge
        function loadAjax() {
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/comptabilite/marge/loadAjax',
                contentType: false,
                processData: false,
                data: getParams(),
                success: function (data) {
                    $("#comptabilite-marge-results").html(data);
                }
            })
        };

        setupLoadAjax(loadAjax);
        setupResetFilter(resetFilter);

        // Récupération des paramètres des requêtes suivant les filtres
        function getParams() {
            var optionsRecherche = new FormData();
            optionsRecherche.append('annee', $("#filtersmarge-annee").val());
            optionsRecherche.append('emplacement', $("#filtersmarge-emplacement").val());
            optionsRecherche.append('vendeur', $("#filtersmarge-vendeur").val());
            optionsRecherche.append('prix', $("#filtersmarge-prix").val());
            optionsRecherche.append('type', $("#filtersmarge-type").val());
            return optionsRecherche;
        }

        // Requête pour tout type d'export de donné de marge
        $('body').on('click', "[id^='comptabilite-marge-export-']", function (e) {
            const exportType = e.target.id.replace("comptabilite-marge-export-", "");
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/comptabilite/marge/export/' + exportType,
                contentType: false,
                processData: false,
                data: getParams(),
                success: function (data) {
                    toastr.success('Le fichier ' + exportType + ' est fini', 'Succès');
                    var element = document.createElement('a');
                    element.setAttribute('href', siteURL + data);
                    element.setAttribute('download', "Export " + (new Date()).toLocaleDateString("fr") + "." + (exportType == "excel" ? "xlsx" : exportType));
                    document.body.appendChild(element);
                    element.click();
                    document.body.removeChild(element);
                },
                error: function () {
                    toastr.error("Erreur lors de l'export", "Erreur");
                }
            })
        });

        $('body').on('change', "[id^='filtersmarge-']", function () { clearTimeout(timerBeforeRefreshDataMarge); timerBeforeRefreshDataMarge = setTimeout(loadAjax, 500); });
        // Bouton reset
        $('body').on('click', '#filtersmarge-reset', function () {
            resetFilter();
            loadAjax();
        });

        // Fonction de reset des filtres
        function resetFilter() {
            $("#filtersmarge-annee").val($("#filtersmarge-annee")[0][0].value);
            $("#filtersmarge-emplacement").val('default').selectpicker("refresh");
            $("#filtersmarge-vendeur").val('default').selectpicker("refresh");
            $("#filtersmarge-prix").val('ht');
            $("#filtersmarge-type").val('total');
        }

        filtreConfigFunOnEvent();
    }
});
