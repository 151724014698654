let timerBeforeRefreshDataFmr = null;

$(document).ready(function () {
    if ($("#comptabilite-fmr-results").length) {
        colvisFunOnEvent();
        // Chargement du tableau de fmr
        function loadAjax() {
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/comptabilite/fmr/loadAjax',
                contentType: false,
                processData: false,
                data: getParams(),
                success: function (data) {
                    $("#comptabilite-fmr-results").html(data);
                }
            })
        };

        setupLoadAjax(loadAjax);
        setupResetFilter(resetFilter);

        // Récupération des paramètres des requêtes suivant les filtres
        function getParams() {
            var optionsRecherche = new FormData();
            optionsRecherche.append('annee', $("#filtersfmr-annee").val());
            optionsRecherche.append('affichage', $("#filtersfmr-affichage").val());
            optionsRecherche.append('emplacement', $("#filtersfmr-emplacement").val());
            optionsRecherche.append('vendeur', $("#filtersfmr-vendeur").val());
            optionsRecherche.append('prix', $("#filtersfmr-prix").val());
            optionsRecherche.append('type', $("#filtersfmr-type").val());
            return optionsRecherche;
        }

        // Requête pour tout type d'export de donné de fmr
        $('body').on('click', "[id^='comptabilite-fmr-export-']", function (e) {
            const exportType = e.target.id.replace("comptabilite-fmr-export-", "");
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/comptabilite/fmr/export/' + exportType,
                contentType: false,
                processData: false,
                data: getParams(),
                success: function (data) {
                    toastr.success('Le fichier ' + exportType + ' est fini', 'Succès');
                    var element = document.createElement('a');
                    element.setAttribute('href', siteURL + data);
                    element.setAttribute('download', "Export " + (new Date()).toLocaleDateString("fr") + "." + (exportType == "excel" ? "xlsx" : exportType));
                    document.body.appendChild(element);
                    element.click();
                    document.body.removeChild(element);
                },
                error: function () {
                    toastr.error("Erreur lors de l'export", "Erreur");
                }
            })
        });

        $('body').on('change', "[id^='filtersfmr-']", function () { clearTimeout(timerBeforeRefreshDataFmr); timerBeforeRefreshDataFmr = setTimeout(loadAjax, 500); });
        // Bouton reset
        $('body').on('click', '#filtersfmr-reset', function () {
            resetFilter();
            loadAjax();
        });

        // Fonction de reset des filtres
        function resetFilter() {
            $("#filtersfmr-annee").val($("#filtersfmr-annee")[0][0].value);
            $("#filtersfmr-affichage").val('marge');
            $("#filtersfmr-emplacement").val('default').selectpicker("refresh");
            $("#filtersfmr-vendeur").val('default').selectpicker("refresh");
            $("#filtersfmr-prix").val('ht');
            $("#filtersfmr-type").val('total');
        }

        filtreConfigFunOnEvent();
    }
});
