function expertiseChannelNext(step, channel = "next") {
    channelLoader(step, "expertise", "on");
    var form = new FormData();
    form.append("id", $("#stock_vehicule_page").data("id"));
    form.append("channel", "expertise-" + channel);
    form.append("step", step);
    $.ajax({
        type: "POST",
        url: siteURL + "admin/stock/update/channel",
        contentType: false,
        processData: false,
        data: form,
        success: function (data) {
            if (data.success) {
                toastr.success("Enregistrement réussi.", "Succès");
                $("#stock_vehicule-expertise-container").html(data.container);
                channelLoader(step, "expertise", "off");
                channelNext(step, "expertise");
                if (channel == "end") {
                    $("#stock_vehicule-tab-expertise").trigger("click");
                    $('#stock_vehicule-tab-expertise .stock_vehicule-statut-onglet').removeClass('bg-danger').removeClass('bg-warning').addClass('bg-success');
                    $('#stock_vehicule-tab-expertise .stock_vehicule-statut-onglet').html('<i class="fas fa-check"></i>');
                    // Déblocage de l'onglet livraison
                    $('#stock_vehicule-tab-livraison').attr("class", $("#stock_vehicule-tab-general").attr("class"));
                    $('#stock_vehicule-tab-livraison').prop("disabled", false);
                    //! Déblocage de l'onglet préparation
                    // // Mise à jour de l'onglet préparation
                    // $('#stock_vehicule-tab-frais').attr("class", $("#stock_vehicule-tab-general").attr("class"));
                    // $('#stock_vehicule-tab-frais').prop("disabled", false);
                } else {
                    $('#stock_vehicule-tab-expertise .stock_vehicule-statut-onglet').removeClass('bg-danger').removeClass('bg-success').addClass('bg-warning');
                    $('#stock_vehicule-tab-expertise .stock_vehicule-statut-onglet').html('<i class="fas fa-ellipsis-h"></i>');
                }
            }
        },
        error: function () {
            toastr.error("Une erreur s'est produite.", "Erreur");
        }
    });
}

function checkExpertiseStep1() {
    const dialog = $("dialog[name='expertise-dialog'][channel-dialog][data-name='expertise'][data-step='1']");
    const checkStep = dialog.find("#expertise-channel-dialog-check-step-1");
    let error = "";
    if (dialog.find("#stock_vehicule-revision-duree").val() == "") { error += "La durée avant révision n'a pas été complétée.<br>"; }
    if (dialog.find("#stock_vehicule-revision-distance").val() == "") { error += "La distance avant révision n'a pas été complétée.<br>"; }
    if (dialog.find("#stock_vehicule-expertise-affichage-dernier-ct").val() == "on" && dialog.find("#stock_vehicule-date-dernier-controle-technique").val() == "") { error += "La date du dernier contrôle technique n'a pas été complétée.<br>"; }
    if (dialog.find("#stock_vehicule-type-pneu-av").val() == "") { error += "Le type de pneu avant n'a pas été complété.<br>"; }
    if (dialog.find("#stock_vehicule-type-pneu-ar").val() == "") { error += "Le type de pneu arrière n'a pas été complété.<br>"; }
    if (dialog.find("#stock_vehicule-diametre-pneu-av").val() == "") { error += "Le diamètre de pneu avant n'a pas été complété.<br>"; }
    if (dialog.find("#stock_vehicule-diametre-pneu-ar").val() == "") { error += "Le diamètre de pneu arrière n'a pas été complété.<br>"; }
    if (error.length) {
        dialogToastrError(dialog, "expertise", error, "Erreur");
        if (checkStep.val() == "on") { checkStep.val("off"); checkStep.prop('checked', false); }
    }
}

$('body').on('change', "[id^='stock_vehicule-dashboard-photo-']", function () {
    const resultEl = document.querySelector("#file_result_" + this.id);
    onValueChange(resultEl, (observer) => {
        const val = resultEl.value;
        expertisePhoto("dashboard", val, "add");
        observer.disconnect();
    });
});

$('body').on('click', "[id^='expertise-dashboard-'][id$='-delete']", function () {
    const val = this.id.replace("expertise-dashboard-", "").replace("-delete", "");
    expertisePhoto("dashboard", val, "del");
});

$('body').on('change', "[id^='stock_vehicule-pneu-photo-']", function () {
    const resultEl = document.querySelector("#file_result_" + this.id);
    onValueChange(resultEl, (observer) => {
        const val = resultEl.value;
        expertisePhoto("pneu", val, "add");
        observer.disconnect();
    });
});

$('body').on('click', "[id^='expertise-pneu-'][id$='-delete']", function () {
    const val = this.id.replace("expertise-pneu-", "").replace("-delete", "");
    expertisePhoto("pneu", val, "del");
});

$('body').on('change', "[id^='expertise-controle-technique-photo-']", function () {
    const resultEl = document.querySelector("#file_result_" + this.id);
    onValueChange(resultEl, (observer) => {
        const val = resultEl.value;
        expertisePhoto("controle-technique", val, "add");
        observer.disconnect();
    });
});

$('body').on('click', "[id^='expertise-controle-technique-'][id$='-delete']", function () {
    const val = this.id.replace("expertise-controle-technique-", "").replace("-delete", "");
    expertisePhoto("controle-technique", val, "del");
});

function expertisePhoto(type, val, act) {
    var form = new FormData();
    form.append("id", $("#stock_vehicule_page").data("id"));
    form.append("val", val);
    form.append("act", act);
    $.ajax({
        type: "POST",
        url: siteURL + "admin/stock/update/" + type + "-photo",
        contentType: false,
        processData: false,
        data: form,
        success: function (data) {
            if (data.success) {
                const refEl = document.getElementById("expertise-" + type + "-__id__-item");
                const listEl = $("dialog #expertise-" + type + "-list")[0];
                const dialog = $($(refEl).parents("dialog")[0]);
                if (act == "add") {
                    dialogToastrSuccess(dialog, "expertise", 'Enregistrement réussi');
                    const newEl = refEl.cloneNode(true);
                    newEl.id = newEl.id.replace("__id__", val);
                    newEl.innerHTML = newEl.innerHTML.replace("__id__", val);
                    newEl.innerHTML = newEl.innerHTML.replace("__file__", data.path + data.filename);
                    newEl.firstElementChild.setAttribute("custom-fancy", "expertise-" + type + "-item");
                    if (listEl.childElementCount > 0) { listEl.firstElementChild.before(newEl); } else { listEl.appendChild(newEl); }
                } else {
                    dialogToastrSuccess(dialog, "expertise", 'Suppression réussi');
                    $("[id='expertise-" + type + "-" + val + "-item']").remove();
                }
                // Update de la liste hors dialog
                const viewEl = $("#stock_vehicule-expertise-container #expertise-" + type + "-list")[0];
                const newListEl = listEl.cloneNode(true);
                newListEl.innerHTML = newListEl.innerHTML.replaceAll(/<i\s.+i>/g, "");
                viewEl.replaceWith(newListEl);
            } else {
                dialogToastrError(dialog, "expertise", 'Une erreur s\'est produite.');
            }
        },
        error: function () {
            dialogToastrError(dialog, "expertise", 'Une erreur s\'est produite.');
        }
    });
}

$('body').on('change', "[id^='expertise-photo-']", function () { expertiseFileUpload(this); });

function expertiseFileUpload(input, fileUploadResult = null) {
    const jInput = $(input);
    const displayArea = $("#" + input.id + "-display");
    const resultArea = $("#" + input.id + "-result");
    const expertiseFileUploadResult = fileUploadResult ?? function (data) { expertiseFileUploadDisplay(displayArea, data); resultArea.val(data.id); };
    const file = jInput.data("rename") === undefined ? input.files[0] : new File([input.files[0]], jInput.data("rename") + "." + input.files[0].type.split("/")[1], { type: input.files[0].type });
    const fr = new FileReader();
    fr.onload = function (e) {
        const img = new Image();
        img.onload = function () {
            // Créer un canvas pour redimensionner l'image
            const canvas = document.createElement('canvas');
            // Définir les nouvelles dimensions
            canvas.width = (img.width >= 3000 ? img.width / 4 : (img.width >= 1500 ? img.width / 2 : img.width));
            canvas.height = (img.width >= 3000 ? img.height / 4 : (img.width >= 1500 ? img.height / 2 : img.height));
            // Dessiner l'image redimensionnée dans le canvas
            canvas.getContext('2d').drawImage(img, 0, 0, canvas.width, canvas.height);
            // Convertir le canvas en un blob
            canvas.toBlob(function (blob) {
                // Créer un nouveau fichier à partir du blob redimensionné
                let newFile = new File([blob], file.name, { type: file.type });
                newFile = new File([newFile], newFile.name.replace(new RegExp("(.+)[.](.+[^.])", "g"), "$1").slugify() + "." + newFile.name.replace(new RegExp("(.+)[.](.+[^.])", "g"), "$2").slugify(), { type: newFile.type });
                const form = new FormData();
                form.append('data', newFile);
                form.append('filemime', jInput.data("mime"));
                form.append('filetypes', jInput.data("filetypes"));
                form.append('imageratio', jInput.data("imageRatio"));
                form.append('fileexist', jInput.data("fileexist"));
                $.ajax({
                    url: siteURL + 'admin/file/upload',
                    type: 'POST',
                    cache: false,
                    contentType: false,
                    processData: false,
                    data: form,
                    success: function (data) {
                        const step = $(input).parents("dialog").data("step");
                        const dialog = $(channelGetDialog(step, "expertise"));
                        if (data.success) {
                            dialogToastrSuccess(dialog, dialog.data("name"), "Le fichier a bien été envoyé.");
                            expertiseFileUploadResult(data);
                            expertiseEnregistrementFile(dialog, input, data.id);
                        } else {
                            switch (data.error) {
                                case "type":
                                    expertiseFileUploadType(dialog, data);
                                    break;
                                case "ratio":
                                    expertiseFileUploadRatio(dialog, data);
                                    break;
                                case "exists":
                                    expertiseFileUploadExists(dialog, data);
                                    expertiseFileUploadResult(data);
                                    break;
                                default:
                                    expertiseFileUploadDefault(dialog);
                                    break;
                            }
                        }
                    },
                });
            }, file.type);
        }
        img.src = e.target.result;
    }
    fr.readAsDataURL(file);
}

function expertiseFileUploadDisplay(displayArea, data) {
    displayArea.slideUp('slow', function () {
        displayArea.html('<div class="w-100 text-center"><img src="' + data.path + data.file + '" class="mt-2 img-fluid border border-secondary rounded-sm">');
        displayArea.slideDown();
    });
}

function expertiseFileUploadType(dialog, data) {
    let expectedType = "";
    data.expected.forEach(function (e) { expectedType = expectedType + "<br /> - " + e; });
    dialogToastrError(dialog, dialog.data("name"), "Le fichier que vous avez envoyé n'est pas d'un format attendu :" + expectedType);
}

function expertiseFileUploadRatio(dialog, data) {
    dialogToastrError(dialog, dialog.data("name"), "Le fichier que vous avez envoyé n'est pas au bon ratio attendu: " + data.expected);
}

function expertiseFileUploadExists(dialog) {
    dialogToastrSuccess(dialog, dialog.data("name"), "Le fichier que vous avez envoyé existe déjà en base de données.");
}

function expertiseFileUploadDefault(dialog) {
    dialogToastrError(dialog, dialog.data("name"), "Erreur lors de l'enregistrement, si le problème persiste, veuillez contacter le service WEB.");
}

function expertiseEnregistrementFile(dialog, input, idFile) {
    let idVehicule = input.id.match(/\d+$/g)[0];
    let typeFile = input.id.replace("expertise-photo-", "").replace("-", "").replace(/(\d+)$/g, "");

    let form = new FormData();
    form.append("idVehicule", idVehicule);
    form.append("idFile", idFile);
    $.ajax({
        type: "POST",
        url: siteURL + "admin/stock/update/" + typeFile,
        contentType: false,
        processData: false,
        data: form,
        success: function (data) {
            if (data.success) {
                dialogToastrSuccess(dialog, "expertise", 'Enregistrement sur le véhicule réussi');
            } else {
                dialogToastrError(dialog, "expertise", 'Une erreur s\'est produite lors de l\'ajout du fichier sur le véhicule.');
            }
        },
        error: function () {
            dialogToastrError(dialog, "expertise", 'Une erreur s\'est produite lors de l\'ajout du fichier sur le véhicule.');
        }
    });
}