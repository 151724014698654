// Fonction pour afficher l'input du label du pays concerné pour les catégorie/prestation/élément/forfait FRE et modifier le drapeau
$('body').on('change', "[id^='fre'][id*='-select-pays-']", function () {
    let element = this.id.replace(/^(fre)(\w+)(-select-pays-)(\d+)$/g, "$2");
    let idElement = this.id.replace('fre' + element + '-select-pays-', '');
    let idPays = $(this).val();
    let codePays = $(this).children("option:selected").attr("data-codePays") != undefined ? $(this).children("option:selected").attr("data-codePays") : "EU";
    // On cache tout les inputs de traduction pour ensuite n'afficher que celui du pays sélectionné
    $("[id^='fre" + element + "-label-pays']").each(function() {
        $(this).addClass('d-none');
    });
    // On affiche l'input de la traduction du pays sélectionné
    $("#fre" + element + "-label-pays" + idPays + "-" + idElement).removeClass('d-none');
    // On met à jour le drapeau du pays sélectionné
    $("#fre" + element + "-drapeau-pays-" + idElement).attr('src', siteURL + "assets/pics/admin/drapeaux/" + codePays + ".png");
});

// Fonction pour récupérer un json des différents label traduit des différents pays
function getLabelTraduit(idElement, type) {
    let label_traduit = {};
    document.querySelectorAll("[id^='fre" + type + "-label-pays'][id$='" + idElement + "']").forEach(function(element) {
        if (element.value.trim() !== "") {
            let paysId = element.getAttribute("data-paysid");
            label_traduit[paysId] = element.value.trim();
        }
    });

    return JSON.stringify(label_traduit);
}