/**
 * Script JS pour la fonctionnalité des configurations de filtres
 */

let reloadLoadAjax = null;

/**
 * Récupère le loadAjax, loadModele et resetFilter
 */
function setupLoadAjax(loadAjax) {
    reloadLoadAjax = loadAjax;
}

// On vérifie qu'on est dans un tableau qui concerne les voitures
function setupLoadModele(loadModele) {
    setupLoadModele = loadModele;
}

function setupResetFilter(resetFilter) {
    setupResetFilter = resetFilter;
}

function setupMiseAJourFiltreIsPro(miseAJourFiltreIsPro) {
    setupMiseAJourFiltreIsPro = miseAJourFiltreIsPro;
}

function setupMiseAJourFiltreTypeEntreprise(miseAJourFiltreTypeEntreprise) {
    setupMiseAJourFiltreTypeEntreprise = miseAJourFiltreTypeEntreprise;
}

/**
 * Récupère les filtres utilisés ainsi que leur valeur
 * @returns {Array} Tableau des valeurs de chaque filtre
 */
function getAllFiltersWithValue() {
    // On boucle sur chaque filtre pour récupérer la valeur, et on en fait une paire de clé/valeur dans un tableau qu'on retournera
    let filtres = $("[id^='filters']");
    // On va stocker les valeurs des filtres dans un objet
    let tableauFiltreValeur = new Object;
    filtres.each(function (index) {
        // On sauvegarde le nom du filtre en découpant l'id du filtre
        let nomFiltreBrut = $(this)[0].id;
        // On enlève le début de l'id du filtre pour garder le nom du filtre
        // L'id d'un filtre commmence toujours par "filters" suivi de la fonctionnalité, tiret le nom du filtre (ex : filterssuivi-marque)
        let nomFiltre = nomFiltreBrut.replace(/^(filters[^-]+-)((?!reset|.*-div$).+)$/g, '$2');

        // Normalement, le nomFiltre contiendra soit le nom du filtre (marque) soit le nom total (filterssuivi-prixpublic-div)
        // Là, on vient regarder si on a toujours le début de l'id du filtre, car si c'est le cas, c'est que ce n'est pas la valeur du filtre, mais un bloc entourant le filtre en lui-même (exemple : les sliders des prix et kilométrage)
        if (!(nomFiltre.match(/filters[\w]+/g))) {
            // On va garder ce console.log dans le cas où on a un nouveau filtre ajouté et qu'on veut le nom pour rajouter en base
            console.log('Nom du filtre à rajouter en base si erreur : ' + nomFiltre); //! A GARDER !

            // On récupère la valeur du filtre
            let valeurFiltre = $(this).val()

            // On regarde si c'est un select, pour prendre son nombre de valeur possible, et voir si la personne a tout coché. Si oui, on met à null
            if ($(this).is('select')) {
                let nombreOptionDeBaseSelect = $('#' + nomFiltreBrut + ' option').length;
                let nombreOptionSelectionne = valeurFiltre.length;

                // On va sélectionner le select pour voir s'il y a des optgroup ou option en disabled, car si oui, il ne faut pas les compter
                let selectFiltre = $('#' + nomFiltreBrut).children().toArray();
                let enfantSelectFiltre = selectFiltre.filter((enfant) => !$(enfant).is(":disabled"))

                let nombreTotalOptionDisponible = 0;
                // On parcourt les enfants du select pour voir en fonction de si c'est un optgroup ou option
                $.each(enfantSelectFiltre, function (key, value) {
                    // On vérifie le premier objet pour savoir si c'est un option ou un optgroup
                    if (value.tagName == 'OPTION') {
                        nombreTotalOptionDisponible++;
                    } else if (value.tagName == 'OPTGROUP') {
                        nombreTotalOptionDisponible = nombreTotalOptionDisponible + $(value).children().length;
                    }
                });

                // Si on a le nombre d'option sélectionné qui est égale au nombre d'option disponible VISIBLE, on met à null
                if (nombreOptionSelectionne == nombreOptionDeBaseSelect || nombreOptionSelectionne == nombreTotalOptionDisponible) {
                    valeurFiltre = null;
                }
            }

            // On va vérifié ensuite si c'est un min ou max, car les min ou max ont une valeur, même si c'est celle par défaut
            // Par exemple : Le filtre des kilomètres aura 0 en min et 136000 en max, et on veut pas que ça s'enregistre en base car c'est les valeurs "par défaut" du min et max
            if (nomFiltre.match(/(\w*-)(min)/g)) {
                // On va chercher les input qui ne sont pas visible, mais qui garde les valeur min et max en value
                let inputMinimum = $('#' + nomFiltreBrut);
                let valeurMinimumDuFiltre = inputMinimum.attr('value');

                // On compare la valeur minimum du filtre avec la valeur du filtre pour voir si c'est la même
                // Si c'est la même, on n'enregistre pas
                if (parseInt(valeurFiltre) <= parseInt(valeurMinimumDuFiltre)) {
                    valeurFiltre = null;
                }
            } else if (nomFiltre.match(/(\w*-)(max)/g)) {
                // On va chercher les input qui ne sont pas visible, mais qui garde les valeur min et max en value
                let inputMaximum = $('#' + nomFiltreBrut);
                let valeurMaximumDuFiltre = inputMaximum.attr('value');

                // On compare la valeur maximum du filtre avec la valeur du filtre pour voir si c'est la même
                // Si c'est la même, on n'enregistre pas
                if (parseInt(valeurFiltre) >= parseInt(valeurMaximumDuFiltre)) {
                    valeurFiltre = null;
                }
            }

            // On vérifie si c'est un date, car pareil, il y a une date min et max par défaut
            if (nomFiltre.match(/^date/g)) {
                let inputDate = $('#' + nomFiltreBrut);
                let valeurDateParDefaut = inputDate.attr('value');

                // On passe à null si la valeur par défaut est sur la valeur du filtre
                if (valeurFiltre == valeurDateParDefaut) {
                    valeurFiltre = null;
                }
            }

            // On vérifie que la valeur ne soit pas vide
            if (valeurFiltre === "" || (Array.isArray(valeurFiltre) && valeurFiltre.length == 0) || valeurFiltre === "all" || ($(this)[0].selectedOptions != undefined ? ($(this)[0].selectedOptions.length ? ($(this)[0].selectedOptions[0].getAttribute("data-default") ? true : false) : false) : false)) {
                valeurFiltre = null;
            }

            // Maintenant, on vérifie si on a une valeur qui n'est pas une valeur par défaut (donc pas null) pour la rajouter dans le tableau qui sera retourné
            if (valeurFiltre != null && valeurFiltre != 'off') {
                tableauFiltreValeur[nomFiltre] = valeurFiltre;
            }
        }
    });

    return tableauFiltreValeur;
}

/**
 * Met à jour l'interface suivant la config sélectionné
 */
function filtreConfigFunSelectConfig() {
    $("#filtreConfigLabel").val($(this).data("label"));
    $("#filtreConfigLabel").prop("disabled", false);
    $("#filtreConfigDefaultConfig").prop("checked", $(this).data("defaultconfig"));
    $("#filtreConfigDefaultConfig").prop("disabled", parseInt($(this).data("defaultconfig")));
    $("#filtreConfigDelete").prop("disabled", (parseInt($(this).data("defaultconfig")) || !parseInt($(this).val())));
    $("#filtreConfigApply").prop("disabled", !parseInt($(this).val()));
}

/**
 * Envoie la requête de suppression de config
 */
function filtreConfigFunDelete() {
    if ($('input[name="filtreConfigId"]:checked').length) {
        let formData = new FormData();
        formData.append('id', $('input[name="filtreConfigId"]:checked').val());
        formData.append('page', $("#filtreConfigPage").val());
        $.ajax({
            type: 'POST',
            url: siteURL + 'admin/config-filtre/delete',
            contentType: false,
            processData: false,
            data: formData,
            success: function (modalContent) {
                toastr.success('Suppression de la configuration réussi', 'Succès');
                $('#filtreConfigModalContent').html(modalContent);
                if ($("#filtreConfigApply").data("id") == formData.get("id")) {
                    filtreConfigFunSelectDefault();
                }
                updateSelectFiltreConfig();
            },
            error: function () { toastr.error('Erreur lors de la suppression', 'Erreur'); }
        });
    }
}

/**
 * Envoie la requête de d'enregistrement de config
 */
function filtreConfigFunSave() {

    // On vérifie que le nom ne soit pas trop long
    if ($("#filtreConfigLabel").val().length <= 30) {
        let filtresValeurs = getAllFiltersWithValue();
        filtresValeurs = JSON.stringify(filtresValeurs);

        if ($('input[name="filtreConfigId"]:checked').length) {
            let formData = new FormData();
            formData.append('id', $('input[name="filtreConfigId"]:checked').val());
            formData.append('label', $("#filtreConfigLabel").val());
            formData.append('page', $("#filtreConfigPage").val());
            formData.append('defaultConfig', $("#filtreConfigDefaultConfig").prop("checked"));
            formData.append('filtresValeurs', filtresValeurs);
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/config-filtre/modify',
                contentType: false,
                processData: false,
                data: formData,
                success: function (response) {
                    toastr.success('Enregistrement de la configuration réussi', 'Succès');
                    updateSelectFiltreConfig()
                    $('#filtreConfigModalContent').html(response.modalContent);
                    $('input[name="filtreConfigId"][value="' + response.nouvelId + '"').trigger("click");
                    // On est obligé de mettre une timeout car la fonction updateSelectFiltreConfig n'est pas fini quand le code arrive ici
                    setTimeout(function () {
                        // On met à jour le select des configs
                        let configDansSelect = $("#selectFiltreConfig").children().toArray();
                        // $("#selectFiltreConfig").append('<option id="filtreConfigSelect-filtreConfig-' + response.nouvelId + '" value="' + $("#filtreConfigLabel").val() + '" data-id="' + response.nouvelId + '" selected>' + $("#filtreConfigLabel").val() + '</option>');
                        // On regarde chaque option, et si c'est celle qui est mise, on selectionne l'option
                        configDansSelect.forEach((element) => {
                            if ($(element).attr('data-id') == response.nouvelId) {
                                $(element).prop('selected', true);
                            } else {
                                $(element).prop('selected', false);
                            }
                        });
                    }, 1000);
                },
                error: function () { toastr.error('Erreur lors de l\'enregistrement', 'Erreur'); }
            });
        }
    } else {
        toastr.error('Nom de configuration trop long (Max 30 caractères)', 'Erreur');
    }
}

/**
 * Applique une configuration sur le tableau
 */
function filtreConfigFunApply() {
    $("#filtreConfigModalOverlay").removeClass("d-none");

    if ($('input[name="filtreConfigId"]:checked').length) {
        $("#filtreConfigApply").data("id", $('input[name="filtreConfigId"]:checked').val());

        // On reset les filtres avant
        setupResetFilter();

        let formData = new FormData();
        formData.append('id', $('input[name="filtreConfigId"]:checked').val());
        $.ajax({
            type: 'POST',
            url: siteURL + 'admin/config-filtre/get-config',
            contentType: false,
            processData: false,
            data: formData,
            success: function (data) {
                // CAS SPÉCIAL ANNUAIRE : Pour checker si on doit régler l'affichage dans l'annuaire
                let annuairePro = false;

                let nomsFiltres = Object.getOwnPropertyNames(data);
                nomsFiltres.forEach(nom => {
                    let valeursFiltre = data[nom];

                    // On boucle sur chaque filtre pour récupérer la valeur, et on en fait une paire de clé/valeur dans un tableau qu'on retournera
                    let filtres = $("[id*='" + nom + "']");
                    filtres.each(function (index) {
                        // On sauvegarde le nom du filtre en découpant l'id du filtre
                        let nomFiltreBrut = $(this)[0].id;
                        // On enlève le début de l'id du filtre pour garder le nom du filtre
                        // L'id d'un filtre commmence toujours par "filters" suivi de la fonctionnalité, tiret le nom du filtre (ex : filterssuivi-marque)
                        let nomFiltre = nomFiltreBrut.replace(/^(filters[^-]+-)((?!reset|.*-div$).+)$/g, '$2');
                        if (nomFiltre == nom) {
                            // On applique la valeur
                            $(this).val(valeursFiltre).selectpicker("refresh");

                            // On check si c'est un checkbox
                            if ($(this).attr('type') == "checkbox") {
                                $(this).prop("checked", valeursFiltre == "on");
                            }

                            // Si c'est un min ou max, il faut qu'on change aussi le slider
                            if (nomFiltre.match(/(\w*-)(min)/g)) {
                                // On reprend le "vrai" filtre
                                let nomFiltreBruteMinMax = nomFiltreBrut.replace(/^(filters[^-]+-)((?!reset|.*-div$).+)(-min|-max)$/g, '$1$2');
                                $('#' + nomFiltreBruteMinMax).slider()[0].noUiSlider.set([valeursFiltre, null]);
                            } else if (nomFiltre.match(/(\w*-)(max)/g)) {
                                // On reprend le "vrai" filtre
                                let nomFiltreBruteMinMax = nomFiltreBrut.replace(/^(filters[^-]+-)((?!reset|.*-div$).+)(-min|-max)$/g, '$1$2');
                                $('#' + nomFiltreBruteMinMax).slider()[0].noUiSlider.set([null, valeursFiltre]);
                            }

                            // CAS SPÉCIAL ANNUAIRE : on check si on a un type d'entreprise. Si oui, on va lancer un trigger change pour lancer l'affichage des éléments
                            if (nomFiltre == "isPro" || nomFiltre == "typeEntreprise") {
                                annuairePro = true;
                            }
                        }
                    });
                });

                // CAS SPÉCIAL ANNUAIRE : on check il y a eu des changements dans les filtres qui font changer l'affichage
                if (annuairePro == true) {
                    if (typeof setupMiseAJourFiltreIsPro != 'undefined') {
                        setupMiseAJourFiltreIsPro();
                    }
                    if (typeof setupMiseAJourFiltreTypeEntreprise != 'undefined') {
                        setupMiseAJourFiltreTypeEntreprise();
                    }
                }

                if (typeof setupLoadModele != 'undefined') {
                    setupLoadModele();
                }

                if ($("#filtreConfigPage").val() == "admin_annuaires" || $("#filtreConfigPage").val() == "admin_vehicule_fournisseur" || $("#filtreConfigPage").val() == "admin_offres_fournisseur") {
                    reloadLoadAjax({ isPageReload: true });
                } else {
                    reloadLoadAjax();
                }
                toastr.success('Application de la configuration des filtres réussie', 'Succès');
            },
            error: function () {
                toastr.error('Erreur lors de l\'application de la configuration des filtres', 'Erreur');
                if ($("#filtreConfigPage").val() == "admin_annuaires" || $("#filtreConfigPage").val() == "admin_vehicule_fournisseur" || $("#filtreConfigPage").val() == "admin_offres_fournisseur") {
                    reloadLoadAjax({ isPageReload: true });
                } else {
                    reloadLoadAjax();
                }
            }
        });
    } else {
        reloadLoadAjax();
    }
    $("#filtreConfigModalOverlay").addClass("d-none");
    $('#filtreConfigModal').modal('hide');

    // On met à jour le select des configs
    let configDansSelect = $("#selectFiltreConfig").children().toArray();
    // On regarde chaque option, et si c'est celle qui est mise, on selectionne l'option
    configDansSelect.forEach((element) => {
        if ($(element).attr('data-id') == $('input[name="filtreConfigId"]:checked').val()) {
            $(element).prop('selected', true);
        } else {
            $(element).prop('selected', false);
        }
    });
}

/**
 * Sélectionne la config par défaut
 */
function filtreConfigFunSelectDefault() {
    for (const el of $("[name='filtreConfigId']")) { if (parseInt($(el).data("defaultconfig"))) { $("#filtreConfigApply").data("id", $(el).val()); } }
}

/**
 * Met à jour le bouton radio sélectionne en fonction de la config
 */
function filtreConfigFunSelectRefresh() {
    $('input[name="filtreConfigId"][value="' + $("#filtreConfigApply").data("id") + '"').trigger("click");
}

/**
 * Met à jour le bouton radio sélectionne en fonction de la config sélectionnée dans le select, et applique la configuration
 */
function filtreConfigFunSelectDansSelectRefresh() {
    // On sélectionne le filtre sélectionné dans le select
    let configSelected = $("#selectFiltreConfig option:selected");

    // On vérifie que ce soit pas le "Aucun filtre" de sélectionné
    if (configSelected.val() != 'aucun') {
        // On applique la config sélectionné dans la modale et on applique la configuration sur le tableau
        $('input[name="filtreConfigId"][value="' + configSelected.data("id") + '"]').trigger("click");
        filtreConfigFunApply();
    } else {
        setupResetFilter();
        $('input[name="filtreConfigId"][value="0"]').trigger("click");
        reloadLoadAjax();
    }
}

/**
 * Désactive tous les évènements
 */
function filtreConfigFunOffEvent() {
    $("body").off("change", "[name='filtreConfigId']", filtreConfigFunSelectConfig);
    $("body").off("change", "#selectFiltreConfig", filtreConfigFunSelectDansSelectRefresh);
    $("body").off("click", "#filtreConfigDelete", filtreConfigFunDelete);
    $("body").off("click", "#filtreConfigSave", filtreConfigFunSave);
    $("body").off("click", "#filtreConfigApply", filtreConfigFunApply);
}

/**
 * Active tous les évènements
 */
function filtreConfigFunOnEvent() {
    $("body").on("change", "[name='filtreConfigId']", filtreConfigFunSelectConfig);
    $("body").on("change", "#selectFiltreConfig", filtreConfigFunSelectDansSelectRefresh);
    $("body").on("click", "#filtreConfigDelete", filtreConfigFunDelete);
    $("body").on("click", "#filtreConfigSave", filtreConfigFunSave);
    $("body").on("click", "#filtreConfigApply", filtreConfigFunApply);
    // On check si on a cliqué sur un lien contenant des paramètres de filtres
    if (Object.keys(getAllFiltersWithValue()).length === 0) {
        $("#selectFiltreConfig").trigger("change");
    } else {
        $('input[name="filtreConfigId"][value="0"]').trigger("click");
        $('#filtreConfigSelect-filtreConfig-aucun').prop('selected', true);
        reloadLoadAjax();
    }
    filtreConfigFunSelectDefault();
}


/**
 * Update le select des choix des filtres
 */
function updateSelectFiltreConfig() {
    let formData = new FormData();
    formData.append('page', $("#filtreConfigPage").val());
    $.ajax({
        type: 'POST',
        url: siteURL + 'admin/config-filtre/update-select',
        contentType: false,
        processData: false,
        data: formData,
        success: function (selectFiltreContent) {
            toastr.success('Mise à jour de la sélection de la configuration de filtre réussi', 'Succès');
            $('#selectFiltreConfig').html(selectFiltreContent);
        },
        error: function () { toastr.error('Erreur lors de l\'actualisation du select du filtre', 'Erreur'); }
    });
}
