// Génération de la datatable des tv
tv_table = $('#tv_table').DataTable({
    "autoWidth": false,
    "language": {
        "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/fr-FR.json"
    },
    "responsive": true,
    "ajax": {
        "url": siteURL+'json/ora7tv',
        "type": "POST",
    },
    "order": [
        [0, "desc"]
    ],
    "columnDefs": [
        // TEXT CENTER
        {
            "className": "text-center align-middle",
            "targets": [2,3,4,5],
        },
        // ALIGN MIDDLE
        {
            "className": "align-middle",
            "targets": '_all',
        },
        // ID
        {
            "name": "id",
            "targets": 0,
            "render": function(data, type, row) {
                return '<a href="'+siteURL+row[0]+'" id="display_vehicule_'+row[0]+'"><i class="fad fa-eye pr-1"></i></a> '+row[0];
            },
            "width": 70,
        },
        // TITLE
        {
            "name": "title",
            "targets": 1,
            "render": function(data, type, row) {
                return row[1];
            },
        },
        // LAST UPDATE
        {
            "name": "last update",
            "targets": 2,
            "render": function(data, type, row) {
                return row[2].date.split('.')[0];
            },
            "width": 210,
        },
        // USER
        {
            "targets": 3,
            "render": function(data, type, row) {
                return row[3];
            },
            "width": 250,
        },
        // ISVISIBLE
        {
            "targets": 4,
            "render": function(data, type, row) {
                if (row[4] == 1) {
                    return '<i class="fas fa-check" style="color:green" title="Visible"></i><span class="d-inline d-lg-none pl-2">OUI</span>';
                } else {
                    return '<i class="fas fa-times" style="color:red" title="Non Visible"></i><span class="d-inline d-lg-none pl-2">NON</span>';
                }
            },
            "width": "25px",
        },
        // MODIFY
        {
            "targets": 5,
            "render": function(data, type, row) {
                return '<button class="btn w-100 p-0" id="ora7tv-open-modify-' + row[0] + '" data-category="autres"><i class="fad fa-edit"></i>';
            },
            "orderable": false,
            "width": "30px",
        },
    ],
    "conditionalPaging": true,
});

// Recharge la table
$('[id^="tvtable-"').on( 'change keyup', function () {
    column = this.id.replace('tvtable-', '');

    tv_table.column( column ).search(
        $(this).val()
    ).draw();
} );

// RESET
$('#tvtable-reset').on('click', function() {
    $('#tv-tablesearch').trigger("reset");
    $("#tvtable-0").val('').selectpicker("refresh");
    $("#tvtable-1").val('').selectpicker("refresh");
    $("#tvtable-2").val('default').selectpicker("refresh");
    $("#tvtable-3").val('default').selectpicker("refresh");
    tv_table.columns().search( '' ).draw();
    return false;
})


// ===========================================================
// ===========================================================
// ===================== VEHICULE TV =========================
// ===========================================================
// ===========================================================

// Génération de la datatable des tv vehicule
if (typeof vehiculetv_table === "undefined") {
    vehiculetv_table = new Array();
}
vehiculetv_table[0] = $('[id^="vehiculetv_table_"]').DataTable({
    "autoWidth": false,
    "language": {
        "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/fr-FR.json"
    },
    "responsive": true,
    "ajax": {
        "url": siteURL+'json/vehicules',
        "type": "POST",
    },
    "order": [
        [0, "desc"]
    ],
    "columns": [
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        {
            "orderDataType": "dom-text",
            type: 'string',
        },
    ],
    "columnDefs": [
        // TEXT CENTER
        {
            "className": "text-center align-middle",
            "targets": [4,5,6,7,8],
        },
        // ALIGN MIDDLE
        {
            "className": "align-middle",
            "targets": '_all',
        },
        {
            "orderDataType": "dom-text",
            targets: 8,
        },
        // ID
        {
            "name": "id",
            "targets": 0,
            "render": function(data, type, row) {
                return '<a href="'+siteURL+row[0]+'" id="display_vehicule_'+row[0]+'"><i class="fad fa-eye pr-1"></i></a> '+row[0];
            },
        },
        // MARQUE
        {
            "name": "marque",
            "targets": 1,
            "render": function(data, type, row) {
                return row[3];
            },
        },
        // MODELE
        {
            "name": "modele",
            "targets": 2,
            "render": function(data, type, row) {
                return row[4];
            },
        },
        // FINITION
        {
            "targets": 3,
            "render": function(data, type, row) {
                return row[5];
            },
        },
        // ETAT
        {
            "targets": 4,
            "render": function(data, type, row) {
                var kilometrage = row[7];
                if (kilometrage <= 100) {
                    return 'Neuf';
                } else {
                    return 'Occasion';
                }
                // return row[7];
            },
        },
        // EMPLACEMENT
        {
            "targets": 5,
            "render": function(data, type, row) {
                if (row[11] == null) {
                    return "En arrivage";
                } else {
                    return row[11];
                }

            },
        },
        // DISPONIBLE
        {
            "targets": 6,
            "render": function(data, type, row) {
                if (row[12] == null) {
                    return '<i class="fas fa-check" style="color:green" title="Validé"></i><span class="d-inline d-lg-none pl-2">OUI</span>';
                } else {
                    return '<i class="fas fa-times" style="color:red" title="Non Validé"></i><span class="d-inline d-lg-none pl-2">NON</span>';
                }
            },
        },
        // PHOTO
        {
            "width": "25px",
            "targets": 7,
            "render": function(data, type, row) {
                if (row[13] == "") {
                    return '<i class="fas fa-circle" style="color:red" title="Non Validé"></i><span class="d-inline d-lg-none pl-2">NON</span>';
                } else {
                    return '<i class="fas fa-circle" style="color:green" title="Validé"></i><span class="d-inline d-lg-none pl-2">OUI</span>';
                }
            },
            //"orderable": false,
        },
        // ADD
        {
            "width": "25px",
            "targets": 8,
            "render": function(data, type, row) {
                return '<button class="btn w-100 p-0" id="vehiculetv-add-0-' + row[0] + '" title="Ajouter"><i class="fas fa-plus-circle"></i></button>';
            },
        },
    ],
    "conditionalPaging": true,
});


// Permets de pouvoir trier la column du check vehicule
$.fn.dataTable.ext.order['dom-text'] = function(settings, col)
{
    return this.api().column( col, {order:'index'} ).nodes().map( function ( td, i ) {
        return $('button', td).html();
    });
}

// Recharge la table
$('body').on( 'change keyup', '[id^="vehiculetvtable-"]', function () {
    column = this.id.replace('vehiculetvtable-', '').split('-')[0];
    id_clicked = this.id.replace('vehiculetvtable-', '').split('-')[1];

    // Au changement de la marque afficher les modeles correspondants
    if (column == 1) {
        var marque = this.value;
        if (marque != "") {


            var form = new FormData();
            form.append('marque', marque);

            $.ajax({
                type: 'POST',
                url: siteURL + "json/modeles_marque",
                data: form,
                processData: false,
                contentType: false,
                success: function(data, status) {
                    $('#vehiculetvtable-2-'+id_clicked).empty();
                    $('#vehiculetvtable-2-'+id_clicked).append('<option value=>Tous les modèles</option>');
                    for (id in data) {
                        if (data[id]['nb'] > 0) {
                            $('#vehiculetvtable-2-'+id_clicked).append("<option value='" + id + "'>" + id + "</option>");
                        }
                    };
                    $('#vehiculetvtable-2-'+id_clicked).val('default').selectpicker("refresh");
                }
            });
            vehiculetv_table[id_clicked].column(2).search( '' ).draw();
            vehiculetv_table[id_clicked].column( column ).search(
                '^'+$(this).val()+'$',
                true,
                false,
            ).draw();
        } else {
            vehiculetv_table[id_clicked].column(1).search( '' ).draw();
            $('#vehiculetvtable-2-'+id_clicked).empty();
            $('#vehiculetvtable-2-'+id_clicked).append('<option value=>Tous les modèles</option>');
            $('#vehiculetvtable-2-'+id_clicked).val('default').selectpicker("refresh");
            vehiculetv_table[id_clicked].column(2).search( '' ).draw();
        }
    }
    else if (column == 0) {
        vehiculetv_table[id_clicked].column(0).search( $(this).val() ).draw();
    }
    else {
        if (this.value != "") {
            vehiculetv_table[id_clicked].column( column ).search(
                '^'+$(this).val()+'$',
                true,
                false,
            ).draw();
        } else {
            vehiculetv_table[id_clicked].column(column).search( '' ).draw();
        }

    }
} );

// SELECT ALL VEHICULE
$('body').on('click', '[id^="select-all-"]', function () {
    id_clicked = this.id.replace('select-all-', '').split('-')[0];

    for (i=0; i < vehiculetv_table[id_clicked].rows( {order:'index', search:'applied'} ).nodes().length; i++) {
        row = vehiculetv_table[id_clicked].rows( {order:'index', search:'applied'} ).nodes()[i];
        vehiculeId = $(row).find("td:first").text();
        if (typeof vehiculesAddInChaine[id_clicked] == 'undefined') {
            vehiculesAddInChaine[id_clicked] = [];
        }
        // Permet de vider la liste
        vehiculesAddInChaine[id_clicked] = jQuery.grep(vehiculesAddInChaine[id_clicked], function(value) {
            return value != vehiculeId;
        });
        vehiculesAddInChaine[id_clicked].push(vehiculeId);
        // GRAPHIQUE
        last = $(row).find("td:last-child");
        $(last).html("<button class='btn w-100 p-0' id='vehiculetv-del-" + id_clicked + "-" + vehiculeId +"' title='Supprimer'><i class='fas fa-check-circle' id='vehiculeadd-"+id_clicked+"-"+vehiculeId+"' style='color: #22E03B;'></i></button>");
    }

    return false;
});

// UNSELECT ALL VEHICULE
$('body').on('click', '[id^="unselect-all-"]', function () {
    id_clicked = this.id.replace('unselect-all-', '').split('-')[0];

    for (i=0; i < vehiculetv_table[id_clicked].rows( {order:'index', search:'applied'} ).nodes().length; i++) {
        row = vehiculetv_table[id_clicked].rows( {order:'index', search:'applied'} ).nodes()[i];
        vehiculeId = $(row).find("td:first").text();
        if (typeof vehiculesAddInChaine[id_clicked] == 'undefined') {
            vehiculesAddInChaine[id_clicked] = [];
        }
        // Permet de vider la liste
        vehiculesAddInChaine[id_clicked] = jQuery.grep(vehiculesAddInChaine[id_clicked], function(value) {
            return value != vehiculeId;
        });
        // GRAPHIQUE
        last = $(row).find("td:last-child");
        $(last).html("<button class='btn w-100 p-0' id='vehiculetv-add-" + id_clicked + "-" + vehiculeId + "' title='Ajouter'><i class='fas fa-plus-circle'></i></button>");
    }

    return false;
});

// RESET
$('body').on('click', '[id^="vehiculetvtable-reset-"]', function() {
    id_clicked = this.id.replace('vehiculetvtable-reset-', '').split('-')[0];

    $("[id^='vehiculetv-tablesearch-'").trigger("reset");
    $("[id^='vehiculetvtable-0-'").val('').selectpicker("refresh");
    $("[id^='vehiculetvtable-1-'").val('default').selectpicker("refresh");
    $("[id^='vehiculetvtable-2-'").val('default').selectpicker("refresh");
    $("[id^='vehiculetvtable-4-'").val('default').selectpicker("refresh");
    $("[id^='vehiculetvtable-5-'").val('default').selectpicker("refresh");
    vehiculetv_table[id_clicked].columns().search( '' ).draw();
    return false;
});



vehiculesAddInChaine = [];
// Ajout sur la liste des vehicules
$('body').on('click', "button[id^='vehiculetv-add-']", function() {
    var vehiculeId = this.id.split(/[- ]+/).pop();
    var clickedId = this.id.split(/[- ]+/)[2];

    if (typeof vehiculesAddInChaine[clickedId] == 'undefined') {
        vehiculesAddInChaine[clickedId] = [];
    }
    $(this).replaceWith("<button class='btn w-100 p-0' id='vehiculetv-del-" + clickedId + "-" + vehiculeId +"' title='Supprimer'><i class='fas fa-check-circle' id='vehiculeadd-"+clickedId+"-"+vehiculeId+"' style='color: #22E03B;'></i></button>");
    vehiculesAddInChaine[clickedId].push(vehiculeId);

    return false;
});

// Delete sur la liste des vehicule
$('body').on('click', "button[id^='vehiculetv-del-']", function() {
    var vehiculeId = this.id.split(/[- ]+/).pop();
    var clickedId = this.id.split(/[- ]+/)[2];

    $(this).replaceWith("<button class='btn w-100 p-0' id='vehiculetv-add-" + clickedId + "-" + vehiculeId + "' title='Ajouter'><i class='fas fa-plus-circle'></i></button>");
    vehiculesAddInChaine[clickedId] = jQuery.grep(vehiculesAddInChaine[clickedId], function(value) {
        return value != vehiculeId;
    });

    return false;
});


// ===========================================================
// =================== TYPE SELECTION ========================
// ===========================================================

// Recharge la table
$('body').on( 'change keyup', '[id^="vehiculetvtype-"]', function () {
    column = this.id.replace('vehiculetvtype-', '').split('-')[0];
    id_clicked = this.id.replace('vehiculetvtype-', '').split('-')[1];

    // Au changement de la marque afficher les modeles correspondants
    if (column == 1) {
        var marque = this.value;
        if (marque != "") {
            console.log(marque);
            var form = new FormData();
            // Hack pour le JSON
            form.append('marque', marque+",");

            $.ajax({
                type: 'POST',
                url: siteURL + "json/modeles",
                data: form,
                processData: false,
                contentType: false,
                success: function(data, status) {
                    console.log(data);
                    $('#vehiculetvtype-2-'+id_clicked).empty();
                    $('#vehiculetvtype-2-'+id_clicked).append('<option value=>Tous les modèles</option>');

                    for (id in data[0]) {
                        if (data[0][id]['nb'] > 0) {
                            $('#vehiculetvtype-2-'+id_clicked).append("<option value='" + data[0][id].id + "'>" + id + "</option>");
                        }
                    };
                    $('#vehiculetvtype-2-'+id_clicked).val('default').selectpicker("refresh");
                }
            });
        } else {
            $('#vehiculetvtype-2-'+id_clicked).empty();
            $('#vehiculetvtype-2-'+id_clicked).append('<option value=>Tous les modèles</option>');
            $('#vehiculetvtype-2-'+id_clicked).val('default').selectpicker("refresh");
        }

    }
    // Mettre le nombre de véhicule disponible en fonction du choix
    var marque = $('[id^="vehiculetvtype-1-"]').val();
    var modele = $('[id^="vehiculetvtype-2-"]').val();
    var etat = $('[id^="vehiculetvtype-3-"]').val();
    var emplacement = $('[id^="vehiculetvtype-4-"]').val();

    var form = new FormData();
    form.append('marque', marque);
    form.append('modele', modele);
    form.append('etat', etat);
    form.append('emplacement', emplacement);

    $.ajax({
        type: 'POST',
        url: siteURL + "annonce_nombre_vehicule",
        data: form,
        processData: false,
        contentType: false,
        success: function(data, status) {
            $('#resultcountvehicule-'+id_clicked).empty();
            $('#resultcountvehicule-'+id_clicked).text('Nombre de véhicule(s) disponible(s) : ' + data);

        }
    });
});

// RESET TYPE SELECTION
$('body').on('click', '[id^="vehiculetvtype-reset-"]', function() {
    id_clicked = this.id.replace('vehiculetvtype-reset-', '').split('-')[0];

    $('#vehiculetv-typesearch-'+id_clicked).trigger("reset");
    $('#vehiculetvtype-1-'+id_clicked).val('').selectpicker("refresh");
    $('#vehiculetvtype-2-'+id_clicked).empty();
    $('#vehiculetvtype-2-'+id_clicked).append('<option value=>Tous les modèles</option>');
    $('#vehiculetvtype-2-'+id_clicked).val('').selectpicker("refresh");
    $('#vehiculetvtype-3-'+id_clicked).val('').selectpicker("refresh");
    $('#vehiculetvtype-4-'+id_clicked).val('').selectpicker("refresh");
    $('#resultcountvehicule-'+id_clicked).text('Nombre de véhicule(s) disponible(s)');

    return false;
});

// VALIDE TYPE SELECTION
type_selection = [];
$('body').on('click', '[id^="vehiculetvtype-valid-"]', function() {

    id_clicked = this.id.replace('vehiculetvtype-valid-', '').split('-')[0];

    if (typeof type_selection[id_clicked] == 'undefined') {
        type_selection[id_clicked] = [];
    }

    type_selection[id_clicked].push($("#vehiculetvtype-1-"+id_clicked).val());
    type_selection[id_clicked].push($("#vehiculetvtype-2-"+id_clicked).val());
    type_selection[id_clicked].push($("#vehiculetvtype-3-"+id_clicked).val());
    type_selection[id_clicked].push($("#vehiculetvtype-4-"+id_clicked).val());

    // Supprime tout les vehicules rentrée a la main
    for (i=0; i < vehiculetv_table[id_clicked].rows( {order:'index', search:'applied'} ).nodes().length; i++) {
        row = vehiculetv_table[id_clicked].rows( {order:'index', search:'applied'} ).nodes()[i];
        vehiculeId = $(row).find("td:first").text();

        if (typeof vehiculesAddInChaine[id_clicked] == 'undefined') {
            vehiculesAddInChaine[id_clicked] = [];
        }
        vehiculesAddInChaine[id_clicked] = jQuery.grep(vehiculesAddInChaine[id_clicked], function(value) {
            return value != vehiculeId;
        });
        // GRAPHIQUE
        last = $(row).find("td:last-child");
        $(last).html("<button class='btn w-100 p-0' id='vehiculetv-add-" + id_clicked + "-" + vehiculeId + "' title='Ajouter'><i class='fas fa-plus-circle'></i></button>");
    }
    // Residu de vehicule
    vehiculesAddInChaine[id_clicked].length = 0;

    return false;
});


//Envoi de modification ora7tv
$('body').on('click', "button[id^='tv-send-modify-']", function() {
    var clickedId = this.id.split(/[- ]+/).pop();
    if (makeSureFields(this.id) == true) {
        var form = new FormData();
        // On extrait le contenu du formulaire dans la variable 'form'
        form.append('id', clickedId);
        form.append('tv_title', $('#tv_nom_chaine_' + clickedId).val());
        form.append('tv_defilement', $('#tv_defilement_' + clickedId).val());
        form.append('tv_transition', $('#tv_transition_' + clickedId).val());
        form.append('tv_information', $('#tv_information_' + clickedId).val());
        form.append('tv_valid', $('input[name="tv_valid_' + clickedId + '"]:checked').val());

        if (typeof $("#tv_information_"+clickedId).val() !== "undefined") {
            var tv_information = $("#tv_information_"+clickedId).val();
            tv_information = tv_information.split('\n');
            tv_information.forEach(function(information, index) {
                if (information.length > 60) {
                    toastr.error('Merci de reduire le texte n°' + (index+1) + ' des informations ', 'Erreur');
                    echec = true;
                }
            });
            if (typeof echec !== "undefined" && echec == true) {
                echec = false;
                return false;
            }
        }

        if (typeof vehiculesAddInChaine[clickedId] == 'undefined' & typeof type_selection[clickedId] == 'undefined') {
            toastr.error('Merci d\'ajouter des véhicules', 'Erreur');
            return false;
        }

        if (typeof vehiculesAddInChaine[clickedId] !== 'undefined') {
            if (vehiculesAddInChaine[clickedId].length != 0) {

                // CHECK si le véhicule est disponnible
                vehiculesAddInChaine[clickedId].forEach(function(information, index) {
                    var temp = new FormData();
                    temp.append('id', information);

                    $.ajax({
                        type: 'POST',
                        url: siteURL+'admin/autres/ora7tv/disponible',
                        contentType: false,
                        processData: false,
                        data: temp,
                        success: function(data) {
                            if (data != 1) {
                                // Supprime du tableau si il n'est plus disponible
                                vehiculesAddInChaine[clickedId] = jQuery.grep(vehiculesAddInChaine[clickedId], function(value) {
                                    return value != information;
                                });
                            }
                        }
                    });
                });





            }
        }
        // Si vehiculesAddInChaine existe attendre que la fonction de audessus soit terminer
        if (typeof vehiculesAddInChaine[clickedId] !== 'undefined') {

            $.when( $.ajax( siteURL+"admin/autres/ora7tv/disponible" ) ).then(function( data, textStatus, jqXHR ) {
                form.append('tv_vehicules', vehiculesAddInChaine[clickedId]);
                // Vidé la liste des véhicules
                //
                vehiculesAddInChaine[clickedId].forEach(function(idVehicule) {
                    $("button[id^='vehiculetv-del-"+clickedId+"-"+idVehicule+"']").replaceWith("<button class='btn w-100 p-0' id='vehiculetv-add-"+clickedId+"-" + idVehicule + "' title='Ajouter'><i class='fas fa-plus-circle'></i></button>");
                });

                vehiculesAddInChaine[clickedId].length = 0;

                if (typeof type_selection[clickedId] !== 'undefined') {
                    if (type_selection != 0) {
                        form.append('tv_type_selection', type_selection[clickedId]);
                        type_selection[clickedId].length = 0;
                    }
                }
                // Envoie du formulaire
                $.ajax({
                    type: 'POST',
                    url: siteURL+ 'admin/autres/ora7tv/modify',
                    contentType: false,
                    processData: false,
                    data: form,
                    success: function(data) {
                        if (data == "exists") {
                            toastr.error('Erreur', 'Erreur');
                        }
                        else {
                            if (clickedId == 0) {
                                toastr.success('Succès', 'Succès');
                                $('#formtv_0')[0].reset();
                            }
                            else {
                                toastr.success('La chaine a bien été enregistré', 'Succès');
                                $('#tv-tabs-modify-' + clickedId).remove();
                                $('#tv-close-modify-' + clickedId).parent().remove();
                            }
                            tv_table.ajax.reload();
                            $('#tv-tabs-list-tab').trigger('click');
                        }
                    }
                });

            });
        // Sinon executer le code normalement
        } else {
           if (typeof type_selection[clickedId] !== 'undefined') {
                if (type_selection != 0) {
                    form.append('tv_type_selection', type_selection[clickedId]);
                    type_selection[clickedId].length = 0;
                }
            }
            // Envoie du formulaire
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/autres/ora7tv/modify',
                contentType: false,
                processData: false,
                data: form,
                success: function(data) {
                    if (data == "exists") {
                        toastr.error('Erreur', 'Erreur');
                    }
                    else {
                        if (clickedId == 0) {
                            toastr.success('Succès', 'Succès');
                            $('#formtv_0')[0].reset();
                        }
                        else {
                            toastr.success('La chaine a bien été enregistré', 'Succès');
                            $('#tv-tabs-modify-' + clickedId).remove();
                            $('#tv-close-modify-' + clickedId).parent().remove();
                        }
                        tv_table.ajax.reload();
                        $('#tv-tabs-list-tab').trigger('click');
                    }
                }
            });
        }

    }
    return false;
});