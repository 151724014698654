$(document).ready( function () {

    // Génération de la datatable des publicites
    publicite_table = $('#publicites_table').DataTable( {
        "autoWidth": false,
    	"language": {
            "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/fr-FR.json"
        },
        "responsive": true,
    	"ajax": siteURL + 'json/publicites',
    	"order": [[ 0, "desc" ]],
    	"columnDefs": [
            { responsivePriority: 1, targets: 0 },
            { responsivePriority: 2, targets: 3 },
            { responsivePriority: 3, targets: 1 },
            // TEXT CENTER
            {
                "className": "text-center align-middle",
                "targets": [0,2,3,4,5],
            },
            //TEXT START
            {
                "className": "text-start",
                "targets": [1],
        },
            // ID
            {
                "width": "50px",
                "targets": 0,
                "render": function ( data, type, row ) {
                    return row[0];
                },
            },
            // TITLE
    		{
                "targets": 1,
    			"render": function ( data, type, row ) {
                    return "<span class='text-start fw-bold'>"+row[1]+"</span><br />"+row[2];
                },
    		},
            // ORDRE
            {
                "width": "75px",
                "targets": 2,
                "render": function ( data, type, row ) {
                    return row[4];
                },
            },
            // SITE
            {
                "width": "120px",
                "targets": 3,
                "render": function (data, type, row) {
                    if (row[5] == 0) { return "TOUS"; }
                    if (row[5] == 1) { return "PARTICULIER"; }
                    if (row[5] == 2) { return "PRO"; }
                },
                "orderable": false,
            },
            // VISIBLE
    		{
                "width": "50px",
                "targets": 4,
                "render": function (data, type, row) {
                    if (row[6] == 1) {
                        return '<i class="far fa-check-square"></i><span class="d-inline d-lg-none pl-2">OUI</span>';
                    }
                    else {
                        return '<i class="far fa-square"></i><span class="d-inline d-lg-none pl-2">NON</span>';
                    }
                },
                "orderable": false,
            },
            // MODIFY
    		{
                "width": "50px",
                "targets": 5,
    			"render": function ( data, type, row ) {
                    return '<button class="btn w-100 p-0" id="publicite-open-modify-'+row[0]+'" data-category="web"><i class="fad fa-edit"></i>';
                },
                "orderable": false,
    		},
    	],
        "conditionalPaging": true,
    });

    // Envoi de modification d'une publicité
    $('body').on('click', "button[id^='publicite-send-modify-']", function() {
        var clickedId = this.id.split(/[- ]+/).pop();

        var form = new FormData();
        // On extrait le contenu du formulaire dans la variable 'form'
        form.append('id', clickedId);
        form.append('publicite_title', $('#publicite_title_'+clickedId).val());
        form.append('publicite_subtitle', $('#publicite_subtitle_'+clickedId).val());
        form.append('publicite_isvisible', $('input[name="publicite_visible_'+clickedId+'"]:checked').val());
        form.append('publicite_link', $('#publicite_link_'+clickedId).val());
        form.append('publicite_ordre', $('select[name="publicite_ordre_'+clickedId+'"]').val());
        form.append('publicite_site', $('select[name="publicite_site_'+clickedId+'"]').val());
        form.append('publicite_image', $('#file_result_0').val());

        // Requète ajax d'envoi du formulaire : /src/Controller/AdminController.php -> admin_publicites()
        $.ajax({
            type: 'POST',
            url: siteURL + 'admin/web/publicite/modify',
            cache: false,
            contentType: false,
            processData: false,
            data: form,
            success: function(data) {
                // Si il s'agit de l'ajout d'une nouvelle publicité
                if (clickedId == 0) {
                    toastr.success('La nouvelle publicité a bien été enregistrée', 'Succès');
                    $('#formpublicite_0')[0].reset();

                    $("[id^='file_upload_0_']","[id^='file_result_0_']").each( function() {$(this).val('')});
                    $("[id^='file_display_0_").each( function() {$(this).html('')});
                }
                // Si il s'agit d'une modification de publicité
                else {
                    toastr.success('La publicité a bien été modifiée', 'Succès');
                    // On nettoye le html en supprimant l'onglet 'Modifier/Nouveau'
                    $('#publicite-tabs-modify-'+clickedId).remove();
                    $('#publicite-close-modify-'+clickedId).parent().remove();
                }
                // Retour à la liste d'actualités en rechargeant la datatable
                publicite_table.ajax.reload();
                $('#publicite-tabs-list-tab').trigger('click');
            }
        });
        // Empèche le 'button' de fonctionner
        return false;
    });
});