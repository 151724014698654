let etatpreparationvehicule_table = null;
let timerBeforeRefreshDataEtatPreparationVehicule = null;

$(document).ready(function() {
    if ($("#etat_preparation_vehicule_results").length) {
        colvisFunOnEvent();

        function loadAjax() {
            let optionsRecherche = optionsRecherchesDataForm();
            $('#etat_preparation_vehicule_loader').removeClass('d-none');
            $('.card-body').addClass('d-none');
            $("#etat_preparation_vehicule_results").addClass("d-none");
            $.ajax({
                type:'POST',
                url: siteURL + 'admin/preparation/etat_preparation_vehicule_ajax',
                contentType: false,
                processData: false,
                data: optionsRecherche,
                success: function(data){
                    $("#etat_preparation_vehicule_results").html(data);
                    // Génération de la datatable des generateur reseaux sociaux
                    etatpreparationvehicule_table = $('#etat_preparation_vehicule_table').DataTable({
                        "dom": dataTablesCustomDom(),
                        "buttons": [dataTablesColvisBtn('th:nth-child(n+2)')],
                        "autoWidth": false,
                        "language": { "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/fr-FR.json" },
                        "paging": true,
                        "pageLength": 25,
                        "responsive": true,
                        "order": [[0, "desc"]],
                        "conditionalPaging": true,
                        "columnDefs": [{ "className": "text-center align-middle", "targets": '_all' }].push(...getColumnDefsType("etat_preparation_vehicule_table")),
                        "columns": getColvisColumn("etat_preparation_vehicule_table")
                    });
                    $('#etat_preparation_vehicule_table').on('init.dt', function () { initMirrorBtn(); setColvisTable(etatpreparationvehicule_table); colvisFunSelectRefresh(); });
                    $("#etat_preparation_vehicule_results").removeClass("d-none");
                    $('#etat_preparation_vehicule_loader').addClass('d-none');
                    $('.card-body').removeClass('d-none');
                }
            })
        };

        setupLoadAjax(loadAjax);
        setupResetFilter(resetFilter);

        $('body').on('change', "[id^='filtersetatpreparationvehicule-']", function() {
            clearTimeout(timerBeforeRefreshDataEtatPreparationVehicule);
            timerBeforeRefreshDataEtatPreparationVehicule = setTimeout(loadAjax, 500);
        });

        $('body').on('click mouseup', "[id^='filtersetatpreparationvehicule-']", function() {
            clearTimeout(timerBeforeRefreshDataEtatPreparationVehicule);
            timerBeforeRefreshDataEtatPreparationVehicule = setTimeout(loadAjax, 500);
        });

        // Bouton reset
        $('body').on('click', '#filtersetatpreparationvehicule-reset', function() {
            resetFilter();
            loadAjax();
        });

        // On charge les modèles en fonction des marques
        $('body').on('change', "#filtersetatpreparationvehicule-marque", loadModele);

        function optionsRecherchesDataForm() {
            let optionsRecherche = new FormData();
            optionsRecherche.append('marque', $("#filtersetatpreparationvehicule-marque").val());
            optionsRecherche.append('modele', $("#filtersetatpreparationvehicule-modele").val());
            optionsRecherche.append('energie', $("#filtersetatpreparationvehicule-energie").val());
            optionsRecherche.append('jourstock', $("#filtersetatpreparationvehicule-jourstock").val());
            optionsRecherche.append('parc', $("#filtersetatpreparationvehicule-parc").val().flatMap((v) => v.split(",")).distinct());
            optionsRecherche.append('etudeVF', $("#filtersetatpreparationvehicule-etudeVF").val());
            optionsRecherche.append('paiement', $("#filtersetatpreparationvehicule-paiement").val());
            optionsRecherche.append('transport', $("#filtersetatpreparationvehicule-transport").val());
            optionsRecherche.append('reception', $("#filtersetatpreparationvehicule-reception").val());
            optionsRecherche.append('expertise', $("#filtersetatpreparationvehicule-expertise").val());
            optionsRecherche.append('photo', $("#filtersetatpreparationvehicule-photo").val());
            optionsRecherche.append('preparation', $("#filtersetatpreparationvehicule-preparation").val());
            optionsRecherche.append('entretien', $("#filtersetatpreparationvehicule-entretien").val());
            optionsRecherche.append('facturation', $("#filtersetatpreparationvehicule-facturation").val());
            optionsRecherche.append('dateDebutClotureReception', (new Date($("#filtersetatpreparationvehicule-dateDebutClotureReception").val())).toLocaleDateString("fr"));
            optionsRecherche.append('dateFinClotureReception', (new Date($("#filtersetatpreparationvehicule-dateFinClotureReception").val())).toLocaleDateString("fr"));
            optionsRecherche.append('dateDebutClotureExpertise', (new Date($("#filtersetatpreparationvehicule-dateDebutClotureExpertise").val())).toLocaleDateString("fr"));
            optionsRecherche.append('dateFinClotureExpertise', (new Date($("#filtersetatpreparationvehicule-dateFinClotureExpertise").val())).toLocaleDateString("fr"));
            return optionsRecherche;
        }

        function resetFilter() {
            $("#filtersetatpreparationvehicule-marque").val('default').selectpicker("refresh");
            $("#filtersetatpreparationvehicule-modele").find("optgroup").prop('disabled', true);
            $('#filtersetatpreparationvehicule-modele').prop("disabled", true);
            $("#filtersetatpreparationvehicule-modele").val('default').selectpicker("refresh");
            $("#filtersetatpreparationvehicule-energie").val('default').selectpicker("refresh");
            $("#filtersetatpreparationvehicule-jourstock").val('default').selectpicker("refresh");
            $("#filtersetatpreparationvehicule-parc").val('default').selectpicker("refresh");
            $("#filtersetatpreparationvehicule-etudeVF").val('default').selectpicker("refresh");
            $("#filtersetatpreparationvehicule-paiement").val('default').selectpicker("refresh");
            $("#filtersetatpreparationvehicule-transport").val('default').selectpicker("refresh");
            $("#filtersetatpreparationvehicule-reception").val('default').selectpicker("refresh");
            $("#filtersetatpreparationvehicule-expertise").val('default').selectpicker("refresh");
            $("#filtersetatpreparationvehicule-photo").val('default').selectpicker("refresh");
            $("#filtersetatpreparationvehicule-preparation").val('default').selectpicker("refresh");
            $("#filtersetatpreparationvehicule-facturation").val('default').selectpicker("refresh");
            $("#filtersetatpreparationvehicule-dateDebutClotureReception").val('').selectpicker("refresh");
            $("#filtersetatpreparationvehicule-dateFinClotureReception").val('').selectpicker("refresh");
            $("#filtersetatpreparationvehicule-dateDebutClotureExpertise").val('').selectpicker("refresh");
            $("#filtersetatpreparationvehicule-dateFinClotureExpertise").val('').selectpicker("refresh");
        }

        function loadModele() {
            if ($('#filtersetatpreparationvehicule-marque').val().length != 0) {
                $("#filtersetatpreparationvehicule-modele").prop('disabled', false);
                $('#filtersetatpreparationvehicule-marque').val().forEach(element => { $("#opt_" + element).prop('disabled', false); });
                $("[id^='opt_']").each(function () {
                    let marqueEnCours = $(this).attr('id').replace('opt_', '');
                    if (!$('#filtersetatpreparationvehicule-marque').val().includes(marqueEnCours)) {
                        $(this).prop('disabled', true);
                    }
                })
                $("#filtersetatpreparationvehicule-modele").selectpicker('refresh');
            } else {
                $('#filtersetatpreparationvehicule-modele').prop("disabled", true);
                $("#filtersetatpreparationvehicule-modele").find("optgroup").prop('disabled', true);
                $("#filtersetatpreparationvehicule-modele").selectpicker('refresh');
            }
        }

        filtreConfigFunOnEvent();
    }
})
