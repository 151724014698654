if ($("#widget-rappel-agenda").length) {
    function widgetRappelAgendaAjax() {
        // Requête ajax
        $.ajax({
            type: 'POST',
            url: siteURL + 'admin/widget/rappel-agenda',
            contentType: false,
            processData: false,
            success: function (data) {
                if (data != null) {
                    $("#rappel-agenda-results-div").html(data);
                }
                // On appelle la requête du prochain widget
                nextWidgetRequeteAjax();
            }
        });
    }
};