// On doit prendre tout les VF, sauf la ligne avec les id en "__id__"
// On fait d'abord les VF, car s'ils sont nouveaux, il nous faut leur ID pour rajouter sur l'offre
// On doit vérifier les inputs :
// - REGISTRATION DATE
// - YEAR
// - VIN
// - BRAND
// - MODEL
// - VERSION
// - GEARBOX
// - ENERGY
// - MILEAGE
// - DIN POWER
// - COLOR
// - TVA
// - TRANSPORT PRICE
// - BUYING PRICE
// - MARKET PRICE
// - DAMAGE
// - MALUS
// Si y en manque, on ajoute un is-invalid en class + toaster d'erreur avec les VIN des lignes incorrectes
// Si tout correct :
// - On boucle sur chaque ligne
// - On vérifie si l'id existe, si oui on update, sinon on créait
// - On créait ou update l'offre si elle existe
const ofCheck = (idOF, idField, ligneIncorrect) => {
    $("#liste_vehicule_fournisseur_offre_fournisseur_" + idOF + " [id^='" + idField + "']").each((i, e) => {
        const idVF = e.id.replace(idField, "");
        const ligne = parseInt($("#numero_ligne_" + idVF).html());
        if (e.getAttribute("data-idof") == idOF && idVF != "__id__") {
            if (e.value.length == 0) {
                $(e).addClass("is-invalid");
                ligneIncorrect.push(ligne);
            } else {
                if (idField == "vehicule_fournisseur_input_vin_" && (e.value.length != 17 || e.value.toLowerCase().match(/(\w)\1\1\1\1\1\1\1\1\1\1\1\1\1\1\1\1/g))) {
                    $(e).addClass('is-invalid');
                    ligneIncorrect.push(ligne);
                    toastr.error("The VIN \"" + e.value + "\" is incorrect. Please, enter a correct VIN.", 'Error');
                } else {
                    $(e).removeClass("is-invalid");
                }
            }
        }
    });
    return ligneIncorrect;
}
