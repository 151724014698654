// Génération du lien starterre
function starterreLinkGenerator(vehicule_fournisseur_marque, vehicule_fournisseur_modele, vehicule_fournisseur_boite, vehicule_fournisseur_carburant, vehicule_fournisseur_date_mec, vehicule_fournisseur_kilometrage) {
    // URL de base
    let url_starterre = "https://www.starterre.fr/recherche/?sortBy=price_asc";

    // Les marques passent en slug
    if (vehicule_fournisseur_marque.length != 0) {
        url_starterre = url_starterre + "&marque=" + (vehicule_fournisseur_marque.slugify() == "mercedes" ? "mercedes-benz" : vehicule_fournisseur_marque.slugify());
    }
    // Les modèles passent en slug
    if (vehicule_fournisseur_modele.length != 0) {
        url_starterre = url_starterre + "&modele=" + vehicule_fournisseur_modele.slugify();
    }
    // Les boites
    if (vehicule_fournisseur_boite.length != 0) {
        url_starterre = url_starterre + "&boite-vitesse=boite-" + (vehicule_fournisseur_boite == "A" ? "automatique" : "manuelle");
    }
    // Les carburants
    if (vehicule_fournisseur_carburant.length != 0) {
        url_starterre = url_starterre + "&energie=" + (vehicule_fournisseur_carburant == "ES" ? "essence" : (vehicule_fournisseur_carburant == "GO" ? "diesel" : (vehicule_fournisseur_carburant == "EH" ? "hybride-non-rechargeable&energie=hybride-rechargeable" : (vehicule_fournisseur_carburant == "EL" ? "electrique" : (vehicule_fournisseur_carburant == "GP" ? "gpl" : "")))));
    }
    // Kilométrage :
    // Arrondi à la dizaine de millier si ni essence et ni diesel
    //
    // kmPerYearMin = {essence -> 10000, diesel -> 17500}
    // kmPerYearMax = {essence -> 15000, diesel -> 25000}
    // distMin = (année actuel - année MEC) * kmPerYearMin
    // distMax = (année actuel - année MEC) * kmPerYearMax
    // Arrondi à la dizaine de millier si essence ou diesel et si le kilometrage est compris entre distMin et distMax
    // Si < distMin alors distMin
    // Si > distMax alors viellir le véhicule de (année actuel - (kilometrage du véhicule / kmPerYearMax))
    if (vehicule_fournisseur_kilometrage.length != 0) {
        if (vehicule_fournisseur_carburant != "ES" && vehicule_fournisseur_carburant != "GO" && vehicule_fournisseur_carburant != "EH") {
            url_starterre = url_starterre + "&kilometrage=" + Math.ceil(parseFloat(vehicule_fournisseur_kilometrage) / 10000) * 10000 + "%3A";
        } else if (vehicule_fournisseur_date_mec.length != 0) {
            yearDiff = (new Date().getFullYear()) - parseInt(vehicule_fournisseur_date_mec) + 1;
            if (vehicule_fournisseur_carburant == "ES" || vehicule_fournisseur_carburant == "EH") {
                let kmPerYearMin = 10000;
                let kmPerYearMax = 15000;
                distMin = yearDiff * kmPerYearMin;
                distMax = yearDiff * kmPerYearMax;
                if (parseFloat(vehicule_fournisseur_kilometrage) < distMin) {
                    vf_kilometrage_url = distMin;
                } else if (parseFloat(vehicule_fournisseur_kilometrage) <= distMax) {
                    vf_kilometrage_url = Math.ceil(parseFloat(vehicule_fournisseur_kilometrage) / 10000) * 10000;
                } else {
                    vf_kilometrage_url = Math.ceil(parseFloat(vehicule_fournisseur_kilometrage) / 10000) * 10000;
                    vehicule_fournisseur_date_mec = parseInt(vehicule_fournisseur_date_mec) - Math.floor(parseFloat(vehicule_fournisseur_kilometrage) / kmPerYearMax);
                }
            } else if (vehicule_fournisseur_carburant == "GO") {
                let kmPerYearMin = 17500;
                let kmPerYearMax = 25000;
                distMin = yearDiff * kmPerYearMin;
                distMax = yearDiff * kmPerYearMax;
                if (parseFloat(vehicule_fournisseur_kilometrage) < distMin) {
                    vf_kilometrage_url = distMin;
                } else if (parseFloat(vehicule_fournisseur_kilometrage) <= distMax) {
                    vf_kilometrage_url = Math.ceil(parseFloat(vehicule_fournisseur_kilometrage) / 10000) * 10000;
                } else {
                    vf_kilometrage_url = Math.ceil(parseFloat(vehicule_fournisseur_kilometrage) / 10000) * 10000;
                    vehicule_fournisseur_date_mec = parseInt(vehicule_fournisseur_date_mec) - Math.floor(parseFloat(vehicule_fournisseur_kilometrage) / kmPerYearMax);
                }
            }
            url_starterre = url_starterre + "&kilometrage=" + vf_kilometrage_url + "%3A";
        }
    }
    // Date de MEC
    if (vehicule_fournisseur_date_mec.length != 0) {
        url_starterre = url_starterre + "&years%5Bmin%5D=" + vehicule_fournisseur_date_mec;
    }

    return url_starterre;
}
