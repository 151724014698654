$(document).ready(function () {
    if ($("#annuaire_results").length) {
        // Update employeEntreprise form
        $('body').on('click', "[id^='annuaire-'][id*='-employeEntreprise-'][id$='-modify']", function (e) {
            e.preventDefault();
            let idAnnuaire = this.id.replace("annuaire-", "").replace(/-employeEntreprise-[\d]+-modify/g, "");
            let idEmployeEntreprise = this.id.replace("-modify", "").replace(/annuaire-[\d]+-employeEntreprise-/g, "");
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/annuaire/employeEntreprise/show_modify',
                data: { id: idEmployeEntreprise, idAnnuaire: idAnnuaire },
                success: function (data) {
                    $("#annuaire-tab-effectif-" + idAnnuaire + "-effectif").html(data);
                    $("#employeEntreprise_fonctionEmployeEntreprise_" + idAnnuaire).selectpicker();
                    $("#employeEntreprise_civilite_" + idAnnuaire).selectpicker();
                }
            });
        });

        // Erreur si seul contact principal
        $('body').on('click', "[id^='employeEntreprise_isContactPrincipal_container_']", function (e) {
            if ($("#" + this.id.replace("container_", "")).prop('disabled')) {
                toastr.error("L'employé est le seul contact de son entreprise.", 'Erreur');
            }
        });

        // Si contact principal
        $('body').on('change', "[id^='employeEntreprise_isContactPrincipal_']", function (e) {
            if ($(this).val() == "on") {
                toastr.warning("Un contact principal est déjà renseigné, à l'enregistrement le contact en cours d'édition deviendra le contact principal.", 'Alert');
            }
        });

        // Cancel modification
        $('body').on('click', "[id^='annuaire-'][id*='-employeEntreprise-'][id$='-cancel']", function (e) {
            e.preventDefault();
            let idAnnuaire = this.id.replace("annuaire-", "").replace(/-employeEntreprise-[\d]+-cancel/g, "");
            let form = new FormData();
            form.append('idAnnuaire', idAnnuaire);
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/annuaire/employeEntreprise/cancel',
                contentType: false,
                processData: false,
                data: form,
                success: function (data) {
                    $("#annuaire-tab-effectif-" + idAnnuaire + "-effectif").html(data);
                    annuaireStatsConnexionSetupAllExcept(idAnnuaire, idAnnuaire);
                }
            });
        });

        // Update employeEntreprise form
        $('body').on('click', "[id^='annuaire-'][id*='-employeEntreprise-'][id$='-save']", function (e) {
            e.preventDefault();
            let idAnnuaire = this.id.replace("annuaire-", "").replace(/-employeEntreprise-[\d]+-save/g, "");
            let idEmployeEntreprise = this.id.replace("-save", "").replace(/annuaire-[\d]+-employeEntreprise-/g, "");
            let employeEntreprise_fonctionEmployeEntreprise = $('#employeEntreprise_fonctionEmployeEntreprise_' + idAnnuaire).val();
            let employeEntreprise_civilite = $('#employeEntreprise_civilite_' + idAnnuaire).val();
            let employeEntreprise_nom = $('#employeEntreprise_nom_' + idAnnuaire).val();
            let employeEntreprise_prenom = $('#employeEntreprise_prenom_' + idAnnuaire).val();
            let employeEntreprise_email = $('#employeEntreprise_email_' + idAnnuaire).val();
            let employeEntreprise_telephone = $('#employeEntreprise_telephone_' + idAnnuaire).val();
            let employeEntreprise_isContactPrincipal = $('#employeEntreprise_isContactPrincipal_' + idAnnuaire).val();
            let employeEntreprise_communicationEmail = $('#employeEntreprise_communicationEmail_' + idAnnuaire).val();
            let employeEntreprise_communicationSms = $('#employeEntreprise_communicationSms_' + idAnnuaire).val();
            let employeEntreprise_communicationTelephone = $('#employeEntreprise_communicationTelephone_' + idAnnuaire).val();
            let employeEntreprise_communicationCourrier = $('#employeEntreprise_communicationCourrier_' + idAnnuaire).val();
            let employeEntreprise_communicationNewsletter = $('#employeEntreprise_communicationNewsletter_' + idAnnuaire).val();
            let error_message = "";
            if (employeEntreprise_fonctionEmployeEntreprise.length == 0) {
                error_message += "La fonction de l'employé n\'a pas été séléctionné.<br/>"
            }
            if (employeEntreprise_civilite.length == 0) {
                error_message += "La civilité n\'a pas été séléctionné.<br/>"
            }
            if (employeEntreprise_nom.length == 0) {
                error_message += "Le nom n\'a pas été renseigné.<br/>"
            }
            if (employeEntreprise_prenom.length == 0) {
                error_message += "Le prénom n\'a pas été renseigné.<br/>"
            }
            if (employeEntreprise_email.length == 0) {
                error_message += "L\'adresse email n\'a pas été renseigné.<br/>"
            } else {
                let mailReg = new RegExp(/^(([^<>()\/\[\]\\\\.,;:\s@"\'!$]+(\.[^<>()\/\[\]\\\\.,;:\s@"\'!$]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/g);
                if (!mailReg.test(employeEntreprise_email)) {
                    error_message += "L\'adresse email renseigné n\'est pas valide.<br/>";
                }
            }
            if (error_message != "") {
                toastr.error(error_message, 'Erreur');
            } else {
                let form = new FormData();
                form.append('id', idEmployeEntreprise);
                form.append('idAnnuaire', idAnnuaire);
                form.append('employeEntreprise_fonctionEmployeEntreprise', employeEntreprise_fonctionEmployeEntreprise);
                form.append('employeEntreprise_civilite', employeEntreprise_civilite);
                form.append('employeEntreprise_nom', employeEntreprise_nom);
                form.append('employeEntreprise_prenom', employeEntreprise_prenom);
                form.append('employeEntreprise_email', employeEntreprise_email);
                form.append('employeEntreprise_telephone', employeEntreprise_telephone);
                form.append('employeEntreprise_isContactPrincipal', employeEntreprise_isContactPrincipal);
                form.append('employeEntreprise_communicationEmail', employeEntreprise_communicationEmail);
                form.append('employeEntreprise_communicationSms', employeEntreprise_communicationSms);
                form.append('employeEntreprise_communicationTelephone', employeEntreprise_communicationTelephone);
                form.append('employeEntreprise_communicationCourrier', employeEntreprise_communicationCourrier);
                form.append('employeEntreprise_communicationNewsletter', employeEntreprise_communicationNewsletter);
                $.ajax({
                    type: 'POST',
                    url: siteURL + 'admin/annuaire/employeEntreprise/modify',
                    contentType: false,
                    processData: false,
                    data: form,
                    success: function (data) {
                        if (data == "error") {
                            toastr.error("Email déjà utilisé", 'Erreur');
                        } else {
                            toastr.success('L\'employé a bien été enregistré', 'Success');
                            $("#annuaire-tab-effectif-" + idAnnuaire + "-effectif").html(data);
                            annuaireStatsConnexionSetupAllExcept(idAnnuaire, idAnnuaire);
                        }
                    }
                });
            }
        });

        // Update employeEntreprise form
        $('body').on('click', "[id^='annuaire-'][id*='-employeEntreprise-'][id$='-delete']", function (e) {
            e.preventDefault();
            let idAnnuaire = this.id.replace("annuaire-", "").replace(/-employeEntreprise-[\d]+-delete/g, "");
            let idEmployeEntreprise = this.id.replace("-delete", "").replace(/annuaire-[\d]+-employeEntreprise-/g, "");
            Lobibox.confirm({
                title: 'Suppression',
                baseClass: "modal-content modal-lobistrap",
                msg: "Êtes-vous sûr de vouloir supprimer cet élément <strong>définitivement</strong> de la base de données ? Attention, il sera <strong>impossible</strong> de le restaurer ensuite.",
                buttons: {
                    cancel: {
                        type: 'ok',
                        text: 'Supprimer'
                    },
                    ok: {
                        type: 'cancel',
                        text: 'Conserver'
                    }
                },
                callback: function (lobibox, type) {
                    if (type === 'cancel') {
                        $.ajax({
                            type: 'POST',
                            url: siteURL + 'admin/annuaire/employeEntreprise/delete',
                            data: { id: idEmployeEntreprise },
                            success: function (data) {
                                $("#annuaire-tab-effectif-" + idAnnuaire + "-effectif").html(data);
                                $('#employeEntreprise_fonctionEmployeEntreprise_' + idAnnuaire).val('').selectpicker('refresh');
                                $('#employeEntreprise_civilite_' + idAnnuaire).val('').selectpicker('refresh');
                                $('#employeEntreprise_nom_' + idAnnuaire).val('');
                                $('#employeEntreprise_prenom_' + idAnnuaire).val('');
                                $('#employeEntreprise_email_' + idAnnuaire).val('');
                                $('#employeEntreprise_telephone_' + idAnnuaire).val('');
                                $("#annuaire-sub-tab-effectif-" + idAnnuaire).trigger('click');
                            }
                        });
                    }
                },
            });
        });

    }
});
