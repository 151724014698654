let freForfait_table = null;
$(document).ready(function () {
    if ($("#freForfait-results").length) {
        function loadAjax() {
            $("#freForfait-results").addClass("d-none");
            $("#freForfait-loader").removeClass("d-none");
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/fre/freForfait/ajax',
                contentType: false,
                processData: false,
                data: {},
                success: function (data) {
                    $("#freForfait-results").html(data);
                    freForfait_table = $('#freForfait-table').DataTable({
                        "autoWidth": false,
                        "language": {
                            "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/fr-FR.json"
                        },
                        "paging": true,
                        "responsive": true,
                        "order": [
                            [0, "asc"]
                        ],
                        "columnDefs": [
                            {
                                "targets": 4,
                                "orderable": false,
                            },
                            { type: 'num', targets: [0] },
                        ],
                        "conditionalPaging": true,
                    });
                    $('#freForfait-table').on('draw.dt', function () {
                        $("#freForfait-loader").addClass("d-none");
                        $("#freForfait-results").removeClass("d-none");
                    });
                }
            })
        };

        // Envoi de modification d'un freForfait
        $('body').on('click', "[id^='freForfait-send-modify-']", function (e) {
            e.preventDefault();
            let id_clicked = this.id.replace('freForfait-send-modify-', '');
            let freForfait_label = $('#freForfait-label-' + id_clicked).val();
            let freForfait_frePrestation = getPrestation(id_clicked);
            let error_message = "";
            if (freForfait_label.length == 0) {
                error_message += "Le libellé du forfait n\'a pas été renseigné.<br/>"
            }
            if (freForfait_frePrestation.length == 0) {
                error_message += "Les prestations du forfait n\'ont pas été renseignés.<br/>"
            }
            if (error_message != "") {
                toastr.error(error_message, 'Erreur');
            } else {
                let form = new FormData();
                form.append('freForfait_id', id_clicked);
                form.append('freForfait_label', freForfait_label);
                form.append('freForfait_frePrestation', freForfait_frePrestation);
                form.append('freForfait_label_traduit', getLabelTraduit(id_clicked, "Forfait"));
                $.ajax({
                    type: 'POST',
                    url: siteURL + 'admin/fre/freForfait/modify',
                    contentType: false,
                    processData: false,
                    data: form,
                    success: function (data) {
                        toastr.success('Le forfait a bien été enregistrée', 'Succès');
                        $("#freForfait-tabs-add").html(data);
                        $('#freForfait-tabs-modify-' + id_clicked).remove();
                        $('#freForfait-close-modify-' + id_clicked).parent().remove();
                        $('#freForfait-tabs-list-tab').trigger('click');
                    },
                    error: function () {
                        toastr.error('Une erreur s\'est produite.', 'Erreur');
                    }
                });
            }
        });

        // Filtre les prestations
        function filtrePrestation(id) {
            let search = $("#freForfait-search-" + id).val().slugify();
            let selected = $("#freForfait-selected-" + id).is(":checked");
            $("[name^='freForfait-searchItem-" + id + "-']").toArray()
                .map(function (el) { el.classList.add("d-none"); return el; })
                .filter((el) => el.getAttribute("data-label").slugify().includes(search) && (selected ? $(el).find("#" + el.getAttribute("name")).is(":checked") : true))
                .map((el) => el.classList.remove("d-none")).length;
        }

        // Retourne les prestations sélectionnés
        function getPrestation(id) {
            return $("input[type='checkbox'][id^='freForfait-searchItem-" + id + "-']").toArray()
                .filter((el) => $(el).is(":checked"))
                .map((el) => el.id.replace("freForfait-searchItem-" + id + "-", ""));
        }

        $('body').on('keyup change', "[id^='freForfait-search-']", function (e) { filtrePrestation(this.id.replace('freForfait-search-', '')); });
        $('body').on('change', "[id^='freForfait-selected-']", function (e) { filtrePrestation(this.id.replace('freForfait-selected-', '')); });

        // Bouton refresh
        $('body').on('click', "#freForfait-tabs-list-tab", function () { loadAjax(); });
        loadAjax();
    }
});