/*
  /admin/vehicules/listing
  /admin/vehicules/marchand
  /admin/vehicules/particulier
 */

// /admin/vehicules/listing
// selectpicker()
$('select[id^="vehicules_reservationtable"]').selectpicker();
// dataTable()
vehicules_reservation_table = $('#vehicules_reservation_table').DataTable({
    "autoWidth": false,
    "language": {
        "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/fr-FR.json"
    },
    "responsive": true,
    "order": [
        [10, "desc"], [1, "desc"]
    ],
    "columnDefs": [
        // TEXT CENTER
        {
            "className": "text-center align-middle",
            "targets": [0,1,5,6,7,8,9,10],
        },
        // ALIGN MIDDLE
        {
            "className": "align-middle",
            "targets": '_all',
        },
        // CLICK AND LEASE
        {
            "width": "25px",
            "targets": 0,
            "orderable": false,
        },

    ],
    "pageLength": 50,
    "conditionalPaging": true,
    "initComplete": function() {
        $(this).show();
        $('#vehicules_particulier_table_loading').remove();
        $('#vehicules_particulier_collapse').click();
    }
});
// Champs de recherche
$('[id^="vehicules_reservationtable-"').on( 'change keyup', function () {
    column = this.id.replace('vehicules_reservationtable-', '');

    if (column == 2) {
        var marque = this.value;
        if (marque != "") {
            var form = new FormData();
            form.append('marque', marque);

            $.ajax({
                type: 'POST',
                url: siteURL + "json/modeles_marque",
                data: form,
                processData: false,
                contentType: false,
                success: function(data, status) {
                    $('#vehicules_reservationtable-3').empty();
                    $('#vehicules_reservationtable-3').append('<option value="">Tous les modèles</option>');
                    for (id in data) {
                        if (data[id]['nb'] > 0) {
                            $('#vehicules_reservationtable-3').append("<option value='" + id + "'>" + id + "</option>");
                        }
                    };
                    $('#vehicules_reservationtable-3').val('default').selectpicker("refresh");
                }
            });
            vehicules_reservation_table.column(3).search( '' ).draw();
            vehicules_reservation_table.column( column ).search(
                '^'+$(this).val()+'$',
                true,
                false,
            ).draw();
        } else {
            vehicules_reservation_table.column(3).search( '' ).draw();
            $('#vehicules_reservationtable-3').empty();
            $('#vehicules_reservationtable-3').append('<option value="">Tous les modèles</option>');
            $('#vehicules_reservationtable-3').val('default').selectpicker("refresh");
            vehicules_reservation_table.column(2).search( '' ).draw();
        }
    }
    if (column == 9 && $(this).val() == "AUTRES") {
        terms = [
            $.fn.dataTable.util.escapeRegex( "ORA 1" ),
            $.fn.dataTable.util.escapeRegex( "DEPOTSEREZIN" ),
            $.fn.dataTable.util.escapeRegex( "TAMPON SEREZIN" ),
            $.fn.dataTable.util.escapeRegex( "MANDAT SEREZIN" ),
            $.fn.dataTable.util.escapeRegex( "ORA 2" ),
            $.fn.dataTable.util.escapeRegex( "DEPOTNEUVILLE" ),
            $.fn.dataTable.util.escapeRegex( "TAMPON NEUVILLE" ),
            $.fn.dataTable.util.escapeRegex( "MANDAT NEUVILLE" ),
            $.fn.dataTable.util.escapeRegex( "ORA 6" ),
            $.fn.dataTable.util.escapeRegex( "DEPOTMONTELIMAR" ),
            $.fn.dataTable.util.escapeRegex( "TAMPON MONTELIMAR" ),
            $.fn.dataTable.util.escapeRegex( "MANDAT MONTELIMAR" ),
        ]

        vehicules_reservation_table.column( column ).search("");

        $.fn.dataTable.ext.search.push(
            function(settings, data, dataIndex) {
            for (term in terms) {
                if (data[column] == terms[term]) {
                    return false;
                }
            }
            return true;
        }
      );
      vehicules_reservation_table.draw();
      $.fn.dataTable.ext.search.pop();
    }
    else {
        vehicules_reservation_table.column( column ).search(
            $(this).val(), true, false
        ).draw();
    }
} );
// Bouton reset
$('body').on('click', '#vehicules_reservation-reset', function() {
    $("#vehicules_reservationtable-1").val('').selectpicker("refresh");
    $("#vehicules_reservationtable-2").val('default').selectpicker("refresh");
    $("#vehicules_reservationtable-3").val('default').selectpicker("refresh");
    $("#vehicules_reservationtable-5").val('default').selectpicker("refresh");
    $("#vehicules_reservationtable-9").val('default').selectpicker("refresh");
    vehicules_reservation_table.columns().search( '' ).draw();
    return false;
});

$('body').on('click', '[id^="vehicule_reservation_"]', function() {
    clickedId = this.id.replace("vehicule_reservation_", "");
    if ($(this).hasClass('fa-check-square')) { var reservationEtat = 0;}
    else { var reservationEtat = 1;}

    var form = new FormData();
    form.append('id', clickedId);
    form.append('reservation', reservationEtat);
    $.ajax({
        type: 'POST',
        url: siteURL + 'admin/vehicules/reservation',
        contentType: false,
        processData: false,
        data: form,
        success: function(data) {
            if (data == 0) {
                toastr.error('Le véhicule n\'est maintenant plus indiqué comme réservé', 'Succès');
            }
            else if (data == 1) {
                toastr.success('Le véhicule est maintenant indiqué comme réservé', 'Succès');
            }
        }
    });
    $(this).toggleClass('fa-check-square fa-square');
})