$(document).ready(function () {
    if ($("[id^='formvehicule_fournisseur_']").length || $("#vehicule_fournisseur_results").length || $("#vehicule_fournisseur_achete_results").length || $("#offre_fournisseur_results").length) {
        $('body').on('change', "[id^='vehicule_fournisseur_input_marque_']", updateMechanicalDamage);
        $('body').on('change', "[id^='vehicule_fournisseur_input_modele_']", updateMechanicalDamage);
        $('body').on('change', "[id^='vehicule_fournisseur_input_carburant_']", updateMechanicalDamage);
        $('body').on('change', "[id^='vehicule_fournisseur_input_year_']", updateMechanicalDamage);
        $('body').on('change', "[id^='vehicule_fournisseur_input_kilometrage_']", updateMechanicalDamage);
        $('body').on('change', "[id^='vehicule_fournisseur_input_date_mec_']", updateMechanicalDamage);

        // Click quick expertise
        $('body').on('click', "[id^='vehicule_fournisseur_quick_expertise_']", function () { updateBodyworkDamage(this.id.replace(/\D/gm, "")); });

        // Change full expertise
        $('body').on('change', "[name='expertise-dialog']", function (e) {
            if ($(e.target).data("expertise") != undefined) {
                let idVF = e.target.id.replace("expertise-dialog-", "").replace("-dialog", "");
                $("#vehicule_fournisseur-expertise-" + idVF).data("expertise", $(e.target).data("expertise"));
            }
        });

        // Ouverture de l'expertise
        $('body').on('click', "[id^='btnGroupFRE_']", function () {
            const idVF = this.id.replace("btnGroupFRE_", "");
            if (!this.hasAttribute("data-toggle") && !this.hasAttribute("data-target")) {
                $("#expertise-dialog-" + idVF + "-dialog").data("expertise", $("#vehicule_fournisseur-expertise-" + idVF).data("expertise"));
                $("#expertise-dialog-" + idVF + "-open").trigger("click");
            }
        });

        // Ouverture de l'expertise
        $('body').on('click', "[id^='vehicule_fournisseur_open_expertise_']", function () {
            console.log("pourquoi ?");
            const idVF = this.id.replace("vehicule_fournisseur_open_expertise_", "");
            $("#vehicule_fournisseur_back_expertise_" + idVF).attr("disabled", "");
            $("#vehicule_fournisseur_open_expertise_" + idVF).attr("disabled", "");
            $("#vehicule_fournisseur_open_expertise_" + idVF).html("<i class='fas fa-spinner fa-spin'></i>" + $("#vehicule_fournisseur_open_expertise_" + idVF).html());
            let options = new FormData();
            options.append('id', idVF);
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/vehicule_fournisseur/update/fullExpertise',
                contentType: false,
                processData: false,
                data: options,
                success: function () {
                    $("[id='btnGroupQEX_" + idVF + "']").each((i, e) => { $(e).addClass("d-none"); });
                    $("[id='btnGroupFRE_" + idVF + "']").each((i, e) => { $(e).removeAttr('data-toggle'); $(e).removeAttr('data-target'); });
                    $("#vehicule_fournisseur_full_expertise_" + idVF).modal('hide');
                    $("#expertise-dialog-" + idVF + "-dialog").data("expertise", $("#vehicule_fournisseur-expertise-" + idVF).data("expertise"));
                    $("#expertise-dialog-" + idVF + "-open").trigger("click");
                }
            });
        });

        // Update of mirror input "bodywork damage"
        $('body').on('change keyup', "[id^='vehicule_fournisseur_input_bodywork_damage_ht_']", function (e) {
            const newValue = $(this).val();
            $("[id^='" + this.id + "']").each((i, e) => {
                $(e).val(newValue);
            });
            // Lance l'update des frais estimés
            updateFraisTotal(this.id.replace("vehicule_fournisseur_input_bodywork_damage_ht_", ""));
        });
    }
});
