/**
 * Update les calculs relatifs au marge
 * @param {Event} e
 * @returns {any}
 */
const updateCalculVF = (e) => {
    // modifiedInput : identifie l'input modifié
    let modifiedInput = e.target.id.replace("vehicule_fournisseur_input_", "").replace(/_[\d]+/g, "");
    // idVF : extrait l'identification du véhicule fournisseur (0 si nouveau véhicule)
    let idVF = '';
    if (modifiedInput.includes('__id__')) {
        modifiedInput = modifiedInput.replace('___id__', '');
        idVF = "__id__";
    } else {
        idVF = e.target.id.replace("vehicule_fournisseur_input_" + modifiedInput + "_", "");
    }
    // Calcul si on est dans une offre fournisseur de l'id offre fournisseur
    let idOF = '';
    if ($('#offre_fournisseur_information_container').length == 1) {
        idOF = $('#ligne_vehicule_fournisseur_' + idVF).attr('data-idof');
        if ($(e.target).hasClass('is-invalid')) {
            $(e.target).removeClass('is-invalid');
            if (e.target.id == 'vehicule_fournisseur_input_date_mec_' + idVF) {
                $('#vehicule_fournisseur_input_year_' + idVF).removeClass('is-invalid');
            }
            if (e.target.id == 'vehicule_fournisseur_input_modele_' + idVF) {
                $('#vehicule_fournisseur_input_modele_' + idVF).parent().removeClass('is-invalid');
            }
        }
    }
    // Pour savoir si on est dans un véhicule fournisseur ou une offre fournisseur
    let nomInputFournisseur = '';
    if ($('#offre_fournisseur_information_container').length == 1) {
        nomInputFournisseur = 'offre_fournisseur_input_fournisseur_' + idOF;
    } else {
        nomInputFournisseur = 'vehicule_fournisseur_input_fournisseur_' + idVF;
    }
    if ($("#" + nomInputFournisseur).length && $($("#" + nomInputFournisseur)[0].selectedOptions[0]).attr("data-devisename") != undefined) {
        $("#btnGroupDevise_" + idVF).html("<div css-tooltip>" + $($("#" + nomInputFournisseur)[0].selectedOptions[0]).attr("data-devisename") + "<div css-tooltip class='text-nowrap'> 1 EUR = " + $($("#" + nomInputFournisseur)[0].selectedOptions[0]).attr("data-devise") + " " + $($("#" + nomInputFournisseur)[0].selectedOptions[0]).attr("data-devisename") + "</div></div>");
        // Calcul des frais de plateforme en € HT et TTC
        $("#vehicule_fournisseur_input_fraisPlateforme_ht_" + idVF).val((parseFloat($($("#" + nomInputFournisseur)[0].selectedOptions[0]).attr("data-fraisplateforme")) / getDevVF(nomInputFournisseur)).toFixed(2));
        $("#vehicule_fournisseur_input_fraisPlateforme_ttc_" + idVF).val(((parseFloat($($("#" + nomInputFournisseur)[0].selectedOptions[0]).attr("data-fraisplateforme")) / getDevVF(nomInputFournisseur)) * 1.2).toFixed(2));
    }
    if ($("#vehicule_fournisseur_input_lien_annonce_fournisseur_" + idVF).val() !== "") {
        $("#btnGroupLienAnnonceFournisseur_" + idVF).html("<a href=\"" + $("#vehicule_fournisseur_input_lien_annonce_fournisseur_" + idVF).val() + "\" target=\"_blank\"><i class=\"fas fa-eye text-primary\"></i></a>");
    } else {
        $("#btnGroupLienAnnonceFournisseur_" + idVF).html("<a target=\"_blank\"><i class=\"fas fa-eye text-primary\"></i></a>");
    }
    if ($('#offre_fournisseur_information_container').length == 0) {
        vfDataCompilationGenerator(idVF);
    }
    vfLinkGenerator(idVF);

    // Update des calculs
    updatePrixAchatVF(idVF, nomInputFournisseur);
    updateFreVF(idVF);
    updatePrixTransportVF(idVF);
    updatePrixRevientMarchandVF(idVF);
    updatePrixRevientParticulierVF(idVF);
    if (modifiedInput != "prix_proposition") { updatePrixPropositionVF(idVF); }
    updateMargeVF(idVF);
    // Update du statut
    calculQuickStudyStatus(idVF);
}

/**
 * Update le prix d'achat
 * @param {string} idVF
 * @param {string} nomInputFournisseur
 */
const updatePrixAchatVF = (idVF, nomInputFournisseur) => {
    if (getDevVF(nomInputFournisseur) !== "" && getPmlVF(idVF) !== "") {
        $("[id^='vehicule_fournisseur_input_prix_achat_ht_" + idVF + "']").each(function (i, e) {
            $(e).val(
                (
                    getPmlVF(idVF)
                    / getDevVF(nomInputFournisseur)
                ).toFixed(2)
            );
        });
        if (getTvaVF(idVF) !== "") {
            $("[id^='vehicule_fournisseur_input_prix_achat_ttc_" + idVF + "']").each(function (i, e) {
                $(e).val(
                    (
                        (
                            getPmlVF(idVF)
                            / getDevVF(nomInputFournisseur)
                        )
                        * getTvaVF(idVF)
                    ).toFixed(2)
                );
            });
        }
    } else {
        $("#vehicule_fournisseur_input_prix_achat_ht_" + idVF).val("");
        $("#vehicule_fournisseur_input_prix_achat_ttc_" + idVF).val("");
    }
}

/**
 * Update les fre
 * @param {string} idVF
 */
const updateFreVF = (idVF) => {
    if (getFreVF(idVF) !== "") {
        $("#vehicule_fournisseur_input_fre_ttc_" + idVF).val(
            (
                getFreVF(idVF)
                * 1.2
            ).toFixed(2)
        );
    } else {
        $("#vehicule_fournisseur_input_fre_ttc_" + idVF).val("");
    }
}

/**
 * Update le transport
 * @param {string} idVF
 */
const updatePrixTransportVF = (idVF) => {
    if (getTptVF(idVF) !== "") {
        $("#vehicule_fournisseur_input_prix_transport_ttc_" + idVF).val(
            (
                getTptVF(idVF)
                * 1.2
            ).toFixed(2)
        );
    } else {
        $("#vehicule_fournisseur_input_prix_transport_ttc_" + idVF).val("");
    }
}

/**
 * Update le prix de revient à marchand
 * @param {string} idVF
 */
const updatePrixRevientMarchandVF = (idVF) => {
    if (getAchVF(idVF, "ttc") !== "" && getTptVF(idVF, "ttc") !== "") {
        $("#vehicule_fournisseur_input_prix_revient_marchand_ttc_" + idVF).val(
            (
                getAchVF(idVF, "ttc")
                + getTptVF(idVF, "ttc")
                + getPltVF(idVF)
            ).toFixed(2)
        );
    } else {
        $("#vehicule_fournisseur_input_prix_revient_marchand_ttc_" + idVF).val("");
    }
}

/**
 * Update le prix de revient à particulier
 * @param {string} idVF
 */
const updatePrixRevientParticulierVF = (idVF) => {
    if (getAchVF(idVF, "ttc") !== "" && getTptVF(idVF, "ttc") !== "" && getFreVF(idVF, "ttc") !== "" && getMalVF(idVF) !== "") {
        $("#vehicule_fournisseur_input_prix_revient_particulier_ttc_" + idVF).val(
            (
                getAchVF(idVF, "ttc")
                + getTptVF(idVF, "ttc")
                + getFreVF(idVF, "ttc")
                + getMalVF(idVF)
                + getPltVF(idVF)
            ).toFixed(2)
        );
    } else {
        $("#vehicule_fournisseur_input_prix_revient_particulier_ttc_" + idVF).val("");
    }
}

/**
 * Update le prix de proposition
 * @param {string} idVF
 */
const updatePrixPropositionVF = (idVF) => {
    if (getPrmVF(idVF) !== "") {
        $("#vehicule_fournisseur_input_prix_proposition_" + idVF).val(
            (
                (
                    Math.ceil(
                        (
                            getPrmVF(idVF)
                            + 600
                        )
                        / 100
                    )
                )
                * 100
            ).toFixed(2)
        );
    } else {
        $("#vehicule_fournisseur_input_prix_proposition_" + idVF).val("");
    }
}

/**
 * Update la marge
 * @param {string} idVF
 */
const updateMargeVF = (idVF) => {
    if (getAchVF(idVF, "ttc") !== "" && getTptVF(idVF, "ttc") !== "" && getFreVF(idVF, "ttc") !== "" && getMalVF(idVF) !== "" && getMrcVF(idVF) !== "") {
        // Margin (TTC)
        $("#vehicule_fournisseur_input_marge_ttc_" + idVF).val(
            (
                (
                    (
                        getMrcVF(idVF)
                        - (
                            getAchVF(idVF, "ttc")
                            + getTptVF(idVF, "ttc")
                            + getFreVF(idVF, "ttc")
                            + getPltVF(idVF)
                        )
                    )
                    / 1
                )
                - getMalVF(idVF)
            ).toFixed(2)
        );
        // Margin (HT)
        $("#vehicule_fournisseur_input_marge_ht_" + idVF).val(
            (
                (
                    (
                        getMrcVF(idVF)
                        - (
                            getAchVF(idVF, "ttc")
                            + getTptVF(idVF, "ttc")
                            + getFreVF(idVF, "ttc")
                            + getPltVF(idVF)
                        )
                    )
                    / 1.2
                )
                - getMalVF(idVF)
            ).toFixed(2)
        );
    } else {
        $("#vehicule_fournisseur_input_marge_ttc_" + idVF).val("");
        $("#vehicule_fournisseur_input_marge_ht_" + idVF).val("");
    }
    if ($("#vehicule_fournisseur_marge_ht_" + idVF).length) {
        $("#vehicule_fournisseur_marge_ht_" + idVF).html($("#vehicule_fournisseur_input_marge_ht_" + idVF).val() + " €/HT");
    }
}

/**
 * get la devise
 * @param {string} nomInputFournisseur
 */
const getDevVF = (nomInputFournisseur) => { if ($("#" + nomInputFournisseur).val()) { return parseFloat($($("#" + nomInputFournisseur)[0].selectedOptions[0]).attr("data-devise")); } else { return ""; } }

/**
 * get le prix achat en monnaie locale
 * @param {string} idVF
 */
const getPmlVF = (idVF) => { if ($("#vehicule_fournisseur_input_prix_achat_ht_monnaie_locale_" + idVF).val() !== "") { return parseFloat($("#vehicule_fournisseur_input_prix_achat_ht_monnaie_locale_" + idVF).val()); } else { return ""; } }

/**
 * get la valeur de tva
 * @param {string} idVF
 */
const getTvaVF = (idVF) => { if ($("#vehicule_fournisseur_input_tva_" + idVF).val() !== "") { return parseFloat($("#vehicule_fournisseur_input_tva_" + idVF).val()); } else { return ""; } }

/**
 * get le malus
 * @param {string} idVF
 */
const getMalVF = (idVF) => { if ($("#vehicule_fournisseur_input_malus_" + idVF).val() !== "") { return parseFloat($("#vehicule_fournisseur_input_malus_" + idVF).val()); } else { return 0; } }

/**
 * get le total des fre
 * @param {string} idVF
 */
const getFreVF = (idVF, tva = "ht") => { if ($("#vehicule_fournisseur_input_fre_" + tva + "_" + idVF).val() !== "") { return parseFloat($("#vehicule_fournisseur_input_fre_" + tva + "_" + idVF).val()); } else { return ""; } }

/**
 * get le prix du transport
 * @param {string} idVF
 */
const getTptVF = (idVF, tva = "ht") => { if ($("#vehicule_fournisseur_input_prix_transport_" + tva + "_" + idVF).val() !== "") { return parseFloat($("#vehicule_fournisseur_input_prix_transport_" + tva + "_" + idVF).val()); } else { return 0; } }

/**
 * get les frais de plateforme
 * @param {string} idVF
 */
const getPltVF = (idVF) => { if ($("#vehicule_fournisseur_input_fraisPlateforme_ttc_" + idVF).val() !== "") { return parseFloat($("#vehicule_fournisseur_input_fraisPlateforme_ttc_" + idVF).val()); } else { return ""; } }

/**
 * get le prix d'achat
 * @param {string} idVF
 */
const getAchVF = (idVF, tva = "ht") => { if ($("#vehicule_fournisseur_input_prix_achat_" + tva + "_" + idVF).val() !== "") { return parseFloat($("#vehicule_fournisseur_input_prix_achat_" + tva + "_" + idVF).val()); } else { return ""; } }

/**
 * get le prix de revient marchand
 * @param {string} idVF
 */
const getPrmVF = (idVF) => { if ($("#vehicule_fournisseur_input_prix_revient_marchand_ttc_" + idVF).val() !== "") { return parseFloat($("#vehicule_fournisseur_input_prix_revient_marchand_ttc_" + idVF).val()); } else { return ""; } }

/**
 * get le prix marché
 * @param {string} idVF
 */
const getMrcVF = (idVF) => { if ($("#vehicule_fournisseur_input_prix_marche_ttc_" + idVF).val() !== "") { return parseFloat($("#vehicule_fournisseur_input_prix_marche_ttc_" + idVF).val()); } else { return ""; } }
