$(document).ready(function () {
    if ($("#annuaire_results").length) {
        $('body').on('change', "[id^='annuaire-suiviRelationPresta-interlocuteur-']", function (e) {
            const idAnnuaire = this.id.replace("annuaire-suiviRelationPresta-interlocuteur-", "");
            annuaireSuiviRelationPrestaInterlocuteurUpdate(idAnnuaire, this);
        });
    }
});

function annuaireSuiviRelationPrestaInterlocuteurUpdate(idAnnuaire, el) {
    const value = el.value;
    const elDelete = $(el).parent();
    const elParent = $(el).parent().parent();
    $.ajax({
        type: 'POST',
        url: siteURL + 'admin/annuaire/annuaire/interlocuteur',
        data: { id: idAnnuaire, user: value },
        success: function (data) {
            elDelete.remove();
            elParent.append(data);
            toastr.success('L\'interlocuteur a bien été modifiée', 'Success');
        },
        error: function () {
            toastr.error('Erreur lors de l\'enregistrement', 'Erreur');
        }
    });
}
