let campagne_table = null;
let timerBeforeRefreshDataCampagne = null;

$(document).ready(function () {
    if ($("#campagne-page").length) {
        colvisFunOnEvent();
        // Chargement du tableau de campagne
        function loadAjax() {
            $("#campagne-results").addClass("d-none");
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/campagne/loadAjax',
                contentType: false,
                processData: false,
                data: getParams(),
                success: function (data) {
                    $("#campagne-results").html(data);
                    campagne_table = $('#campagne-table').DataTable({
                        "dom": dataTablesCustomDom(),
                        "buttons": [dataTablesColvisBtn('th:nth-child(n+0)')],
                        "autoWidth": false,
                        "language": { "url": "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/French.json" },
                        "paging": true,
                        "pageLength": 25,
                        "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, "All"]],
                        "responsive": true,
                        "order": [[0, "desc"]],
                        "conditionalPaging": true,
                        "columnDefs": [
                            { "className": "text-center align-middle", "targets": '_all' }, // ALIGN MIDDLE
                        ],
                        "columns": getColvisColumn("campagne-table")
                    });
                    $('#campagne-table').on('init.dt', function () { initMirrorBtn(); setColvisTable(campagne_table); colvisFunSelectRefresh(); });
                    $('#campagne-table').on('draw.dt', function () { $("#campagne-results").removeClass("d-none"); loadCampagneStats(getCampagneListId()); });
                    $('#annuaire-table').on('page.dt', function () { loadCampagneStats(getCampagneListId()); });
                    $('#annuaire-table').on('length.dt', function () { loadCampagneStats(getCampagneListId()); });
                    $('#annuaire-table').on('buttons-action.dt', function () { loadCampagneStats(getCampagneListId()); });
                }
            })
        };

        setupLoadAjax(loadAjax);
        setupResetFilter(resetFilter);

        // Récupération des paramètres des requêtes suivant les filtres
        function getParams() {
            var optionsRecherche = new FormData();
            optionsRecherche.append('entite', $("#filterscampagne-entite").val());
            return optionsRecherche;
        }

        $('body').on('change', "[id^='filterscampagne-']", function () { clearTimeout(timerBeforeRefreshDataCampagne); timerBeforeRefreshDataCampagne = setTimeout(loadAjax, 500); });
        // Bouton reset
        $('body').on('click', '#filterscampagne-reset', function () {
            resetFilter();
            loadAjax();
        });

        // Fonction de reset des filtres
        function resetFilter() {
            $("#filterscampagne-entite").val('all');
        }

        filtreConfigFunOnEvent();
    }
});

function loadCampagneStats(listId, i = 0) {
    const errorHTML = '<i class="fas fa-times-square text-danger" title="chargement impossible"></i>'
    $.ajax({
        type: 'GET',
        url: siteURL + 'admin/campagne/mailjetStats/' + listId[i],
        success: function (data) {
            if (data.success) {
                if (data.stats != null) {
                    $("#statut-campagne-" + listId[i]).html(data.stats.EventOpenedCount + " / " + data.stats.Total + " ouvertures");
                    $("#campagne-annuaire-typeEntreprise-" + listId[i]).html(data.stats.Total + " contacts");
                } else {
                    $("#statut-campagne-" + listId[i]).html(errorHTML);
                    $("#campagne-annuaire-typeEntreprise-" + listId[i]).html(errorHTML);
                }
            } else {
                $("#statut-campagne-" + listId[i]).html(errorHTML);
                $("#campagne-annuaire-typeEntreprise-" + listId[i]).html(errorHTML);
            }
        },
        error: function () {
            $("#statut-campagne-" + listId[i]).html(errorHTML);
            $("#campagne-annuaire-typeEntreprise-" + listId[i]).html(errorHTML);
        },
        complete: function () {
            if (i + 1 < listId.length) {
                loadCampagneStats(listId, i + 1);
            }
        }
    });
}

function getCampagneListId() {
    // On récupère les id des lignes affichées
    campagne_table.rows().invalidate();
    let all_data = campagne_table.rows().data();
    let listId = [];
    $.each(all_data, function (key, value) {
        let tr = $('#campagne-tr-' + value[0]["@data-search"].replace("id:[", "").replace("]", ""));
        if (tr.length != 0) {
            listId.push(value[0]["@data-search"].replace("id:[", "").replace("]", ""));
        }
    });
    return listId;
}
