$(document).ready( function () {

    // Génération de la datatable des categories
    categorie_table = $('#categories_table').DataTable( {
        "autoWidth": false,
    	"language": {
            "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/fr-FR.json"
        },
        "responsive": true,
    	"ajax": siteURL + 'json/categories',
    	"order": [[ 0, "asc" ]],
    	"columnDefs": [
            { responsivePriority: 1, targets: 0 },
            { responsivePriority: 2, targets: 1 },
            // TEXT CENTER
            {
                "className": "text-center align-middle",
                "targets": [2,3,4],
            },
            // ALIGN MIDDLE
            {
                "className": "align-middle",
                "targets": [0, 3, 4],
            },
            // TEXT START
            {
                "className": "text-start",
                "targets": [1, 2],
            },
            // NAME
            {
                "targets": 0,
                "render": function ( data, type, row ) {
                    return row[1];
                },
            },
            // REWRITENAME
            {
                "targets": 1,
                "render": function ( data, type, row ) {
                    return "<span class='text-lowercase text-start'>"+"/categorie/"+row[2]+"/"+"</span>";
                },
            },
            // ORDRE
            {
                "width": "70px",
                "targets": 2,
                "render": function ( data, type, row ) {
                    return row[3];
                },
            },
            // VISIBLE
            {
                "width": "50px",
                "targets": 3,
                "render": function (data, type, row) {
                    if (row[4] == 1) {
                        return '<i class="fal fa-check-square"></i><span class="d-inline d-lg-none pl-2">OUI</span>';
                    }
                    else {
                        return '<i class="fal fa-square"></i><span class="d-inline d-lg-none pl-2">NON</span>';
                    }
                },
                "orderable": false,
            },
            // MODIFY
    		{
                "width": "50px",
                "targets": 4,
    			"render": function ( data, type, row ) {
                    return '<button class="btn w-100 p-0" id="categorie-open-modify-'+row[0]+'" data-category="web"><i class="fad fa-edit"></i>';
                },
                "orderable": false,
    		},
    	],
        "conditionalPaging": true,
    });

    // VERIFICATION LONGUEUR TEXTE
    // Title
    $('body').on('keyup keydown', "input[id^='categorie_title_']", function() {
        var clickedId = this.id.split(/[_ ]+/).pop();

        number = $('#categorie_title_'+clickedId).val().length;

        // Change la couleur en fonction du nombre de caractère
        $('#categorie_text_title_'+clickedId).text(number+"/63 caractère"+(number >= 2 ? "s" : ""))
        if (number >= 40 && number < 55 || number > 72 && number <= 90) {
            $('#categorie_text_title_'+clickedId).css('color', 'orange');
        } else if (number >= 55 && number <= 72) {
            $('#categorie_text_title_'+clickedId).css('color', 'green');
        } else {
            $('#categorie_text_title_'+clickedId).css('color', 'red');
        }

    });
    // Balise h1
    $('body').on('keyup keydown', "input[id^='categorie_balise_h1_']", function() {
        var clickedId = this.id.split(/[_ ]+/).pop();

        number = $('#categorie_balise_h1_'+clickedId).val().length;

        // Change la couleur en fonction du nombre de caractère
        $('#categorie_text_balise_h1_'+clickedId).text(number+"/60 caractère"+(number >= 2 ? "s" : ""))
        if (number >= 40 && number < 50 || number > 70 && number <= 80) {
            $('#categorie_text_balise_h1_'+clickedId).css('color', 'orange');
        } else if (number >= 50 && number <= 70) {
            $('#categorie_text_balise_h1_'+clickedId).css('color', 'green');
        } else {
            $('#categorie_text_balise_h1_'+clickedId).css('color', 'red');
        }

    });
    // Meta Description
    $('body').on('keyup keydown', "input[id^='categorie_meta_description_']", function() {
        var clickedId = this.id.split(/[_ ]+/).pop();

        number = $('#categorie_meta_description_'+clickedId).val().length;

        // Change la couleur en fonction du nombre de caractère
        $('#categorie_text_meta_description_'+clickedId).text(number+"/154 caractère"+(number >= 2 ? "s" : ""))
        if (number >= 100 && number < 130 || number > 170 && number <= 200) {
            $('#categorie_text_meta_description_'+clickedId).css('color', 'orange');
        } else if (number >= 130 && number <= 170) {
            $('#categorie_text_meta_description_'+clickedId).css('color', 'green');
        } else {
            $('#categorie_text_meta_description_'+clickedId).css('color', 'red');
        }

    });

    // Tagline
    $('body').on('keyup keydown', "input[id^='categorie_tagline_']", function() {
        var clickedId = this.id.split(/[_ ]+/).pop();
        var tagline = this.id.split(/[_ ]+/)[2];

        number = $('#categorie_tagline_'+tagline+'_'+clickedId).val().length;

        // Change la couleur en fonction du nombre de caractère
        $('#categorie_text_tagline_'+tagline+'_'+clickedId).text(number+"/100 caractère"+(number >= 2 ? "s" : ""))
        if (number >= 60 && number < 80 || number > 120 && number <= 140) {
            $('#categorie_text_tagline_'+tagline+'_'+clickedId).css('color', 'orange');
        } else if (number >= 80 && number <= 120) {
            $('#categorie_text_tagline_'+tagline+'_'+clickedId).css('color', 'green');
        } else {
            $('#categorie_text_tagline_'+tagline+'_'+clickedId).css('color', 'red');
        }

    });

    $('body').on('keyup keydown', "textarea[id^='categorie_description_courte_']", function() {
        var clickedId = this.id.split(/[_ ]+/).pop();
        number = $('#categorie_description_courte_'+clickedId).val().length;

        // Change la couleur en fonction du nombre de caractère
        $('#categorie_text_description_courte_'+clickedId).text(number+"/400 caractère"+(number >= 2 ? "s" : ""))
        if (number >= 260 && number < 320 || number > 480 && number <= 560) {
            $('#categorie_text_description_courte_'+clickedId).css('color', 'orange');
        } else if (number >= 320 && number <= 480) {
            $('#categorie_text_description_courte_'+clickedId).css('color', 'green');
        } else {
            $('#categorie_text_description_courte_'+clickedId).css('color', 'red');
        }
    });

    // Envoi de modification d'un categorie
    $('body').on('click', "button[id^='categorie-send-modify-']", function() {
        var clickedId = this.id.split(/[- ]+/).pop();;

        var form = new FormData();
        // On extrait le contenu du formulaire dans la variable 'form'
        form.append('id', clickedId);
        form.append('categorie_name', $('#categorie_name_'+clickedId).val());
        form.append('categorie_namerewrite', $('#categorie_namerewrite_'+clickedId).val());
        form.append('categorie_isvisible', $('input[name="categorie_visible_'+clickedId+'"]:checked').val());
        form.append('categorie_ordre', $('select[name="categorie_ordre_'+clickedId+'"]').val());
        form.append('categorie_title', $('#categorie_title_'+clickedId).val());
        form.append('categorie_meta_description', $('#categorie_meta_description_'+clickedId).val());
        form.append('categorie_balise_h1', $('#categorie_balise_h1_'+clickedId).val());
        form.append('categorie_tagline_1', $('#categorie_tagline_1_'+clickedId).val());
        form.append('categorie_tagline_2', $('#categorie_tagline_2_'+clickedId).val());
        form.append('categorie_description_courte', $('#categorie_description_courte_'+clickedId).val());

        // Verifie la longeur du texte
        if ($("#categorie_title_"+clickedId).val().length > 200) {
            toastr.error('Merci de reduire le title', 'Erreur');
            return false;
        }
        if ($("#categorie_meta_description_"+clickedId).val().length > 249) {
            toastr.error('Merci de reduire la meta description', 'Erreur');
            return false;
        }
        if ($("#categorie_balise_h1_"+clickedId).val().length > 200) {
            toastr.error('Merci de reduire la balise h1', 'Erreur');
            return false;
        }
        if ($("#categorie_tagline_1_"+clickedId).val().length > 249) {
            toastr.error('Merci de reduire la tagline 1', 'Erreur');
            return false;
        }
        if ($("#categorie_tagline_2_"+clickedId).val().length > 249) {
            toastr.error('Merci de reduire la tagline 2', 'Erreur');
            return false;
        }
        if ($("#categorie_description_courte_"+clickedId).val().length > 999) {
            toastr.error('Merci de reduire la description courte', 'Erreur');
            return false;
        }

        // Requète ajax d'envoi du formulaire : /src/Controller/AdminController.php -> admin_categories()
        $.ajax({
            type: 'POST',
            url: siteURL + 'admin/web/categorie/modify',
            cache: false,
            contentType: false,
            processData: false,
            data: form,
            success: function(data) {
                // Si il s'agit de l'ajout d'un nouveau categorie
                if (clickedId == 0) {
                    toastr.success('La nouvelle catégorie a bien été enregistrée', 'Succès');
                    $('#formcategorie_0')[0].reset();
                }
                // Si il s'agit d'une modification d'categoriealité
                else {
                    toastr.success('La catégorie a bien été modifiée', 'Succès');
                    // On nettoye le html en supprimant l'onglet 'Modifier/Nouveau'
                    $('#categorie-tabs-modify-'+clickedId).remove();
                    $('#categorie-close-modify-'+clickedId).parent().remove();
                }
                // Retour à la liste d'categoriealités en rechargeant la datatable
                categorie_table.ajax.reload();
                $('#categorie-tabs-list-tab').trigger('click');
            }
        });
        return false;
    });
});