$(document).ready( function () {

    // Génération de la datatable des utilisateurs
    user_table = $('#users_table').DataTable( {
        "autoWidth": false,
        "language": {
            "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/fr-FR.json"
        },
        "responsive": true,
        "ajax": siteURL+'json/users',
        "order": [[ 3, "desc" ]],
        "columnDefs": [
            // TEXT CENTER
            {
                "className": "text-center align-middle",
                "targets": [7,8,9],
            },
            // PHOTO
            {
                "width": "50px",
                "className": "text-center",
                "targets": 0,
                "render": function (data, type, row) {
                        return "<img class='img-fluid img-circle image w-50 m-auto d-block' src='"+row[1]+"'>";
                }
            },
            // PRENOM
            {
                "targets": 1,
                "render": function (data, type, row) {
                        return row[2];
                }
            },
            // NOM
            {
                "targets": 2,
                "render": function (data, type, row) {
                        return row[3];
                }
            },
            // LIEU
            {
                "targets": 3,
                "render": function (data, type, row) {
                    if (row[4] == "En arrivage") {
                        return "Neuville-sur-Saône";
                    } else {
                        return row[4];
                    }
                }
            },
            // TELEPHONE
            {
                "targets": 4,
                "render": function (data, type, row) {
                        return row[5];
                }
            },
            // EMAIL
            {
                "targets": 5,
                "render": function (data, type, row) {
                        return "<span class='text-lowercase'>"+row[6]+"</span>";
                }
            },
            // POSTE
            {
                "targets": 6,
                "render": function (data, type, row) {
                        return row[7];
                }
            },
            // VISIBLE
            {
                "width": "50px",
                "className": "text-center",
                "targets": 7,
                "render": function (data, type, row) {
                    if (row[8] == 1) {
                        return '<i class="fal fa-check-square"></i><span class="d-inline d-lg-none pl-2">OUI</span>';
                    }
                    else {
                        return '<i class="fal fa-square"></i><span class="d-inline d-lg-none pl-2">NON</span>';
                    }
                }
            },
            // ACTIF
            {
                "width": "50px",
                "className": "text-center",
                "targets": 8,
                "render": function (data, type, row) {
                    if (row[9] == 1) {
                        return '<i class="fal fa-check-square"></i><span class="d-inline d-lg-none pl-2">OUI</span>';
                    }
                    else {
                        return '<i class="fal fa-square"></i><span class="d-inline d-lg-none pl-2">NON</span>';
                    }
                }
            },
            // MODIFY
            {
                "targets": 9,
                "render": function ( data, type, row ) {
                    return '<button class="btn w-100 p-0" id="user-open-modify-'+row[0]+'" data-category="administratif"><i class="fad fa-edit"></i>';
                },
                "orderable": false,
            },
        ],
        "conditionalPaging": true,
    });


    // Modifie l'adresse mail et le login dynamiquement lors de la saisie des nom et prénom
    $('body').on('keyup', "[id^='user_name_'], [id^='user_surname_']", function(e){
        var id_selected = this.id.replace('user_name_','');
        if (isNaN(id_selected)) { var id_selected = this.id.replace('user_surname_',''); }
        $("#user_login_"+id_selected).val($('#user_name_'+id_selected).val().charAt(0).toLowerCase() + $('#user_surname_'+id_selected).val().replace(' ', '').toLowerCase());
        $("#user_email_"+id_selected).val($('#user_name_'+id_selected).val().toLowerCase() + '.' + $('#user_surname_'+id_selected).val().replace(' ', '').toLowerCase() + '@ora7.fr');
    });

    // Envoi de la modification de l'utilisateur
    $('body').on('click', "button[id^='user-send-modify-']", function() {
        var id_clicked = this.id.replace('user-send-modify-','');

        if ((id_clicked == "0" ? $('#user_password_'+id_clicked).val().length >= 4 : true)) {
            var form = new FormData();
            form.append('id', id_clicked);
            form.append('user_name', $('#user_name_'+id_clicked).val());
            form.append('user_surname', $('#user_surname_'+id_clicked).val());
            form.append('user_fonction', $('select[name="user_fonction_'+id_clicked+'"]').children("option:selected").val());
            form.append('user_centre', $('select[name="user_centre_'+id_clicked+'"]').children("option:selected").val());
            form.append('user_centre_secondaire', $('select[name="user_centre_secondaire_'+id_clicked+'"]').val());
            form.append('user_centre_facturation', $('select[name="user_centre_facturation_'+id_clicked+'"]').children("option:selected").val());
            form.append('user_ordre', $('select[name="user_ordre_'+id_clicked+'"]').children("option:selected").val());
            form.append('user_telephone', $('#user_telephone_'+id_clicked).val());
            form.append('user_email', $('#user_email_'+id_clicked).val());
            form.append('user_identifiant', $('#user_login_'+id_clicked).val());
            form.append('user_password', $('#user_password_'+id_clicked).val());
            form.append('user_isvisible', $('input[name="user_onsite_'+id_clicked+'"]:checked').val());
            form.append('user_isuservisible', $('input[name="user_userVisible_'+id_clicked+'"]:checked').val());
            form.append('user_isactive', $('input[name="user_active_'+id_clicked+'"]:checked').val());
            form.append('user_image', $('#file_result_0').val());
            form.append('user_roles', $('select[name="user_roles_'+id_clicked+'"]').val());
            form.append('user_sexe', $('select[name="user_sexe_'+id_clicked+'"]').children("option:selected").val());
            form.append('user_pays', $('select[name="user_pays_'+id_clicked+'"]').children("option:selected").val());
            $.ajax({
                type: 'POST',
                url: siteURL+'admin/administratif/user/modify',
                cache: false,
                contentType: false,
                processData: false,
                data: form,
                success: function(data) {
                    toastr.success('L\'utilisateur a bien été enregistré', 'Succès');
                    $('#user-tabs-modify-'+id_clicked).remove();
                    $('#user-close-modify-'+id_clicked).parent().remove();
                    // Si nous sommes sur un nouvel utilisateur à ajouter uniquement, pas une modification
                    if (id_clicked == 0) {
                        $('#formuser_'+id_clicked)[0].reset();
                    }
                    $('#user-tabs-list-tab').trigger('click');
                    user_table.ajax.reload()
                }
            });
        } else {
            toastr.error('Le mot de passe n\'est pas assez long', 'Erreur');
        }
        return false;
    });
});