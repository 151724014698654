// Initialisation des selectpicker du formulaire de réception lors du clic sur l'onglet Réception
$('body').on('click', "#stock_vehicule-reception", function (e) {
    $("#reception_input_marque").selectpicker();
    $("#reception_input_modele").selectpicker();
    $("#reception_input_energie").selectpicker();
    $("#reception_input_boite").selectpicker();
    $("#reception_input_couleur").selectpicker();
});

// Pour montrer les modèles qui sont rattachés que à la marque
$('body').on('change', "#reception_input_marque", function() {
    // On change le logo de la marque
    $('.img-reception-marque').attr('src', $('#reception_input_marque option:selected').attr('data-url'));

    if ($('#reception_input_marque').val().length != 0) {
        $("#reception_input_modele").prop('disabled', false);
        $("#opt_" + $('#reception_input_marque').val()).prop('disabled', false);
        $("[id^='opt_']").each(function () {
            let marqueEnCours = $(this).attr('id').replace('opt_', '');
            if ($('#reception_input_marque').val() != marqueEnCours) {
                $(this).prop('disabled', true);
            }
        })
        $("#reception_input_modele").selectpicker('refresh');
    } else {
        $('#reception_input_modele').prop("disabled", true);
        $("#reception_input_modele").find("optgroup").prop('disabled', true);
        $("#reception_input_modele").selectpicker('refresh');
    }
});

$('body').on('shown.bs.modal', "#reception-canva-photo-vin", function (e) {
    lancementScriptVideoVIN('#reception_input_vin');
});

$('body').on('input', "#reception_input_vin", function (e) {
    let valeurInput = $("#reception_input_vin").val();
    let valeurVIN = $("#reception_input_vin").attr("data-vraivin");
    if ($("#reception_input_vin").val().length >= 17 && valeurInput != valeurVIN) {
        $("#reception_faux_vin").removeClass("d-none");
        $("#reception_vrai_vin").addClass("d-none");
    } else if ($("#reception_input_vin").val().length >= 17 && valeurInput == valeurVIN) {
        $("#reception_vrai_vin").removeClass("d-none");
        $("#reception_faux_vin").addClass("d-none");
    } else {
        $("#reception_vrai_vin").addClass("d-none");
        $("#reception_faux_vin").addClass("d-none");
    }
});

// Pour la vérification de la sélection de la carte grise
$('body').on('click', "[id^='reception_cg_']", function (e) {
    let value = this.id.replace('reception_cg_', '');
    let contreValue = (value == "true" ? "false" : "true")
    $('#reception_cg_' + value).addClass('btn-primary');
    $('#reception_cg_' + value).removeClass('btn-outline-secondary');
    $('#reception_cg_' + contreValue).removeClass('btn-primary');
    $('#reception_cg_' + contreValue).addClass('btn-outline-secondary');
});

// Pour la vérification de la sélection du certificat de conformité Européen
$('body').on('click', "[id^='reception_coc_']", function (e) {
    let value = this.id.replace('reception_coc_', '');
    let contreValue = (value == "true" ? "false" : "true")
    $('#reception_coc_' + value).addClass('btn-primary');
    $('#reception_coc_' + value).removeClass('btn-outline-secondary');
    $('#reception_coc_' + contreValue).removeClass('btn-primary');
    $('#reception_coc_' + contreValue).addClass('btn-outline-secondary');
});

// Pour la vérification de la sélection du double des clé
$('body').on('click', "[id^='reception_doublecle_']", function (e) {
    let value = this.id.replace('reception_doublecle_', '');
    let contreValue = (value == "true" ? "false" : "true")
    $('#reception_doublecle_' + value).addClass('btn-primary');
    $('#reception_doublecle_' + value).removeClass('btn-outline-secondary');
    $('#reception_doublecle_' + contreValue).removeClass('btn-primary');
    $('#reception_doublecle_' + contreValue).addClass('btn-outline-secondary');
});

// Pour la vérification de présence d'une prestation (attelage, écrou anti-vol, etc)
$('body').on('click', "[id^='btn-presence-']", function (e) {
    // On récupère l'ID de la card de prestation afin de cliquer dessus en arrière plan
    let idCard = $(this).attr('data-idcard');
    let idPrestation = $(this).attr('data-idprestation');
    let numeroEtape = $(this).attr('data-numeroetape');
    let value = this.id.replace('btn-presence-', '').replace(/-(\d+)$/, '');

    if (value == 'oui') {
        $(this).addClass("btn-primary");
        $(this).removeClass("btn-outline-secondary");
        $("#btn-presence-non-" + idPrestation).removeClass("btn-primary");
        $("#btn-presence-non-" + idPrestation).addClass("btn-outline-secondary");
        $('#card-presence-prestation-' + idPrestation).addClass('d-none');
        $('#card-prestation-' + idPrestation).removeClass('d-none');
    } else {
        let boutonAbsent = $('#div-reception-prestation-etape4').find('#btn-prestation-0[data-idprestation="0"][data-idcard="' + idCard + '"][data-numeroetape="' + numeroEtape + '"]')[0];
        $(boutonAbsent).trigger('click');
        $(this).addClass("btn-primary");
        $(this).removeClass("btn-outline-secondary");
        $("#btn-presence-oui-" + idPrestation).removeClass("btn-primary");
        $("#btn-presence-oui-" + idPrestation).addClass("btn-outline-secondary");
    }
});

// Pour revenir sur la card de vérification de présence
$('body').on('click', "[id^='retour-presence-']", function (e) {
    let idPrestation = this.id.replace('retour-presence-', '');
    $('#card-presence-prestation-' + idPrestation).removeClass('d-none');
    $('#card-prestation-' + idPrestation).addClass('d-none');
});

// Pour afficher l'input de CO2 certifié s'il y a le COC
$('body').on('click', "#reception_coc_true", function (e) {
    $('#card-presence-coc').addClass('d-none');
    $('#card-coc').removeClass('d-none');
});

// Pour revenir sur la card de COC
$('body').on('click', "#retour-coc", function (e) {
    $('#card-presence-coc').removeClass('d-none');
    $('#card-coc').addClass('d-none');
});

// Enregistrement des prestations correspondants
$('body').on('click', "[id^='btn-prestation-']", function (e) {
    // On vérifie qu'on clique pas sur une prestation déjà faite, sinon on ne fait rien
    if ($(this).attr("data-active") == 0) {
        // On récupère l'ID du véhicule et l'ID VF correspondant
        let idVS = $('#stock_vehicule-reception').attr('data-idvs');
        let idVF = $('#stock_vehicule-reception').attr('data-idvf');
        // On récupère l'ID de la card de prestation afin de mettre et enlever le loader le temps de la requête AJAX
        let idCard = $(this).attr('data-idcard');
        let numeroEtape = $(this).attr('data-numeroetape');
        // On récupère l'ID de la prestation
        let idPrestation = this.id.replace('btn-prestation-', '');
        let idPrestationASupprimer = [];
        // On va boucler sur tout les boutons pour enregistrer
        let buttonPrestationConcerne = $(this).parent().children();
        buttonPrestationConcerne.each(function() {
            let idPrestationEnCours = this.id.replace('btn-prestation-', '');
            if(idPrestation != idPrestationEnCours) {
                idPrestationASupprimer.push(idPrestationEnCours);
            }
            $(this).removeClass("btn-primary");
            $(this).addClass("btn-outline-secondary");
            $(this).attr('data-active', '0');
        })
        // On rajoute le style sur le bouton de prestation qu'on a changé
        $(this).attr('data-active', '1');
        $(this).removeClass("btn-outline-secondary");
        $(this).addClass("btn-primary");

        // On met à jour le data expertise de l'étape 5 pour enlever le frais ou le rajouter si besoin
        let dialogExpertiseReception = $("#reception-channel-6 #reception-dialog-" + idVS + "-dialog");
        let frais = dialogExpertiseReception.data("expertise");
        // On supprime les frais qui sont retiré
        dialogExpertiseReception.data("expertise", frais.filter((f) => !(idPrestationASupprimer.includes(f.prestation.toString()))));
        expertiseDisplayUpdate(dialogExpertiseReception);


        // On met le loader sur la card
        $('#reception-etape' + numeroEtape + '-loader-card-' + idCard).removeClass('d-none');
        $('#reception-etape' + numeroEtape + '-card-' + idCard).addClass('d-none');
        // On disabled les buttons au cas où pour éviter les clics dessus pendant la requête
        $('.container-button-suivant-precedent button').prop('disabled', true)
        // Envoie de la requête pour l'enregistrement des données en base
        let form = new FormData();
        form.append('idVS', idVS);
        form.append('idVF', idVF);
        form.append('idPrestation', idPrestation);
        form.append('idPrestationASupprimer', idPrestationASupprimer);
        $.ajax({
            type: 'POST',
            url: siteURL + 'admin/stock/update/reception-update-prestation',
            data: form,
            processData: false,
            contentType: false,
            success: function(data) {
                // On rajoute les frais sur l'étape 5
                if (data != false && idPrestation != 83 && idPrestation != 82) {
                    dialogExpertiseReception.data("expertise", dialogExpertiseReception.data("expertise").concat([data]));
                    expertiseDisplayUpdate(dialogExpertiseReception);
                }

                // On enlève le loader
                $('#reception-etape' + numeroEtape + '-loader-card-' + idCard).addClass('d-none');
                $('#reception-etape' + numeroEtape + '-card-' + idCard).removeClass('d-none');
                // On réactive les buttons
                $('.container-button-suivant-precedent button').prop('disabled', false)
                // On met un toast pour le succès du rajout du frais changé
                toastr.success("La prestation sur le véhicule a bien été changé", 'Succès');
            }
        });
    }
});

// Pour l'accès et la fermeture de la dialog sur l'ajout de frais à partir de la réception
$('body').on('click', "#reception-acces-frais", function (e) {
    channelClose(5, "reception");
    channelOpen(6, "reception");
});

$('body').on('click', "#reception-fermeture-frais", function (e) {
    channelClose(6, "reception");
    channelOpen(5, "reception");
});

// Pour rafraîchir le tab réception à la fermeture de la modal de QR Code
$('body').on('hidden.bs.modal', "#modal-fin-reception-qrcode", function (e) {
    $("#stock_vehicule-tab-reception").trigger("click");
});
$('body').on('click', "#lien-qr-code-modal-fin-reception-qrcode", function (e) {
    $('#modal-fin-reception-qrcode').modal('hide')
    $("#stock_vehicule-tab-reception").trigger("click");
});

// Pour update les étapes de la réception
function updateReceptionEtape(step, dialog, channel = "next", increment = 0) {
    let form = new FormData();
    form.append('idVehicule', $("#stock_vehicule-reception").attr("data-idvs"));
    form.append('etapeReception', channel == "end" ? 1 : step + increment + 1);
    form.append('finReception', ((channel == "end" || $('#stock_vehicule-reception').data('stepend') == 1) ? "end" : null));
    $.ajax({
        type: 'POST',
        url: siteURL + 'admin/stock/update/reception-update-etape',
        data: form,
        processData: false,
        contentType: false,
        success: function(data) {
            console.log("Étape enregistrée")
            // Pour rafraîchir la pastille de l'onglet réception et débloquer l'onglet expertise si la réception est finie
            if (channel == "end") {
                $('#stock_vehicule-tab-reception .stock_vehicule-statut-onglet').removeClass('bg-danger').removeClass('bg-warning').addClass('bg-success');
                $('#stock_vehicule-tab-reception .stock_vehicule-statut-onglet').html('<i class="fas fa-check"></i>');
                // Mise à jour de l'onglet expertise
                $('#stock_vehicule-tab-expertise').attr("class", $("#stock_vehicule-tab-general").attr("class"));
                $('#stock_vehicule-tab-expertise').prop("disabled", false);
                // Mise à jour de l'onglet papier
                $('#stock_vehicule-tab-papier').attr("class", $("#stock_vehicule-tab-general").attr("class"));
                $('#stock_vehicule-tab-papier').prop("disabled", false);
            } else if ($('#stock_vehicule-reception').data('stepend') != 1) {
                $('#stock_vehicule-tab-reception .stock_vehicule-statut-onglet').removeClass('bg-danger').removeClass('bg-success').addClass('bg-warning');
                $('#stock_vehicule-tab-reception .stock_vehicule-statut-onglet').html('<i class="fas fa-ellipsis-h"></i>');
            }

            channelLoader(step, "reception", "off");

            // On ferme la modal si c'est fini, sinon on passe à la suivante
            if (channel == "end") {
                channelClose(step + increment, "reception");
                // Si c'est finie, et qu'on a le bon VIN, et pas de téléchargement du QR code, on va ouvrir la modal du QR code
                if ($("#reception_input_vin").val() == $("#reception_input_vin").attr("data-vraivin") && $("#stock_vehicule-reception").attr("data-datePremierTelechargementQRCode") == "null") {
                    $("#ouverture-modal-fin-reception-qrcode").trigger("click");
                } else {
                    // On rafraîchit le tab, et on enlève le loader de la modal
                    $("#stock_vehicule-reception-container").html(data.container);
                    $("#stock_vehicule-tab-reception").trigger("click");
                }
            } else {
                channelClose(step, "reception");
                channelOpen(step + increment + 1, "reception");
            }
        },
        error: function () {
            dialogToastrError(dialog, "reception", "Une erreur s'est produite.");
        }
    });
}

/**
 * @function receptionExpertisePointAdd
 * @param {Json} element
 * @summary This function is used to set a point on "reception-expertise" diagram
 */
function receptionExpertisePointAdd(element) {
    const schema = $("#reception-container-resume-expertise");
    const newEl = document.createElement("div");
    newEl.id = "reception-resume-expertise-point";
    newEl.classList.add("expertise-point");
    newEl.setAttribute("data-x", element.point.x);
    newEl.classList.add("l-" + element.point.x);
    newEl.setAttribute("data-y", element.point.y);
    newEl.classList.add("t-" + element.point.y);
    if (element.toDo) {
        newEl.classList.add("bg-warning");
    } else {
        newEl.classList.add("bg-danger");
    }
    schema.append(newEl);


    let elementLabel = null;
    $("#reception-frais-elements").find("option").each((k, o) => {
        if ((element.element == o.value)) { elementLabel = o; }
    });
    let prestationLabel = null;
    $("#reception-frais-prestations").find("option").each((k, o) => {
        if ((element.prestation == o.value)) { prestationLabel = o; }
    });

    newElDetails = $("#generique-reception-resume-expertise-point-details").clone();
    newElDetails.attr('id', "reception-resume-expertise-point-details");
    newElDetails.removeClass("d-none");
    newElDetails.removeClass("l-__pointX__").addClass("l-" + element.point.x);
    newElDetails.removeClass("t-__pointY__").addClass("t-" + element.point.y);
    newElDetails.find(".text-__couleur__").removeClass("text-__couleur__").addClass(element.toDo ? "text-warning" : "text-danger");
    newElDetails.html(newElDetails.html().replaceAll("__elementLabel__", elementLabel.innerHTML));
    newElDetails.html(newElDetails.html().replaceAll("__prestationLabel__", prestationLabel.innerHTML));
    newElDetails.html(newElDetails.html().replaceAll("__fraisQuantite__", element.quantite));
    newElDetails.html(newElDetails.html().replaceAll("__fraisPrixHt__", element.prixHt));
    newElDetails.html(newElDetails.html().replaceAll("__fraisPrixHtTotal__", element.prixHtTotal));
    newElDetails.html(newElDetails.html().replaceAll("__texteRealiseOuNon__", element.toDo ? "Cette prestation doit être réalisée &#x1F6A7;" : "Cette prestation n'est pas à réalisée &#x274C;"));
    schema.append(newElDetails);
}

/**
 * @function receptionExpertisePointRemove
 * @summary This function is used to set a point on "reception-expertise" diagram
 */
function receptionExpertisePointRemove() {
    const oldEl = $("[id^='reception-resume-expertise-point']");
    const oldElDetails = $("[id^='reception-resume-expertise-point-details']");
    if (oldEl.length) { oldEl.remove(); }
    if (oldElDetails.length) { oldElDetails.remove(); }
}