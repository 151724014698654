const wapp = {}; // Instance de configuration de l'API

/**
 * Initialise la requête pour les config de wapp
 * Fait une requête GET vers "/admin/wapp/config" pour récupérer les config de l'API WhatsApp
 * Si la requête est un succès, assigne les valeurs de configuration aux clefs respectives dans l'objet wapp
 * Si la requête échoue, affiche un message d'erreur
 * @return {Promise<void>}
 */
function wappInit() { // Initialise la requête pour les config de wapp
    return new Promise((resolve) => {
        fetch(siteURL + "admin/wapp/config")
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    Object.assign(wapp, Object.fromEntries(data.configs.map(item => [item.name.replace("wapp_", ""), item.value]))); // Donne à wapp les configs retourné par le serveur
                    resolve(); // Success
                } else { wappDisconnected("wapp initialization failed"); } // Error
            })
            .catch(() => wappDisconnected("wapp initialization failed")); // Error
    });
}

/**
 * Affiche un message d'erreur de connexion à l'API WhatsApp
 * @param {string} error - Le message d'erreur
 */
function wappDisconnected(error) {
    toastr.error("L'API WhatsApp semble déconnectée.<br>Si le problème persiste, veuillez contacter le service WEB.", "Erreur"); // Affiche le message d'erreur de connexion à l'API
    console.error(error);
}

/**
 * Fait une requête GET vers l'API WhatsApp
 * @param {string} path - Le chemin de la requête, sans le préfixe "https://api.ultramsg.com/"
 * @returns {Promise<string>} - Une promesse qui se résout en cas de succès avec le contenu de la réponse
 */
function wappGet(path, { } = {}) {
    const data = { token: wapp.token };
    // POUR LES TEST/DEBUG, SIMULE LE TEMPS D'UNE REQUÊTE. NE PAS ENLEVER MERCI.
    // console.log("wappGet disabled", data); return new Promise((resolve) => { setTimeout(resolve, 1000); }); // NE PAS ENLEVER MERCI.
    // NE PAS ENLEVER MERCI.
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest({ withCredentials: false });
        xhr.addEventListener("readystatechange", function () { if (this.readyState === this.DONE) { if (this.status >= 200 && this.status < 300) { resolve(this.responseText); } else { reject(); } } });
        xhr.open("GET", "https://api.ultramsg.com/" + wapp.instance + "/" + path + "?" + new URLSearchParams(Object.fromEntries(Object.entries(data).filter(([_, value]) => value != null))).toString());
        xhr.send();
    });
}

/**
 * Fait une requête POST vers l'API WhatsApp
 * @param {string} path - Le chemin de la requête, sans le préfixe "https://api.ultramsg.com/"
 * @param {string} to - L'adresse du destinataire à qui envoyer le message
 * @param {string} [body] - Le corps du message
 * @param {string} [image] - L'URL de l'image à envoyer
 * @param {string} [caption] - Le texte de la légende de l'image
 * @returns {Promise<void>} - Une promesse qui se résout si la requête est un succès
 */
function wappPost(path, to, { body = null, image = null, caption = null } = {}) {
    const data = { token: wapp.token, to, body, image, caption };
    // POUR LES TEST/DEBUG, SIMULE LE TEMPS D'UNE REQUÊTE. NE PAS ENLEVER MERCI.
    // console.log("wappPost disabled", data); return new Promise((resolve) => { setTimeout(resolve, 1000); }); // NE PAS ENLEVER MERCI.
    // NE PAS ENLEVER MERCI.
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest({ withCredentials: false });
        xhr.addEventListener("readystatechange", function () { if (this.readyState === this.DONE) { if (this.status >= 200 && this.status < 300) { resolve(); } else { reject(); } } });
        xhr.open("POST", "https://api.ultramsg.com/" + wapp.instance + "/" + path);
        xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        xhr.send(new URLSearchParams(Object.fromEntries(Object.entries(data).filter(([_, value]) => value != null))).toString());
    });
}
