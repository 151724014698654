$(document).ready( function () {

    // Génération de la datatable des partenaires
    partenaire_table = $('#partenaires_table').DataTable( {
        "autoWidth": false,
    	"language": {
            "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/fr-FR.json"
        },
        "responsive": true,
    	"ajax": siteURL + 'json/partenaires',
    	"order": [[ 0, "asc" ]],
    	"columnDefs": [
            { responsivePriority: 1, targets: 1 },
            { responsivePriority: 2, targets: 3 },
            { responsivePriority: 3, targets: 2 },
            { responsivePriority: 4, targets: 0 },
            { responsivePriority: 5, targets: 4 },
            // TEXT CENTER
            {
                "className": "text-center align-middle",
                "targets": [3, 4],
            },
             // TEXT START
             {
                "className": "text-start",
                "targets": [1],
            },
            // NAME
            {
                "targets": 0,
                "render": function ( data, type, row ) {
                    return row[1];
                },
            },
            // TITRE + SOUS-TITRE
    		{
                "targets": 1,
    			"render": function ( data, type, row ) {
                    return "<span class='text-start'>"+row[2]+"</span>";
                },
                "orderable": false,
    		},
            // REDACTEUR
    		{
                "targets": 2,
    			"render": function ( data, type, row ) {
                    return row[3];
                },
    		},
            // VISIBLE
    		{
                "width": "50px",
                "targets": 3,
                "render": function (data, type, row) {
                    if (row[4] == 1) {
                        return '<i class="fal fa-check-square"></i><span class="d-inline d-lg-none pl-2">OUI</span>';
                    }
                    else {
                        return '<i class="fal fa-square"></i><span class="d-inline d-lg-none pl-2">NON</span>';
                    }
                },
                "orderable": false,
            },
            // MODIFY
    		{
                "width": "50px",
                "targets": 4,
    			"render": function ( data, type, row ) {
                    return '<button class="btn w-100 p-0" id="partenaire-open-modify-'+row[0]+'" data-category="web"><i class="fad fa-edit"></i>';
                },
                "orderable": false,
    		},
    	],
        "conditionalPaging": true,
    });

    // Envoi de modification d'un partenaire
    $('body').on('click', "button[id^='partenaire-send-modify-']", function() {
        var clickedId = this.id.split(/[- ]+/).pop();;

        if (makeSureFields(this.id) == true) {
            var form = new FormData();
            // On extrait le contenu du formulaire dans la variable 'form'
            form.append('id', clickedId);
            form.append('partenaire_name', $('#partenaire_name_'+clickedId).val());
            form.append('partenaire_texte', $('#partenaire_texte_'+clickedId).val());
            form.append('partenaire_link', $('#partenaire_link_'+clickedId).val());
            form.append('partenaire_ordre', $('select[name="partenaire_ordre_'+clickedId+'"]').children("option:selected").val());
            form.append('partenaire_isvisible', $('input[name="partenaire_visible_'+clickedId+'"]:checked').val());
            form.append('partenaire_image', $('#file_result_0').val());

            // Requète ajax d'envoi du formulaire : /src/Controller/AdminController.php -> admin_partenaires()
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/web/partenaire/modify',
                cache: false,
                contentType: false,
                processData: false,
                data: form,
                success: function(data) {
                    // Si il s'agit de l'ajout d'un nouveau partenaire
                    if (clickedId == 0) {
                        toastr.success('Le nouveau partenaire a bien été enregistré', 'Succès');
                        $('#formpartenaire_0')[0].reset();
                        $('#file_upload_0').val('');
                        $('#file_result_0').val('');
                        $('#file_display_0').html('');

                    }
                    // Si il s'agit d'une modification d'partenairealité
                    else {
                        toastr.success('Le partenaire a bien été modifié', 'Succès');
                        // On nettoye le html en supprimant l'onglet 'Modifier/Nouveau'
                        $('#partenaire-tabs-modify-'+clickedId).remove();
                        $('#partenaire-close-modify-'+clickedId).parent().remove();
                    }
                    // Retour à la liste d'partenairealités en rechargeant la datatable
                    partenaire_table.ajax.reload();
                    $('#partenaire-tabs-list-tab').trigger('click');
                }
            });
        }
        return false;
    });
});