if (!String.prototype.includes) {
    String.prototype.includes = function (search, start) {
        'use strict';
        if (typeof start !== 'number') {
            start = 0;
        }

        if (start + search.length > this.length) {
            return false;
        } else {
            return this.indexOf(search, start) !== -1;
        }
    };
}

if (!String.prototype.slugify) {
    String.prototype.slugify = function (separator = "-") {
        return this
            .toString()
            .normalize('NFD')                 // split an accented letter in the base letter and the acent
            .replace(/[\u0300-\u036f]/g, '')  // remove all previously split accents
            .toLowerCase()
            .trim()
            .replace(/[^a-z0-9 ]/g, '')       // remove all chars not letters, numbers and spaces (to be replaced)
            .replace(/\s+/g, separator);
    };
}

if (!String.prototype.unformatNumber) {
    String.prototype.unformatNumber = function () {
        return this
            .replaceAll(",", ".")             // replace comma by dot
            .replace(/(?!([.]))\D/g, "");     // remove all chars not numbers and dots
    };
}

if (!String.prototype.reverseDayMonth) {
    String.prototype.reverseDayMonth = function () {
        return this.replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3");
    };
}

if (!String.prototype.fixUTF8) {
    String.prototype.fixUTF8 = function () {
        return this
            .replace(/Ã\x89/g, 'É').replace(/Ã©/g, 'é')
            .replace(/Ã\x88/g, 'È').replace(/Ã¨/g, 'è')
            .replace(/Ã\x8A/g, 'Ê').replace(/Ãª/g, 'ê')
            .replace(/Ã\x82/g, 'Â').replace(/Ã¢/g, 'â')
            .replace(/Ã\x94/g, 'Ô').replace(/Ã´/g, 'ô')
            .replace(/Ã\x80/g, 'À').replace(/Ã\s/g, 'à')
            .replace(/Ã\x87/g, 'Ç').replace(/Ã§/g, 'ç')
            .replace(/Ã\x99/g, 'Ù').replace(/Ã¹/g, 'ù')
            .replace(/Ã\x9B/g, 'Û').replace(/Ã»/g, 'û')
            .replace(/â\x80/g, '€');
    };
}

if (!String.prototype.decodeHtmlEntities) {
    String.prototype.decodeHtmlEntities = function () {
        const htmlEntities = {
            '&EACUTE;': 'É', '&Eacute;': 'É', '&eacute;': 'é',
            '&AGRAVE;': 'À', '&Agrave;': 'À', '&agrave;': 'à',
            '&UGRAVE;': 'Ù', '&Ugrave;': 'Ù', '&ugrave;': 'ù',
            '&OGRAVE;': 'Ò', '&Ograve;': 'Ò', '&ograve;': 'ò',
            '&CCEDIL;': 'Ç', '&Ccedil;': 'Ç', '&ccedil;': 'ç',
            '&EGRAVE;': 'È', '&Egrave;': 'È', '&egrave;': 'è'
        };

        // Remplace les entités HTML dans la chaîne
        return this.replace(/&[a-zA-Z]+;/g, (match) => htmlEntities[match] || match);
    };
}

if (!Number.prototype.formatNumber) {
    Number.prototype.formatNumber = function () {
        return this
            .toLocaleString('fr-FR')
            .replace(/\s/g, ' ');
    };
}

Array.prototype.distinct = function () {
    if (Array.isArray(this)) {
        return this.filter((item, i, ar) => ar.indexOf(item) === i);
    }
    return this;
};

Array.prototype.diff = function (a = []) {
    const arr1 = this, arr2 = a || [];
    return arr1.filter(x => !arr2.includes(x)).concat(arr2.filter(x => !arr1.includes(x)));
}

var valueDescriptor = Object.getOwnPropertyDescriptor(HTMLInputElement.prototype, "value");

HTMLInputElement.prototype.addInputChangedByJsListener = function (cb) {
    if (!this.hasOwnProperty("_inputChangedByJSListeners")) {
        this._inputChangedByJSListeners = [];
    }
    this._inputChangedByJSListeners.push(cb);
}

Object.defineProperty(HTMLInputElement.prototype, "value", {
    get: function () {
        return valueDescriptor.get.apply(this, arguments);
    },
    set: function () {
        var self = this;
        valueDescriptor.set.apply(self, arguments);
        if (this.hasOwnProperty("_inputChangedByJSListeners")) {
            this._inputChangedByJSListeners.forEach(function (cb) {
                cb.apply(self);
            })
        }
    }
});

// Renvoie true si nous sommes sous IE11, sinon false
var IE11 = !!window.msCrypto;

// Retourne l'id 1234 pour une string de la forme quelquechose_autrechose_1234
function extractId(element) {
    return element.split(/[_ ]+/).pop();
}

function makeSummerNote(id, height, version) {
    if (height == "") { height = 300; }
    if (version == "") { version = ['']; }

    toolbar = [
        ['style', ['bold', 'italic', 'underline', 'clear']],
        ['font', ['strikethrough', 'superscript', 'subscript']],
        ['color', ['color']],
    ]

    if ((version + "").search('text') > -1) {
        //if (version.includes('text')) {
        toolbar.push(['fontsize', ['fontsize']]);
    }
    if ((version + "").search('full') > -1) {
        //if (version.includes('full')) {
        toolbar.push(['para', ['ul', 'ol', 'paragraph', 'height']]);
        toolbar.push(['insert', ['link', 'picture', 'video', 'hr']]);
        toolbar.push(['codeview']);
        toolbar.push(['fontsize', ['fontsize']]);
        toolbar.push(['table', ['table']]);
    }

    //$("[id*='_content_']").summernote({
    $("#" + id).summernote({
        tabsize: 1,
        lang: 'fr-FR',
        height: height,
        minHeight: height,
        tooltip: false,
        toolbar: toolbar,
    });
}

function makeSureFields(id) {
    var clickedId = id.split(/[- ]+/).pop();
    var type = id.split(/[- ]+/)[0];
    var errors = Array();
    $('select[id^="' + type + '"], input[id^="' + type + '"]').each(function () {
        if ($(this).data('required') !== undefined) {
            if (this.id.split(/[_ ]+/).pop() == clickedId) {
                if (($(this).prop('tagName').toLowerCase() == "input" && $(this).val() == "") || ($(this).prop('tagName').toLowerCase() == "select" && $(this).children("option:selected").val() == "")) {
                    errors.push(this.id);
                }
            }
        }
    })
    for (error in errors) {
        $('#' + errors[error]).addClass('form-required');
        toastr.error('Le champ "' + $('label[for="' + errors[error] + '"]').html() + '"" n\'est pas rempli', 'Erreur');
    }
    if (errors.length >= 1) { return false; } else { return true; };
}

var siteURL = (function () { return $('#get_host').html(); })();
var siteProURL = (function () { return $('#get_host_pro').html(); })();
var sitePrestaURL = (function () { return $('#get_host_presta').html(); })();

function htmlDecode(input) {
    var e = document.createElement('textarea');
    e.innerHTML = input;
    // handle case of empty input
    return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
}

const tabCodePostal = {
    "01": "Ain",
    "02": "Aisne",
    "03": "Allier",
    "04": "Alpes-de-Haute-Provence",
    "05": "Hautes-Alpes",
    "06": "Alpes-Maritimes",
    "07": "Ardèche",
    "08": "Ardennes",
    "09": "Ariège",
    "10": "Aube",
    "11": "Aude",
    "12": "Aveyron",
    "13": "Bouches-du-Rhône",
    "14": "Calvados",
    "15": "Cantal",
    "16": "Charente",
    "17": "Charente-Maritime",
    "18": "Cher",
    "19": "Corrèze",
    "2A": "Corse-du-Sud",
    "2B": "Haute-Corse",
    "21": "Côte-d'Or",
    "22": "Côtes-d'Armor",
    "23": "Creuse",
    "24": "Dordogne",
    "25": "Doubs",
    "26": "Drôme",
    "27": "Eure",
    "28": "Eure-et-Loir",
    "29": "Finistère",
    "30": "Gard",
    "31": "Haute-Garonne",
    "32": "Gers",
    "33": "Gironde",
    "34": "Hérault",
    "35": "Ille-et-Vilaine",
    "36": "Indre",
    "37": "Indre-et-Loire",
    "38": "Isère",
    "39": "Jura",
    "40": "Landes",
    "41": "Loir-et-Cher",
    "42": "Loire",
    "43": "Haute-Loire",
    "44": "Loire-Atlantique",
    "45": "Loiret",
    "46": "Lot",
    "47": "Lot-et-Garonne",
    "48": "Lozère",
    "49": "Maine-et-Loire",
    "50": "Manche",
    "51": "Marne",
    "52": "Haute-Marne",
    "53": "Mayenne",
    "54": "Meurthe-et-Moselle",
    "55": "Meuse",
    "56": "Morbihan",
    "57": "Moselle",
    "58": "Nièvre",
    "59": "Nord",
    "60": "Oise",
    "61": "Orne",
    "62": "Pas-de-Calais",
    "63": "Puy-de-Dôme",
    "64": "Pyrénées-Atlantiques",
    "65": "Hautes-Pyrénées",
    "66": "Pyrénées-Orientales",
    "67": "Bas-Rhin",
    "68": "Haut-Rhin",
    "69": "Rhône",
    "70": "Haute-Saône",
    "71": "Saône-et-Loire",
    "72": "Sarthe",
    "73": "Savoie",
    "74": "Haute-Savoie",
    "75": "Paris",
    "76": "Seine-Maritime",
    "77": "Seine-et-Marne",
    "78": "Yvelines",
    "79": "Deux-Sèvres",
    "80": "Somme",
    "81": "Tarn",
    "82": "Tarn-et-Garonne",
    "83": "Var",
    "84": "Vaucluse",
    "85": "Vendée",
    "86": "Vienne",
    "87": "Haute-Vienne",
    "88": "Vosges",
    "89": "Yonne",
    "90": "Territoire de Belfort",
    "91": "Essonne",
    "92": "Hauts-de-Seine",
    "93": "Seine-Saint-Denis",
    "94": "Val-de-Marne",
    "95": "Val-d'Oise",
};

const dataTablesColvisBtn = (customColumns = '') => {
    return {
        extend: 'colvis',
        text: 'Colonnes',
        columns: customColumns,
        className: 'btn-sm btn-info rounded-5'
    }
};

const dataTablesCustomDom = ({ scrollable = false, colvis = true, graphs = false, addbtn = false } = {}) => {
    /**
     * @type {object} TOP
     */
    const top = {
        class: "row",
        content: [
            {
                class: "col-12 col-md-6 d-flex flex-wrap justify-content-start align-items-center gap-3 pb-3",
                content: [
                    { class: "d-none d-md-block", content: "l" },
                    { class: "mb-n2 mb-md-0", content: "B", ok: colvis },
                    { class: "dt-dom-colvis d-none d-md-block", ok: colvis },
                    { class: "dt-dom-graphs", ok: graphs },
                    { class: "dt-dom-addbtn", ok: addbtn },
                    { class: "dt-dom-filter d-md-none d-block" },
                ]
            },
            {
                class: "col-12 col-md-6 d-flex flex-wrap justify-content-end align-items-center gap-3 pb-3",
                content: [
                    { class: "dt-dom-filter d-none d-md-block" },
                    { class: "dt-dom-action d-none d-md-block" },
                    { content: "f" },
                    { class: "dt-dom-ocrvin" },
                ]
            },
        ]
    }; // TOP

    /**
     * @type {object} MID
     */
    const mid = {
        class: (scrollable ? "container mw-100" : "row"),
        content: [
            { class: (scrollable ? "table-responsive" : "col-12"), content: "tr" },
        ]
    }; // MID

    /**
     * @type {object} BTM
     */
    const btm = {
        class: "row",
        content: [
            { class: "col-12 col-md-5", content: "i" },
            { class: "col-12 col-md-7", content: "p" },
        ]
    }; // BTM

    /**
     * @function buildDtDom
     * @param {object} data
     * @returns A string containing the DOM code for the DataTable
     */
    const buildDtDom = (data) => {
        let result = "<";
        if (data.hasOwnProperty("class")) {
            result += "'" + data.class + "'";
        }
        if (data.hasOwnProperty("content")) {
            if (Array.isArray(data.content)) {
                data.content.forEach(function(child) {
                    if (!(child.hasOwnProperty("ok") && child.ok === false)) {
                        result += buildDtDom(child);
                    }
                });
            } else {
                result += data.content;
            }
        }
        return result + ">";
    } // buildDtDom

    return buildDtDom(top) + buildDtDom(mid) + buildDtDom(btm);
};

function initMirrorBtn() {
    const colvis = $(".colvis-button-container").find("button");
    const filter = $(".action-button-container").find("[data-widget='control-sidebar']:not(#close-sidebar-right)");
    const action = $(".action-button-container").find(".admin-action-btn");
    const ocrvin = $(".ocrvin-button-container").find("#btn-stock-canva-photo-vin");
    const graphs = $(".graphs-button-container").find("button");
    const addbtn = $(".addbtn-button-container").find(".btn");
    colvis.toArray().forEach(colvis => { $(colvis).parents(".colvis-button-container").parent().find(".dt-dom-colvis").html($(colvis).clone()); });
    filter.toArray().forEach(filter => { $(filter).parents(".action-button-container").parent().find(".dt-dom-filter").html($(filter).clone()); });
    action.toArray().forEach(action => { $(action).parents(".action-button-container").parent().find(".dt-dom-action").html($(action).clone()); });
    ocrvin.toArray().forEach(ocrvin => { $(ocrvin).parents(".ocrvin-button-container").parent().find(".dt-dom-ocrvin").html($(ocrvin).clone()); });
    graphs.toArray().forEach(graphs => { $(graphs).parents(".graphs-button-container").parent().find(".dt-dom-graphs").html($(graphs).clone()); });
    addbtn.toArray().forEach(addbtn => { $(addbtn).parents(".addbtn-button-container").parent().find(".dt-dom-addbtn").html($(addbtn).clone()); });
}

function selectpickerReset(selectpickerList, id = 0) {
    selectpickerList.forEach(element => { $("#" + element + id).val('default'); });
    selectpickerRefresh(selectpickerList, id);
}

function selectpickerRefresh(selectpickerList, id = 0) {
    selectpickerList.forEach(element => { $("#" + element + id).selectpicker("refresh"); });
}

function getSelectedRows(baseId) {
    // Get rows with checked checkboxes
    return $("#" + baseId + "table").dataTable().api().rows(function (idx, data, node) {
        // Get all the checkboxes in the row
        let cells = $(node).find("[id^='" + baseId + "selection-']");
        // Keep the rows with checked checkboxes
        return cells.filter(function (index) {
            return $(cells[index]).prop('checked');
        }).length;
    }).data().toArray().map((r) => r["DT_RowId"].replace("" + baseId + "tr-", ""));
}

function dateDiff(date1, date2) {
    var diff = {}                             // Initialisation du retour
    var tmp = date2 - date1;
    tmp = Math.floor(tmp / 1000);             // Nombre de secondes entre les 2 dates
    diff.sec = tmp % 60;                      // Extraction du nombre de secondes
    tmp = Math.floor((tmp - diff.sec) / 60);  // Nombre de minutes (partie entière)
    diff.min = tmp % 60;                      // Extraction du nombre de minutes
    tmp = Math.floor((tmp - diff.min) / 60);  // Nombre d'heures (entières)
    diff.hour = tmp % 24;                     // Extraction du nombre d'heures
    tmp = Math.floor((tmp - diff.hour) / 24); // Nombre de jours restants
    diff.day = tmp;
    return diff;
}

function drawDateDiff(diff) {
    if (diff.day != 0) {
        return diff.day + " jour" + (diff.day > 1 ? "s" : "");
    } else if (diff.hour != 0) {
        return diff.hour + " heure" + (diff.hour > 1 ? "s" : "");
    } else if (diff.min != 0) {
        return diff.min + " minute" + (diff.min > 1 ? "s" : "");
    } else {
        return diff.sec + " seconde" + (diff.sec > 1 ? "s" : "");
    }
}

function getStatusMailIds(listEl) {
    return listEl.toArray().map((el) => { return el.id.replace(/\D*(\d*)/g, '$1') });
}

function getStatusMailAsynchrone(listEl, i = 0) {
    if (getStatusMailIds(listEl).length) {
        let j = i + 1;
        let optionsRecherche = new FormData();
        optionsRecherche.append('id', getStatusMailIds(listEl).slice(i, j));
        $.ajax({
            type: 'POST',
            url: siteURL + 'admin/annuaire/suiviRelation/statutMail',
            contentType: false,
            processData: false,
            data: optionsRecherche,
            success: function (data) {
                $("#" + listEl.toArray().slice(i, j)[0].id).html(data);
            },
            complete: function () {
                if (j < listEl.length) {
                    getStatusMailAsynchrone(listEl, j);
                }
            }
        });
    }
}

function getStatusCampagneMailAsynchrone(listEl, i = 0) {
    if (getStatusMailIds(listEl).length) {
        let j = i + 1;
        let optionsRecherche = new FormData();
        let idCampagne = getStatusMailIds(listEl).slice(i, j);
        let idAnnuaire = $("#annuaire-suiviRelation-status-campagne-mail-" + idCampagne[0]).data('idannuaire');
        optionsRecherche.append('idCampagne', idCampagne[0]);
        optionsRecherche.append('idAnnuaire', idAnnuaire);
        $.ajax({
            type: 'POST',
            url: siteURL + 'admin/campagne/mailjetStatsIndividuel/' + idCampagne,
            contentType: false,
            processData: false,
            data: optionsRecherche,
            success: function (data) {
                $("#" + listEl.toArray().slice(i, j)[0].id).html(data);
            },
            complete: function () {
                if (j < listEl.length) {
                    getStatusCampagneMailAsynchrone(listEl, j);
                }
            }
        });
    }
}

function formatNumber(amount, decimalCount = 2, decimal = ".", thousands = " ") { // Source : https://stackoverflow.com/questions/149055/how-to-format-numbers-as-currency-strings
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? "-" : "";

        let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;

        return negativeSign +
            (j ? i.substr(0, j) + thousands : '') +
            i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
            (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
        console.log(e)
    }
};

/**
 * Crée un élément HTML à partir d'une chaîne de caractères HTML.
 * @param {string} htmlString - La chaîne de caractères HTML à parser.
 * @returns {Element} - L'élément HTML créé.
 */
function createElementFromHTML(htmlString) {
    var div = document.createElement('div');
    div.innerHTML = htmlString.trim();
    // Change this to div.childNodes to support multiple top-level nodes.
    return div.firstElementChild;
}

/**
 * Convertit un timestamp en date au format JJ/MM/AAAA
 * @param {number} timestamp - Le timestamp à convertir
 * @param {boolean} time - Indique si on retourne une heure au format HH:MM
 * @returns {string} - La date au format JJ/MM/AAAA ou JJ/MM/AAAA HH:MM
 */
function timestampToFormatedDate(timestamp, time = false) {
    const date = new Date(timestamp); // Convertir le timestamp en objet Date
    /**
     * Retourne une date au format JJ/MM/AAAA
     * JJ - Jour avec un zéro devant si nécessaire. Exemple : 1 = 01
     * MM - Mois avec un zéro devant si nécessaire. Le mois commence à 0, donc +1. Exemple : janvier = 0, donc +1 -> janvier = 01
     * AAAA - Année complète. Exemple : 2024
     * HH - Heure avec un zéro devant si nécessaire. Exemple : 1 = 01
     * MM - Minutes avec un zéro devant si nécessaire. Exemple : 1 = 01
     */
    return `${String(date.getDate()).padStart(2, '0')}/${String(date.getMonth() + 1).padStart(2, '0')}/${date.getFullYear()}${time ? ' à ' + String(date.getHours()).padStart(2, '0') + ':' + String(date.getMinutes()).padStart(2, '0') : ''}`;
}

// Conversion de feuille excel en array JS
function sheet2arr(sheet) {
    var result = [];
    var row;
    var rowNum;
    var colNum;
    var range = XLSX.utils.decode_range(sheet['!ref']);
    for (rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
        row = [];
        for (colNum = range.s.c; colNum <= range.e.c; colNum++) {
            var nextCell = sheet[
                XLSX.utils.encode_cell({ r: rowNum, c: colNum })
            ];
            if (typeof nextCell === 'undefined') {
                row.push(void 0);
            } else row.push(nextCell.w);
        }
        result.push(row.map(str => str != undefined ? str.fixUTF8().decodeHtmlEntities() : undefined));
    }
    return result;
};

const regexAntiHTML = /<[^>]+>/gm;

const isFileInputLabel = (e) => { return e.type === "click" && e.target && e.target.tagName === "LABEL" && e.target.previousElementSibling && e.target.previousElementSibling.tagName === "INPUT" && e.target.previousElementSibling.getAttribute("type") === "file" };
const isFileInput = (e) => { return e.type === "click" && e.target && e.target.tagName === "INPUT" && e.target.getAttribute("type") === "file" };
const isCheckboxInputLabel = (e) => { return e.type === "click" && e.target && e.target.tagName === "LABEL" && e.target.previousElementSibling && e.target.previousElementSibling.tagName === "INPUT" && e.target.previousElementSibling.getAttribute("type") === "checkbox" };
const isCheckboxInput = (e) => { return e.type === "click" && e.target && e.target.tagName === "INPUT" && e.target.getAttribute("type") === "checkbox" };
const isDateInputLabel = (e) => { return e.type === "click" && e.target && e.target.tagName === "LABEL" && e.target.previousElementSibling && e.target.previousElementSibling.tagName === "INPUT" && e.target.previousElementSibling.getAttribute("type") === "date" };
const isDateInput = (e) => { return e.type === "click" && e.target && e.target.tagName === "INPUT" && e.target.getAttribute("type") === "date" };

$('body').on('click', "[copy]", (e) => {
    navigator.clipboard.writeText(e.target.getAttribute("copy"));
    e.target.setAttribute("copied", "");
    setTimeout((e) => { e.target.removeAttribute("copied"); }, 600, e);
});

/**
 * Return if click event is on pseudo-element
 * @param {Event} e
 * @param {string} type
 * @returns {bool}
 */
function pseudoElementIsClick(e, type) {
    // First we get the pseudo-elements style
    const target = e.currentTarget || e.target
    const after = window.getComputedStyle(target, ":" + type)
    if (after) {
        // Then we parse out the dimensions
        const top = Number(after.getPropertyValue("top").slice(0, -2))
        const height = Number(after.getPropertyValue("height").slice(0, -2))
        const left = Number(after.getPropertyValue("left").slice(0, -2))
        const width = Number(after.getPropertyValue("width").slice(0, -2))
        // And get the mouse position
        const ex = e.originalEvent.layerX
        const ey = e.originalEvent.layerY
        // Finally we do a bounds check (Is the mouse inside of the after element)
        if (ex > left && ex < left + width && ey > top && ey < top + height) {
            return true;
        }
    }
    return false;
}

function matchVIN(vin) {
    const regex = /^(?!.*([A-HJ-NPR-Z0-9])\1{8})[A-HJ-NPR-Z0-9]{17}$/g;
    const m = regex.exec(vin);
    return m != null;
}

async function getBase64FromUrl(url) {
    // Fetch the image data
    const response = await fetch(url);
    const blob = await response.blob();

    // Read the blob data as a Base64 string
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
}

// Fonction pour créer un barre de progression
function createProgressBar(width, bgColor = "success") {
    return Object.assign(document.createElement('div'), { className: 'progress-bar bg-' + bgColor, role: 'progressbar', style: 'width: ' + width });
}

// Fonction pour l'importation de fichier dans les inputs
function importFileInInput(file, idInputCible) {
    const input = document.getElementById(idInputCible);

    if (!input || input.type !== "file") {
        console.error(`L'élément avec l'ID ${idInputCible} n'est pas un input file.`);
        return;
    }

    // Utiliser DataTransfer pour ajouter des fichiers à l'input
    const dataTransfer = new DataTransfer();

    // Ajouter le nouveau fichier
    dataTransfer.items.add(file);

    // Assigner les fichiers à l'input
    input.files = dataTransfer.files;
}

/**
 * Renvoie un objet qui contient les types de colonne de la table donnée en paramètre
 *
 * @param {string} id - L'ID de la table
 *
 * @returns {array} - Un tableau d'objet qui contient les types de colonne associé aux colonnes, avec les valeurs suivantes :
 *  - {string} type - Le type de la colonne (par exemple "num" ou "date")
 *  - {array} targets - Les index des colonnes qui ont ce type
 *
 * @example
 * const columnDefsType = getColumnDefsType(myTableId);
 * columnDefsType est {
 *  "text": { type: "text", targets: [0, 2] },
 *  "number": { type: "number", targets: [1] }
 * }
 */
function getColumnDefsType(id) {
    const table = document.getElementById(id); // Récupère la table
    if (table !== null) { // Si la table existe
        const ligne = table.querySelector("tbody").querySelector("tr"); // Récupère la première ligne
        if (ligne !== null) { // Si la première ligne existe
            return Object.values( // Transforme l'objet du résultat final en array
                Array.from(ligne.children) // Tranforme l'HTMLCollection en array
                    .map( // Transforme le tableau
                        (cell, index) => // Récupère la cellule en tant que valeur, et la position dans le tableau étudié en tant qu'index
                            Object.assign( // Créer un objet
                                {}, // À partir de rien
                                { // En ajoutant
                                    index: index, // Le numéro correspondant à la colonne
                                    type: cell.getAttribute("data-type") // Le type de la colonne en fonction de l'attribut
                                }
                            )
                    )
                    .filter(v => v.type != null) // Filtre les types non null pour ne garder que les data-type qui existait
                    .reduce( // Transforme la liste des colonnes avec type associé en une liste des types avec colonnes associé
                        (acc, curr) => // Récupère l'accumulateur et la valeur en question
                        (
                            acc[curr.type] = acc[curr.type] || { type: curr.type, targets: [] }, // Affecte la valeur de l'accumulateur pour le type en question par lui même si il existe ou l'initialise
                            acc[curr.type].targets.push(curr.index), // Ajout l'index en question aux targets du type en question
                            acc // Pour que la ligne retourne l'accumulateur
                        ),
                        {} // Démarre l'accumulation avec un objet vide
                    )
            ); // Organise les donnés dans leur forme final avec un accumulateur objet
        }
    }
    return [];
}
