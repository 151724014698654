if ($("#widget-taux-remplissage-parc").length) {
    /**
     * Fonction de loading
     * @param {Object} reload
     */
    function widgetTauxRemplissageParcAjax() {
        // Requête ajax
        $.ajax({
            type: 'POST',
            url: siteURL + 'admin/widget/taux-remplissage-parc',
            contentType: false,
            processData: false,
            success: function (data) {
                if (data != null) {
                    $("#taux-remplissage-parc-results-div").html(data);
                }

                // On appelle la requête du prochain widget
                nextWidgetRequeteAjax();
            }
        });
    }
};