// Génération de la datatable des generateur clickandlease
clickandlease_table = $('#clickandlease_table').DataTable({
    "autoWidth": false,
    "language": {
        "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/fr-FR.json"
    },
    "responsive": true,
    "ajax": {
        "url": siteURL+'json/vehicules',
        "type": "POST",
    },
    "order": [
        [0, "desc"]
    ],
    "columnDefs": [
        // TEXT CENTER
        {
            "className": "text-center align-middle",
            "targets": [4,5,6,7,8,9,10,11],
        },
        // ALIGN MIDDLE
        {
            "className": "align-middle",
            "targets": '_all',
        },
        // ID
        {
            "name": "id",
            "targets": 0,
            "render": function(data, type, row) {
                return '<a href="'+siteURL+row[0]+'" id="display_vehicule_'+row[0]+'"><i class="fad fa-eye pr-1"></i></a> '+row[0];
            },
        },
        // MARQUE
        {
            "name": "marque",
            "targets": 1,
            "render": function(data, type, row) {
                return row[3];
            },
        },
        // MODELE
        {
            "name": "modele",
            "targets": 2,
            "render": function(data, type, row) {
                return row[4];
            },
        },
        // FINITION
        {
            "targets": 3,
            "render": function(data, type, row) {
                return row[5];
            },
        },
        // ETAT
        {
            "targets": 4,
            "render": function(data, type, row) {
                var kilometrage = row[7];
                if (kilometrage <= 100) {
                    return 'Neuf';
                } else {
                    return 'Occasion';
                }
                // return row[7];
            },
        },
        // KILOMETRAGE
        {
            "targets": 5,
            "render": function(data, type, row) {
                return row[7] + " km";
            },
        },
        // EMPLACEMENT
        {
            "targets": 6,
            "render": function(data, type, row) {
                if (row[11] == null) {
                    return "En arrivage";
                } else {
                    return row[11];
                }

            },
        },
        // DISPONIBLE
        {
            "targets": 7,
            "render": function(data, type, row) {
                if (row[12] == null) {
                    return '<i class="fas fa-check" style="color:green" title="Non Validé"></i><span class="d-inline d-lg-none pl-2">OUI</span>';
                } else {
                    return '<i class="fas fa-times" style="color:red" title="Non Validé"></i><span class="d-inline d-lg-none pl-2">NON</span>';
                }
            },
        },
        // PHOTO
        {
            "width": "25px",
            "targets": 8,
            "render": function(data, type, row) {
                if (row[13] == "") {
                    return '<i class="fas fa-circle" style="color:red" title="Non Validé"></i><span class="d-inline d-lg-none pl-2">NON</span>';
                } else {
                    return '<i class="fas fa-circle" style="color:green" title="Validé"></i><span class="d-inline d-lg-none pl-2">OUI</span>';
                }
            },
            //"orderable": false,
        },
        // LEBONCOIN
        {
            "width": "25px",
            "targets": 9,
            "render": function(data, type, row) {
                if (row[17] == 1) {
                    return '<i class="fas fa-circle" style="color:green" title="Validé"></i><span class="d-inline d-lg-none pl-2">OUI</span>';
                } else {
                    return '<i class="fas fa-circle" style="color:red" title="Non Validé"></i><span class="d-inline d-lg-none pl-2">NON</span>';
                }
                return '<i class="fas fa-circle" style="color:red" title="Non Validé"></i><span class="d-inline d-lg-none pl-2">NON</span>';
            },
            //"orderable": false,
        },
        // CLICK AND LEASE
        {
            "width": "25px",
            "targets": 10,
            "render": function(data, type, row) {
                if (row[16] == 1) {
                    return '<i class="fas fa-circle" style="color:green" title="Validé"></i><span class="d-inline d-lg-none pl-2">OUI</span>';
                } else {
                    return '<i class="fas fa-circle" style="color:red" title="Non Validé"></i><span class="d-inline d-lg-none pl-2">NON</span>';
                }
                return '<i class="fas fa-circle" style="color:red" title="Non Validé"></i><span class="d-inline d-lg-none pl-2">NON</span>';
            },
            // "orderable": false,
        },
        // MODIFY
        {
            "width": "25px",
            "targets": 11,
            "render": function(data, type, row) {
                return '<button class="btn w-100 p-0" id="clickandlease-open-modify-' + row[0] + '" data-category="autres"><i class="fad fa-edit"></i>';
            },
            "orderable": false,
        },
    ],
    "conditionalPaging": true,
});

allLoyer = [];
// clickandleasecopy_table = [];


// Ajouter un loyer dans la liste
$('body').on('click', "button[id^='validate-loyer-']", function() {
    var clickedId = this.id.split(/[- ]+/).pop();
    var numLoyer = this.id.split(/[- ]+/)[2];

    kilometrageVehicule = $('#validate-loyer-'+numLoyer+"-"+id_clicked).data('value');
    // Check si les valeurs sont bien rempli
    if ($("#loyer"+numLoyer+"_prixmois_"+id_clicked).val().length > 0 && $("#loyer"+numLoyer+"_mois_"+id_clicked).val().length > 0 && $("#loyer"+numLoyer+"_apport_"+id_clicked).val().length > 0 && $("#loyer"+numLoyer+"_valeurrachat_"+id_clicked).val().length > 0) {

        // Ajouter la phrase LLD a l'endroit correspondant
        textarea = $("#area_"+clickedId).val();
        textareaSplit = textarea.split('LES FORMULES LEASING :\n');
        formule = textareaSplit[1].split("\n");
        texteLOA = "- " + $("#loyer"+numLoyer+"_prixmois_"+id_clicked).val() + " €/MOIS : SUR " + $("#loyer"+numLoyer+"_mois_"+id_clicked).val() + " MOIS et " + $("#loyer"+numLoyer+"_kilometretotal_"+id_clicked).val() + " Kms AVEC "+ $("#loyer"+numLoyer+"_apport_"+id_clicked).val() +" € D’APPORT";
        switch(numLoyer) {
            case '1' :
                if (formule[0].match(/- .+/) && !(formule[0].match(/- Étude .+/))) {
                    formule[0] = texteLOA;

                    formule = formule.join('\n');
                    result = textareaSplit[0] + 'LES FORMULES LEASING :\n' + formule;
                } else {
                    result = textareaSplit[0] + 'LES FORMULES LEASING :\n' + texteLOA + '\n' + textareaSplit[1];

                    $("#delete-loyer-1-"+clickedId).removeClass("buttonDeleteFalse");
                    $("#delete-loyer-1-"+clickedId).addClass("buttonDeleteTrue");
                }
                break;

            case '2' :
                if (formule[1].match(/- .+/) && !(formule[1].match(/- Étude .+/))) {
                    formule[1] = texteLOA;

                    formule = formule.join('\n');
                    result = textareaSplit[0] + 'LES FORMULES LEASING :\n' + formule;
                } else {
                    formule[0] = formule[0] + '\n' + texteLOA;
                    formule = formule.join('\n');
                    result = textareaSplit[0] + 'LES FORMULES LEASING :\n' + formule;

                    $("#delete-loyer-1-"+clickedId).removeClass("buttonDeleteTrue");
                    $("#delete-loyer-1-"+clickedId).addClass("buttonDeleteFalse");

                    $("#delete-loyer-2-"+clickedId).removeClass("buttonDeleteFalse");
                    $("#delete-loyer-2-"+clickedId).addClass("buttonDeleteTrue");
                }
                break;

            case '3' :
                if (formule[2].match(/- .+/) && !(formule[2].match(/- Étude .+/))) {
                    formule[2] = texteLOA;

                    formule = formule.join('\n');
                    result = textareaSplit[0] + 'LES FORMULES LEASING :\n' + formule;
                } else {
                    formule[1] = formule[1] + '\n' + texteLOA;
                    formule = formule.join('\n');
                    result = textareaSplit[0] + 'LES FORMULES LEASING :\n' + formule;

                    $("#delete-loyer-1-"+clickedId).removeClass("buttonDeleteTrue");
                    $("#delete-loyer-1-"+clickedId).addClass("buttonDeleteFalse");

                    $("#delete-loyer-2-"+clickedId).removeClass("buttonDeleteTrue");
                    $("#delete-loyer-2-"+clickedId).addClass("buttonDeleteFalse");

                    $("#delete-loyer-3-"+clickedId).removeClass("buttonDeleteFalse");
                    $("#delete-loyer-3-"+clickedId).addClass("buttonDeleteTrue");
                }
                break;

            case '4' :
                if (formule[3].match(/- .+/) && !(formule[3].match(/- Étude .+/))) {
                    formule[3] = texteLOA;
                    formule = formule.join('\n');
                    result = textareaSplit[0] + 'LES FORMULES LEASING :\n' + formule;
                } else {
                    formule[2] = formule[2] + '\n' + texteLOA;
                    formule = formule.join('\n');
                    result = textareaSplit[0] + 'LES FORMULES LEASING :\n' + formule;

                    $("#delete-loyer-1-"+clickedId).removeClass("buttonDeleteTrue");
                    $("#delete-loyer-1-"+clickedId).addClass("buttonDeleteFalse");

                    $("#delete-loyer-2-"+clickedId).removeClass("buttonDeleteTrue");
                    $("#delete-loyer-2-"+clickedId).addClass("buttonDeleteFalse");

                    $("#delete-loyer-3-"+clickedId).removeClass("buttonDeleteTrue");
                    $("#delete-loyer-3-"+clickedId).addClass("buttonDeleteFalse");

                    $("#delete-loyer-4-"+clickedId).removeClass("buttonDeleteFalse");
                    $("#delete-loyer-4-"+clickedId).addClass("buttonDeleteTrue");
                }
                break;

            default:
                return false
                break;


        }

        $("#area_"+clickedId).val(result);

        detention = parseInt($("#loyer"+numLoyer+"_prixmois_"+id_clicked).val()) + (parseInt($("#loyer"+numLoyer+"_apport_"+id_clicked).val()) / parseInt($("#loyer"+numLoyer+"_mois_"+id_clicked).val()));
        $("#loyer"+numLoyer+"_detention_"+id_clicked).val((Math.round(detention * 100) / 100).toFixed(2));
        kilometrageFinal = parseInt($("#loyer"+numLoyer+"_kilometretotal_"+id_clicked).val()) + parseInt(kilometrageVehicule);
        $("#loyer"+numLoyer+"_kilometragefinal_"+id_clicked).val(kilometrageFinal);

        if (typeof allLoyer[clickedId] === "undefined") {
            allLoyer[clickedId] = [];
        }
        allLoyer[clickedId][numLoyer] = {prixMois: $("#loyer"+numLoyer+"_prixmois_"+id_clicked).val(), mois: $("#loyer"+numLoyer+"_mois_"+id_clicked).val(), apport: $("#loyer"+numLoyer+"_apport_"+id_clicked).val(), kilometreTotal: $("#loyer"+numLoyer+"_kilometretotal_"+id_clicked).val(), valeurRachat: $("#loyer"+numLoyer+"_valeurrachat_"+id_clicked).val()};
        // Permet d'autoriser le changement des selects
        if (numLoyer == 1) {
            $("#clickandlease_clickandlease_"+id_clicked).prop('disabled', false);
            $("#clickandlease_leboncoin_"+id_clicked).prop('disabled', false);
            titre = $("#titre_clickandlease_"+id_clicked).val();
            temp = titre.split('dès');
            titresuite = temp[1].split(' ');

            titre = temp[0] + "dès " + $("#loyer"+numLoyer+"_prixmois_"+id_clicked).val() + "€/MOIS ";
            titresuite.splice(0,1);
            titresuite.splice(0,1);
            for (word of titresuite) {
                titre += word + " ";
            }
            // $("#titre_clickandlease_"+id_clicked).val(temp[0] + "à partir de " + $("#loyer"+numLoyer+"_prixmois_"+id_clicked).val() + "€/MOIS");
            $("#titre_clickandlease_"+id_clicked).val(titre);

            $("#loyer1_clickandlease_caracteristique_"+id_clicked).val($("#loyer"+numLoyer+"_prixmois_"+id_clicked).val());

            $("#prixpublic_clickandlease_"+id_clicked).val($("#loyer"+numLoyer+"_prixmois_"+id_clicked).val() + "€");

            $("#clickandlease-copy-modify-"+id_clicked).prop('disabled', false);

        }
        // Possibilité de rajouter le prochain loyer
        if (numLoyer == 1 || numLoyer == 2 || numLoyer == 3) {
            nextLoyer = parseInt(numLoyer) + 1;
            $('#validate-loyer-'+nextLoyer+'-'+clickedId).prop('disabled', false);
        }

    } else {
        toastr.error('Merci de remplir les champs du loyer '+numLoyer+'', 'Erreur');
    }
    return false;
})

// Supprimer un champ de loyer
$('body').on('click', "i[id^='delete-loyer-']", function() {
    var clickedId = this.id.split(/[- ]+/).pop();
    var numLoyer = this.id.split(/[- ]+/)[2];
    if ($(this).hasClass("buttonDeleteFalse")) {
        toastr.error('Merci de supprimer dans l\'ordre décroissant', 'Erreur');
    } else {

        textarea = $("#area_"+clickedId).val();
        textareaSplit = textarea.split('LES FORMULES LEASING :\n');
        formule = textareaSplit[1].split("\n");
        switch(numLoyer) {
            case '1' :
                if (formule[0].match(/- .+/)) {
                    formule[0] = "";
                    // Supprime la ligne dans la textarea
                    formule = formule.join('\n');
                    formule = formule.slice(1);
                    result = textareaSplit[0] + 'LES FORMULES LEASING :\n' + formule;
                    // Vide les input
                    $("#loyer1_prixmois_"+clickedId).val("");
                    $("#loyer1_mois_"+clickedId).val("");
                    $("#loyer1_apport_"+clickedId).val("");
                    $("#loyer1_kilometretotal_"+clickedId).val("");
                    $("#loyer1_valeurrachat_"+clickedId).val("");

                    $("#loyer1_detention_"+id_clicked).val("");
                    $("#loyer1_kilometragefinal_"+id_clicked).val("");

                    // Ajout ou supprime les button valider et supprimer
                    $("#delete-loyer-1-"+clickedId).removeClass("buttonDeleteTrue");
                    $("#delete-loyer-1-"+clickedId).addClass("buttonDeleteFalse");
                    $('#validate-loyer-2-'+clickedId).prop('disabled', 'disabled');

                    $("#clickandlease_clickandlease_"+id_clicked).val(0);
                    $("#clickandlease_leboncoin_"+id_clicked).val(0);
                    $("#clickandlease_clickandlease_"+id_clicked).prop('disabled', 'disabled');
                    $("#clickandlease_leboncoin_"+id_clicked).prop('disabled', 'disabled');

                    $("#clickandlease-copy-modify-"+id_clicked).prop('disabled', 'disabled');

                    $("#loyer1_clickandlease_caracteristique_"+id_clicked).val("");

                    titre = $("#titre_clickandlease_"+id_clicked).val();
                    temp = titre.split('dès');
                    titresuite = temp[1].split(' ');

                    titre = temp[0] + "dès X€/MOIS";
                    titresuite.splice(0,1);
                    titresuite.splice(0,1);
                    for (word of titresuite) {
                        titre += word + " ";
                    }
                    $("#titre_clickandlease_"+id_clicked).val(titre);

                    $("#prixpublic_clickandlease_"+id_clicked).val("X€");
                }
                break;

            case '2' :
                if (formule[1].match(/- .+/)) {
                    formule[1] = "";

                    formule = formule.join('\n');
                    result = textareaSplit[0] + 'LES FORMULES LEASING :\n' + formule;

                    $("#loyer2_prixmois_"+clickedId).val("");
                    $("#loyer2_mois_"+clickedId).val("");
                    $("#loyer2_apport_"+clickedId).val("");
                    $("#loyer2_kilometretotal_"+clickedId).val("");
                    $("#loyer2_valeurrachat_"+clickedId).val("");

                    $("#loyer2_detention_"+id_clicked).val("");
                    $("#loyer2_kilometragefinal_"+id_clicked).val("");

                    $("#delete-loyer-1-"+clickedId).removeClass("buttonDeleteFalse");
                    $("#delete-loyer-1-"+clickedId).addClass("buttonDeleteTrue");

                    $("#delete-loyer-2-"+clickedId).removeClass("buttonDeleteTrue");
                    $("#delete-loyer-2-"+clickedId).addClass("buttonDeleteFalse");
                    $('#validate-loyer-3-'+clickedId).prop('disabled', 'disabled');
                }
                break;

            case '3' :
                if (formule[2].match(/- .+/)) {
                    formule[2] = "";

                    formule = formule.join('\n');
                    result = textareaSplit[0] + 'LES FORMULES LEASING :\n' + formule;

                    $("#loyer3_prixmois_"+clickedId).val("");
                    $("#loyer3_mois_"+clickedId).val("");
                    $("#loyer3_apport_"+clickedId).val("");
                    $("#loyer3_kilometretotal_"+clickedId).val("");
                    $("#loyer3_valeurrachat_"+clickedId).val("");

                    $("#loyer3_detention_"+id_clicked).val("");
                    $("#loyer3_kilometragefinal_"+id_clicked).val("");

                    $("#delete-loyer-2-"+clickedId).removeClass("buttonDeleteFalse");
                    $("#delete-loyer-2-"+clickedId).addClass("buttonDeleteTrue");

                    $("#delete-loyer-3-"+clickedId).removeClass("buttonDeleteTrue");
                    $("#delete-loyer-3-"+clickedId).addClass("buttonDeleteFalse");
                    $('#validate-loyer-4-'+clickedId).prop('disabled', 'disabled');
                }
                break;

            case '4' :
                if (formule[3].match(/- .+/)) {
                    formule[3] = "";

                    formule = formule.join('\n');
                    result = textareaSplit[0] + 'LES FORMULES LEASING :\n' + formule;

                    $("#loyer4_prixmois_"+clickedId).val("");
                    $("#loyer4_mois_"+clickedId).val("");
                    $("#loyer4_apport_"+clickedId).val("");
                    $("#loyer4_kilometretotal_"+clickedId).val("");
                    $("#loyer4_valeurrachat_"+clickedId).val("");

                    $("#loyer4_detention_"+id_clicked).val("");
                    $("#loyer4_kilometragefinal_"+id_clicked).val("");

                    $("#delete-loyer-3-"+clickedId).removeClass("buttonDeleteFalse");
                    $("#delete-loyer-3-"+clickedId).addClass("buttonDeleteTrue");

                    $("#delete-loyer-4-"+clickedId).removeClass("buttonDeleteTrue");
                    $("#delete-loyer-4-"+clickedId).addClass("buttonDeleteFalse");
                }
                break;

            default:
                return false
                break;
        }

        allLoyer[clickedId][numLoyer] = {prixMois: "", mois: "", apport: "", kilometreTotal: "", valeurRachat: ""};
        $("#area_"+clickedId).val(result);
    }

});

//Envoi de modification generateurclickandlease
$('body').on('click', "button[id^='clickandlease-send-modify-']", function() {
    var clickedId = this.id.split(/[- ]+/).pop();
    if (makeSureFields(this.id) == true) {
        var form = new FormData();
        // On extrait le contenu du formulaire dans la variable 'form'
        form.append('id', clickedId);
        form.append('leboncoin', $('#clickandlease_leboncoin_' + clickedId).val());
        form.append('clickandlease', $('#clickandlease_clickandlease_' + clickedId).val());
        form.append('loyer-1-prixmois', allLoyer[clickedId][1]['prixMois']);
        form.append('loyer-1-mois', allLoyer[clickedId][1]['mois']);
        form.append('loyer-1-apport', allLoyer[clickedId][1]['apport']);

        form.append('loyer-1-kilometretotal', allLoyer[clickedId][1]['kilometreTotal']);
        form.append('loyer-1-valeurrachat', allLoyer[clickedId][1]['valeurRachat']);

        resultArrayLoyerSup = [];
        first = true;
        allLoyer[clickedId].forEach(function(item) {
            if (first) {
                first = false;
            } else {
                resultArrayLoyerSup.push(item);
            }
        });

        form.append('loyerSupplementaire', JSON.stringify(resultArrayLoyerSup));

        $.ajax({
            type: 'POST',
            url: siteURL+'admin/autres/clickandlease/modify',
            contentType: false,
            processData: false,
            data: form,
            success: function(data) {

                if (data == "exists") {
                    toastr.error('Erreur', 'Erreur');
                }
                else {
                    if (clickedId == 0) {
                        toastr.success('Succès', 'Succès');
                    }
                    else {
                        toastr.success('Les loyers ont bien été enregistrés', 'Succès');
                        $('#clickandlease-tabs-modify-' + clickedId).remove();
                        $('#clickandlease-close-modify-' + clickedId).parent().remove();
                    }
                    clickandlease_table.ajax.reload();
                    $('#clickandlease-tabs-list-tab').trigger('click');
                }
            }
        });

        return false;
        // Vérifier si il y a 35 annonce LeBonCoin
        // $.ajax({
        //     type: 'POST',
        //     url: siteURL+'admin/autres/clickandlease/countLeboncoin',
        //     contentType: false,
        //     processData: false,
        //     success: function(data) {
        //         if (data >= 35 && $('#clickandlease_leboncoin_' + clickedId).val() == 1) {
        //             // Si 35 Annonces
        //             Lobibox.confirm({
        //                 title: '35 annonces Leboncoin',
        //                 msg: "Vous avez déjà 35 annonces sur Leboncoin. La plus ancienne annonce de Leboncoin sera supprimée, voulez-vous continuer ?",
        //                 buttons : {
        //                     ok : {
        //                         type: 'ok',
        //                         text: 'Oui'
        //                     },
        //                     cancel : {
        //                         type: 'cancel',
        //                         text: 'Non'
        //                     }
        //                 },
        //                 callback: function(lobibox, type){
        //                     if (type === 'ok'){
        //                         var form = new FormData();
        //                         // On extrait le contenu du formulaire dans la variable 'form'
        //                         form.append('id', clickedId);
        //                         form.append('leboncoin', $('#clickandlease_leboncoin_' + clickedId).val());
        //                         form.append('clickandlease', $('#clickandlease_clickandlease_' + clickedId).val());
        //                         form.append('loyer-1-prixmois', allLoyer[clickedId][1]['prixMois']);
        //                         form.append('loyer-1-mois', allLoyer[clickedId][1]['mois']);
        //                         form.append('loyer-1-apport', allLoyer[clickedId][1]['apport']);
        //                         form.append('loyer-1-kilometretotal', allLoyer[clickedId][1]['kilometreTotal']);
        //                         form.append('loyer-1-valeurrachat', allLoyer[clickedId][1]['valeurRachat']);

        //                         resultArrayLoyerSup = [];
        //                         first = true;
        //                         allLoyer[clickedId].forEach(function(item) {
        //                             if (first) {
        //                                 first = false;
        //                             } else {
        //                                 resultArrayLoyerSup.push(item);
        //                             }
        //                         });

        //                         form.append('loyerSupplementaire', JSON.stringify(resultArrayLoyerSup));

        //                         $.ajax({
        //                             type: 'POST',
        //                             url: siteURL+'admin/autres/clickandlease/modify',
        //                             contentType: false,
        //                             processData: false,
        //                             data: form,
        //                             success: function(data) {
        //                                 if (data == "exists") {
        //                                     toastr.error('Erreur', 'Erreur');
        //                                 }
        //                                 else {
        //                                     if (clickedId == 0) {
        //                                         toastr.success('Succès', 'Succès');
        //                                     }
        //                                     else {
        //                                         toastr.success('Les loyers ont bien été enregistrés', 'Succès');
        //                                         $('#clickandlease-tabs-modify-' + clickedId).remove();
        //                                         $('#clickandlease-close-modify-' + clickedId).parent().remove();
        //                                     }
        //                                     clickandlease_table.ajax.reload();
        //                                     $('#clickandlease-tabs-list-tab').trigger('click');
        //                                 }
        //                             }
        //                         });

        //                         return false;
        //                     }
        //                     if (type === 'cancel'){
        //                         $('#clickandlease_leboncoin_' + clickedId).val('0');
        //                         return false;
        //                     }
        //                 },
        //             });
        //         } else {
        //             var form = new FormData();
        //             // On extrait le contenu du formulaire dans la variable 'form'
        //             form.append('id', clickedId);
        //             form.append('leboncoin', $('#clickandlease_leboncoin_' + clickedId).val());
        //             form.append('clickandlease', $('#clickandlease_clickandlease_' + clickedId).val());
        //             form.append('loyer-1-prixmois', allLoyer[clickedId][1]['prixMois']);
        //             form.append('loyer-1-mois', allLoyer[clickedId][1]['mois']);
        //             form.append('loyer-1-apport', allLoyer[clickedId][1]['apport']);

        //             form.append('loyer-1-kilometretotal', allLoyer[clickedId][1]['kilometreTotal']);
        //             form.append('loyer-1-valeurrachat', allLoyer[clickedId][1]['valeurRachat']);

        //             resultArrayLoyerSup = [];
        //             first = true;
        //             allLoyer[clickedId].forEach(function(item) {
        //                 if (first) {
        //                     first = false;
        //                 } else {
        //                     resultArrayLoyerSup.push(item);
        //                 }
        //             });

        //             form.append('loyerSupplementaire', JSON.stringify(resultArrayLoyerSup));

        //             $.ajax({
        //                 type: 'POST',
        //                 url: siteURL+'admin/autres/clickandlease/modify',
        //                 contentType: false,
        //                 processData: false,
        //                 data: form,
        //                 success: function(data) {

        //                     if (data == "exists") {
        //                         toastr.error('Erreur', 'Erreur');
        //                     }
        //                     else {
        //                         if (clickedId == 0) {
        //                             toastr.success('Succès', 'Succès');
        //                         }
        //                         else {
        //                             toastr.success('Les loyers ont bien été enregistrés', 'Succès');
        //                             $('#clickandlease-tabs-modify-' + clickedId).remove();
        //                             $('#clickandlease-close-modify-' + clickedId).parent().remove();
        //                         }
        //                         clickandlease_table.ajax.reload();
        //                         $('#clickandlease-tabs-list-tab').trigger('click');
        //                     }
        //                 }
        //             });

        //             return false;
        //         }
        //     }
        // });
        // return false;
    }

});


// Recharge la table
$('[id^="clickandleasetable-"').on( 'change keyup', function () {
    column = this.id.replace('clickandleasetable-', '');
    // Au changement de la marque afficher les modeles correspondants
    if (column == 1) {
        var marque = this.value;
        if (marque != "") {

            var form = new FormData();
            form.append('marque', marque);

            $.ajax({
                type: 'POST',
                url: siteURL + "json/modeles_marque",
                data: form,
                processData: false,
                contentType: false,
                success: function(data, status) {
                    $("#clickandleasetable-2").empty();
                    $("#clickandleasetable-2").append('<option value=>Tous les modèles</option>');
                    for (id in data) {
                        if (data[id]['nb'] > 0) {
                            $("#clickandleasetable-2").append("<option value='" + id + "'>" + id + "</option>");
                        }
                    };
                    $("#clickandleasetable-2").val('default').selectpicker("refresh");
                }
            });
            clickandlease_table.column(2).search( '' ).draw()
            clickandlease_table.column( column ).search(
                '^'+$(this).val()+'$',
                true,
                false,
            ).draw();
        } else {
            clickandlease_table.column(1).search( '' ).draw()
            $("#clickandleasetable-2").empty();
            $("#clickandleasetable-2").append('<option value=>Tous les modèles</option>');
            $("#clickandleasetable-2").val('default').selectpicker("refresh");
            clickandlease_table.column(2).search( '' ).draw()
        }
    }
    else if (column == 0) {
        clickandlease_table.column(0).search( $(this).val() ).draw();
    }
    else {
        if (this.value != "") {
            clickandlease_table.column( column ).search(
                '^'+$(this).val()+'$',
                true,
                false,
            ).draw();
        } else {
            clickandlease_table.column(column).search( '' ).draw();
        }
    }
});

// RESET
$('#clickandleasetable-reset').on('click', function() {
    $('#clickandlease-tablesearch').trigger("reset");
    $("#clickandleasetable-1").val('default').selectpicker("refresh");
    $("#clickandleasetable-2").val('default').selectpicker("refresh");
    $("#clickandleasetable-4").val('default').selectpicker("refresh");
    $("#clickandleasetable-6").val('default').selectpicker("refresh");
    clickandlease_table.columns().search( '' ).draw();
    return false;
});


// Copier le text du textarea
$('body').on ('click', 'button[id^="gacal-paste-"]', function () {
    $('[id^="area_"]').select();
    document.execCommand( 'copy' );
    toastr.success('Copié dans le presse papier', 'Succès');
});

// Copier le text du titre
$('body').on ('click', 'button[id^="titre-clickandlease-paste-"]', function () {
    $('[id^="titre_clickandlease_"]').prop('disabled', false);
    $('[id^="titre_clickandlease_"]').select();
    document.execCommand( 'copy' );
    toastr.success('Copié dans le presse papier', 'Succès');
    $('[id^="titre_clickandlease_"]').prop('disabled', 'disabled');
});

// Copier le text du textarea
$('body').on ('click', 'button[id^="prixpublic-clickandlease-paste-"]', function () {
    $('[id^="prixpublic_clickandlease_"]').prop('disabled', false);
    $('[id^="prixpublic_clickandlease_"]').select();
    document.execCommand( 'copy' );
    toastr.success('Copié dans le presse papier', 'Succès');
    $('[id^="prixpublic_clickandlease_"]').prop('disabled', 'disabled');
});

// Copier l'ensemble des caractéristique
$('body').on ('click', 'button[id*="-clickandlease-caracteristique-paste-"]', function () {
    var caract = this.id.split(/[- ]+/).shift();

    input = $(this).parent().prev().find('input');
    input.prop('disabled', false);
    input.select();
    document.execCommand( 'copy' );
    toastr.success('Copié dans le presse papier', 'Succès');
    input.prop('disabled', 'disabled');

    //$('[id^="'+caract+'_clickandlease_caracteristique_"]').prop('disabled', false);
    //$('[id^="'+caract+'_clickandlease_caracteristique_"]').select();
    //$('[id^="'+caract+'_clickandlease_caracteristique_"]').prop('disabled', 'disabled');
});

// Télécharger les 5 images du véhicule
$('body').on ('click', 'button[id^="download-img-"]', function () {
    var clickedId = this.id.split(/[- ]+/).pop();

    $.ajax({
        url: siteURL+'admin/autres/clickandlease/download',
        type: 'POST',
        data: { idVehicule: clickedId },
        success: function(data, response){
            // Créer un lien pour telecharger le fichier puis supprime ce lien
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = siteURL + data;
            a.download = clickedId+'.zip';
            document.body.appendChild(a);
            a.click();
            a.remove();
        }
    });

});

// Copier les loyers pour d'autres véhicules
$('body').on ('click', 'button[id^="clickandlease-copy-modify-"]', function () {
    var clickedId = this.id.split(/[- ]+/).pop();

    $('#clickandlease-modal-'+clickedId).show();

    $("#clickandleasecopytable-0-"+clickedId).val('').selectpicker("refresh");
    $("#clickandleasecopytable-1-"+clickedId).val('default').selectpicker("refresh");
    $("#clickandleasecopytable-2-"+clickedId).val('default').selectpicker("refresh");
    $("#clickandleasecopytable-4-"+clickedId).val('default').selectpicker("refresh");
    $("#clickandleasecopytable-6-"+clickedId).val('default').selectpicker("refresh");

    return false;

});

$('body').on('click', 'span[id^="clickandleasecopyclose-"]', function () {
    var clickedId = this.id.split(/[- ]+/).pop();
    $('#clickandlease-modal-'+clickedId).hide();
    // return false;
});

// Recharge la table
$('body').on( 'change keyup', '[id^="clickandleasecopytable-"]', function () {
    column = this.id.replace('clickandleasecopytable-', '').split('-')[0];
    id_clicked = this.id.replace('clickandleasecopytable-', '').split('-')[1];

    // Au changement de la marque afficher les modeles correspondants
    if (column == 1) {
        var marque = this.value;
        var form = new FormData();
        form.append('marque', marque);

        $.ajax({
            type: 'POST',
            url: siteURL + "json/modeles_marque",
            data: form,
            processData: false,
            contentType: false,
            success: function(data, status) {
                $('#clickandleasecopytable-2-'+id_clicked).empty();
                $('#clickandleasecopytable-2-'+id_clicked).append('<option value=>Tous les modèles</option>');
                for (id in data) {
                    if (data[id]['nb'] > 0) {
                        $('#clickandleasecopytable-2-'+id_clicked).append("<option value='" + id + "'>" + id + "</option>");
                    }
                };
                $('#clickandleasecopytable-2-'+id_clicked).val('default').selectpicker("refresh");
            }
        });
        clickandleasecopy_table[id_clicked].column(2).search( '' ).draw();
        clickandleasecopy_table[id_clicked].column( column ).search(
            '^'+$(this).val()+'$',
            true,
            false,
        ).draw();
    }
    else if (column == 0) {
        clickandleasecopy_table[id_clicked].column(0).search( $(this).val() ).draw();
    }
    else {
        clickandleasecopy_table[id_clicked].column( column ).search(
            '^'+$(this).val()+'$',
            true,
            false,
        ).draw();
    }
} );

// RESET
$('body').on('click', 'button[id^="clickandleasecopytable-reset-"]', function() {
    var clickedId = this.id.split(/[- ]+/).pop();

    $('#clickandleasecopy-tablesearch-'+clickedId).trigger("reset");
    $("#clickandleasecopytable-0-"+clickedId).val('').selectpicker("refresh");
    $("#clickandleasecopytable-1-"+clickedId).val('default').selectpicker("refresh");
    $("#clickandleasecopytable-2-"+clickedId).val('default').selectpicker("refresh");
    $("#clickandleasecopytable-4-"+clickedId).val('default').selectpicker("refresh");
    $("#clickandleasecopytable-6-"+clickedId).val('default').selectpicker("refresh");
    clickandleasecopy_table[clickedId].columns().search( '' ).draw();
    return false;
});

// COPIER LES LOYER POUR VEHICULE-ID
$('body').on('click', 'button[id^="clickandleasecopy-paste-"]', function() {
    var clickedId = this.id.split(/[- ]+/)[3];
    var vehiculeId = this.id.split(/[- ]+/)[2];


    var form = new FormData();
    // On extrait le contenu du formulaire dans la variable 'form'
    form.append('vehicule-id', vehiculeId);

    form.append('loyer-1-prixmois', allLoyer[clickedId][1]['prixMois']);
    form.append('loyer-1-mois', allLoyer[clickedId][1]['mois']);
    form.append('loyer-1-apport', allLoyer[clickedId][1]['apport']);
    form.append('loyer-1-kilometretotal', allLoyer[clickedId][1]['kilometreTotal']);
    form.append('loyer-1-valeurrachat', allLoyer[clickedId][1]['valeurRachat']);

    resultArrayLoyerSup = [];
    first = true;
    allLoyer[clickedId].forEach(function(item) {
        if (first) {
            first = false;
        } else {
            resultArrayLoyerSup.push(item);
        }
    });

    form.append('loyerSupplementaire', JSON.stringify(resultArrayLoyerSup));

    if($("#clickandleasecopy-leboncoin-"+ vehiculeId +"-"+ clickedId).prop( "checked" ) == true) {
        form.append('leboncoin', 1);
    } else {
        form.append('leboncoin', 0);
    }

    if($("#clickandleasecopy-clickandlease-"+ vehiculeId +"-"+ clickedId).prop( "checked" ) == true) {
        form.append('clickandlease', 1);
    } else {
        form.append('clickandlease', 0);
    }


    $.ajax({
        type: 'POST',
        url: siteURL+'admin/autres/clickandlease/copy',
        contentType: false,
        processData: false,
        data: form,
        success: function(data) {
            toastr.success('Les loyers ont bien été copié', 'Succès');
        }
    });

    $(this).replaceWith("<button class='btn w-100 p-0' id='clickandleasecopy-paste-'"+ vehiculeId +"-" + clickedId +"' ><i class='far fa-copy' style='color: #22E03B;'></i></button>");


    return false;
});