let actionprix_table = null;
let timerBeforeRefreshDataActionPrix = null;

$(document).ready(function() {
    if ($("#action_prix_results").length) {
        colvisFunOnEvent();

        function loadAjax() {
            let optionsRecherche = optionsRecherchesDataForm();
            $('#action_prix_loader').removeClass('d-none');
            $('.card-body').addClass('d-none');
            $("#action_prix_results").addClass("d-none");
            $.ajax({
                type:'POST',
                url: siteURL + 'admin/achat/action_prix_ajax',
                contentType: false,
                processData: false,
                data: optionsRecherche,
                success: function(data){
                    $("#action_prix_results").html(data);
                    // Génération de la datatable des generateur reseaux sociaux
                    let columnDefsAction_prix_table = [
                        { "className": "text-center align-middle", "targets": '_all' }, // ALIGN MIDDLE
                        { "targets": 1, "orderable": false }
                    ];
                    columnDefsAction_prix_table.push(...getColumnDefsType("action_prix_table"))
                    actionprix_table = $('#action_prix_table').DataTable({
                        "dom": dataTablesCustomDom(),
                        "buttons": [dataTablesColvisBtn('th:nth-child(n+2)')],
                        "autoWidth": false,
                        "language": { "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/fr-FR.json" },
                        "paging": true,
                        "pageLength": 25,
                        "responsive": true,
                        "order": [[16, "asc"]],
                        "conditionalPaging": true,
                        "columnDefs": columnDefsAction_prix_table,
                        "columns": getColvisColumn("action_prix_table")
                    });
                    $('#action_prix_table').on('init.dt', function () { initMirrorBtn(); setColvisTable(actionprix_table); colvisFunSelectRefresh(); });
                    $("#action_prix_results").removeClass("d-none");
                    $('#action_prix_loader').addClass('d-none');
                    $('.card-body').removeClass('d-none');
                }
            })
        };

        setupLoadAjax(loadAjax);
        setupResetFilter(resetFilter);

        $('body').on('change', "[id^='filtersactionprix-']", function() {
            clearTimeout(timerBeforeRefreshDataActionPrix);
            timerBeforeRefreshDataActionPrix = setTimeout(loadAjax, 500);
        });

        $('body').on('click mouseup', "[id^='filtersactionprix-prix']", function() {
            clearTimeout(timerBeforeRefreshDataActionPrix);
            timerBeforeRefreshDataActionPrix = setTimeout(loadAjax, 500);
        });

        // Bouton reset
        $('body').on('click', '#filtersactionprix-reset', function() {
            resetFilter();
            loadAjax();
        });

        $('body').on('click', "[id^=action_prix-reset-cycle-co-]", function() {
            let idVehicule = this.id.replace("action_prix-reset-cycle-co-", "");
            let form = new FormData();
            form.append('id', idVehicule);
            $.ajax({
                type:'POST',
                url: siteURL + 'admin/stock/update/reset-cycle-co',
                contentType: false,
                processData: false,
                data: form,
                success: function(success) {
                    if (success == "ok") {
                        toastr.success("La nouvelle date a bien été enregistré", "Succès");
                    } else {
                        toastr.error("Une erreur s'est produite", "Erreur");
                    }
                }
            });
        });

        $('body').on('click', "[id^=action_prix-commentaire-btn-]", function() {
            let idVehicule = this.id.replace("action_prix-commentaire-btn-", "");
            let today = new Date();
            let dd = String(today.getDate()).padStart(2, '0');
            let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            let yyyy = today.getFullYear();
            let hh = String(today.getHours()).padStart(2, '0');
            let ii = String(today.getMinutes()).padStart(2, '0');
            let now = 'Le ' + mm + '/' + dd + '/' + yyyy + ' à ' + hh + ':' + ii;
            $('#action_prix-commentaire-textarea-'+idVehicule).val($('#action_prix-commentaire-textarea-'+idVehicule).data("commentaire")+"\n\n"+now+"\n\n");
        });

        $('body').on('click', "[id^=action_prix-commentaire-save-]", function() {
            let idVehicule = this.id.replace("action_prix-commentaire-save-", "");
            let form = new FormData();
            form.append('id', idVehicule);
            form.append('commentaire', $("#action_prix-commentaire-textarea-"+idVehicule).val());
            $.ajax({
                type:'POST',
                url: siteURL + 'admin/stock/update/commentaire',
                contentType: false,
                processData: false,
                data: form,
                success: function(commentaire) {
                    toastr.success("Le commentaire a bien été enregistré", "Succès");
                    $("#action_prix-commentaire-textarea-"+idVehicule).val("");
                    $("#action_prix-commentaire-textarea-"+idVehicule).data("commentaire", commentaire);
                    $("#action_prix-commentaire-modal-"+idVehicule).modal("hide");
                    if (commentaire.length) { $("[id^=action_prix-commentaire-indicator-"+idVehicule+"]").removeClass("d-none"); }
                    else { $("[id^=action_prix-commentaire-indicator-"+idVehicule+"]").addClass("d-none"); }
                    console.log(commentaire.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br />$2'));
                    $('[id^=action_prix-commentaire-btn-'+idVehicule+"]").parent().find(".adminTableTooltip").html(commentaire.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br />$2'));
                }
            });
        });

        $('body').on('shown.bs.modal', '[id^="action_prix-commentaire-modal-"]', function () {
            $('.modal-backdrop').css('z-index', 1035);
        })

        function optionsRecherchesDataForm() {
            let optionsRecherche = new FormData();
            optionsRecherche.append('jourstock', $("#filtersactionprix-jourstock").val());
            optionsRecherche.append('badge', $("#filtersactionprix-badge").val());
            return optionsRecherche;
        }

        function resetFilter() {
            $("#filtersactionprix-jourstock").val('default').selectpicker("refresh");
            $("#filtersactionprix-badge").val('default').selectpicker("refresh");
        }

        filtreConfigFunOnEvent();
    }
})
