let stock_table = null;
let timerBeforeRefreshDataStock = null;
let selectedStockCache = [];
const isStockPage = window.location.href.includes("/admin/stock");

$(document).ready(function () {
    if ($("#stock_results").length) {
        if (isStockPage) {
            window.history.pushState("", "", siteURL + "admin/stock");
        }

        if (isStockPage) {
            colvisFunOnEvent();
        } else {
            if ($("#campagne-page-form").length && $("#campagne-page-form").data("stockcache") != undefined) {
                selectedStockCache = $("#campagne-page-form").data("stockcache").toString().split(",");
            }
        }

        function loadAjax() {
            let optionsRecherche = new FormData();
            optionsRecherche.append('isStockPage', isStockPage);
            optionsRecherche.append('marque', $("#filtersstock-marque").val());
            optionsRecherche.append('modele', $("#filtersstock-modele").val());
            optionsRecherche.append('carrosserie', $("#filtersstock-carrosserie").val());
            optionsRecherche.append('date_mec', (new Date($("#filtersstock-datemec1").val())).toLocaleDateString("fr") + "," + (new Date($("#filtersstock-datemec2").val())).toLocaleDateString("fr"));
            optionsRecherche.append('jourstock', $("#filtersstock-jourstock").val());
            optionsRecherche.append('energie', $("#filtersstock-energie").val());
            optionsRecherche.append('boite', $("#filtersstock-boite").val());
            optionsRecherche.append('place', $("#filtersstock-place").val());
            optionsRecherche.append('emplacement', $("#filtersstock-emplacement").val());
            optionsRecherche.append('parc', $("#filtersstock-parc").val().flatMap((v) => v.split(",")).distinct());
            optionsRecherche.append('tva', $("#filtersstock-tva").val());
            optionsRecherche.append('statutstock', $("#filtersstock-statutstock").val());
            optionsRecherche.append('offrePrix', $("#filtersstock-offrePrix").is(':checked'));
            optionsRecherche.append('prix_public', [$("#filtersstock-prixpublic .prixpublic-handle")[0].ariaValueNow, $("#filtersstock-prixpublic .prixpublic-handle")[1].ariaValueNow]);
            optionsRecherche.append('prix_marchand', [$("#filtersstock-prixmarchand .prixmarchand-handle")[0].ariaValueNow, $("#filtersstock-prixmarchand .prixmarchand-handle")[1].ariaValueNow]);
            optionsRecherche.append('prix_achat', [$("#filtersstock-prixachat .prixachat-handle")[0].ariaValueNow, $("#filtersstock-prixachat .prixachat-handle")[1].ariaValueNow]);
            optionsRecherche.append('kilometrage', [$("#filtersstock-kilometrage .kilometrage-handle")[0].ariaValueNow, $("#filtersstock-kilometrage .kilometrage-handle")[1].ariaValueNow]);
            $("#stock_results").addClass("loading");
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/stock/stock_ajax',
                contentType: false,
                processData: false,
                data: optionsRecherche,
                success: function (data) {
                    $("#stock_results").html(data);
                    for (let index = 0; index < selectedStockCache.length; index++) {
                        $("#stock-selection-" + selectedStockCache[index]).prop("checked", true);
                    }
                    stock_table = $('#stock-table').DataTable({
                        "dom": (isStockPage ? dataTablesCustomDom({ graphs: true }) : dataTablesCustomDom({ scrollable: true })),
                        "buttons": [dataTablesColvisBtn('th:nth-child(n+4)')],
                        "autoWidth": false,
                        "language": { "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/fr-FR.json" },
                        "paging": true,
                        "pageLength": 25,
                        "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, "All"]],
                        "responsive": isStockPage,
                        "order": [[4, "asc"], [5, "asc"]],
                        "conditionalPaging": true,
                        "columnDefs": [
                            { type: 'num', targets: [2, 10, 11, 13, 14, 15, 16, 17, 18, 20, 21, 22, 23] },
                            { "className": "text-center align-middle", "targets": '_all' }, // ALIGN MIDDLE
                            { "targets": [0, 1, 3], "orderable": false }
                        ],
                        "columns": (isStockPage ? getColvisColumn("stock-table") : null)
                    });
                    $('#stock-table').on('init.dt', function () { initMirrorBtn(); setColvisTable(stock_table); colvisFunSelectRefresh(); });
                    $('#stock-table').on('draw.dt', function () { $("#stock_results").removeClass("loading"); stockLinkGenerator(); });
                    $('#stock-table').on('page.dt', function () { stockLinkGenerator(); });
                    $('#stock-table').on('length.dt', function () { stockLinkGenerator(); });
                    stockGraphInit(optionsRecherche);
                }
            })
        };

        if (isStockPage) {
            setupLoadAjax(loadAjax);
            setupLoadModele(loadModele);
            setupResetFilter(resetFilter);
        }

        $('body').on('change', "[id^='filtersstock-']", function () { clearTimeout(timerBeforeRefreshDataStock); timerBeforeRefreshDataStock = setTimeout(loadAjax, 500); });
        $('body').on('click mouseup', "[id^='filtersstock-prix']", function () { clearTimeout(timerBeforeRefreshDataStock); timerBeforeRefreshDataStock = setTimeout(loadAjax, 500); });
        $('body').on('click mouseup', "#filtersstock-kilometrage", function () { clearTimeout(timerBeforeRefreshDataStock); timerBeforeRefreshDataStock = setTimeout(loadAjax, 500); });

        // Pour pouvoir cocher la case de la première colonne sans déclencher l'affichage des colonnes masquées
        $('body').on('click', "[id^='stock-selection-']", function (e) {
            let id = this.id.replace('stock-selection-', '')
            let tr = $(this).parents('tr');
            let row = stock_table.row(tr);
            row.child.hide();
        });

        // Bouton reset
        $('body').on('click', '#filtersstock-reset', function () {
            resetFilter();
            loadAjax();
        });

        // On charge les modèles en fonction des marques
        $('body').on('change', "#filtersstock-marque", loadModele);

        // Selection des véhicules
        $('body').on('change', "[id^='stock-selection-']", function (evt) {
            if (evt.stopPropagation !== undefined) { evt.stopPropagation(); } else { evt.cancelBubble = true; }
            if (this.id.replace("stock-selection-", "") == "all") {
                $("[id^='stock-selection-']").each(function () {
                    if ($("#stock-selection-all").is(':checked')) {
                        $(this).prop("checked", true);
                    } else {
                        $(this).prop("checked", false);
                    }
                });
            } else {
                if ($(this).is(":checked")) {
                    $(this).prop("checked", true);
                } else {
                    $(this).prop("checked", false);
                }
            }
            stock_table.rows().invalidate();
            let all_data = stock_table.rows().data();
            let index = null;
            id_vehicules = [];
            $.each(all_data, function (key, value) {
                let tr = $('#stock-tr-' + $(value[0])[0].id.replace("stock-selection-", ""));
                if (tr.length != 0) {
                    if ($(value[0])[0].id.includes("stock-selection-") && $("#" + $(value[0])[0].id).is(":checked")) {
                        id_vehicules.push($(value[0])[0].id.replace("stock-selection-", ""));
                    } else {
                        index = selectedStockCache.findIndex((el) => el == $(value[0])[0].id.replace("stock-selection-", ""));
                        if (index != -1) { selectedStockCache.splice(index, 1); }
                    }
                }
            });
            for (let index = 0; index < id_vehicules.length; index++) {
                selectedStockCache.push(id_vehicules[index]);
            }
            selectedStockCache = selectedStockCache.filter((value, index, array) => array.indexOf(value) === index);
        });

        // Reservation véhicule
        $('body').on('change', '[id^="stock-reservation-"]', function () {
            let clickedId = this.id.replace("stock-reservation-", "");
            let reservationEtat = ($(this).is(':checked') ? 1 : 0);

            var form = new FormData();
            form.append('id', clickedId);
            form.append('reservation', reservationEtat);
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/stock/update/reservation',
                contentType: false,
                processData: false,
                data: form,
                success: function (data) {
                    if (parseInt(data)) { toastr.success('Le véhicule est maintenant indiqué comme réservé', 'Succès'); }
                    else { toastr.error('Le véhicule n\'est maintenant plus indiqué comme réservé', 'Succès'); }
                }
            });

            if ($(this).is(':checked')) {
                $("#badge_statut_" + clickedId).removeClass('badge-success').addClass('badge-warning').text('Réservé');
            } else {
                $("#badge_statut_" + clickedId).removeClass('badge-warning').addClass('badge-success').text('Stock');
            }
        });

        // Proposer véhicule(s) par e-mail
        $('body').on('click', "#menu-stock-propositionVehiculeEmail", function (e) {
            if (selectedStockCache.length) {
                if (selectedStockCache.length <= 30) {
                    window.open($(this).data("src") + "?listId=" + selectedStockCache.join(), "_blank");
                    selectedStockCache.forEach(element => {
                        $("#stock-selection-" + element).prop("checked", false);
                    });
                    selectedStockCache = [];
                } else { toastr.error('30 véhicules au maximum peuvent être proposé par mail. Ajustez votre sélection.', 'Erreur'); }
            }
            else { toastr.error('Aucun véhicule sélectionné', 'Erreur'); }
        });

        /**
         * EXPORT LISTE - DEBUT
         */

        $('body').on('click', "[id^='menu-stock-export-']", function () {
            if (stock_table != null) {
                exporttype = this.id.replace("menu-stock-export-", "");
                // Si aucun véhicule est rentré
                if (selectedStockCache.length == 0) {
                    toastr.error('Merci de sélectionner au moins un véhicule', 'Erreur');
                    return false;
                }

                var form = new FormData();
                form.append('ids', selectedStockCache);
                today = new Date();

                switch (exporttype) {
                    case 'excel':
                        $.ajax({
                            type: 'POST',
                            url: siteURL + 'admin/stock/export/ficheExcel',
                            contentType: false,
                            processData: false,
                            data: form,
                            success: function (data) {
                                toastr.success('Le fichier excel est fini', 'Succès');
                                var element = document.createElement('a');
                                element.setAttribute('href', siteURL + "assets/docs/listing_pro/results.xlsx");
                                element.setAttribute('download', "export-" + today.toLocaleDateString("fr").slugify() + ".xlsx");
                                document.body.appendChild(element);
                                element.click();
                                document.body.removeChild(element);
                            }
                        });
                        break;
                    case 'excel_btob':
                        $.ajax({
                            type: 'POST',
                            url: siteURL + 'admin/stock/export/ficheExcelBtoB',
                            contentType: false,
                            processData: false,
                            data: form,
                            success: function (data) {
                                toastr.success('Le fichier excel est fini', 'Succès');
                                var element = document.createElement('a');
                                element.setAttribute('href', siteURL + "assets/docs/listing_pro/results_btob.xlsx");
                                element.setAttribute('download', "Liste Vehicule Ora7 " + today.toLocaleDateString("fr") + ".xlsx");
                                document.body.appendChild(element);
                                element.click();
                                document.body.removeChild(element);
                            }
                        });
                        break;
                    case 'pdf_btob':
                        $.ajax({
                            type: 'POST',
                            url: siteURL + 'admin/stock/export/fichePDFBtoB',
                            contentType: false,
                            processData: false,
                            data: form,
                            success: function (data) {
                                toastr.success('Le fichier pdf est fini', 'Succès');
                                var element = document.createElement('a');
                                element.setAttribute('href', siteURL + "assets/docs/listing_pro/results_btob.pdf");
                                element.setAttribute('download', "Liste Vehicule Ora7 " + today.toLocaleDateString("fr") + ".pdf");
                                document.body.appendChild(element);
                                element.click();
                                document.body.removeChild(element);
                            }
                        });
                        break;
                    case 'excel_prix_mini':
                        $.ajax({
                            type: 'POST',
                            url: siteURL + 'admin/stock/export/ficheExcelPrixMini',
                            contentType: false,
                            processData: false,
                            data: form,
                            success: function (data) {
                                toastr.success('Le fichier excel est fini', 'Succès');
                                var element = document.createElement('a');
                                element.setAttribute('href', siteURL + "assets/docs/listing_pro/results_prix_mini.xlsx");
                                element.setAttribute('download', "Liste Vehicule Ora7 " + today.toLocaleDateString("fr") + ".xlsx");
                                document.body.appendChild(element);
                                element.click();
                                document.body.removeChild(element);
                            }
                        });
                        break;
                    case 'pdf_prix_mini':
                        $.ajax({
                            type: 'POST',
                            url: siteURL + 'admin/stock/export/fichePDFPrixMini',
                            contentType: false,
                            processData: false,
                            data: form,
                            success: function (data) {
                                toastr.success('Le fichier pdf est fini', 'Succès');
                                var element = document.createElement('a');
                                element.setAttribute('href', siteURL + "assets/docs/listing_pro/results_prix_mini.pdf");
                                element.setAttribute('download', "Liste Vehicule Ora7 " + today.toLocaleDateString("fr") + ".pdf");
                                document.body.appendChild(element);
                                element.click();
                                document.body.removeChild(element);
                            }
                        });
                        break;
                    case 'excel_custom':
                        $.ajax({
                            type: 'POST',
                            url: siteURL + 'admin/stock/export/ficheExcelCustom',
                            contentType: false,
                            processData: false,
                            data: form,
                            success: function (data) {
                                toastr.success('Le fichier excel est fini', 'Succès');
                                var element = document.createElement('a');
                                element.setAttribute('href', siteURL + "assets/docs/listing_pro/results_custom.xlsx");
                                element.setAttribute('download', "liste_vehicule_ora7_personnalisee.xlsx");
                                document.body.appendChild(element);
                                element.click();
                                document.body.removeChild(element);
                            }
                        });
                        break;
                    case 'pdf_custom':
                        $.ajax({
                            type: 'POST',
                            url: siteURL + 'admin/stock/export/fichePDFCustom',
                            contentType: false,
                            processData: false,
                            data: form,
                            success: function (data) {
                                toastr.success('Le fichier pdf est fini', 'Succès');
                                var element = document.createElement('a');
                                element.setAttribute('href', siteURL + "assets/docs/listing_pro/results_custom.pdf");
                                element.setAttribute('download', "liste_vehicule_ora7_personnalisee.pdf");
                                document.body.appendChild(element);
                                element.click();
                                document.body.removeChild(element);
                            }
                        });
                        break;
                    default:
                        break;
                }
                return true;
            }
        });

        $('body').on('click', "#menu-stock-propositionCommercial", function () {
            if (stock_table != null) {
                // Si aucun véhicule est rentré
                if (selectedStockCache.length == 0) {
                    toastr.error('Merci de sélectionner au moins un véhicule', 'Erreur');
                    return false;
                }
                if (selectedStockCache.length > 30) {
                    toastr.error('Merci de renseigner moins de 30 véhicules', 'Erreur');
                    return false;
                }
                Lobibox.window({
                    baseClass: "modal-content modal-lobistrap",
                    width: 1500,
                    title: 'Liste vehicule prix remisé',
                    url: siteURL + 'admin/stock/export/propositionCommercial',
                    params: { 'ids': selectedStockCache },
                    showAfterLoad: false,
                });
                return false;
            }
        });

        /**
         * EXPORT LISTE - FIN
         */

        // Bouton gestion des demandes
        $('body').on('click dblclick', "[id^='inputGroup-ajout-demande-']", function (e) {
            let idRegex = e.target.id.match(/\d+/g);
            if (idRegex == null) { idRegex = e.currentTarget.id.match(/\d+/g); }
            if (idRegex != null) {
                let idVehicule = idRegex[0];
                let nomClient = $("#inputGroup-input-demande-" + idVehicule).val();
                if (nomClient != "") {
                    $("#inputGroup-ajout-demande-" + idVehicule).attr("disabled", true);
                    $("#inputGroup-ajout-demande-" + idVehicule).html("<i class=\"fas fa-sync-alt\"></i>");
                    var form = new FormData();
                    form.append('id', idVehicule);
                    form.append('client', nomClient);
                    $.ajax({
                        type: 'POST',
                        url: siteURL + 'admin/stock/update/reservationSurVendu',
                        contentType: false,
                        processData: false,
                        data: form,
                        success: function (idDemande) {
                            $("#list-demande-client-" + idVehicule).append("<div class=\"text-left mb-1\" style=\"font-size: small;\"><button class=\"btn btn-danger btn-sm mr-2\" type=\"button\" id=\"delete-demande-" + idVehicule + "-" + idDemande + "\"><i class=\"fas fa-times\"></i></button> " + $(".sidebar .user-panel .info a").text() + " - client : " + nomClient + "</div>");
                            updateInfoBulle(idVehicule);
                            $("#inputGroup-ajout-demande-" + idVehicule).attr("disabled", false);
                            $("#inputGroup-ajout-demande-" + idVehicule).html("Ajouter <i class=\"fas fa-plus\"></i>");
                            toastr.success('Vous avez indiqué une demande pour le véhicule vendu n° ' + idVehicule + '.', 'Succès');
                        }
                    });
                }
            }
        });

        // Bouton gestion des demandes
        $('body').on('click dblclick', "[id^='delete-demande-']", function (e) {
            let idRegex = e.target.id.match(/\d+/g);
            if (idRegex == null) {
                idRegex = e.currentTarget.id.match(/\d+/g);
            }
            if (idRegex != null) {
                let idVehicule = idRegex[0];
                let idDemande = idRegex[1];
                $("#delete-demande-" + idVehicule + "-" + idDemande).attr("disabled", true);
                $("#delete-demande-" + idVehicule + "-" + idDemande).html("<i class=\"fas fa-sync-alt\"></i>");
                var form = new FormData();
                form.append('id', idVehicule);
                form.append('idDemande', idDemande);
                $.ajax({
                    type: 'POST',
                    url: siteURL + 'admin/stock/update/deleteReservationSurVendu',
                    contentType: false,
                    processData: false,
                    data: form,
                    success: function (data) {
                        $("#delete-demande-" + idVehicule + "-" + idDemande).parent().remove();
                        updateInfoBulle(idVehicule);
                        if (data) {
                            toastr.success('Vous avez retiré la demande pour le véhicule vendu n° ' + idVehicule + '.', 'Succès');
                        } else {
                            toastr.error('Erreur, la demande pour le véhicule vendu n° ' + idVehicule + ' avait déjà été rétirée.', 'Erreur');
                        }
                    }
                });
            }
        });

        // Édition prix marchand remisé ou minimum
        const typePrixEditable = ["remise", "mini"];
        $('body').on('click dblclick', "[id^='edit_prix_'][id*='_marchand_']", function (e) {
            const regexPrixMarchand = /\d+/g;
            let idVehicule = this.id.match(regexPrixMarchand)[0];
            let typePrix = this.id.replace("edit_prix_", "").replace("_marchand_", "").replace(idVehicule, "");
            if (typePrixEditable.includes(typePrix)) {
                let originalContent = $(this).text().match(regexPrixMarchand);
                $(this).parent().html("<input type='number' size='10' min='0' step='100' id='input_prix_" + typePrix + "_marchand_" + idVehicule + "' value='" + (originalContent != null ? originalContent.join("") : "") + "' />");
                $("#input_prix_" + typePrix + "_marchand_" + idVehicule).trigger("focus");
            }
        });
        $('body').on('click dblclick', "[id^='input_prix_'][id*='_marchand_']", function (e) { e.stopPropagation(); });
        $('body').on('keyup blur', "[id^='input_prix_'][id*='_marchand_']", function (e) {
            const regexPrixMarchand = /\d+/g;
            let idVehicule = this.id.match(regexPrixMarchand)[0];
            let typePrix = this.id.replace("input_prix_", "").replace("_marchand_", "").replace(idVehicule, "");
            if (typePrixEditable.includes(typePrix)) {
                let prixMarchand = null;
                switch (typePrix) {
                    case "remise":
                        prixMarchand = setPrixMarchandRemise($(this), regexPrixMarchand, idVehicule); break;
                    case "mini":
                        prixMarchand = setPrixMiniMarchand($(this), regexPrixMarchand, idVehicule); break;
                    default:
                        break;
                }
                if (e.key === undefined || e.key == "Enter") {
                    let newBtnStr = "<button type='button' id='edit_prix_" + typePrix + "_marchand_" + idVehicule + "' class='btn btn-outline-dark btn-sm text-11'>" + (parseFloat(prixMarchand) ? prixMarchand.replace(/(?=(\d\d\d)+(?!\d))/g, " ").trim() + " €" : "Éditer <i class='fal fa-pen text-10'></i>") + "</button>";
                    $(this).parent().html(newBtnStr);
                    $("#prix_" + typePrix + "_marchand_" + idVehicule).html(newBtnStr);
                    $("#prix_" + typePrix + "_marchand_" + idVehicule)[0].dataset.order = prixMarchand;
                    switch (typePrix) {
                        case "remise":
                            if (parseFloat(prixMarchand)) { $("#prix_marchand_remise_" + idVehicule).text(prixMarchand.replace(/(?=(\d\d\d)+(?!\d))/g, " ").trim() + " €"); $("#prix_marchand_remise_" + idVehicule).removeClass("d-none"); $("#prix_marchand_" + idVehicule).addClass("prix-barre text-secondary text-9"); }
                            else { $("#prix_marchand_remise_" + idVehicule).addClass("d-none"); $("#prix_marchand_" + idVehicule).removeClass("prix-barre text-secondary text-9"); } break;
                        case "mini":
                            if (parseFloat(prixMarchand)) { $("#stock-infobulle-" + idVehicule).text("Prix min marchand : " + prixMarchand.replace(/(?=(\d\d\d)+(?!\d))/g, " ").trim() + " €"); $("#stock-infobulle-button-" + idVehicule).removeClass("d-none"); }
                            else { $("#stock-infobulle-button-" + idVehicule).addClass("d-none"); } break;
                        default:
                            break;
                    }
                    updateMargeHT(idVehicule);
                    if (stock_table != null) { stock_table.rows().invalidate(); }
                }
            }
        });

        if ($('#filtersstock-prixpublic').length > 0) {
            // Slider Prix Public
            sliderPublic = document.getElementById('filtersstock-prixpublic');
            // Initialisation des bornages d'après le contenue de la page
            prix_min = parseInt($('#filtersstock-prixpublic-min').val());
            prix_max = parseInt($('#filtersstock-prixpublic-max').val());
            // Si jamais les deux valeurs sont identiques
            if (prix_min == prix_max) {
                prix_min = prix_min - 500;
                prix_max = prix_max + 500;
            }
            // Création du slider à proprement parler
            noUiSlider.create(sliderPublic, {
                start: [prix_min, prix_max],
                step: 500,
                connect: true,
                range: {
                    'min': prix_min,
                    '90%': 60000,
                    'max': prix_max
                },
                cssPrefix: 'prixpublic-'
            });
            // Mets à jour en direct les valeurs lors d'une modification
            sliderPublic.noUiSlider.on('update', function (values, handle) {
                updatePrixPublic(values, handle);
            });
            // Mets à jour les valeurs au dessus du slider en cas de modification
            sliderPublic.noUiSlider.on('end', function (values, handle) {
                updatePrixPublic(values, handle);
            });

            // Function pour mettre à jour les infos en ttc
            function updatePrixPublic(values, handle) {
                let value = values[handle];
                // la présence de handle correspond ici au fait que cela soit la seconde tirette
                if (handle) {
                    $("#filtersstock-prixpublic-max").val(parseInt(value));
                    $(".min-max-public .float-right").html(parseInt(value) + ' &euro;');
                } else {
                    $("#filtersstock-prixpublic-min").val(parseInt(value));
                    $(".min-max-public .float-left").html(parseInt(value) + ' &euro;');
                }
            }

            // Function pour mettre à jour les infos en ttc
            function resetPrixPublic() {
                sliderPublic.noUiSlider.reset();
            }
        }

        if ($('#filtersstock-prixmarchand').length > 0) {
            // Slider Prix Public
            sliderMarchand = document.getElementById('filtersstock-prixmarchand');
            // Initialisation des bornages d'après le contenue de la page
            prix_min = parseInt($('#filtersstock-prixmarchand-min').val());
            prix_max = parseInt($('#filtersstock-prixmarchand-max').val());
            // Si jamais les deux valeurs sont identiques
            if (prix_min == prix_max) {
                prix_min = prix_min - 500;
                prix_max = prix_max + 500;
            }
            // Création du slider à proprement parler
            noUiSlider.create(sliderMarchand, {
                start: [prix_min, prix_max],
                step: 500,
                connect: true,
                range: {
                    'min': prix_min,
                    '90%': 60000,
                    'max': prix_max
                },
                cssPrefix: 'prixmarchand-'
            });
            // Mets à jour en direct les valeurs lors d'une modification
            sliderMarchand.noUiSlider.on('update', function (values, handle) {
                updatePrixMarchand(values, handle);
            });
            // Mets à jour les valeurs au dessus du slider en cas de modification
            sliderMarchand.noUiSlider.on('end', function (values, handle) {
                updatePrixMarchand(values, handle);
            });

            // Function pour mettre à jour les infos en ttc
            function updatePrixMarchand(values, handle) {
                let value = values[handle];
                // la présence de handle correspond ici au fait que cela soit la seconde tirette
                if (handle) {
                    $("#filtersstock-prixmarchand-max").val(parseInt(value));
                    $(".min-max-marchand .float-right").html(parseInt(value) + ' &euro;');
                } else {
                    $("#filtersstock-prixmarchand-min").val(parseInt(value));
                    $(".min-max-marchand .float-left").html(parseInt(value) + ' &euro;');
                }
            }

            // Function pour mettre à jour les infos en ttc
            function resetPrixMarchand() {
                sliderMarchand.noUiSlider.reset();
            }
        }

        if ($('#filtersstock-prixachat').length > 0) {
            // Slider Prix Public
            sliderAchat = document.getElementById('filtersstock-prixachat');
            // Initialisation des bornages d'après le contenue de la page
            prix_min = parseInt($('#filtersstock-prixachat-min').val());
            prix_max = parseInt($('#filtersstock-prixachat-max').val());
            // Si jamais les deux valeurs sont identiques
            if (prix_min == prix_max) {
                prix_min = prix_min - 500;
                prix_max = prix_max + 500;
            }
            // Création du slider à proprement parler
            noUiSlider.create(sliderAchat, {
                start: [prix_min, prix_max],
                step: 500,
                connect: true,
                range: {
                    'min': prix_min,
                    '90%': 60000,
                    'max': prix_max
                },
                cssPrefix: 'prixachat-'
            });
            // Mets à jour en direct les valeurs lors d'une modification
            sliderAchat.noUiSlider.on('update', function (values, handle) {
                updatePrixAchat(values, handle);
            });
            // Mets à jour les valeurs au dessus du slider en cas de modification
            sliderAchat.noUiSlider.on('end', function (values, handle) {
                updatePrixAchat(values, handle);
            });

            // Function pour mettre à jour les infos en ttc
            function updatePrixAchat(values, handle) {
                let value = values[handle];
                // la présence de handle correspond ici au fait que cela soit la seconde tirette
                if (handle) {
                    $("#filtersstock-prixachat-max").val(parseInt(value));
                    $(".min-max-achat .float-right").html(parseInt(value) + ' &euro;');
                } else {
                    $("#filtersstock-prixachat-min").val(parseInt(value));
                    $(".min-max-achat .float-left").html(parseInt(value) + ' &euro;');
                }
            }

            // Function pour mettre à jour les infos en ttc
            function resetPrixAchat() {
                sliderAchat.noUiSlider.reset();
            }
        }

        if ($('#filtersstock-kilometrage').length > 0) {
            // Slider Prix Public
            sliderKilometrage = document.getElementById('filtersstock-kilometrage');
            // Initialisation des bornages d'après le contenue de la page
            kilometrage_min = parseInt($('#filtersstock-kilometrage-min').val());
            kilometrage_max = parseInt($('#filtersstock-kilometrage-max').val());
            // Si jamais les deux valeurs sont identiques
            if (kilometrage_min == kilometrage_max) {
                kilometrage_min = kilometrage_min - 500;
                kilometrage_max = kilometrage_max + 500;
            }
            // Création du slider à proprement parler
            noUiSlider.create(sliderKilometrage, {
                start: [kilometrage_min, kilometrage_max],
                step: 500,
                connect: true,
                range: {
                    'min': kilometrage_min,
                    'max': kilometrage_max
                },
                cssPrefix: 'kilometrage-'
            });
            // Mets à jour en direct les valeurs lors d'une modification
            sliderKilometrage.noUiSlider.on('update', function (values, handle) {
                updateKilometrage(values, handle);
            });
            // Mets à jour les valeurs au dessus du slider en cas de modification
            sliderKilometrage.noUiSlider.on('end', function (values, handle) {
                updateKilometrage(values, handle);
            });

            // Function pour mettre à jour les infos en ttc
            function updateKilometrage(values, handle) {
                let value = values[handle];
                // la présence de handle correspond ici au fait que cela soit la seconde tirette
                if (handle) {
                    $("#filtersstock-kilometrage-max").val(parseInt(value));
                    $(".min-max-kilometrage .float-right").html(parseInt(value) + ' Km(s)');
                } else {
                    $("#filtersstock-kilometrage-min").val(parseInt(value));
                    $(".min-max-kilometrage .float-left").html(parseInt(value) + ' Km(s)');
                }
            }

            // Function pour mettre à jour les infos en ttc
            function resetKilometrage() {
                sliderKilometrage.noUiSlider.reset();
            }
        }

        // Pour la modale et la rentrée du VIN via l'OCR
        $('body').on('shown.bs.modal', "#stock-canva-photo-vin", function (e) {
            lancementScriptVideoVIN('#stock-table_filter input', 'stock');
        });

        function initDateMEC() {
            $("#filtersstock-datemec1").val($("#firstMEC").val());
            $("#filtersstock-datemec2").val($("#lastMEC").val());
        }

        function resetFilter() {
            initDateMEC();
            $("#filtersstock-marque").val('default').selectpicker("refresh");
            $("#filtersstock-modele").find("optgroup").prop('disabled', true);
            $('#filtersstock-modele').prop("disabled", true);
            $("#filtersstock-modele").val('default').selectpicker("refresh");
            $("#filtersstock-carrosserie").val('default').selectpicker("refresh");
            $("#filtersstock-place").val('default').selectpicker("refresh");
            $("#filtersstock-emplacement").val('default').selectpicker("refresh");
            $("#filtersstock-parc").val('default').selectpicker("refresh");
            $("#filtersstock-energie").val('default').selectpicker("refresh");
            $("#filtersstock-boite").val('default').selectpicker("refresh");
            $("#filtersstock-jourstock").val('all').selectpicker("refresh");
            $("#filtersstock-tva").val('default').selectpicker("refresh");
            $("#filtersstock-etat").val('default').selectpicker("refresh");
            $("#filtersstock-offrePrix").prop("checked", false);
            $("#filtreConfigSelect-filtreConfig-aucun").val('aucun').prop("selected", true);
            resetPrixPublic();
            resetPrixMarchand();
            resetPrixAchat();
            resetKilometrage();
        }

        function loadModele() {
            if ($('#filtersstock-marque').val().length != 0) {
                $("#filtersstock-modele").prop('disabled', false);
                $('#filtersstock-marque').val().forEach(element => { $("#opt_" + element).prop('disabled', false); });
                $("[id^='opt_']").each(function () {
                    let marqueEnCours = $(this).attr('id').replace('opt_', '');
                    if (!$('#filtersstock-marque').val().includes(marqueEnCours)) {
                        $(this).prop('disabled', true);
                    }
                })
                $("#filtersstock-modele").selectpicker('refresh');
            } else {
                $('#filtersstock-modele').prop("disabled", true);
                $("#filtersstock-modele").find("optgroup").prop('disabled', true);
                $("#filtersstock-modele").selectpicker('refresh');
            }
        }

        $('body').on('click', "[id^=stock-commentaire-btn-]", function () {
            let idVehicule = this.id.replace("stock-commentaire-btn-", "");
            let today = new Date();
            let dd = String(today.getDate()).padStart(2, '0');
            let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            let yyyy = today.getFullYear();
            let hh = String(today.getHours()).padStart(2, '0');
            let ii = String(today.getMinutes()).padStart(2, '0');
            let now = 'Le ' + mm + '/' + dd + '/' + yyyy + ' à ' + hh + ':' + ii;
            $('#stock-commentaire-textarea-' + idVehicule).val($('#stock-commentaire-textarea-' + idVehicule).data("commentaire").length ? $('#stock-commentaire-textarea-' + idVehicule).data("commentaire") + "\n\n" + now + "\n\n" : now + "\n\n");
        });

        $('body').on('shown.bs.modal', '[id^="stock-commentaire-modal-"]', function () {
            $('.modal-backdrop').css('z-index', 1035);
        })

        $('body').on('click', "[id^=stock-commentaire-save-]", function () {
            let idVehicule = this.id.replace("stock-commentaire-save-", "");
            let form = new FormData();
            form.append('id', idVehicule);
            form.append('commentaire', $("#stock-commentaire-textarea-" + idVehicule).val());
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/stock/update/commentaire',
                contentType: false,
                processData: false,
                data: form,
                success: function (commentaire) {
                    toastr.success("Le commentaire a bien été enregistré", "Succès");
                    $("#stock-commentaire-textarea-" + idVehicule).val("");
                    $("#stock-commentaire-textarea-" + idVehicule).data("commentaire", commentaire);
                    $("#stock-commentaire-modal-" + idVehicule).modal("hide");
                    if (commentaire.length) { $("[id^=stock-commentaire-indicator-" + idVehicule + "]").removeClass("d-none"); }
                    else { $("[id^=stock-commentaire-indicator-" + idVehicule + "]").addClass("d-none"); }
                    console.log(commentaire.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br />$2'));
                    $('[id^=stock-commentaire-btn-' + idVehicule + "]").parent().find(".adminTableTooltip").html(commentaire.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br />$2'));
                    if (stock_table != null) { stock_table.rows().invalidate(); }
                }
            });
        });

        if (isStockPage) {
            filtreConfigFunOnEvent();
        } else {
            loadAjax();
        }
        initDateMEC();
    }
});

function setPrixMarchandRemise(el, regexPrixMarchandRemise, idVehicule) {
    let newContent = el.val().match(regexPrixMarchandRemise);
    if (newContent == null) {
        newContent = [0];
        el.val("");
    } else {
        el.val(newContent.join(""));
    }
    let form = new FormData();
    form.append('id', idVehicule);
    form.append('prix_remise_marchand', parseInt(newContent.join("")));
    $.ajax({
        type: 'POST',
        url: siteURL + 'admin/stock/update/setPrixMarchandRemise',
        contentType: false,
        processData: false,
        data: form,
        success: function (data) {
            // OK
        }
    });
    return form.get('prix_remise_marchand');
}

function setPrixMiniMarchand(el, regexPrixMiniMarchand, idVehicule) {
    let newContent = el.val().match(regexPrixMiniMarchand);
    if (newContent == null) {
        newContent = [0];
        el.val("");
    } else {
        el.val(newContent.join(""));
    }
    let form = new FormData();
    form.append('id', idVehicule);
    form.append('prix_mini_marchand', parseInt(newContent.join("")));
    $.ajax({
        type: 'POST',
        url: siteURL + 'admin/stock/update/setPrixMiniMarchand',
        contentType: false,
        processData: false,
        data: form,
        success: function (data) {
            // OK
        }
    });
    return form.get('prix_mini_marchand');
}

function stockLinkGenerator() {
    // Génération dynamique des liens sur le tableau
    stock_table.rows().invalidate();
    let all_data = stock_table.rows().data();
    $.each(all_data, function (key, value) {
        let tr = $('#' + value["DT_RowId"]);
        if (tr.length != 0) {
            $("#stock_lacentrale_" + value["DT_RowId"].replace("stock-tr-", "")).attr("href", lacentraleLinkGenerator(tr.attr("data-marque"), tr.attr("data-modele"), tr.attr("data-boite"), tr.attr("data-carburant"), tr.attr("data-datemec"), tr.attr("data-kilometrage")));
            $("#stock_leboncoin_" + value["DT_RowId"].replace("stock-tr-", "")).attr("href", leboncoinLinkGenerator(tr.attr("data-marque"), tr.attr("data-modele"), tr.attr("data-boite"), tr.attr("data-carburant"), tr.attr("data-datemec"), tr.attr("data-kilometrage")));
            $("#stock_starterre_" + value["DT_RowId"].replace("stock-tr-", "")).attr("href", starterreLinkGenerator(tr.attr("data-marque"), tr.attr("data-modele"), tr.attr("data-boite"), tr.attr("data-carburant"), tr.attr("data-datemec"), tr.attr("data-kilometrage")));
            $("#stock_aramisauto_" + value["DT_RowId"].replace("stock-tr-", "")).attr("href", aramisautoLinkGenerator(tr.attr("data-marque"), tr.attr("data-modele"), tr.attr("data-boite"), tr.attr("data-carburant"), tr.attr("data-datemec"), tr.attr("data-kilometrage")));
        }
    });
}

function stockGraphInit(form) {
    $.ajax({
        type: 'POST',
        url: siteURL + 'admin/stock/stock_graph',
        contentType: false,
        processData: false,
        data: form,
        success: function (datas) {
            const doughnutConfig = {
                baseId: "DonutChart", type: "doughnut", unit: "%",
                options: {
                    maintainAspectRatio: false,
                    responsive: true,
                    tooltips: { callbacks: { label: function (tooltipItem, data) { return data.labels[tooltipItem.index].match(/.+[ ]/g) + ": " + data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] + "%"; } } }
                }, data: { labels: [], datasets: [{ data: [], backgroundColor: ['#f56954', '#00a65a', '#f39c12', '#00c0ef', '#3c8dbc', '#d2d6de', '#dd8888', '#88dddd', '#88dd88', '#dd88dd', '#dddd88', '#8888dd', '#b3ff66'] }] }
            };
            const barConfig = {
                baseId: "BarChart", type: "bar", unit: "",
                options: {
                    maintainAspectRatio: false,
                    responsive: true,
                    scales: { yAxes: [{ ticks: { beginAtZero: true } }] },
                    legend: { display: false },
                    tooltips: { callbacks: { label: function (tooltipItem, data) { return data.labels[tooltipItem.index].match(/.+[ ]/g) + ": " + data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]; } } }
                }, data: { labels: [], datasets: [{ data: [] }] }
            };
            [doughnutConfig, barConfig].forEach(config => {
                Object.keys(datas).forEach(key => {
                    config.data.datasets[0].data = [], config.data.labels = [];
                    datas[key].data.forEach((v, i) => { config.data.datasets[0].data[i] = config.unit == '%' ? Math.round((v / datas[key].data.reduce((a, v) => a + v, 0)) * 100) : v; });
                    datas[key].label.forEach((l, i) => { config.data.labels[i] = l + " (" + config.data.datasets[0].data[i] + config.unit + ")"; });
                    document.getElementById(key + config.baseId).replaceWith(document.getElementById(key + config.baseId).cloneNode(true));
                    new Chart(document.getElementById(key + config.baseId).getContext('2d'), {
                        type: config.type,
                        options: config.options,
                        data: { labels: config.data.labels, datasets: [{ data: config.data.datasets[0].data, backgroundColor: config.data.datasets[0].backgroundColor }] }
                    });
                });
            });
        }
    });
}