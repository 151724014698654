// Tiré du codepen https://codepen.io/chrisbeast/pen/ebYwpX
// Article aidant : https://www.digitalocean.com/community/tutorials/front-and-rear-camera-access-with-javascripts-getusermedia-fr

function lancementScriptVideoVIN(idChampARemplir, name = "reception") {
    console.log('idChampARemplir :', idChampARemplir);
    console.log('name :', name);
    let stream = null;
    const parent = document.querySelector('#' + name + '-canva-photo-vin');
    const screenshot = parent.querySelector('#screenshot');
    const video = parent.querySelector('video');
    const canvas = parent.querySelector('canvas');
    const closeModalVideo = parent.querySelector('#btn-fermeture-' + name + '-canva-photo-vin');
    let streamStarted = false;

    const constraints = {
        video: {
            width: 1920,
            height: 1080,
            //* Le facingMode est à mettre en commentaire pour tester sur ordi, sinon ça force la caméra extérieur
            facingMode: {
                exact: 'environment'
            }
        }
    };

    const startStream = async (constraints) => {
        stream = await navigator.mediaDevices.getUserMedia(constraints);
        handleStream(stream);
    };

    const handleStream = (stream) => {
        video.srcObject = stream;
    };

    const autoPlay = () => {
        if (streamStarted) {
            video.play();
            return;
        }
        if ('mediaDevices' in navigator && navigator.mediaDevices.getUserMedia) {
            const updatedConstraints = {
                ...constraints
            };
            startStream(updatedConstraints);
        }
    }

    const closeStream = () => {
        let tracks = stream.getVideoTracks();
        tracks.forEach(track => track.stop());
        video.srcObject = null;
    };

    const doScreenshot = () => {
        // Si l'écran est en portrait
        if (video.videoHeight > video.videoWidth) {
            canvas.width = ((video.videoWidth * 90) / 100);
            canvas.height = ((video.videoHeight * 10) / 100);
            canvas.getContext('2d').drawImage(
                video, // image,
                ((video.videoWidth * 5) / 100), // sx, X du point de départ de l'image de départ
                ((video.videoHeight * 45) / 100), // sy, Y du point de départ de l'image de départ
                ((video.videoWidth * 90) / 100), // sWidth, Largeur du bout de l'image de départ
                ((video.videoHeight * 10) / 100), // sHeight, Hauteur du bout de l'image de départ
                0, // dx, X du point de départ de l'image d'arrivé
                0, // dy, Y du point de départ de l'image d'arrivé
                ((video.videoWidth * 90) / 100), // dWidth, Largeur du bout de l'image d'arrivé
                ((video.videoHeight * 10) / 100)  // dHeight, Hauteur du bout de l'image d'arrivé
            );
        } else { // Si l'écran est en paysage
            canvas.width = ((video.videoWidth * 50) / 100);
            canvas.height = ((video.videoHeight * 20) / 100);
            canvas.getContext('2d').drawImage(
                video, // image,
                ((video.videoWidth * 25) / 100), // sx, X du point de départ de l'image de départ
                ((video.videoHeight * 40) / 100), // sy, Y du point de départ de l'image de départ
                ((video.videoWidth * 50) / 100), // sWidth, Largeur du bout de l'image de départ
                ((video.videoHeight * 25) / 100), // sHeight, Hauteur du bout de l'image de départ
                0, // dx, X du point de départ de l'image d'arrivé
                0, // dy, Y du point de départ de l'image d'arrivé
                ((video.videoWidth * 50) / 100), // dWidth, Largeur du bout de l'image d'arrivé
                ((video.videoHeight * 20) / 100)  // dHeight, Hauteur du bout de l'image d'arrivé
            );
        }

        // Utilisation de l'API OCR pour lire l'imaage
        // Code repris de https://ocr.space/OCRAPI#js
        // Prepare form data
        let formData = new FormData();
        formData.append("base64Image", canvas.toDataURL('image/jpeg'));
        formData.append("language", "eng");
        formData.append("apikey", "K85533975988957"); // Clé API gratuite
        formData.append("OCREngine", 2) // Laisser le moteur 2, car plus performant dans notre cas
        // On envoie l'image à l'API OCR
        $.ajax({
            url: "https://api.ocr.space/parse/image",
            data: formData,
            dataType: 'json',
            cache: false,
            contentType: false,
            processData: false,
            type: 'POST',
            success: function (ocrParsedResult) {
                let resultatOCR = ocrParsedResult["ParsedResults"];
                let text = "";
                if (resultatOCR != null) {
                    $.each(resultatOCR, function (index, value) {
                        if (value["ParsedText"].length > text.length) {
                            text = value["ParsedText"];
                        }
                    });
                    // On met le VIN trouvé par l'OCR dans l'input en enlevant les caractères spéciaux
                    $(idChampARemplir).val(text.replace(/[^a-zA-Z0-9]/g, '').replace('O', '0'));
                    toastr.success("Texte placé dans le champ, veuillez le vérifier.", 'Succès');
                    $('#btn-fermeture-' + name + '-canva-photo-vin').trigger('click');
                    $(idChampARemplir).trigger('input');
                    if (name == "search") {
                        $(idChampARemplir).trigger('click');
                    }
                } else {
                    console.log("errorMessage :", ocrParsedResult["ErrorMessage"]);
                    console.log("errorDetails :", ocrParsedResult["ErrorDetails"]);
                    toastr.error("Erreur avec l'OCR, essayez de recommencer.", 'Error');
                }
                // On arrête l'enregistrement de la vidéo
                closeStream();
            }
        });

        // On enregistre la photo du vin sur la réception
        if (name == "reception") {
            // Capture et envoi de la photo "originelle" (sans rognage)
            const originCanvas = document.createElement('canvas');
            originCanvas.width = video.videoWidth;
            originCanvas.height = video.videoHeight;
            originCanvas.getContext('2d').drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

            originCanvas.toBlob(blob => {
                const originFile = new File([blob], "photo_vin_" + $("#stock_vehicule-reception").attr("data-idvs") + ".jpg", { type: 'image/jpeg' });

                let form = new FormData();
                form.append("data", originFile);
                form.append('filemime', "image");
                form.append('filetypes', "jpg,jpeg,png,webp");
                form.append('imageratio', null);
                form.append('fileexist', 0);

                $.ajax({
                    url: siteURL + "admin/file/upload",
                    data: form,
                    dataType: 'json',
                    cache: false,
                    contentType: false,
                    processData: false,
                    type: 'POST',
                    success: function (response) {
                        console.log("Photo vin enregistré avec succès :", response);
                        let form = new FormData();
                        form.append('idVehicule', $("#stock_vehicule-reception").attr("data-idvs"));
                        form.append('idPhotoVin', response.id);

                        $.ajax({
                            url: siteURL + 'admin/stock/update/reception-enregistrement-photo-vin',
                            data: form,
                            contentType: false,
                            processData: false,
                            type: 'POST',
                            success: function (response) {
                                console.log("Photo vin enregistré sur la réception avec succès :", response);
                            },
                            error: function (error) {
                                console.error("Erreur lors de l'enregistrement sur la réception de la photo vin :", error);
                            }
                        });
                    },
                    error: function (error) {
                        console.error("Erreur lors de l'enregistrement de la photo vin :", error);
                    }
                });
            }, 'image/jpeg');
        }
    };

    screenshot.onclick = doScreenshot;
    closeModalVideo.onclick = closeStream;
    autoPlay();
}