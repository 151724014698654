$(document).ready(function() {

    // Génération de la datatable des devis
    devi_table = $('#devis_table').DataTable({
        "autoWidth": false,
        "language": {
            "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/fr-FR.json"
        },
        "responsive": true,
        "ajax": siteURL + 'json/devis',
        "order": [
            [0, "desc"]
        ],
        "columnDefs": [
            // TEXT CENTER
            {
                "className": "text-center align-middle",
                "targets": [0,1,2,5,6,7,8],
            },
            // ALIGN MIDDLE
            {
                "className": "align-middle",
                "targets": '_all',
            },
            // DATE CREATION
            {
                "targets": 0,
                "render": function(data, type, row) {
                    var monthNames = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];
                    var deviDateCreation = row[0].split('/');
                    if (deviDateCreation != '') {
                        return deviDateCreation[0] + '/' + deviDateCreation[1] + '/' + deviDateCreation[2];
                    } else {
                        return '--';
                    }
                },
            },
            //DEVIS
            {
                "targets": 1,
                "render": function(data, type, row) {
                    return 'DW'+row[1].toString().padStart(5, '0');
                },
            },
            {
                "targets": 2,
                "render": function(data, type, row) {
                    if (row[10] != "") {
                        return '<a href="'+siteURL+'admin/stock/'+row[10]+'" target="_blank">'+row[10]+'</a>';
                    }
                    return '';
                },
            },
            // IDENTITE
            {
                "targets": 3,
                "render": function(data, type, row) {
                    return row[3] + ' ' + row[4];
                },
            },
            // VILLE
            {
                "targets": 4,
                "render": function(data, type, row) {
                    return row[5] + ' ' + row[6];
                },
            },
            // ACHAT
            {
                "targets": 5,
                "render": function(data, type, row) {
                    if (row[2] == "comptant") {
                        return '<i class="fas fa-circle text-success"></i>';
                    }
                    else {
                        return '';
                    }
                },
            },
            // LOCATION
            {
                "targets": 6,
                "render": function(data, type, row) {
                    if (row[2] == "credit") {
                        return '<i class="fas fa-circle text-success"></i>';
                    }
                    else {
                        return '';
                    }
                },
            },
            // MONTANT
            {
                "targets": 7,
                "render": function(data, type, row) {
                    if (row[2] == "credit") {
                        return "<span class='text-lowercase' td-prix>"+row[9]+' €/mois'+"</span>";
                    }
                    else {
                        return "<span td-prix>"+row[9]+' €'+"</span>";
                    }
                },
            },
            // MONTANT
            {
                "targets": 8,
                "render": function(data, type, row) {
                    return "<a href='"+siteURL+'devis_pdf_display/'+row[10]+'/'+row[1]+"'><i class='fa-2x fad fa-file-pdf'></i>";
                },
            },
        ],
        "conditionalPaging": true,
    });

});