let vehicule_fournisseur_achete_table = null;
let vehicule_fournisseur_achete_table_page = 0;
let timerBeforeRefreshDataVehiculeFournisseurAchete = null;

$(document).ready(function () {
    if ($("#vehicule_fournisseur_achete_results").length) {
        window.history.pushState("", "", siteURL + "admin/achat/vehicules_fournisseur_achete");

        let trieColonne = [];

        colvisFunOnEvent();

        function loadAjax({ isPageReload = false, goToVehiculeFournisseur = "" } = {}) {
            let id_clicked = parseInt($("#vehicule_fournisseur-tabs-list-tab").attr("data-load-ajax-id"));
            $("#vehicule_fournisseur-tabs-list-tab").attr("data-load-ajax-id", 0);
            let optionsRecherche = new FormData();
            optionsRecherche.append('identifiant', $("#filtersvehiculefournisseur-identifiant").val());
            optionsRecherche.append('marques', $("#filtersvehiculefournisseur-marque").val());
            optionsRecherche.append('modeles', $("#filtersvehiculefournisseur-modele").val());
            optionsRecherche.append('statut', $("#filtersvehiculefournisseur-statut").val());
            optionsRecherche.append('is_verif', $("#filtersvehiculefournisseur-is_verif").val());
            optionsRecherche.append('active', ($("#filtersvehiculefournisseur-active").val() == "on" ? 0 : 1));
            optionsRecherche.append('createur', $("#filtersvehiculefournisseur-createur").val());
            optionsRecherche.append('fournisseur', $("#filtersvehiculefournisseur-fournisseur").val());
            $("#vehicule_fournisseur_achete_results").addClass("d-none");
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/achat/vehicule_fournisseur_achete_ajax',
                contentType: false,
                processData: false,
                data: optionsRecherche,
                success: function (data) {
                    $("#vehicule_fournisseur_achete_results").html(data);
                    vehicule_fournisseur_achete_table = $('#vehicule_fournisseur_achete_table').DataTable({
                        "dom": dataTablesCustomDom(),
                        "buttons": [dataTablesColvisBtn('th:nth-child(n+3):not(:last-child)')],
                        "columns": getColvisColumn("vehicule_fournisseur_achete_table"),
                        "oSearch": { "sSearch": (goToVehiculeFournisseur ? "id:[" + goToVehiculeFournisseur.toString() + "]" : "") },
                        "autoWidth": false,
                        "language": {
                            "url": "//cdn.datatables.net/plug-ins/2.0.3/i18n/en-GB.json"
                        },
                        "paging": true,
                        "pageLength": 50,
                        "lengthMenu": [[10, 25, 50, 100, -1], [10, 25, 50, 100, "All"]],
                        "responsive": true,
                        "order": [
                            [1, "desc"]
                        ],
                        "columnDefs": [
                            { type: 'num', targets: [1, 8] },
                            // ALIGN MIDDLE
                            {
                                "className": "text-center align-middle",
                                "targets": '_all',
                            },
                            // MODIFY
                            {
                                "targets": [0, 2, 14],
                                "orderable": false,
                            },
                        ],
                        "conditionalPaging": true,
                    });
                    $('#vehicule_fournisseur_achete_table').on('init.dt', function () { initMirrorBtn(); setColvisTable(vehicule_fournisseur_achete_table); colvisFunSelectRefresh(); });
                    $('#vehicule_fournisseur_achete_table').on('draw.dt', function () {
                        // Génération dynamique des liens sur le tableau
                        vehicule_fournisseur_achete_table.rows().invalidate();
                        let all_data = vehicule_fournisseur_achete_table.rows().data();
                        $.each(all_data, function (key, value) {
                            let idCheckBox = value[1]["@data-order"];
                            let tr = $('#vehicule_fournisseur_achete_table_tr_' + idCheckBox);
                            if (tr.length) {
                                $("#vehicule_fournisseur_achete_lacentrale_" + idCheckBox.replace("selection_vehicule_fournisseur_achete_", "")).attr("href", lacentraleLinkGenerator(tr.attr("data-marque"), tr.attr("data-modele"), tr.attr("data-boite"), tr.attr("data-carburant"), tr.attr("data-datemec"), tr.attr("data-kilometrage")));
                                $("#vehicule_fournisseur_achete_leboncoin_" + idCheckBox.replace("selection_vehicule_fournisseur_achete_", "")).attr("href", leboncoinLinkGenerator(tr.attr("data-marque"), tr.attr("data-modele"), tr.attr("data-boite"), tr.attr("data-carburant"), tr.attr("data-datemec"), tr.attr("data-kilometrage")));
                                $("#vehicule_fournisseur_achete_starterre_" + idCheckBox.replace("selection_vehicule_fournisseur_achete_", "")).attr("href", starterreLinkGenerator(tr.attr("data-marque"), tr.attr("data-modele"), tr.attr("data-boite"), tr.attr("data-carburant"), tr.attr("data-datemec"), tr.attr("data-kilometrage")));
                                $("#vehicule_fournisseur_achete_aramisauto_" + idCheckBox.replace("selection_vehicule_fournisseur_achete_", "")).attr("href", aramisautoLinkGenerator(tr.attr("data-marque"), tr.attr("data-modele"), tr.attr("data-boite"), tr.attr("data-carburant"), tr.attr("data-datemec"), tr.attr("data-kilometrage")));
                            }
                        });
                        $("#vehicule_fournisseur_achete_results").removeClass("d-none");
                        if (isPageReload) {
                            vehicule_fournisseur_achete_table_page = vehicule_fournisseur_achete_table.page();
                            // On affiche le tableau
                            $("#vehicule_fournisseur_achete_results").removeClass("d-none");
                            if (goToVehiculeFournisseur) {
                                $("#vehicule_fournisseur-open-modify-" + goToVehiculeFournisseur).trigger("click");
                            }
                        } else {
                            isPageReload = true;
                            if (trieColonne != null && trieColonne.length > 0) {
                                vehicule_fournisseur_achete_table.order([trieColonne[0][0], trieColonne[0][1]]).page(vehicule_fournisseur_achete_table_page).draw(false);
                            } else {
                                vehicule_fournisseur_achete_table.page(vehicule_fournisseur_achete_table_page).draw(false);
                            }
                        }
                        if (id_clicked) {
                            $('html,body').animate({ scrollTop: $('#selection_vehicule_fournisseur_achete_' + id_clicked).parent().offset().top - $('.navbar').height() - $($("#vehicule_fournisseur_achete_table")[0].firstElementChild).height() }, 'slow');
                        }
                    });
                }
            })
        };

        // Pour garder l'ordre de tri des colonnes
        $('body').on('click', "th", function () {
            if (trieColonne[0] == vehicule_fournisseur_achete_table.order()[0]) {
                if (trieColonne[0][1] == 'desc') {
                    trieColonne[0][1] = 'asc';
                } else if (trieColonne[0][1] == 'asc') {
                    trieColonne[0][1] = 'desc';
                }
            } else {
                trieColonne[0] = vehicule_fournisseur_achete_table.order()[0];
            }
            vehicule_fournisseur_achete_table.order.neutral();
            vehicule_fournisseur_achete_table.order([1, "asc"]).order([trieColonne[0][0], trieColonne[0][1]]);
            vehicule_fournisseur_achete_table.draw(false);
        });

        setupLoadAjax(loadAjax);
        setupLoadModele(loadModele);
        setupResetFilter(resetFilter);

        $('body').on('keyup', "[id^='filtersvehiculefournisseur-identifiant']", function () {
            clearTimeout(timerBeforeRefreshDataVehiculeFournisseurAchete);
            timerBeforeRefreshDataVehiculeFournisseurAchete = setTimeout(loadAjax({ isPageReload: true }), 500);
        });

        $('body').on('change', "[id^='filtersvehiculefournisseur-']", function () {
            clearTimeout(timerBeforeRefreshDataVehiculeFournisseurAchete);
            timerBeforeRefreshDataVehiculeFournisseurAchete = setTimeout(loadAjax({ isPageReload: true }), 500);
        });

        // Bouton reset
        $('body').on('click', '#filtersvehiculefournisseur-reset', function () {
            resetFilter();
            loadAjax({ isPageReload: true });
        });

        $('body').on('click', "[id^='menu-vehicule-fournisseur-achete-etat-desachete']", function () {
            if (vehicule_fournisseur_achete_table != null) {
                Lobibox.confirm({
                    title: 'Don\'t buy anymore',
                    baseClass: "modal-content modal-lobistrap",
                    msg: "Are you sure you no longer want to purchase this item(s)?",
                    buttons: {
                        cancel: {
                            type: 'ok',
                            text: 'Don\'t buy anymore'
                        },
                        ok: {
                            type: 'cancel',
                            text: 'Cancel'
                        }
                    },
                    callback: function (lobibox, type) {
                        if (type === 'cancel') {
                            vehicule_fournisseur_achete_table.rows().invalidate();
                            let all_data = vehicule_fournisseur_achete_table.rows().data();
                            let id_vehicules = [];
                            $.each(all_data, function (key, value) {
                                let idCheckBox = value[1]["@data-order"];
                                if ($("#selection_vehicule_fournisseur_achete_" + idCheckBox).is(':checked')) {
                                    id_vehicules.push(idCheckBox.replace("selection_vehicule_fournisseur_achete_", ""));
                                }
                            });
                            // Si aucun véhicule est rentré
                            if (id_vehicules.length == 0) {
                                toastr.error('Please select at least one vehicle', 'Error');
                                return false;
                            }
                            let form = new FormData();
                            form.append('id_vehicules', id_vehicules);
                            vfEtatModify(form, "desachete", true);
                        }
                    },
                });
            }
        });

        $('body').on('click', "[id^='vehicule-fournisseur-achete-etat-desachete-']", function () {
            let idVF = this.id.replace(/\w+-/g, "");
            Lobibox.confirm({
                title: 'Don\'t buy anymore',
                baseClass: "modal-content modal-lobistrap",
                msg: "Are you sure you no longer want to purchase this item(s)?",
                buttons: {
                    cancel: {
                        type: 'ok',
                        text: 'Don\'t buy anymore'
                    },
                    ok: {
                        type: 'cancel',
                        text: 'Cancel'
                    }
                },
                callback: function (lobibox, type) {
                    if (type === 'cancel') {
                        let form = new FormData();
                        form.append('id_vehicules', [idVF]);
                        vfEtatModify(form, "desachete");
                    }
                },
            });
        });

        $('body').on('click', "#menu-vehicule-fournisseur-achete-delete", function () {
            if (vehicule_fournisseur_achete_table != null) {
                Lobibox.confirm({
                    title: 'Deletion',
                    baseClass: "modal-content modal-lobistrap",
                    msg: "Are you sure you want to delete this item <strong>permanently</strong> from the database? Please note, it will be <strong>impossible</strong> to restore it afterwards.",
                    buttons: {
                        cancel: {
                            type: 'ok',
                            text: 'Delete'
                        },
                        ok: {
                            type: 'cancel',
                            text: 'Keep'
                        }
                    },
                    callback: function (lobibox, type) {
                        if (type === 'cancel') {
                            vehicule_fournisseur_achete_table.rows().invalidate();
                            let all_data = vehicule_fournisseur_achete_table.rows().data();
                            let id_vehicules = [];
                            $.each(all_data, function (key, value) {
                                let idCheckBox = value[1]["@data-order"];
                                if ($("#selection_vehicule_fournisseur_achete_" + idCheckBox).is(':checked')) {
                                    id_vehicules.push(idCheckBox.replace("selection_vehicule_fournisseur_achete_", ""));
                                }
                            });
                            // Si aucun véhicule est rentré
                            if (id_vehicules.length == 0) {
                                toastr.error('Please select at least one vehicle', 'Erreur');
                                return false;
                            }
                            let form = new FormData();
                            form.append('id_vehicules', id_vehicules);
                            $.ajax({
                                type: 'POST',
                                url: siteURL + 'admin/vehicule_fournisseur/update/deleteSeveral',
                                contentType: false,
                                processData: false,
                                data: form,
                                success: function (data) {
                                    if (data.split(',').length > 1) {
                                        toastr.success('Disabled supplier vehicles', 'Success');
                                    } else {
                                        toastr.success('Disabled supplier vehicle', 'Success');
                                    }
                                    loadAjax();
                                    return false;
                                },
                                error: function () {
                                    toastr.error('Error when deactivating supplier vehicles', 'Error');
                                }
                            });
                        }
                    },
                });
            }
        });

        // Bouton refresh
        $('body').on('click', "#vehicule_fournisseur-tabs-list-tab", function () {
            loadAjax();
        });


        $('body').on('click', "#menu-vehicule-fournisseur-achete-extraire", function () {
            if (vehicule_fournisseur_achete_table != null) {
                vehicule_fournisseur_achete_table.rows().invalidate();
                let all_data = vehicule_fournisseur_achete_table.rows().data();
                id_vehicules = [];
                $.each(all_data, function (key, value) {
                    let idCheckBox = value[1]["@data-order"];
                    if ($("#selection_vehicule_fournisseur_achete_" + idCheckBox).is(':checked')) {
                        id_vehicules.push(idCheckBox.replace("selection_vehicule_fournisseur_achete_", ""));
                    }
                });
                // Si aucun véhicule est rentré
                if (id_vehicules.length == 0) {
                    toastr.error('Please select at least one vehicle', 'Error');
                    return false;
                }

                var form = new FormData();
                form.append('ids', id_vehicules);
                today = new Date();
                vfaCSV(form);
            }
        });

        $('body').on('click', "[id^='vehicule-fournisseur-achete-extraire-']", function () {
            var form = new FormData();
            form.append('ids', [this.id.replace(/\w+-/g, "")]);
            today = new Date();
            vfaCSV(form);
        });

        function checkDateExport(check, form) {
            const error = [];
            let time = null;
            let offset = null;
            check.forEach(result => {
                time = new Date(result.date_export.date).getTime(); // Récupère le timestamp
                offset = new Date(result.date_export.date).getTimezoneOffset() * 60000; // Réajuste la timezone
                error.push("<b>vf-" + result.id + "</b> le <b>" + new Date(time - offset).toLocaleString('fr-FR', { timeZone: 'Europe/Paris' }) + "</b>");
            });

            Lobibox.confirm({
                title: 'Confirmation',
                baseClass: "modal-content modal-lobistrap",
                msg: "Attention, des véhicules ont déjà été exportés vers Spider :<br>" + error.join("<br>") + "<br><br>Pensez à contrôler qu’ils ne soient pas déjà créés dans Spider !<br><br>Souhaitez-vous quand même exporter ces véhicules ?",
                buttons: {
                    cancel: {
                        type: 'ok',
                        text: 'Oui'
                    },
                    ok: {
                        type: 'cancel',
                        text: 'Non'
                    }
                },
                callback: function (lobibox, type) {
                    if (type === 'cancel') {
                        form.append('skipCheckDateExport', true);
                        vfaCSV(form);
                    }
                },
            });
        }

        $('body').on('change', "input[id^='vehicule_fournisseur_achete_invoiced_']", function (e) {
            const value = e.target.value;
            const idVF = e.target.id.replace("vehicule_fournisseur_achete_invoiced_", "");
            let form = new FormData();
            form.append('idVF', idVF);
            form.append('value', value);
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/vehicule_fournisseur/update/invoiced',
                contentType: false,
                processData: false,
                data: form,
                success: function (success) {
                    if (success) {
                        toastr.success("Enregistrement réussi", "Succès");
                    } else { toastr.error("Erreur lors de l'enregistrement", "Erreur"); }
                },
                error: function () { toastr.error("Erreur lors de l'enregistrement", "Erreur"); }
            });
        });

        $('body').on('click', ".dropdown-item[name^='vehicule_fournisseur_achete_']", function (e) {
            const item = e.target;
            const btn = $("#" + item.name);
            const match = /vehicule_fournisseur_achete_(?<input>\w+)_(?<id>\d+)/.exec(item.name);
            const inputName = match.groups.input;
            const idVF = match.groups.id;
            const idItem = item.getAttribute("data-id");
            const labelItem = item.innerText;
            let form = new FormData();
            form.append('idVF', idVF);
            form.append('idItem', idItem);
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/vehicule_fournisseur/update/' + inputName,
                contentType: false,
                processData: false,
                data: form,
                success: function (success) {
                    if (success) {
                        toastr.success("Enregistrement réussi", "Succès");
                        switch (inputName) {
                            case "buyer": btn.html(idItem != "0" ? '<img class="fit-picture rounded-circle w-2rem" src="' + item.getAttribute("data-src") + '" alt="' + labelItem + '"/>' : "-"); break;
                            case "assignment": btn.html(idItem != "0" ? labelItem : "-"); break;
                        }
                    } else { toastr.error("Erreur lors de l'enregistrement", "Erreur"); }
                },
                error: function () { toastr.error("Erreur lors de l'enregistrement", "Erreur"); }
            });
        });

        function vfaCSV(form) {
            $.ajax({
                type: 'POST',
                url: siteURL + 'admin/vehicule_fournisseur/update/ficheCSV',
                contentType: false,
                processData: false,
                data: form,
                success: function (data) {
                    if (data.success == true) {
                        toastr.success('The file CSV is finish', 'Success');
                        var element = document.createElement('a');
                        element.setAttribute('href', siteURL + "assets/docs/vehicule_fournisseur/results_vfa_spider.csv");
                        element.setAttribute('download', "Liste Vehicule fournisseur achete Ora7 " + today.toLocaleDateString("fr") + ".csv");
                        document.body.appendChild(element);
                        element.click();
                        document.body.removeChild(element);
                    }
                    if (data.vinIncorrect != undefined) { toastr.error('The vehicle(s) with ID ' + data.vinIncorrect.toString() + ' is incorrect : the VIN of this vehicle(s) are incorrect (not 17 caracteres or like XXXXXXXXXXXXXXXXX)', 'Error'); }
                    if (data.checkDateExport != undefined) { checkDateExport(data.checkDateExport, form); }
                    if (data.checkInvoiced != undefined) { toastr.error('The vehicle(s) with ID ' + data.checkInvoiced.toString() + ' is incorrect : the invoiced price of this vehicle(s) are incorrect', 'Error'); }
                    if (data.checkBuyer != undefined) { toastr.error('The vehicle(s) with ID ' + data.checkBuyer.toString() + ' is incorrect : the buyer of this vehicle(s) are not complete', 'Error'); }
                    if (data.checkAssignment != undefined) { toastr.error('The vehicle(s) with ID ' + data.checkAssignment.toString() + ' is incorrect : the assignment of this vehicle(s) are not complete', 'Error'); }
                }
            });
        }

        function resetFilter() {
            $("#filtersvehiculefournisseur-identifiant").val('').selectpicker("refresh");
            $("#filtersvehiculefournisseur-marque").val('default').selectpicker("refresh");
            $("#filtersvehiculefournisseur-modele").find("optgroup").prop('disabled', true);
            $('#filtersvehiculefournisseur-modele').prop("disabled", true);
            $("#filtersvehiculefournisseur-modele").val('default').selectpicker('refresh');
            $("#filtersvehiculefournisseur-statut").val('default').selectpicker("refresh");
            $("#filtersvehiculefournisseur-is_verif").val('default').selectpicker("refresh");
            $("#filtersvehiculefournisseur-active").val('off').prop("checked", false);
            $("#filtersvehiculefournisseur-createur").val('default').selectpicker("refresh");
            $("#filtersvehiculefournisseur-fournisseur").val('default').selectpicker("refresh");
            $("#filtreConfigSelect-filtreConfig-aucun").val('aucun').prop("selected", true);
        }

        const goToVehiculeFournisseur = $("#vehicule_fournisseur_achete_results").data("gotovehiculefournisseur");
        if (goToVehiculeFournisseur != "") {
            loadAjax({ goToVehiculeFournisseur: goToVehiculeFournisseur });
        } else {
            filtreConfigFunOnEvent();
        }
    }
});

// SELECT and DESELECT
function checkVehiculeFournisseurAcheteTab(evt) {
    if (evt.stopPropagation !== undefined) {
        // evt.preventDefault();
        $("[id^='selection_vehicule_fournisseur_achete_']").each(function () {
            if ($("#selection_vehicule_fournisseur_achete").is(':checked')) {
                $(this).prop("checked", true);
            } else {
                $(this).prop("checked", false);
            }
        });
        evt.stopPropagation();
    } else {
        evt.cancelBubble = true;
    }
}

// SELECT and DESELECT
function checkVehiculeFournisseurAchete(evt) {
    if (evt.stopPropagation !== undefined) {
        evt.stopPropagation();
    } else {
        evt.cancelBubble = true;
    }
}

function loadVFA() {
    loadAjax();
}