// Pour permettre que les dialogs dans la même page du stock véhicule fonctionne, on a besoin à chaque fois de spécifier de qu'elle dialog il s'agit

$('body').on('click', "[channel-dialog-prev]", function () {
    const step = $(this).parents("dialog").data("step");
    const name = $(this).parents("dialog").data("name");
    if (name == "reception") {
        receptionChannelPrev(step, name);
    } else {
        channelPrev(step, name);
    }
});

$('body').on('click', "[channel-dialog-next]", function () {
    const step = $(this).parents("dialog").data("step");
    const name = $(this).parents("dialog").data("name");
    // Pour gérer les différentes modales
    if ($(this).parents("dialog").data('name') == "expertise") {
        if ($("#stock_vehicule-expertise").data("step") <= step && !$("#stock_vehicule-expertise").data("stepend")) {
            expertiseChannelNext(step);
        } else {
            channelNext(step, name);
        }
    } else if ($(this).parents("dialog").data('name') == "reception") {
        let dialog = (this.id.startsWith("reception-dialog-") && this.id.endsWith("-open") ? $(this).next() : $(this).parents("[name='reception-dialog']"));
        receptionChannelNext(step, dialog);
    }
});

$('body').on('click', "[channel-dialog-end]", function () {
    const step = $(this).parents("dialog").data("step");
    const name = $(this).parents("dialog").data("name");
    if ($(this).parents("dialog").data('name') == "expertise") {
        if (!$("#stock_vehicule-expertise").data("stepend")) {
            expertiseChannelNext(step, "end");
        } else {
            channelNext(step, name);
        }
    } else if ($(this).parents("dialog").data('name') == "reception") {
        let dialog = (this.id.startsWith("reception-dialog-") && this.id.endsWith("-open") ? $(this).next() : $(this).parents("[name='reception-dialog']"));
        receptionChannelNext(step, dialog, "end");
    } else if ($(this).parents("dialog").data('name') == "papier") {
        let dialog = (this.id.startsWith("papier-dialog-") && this.id.endsWith("-open") ? $(this).next() : $(this).parents("[name='papier-dialog']"));
        updateReceptionPapier(step, dialog);
    }
});