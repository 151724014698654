/**
 * Calcul le statut en lisant les inputs
 * @param {(string|number)} idVF
 * @returns {any}
 */
function calculQuickStudyStatus(idVF) {
    const margeStatus = getMargeStatus($("#vehicule_fournisseur_input_marge_ht_" + idVF).val(), $("#vehicule_fournisseur_input_prix_achat_ht_" + idVF).val());
    setQuickStudyStatus(margeStatus.status, idVF, margeStatus.up, margeStatus.down);

    if (idVF != null && idVF != 0 && $('#offre_fournisseur-tabs').length == 0) {
        let montantMargeHT = $('#vehicule_fournisseur_input_marge_ht_' + idVF).val();
        let prixAchatHT = $("#vehicule_fournisseur_input_prix_achat_ht_" + idVF).val();
        let margeData = getMargeDisplay(montantMargeHT, prixAchatHT);
        console.log('margeData :', margeData);
        let configMarge = {
            type: 'doughnut',
            data: {
                labels: [
                    "Marge",
                ],
                datasets: [{
                    data: [margeData.display.bad.width, margeData.display.avg.width, margeData.display.good.width, margeData.display.leftover.width],
                    backgroundColor: [margeData.display.bad.color, margeData.display.avg.color, margeData.display.good.color, margeData.display.leftover.color],
                    borderWidth: 0,
                }]
            },
            options: {
                circumference: Math.PI,
                rotation: -Math.PI,
                cutoutPercentage: 90,
                legend: {
                    display: false,
                },
                tooltips: {
                    enabled: false,
                },
                hover: false,
            }
        };
        // Update montant et pourcentage marge
        $('#vehicule_fournisseur-rentabilite-montant-marge-vf').html($('#vehicule_fournisseur_input_marge_ht_' + idVF).val().replace(".", ",") + " €");
        $('#vehicule_fournisseur-rentabilite-pourcentage-marge-vf').html(((parseFloat($('#vehicule_fournisseur_input_marge_ht_' + idVF).val()) * 100) / (parseFloat($("#vehicule_fournisseur_input_prix_marche_ttc_" + idVF).val()) / 1.2)).toFixed(2));
        let couleurMarge = (margeData.status.status == "vert" ? "success" : (margeData.status.status == "jaune" ? "warning" : (margeData.status.status == "rouge" ? "danger" : "secondary")));
        // Update couleur montant
        $('#vehicule_fournisseur-rentabilite-montant-marge-vf').removeClass("text-success").removeClass("text-warning").removeClass("text-danger");
        $('#vehicule_fournisseur-rentabilite-montant-marge-vf').addClass("text-" + couleurMarge);
        // Update couleur card
        $('#vehicule_fournisseur_input_marge_ht_' + idVF).parent().removeClass("bg-ultra-soft-success").removeClass("bg-ultra-soft-warning").removeClass("bg-ultra-soft-danger");
        $('#vehicule_fournisseur_input_marge_ht_' + idVF).parent().addClass("bg-ultra-soft-" + couleurMarge);
        $('#vehicule_fournisseur_input_marge_ht_' + idVF).parent().parent().parent().parent().find(".header-left").removeClass("bg-ultra-soft-success").removeClass("bg-ultra-soft-warning").removeClass("bg-ultra-soft-danger");
        $('#vehicule_fournisseur_input_marge_ht_' + idVF).parent().parent().parent().parent().find(".header-left").addClass("bg-ultra-soft-" + couleurMarge);
        $('#vehicule_fournisseur_input_marge_ht_' + idVF).parent().parent().parent().parent().find(".corner-left-bottom-color").removeClass("bg-ultra-soft-success").removeClass("bg-ultra-soft-warning").removeClass("bg-ultra-soft-danger");
        $('#vehicule_fournisseur_input_marge_ht_' + idVF).parent().parent().parent().parent().find(".corner-left-bottom-color").addClass("bg-ultra-soft-" + couleurMarge);

        new Chart(document.getElementById('vehicule_fournisseur-rentabilite-marge-vf-chartjs').getContext('2d'), configMarge);
    }
}

/**
 * Controle l'affichage du statut suivant le résultat de la préétude
 * @param {string} statut
 * @param {(string|number)} idVF
 * @param {number} limitMargeVert=0
 * @param {number} limitMargeJaune=0
 * @returns {any}
 */
function setQuickStudyStatus(statut, idVF, limitMargeVert = 0, limitMargeJaune = 0) {
    $("#quick-study-status-progress_" + idVF + " > .progress-bar").removeClass("bg-success");
    $("#quick-study-status-progress_" + idVF + " > .progress-bar").removeClass("bg-vf-jaune");
    $("#quick-study-status-progress_" + idVF + " > .progress-bar").removeClass("bg-danger");
    $("#quick-study-status-progress_" + idVF + " > .progress-bar").removeClass("bg-secondary");
    $("#vehicule_fournisseur_marge_ht_" + idVF).parent().removeClass("bg-success"); // OF
    $("#vehicule_fournisseur_marge_ht_" + idVF).parent().removeClass("bg-vf-jaune"); // OF
    $("#vehicule_fournisseur_marge_ht_" + idVF).parent().removeClass("bg-danger"); // OF
    $("#vehicule_fournisseur_marge_ht_" + idVF).parent().removeClass("bg-secondary"); // OF
    if (limitMargeVert != 0 && limitMargeJaune != 0) {
        // Calcul des limites
        limitMargeVertAchat = Math.abs(
            limitMargeJaune * 1.2
            + parseFloat($("#vehicule_fournisseur_input_malus_" + idVF).val()) * 1.2
            + parseFloat($("#vehicule_fournisseur_input_fre_ttc_" + idVF).val())
            + parseFloat($("#vehicule_fournisseur_input_prix_transport_ttc_" + idVF).val())
            + parseFloat($("#vehicule_fournisseur_input_fraisPlateforme_ttc_" + idVF).val())
            - parseFloat($("#vehicule_fournisseur_input_prix_marche_ttc_" + idVF).val())
        );
        limitMargeJauneAchat = Math.abs(
            limitMargeVert * 1.2
            + parseFloat($("#vehicule_fournisseur_input_malus_" + idVF).val()) * 1.2
            + parseFloat($("#vehicule_fournisseur_input_fre_ttc_" + idVF).val())
            + parseFloat($("#vehicule_fournisseur_input_prix_transport_ttc_" + idVF).val())
            + parseFloat($("#vehicule_fournisseur_input_fraisPlateforme_ttc_" + idVF).val())
            - parseFloat($("#vehicule_fournisseur_input_prix_marche_ttc_" + idVF).val())
        );
        limitMargeVertMarche = Math.abs(
            limitMargeVert * 1.2
            + parseFloat($("#vehicule_fournisseur_input_malus_" + idVF).val()) * 1.2
            + parseFloat($("#vehicule_fournisseur_input_fre_ttc_" + idVF).val())
            + parseFloat($("#vehicule_fournisseur_input_prix_transport_ttc_" + idVF).val())
            + parseFloat($("#vehicule_fournisseur_input_fraisPlateforme_ttc_" + idVF).val())
            + parseFloat($("#vehicule_fournisseur_input_prix_achat_ttc_" + idVF).val())
        );
        limitMargeJauneMarche = Math.abs(
            limitMargeJaune * 1.2
            + parseFloat($("#vehicule_fournisseur_input_malus_" + idVF).val()) * 1.2
            + parseFloat($("#vehicule_fournisseur_input_fre_ttc_" + idVF).val())
            + parseFloat($("#vehicule_fournisseur_input_prix_transport_ttc_" + idVF).val())
            + parseFloat($("#vehicule_fournisseur_input_fraisPlateforme_ttc_" + idVF).val())
            + parseFloat($("#vehicule_fournisseur_input_prix_achat_ttc_" + idVF).val())
        );

        // Calcul des min / max
        let min = 0;
        let max = limitMargeVert * 1.5;
        if (parseFloat($("#vehicule_fournisseur_input_marge_ht_" + idVF).val()) - min > max) {
            max = parseFloat($("#vehicule_fournisseur_input_marge_ht_" + idVF).val()) - min;
        }
        // Calcul de la bar central
        let widthAchat = 0;
        if (parseFloat($("#vehicule_fournisseur_input_marge_ht_" + idVF).val()) > min) {
            widthAchat = Math.round(((parseFloat($("#vehicule_fournisseur_input_marge_ht_" + idVF).val()) - min) / max) * 100);
        }
        $("#quick-study-status-progress_" + idVF + " > .progress-bar").attr("aria-valuenow", widthAchat);
        $("#quick-study-status-progress_" + idVF + " > .progress-bar").width(widthAchat + "%");
        $("#quick-study-status-progress_" + idVF + " > .quick-study-status-indicator").removeClass("d-none");
        $("#quick-study-status-progress_" + idVF + " > .quick-study-status-indicator").css("margin-left", widthAchat + "%");
        $("#quick-study-status-progress_" + idVF + " > .quick-study-status-indicator-above > .title").html(parseInt($("#vehicule_fournisseur_input_prix_achat_ttc_" + idVF).val()).toFixed(2) + " €/TTC");
        $("#quick-study-status-progress_" + idVF + " > .quick-study-status-indicator-below > .title").html(parseInt($("#vehicule_fournisseur_input_prix_marche_ttc_" + idVF).val()).toFixed(2) + " €/TTC");
        if (statut == "vert") {
            $("#quick-study-status-progress_" + idVF + " > .progress-bar").addClass("bg-success");
            if ($('#offre_fournisseur_information_container').length == 1) { $("#vehicule_fournisseur_marge_ht_" + idVF).parent().addClass("bg-success"); } // OF
        } else if (statut == "jaune") {
            $("#quick-study-status-progress_" + idVF + " > .progress-bar").addClass("bg-vf-jaune");
            if ($('#offre_fournisseur_information_container').length == 1) { $("#vehicule_fournisseur_marge_ht_" + idVF).parent().addClass("bg-vf-jaune"); } // OF
        } else if (statut == "rouge") {
            $("#quick-study-status-progress_" + idVF + " > .progress-bar").addClass("bg-danger");
            if ($('#offre_fournisseur_information_container').length == 1) { $("#vehicule_fournisseur_marge_ht_" + idVF).parent().addClass("bg-danger"); } // OF
        }
        // Calcul des bar de règles
        $("#quick-study-status-rules-progress-achat_" + idVF).removeClass("d-none");
        $("#quick-study-status-rules-progress-marche_" + idVF).removeClass("d-none");
        let widthRouge = Math.round(((limitMargeJaune - min) / max) * 100);
        let widthJaune = Math.round(((limitMargeVert - min) / max) * 100) - widthRouge - 1;
        let widthVert = 100 - widthJaune - widthRouge - 1;
        $("#quick-study-status-rules-progress-achat_" + idVF + " > .bg-danger").attr("aria-valuenow", widthRouge);
        $("#quick-study-status-rules-progress-marche_" + idVF + " > .bg-danger").attr("aria-valuenow", widthRouge);
        $("#quick-study-status-rules-progress-achat_" + idVF + " > .bg-danger").width(widthRouge + "%");
        $("#quick-study-status-rules-progress-marche_" + idVF + " > .bg-danger").width(widthRouge + "%");
        $("#quick-study-status-limit-jaune-achat_" + idVF + "").css("margin-left", widthRouge + 0.5 + "%");
        $("#quick-study-status-limit-jaune-marche_" + idVF + "").css("margin-left", widthRouge + 0.5 + "%");
        $("#quick-study-status-limit-jaune-achat_" + idVF + " > .title").html(limitMargeVertAchat.toFixed(2) + " €/TTC");
        $("#quick-study-status-limit-jaune-marche_" + idVF + " > .title").html(limitMargeJauneMarche.toFixed(2) + " €/TTC");
        $("#quick-study-status-rules-progress-achat_" + idVF + " > .bg-vf-jaune").attr("aria-valuenow", widthJaune);
        $("#quick-study-status-rules-progress-marche_" + idVF + " > .bg-vf-jaune").attr("aria-valuenow", widthJaune);
        $("#quick-study-status-rules-progress-achat_" + idVF + " > .bg-vf-jaune").width(widthJaune + "%");
        $("#quick-study-status-rules-progress-marche_" + idVF + " > .bg-vf-jaune").width(widthJaune + "%");
        $("#quick-study-status-limit-vert-achat_" + idVF + "").css("margin-left", widthRouge + widthJaune + 1.5 + "%");
        $("#quick-study-status-limit-vert-marche_" + idVF + "").css("margin-left", widthRouge + widthJaune + 1.5 + "%");
        $("#quick-study-status-limit-vert-achat_" + idVF + " > .title").html(limitMargeJauneAchat.toFixed(2) + " €/TTC");
        $("#quick-study-status-limit-vert-marche_" + idVF + " > .title").html(limitMargeVertMarche.toFixed(2) + " €/TTC");
        $("#quick-study-status-rules-progress-achat_" + idVF + " > .bg-success").attr("aria-valuenow", widthVert);
        $("#quick-study-status-rules-progress-marche_" + idVF + " > .bg-success").attr("aria-valuenow", widthVert);
        $("#quick-study-status-rules-progress-achat_" + idVF + " > .bg-success").width(widthVert + "%");
        $("#quick-study-status-rules-progress-marche_" + idVF + " > .bg-success").width(widthVert + "%");
    } else {
        $("#quick-study-status-progress_" + idVF + " > .progress-bar").attr("aria-valuenow", 100);
        $("#quick-study-status-progress_" + idVF + " > .progress-bar").width(100 + "%");
        $("#quick-study-status-progress_" + idVF + " > .quick-study-status-indicator").addClass("d-none");
        $("#quick-study-status-progress_" + idVF + " > .quick-study-status-indicator").css("margin-left", "0%");
        $("#quick-study-status-progress_" + idVF + " > .quick-study-status-indicator > .title").html(0 + " €/TTC");
        $("#quick-study-status-progress_" + idVF + " > .progress-bar").addClass("bg-secondary");
        if ($('#offre_fournisseur_information_container').length == 1) { $("#vehicule_fournisseur_marge_ht_" + idVF).parent().addClass("bg-secondary"); } // OF
        $("#quick-study-status-rules-progress-achat_" + idVF + "").addClass("d-none");
        $("#quick-study-status-rules-progress-marche_" + idVF + "").addClass("d-none");
        $("#quick-study-status-rules-progress-achat_" + idVF + " > .bg-danger").attr("aria-valuenow", 0);
        $("#quick-study-status-rules-progress-marche_" + idVF + " > .bg-danger").attr("aria-valuenow", 0);
        $("#quick-study-status-rules-progress-achat_" + idVF + " > .bg-danger").width(0 + "%");
        $("#quick-study-status-rules-progress-marche_" + idVF + " > .bg-danger").width(0 + "%");
        $("#quick-study-status-rules-progress-achat_" + idVF + " > .bg-vf-jaune").attr("aria-valuenow", 0);
        $("#quick-study-status-rules-progress-marche_" + idVF + " > .bg-vf-jaune").attr("aria-valuenow", 0);
        $("#quick-study-status-rules-progress-achat_" + idVF + " > .bg-vf-jaune").width(0 + "%");
        $("#quick-study-status-rules-progress-marche_" + idVF + " > .bg-vf-jaune").width(0 + "%");
        $("#quick-study-status-rules-progress-achat_" + idVF + " > .bg-success").attr("aria-valuenow", 0);
        $("#quick-study-status-rules-progress-marche_" + idVF + " > .bg-success").attr("aria-valuenow", 0);
        $("#quick-study-status-rules-progress-achat_" + idVF + " > .bg-success").width(0 + "%");
        $("#quick-study-status-rules-progress-marche_" + idVF + " > .bg-success").width(0 + "%");
    }
}

/**
 * Indique l'état du statut de la préétude (vert, jaune, rouge, gris)
 * @param {(string|number)} idVF
 * @returns {string}
 */
function getQuickStudyStatus(idVF) {
    if ($("#quick-study-status-progress_" + idVF + " > .progress-bar").hasClass("bg-success")) {
        return "vert";
    } else if ($("#quick-study-status-progress_" + idVF + " > .progress-bar").hasClass("bg-vf-jaune")) {
        return "jaune";
    } else if ($("#quick-study-status-progress_" + idVF + " > .progress-bar").hasClass("bg-danger")) {
        return "rouge";
    } else {
        return "gris";
    }
}
