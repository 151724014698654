document.addEventListener("DOMContentLoaded", function () {
    /* --- FancyBox (handmade) --- */
    $('body').on('click', "[custom-fancy]", fancyOpen); // OPEN
    $('body').on('click', "#custom-fancy-nav-prev", fancyPrev); // PREVIOUS
    $('body').on('click', "#custom-fancy-nav-next", fancyNext); // NEXT
});

/**
 * OPEN / DISPLAY
 */
fancyOpen = (e) => { // Open fancy modal
    fancyDisplay(e.target);
    fancyGetModal(e.target).modal('show');
}

fancyDisplay = (el) => { // Open fancy modal
    fancyGetModal(el).find("#custom-fancy-img").attr("src", fancyGetSrc(el));
    fancyGetModal(el).find("#custom-fancy-img").attr("custom-fancy", fancyGetId(el));
    fancyGetModal(el).find("#custom-fancy-img").attr("custom-fancy-index", fancyGetIndex(el));
}
// OPEN / DISPLAY

/**
 * PREV / NEXT
 */
fancyPrev = (e) => { // Fonction de passage à l'image précédente
    const el = fancyGetModal(e.target).find("#custom-fancy-img")[0];
    fancyDisplay(fancyGetImg(fancyGetIndex(el) - (fancyIsFirst(el) ? 0 : 1), fancyGetId(el)));
}

fancyNext = (e) => { // Fonction de passage à l'image suivante
    const el = fancyGetModal(e.target).find("#custom-fancy-img")[0];
    fancyDisplay(fancyGetImg(fancyGetIndex(el) + (fancyIsLast(el) ? 0 : 1), fancyGetId(el)));
}
// PREV / NEXT

/**
 * GET
 */
fancyGetModal = (el) => { // Get fancy modal id
    return ($(el).parents("dialog").length ? $(el).parents("dialog").find("[custom-fancy-modal]") : $("#custom-fancy"));
}

fancyGetGroup = (id = null) => { // Get group of img
    if (id == null) { id = fancyGetId(); }
    return Array.from(document.querySelectorAll("img[custom-fancy='" + id + "']:not([custom-fancy-disabled])"));
}

fancyGetSrc = (el = null) => { // Get src of group
    if (el == null) { el = fancyGetImg(); }
    return el.getAttribute("src");
}

fancyGetId = (el = null) => { // Get id of group
    if (el == null) { el = fancyGetImg(); }
    return el.getAttribute("custom-fancy");
}

fancyGetIndex = (el = null) => { // Get index in group
    if (el == null) { el = fancyGetImg(); }
    if (el.getAttribute("custom-fancy-index") !== null) {
        return parseInt(el.getAttribute("custom-fancy-index"));
    }
    return fancyGetGroup(fancyGetId(el)).indexOf(el);
}

fancyGetImg = (index = null, id = null) => { // Get img by index
    if (index == null) { index = fancyGetIndex(); }
    return fancyGetGroup(id)[index];
}
// GET

/**
 * IS
 */
fancyIsFirst = (el) => { // Is first img of group
    return fancyGetIndex(el) == 0;
}

fancyIsLast = (el) => { // Is last img of group
    return fancyGetIndex(el) == fancyGetGroup(fancyGetId(el)).length - 1;
}
// IS
