
$(document).ready(function() {
    if ($("#pvlivraison_page").length) {
        $('body').on('change', "#pvlivraison-input-typeCession", function(e) {
            if (parseInt(this.value)) { $("#pvlivraison-input-partenaireFinancier").parent().parent().parent().removeClass("d-none"); }
            else { $("#pvlivraison-input-partenaireFinancier").parent().parent().parent().addClass("d-none"); }
        });

        $('body').on('click', "#pvlivraison-suivant", function(e) {
            let optionsRecherche = new FormData();
            optionsRecherche.append('id', $("#pvlivraison-input-id").val());
            optionsRecherche.append('typeCession', $("#pvlivraison-input-typeCession").val());
            optionsRecherche.append('partenaireFinancier', $("#pvlivraison-input-partenaireFinancier").val());
            optionsRecherche.append('agence', $("#pvlivraison-input-agence").val());
            optionsRecherche.append('titulaire', $("#pvlivraison-input-titulaire").val());
            $.ajax({
                type:'POST',
                url: siteURL + 'admin/outils/pvlivraison/suivant',
                contentType: false,
                processData: false,
                data: optionsRecherche,
                success: function(data) {
                    $("#pvlivraison-form").html(data);
                    $(".selectpicker").selectpicker();
                    $("#pvlivraison-input-millesime").datepicker({ minViewMode: 2, format: 'yyyy' });
                }
            });
        });

        $('body').on('click', "#pvlivraison-consulter", function(e) {
            if (checkPvLivraisonFormData()) {
                const formData = getPvLivraisonFormData();
                $.ajax({
                    type:'POST',
                    url: siteURL + 'admin/outils/pvlivraison/consulter',
                    contentType: false,
                    processData: false,
                    data: formData,
                    success: function() {
                        toastr.success('Le fichier pdf est fini', 'Succès');
                        var element = document.createElement('a');
                        element.setAttribute('href', siteURL+"assets/docs/pvlivraison/all.pdf");
                        element.setAttribute('target', "_blank");
                        document.body.appendChild(element);
                        element.click();
                        document.body.removeChild(element);
                    },
                    error: function() { toastr.error('Erreur lors de la création du pdf', 'Erreur'); }
                });
            }
        });

        $('body').on('click', "#pvlivraison-zeendoc", function(e) {
            if (checkPvLivraisonFormData()) {
                $("#pvlivraison-zeendoc").prop("disabled", true); $("#pvlivraison-zeendoc").html("<i class=\"fad fa-spinner fa-pulse\"></i> Envoyer dans Zeendoc");
                const formData = getPvLivraisonFormData();
                $.ajax({
                    type:'POST',
                    url: siteURL + 'admin/outils/pvlivraison/zeendoc',
                    contentType: false,
                    processData: false,
                    data: formData,
                    success: function(data) {
                        if (parseInt(data)) { toastr.success('Un mail vient d\'être envoyé à Zeendoc.', 'Succès'); }
                        else { toastr.error('Erreur lors de l\'envoi du mail.', 'Erreur'); }
                    },
                    error: function() { toastr.error('Erreur lors de la requète', 'Erreur'); },
                    complete: function() { $("#pvlivraison-zeendoc").prop("disabled", false); $("#pvlivraison-zeendoc").html("<i class=\"fas fa-save\"></i> Envoyer dans Zeendoc"); }
                });
            }
        });

        $('body').on('click', "[id^='type_personne_aucun']", function(e) {
            $('#type_personne_aucun').attr('checked', true);
            $('#type_personne_physique').attr('checked', false);
            $('#type_personne_morale').attr('checked', false);
            $("#cotitulaire-physique").addClass("d-none");
            $("#cotitulaire-morale").addClass("d-none");
        });

        $('body').on('click', "[id^='type_personne_physique']", function(e) {
            $('#type_personne_aucun').attr('checked', false);
            $('#type_personne_physique').attr('checked', true);
            $('#type_personne_morale').attr('checked', false);
            $("#cotitulaire-physique").removeClass("d-none");
            $("#cotitulaire-morale").addClass("d-none");
        });

        $('body').on('click', "[id^='type_personne_morale']", function(e) {
            $('#type_personne_aucun').attr('checked', false);
            $('#type_personne_physique').attr('checked', false);
            $('#type_personne_morale').attr('checked', true);
            $("#cotitulaire-physique").addClass("d-none");
            $("#cotitulaire-morale").removeClass("d-none");
        });

        function checkPvLivraisonFormData() {
            let error = "";
            if ($("#pvlivraison-input-marque").val() == null) { error += "Marque non renseignée<br>"; }
            if ($("#pvlivraison-input-modele").val() == null) { error += "Modele non renseigné<br>"; }
            if ($("#pvlivraison-input-version").val() == "") { error += "Version non renseignée<br>"; }
            if ($("#pvlivraison-input-genre").val() == "") { error += "Genre non renseigné<br>"; }
            if ($("#pvlivraison-input-energie").val() == null) { error += "Energie non renseignée<br>"; }
            if ($("#pvlivraison-input-kilometrage").val() == "") { error += "Kilometrage non renseigné<br>"; }
            if ($("#pvlivraison-input-dateMEC").val() == "") { error += "Date de MEC non renseignée<br>"; }
            if ($("#pvlivraison-input-millesime").val() == "") { error += "Millesime non renseigné<br>"; }
            if ($("#pvlivraison-input-immatriculation").val() == "") { error += "Immatriculation non renseignée<br>"; }
            if ($("#pvlivraison-input-vin").val() == "") { error += "VIN non renseigné<br>"; }
            if ($("#pvlivraison-input-couleur").val() == null) { error += "Couleur non renseignée<br>"; }
            if (!($("#pvlivraison-input-civilite").hasClass("d-none")) && $("#pvlivraison-input-civilite").val() == null) { error += "Civilité non renseignée<br>"; }
            if ($("#pvlivraison-input-nom").val() == "") { error += "Nom non renseigné<br>"; }
            if ($("#pvlivraison-input-codePostal").val() == "") { error += "Code postal non renseigné<br>"; }
            if ($("#pvlivraison-input-ville").val() == "") { error += "Ville non renseignée<br>"; }
            if ($("#pvlivraison-input-adresse").val() == "") { error += "Adresse non renseignée<br>"; }
            if (!($("#pvlivraison-input-siret").hasClass("d-none")) && $("#pvlivraison-input-siret").val() == "") { error += "Siret non renseigné<br>"; }
            if (!($("#pvlivraison-input-villeNaissance").hasClass("d-none")) && $("#pvlivraison-input-villeNaissance").val() == "") { error += "Ville de naissance non renseignée<br>"; }
            if (!($("#pvlivraison-input-departementNaissance").hasClass("d-none")) && $("#pvlivraison-input-departementNaissance").val() == null) { error += "Département de naissance non renseignée<br>"; }
            if (!($("#pvlivraison-input-paysNaissance").hasClass("d-none")) && $("#pvlivraison-input-paysNaissance").val() == "") { error += "Pays de naissance non renseignée<br>"; }
            if (!($("#pvlivraison-input-dateNaissance").hasClass("d-none")) && $("#pvlivraison-input-dateNaissance").val() == "") { error += "Date de naissance non renseignée<br>"; }
            if ($('#type_personne_morale').attr('checked')) {
                if ($("#pvlivraison-input-cosiret").val() == "") { error += "Siret co-Titulaire non renseigné<br>"; }
                if ($("#pvlivraison-input-conom").val() == "") { error += "Raison sociale co-Titulaire non renseignée<br>"; }
            } else if ($('#type_personne_physique').attr('checked')) {
                if ($("#pvlivraison-input-nomPrenomCoTitulaire").val() == "") { error += "Nom et prénom co-titulaire non renseignés<br>"; }
            }
            if (error == "") { return true; } else { toastr.error(error, 'Erreur'); return false; }
        }

        function getPvLivraisonFormData() {
            let formData = new FormData();
            formData.append('marque', $("#pvlivraison-input-marque").val());
            formData.append('modele', $("#pvlivraison-input-modele").val());
            formData.append('version', $("#pvlivraison-input-version").val());
            formData.append('genre', $("#pvlivraison-input-genre").val());
            formData.append('energie', $("#pvlivraison-input-energie").val());
            formData.append('kilometrage', $("#pvlivraison-input-kilometrage").val());
            formData.append('dateMEC', $("#pvlivraison-input-dateMEC").val());
            formData.append('millesime', $("#pvlivraison-input-millesime").val());
            formData.append('immatriculation', $("#pvlivraison-input-immatriculation").val());
            formData.append('vin', $("#pvlivraison-input-vin").val());
            formData.append('couleur', $("#pvlivraison-input-couleur").val());
            formData.append('civilite', $("#pvlivraison-input-civilite").val());
            formData.append('nom', $("#pvlivraison-input-nom").val());
            formData.append('codePostal', $("#pvlivraison-input-codePostal").val());
            formData.append('ville', $("#pvlivraison-input-ville").val());
            formData.append('adresse', $("#pvlivraison-input-adresse").val());
            formData.append('numeroVoieAdresse', $("#pvlivraison-input-numeroVoieAdresse").val());
            formData.append('tel', $("#pvlivraison-input-tel").val());
            formData.append('mail', $("#pvlivraison-input-mail").val());
            formData.append('siret', $("#pvlivraison-input-siret").val());
            formData.append('typeCession', $("#pvlivraison-input-typeCession").val());
            formData.append('partenaireFinancier', $("#pvlivraison-input-partenaireFinancier").val());
            formData.append('titulaire', $("#pvlivraison-input-titulaire").val());
            formData.append('agence', $("#pvlivraison-input-agence").val());
            formData.append('conom', $("#pvlivraison-input-conom").val());
            formData.append('cosiret', $("#pvlivraison-input-cosiret").val());
            formData.append('codePostalNaissance', $("#pvlivraison-input-codePostalNaissance").val());
            formData.append('villeNaissance', $("#pvlivraison-input-villeNaissance").val());
            formData.append('departementNaissance', $("#pvlivraison-input-departementNaissance").val());
            formData.append('dateNaissance', $("#pvlivraison-input-dateNaissance").val());
            formData.append('paysNaissance', $("#pvlivraison-input-paysNaissance").val());
            formData.append('nomUsageCoTitulaire', $("#pvlivraison-input-nomUsageCoTitulaire").val());
            formData.append('nomPrenomCoTitulaire', $("#pvlivraison-input-nomPrenomCoTitulaire").val());
            let typeCotitulaire;
            if ($('#type_personne_morale').attr('checked')) {
                typeCotitulaire = "morale";
            } else if ($('#type_personne_physique').attr('checked')) {
                typeCotitulaire = "physique";
            } else {
                typeCotitulaire = "aucun";
            }
            formData.append('typeCotitulaire', typeCotitulaire);

            return formData;
        }
    }
});
